import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import parse from "html-react-parser";
import { RootState } from '../../store';
import { getDownloadData } from '../../store/actionCreators/pageActionCreators';
import { Spinner } from 'react-bootstrap';
import Colors from '../../utils/colors';


function DevideInstructions(props: any) {

    const { hash } = useLocation();
    useEffect(() => {
        if (hash) {
            const a = document.createElement('a');
            a.href = hash;
            a.click();
        }

    }, [hash]);

    const { activeInstallButton, getDeviceInfo, iosInfo, androidInfo, loadingDeviceInfo } = props;

    const isDateToEnable = (date: string) => {
        const cdate = new Date().toISOString().split('T')[0];
        if (cdate >= date) return true;
        return false;
    }

    useEffect(() => {
        getDeviceInfo(activeInstallButton === 'android' ? "android" : "ios");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeInstallButton]);

    const currentData = useMemo(() => {
        return activeInstallButton === 'ios' ? iosInfo : androidInfo;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeInstallButton, iosInfo, androidInfo]);

    if (activeInstallButton === 'ios' && (iosInfo === null || !isDateToEnable(iosInfo?.enable_date))) {
        return null
    }

    if (activeInstallButton === 'android' && (androidInfo === null || !isDateToEnable(androidInfo?.enable_date))) {
        return null
    }


    if (loadingDeviceInfo) {
        if (!currentData)
            return (
                <section className="direction-content-section">
                    <div className="container">
                        <div className="d-flex justify-content-center w-100 my-5">
                            <Spinner animation="border" style={{ color: Colors.primary }} />
                        </div>
                    </div>
                </section>
            )
    }

    return (
        <section className="direction-content-section" id="location-steps">
            <div className="container">
                <div className="direction-content-box">
                    <div className="section-title text-center text-capitalize">
                        <h2> {parse(currentData?.title)}&nbsp;
                            ({String(activeInstallButton)})
                        </h2>
                    </div>
                    <div className="direction-content-right">
                        {parse(currentData?.description)}
                    </div>
                    <div className="container">
                        <div className="steps-container">
                            {currentData?.step_1_title && currentData?.step_1_image && (
                                <div className="step-box">
                                    <div className="step-title">
                                        Step&nbsp;1: {currentData?.step_1_title}
                                    </div>
                                    <div className="step-image">
                                        <img src={currentData?.step_1_image} alt="" />
                                    </div>
                                </div>
                            )}
                            {currentData?.step_2_title && currentData?.step_2_image && (
                                <div className="step-box">
                                    <div className="step-title">
                                        Step&nbsp;2: {currentData?.step_2_title}
                                    </div>
                                    <div className="step-image">
                                        <img src={currentData?.step_2_image} alt="" />
                                    </div>
                                </div>
                            )}
                            {currentData?.step_3_title && currentData?.step_3_image && (
                                <div className="step-box">
                                    <div className="step-title">
                                        Step&nbsp;3: {currentData?.step_3_title}
                                    </div>
                                    <div className="step-image">
                                        <img src={currentData?.step_3_image} alt="" />
                                    </div>
                                </div>
                            )}
                            {currentData?.step_4_title && currentData?.step_4_image && (
                                <div className="step-box">
                                    <div className="step-title">
                                        Step&nbsp;4: {currentData?.step_4_title}
                                    </div>
                                    <div className="step-image">
                                        <img src={currentData?.step_4_image} alt="" />
                                    </div>
                                </div>
                            )}
                            {currentData?.step_5_title && currentData?.step_5_image && (
                                <div className="step-box">
                                    <div className="step-title">
                                        Step&nbsp;5: {currentData?.step_5_title}
                                    </div>
                                    <div className="step-image">
                                        <img src={currentData?.step_5_image} alt="" />
                                    </div>
                                </div>
                            )}
                            {currentData?.step_6_title && currentData?.step_6_image && (
                                <div className="step-box">
                                    <div className="step-title">
                                        Step&nbsp;6: {currentData?.step_6_title}
                                    </div>
                                    <div className="step-image">
                                        <img src={currentData?.step_6_image} alt="" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="direction-content-right live-mapping-text">
                        Live mapping will now be working. <a href="#install">Click Here</a> for instructions to download the App onto your phone.
                    </div>
                </div>
            </div>
        </section>
    )
}


function mapStateToProps(state: RootState) {
    const { pages } = state;
    return {
        iosInfo: pages.downloadAppDeviceInfo.ios,
        androidInfo: pages.downloadAppDeviceInfo.android,
        loadingDeviceInfo: pages.loadingDeviceInfo
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        getDeviceInfo: (device: string) => dispatch(getDownloadData(device))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DevideInstructions);
