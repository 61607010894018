import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import {
  fetchAboutPageImages,
  fetchAccommodationServices,
  fetchBestMoments,
  fetchFuelData,
  fetchFoodData,
  fetchTrails,
  fetchWildFlowers,
} from "../../services/apis/trailsServices";
import { trailData } from "../../types/trails";
import * as actionCreators from "../actionCreators/trailsActionCreators";
import * as actionTypes from "../actionTypes/trailsActionTypes";

function getSeperateTrailsData(data: trailData[], type: number) {
  const res = {} as { [index: number]: trailData[] };
  if (type === 2) {
    data.forEach((el) => {
      const type = parseInt(el.filter.replace("Grade ", ""));
      if (res[type]) {
        res[type].push(el);
      } else res[type] = [el];
    });
  } else {
    data.forEach((el) => {
      const type = el.sealed_unsealed;
      if (res[type]) {
        res[type].push(el);
      } else res[type] = [el];
    });
  }
  return res;
}

function* onLoadTrails({
  page,
  trail_type,
  filter,
}: actionTypes.GetTrailsAction) {
  try {
    yield put(actionCreators.SetTrailsLoading(true));
    const { data } = yield call(fetchTrails, page, trail_type, filter);
    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(
          actionCreators.getTrailsSuccess(data.data, trail_type, page, filter)
        );
        if (filter === 0 && trail_type !== 1) {
          try {
            const res = getSeperateTrailsData(data.data, trail_type);
            for (let i = 0; i < Object.keys(res).length; i++) {
              const el = Object.keys(res)[i];
              yield put(
                actionCreators.getTrailsSuccess(
                  res[parseInt(el)],
                  trail_type,
                  page,
                  parseInt(el)
                )
              );
            }
          } catch (err) {
            console.log(err);
          }
        } else if (filter === 6 && trail_type === 1) {
          try {
            const res = getSeperateTrailsData(data.data, trail_type);
            for (let i = 0; i < Object.keys(res).length; i++) {
              const el = Object.keys(res)[i];
              yield put(
                actionCreators.getTrailsSuccess(
                  res[parseInt(el)],
                  trail_type,
                  page,
                  parseInt(el)
                )
              );
            }
          } catch (err) {
            console.log(err);
          }
        }
        return;
      }
    }
    yield put(actionCreators.getTrailsFailure(""));
  } catch (error) {
    yield put(actionCreators.SetTrailsLoading(false));
    yield put(actionCreators.getTrailsFailure(""));
  }
}

// get wildflower data from server and dispatch to store
function* onLoadWildFlowers({ page }: actionTypes.GetWildFlowerAction) {
  try {
    yield put(actionCreators.SetTrailsLoading(true));
    const { data } = yield call(fetchWildFlowers, page);
    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(actionCreators.getWildFlowerSuccess(data.data, page));
        return;
      }
    }
    yield put(actionCreators.getWildFlowerFailure(""));
  } catch (error) {
    yield put(actionCreators.SetTrailsLoading(false));
    yield put(actionCreators.getWildFlowerFailure(""));
  }
}

//get about page images from server
function* onLoadAboutPageImages({
  page,
}: actionTypes.GetAboutPageImagesAction) {
  try {
    yield put(actionCreators.SetTrailsLoading(true));
    const { data } = yield call(fetchAboutPageImages, page);
    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(actionCreators.getAboutPageImagesSuccess(data.data));
        return;
      }
    }
    yield put(actionCreators.getAboutPageImagesSuccess(data));
  } catch (error) {
    yield put(actionCreators.SetTrailsLoading(false));
    yield put(actionCreators.getAboutPageImagesFailure(""));
  }
}

//get accommodation services from server
function* onLoadAccommodationServicesImages({
  page,
  accommodation_title_id,
}: actionTypes.GetAccommodationServicesAction) {
  try {
    yield put(actionCreators.SetTrailsLoading(true));
    const { data } = yield call(
      fetchAccommodationServices,
      page,
      accommodation_title_id
    );
    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(
          actionCreators.getAccommodationServicesSuccess(
            data.data,
            page,
            accommodation_title_id
          )
        );
        return;
      }
    }
    yield put(actionCreators.getAccommodationServicesFailure(""));
  } catch (error) {
    yield put(actionCreators.SetTrailsLoading(false));
    yield put(actionCreators.getAccommodationServicesFailure(""));
  }
}

//get Fuel Data from server
function* onLoadFuelData({
  page,
  fuel_and_food_title_id,
}: actionTypes.GetFuelDataAction) {
  try {
    yield put(actionCreators.SetTrailsLoading(true));

    const { data } = yield call(fetchFuelData, page, fuel_and_food_title_id);

    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(
          actionCreators.getFuelDataSuccess(data, page, fuel_and_food_title_id)
        );
        return;
      }
    }
    yield put(actionCreators.getFuelDataFailure(""));
  } catch (error) {
    yield put(actionCreators.SetTrailsLoading(false));
    yield put(actionCreators.getFuelDataFailure(""));
  }
}

//get Food Data from server
function* onLoadFoodData({
  page,
  fuel_and_food_title_id,
}: actionTypes.GetFoodDataAction) {
  try {
    yield put(actionCreators.SetTrailsLoading(true));
    const { data } = yield call(fetchFoodData, page, fuel_and_food_title_id);

    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(
          actionCreators.getFoodDataSuccess(data, page, fuel_and_food_title_id)
        );
        return;
      }
    }
    yield put(actionCreators.getFoodDataFailure(""));
  } catch (error) {
    yield put(actionCreators.SetTrailsLoading(false));
    yield put(actionCreators.getFoodDataFailure(""));
  }
}

//get FoodFuel Data from server
function* onLoadBestMoments({
  page,
}: actionTypes.GetHomePageBestMomentsAction) {
  try {
    yield put(actionCreators.SetTrailsLoading(true));
    const { data } = yield call(fetchBestMoments, page);
    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(actionCreators.getHomePageBestMomentsSuccess(data, page));
        return;
      }
    }
    yield put(actionCreators.getHomePageBestMomentsFailure(""));
  } catch (error) {
    yield put(actionCreators.SetTrailsLoading(false));
    yield put(actionCreators.getHomePageBestMomentsFailure(""));
  }
}
//-------------------------------------------[Watchers]----------------------------------------------------//
function* watchOnLoadTrails() {
  yield takeEvery(actionTypes.GET_TRAILS, onLoadTrails);
}

function* watchOnLoadWildFlowers() {
  yield takeEvery(actionTypes.GET_WILD_FLOWERS, onLoadWildFlowers);
}

function* watchOnAboutPageImages() {
  yield takeEvery(actionTypes.GET_ABOUT_PAGE_IMAGES, onLoadAboutPageImages);
}

function* watchOnLoadAccommodationServices() {
  yield takeEvery(
    actionTypes.GET_ACCOMMODATION_SERVICES,
    onLoadAccommodationServicesImages
  );
}

function* watchOnLoadFuelData() {
  yield takeEvery(actionTypes.GET_FUEL_DATA, onLoadFuelData);
}

function* watchOnLoadFoodData() {
  yield takeEvery(actionTypes.GET_FOOD_DATA, onLoadFoodData);
}

function* watchOnLoadBestMoments() {
  yield takeEvery(actionTypes.GET_HOME_PAGE_BEST_MOMENTS, onLoadBestMoments);
}

export default function* trailsSaga() {
  yield all([
    fork(watchOnLoadTrails),
    fork(watchOnLoadWildFlowers),
    fork(watchOnAboutPageImages),
    fork(watchOnLoadAccommodationServices),
    fork(watchOnLoadFuelData),
    fork(watchOnLoadFoodData),
    fork(watchOnLoadBestMoments),
  ]);
}
