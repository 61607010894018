import { AxiosResponse } from "axios";
import API, { getPublicKey } from "./index";
import { PAGE_TYPES } from "../../store/actionTypes/pageActionTypes";
import { TRAILS_TYPES } from "../../store/actionTypes/trailsActionTypes";

const paths = {
  GET_HOME_PAGE_DATA: "/home/page_content",
  GET_FOOD_FUEL_PAGE_DATA: "/fuel_and_food/page_content",
  GET_ABOUTUS_PAGE_DATA: "/aboutus/page_content",
  GET_DIRECTION_PAGE_DATA: "/direction/page_content",
  GET_PRIVACY_PAGE_DATA: "/privacy_disclaimer/privacy_disclaimer",
  TERMS_AND_CONDITIONS_PAGE_DATA: "terms_and_conditions/terms_and_conditions",
  GET_ACCOMMODATION_PAGE_DATA: "/accommodation/page_content",
  GET_WILD_FLOWER_PAGE_DATA: "/wildflowers/page_content",
  GET_TRAIL_PAGE_DATA: "/categories/page_content",
  GET_DOWNLOA_APP_PAGE_DATA: "/install_app/page_content",
  GET_DOWNLOAD_APP_DEVICE_INFO: "/install_app/instruction",
};

const getTrailsType = (pageName: string): number => {
  switch (pageName) {
    case PAGE_TYPES.MOUNTAIN_BIKE_PAGE:
      return TRAILS_TYPES.MOUNTAIN_BIKE_PAGE;
    case PAGE_TYPES.MOUNTAIN_WALK_PAGE:
      return TRAILS_TYPES.MOUNTAIN_WALK_PAGE;
    case PAGE_TYPES.DRIVES_PAGE:
      return TRAILS_TYPES.DRIVES_PAGE;
    default:
      return 1;
  }
};

export async function fetchPageData(pageName: string): Promise<AxiosResponse> {
  let data: {} = {
    private_key: getPublicKey(),
  };
  switch (pageName) {
    case PAGE_TYPES.ABOUTUS_PAGE:
      return await API.post(paths.GET_ABOUTUS_PAGE_DATA, data);
    case PAGE_TYPES.ACCOMMODATION_PAGE:
      return await API.post(paths.GET_ACCOMMODATION_PAGE_DATA, data);
    case PAGE_TYPES.DIRECTION_PAGE:
      return await API.post(paths.GET_DIRECTION_PAGE_DATA, data);
    case PAGE_TYPES.FOOD_FUEL_PAGE:
      return await API.post(paths.GET_FOOD_FUEL_PAGE_DATA, data);
    case PAGE_TYPES.HOME_PAGE:
      return await API.post(paths.GET_HOME_PAGE_DATA, data);
    case PAGE_TYPES.PRIVACY_PAGE:
      return await API.post(paths.GET_PRIVACY_PAGE_DATA, data);
    case PAGE_TYPES.WILD_FLOWER_PAGE:
      return await API.post(paths.GET_WILD_FLOWER_PAGE_DATA, data);
    case PAGE_TYPES.DOWNLOAD_APP_PAGE:
      return await API.post(paths.GET_DOWNLOA_APP_PAGE_DATA, data);
    case PAGE_TYPES.TERMS_AND_CONDITIONS:
      return await API.post(paths.TERMS_AND_CONDITIONS_PAGE_DATA, data);
    case PAGE_TYPES.MOUNTAIN_BIKE_PAGE:
    case PAGE_TYPES.MOUNTAIN_WALK_PAGE:
    case PAGE_TYPES.DRIVES_PAGE: {
      return await API.post(paths.GET_TRAIL_PAGE_DATA, {
        ...data,
        trail_type: getTrailsType(pageName),
      });
    }

    default:
      return await API.post(paths.GET_ABOUTUS_PAGE_DATA, data);
  }
}

export async function fetchDownloadAppDeviceInfo(
  device: string
): Promise<AxiosResponse> {
  const data = {
    ios_android: device,
    private_key: getPublicKey(),
  };

  return await API.post(paths.GET_DOWNLOAD_APP_DEVICE_INFO, data);
}
