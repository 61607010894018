import * as actions from "../actionTypes/pageActionTypes";
import { aboutUsPageContent } from "../../types/aboutUsType";
import { directionPageContent } from "../../types/directionType";
import { accommodationPageContent } from "../../types/accommodationType";
import { privacyPolicyPageContent } from "../../types/privacyPolicyType";
import { wildFlowerPageContent } from "../../types/wildFlowers";
import { HomePageContent } from "../../types/HomeType";
import { foodFuelPageContent } from "../../types/foodFuelType";
import { trailPageContent } from "../../types/trails";
import {
  DownloadAppInfo,
  DownloadAppPageContent,
} from "../../types/downloadAppType";
import { termsAndConditionPageContent } from "../../types/termsAndConditionsType";

export interface PageDataState {
  pageLoading: boolean;
  homePageData: string | HomePageContent;
  foodFuelPageData: undefined | foodFuelPageContent;
  aboutPageData: string | aboutUsPageContent;
  directionPageData: string | directionPageContent;
  privacyPageData: string | privacyPolicyPageContent;
  termsAndConditionsData: string | termsAndConditionPageContent;
  accommodationPageData: string | accommodationPageContent;
  wildFlowerPageData: string | wildFlowerPageContent;
  mountainWalkPageData: string | trailPageContent;
  mountainBikePageData: string | trailPageContent;
  mountainDrivePageData: string | trailPageContent;
  downloadAppPageData: string | DownloadAppPageContent;
  downloadAppDeviceInfo: DownloadAppInfo;
  loadingDeviceInfo: boolean;
  pageContentCheckList: { [index: string]: number };
  deviceInfoLoaded: boolean;
}

const initialState: PageDataState = {
  pageLoading: false,
  homePageData: "",
  foodFuelPageData: undefined,
  aboutPageData: "",
  directionPageData: "",
  privacyPageData: "",
  termsAndConditionsData: "",
  accommodationPageData: "",
  wildFlowerPageData: "",
  mountainWalkPageData: "",
  mountainBikePageData: "",
  mountainDrivePageData: "",
  downloadAppPageData: "",
  downloadAppDeviceInfo: {
    ios: null,
    android: null,
  },
  loadingDeviceInfo: false,
  pageContentCheckList: {},
  deviceInfoLoaded: false,
};

const getPageObjetKeyByName = (page: string) => {
  switch (page) {
    case actions.PAGE_TYPES.HOME_PAGE:
      return "homePageData";
    case actions.PAGE_TYPES.FOOD_FUEL_PAGE:
      return "foodFuelPageData";
    case actions.PAGE_TYPES.ABOUTUS_PAGE:
      return "aboutPageData";
    case actions.PAGE_TYPES.DIRECTION_PAGE:
      return "directionPageData";
    case actions.PAGE_TYPES.ACCOMMODATION_PAGE:
      return "accommodationPageData";
    case actions.PAGE_TYPES.PRIVACY_PAGE:
      return "privacyPageData";
    case actions.PAGE_TYPES.TERMS_AND_CONDITIONS:
      return "termsAndConditionsData";
    case actions.PAGE_TYPES.MOUNTAIN_BIKE_PAGE:
      return "mountainBikePageData";
    case actions.PAGE_TYPES.MOUNTAIN_WALK_PAGE:
      return "mountainWalkPageData";
    case actions.PAGE_TYPES.DRIVES_PAGE:
      return "mountainDrivePageData";
    case actions.PAGE_TYPES.WILD_FLOWER_PAGE:
      return "wildFlowerPageData";
    case actions.PAGE_TYPES.DOWNLOAD_APP_PAGE:
      return "downloadAppPageData";
    default:
      return "data";
  }
};

export default function TrailsReducer(
  state: PageDataState = initialState,
  action: actions.PageDataAction
): PageDataState {
  switch (action.type) {
    case actions.SET_PAGE_DATA_LOADING:
      return {
        ...state,
        pageLoading: action.loading,
      };
    case actions.SET_DOWNLOAD_DATA_LOADING:
      return {
        ...state,
        loadingDeviceInfo: action.loading,
      };
    case actions.SET_PAGE_DATA:
    case actions.GET_PAGE_DATA_SUCCESS:
      const pcl = state.pageContentCheckList;
      pcl[action.pageName] = 1;
      return {
        ...state,
        pageLoading: false,
        [getPageObjetKeyByName(action.pageName)]: action.data,
        pageContentCheckList: pcl,
      };
    case actions.SET_BULK_PAGE_DATA:
      const pcl2 = state.pageContentCheckList;
      Object.keys(action.data).forEach((el) => {
        pcl2[el] = 1;
      });
      return {
        ...state,
        pageLoading: false,
        ...action.data,
        pageContentCheckList: pcl2,
      };
    case actions.SET_DOWNLOAD_DATA:
    case actions.GET_DOWNLOAD_DATA_SUCCESS:
      return {
        ...state,
        pageLoading: false,
        downloadAppDeviceInfo: {
          ...state.downloadAppDeviceInfo,
          [action.device]: action.data,
        },
        deviceInfoLoaded:
          action.device === "android" || action.device === "ios",
      };
    case actions.GET_PAGE_DATA_FAILURE:
      return {
        ...state,
        pageLoading: false,
      };

    default:
      return state;
  }
}
