import React, { useRef } from 'react';
import { Icon, LatLng, Marker as MarkerInf } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import CloseBtn from '../../assets/images/close_btn.svg';

interface CustomMarkerPopupProps {
    markerIcon: Icon;
    latitude?: string;
    longitude?: string;
    image: string;
    title: string;
    description?: string | JSX.Element | JSX.Element[];
}

const CustomMarkerPopup = ({ latitude, longitude, markerIcon, image, title, description }: CustomMarkerPopupProps) => {
    const markerRef = useRef<MarkerInf>(null);

    if (!latitude || !longitude) return null;

    const getLatLng = () => {
        return new LatLng(parseFloat(latitude), parseFloat(longitude))
    }

    const closePopupModal = () => {
        if (markerRef.current) {
            markerRef.current.closePopup();
        }
    }

    return (
        <Marker position={getLatLng()} icon={markerIcon}
            ref={markerRef} title={title}
            eventHandlers={{
                click() {
                    if (markerRef.current) markerRef.current.openPopup();
                }
            }}>

            <Popup className="custom-popup-wrapper"
                autoClose
            // onOpen={() => {
            //     if (markerRef.current) {
            //         markerRef.current.getElement()?.classList.add('active-marker');
            //     }
            // }}
            // onClose={() => {
            //     if (markerRef.current) {
            //         markerRef.current.getElement()?.classList.remove('active-marker');
            //     }
            // }}
            >
                <div className="custom-popup">
                    <div className="close-btn cursor-pointer" onClick={closePopupModal}>
                        <img src={CloseBtn} alt="" width="18" height="18" />
                    </div>
                    <img src={image} width="254" height="108" alt="" />
                    <div className="custom-popup-content">
                        <h5>{title}</h5>
                        <p>{description}</p>
                    </div>
                </div>
            </Popup>
        </Marker>
    )
}

export default CustomMarkerPopup;