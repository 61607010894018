import React from 'react';
import Colors from '../utils/colors';


const Error = () => {
    return (
        <div className="error-page-wrapper d-flex flex-column justify-content-center align-items-center">
            <h2> <span style={{ color: Colors.danger }}>404 </span>| Page Not Found</h2>
            <h5>Oops! Can't find the page your looking for.</h5>
        </div>
    )
}

export default Error;