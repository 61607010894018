import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  bed_image_black,
  drive_detail_banner,
  map_direction_image,
  map_trail_image,
  van_image,
} from "../utils/images";
import AliceCarousel from "react-alice-carousel";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../store";
import { getTripType, trailData, trail_images } from "../types/trails";
import { useHistory } from "react-router-dom";
import { fetchSingleTrail } from "../services/apis/trailsServices";
import { FullPageLoader } from "../components/Loader";
import { KMLMap } from "../components/CustomMap";
import parse from "html-react-parser";
import ImageView from "../components/Image";
import { getDevicetype, getTitle } from "../utils/helper-functions";
import { setCurrentMapPinpPointsData } from "../store/actionCreators/trailsActionCreators";
import { Helmet } from 'react-helmet';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const DriveDetail = (props: any) => {
  const { singleTrail, detailedMapsArr } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState<trailData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [startLatitude, SetStartLatitude] = useState(-34.45);
  const [startLongitude, SetStartLongitude] = useState(118.05);

  /*
   **@Function to redirect to previous page or home page
   */
  const redirectPage = () => {
    if (history.length > 0) history.goBack();
    else history.replace("/");
  };

  /**
   * Function to retieve single trail data
   * @returns void
   */
  const getTrailData = async () => {
    try {
      const res = await fetchSingleTrail(singleTrail.id);
      if (res.status === 200 && res.data) {
        setLoading(false);
        setData(res.data.data);
        dispatch(setCurrentMapPinpPointsData(res.data.data.pin_location))
        return;
      }
      setLoading(false);
      redirectPage();
    } catch (err) {
      redirectPage();
    }
  };

  useEffect(() => {
    if (singleTrail) {
      try {
        if (navigator.onLine) {
          getTrailData();
          return;
        }
        setLoading(false);
        setData(singleTrail);
        dispatch(setCurrentMapPinpPointsData(singleTrail.pin_location))
      } catch (err) {
        redirectPage();
      }
    }
    // eslint-disable-next-line
  }, [singleTrail]);

  // const showLiveMap = () => {
  //   window.location.href = '/live-trail/2';
  // }

  // show loader when page data is being loaded
  if (loading) return <FullPageLoader />;
  else
    return (
      <div className="main-wrapper">
        <Helmet>
          <link rel="canonical" href={`https://www.stirlingrangetrails.com.au/drives/${getTitle(data?.title)}`} />
          <meta name="description" content={data?.meta_description || ""} />
          <title>{data?.meta_title || "Stirling Range Trails"}</title>
        </Helmet>
        <section className="inner-hero-section">
          <ImageView
            url_src={data?.trail_image || ""}
            placeholder={drive_detail_banner}
            alt={data?.banner_image_alt_content || ""}
            description={data?.banner_image_description || ""}
          />
        </section>
        <section className="inner-second-section">
          <div className="container">
            <div className="title-section-head-box">
              <div className="title-section-head">
                <div className="title-section-head-icon">
                  <img src={van_image} alt="van" />
                </div>
                <h1>{data?.title || "Drive Trail Name"}</h1>
                <span>Drives</span>
              </div>
              <div className="item-info-detail-col">
                <div className="item-info-detail-col-data drives-cols drive-item-info-detail-col-data">
                  <ul>
                    <li className={getDevicetype() === 'mobile' ? "less-line-height" : ""}>
                      <span>{data?.length || "N/A"}km</span> Length
                      {getDevicetype() === 'mobile' ? <br /> : <>&nbsp;</>}
                      {getTripType(data?.trip_type || 4, 'DRIVE')}
                    </li>
                    <li>
                      <span>{data?.time || "N/A"}</span> Time
                    </li>
                    <li>
                      <span>
                        {data?.sealed_unsealed === 0 ? "Unsealed" : data?.sealed_unsealed === 1 ? "Sealed" : "Mixed"}
                      </span>{" "}
                      Road
                    </li>
                  </ul>
                </div>
              </div>
              <div className="title-section-head-content">
                <div className="title-section-head-title">
                  {parse(data?.short_description || "No description available")}
                </div>
                <div className="title-section-head-text">
                  {parse(data?.full_description || "No description available")}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="item-detain-map-section">
          <div className="container">
            <div className="section-title text-center">
              <h2 className=" text-center">Drive Trail Map</h2>
            </div>
            <div className="item-detain-map-view">
              <div className="detail-page-map-container">
                <KMLMap
                  trails_link={data?.trail_data_link}
                  pinpoints={data?.pin_location || null}
                  onStartLatitude={(latitude: number) => SetStartLatitude(latitude)}
                  onStartLongitude={(longitude: number) => SetStartLongitude(longitude)}
                  trailId={data?.id}
                  trailType={'drive'}
                  detailedMapDownloaded={detailedMapsArr.includes(data?.id)}
                />
              </div>
              <div className="map-button-box">
                <a href={`https://maps.google.com/maps/dir/?api=1&destination=${startLatitude},${startLongitude}`} target="_blank" rel="noreferrer">
                  <button className="map-button">
                    <span>
                      <img src={map_direction_image} alt="map" />
                    </span>
                    Get Directions
                  </button>
                </a>
                <Link children={<button className="map-button">
                  <span>
                    <img src={map_trail_image} alt="map" />
                  </span>
                  Start Trail
                </button>} to={"/live-trail/2/" + data?.id} />
              </div>
            </div>
          </div>
        </section>
        <section className="item-detail-img-section">
          <div className="container">
            <div className="section-title text-center">
              <h2 className=" text-center">
                {data?.title} <span>TRAIL PHOTOS</span>
              </h2>
            </div>
            <div className="item-detail-img-box">
              {data?.trail_images && data?.trail_images.length > 0 && (
                <AliceCarousel
                  mouseTracking
                  responsive={responsive}
                  controlsStrategy="responsive"
                  autoPlay={data.trail_images.length > 3}
                  animationDuration={3000}
                  infinite={data.trail_images.length > 3}
                  autoPlayStrategy="action"
                >
                  {data?.trail_images.map((el: trail_images) => (
                    <div className="drive-detail-slide-item" key={el.id}>
                      <ImageView url_src={el.image_link} alt={el.image_alt_content || ""}
                        description={el.image_description || ""} />
                    </div>
                  ))}
                </AliceCarousel>
              )}

              {/* if no images found */}
              {data?.trail_images && data?.trail_images.length <= 0 && (
                <div className="no-result-box">
                  <span>No photos</span>
                </div>
              )}

            </div>
          </div>
        </section>
        <section className="item-accommodation-link-wrapper">
          <h5>There is a range of different accommodation types and options when visiting the Stirling Range National Park. </h5>
          <Link to="/accommodation">
            <button className="map-button">
              <span>
                <img src={bed_image_black} alt="map" />
              </span>
              Accommodation
            </button>
          </Link>
        </section>
      </div>
    );
};

function mapStateToProps(state: RootState) {
  const { trails } = state;
  return { 
    singleTrail: trails.singleTrail || null,
    detailedMapsArr: trails.detailedMapsArr
  };
}

export default connect(mapStateToProps)(DriveDetail);
