import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getFoodData, getFuelData } from '../../store/actionCreators/trailsActionCreators';
import { foodFuelFoodData, foodFuelFuelData } from '../../types/foodFuelType';
import Colors from '../../utils/colors';
import ServiceCard from '../Cards/ServiceCard';

export interface FoodFuelListProps {
    data: { [key: number]: foodFuelFoodData[] | foodFuelFuelData[] };
    food_fuel_type: number;
    type: number;
    title: string;
    subtitle?: string;
    loading: boolean;
}


const FoodFuelList = ({ data, food_fuel_type, type, title, subtitle, loading }: FoodFuelListProps) => {

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [dataLength, setDataLength] = useState(0);

    useEffect(() => {
        if (navigator.onLine) {
            if (food_fuel_type === 1) {
                dispatch(getFuelData(page, type))
            } else dispatch(getFoodData(page, type))
        }
    }, [page, dispatch, type, food_fuel_type]);

    // eslint-disable-next-line
    useEffect(() => {
        let total = 0;
        if (data) {
            for (let key of Object.keys(data)) {
                if (data[parseInt(key)] && parseInt(key) <= page) total += data[parseInt(key)].length;
                else if (parseInt(key) > page) setPage(page + 1)
            }
        }
        setDataLength(total);
    });

    if (data && (Object.keys(data).length <= 0 || data[1].length <= 0)) {
        return null;
    }

    return (
        <section id={`foof-fule-${food_fuel_type}-${type}`}>
            <div>
                <div className="accommodation-list-title">
                    <h3>{title}</h3>
                </div>
                <div className="accommodation-list-subtitle">{subtitle}</div>
            </div>
            <div className="accommodation-place-list-box">
                {data &&
                    Object.keys(data).map(
                        (key: string) =>
                            data[parseInt(key)] &&
                            data[parseInt(key)].map((el: foodFuelFoodData, i: number) => (
                                <ServiceCard key={el.id + "-service--" + type + "-" + i} {...el} type={food_fuel_type === 1 ? "FUEL" : "FOOD"} />
                            ))
                    )}
            </div>
            {
                loading && !(dataLength >= page * 3) && (
                    <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" style={{ color: Colors.primary }} />
                    </div>
                )
            }
            {
                !loading && data && Object.keys(data).length <= 0 && (
                    <div className="no-result-box">
                        <span>No results</span>
                    </div>
                )
            }
            {(dataLength >= page * 3) &&
                <div className="d-flex justify-content-center load-more-text-wrapper">
                    <span onClick={() => setPage(page + 1)}>Load more</span>
                </div>
            }
        </section >
    )
}

export default FoodFuelList;