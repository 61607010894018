import React from 'react';
import { logo } from '../../utils/images';

function Logo() {
    return (
        <a href="/">
            <img src={logo} className="brand-desktop" alt="logo" />
            <img src={logo} alt="logo" className="brand-mobile" />
        </a>
    )
}

export default Logo;