import React from 'react';
import { connect } from 'react-redux';
import { FullPageLoader } from '../components/Loader';
import { RootState } from '../store';
import { getPageData } from '../store/actionCreators/pageActionCreators';
import { PAGE_TYPES } from '../store/actionTypes/pageActionTypes';
import { privacy_icon, privacy_banner_image } from '../utils/images';
import parse from 'html-react-parser';
import ImageView from '../components/Image';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = (props: any) => {
    const { pageData, pageLoading } = props;

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/privacy-policy" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image || privacy_banner_image}
                    placeholder={privacy_banner_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={privacy_icon} alt="info" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Privacy Policy<br />&amp; Disclaimers')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(pageData?.short_description || "No description available")}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.full_description || "No description available")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="privacy-content-section">
                <div className="container">
                    <div className="privacy-content-box">
                        <div className="privacy-content-title">Privacy Policy</div>
                        <div className="privacy-content-text">
                            {parse(pageData?.privacy_policy_content || "No description available")}
                        </div>
                    </div>
                    <div className="privacy-content-box">
                        <div className="privacy-content-title">Disclaimers</div>
                        <div className="privacy-content-text">
                            {parse(pageData?.disclaimers_content || "No description available")}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    const { pages } = state;
    return { pageData: pages.privacyPageData, pageLoading: pages.pageLoading }
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine)
        dispatch(getPageData(PAGE_TYPES.PRIVACY_PAGE))
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);