import React from 'react';
import { getFormattedDate } from '../../utils/helper-functions';
import { image_placehoder } from '../../utils/images';
import parse from 'html-react-parser';
import ImageView from '../Image';

export interface WildFlowerCardProps {
    title: string;
    short_desc: string;
    email: string;
    date_from: string;
    date_to: string;
    image: string;
    external_link: string;
    description: string | null;
    alt: string | null;
}

function WildFlowerCard(props: WildFlowerCardProps) {

    return (
        <div className="wild-flower-tour-item-col">
            <div className="wild-flower-tour-item-full">
                <div className="wild-flower-tour-item-img">
                    <ImageView
                        url_src={props.image || image_placehoder}
                        placeholder={image_placehoder}
                        alt={props.alt}
                        description={props.description}
                        height="225"
                        styles={{ width: '100%', objectFit: 'cover' }}
                    />
                </div>
                <div className="wild-flower-tour-item-title">{props.title}</div>
                <div className="wild-flower-tour-item-text">{getFormattedDate(props.date_from)} – {getFormattedDate(props.date_to)} <br />
                    {parse(props.short_desc || "")}
                </div>
                <div className="wild-flower-tour-item-link">
                    <a href={`mailto:${props.email}`} rel="noreferrer">{props.email}</a>
                    <a href={props.external_link} target="_blank" rel="noreferrer">{props.external_link}</a>
                </div>
            </div>
        </div>
    );
}

export default WildFlowerCard;