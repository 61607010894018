import axios from "axios";
import aes from "crypto-js/aes";

const API = axios.create({
  baseURL: "https://api.stirlingrangetrails.com.au/api",
});

export function getPublicKey() {
  const key = process.env.REACT_APP_PRIVATE_KEY || "";
  const API_KEY = process.env.REACT_APP_API_KEY || "";
  return aes.encrypt(API_KEY, key).toString();
}

export async function getUserGeolocation() {
  try {
    return await axios.get("https://extreme-ip-lookup.com/json");
  } catch (err) {
    return null;
  }
}

export default API;
