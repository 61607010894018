import { openDB, DBSchema } from "idb";
import { trailData } from "../../types/trails";

export interface ImageValueSchema {
  image: Blob;
  imageurl: string;
}

export interface KmlValueSchema {
  data: string;
  kmlurl: string;
}

export interface trailDataSchema {
  data: trailData;
  trail_id: number;
}

interface MyDB extends DBSchema {
  images: {
    value: ImageValueSchema;
    key: string;
    indexes: { "by-url": number };
  };

  kml_data: {
    value: KmlValueSchema;
    key: string;
    indexes: { "by-url": number };
  };
  trail_data: {
    value: trailDataSchema;
    key: number;
    indexes: { "trail-id": number };
  };
}

const dbPromise = openDB<MyDB>("stirlingrange-trails", 1, {
  upgrade(db) {
    const imageStore = db.createObjectStore("images", {
      keyPath: "imageurl",
    });
    imageStore.createIndex("by-url", "imageurl");

    const kmlStore = db.createObjectStore("kml_data", {
      keyPath: "kmlurl",
    });
    kmlStore.createIndex("by-url", "kmlurl");

    const trailDataStore = db.createObjectStore("trail_data", {
      keyPath: "trail_id",
    });
    trailDataStore.createIndex("trail-id", "trail_id");
  },
});

// setter and getter for image
export async function setImage(data: ImageValueSchema) {
  try {
    return (await dbPromise).put("images", data);
  } catch (err) {
    return null;
  }
}

export async function getImageByUrl(url: string) {
  try {
    return (await dbPromise).get("images", url);
  } catch (err) {
    return null;
  }
}
// setter and getter for kml data
export async function setKmlData(data: KmlValueSchema) {
  try {
    return (await dbPromise).put("kml_data", data);
  } catch (err) {
    return null;
  }
}

export async function getKmlDataByUrl(url: string) {
  try {
    return (await dbPromise).get("kml_data", url);
  } catch (err) {
    return null;
  }
}

// setter and getter for single trail data
export async function setTrailData(data: trailDataSchema) {
  try {
    return (await dbPromise).put("trail_data", data);
  } catch (err) {
    return null;
  }
}

export async function getTrailDataId(trailId: number) {
  try {
    return (await dbPromise).get("trail_data", trailId);
  } catch (err) {
    return null;
  }
}
