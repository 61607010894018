import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

render(
  // <React.StrictMode>
  <App />
  // {/* </React.StrictMode> */ }
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const Config = {
  onSuccess: function () {
      console.log('onSuccess');
  },
  onUpdate: function () {
    console.log('onUpdate');
    const reply = confirm('New Version of App is available. Press OK to reload.')
    if (reply) {
      console.log('Reloading...')
      window.location.reload()
    } else {
      // nothing
      console.log('Nothing...')
    }
  }
};
serviceWorkerRegistration.register(Config);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
