import React from "react";
import { Link } from "react-router-dom";
import { image_placehoder } from "../../utils/images";
import ImageView from "../Image";

export interface SimpleCardProps {
  name: string;
  image: string;
  link: string;
  icon: string;
  additionalClass?: string;
  description: string | null;
  alt: string | null;
}

function SimpleCard(props: SimpleCardProps) {
  return (
    <Link to={props.link} className={`home-explore-range-item ${props.additionalClass || ""}`}>
      <div className="home-explore-range-item-inner">
        <div className="home-explore-range-img">
          {props.image && (
            <ImageView
              url_src={props.image}
              placeholder={image_placehoder}
              alt={props.alt}
              description={props.description}
              className="explore-image"
            />
          )}
          <div className="home-explore-range-icon">
            <img src={props.icon} alt="" />
          </div>
        </div>
        <div className="home-explore-range-title">{props.name}</div>
      </div>
    </Link>
  );
}

export default SimpleCard;
