import React from "react";
import { connect, useSelector } from "react-redux";
import { FullPageLoader } from "../components/Loader";
import { RootState } from "../store";
import { getPageData } from "../store/actionCreators/pageActionCreators";
import { PAGE_TYPES } from "../store/actionTypes/pageActionTypes";
import { bed_image, room_image, room_image1 } from "../utils/images";
import parse from "html-react-parser";
import ImageView from "../components/Image";
import { AccommodationList } from "../components/List";
import { Helmet } from 'react-helmet';

const Accommodation = (props: any) => {
    const { pageData, pageLoading } = props;

    const { Services, loading } = useSelector((state: RootState) => {
        return {
            Services: state.trails.accommodationsServices,
            loading: state.trails.loading
        }
    });

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/accommodation" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image}
                    placeholder={room_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={bed_image} alt="bed" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Accommodation')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(
                                    pageData?.banner_short_description ||
                                    "No descripton available"
                                )}
                            </div>
                            <div className="title-section-head-text">
                                {parse(
                                    pageData?.banner_full_description || "No descripton available"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content-page-second-section content-page-second-reverse mb-0">
                <div className="content-page-second-img-big">
                    <ImageView
                        url_src={pageData?.section_one_image}
                        placeholder={room_image1}
                        alt={pageData?.section_one_image_alt_content}
                        description={pageData?.section_one_image_description}
                    />
                </div>
                <div className="container">
                    <div className="content-page-second-box content-page-second-reverse">
                        <div className="content-page-second-text ">
                            <div className="section-title">
                                <h2>
                                    {parse(pageData?.section_one_title || "Where<br /> to Stay")}
                                </h2>
                            </div>
                            <div className="content-page-second-img content-page-second-img-mobile">
                                <ImageView
                                    url_src={pageData?.section_one_image}
                                    placeholder={room_image1}
                                    alt={pageData?.section_one_image_alt_content}
                                    description={pageData?.section_one_image_description}
                                />
                            </div>
                            <div className="section-content-box">
                                {parse(
                                    pageData?.section_one_short_description ||
                                    "No description available"
                                )}
                            </div>
                        </div>
                        <div className="content-page-second-img content-page-second-img-desktop">
                            <ImageView
                                url_src={pageData?.section_one_image}
                                placeholder={room_image1}
                                alt={pageData?.section_one_image_alt_content}
                                description={pageData?.section_one_image_description}
                            />
                        </div>
                    </div>
                    <section>
                        <div className="accommodation-list-item-wrapper">

                            <AccommodationList
                                loading={loading}
                                type={1}
                                data={Services[1]}
                                title="AMELUP"
                                subtitle="This accommodation is the closest to Stirling Range National Park"
                            />
                        </div>
                        <div className="accommodation-list-item-wrapper">
                            <AccommodationList
                                loading={loading}
                                type={2}
                                data={Services[2]}
                                title="BORDEN (39km North)"
                            />
                        </div>
                        <div className="accommodation-list-item-wrapper">
                            <AccommodationList
                                loading={loading}
                                type={3}
                                data={Services[3]}
                                title="GNOWANGERUP (50km North)"
                            />
                        </div>
                        <div className="accommodation-list-item-wrapper">
                            <AccommodationList
                                loading={loading}
                                type={4}
                                data={Services[4]}
                                title="ONGERUP (68km North)"
                            />
                        </div>
                    </section>
                </div>
            </section>
        </div>
    );
};

function mapStateToProps(state: RootState) {
    const { pages } = state;
    return {
        pageData: pages.accommodationPageData,
        pageLoading: pages.pageLoading,
    };
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine) dispatch(getPageData(PAGE_TYPES.ACCOMMODATION_PAGE));
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Accommodation);
