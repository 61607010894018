import React, { useState } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';
import useIosInstallPrompt, { isIOS } from '../../hooks/useIosInstallPrompt';
import useAndroidInstallPrompt, { isAndroid } from '../../hooks/useAndroidInstallPrompt';
import { install_icon, ios_install_btn } from '../../utils/images';
import constants from '../../utils/globalConstants';
import { getDevicetype } from '../../utils/helper-functions';

const renderInfoText = () => {
    return (
        <div className="text-center">
            <label style={{ width: 245 }}>
                This website has app functionality. Add it to your Home Screen to use it in fullscreen and while offline.
            </label>
        </div>
    )
}

const InstallPWA = () => {
    const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
    const [androidInstallPrompt, handleAndroidInstallDeclined, handleAndroidInstallAccepted] = useAndroidInstallPrompt();
    const [showModal, setShowModal] = useState(true);

    const handleCloseModal = () => {
        if (iosInstallPrompt) {
            handleIOSInstallDeclined();
        }
        setShowModal(false);
    }
    if (getDevicetype() === 'desktop' || window.matchMedia('(display-mode: standalone)').matches) {
        return null;
    }

    else {

        if (isIOS() && !iosInstallPrompt) {
            return null;
        } else if (isAndroid() && !androidInstallPrompt) return null;
        else
            return (
                <Modal show={showModal} centered onHide={handleCloseModal}
                    contentClassName="mx-2">
                    <Card >
                        <img
                            className="mx-auto"
                            style={{
                                marginTop: iosInstallPrompt ? '-32px' : '12px'
                            }}
                            width="100px"
                            src={install_icon}
                            alt="Icon"
                        />
                        <Card.Body>
                            {iosInstallPrompt && (
                                <>
                                    <Card.Title className="text-center">
                                        <h3 style={{ paddingRight: 20, paddingLeft: 20 }}>
                                            Install {constants.APP_NAME}
                                        </h3>
                                    </Card.Title>
                                    <Card.Text >
                                        {renderInfoText()}
                                    </Card.Text>
                                </>
                            )}
                            {androidInstallPrompt && isAndroid() && (
                                <>
                                    <Card.Title className="text-center">
                                        <h3 style={{ paddingRight: 20, paddingLeft: 20 }}>
                                            Add {constants.APP_NAME}
                                            &nbsp; to your<br />
                                            Home Screen
                                        </h3>
                                    </Card.Title>
                                    <Card.Text >
                                        {renderInfoText()}
                                    </Card.Text>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="link" color="primary" onClick={() => {
                                            handleAndroidInstallDeclined();
                                            handleCloseModal();
                                        }} size="lg">Cancel</Button>
                                        <div style={{ width: '35px' }} />
                                        <Button variant="link" color="primary" onClick={() => {
                                            handleAndroidInstallAccepted();
                                            handleCloseModal();
                                        }} size="lg">
                                            Add
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Card.Body>
                        {iosInstallPrompt && (<Card.Footer>
                            <Card.Text className="text-center">
                                Just tap
                                <img
                                    src={ios_install_btn}
                                    style={{ margin: 'auto 8px 8px' }}
                                    className=""
                                    alt="Add to homescreen"
                                    width="20"
                                />
                                then &quot;Add to Home Screen&quot;
                            </Card.Text>
                        </Card.Footer>
                        )}
                    </Card>
                </Modal>
            );
    }
};

export default InstallPWA;