import React, { useState, useEffect, useCallback } from 'react';
import { Spinner, ProgressBar } from 'react-bootstrap';
import Logo from '../Logo';
import L, { Map, LatLngExpression } from "leaflet";
import { fetchAllMaps, fetchMapForOffline, calculateMaxOfflineZoom } from "../../utils/map-functions";
import { MapConsumer, MapContainer, TileLayer } from "react-leaflet";

function FullPageMapLoader(props: { now?: number, title?: string, trailsLoaded?: boolean }) {


    const { now, trailsLoaded } = props;
    const progressInstance = <ProgressBar variant="success" now={now} label={`${now}%`}
        style={{ minWidth: '100px', height: '12px', marginTop: 12, color: 'black' }} />;
    const [map, setMap] = useState<Map | undefined>();

        useEffect(() => {
          if(map && trailsLoaded){
            
            // fetchMapForOffline(kmlText, map)
            console.log('running here')

            fetchAllMaps(map).then((complete) => {
            
              console.log('Promise returned, maps loaded complete:', complete)

            })

            
            // @ts-ignore
            const tileLayerOffline = L.tileLayer.offline(
              "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              }
            );
            tileLayerOffline.addTo(map);
          }
        }, [map, trailsLoaded]);

    return (
        <div className="loader-wrapper">
            <div className="loader-body align-items-center d-flex flex-column">
                <Logo />
                <br />
                <Spinner animation={'border'} className="text-success" style={{ marginTop: 10 }} />
                {now && progressInstance}
                <br />
                <br />
                {props.title && <div className="text-center">
                    <h4>{props.title}</h4>
                </div>}

            <MapContainer
                style={{ height: "100%", width: "100%" }}
                // zoom={zoomLevel ? customZoom : getDevicetype() === 'mobile' ? 9 : 10}
                markerZoomAnimation
                zoomControl={false}
                center={[-34.38, 118.1]}
                className="w-100"
                tap={false}
                whenCreated={setMap}
            >
            </MapContainer>

            </div>
        </div>
    )
}

export default FullPageMapLoader;