import React from 'react';
import { bicycle_icon, mountain_bike_banner_image } from '../utils/images';
// import { MountainWalkCard } from '../components/Cards';
import { connect } from 'react-redux';
import { RootState } from '../store';
// import { getTrails } from '../store/actionCreators/trailsActionCreators';
// import Colors from '../utils/colors';
// import { Spinner } from 'react-bootstrap';
// import { trailData } from '../types/trails';
// import GradeInfo from '../data/gradesInfo';
import { PAGE_TYPES } from '../store/actionTypes/pageActionTypes';
import { getPageData } from '../store/actionCreators/pageActionCreators';
import { FullPageLoader } from '../components/Loader';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { TRAILS_TYPES } from '../store/actionTypes/trailsActionTypes';
import parse from 'html-react-parser';
import ImageView from '../components/Image';
import { Helmet } from 'react-helmet';

const MountainBike = (props: any) => {

    const { pageData, pageLoading } = props;
    // const dispatch = useDispatch();

    // const [page, setPage] = useState(1);
    // const [filter, setFilter] = useState(0);
    // const [currentData, setCurrentData] = useState<any>([]);

    // const loadData = () => {
    //     if (navigator.onLine)
    //         dispatch(getTrails(page, TRAILS_TYPES.MOUNTAIN_BIKE_PAGE, filter))
    // }

    // useEffect(() => {
    //     loadData();
    //     // eslint-disable-next-line 
    // }, [page]);

    // useEffect(() => {
    //     if (page !== 1)
    //         setPage(1);
    //     else {
    //         setCurrentData([]);
    //         loadData();
    //     }
    //     // eslint-disable-next-line
    // }, [filter]);

    // const loadCurrentData = () => {
    //     if (page === 1) {
    //         if (trailsList[filter] !== undefined && trailsList[filter][page] !== undefined)
    //             setCurrentData(trailsList[filter][page]);
    //     } else {
    //         if (trailsList[filter] !== undefined && trailsList[filter][page] !== undefined)
    //             setCurrentData((prev: any) => [...prev, ...trailsList[filter][page]]);
    //     }
    // }

    // useEffect(() => {
    //     loadCurrentData();
    //     // eslint-disable-next-line 
    // }, [trailsList]);



    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/mountain-bike" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image || mountain_bike_banner_image}
                    placeholder={mountain_bike_banner_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={bicycle_icon} alt="bicyle" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Mountain Bike')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(pageData?.short_description || "")}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.full_description || "")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="mountain-walk-item-section">
                <div className="container">
                    <div className="item-list-fliter-box">
                        <div className="item-list-fliter-title">Grade Filter</div>
                        <div className="item-list-fliter-field">
                            <div className="item-list-fliter-field-item">
                                <div className={`item-list-fliter-field-label cursor-pointer ${filter === 0 ? 'active-text' : ''}`}
                                    onClick={() => setFilter(0)}>SHOW ALL </div>
                                <ul>
                                    <li onClick={() => setFilter(1)}><input type="radio" name="grade" id="grade1" checked={filter === 1} onChange={() => setFilter(1)} /> <label> 1</label> </li>
                                    <li onClick={() => setFilter(2)}><input type="radio" name="grade" id="grade2" checked={filter === 2} onChange={() => setFilter(2)} /> <label> 2</label> </li>
                                    <li onClick={() => setFilter(3)}><input type="radio" name="grade" id="grade3" checked={filter === 3} onChange={() => setFilter(3)} /> <label> 3</label> </li>
                                    <li onClick={() => setFilter(4)}><input type="radio" name="grade" id="grade4" checked={filter === 4} onChange={() => setFilter(4)} /> <label> 4</label> </li>
                                    <li onClick={() => setFilter(5)}><input type="radio" name="grade" id="grade5" checked={filter === 5} onChange={() => setFilter(5)} /> <label> 5</label> </li>
                                </ul>
                            </div>
                        </div>
                        <div className="item-list-fliter-item-text">
                            <h4>GRADE {filter || "ALL"}</h4>
                            {GradeInfo[filter]}
                        </div>
                    </div>
                    <InfiniteScroll
                        dataLength={currentData.length}
                        next={() => {
                            setPage(page + 1);
                        }}
                        hasMore={currentData.length >= page * 3}
                        loader={ loading ? 
                            <div className="d-flex justify-content-center w-100">
                                <Spinner animation="border" style={{ color: Colors.primary }} />
                            </div> : <></>
                        }
                        className="item-info-list-box">
                        {currentData.length > 0 && currentData.map((item: trailData, i: number) =>
                            <MountainWalkCard id={item?.id} title={item?.title} key={item?.id + "-drive-" + i+filter}
                                description={item?.short_description}
                                distance={item?.length}
                                elevation={item?.elevation}
                                grade={item?.filter.replace("Grade", "").trim()}
                                image={item?.trail_image}  
                                time={item?.time}
                                link="/mountain-bike-detail" />
                        )}
                    </InfiniteScroll>
                    {loading && !(currentData.length >= page * 3) && (
                        <div className="d-flex justify-content-center w-100">
                            <Spinner animation="border" style={{ color: Colors.primary }} />
                        </div>
                    )}
                    {!loading && currentData.length <= 0 && (
                        <div className="no-result-box">
                            <span>No results</span>
                        </div>
                    )}
                </div>
            </section> */}

        </div>
    )
}


function mapStateToProps(state: RootState) {
    const { trails, pages } = state;
    return { trailsList: trails.MountainBikeData || {}, loading: trails.loading, pageData: pages.mountainBikePageData, pageLoading: pages.pageLoading }
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine)
        dispatch(getPageData(PAGE_TYPES.MOUNTAIN_BIKE_PAGE))
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(MountainBike);