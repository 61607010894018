import { GeoJSON } from "leaflet";

export const worldGeoJSON: GeoJSON.FeatureCollection<any> = {
  type: "FeatureCollection",
  features: [
      
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.8923, 37.2311],
            [74.4873, 37.2259],
            [74.3661, 37.1478],
            [74.5424, 37.0217],
            [74.394, 36.994],
            [74.0943, 36.8312],
            [73.6401, 36.8966],
            [72.5652, 36.8206],
            [72.2136, 36.7264],
            [71.7363, 36.3959],
            [71.6291, 36.4595],
            [71.1709, 36.027],
            [71.3416, 35.9473],
            [71.5935, 35.5494],
            [71.6224, 35.4296],
            [71.5298, 35.3009],
            [71.6337, 35.2031],
            [71.46, 34.9427],
            [71.2895, 34.875],
            [71.0806, 34.6729],
            [70.9708, 34.4689],
            [71.122, 34.3568],
            [71.0467, 34.0419],
            [70.8622, 33.9648],
            [70.522, 33.9387],
            [70.0028, 34.0438],
            [69.8411, 33.9418],
            [69.9575, 33.7527],
            [70.1082, 33.7273],
            [70.155, 33.5066],
            [70.2944, 33.3189],
            [69.8809, 33.0892],
            [69.5475, 33.075],
            [69.4291, 32.6674],
            [69.228, 32.4213],
            [69.2988, 31.9377],
            [69.004, 31.6511],
            [68.7773, 31.6186],
            [68.6758, 31.7747],
            [68.5275, 31.823],
            [68.3561, 31.7625],
            [68.1257, 31.8115],
            [68.0466, 31.6884],
            [67.8707, 31.6352],
            [67.597, 31.4257],
            [67.7494, 31.328],
            [67.3462, 31.2078],
            [67.0229, 31.2394],
            [66.9433, 31.3147],
            [66.697, 31.1958],
            [66.55, 30.977],
            [66.3663, 30.9229],
            [66.268, 30.6014],
            [66.3218, 30.4374],
            [66.3009, 30.2256],
            [66.2252, 30.0444],
            [66.3405, 29.9566],
            [66.1956, 29.8353],
            [65.0364, 29.5402],
            [64.4777, 29.5704],
            [64.1726, 29.4843],
            [64.0861, 29.3866],
            [63.5686, 29.4975],
            [62.4775, 29.4078],
            [61.6134, 29.6385],
            [60.8444, 29.8582],
            [61.7852, 30.8314],
            [61.8263, 31.0346],
            [61.7492, 31.3024],
            [61.6612, 31.3819],
            [60.855, 31.4827],
            [60.7926, 31.6601],
            [60.7749, 32.0272],
            [60.8303, 32.2489],
            [60.5775, 32.9943],
            [60.5676, 33.1513],
            [60.8186, 33.4049],
            [60.8796, 33.5487],
            [60.6556, 33.5599],
            [60.5118, 33.6384],
            [60.528, 33.8414],
            [60.4868, 34.0943],
            [60.6437, 34.3066],
            [60.8905, 34.3189],
            [60.7144, 34.5374],
            [60.9475, 34.638],
            [61.0655, 34.8147],
            [61.1159, 35.0599],
            [61.1083, 35.278],
            [61.29, 35.5481],
            [61.2697, 35.6185],
            [61.5387, 35.4523],
            [62.0338, 35.424],
            [62.219, 35.3063],
            [62.6211, 35.2227],
            [63.0802, 35.4371],
            [63.0766, 35.6247],
            [63.1839, 35.7123],
            [63.1253, 35.8602],
            [63.3429, 35.8563],
            [63.6023, 35.9627],
            [63.7659, 35.9773],
            [64.2661, 36.1525],
            [64.4446, 36.25],
            [64.6059, 36.4611],
            [64.592, 36.6211],
            [64.7788, 36.9385],
            [64.7602, 37.0926],
            [65.0631, 37.2332],
            [65.5014, 37.2423],
            [65.6241, 37.3451],
            [65.6688, 37.5206],
            [66.0797, 37.4409],
            [66.2944, 37.3312],
            [66.5196, 37.3642],
            [67.0059, 37.3844],
            [67.2368, 37.1924],
            [67.4824, 37.2774],
            [67.7805, 37.1889],
            [68.0111, 36.9309],
            [68.3073, 37.1142],
            [69.0055, 37.3055],
            [69.1236, 37.1692],
            [69.3239, 37.121],
            [69.4452, 37.2364],
            [69.3784, 37.4374],
            [69.5287, 37.586],
            [70.1579, 37.5414],
            [70.2831, 37.7048],
            [70.1652, 37.8899],
            [70.4826, 38.1374],
            [70.5958, 38.3382],
            [70.7611, 38.4435],
            [70.974, 38.4737],
            [71.3344, 38.2807],
            [71.3645, 38.2068],
            [71.2558, 37.9499],
            [71.3412, 37.8933],
            [71.5977, 37.8984],
            [71.4972, 37.5665],
            [71.487, 37.2671],
            [71.4311, 37.067],
            [71.6111, 36.7048],
            [71.8366, 36.6992],
            [72.2598, 36.9673],
            [72.6577, 37.0288],
            [72.761, 37.1875],
            [73.0675, 37.3151],
            [73.2761, 37.4595],
            [73.4853, 37.481],
            [73.7535, 37.4284],
            [73.7982, 37.2285],
            [74.1633, 37.3301],
            [74.2239, 37.4034],
            [74.6604, 37.394],
            [74.8923, 37.2311],
          ],
        ],
      },
      properties: {
        NAME: "Afghanistan",
        NAME_LONG: "Afghanistan",
        ADM0_A3: "AFG",
        ISO_A2: "AF",
        ISO_A3: "AFG",
        WB_A2: "AF",
        WB_A3: "AFG",
      },
      id: 1,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [23.9675, -10.8723],
              [24.0112, -11.2729],
              [24.0611, -11.395],
              [23.9599, -11.6172],
              [23.9596, -12.1967],
              [24.0308, -12.3851],
              [23.9287, -12.5616],
              [23.8657, -12.7897],
              [24.0006, -13.0015],
              [23.2227, -13.0015],
              [21.9799, -13.0015],
              [21.9808, -14.3576],
              [21.9813, -15.1112],
              [21.9838, -16.1659],
              [22.1517, -16.5977],
              [22.3337, -16.6737],
              [22.5229, -16.9147],
              [22.7105, -17.0554],
              [22.7559, -17.1549],
              [22.9986, -17.2938],
              [23.073, -17.4051],
              [23.2908, -17.5354],
              [23.3817, -17.6411],
              [22.2305, -17.8576],
              [21.3812, -18.0126],
              [21.2166, -17.9306],
              [20.8062, -18.0314],
              [20.5655, -17.9852],
              [20.3362, -17.8549],
              [20.0343, -17.8959],
              [19.6726, -17.8427],
              [19.4213, -17.8594],
              [19.1721, -17.8012],
              [19.0206, -17.8223],
              [18.762, -17.7477],
              [18.4536, -17.3899],
              [17.6226, -17.389],
              [16.3398, -17.3887],
              [15.0572, -17.3883],
              [14.2074, -17.388],
              [13.9427, -17.4082],
              [13.8844, -17.3385],
              [13.5213, -17.1219],
              [13.3637, -16.9642],
              [13.1663, -16.9511],
              [12.8872, -17.0298],
              [12.5546, -17.2356],
              [12.2426, -17.2248],
              [12.0953, -17.1397],
              [11.7661, -17.2527],
              [11.7593, -17.033],
              [11.8205, -16.7815],
              [11.822, -16.4791],
              [11.7366, -15.9002],
              [11.992, -15.6173],
              [12.0564, -15.2331],
              [12.2078, -14.8333],
              [12.2727, -14.7506],
              [12.3313, -14.4335],
              [12.3288, -14.0922],
              [12.4155, -13.8786],
              [12.5025, -13.8552],
              [12.5127, -13.4421],
              [12.6345, -13.3434],
              [12.9448, -12.9824],
              [12.9395, -12.8167],
              [13.1786, -12.6017],
              [13.364, -12.5927],
              [13.5056, -12.3714],
              [13.6398, -12.2487],
              [13.7946, -11.7702],
              [13.7942, -11.3415],
              [13.8465, -11.1137],
              [13.8286, -10.9137],
              [13.7393, -10.7834],
              [13.7529, -10.6462],
              [13.5226, -10.3878],
              [13.509, -10.277],
              [13.3174, -9.9763],
              [13.3272, -9.886],
              [13.1986, -9.7087],
              [13.1707, -9.4025],
              [12.9963, -9.0938],
              [13.2122, -8.8149],
              [13.3801, -8.7551],
              [13.4061, -8.6371],
              [13.3421, -8.4675],
              [13.3694, -8.3235],
              [13.2769, -8.1789],
              [12.85, -7.2659],
              [12.8152, -6.947],
              [12.6204, -6.7348],
              [12.2794, -6.1477],
              [12.6455, -6.029],
              [12.9276, -5.9812],
              [12.9859, -5.9011],
              [13.1838, -5.8565],
              [13.6415, -5.8658],
              [13.9436, -5.8416],
              [14.0212, -5.8725],
              [14.5766, -5.9047],
              [14.7037, -5.8814],
              [16.3157, -5.8546],
              [16.5974, -5.9247],
              [16.6043, -6.0619],
              [16.7268, -6.1892],
              [16.7308, -6.5698],
              [16.8329, -6.8278],
              [16.927, -6.8886],
              [16.9271, -7.0857],
              [16.9961, -7.298],
              [17.0981, -7.4023],
              [17.2889, -7.6995],
              [17.5365, -8.0151],
              [17.5443, -8.0848],
              [17.9014, -8.0481],
              [18.0982, -8.1093],
              [18.1766, -7.9992],
              [18.5076, -7.9975],
              [18.7385, -7.9323],
              [18.7738, -8.002],
              [19.3555, -8.002],
              [19.3338, -7.8673],
              [19.4247, -7.5797],
              [19.5132, -7.4794],
              [19.4764, -7.3159],
              [19.5218, -7.0019],
              [20.2943, -7.0019],
              [20.3311, -6.9168],
              [20.6115, -6.916],
              [20.5245, -7.1431],
              [20.5205, -7.2864],
              [21.785, -7.2834],
              [21.8424, -7.3699],
              [21.8541, -7.586],
              [21.7705, -7.8068],
              [21.7585, -8.0151],
              [21.8512, -8.1435],
              [21.936, -8.413],
              [21.8599, -8.8464],
              [21.8411, -9.2758],
              [21.7933, -9.3935],
              [21.8541, -9.6178],
              [21.9984, -9.8091],
              [22.1599, -9.9315],
              [22.2028, -10.1306],
              [22.3134, -10.3686],
              [22.274, -10.503],
              [22.3224, -10.7422],
              [22.1655, -10.8524],
              [22.2818, -11.2367],
              [22.5376, -11.0372],
              [22.7277, -11.0968],
              [22.8292, -11.0521],
              [23.0143, -11.1025],
              [23.4948, -10.9643],
              [23.8337, -11.0285],
              [23.9675, -10.8723],
            ],
          ],
          [
            [
              [13.0737, -4.6353],
              [12.8332, -4.7227],
              [12.6789, -4.9243],
              [12.4444, -5.0551],
              [12.5307, -5.1627],
              [12.509, -5.7263],
              [12.2105, -5.7634],
              [12.1472, -5.6179],
              [12.2322, -5.4796],
              [12.1246, -5.1848],
              [12.1325, -5.0059],
              [12.0096, -5.0196],
              [12.2352, -4.804],
              [12.3324, -4.7659],
              [12.3776, -4.6194],
              [12.6081, -4.565],
              [12.7617, -4.3912],
              [13.0737, -4.6353],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Angola",
        NAME_LONG: "Angola",
        ADM0_A3: "AGO",
        ISO_A2: "AO",
        ISO_A3: "AGO",
        WB_A2: "AO",
        WB_A3: "AGO",
      },
      id: 2,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.065, 42.5468],
            [20.2496, 42.3186],
            [20.5008, 42.2112],
            [20.5993, 41.9479],
            [20.5671, 41.8732],
            [20.4442, 41.5497],
            [20.5402, 41.4007],
            [20.4777, 41.3196],
            [20.7027, 40.9363],
            [20.9653, 40.8494],
            [21.0367, 40.6397],
            [20.8267, 40.4648],
            [20.6402, 40.0901],
            [20.4328, 40.0638],
            [20.318, 39.9184],
            [20.2995, 39.7282],
            [20.1999, 39.6401],
            [19.9998, 39.6935],
            [20.0163, 39.845],
            [19.8552, 40.0499],
            [19.4762, 40.2137],
            [19.4788, 40.4539],
            [19.3098, 40.6444],
            [19.4142, 40.8688],
            [19.5166, 41.2564],
            [19.4142, 41.3828],
            [19.5652, 41.5851],
            [19.5852, 41.8185],
            [19.3651, 41.8524],
            [19.3749, 42.0947],
            [19.272, 42.1807],
            [19.605, 42.5848],
            [19.8196, 42.4665],
            [20.065, 42.5468],
          ],
        ],
      },
      properties: {
        NAME: "Albania",
        NAME_LONG: "Albania",
        ADM0_A3: "ALB",
        ISO_A2: "AL",
        ISO_A3: "ALB",
        WB_A2: "AL",
        WB_A3: "ALB",
      },
      id: 4,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.9185, 60.3702],
            [20.2776, 60.2787],
            [20.0064, 60.0944],
            [19.7476, 60.1014],
            [19.9185, 60.3702],
          ],
        ],
      },
      properties: {
        NAME: "Aland",
        NAME_LONG: "Aland Islands",
        ADM0_A3: "ALD",
        ISO_A2: "AX",
        ISO_A3: "ALA",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 5,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.707, 42.5028],
            [1.4479, 42.4346],
            [1.4293, 42.5954],
            [1.722, 42.6099],
            [1.707, 42.5028],
          ],
        ],
      },
      properties: {
        NAME: "Andorra",
        NAME_LONG: "Andorra",
        ADM0_A3: "AND",
        ISO_A2: "AD",
        ISO_A3: "AND",
        WB_A2: "AD",
        WB_A3: "ADO",
      },
      id: 6,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [56.2791, 25.6274],
            [56.37, 25.5257],
            [56.3833, 24.9782],
            [56.1183, 24.7354],
            [56.0315, 24.7541],
            [56.0056, 24.9562],
            [55.7887, 24.8552],
            [55.8182, 24.6434],
            [55.7598, 24.5585],
            [55.8074, 24.3408],
            [55.9979, 24.0813],
            [55.8071, 24.0075],
            [55.4998, 23.9706],
            [55.5383, 23.77],
            [55.3753, 23.4693],
            [55.1913, 22.9954],
            [55.1868, 22.7036],
            [55.1053, 22.6209],
            [54.1343, 22.7403],
            [52.5831, 22.9311],
            [52.5385, 22.9547],
            [51.5935, 24.0783],
            [51.5693, 24.2562],
            [51.772, 24.1763],
            [51.8094, 24.0007],
            [52.2244, 23.974],
            [52.3411, 24.0075],
            [52.6341, 24.1899],
            [52.6701, 24.1447],
            [53.2538, 24.0988],
            [53.437, 24.1198],
            [53.5813, 24.0485],
            [53.8538, 24.062],
            [54.1226, 24.1447],
            [54.446, 24.3161],
            [54.5586, 24.4577],
            [54.707, 24.8039],
            [55.0823, 25.0168],
            [55.5247, 25.5426],
            [55.8425, 25.7138],
            [56.0144, 25.8789],
            [56.0774, 26.061],
            [56.1835, 26.0145],
            [56.1451, 25.6708],
            [56.2791, 25.6274],
          ],
        ],
      },
      properties: {
        NAME: "United Arab Emirates",
        NAME_LONG: "United Arab Emirates",
        ADM0_A3: "ARE",
        ISO_A2: "AE",
        ISO_A3: "ARE",
        WB_A2: "AE",
        WB_A3: "ARE",
      },
      id: 7,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-64.3406, -54.716],
              [-64.6072, -54.9006],
              [-64.7587, -54.8323],
              [-64.3406, -54.716],
            ],
          ],
          [
            [
              [-68.6419, -54.783],
              [-68.6347, -53.7109],
              [-68.6276, -52.6396],
              [-68.266, -52.9806],
              [-68.5207, -53.1206],
              [-68.4514, -53.2977],
              [-68.1114, -53.3438],
              [-67.9836, -53.6013],
              [-67.5582, -53.8362],
              [-67.5715, -53.908],
              [-67.2978, -54.0538],
              [-66.7666, -54.2498],
              [-66.4822, -54.4658],
              [-65.8442, -54.6474],
              [-65.1556, -54.6406],
              [-65.3323, -54.9138],
              [-65.9662, -54.9021],
              [-65.9934, -54.9724],
              [-66.5405, -55.051],
              [-67.031, -54.9052],
              [-68.0776, -54.8461],
              [-68.5203, -54.8522],
              [-68.6419, -54.783],
            ],
          ],
          [
            [
              [-62.6504, -22.2345],
              [-62.6327, -22.296],
              [-62.2872, -22.4839],
              [-62.1708, -22.7173],
              [-61.9564, -23.0344],
              [-61.7443, -23.2348],
              [-61.4918, -23.4132],
              [-61.297, -23.4814],
              [-61.1099, -23.607],
              [-61.0063, -23.8055],
              [-60.5936, -23.9122],
              [-60.328, -24.018],
              [-60.0337, -24.007],
              [-59.4597, -24.3585],
              [-59.341, -24.4876],
              [-59.0009, -24.6442],
              [-58.8092, -24.7768],
              [-58.4734, -24.8513],
              [-58.312, -24.9936],
              [-58.2241, -24.9412],
              [-57.7541, -25.1809],
              [-57.5569, -25.4598],
              [-57.6231, -25.6154],
              [-57.7537, -25.6727],
              [-57.8754, -25.8761],
              [-57.8727, -26.0103],
              [-58.0865, -26.1272],
              [-58.2062, -26.4027],
              [-58.2479, -26.7582],
              [-58.4598, -26.9282],
              [-58.5115, -27.0602],
              [-58.6533, -27.1563],
              [-58.5108, -27.2784],
              [-58.022, -27.2598],
              [-57.6978, -27.3335],
              [-57.5357, -27.4072],
              [-57.3352, -27.4095],
              [-57.1801, -27.4873],
              [-56.9044, -27.4187],
              [-56.7712, -27.5067],
              [-56.5464, -27.4551],
              [-56.3675, -27.5806],
              [-56.2798, -27.3896],
              [-56.1246, -27.2989],
              [-55.9131, -27.3278],
              [-55.7547, -27.4437],
              [-55.5913, -27.3284],
              [-55.599, -27.1826],
              [-55.414, -26.9799],
              [-54.9753, -26.7878],
              [-54.7065, -26.4418],
              [-54.6383, -26.197],
              [-54.6623, -25.9799],
              [-54.5879, -25.8108],
              [-54.6002, -25.5749],
              [-54.4606, -25.6421],
              [-54.2148, -25.5315],
              [-53.91, -25.6292],
              [-53.8262, -25.9727],
              [-53.6667, -26.2192],
              [-53.7244, -26.3761],
              [-53.746, -26.6939],
              [-53.7107, -26.8738],
              [-53.8192, -27.1397],
              [-54.1895, -27.2609],
              [-54.2869, -27.4284],
              [-54.4608, -27.4126],
              [-54.6997, -27.553],
              [-54.8275, -27.5451],
              [-54.9132, -27.7369],
              [-55.0258, -27.8402],
              [-55.3299, -27.9281],
              [-55.4407, -28.0789],
              [-55.6047, -28.1169],
              [-55.7725, -28.232],
              [-55.8981, -28.4607],
              [-56.1847, -28.7442],
              [-56.4158, -29.0514],
              [-56.6173, -29.1609],
              [-56.677, -29.3134],
              [-57.0942, -29.752],
              [-57.3085, -29.8485],
              [-57.3252, -29.981],
              [-57.6117, -30.183],
              [-57.6527, -30.3291],
              [-57.8496, -30.4853],
              [-57.8072, -30.9076],
              [-57.9051, -31.241],
              [-58.0752, -31.4752],
              [-57.9868, -31.5541],
              [-58.0593, -31.8115],
              [-58.1686, -31.846],
              [-58.1865, -32.1529],
              [-58.1016, -32.3114],
              [-58.2001, -32.4471],
              [-58.2228, -32.5343],
              [-58.1468, -33.05],
              [-58.3832, -33.0755],
              [-58.4123, -33.2983],
              [-58.5217, -33.4493],
              [-58.5494, -33.683],
              [-58.3954, -34.0312],
              [-58.3786, -34.1882],
              [-58.5115, -34.4359],
              [-58.4742, -34.5216],
              [-58.1549, -34.7504],
              [-57.871, -34.8299],
              [-57.522, -35.0132],
              [-57.2488, -35.2488],
              [-57.1449, -35.4842],
              [-57.3526, -35.7275],
              [-57.3664, -35.9879],
              [-57.248, -36.1703],
              [-56.9287, -36.3637],
              [-56.6976, -36.3962],
              [-56.6649, -36.851],
              [-57.0559, -37.4127],
              [-57.5119, -37.8723],
              [-57.5936, -38.1528],
              [-58.1557, -38.4301],
              [-59.0632, -38.6938],
              [-59.8893, -38.8385],
              [-60.8657, -38.9757],
              [-61.5181, -39.0115],
              [-62.0267, -38.9376],
              [-62.1573, -38.8105],
              [-62.3578, -39.1022],
              [-62.3164, -39.2652],
              [-62.0558, -39.4519],
              [-62.1642, -39.8588],
              [-62.3146, -39.8694],
              [-62.3687, -40.2112],
              [-62.4842, -40.2875],
              [-62.4149, -40.4621],
              [-62.2506, -40.6222],
              [-62.3378, -40.8727],
              [-62.7294, -41.0411],
              [-63.0995, -41.1549],
              [-63.7794, -41.1588],
              [-64.0621, -40.9994],
              [-64.1743, -41.009],
              [-64.773, -40.8061],
              [-64.9465, -40.7114],
              [-65.1219, -40.8337],
              [-65.1751, -41.0102],
              [-65.0612, -41.4484],
              [-64.9933, -41.553],
              [-64.9999, -41.7934],
              [-65.0696, -41.9858],
              [-64.8573, -42.1936],
              [-64.5315, -42.2442],
              [-64.5663, -42.436],
              [-64.1276, -42.4327],
              [-64.0684, -42.2696],
              [-64.1622, -42.2094],
              [-63.81, -42.0702],
              [-63.5905, -42.3412],
              [-63.6203, -42.7512],
              [-63.7366, -42.8248],
              [-64.1079, -42.8837],
              [-64.251, -42.7521],
              [-64.3226, -42.5424],
              [-64.6149, -42.5153],
              [-64.946, -42.6542],
              [-64.9971, -42.7848],
              [-64.5131, -42.9363],
              [-64.4476, -43.0686],
              [-64.7652, -43.1474],
              [-65.0323, -43.2994],
              [-65.0323, -43.3781],
              [-65.3341, -43.6726],
              [-65.2224, -44.0369],
              [-65.3087, -44.2001],
              [-65.2169, -44.3664],
              [-65.3257, -44.5083],
              [-65.6855, -44.7125],
              [-65.7154, -44.87],
              [-65.6026, -45.0277],
              [-66.2004, -44.9929],
              [-66.4677, -45.077],
              [-66.524, -45.2161],
              [-66.8689, -45.2356],
              [-67.0648, -45.3499],
              [-67.3319, -45.6135],
              [-67.3606, -45.7767],
              [-67.5477, -45.9334],
              [-67.6225, -46.1638],
              [-67.4232, -46.5674],
              [-67.13, -46.7066],
              [-66.7864, -47.0066],
              [-66.2005, -47.0896],
              [-65.9796, -47.0662],
              [-65.7449, -47.2042],
              [-65.7357, -47.5095],
              [-65.8456, -47.7422],
              [-65.8471, -47.9444],
              [-66.3009, -48.2519],
              [-66.4685, -48.4003],
              [-66.651, -48.4311],
              [-67.1143, -48.6738],
              [-67.1983, -48.8184],
              [-67.5565, -49.0158],
              [-67.7042, -49.3039],
              [-67.6533, -49.3771],
              [-67.732, -49.7813],
              [-67.8332, -49.9545],
              [-68.0764, -50.0873],
              [-68.8764, -50.3306],
              [-69.0765, -50.5602],
              [-69.1678, -50.9782],
              [-68.9359, -51.6455],
              [-68.6851, -52.005],
              [-68.3656, -52.3062],
              [-68.4484, -52.3469],
              [-69.2123, -52.138],
              [-69.4853, -52.1325],
              [-69.9528, -52.0074],
              [-71.9177, -51.9901],
              [-71.9585, -51.868],
              [-72.2811, -51.7015],
              [-72.3515, -51.5835],
              [-72.3134, -51.298],
              [-72.3822, -51.1606],
              [-72.2635, -50.8363],
              [-72.3028, -50.6489],
              [-72.506, -50.6013],
              [-72.7784, -50.6196],
              [-73.0961, -50.7706],
              [-73.2619, -50.5566],
              [-73.3229, -50.2684],
              [-73.5306, -50.1408],
              [-73.5727, -49.9324],
              [-73.4651, -49.76],
              [-73.5866, -49.53],
              [-73.4579, -49.3077],
              [-73.145, -49.3037],
              [-73.1713, -49.1893],
              [-73.0097, -48.9904],
              [-72.7429, -48.9138],
              [-72.5923, -48.7911],
              [-72.5768, -48.4521],
              [-72.3256, -48.2855],
              [-72.343, -48.0704],
              [-72.5439, -47.9148],
              [-72.3444, -47.6023],
              [-72.3287, -47.4265],
              [-72.1706, -47.4075],
              [-72.0098, -47.1982],
              [-71.9341, -46.7995],
              [-71.6872, -46.6901],
              [-71.7702, -46.1126],
              [-71.6123, -45.9705],
              [-71.7985, -45.7399],
              [-71.7651, -45.5724],
              [-71.5258, -45.519],
              [-71.5083, -45.4084],
              [-71.3115, -45.2995],
              [-71.5887, -44.9781],
              [-72.0809, -44.8866],
              [-72.0478, -44.7548],
              [-71.8545, -44.791],
              [-71.4971, -44.7429],
              [-71.336, -44.8016],
              [-71.1227, -44.5303],
              [-71.2098, -44.4276],
              [-71.822, -44.4032],
              [-71.8587, -44.1078],
              [-71.6599, -43.9263],
              [-71.8192, -43.7581],
              [-71.7238, -43.5948],
              [-71.9373, -43.4569],
              [-71.9017, -43.322],
              [-71.743, -43.1901],
              [-72.0098, -43.1204],
              [-72.1485, -42.9987],
              [-72.1127, -42.8638],
              [-72.1484, -42.5926],
              [-72.0419, -42.491],
              [-72.1247, -42.2633],
              [-71.7363, -42.0839],
              [-71.794, -41.8675],
              [-71.926, -41.6229],
              [-71.8533, -41.5673],
              [-71.904, -41.3677],
              [-71.8514, -40.9383],
              [-71.9556, -40.7204],
              [-71.8042, -40.4264],
              [-71.6785, -40.3402],
              [-71.8238, -40.2101],
              [-71.6837, -40.099],
              [-71.6248, -39.931],
              [-71.7187, -39.6234],
              [-71.52, -39.615],
              [-71.402, -39.236],
              [-71.4158, -38.9353],
              [-71.228, -38.8088],
              [-70.8738, -38.6914],
              [-70.8487, -38.5276],
              [-70.9738, -38.4247],
              [-71.01, -38.0655],
              [-71.185, -37.7061],
              [-71.1231, -37.4432],
              [-71.1453, -36.6883],
              [-71.0433, -36.4843],
              [-70.8904, -36.4004],
              [-70.7187, -36.4146],
              [-70.7031, -36.2601],
              [-70.3803, -36.046],
              [-70.4202, -35.8684],
              [-70.4082, -35.5062],
              [-70.5429, -35.2093],
              [-70.3866, -35.1667],
              [-70.3537, -34.9533],
              [-70.2278, -34.5853],
              [-70.0587, -34.3958],
              [-70.0403, -34.277],
              [-69.8328, -34.2432],
              [-69.9096, -33.9557],
              [-69.8954, -33.6623],
              [-69.7877, -33.3794],
              [-69.9053, -33.2382],
              [-70.1008, -33.1869],
              [-70.1112, -33.0384],
              [-70.0002, -32.8766],
              [-70.14, -32.7683],
              [-70.1725, -32.4649],
              [-70.3218, -32.2801],
              [-70.3352, -32.14],
              [-70.2417, -31.9615],
              [-70.4283, -31.8701],
              [-70.5914, -31.5496],
              [-70.569, -31.3042],
              [-70.4798, -31.0967],
              [-70.3398, -31.0417],
              [-70.3195, -30.8008],
              [-70.2171, -30.5151],
              [-69.9028, -30.3127],
              [-69.8497, -30.1266],
              [-69.9756, -30.0586],
              [-69.9222, -29.9396],
              [-69.9291, -29.7183],
              [-70.0426, -29.3631],
              [-69.9109, -29.1432],
              [-69.8039, -29.0987],
              [-69.7358, -28.8124],
              [-69.7409, -28.6406],
              [-69.653, -28.3979],
              [-69.4203, -28.2126],
              [-69.3032, -27.9995],
              [-69.173, -27.9241],
              [-69.1243, -27.7344],
              [-68.8011, -27.1128],
              [-68.5859, -27.1628],
              [-68.518, -27.0769],
              [-68.3305, -27.045],
              [-68.3201, -26.8701],
              [-68.5702, -26.5506],
              [-68.5752, -26.3035],
              [-68.4199, -26.1793],
              [-68.4985, -25.7549],
              [-68.606, -25.437],
              [-68.516, -25.1836],
              [-68.3674, -25.1034],
              [-68.5724, -24.7699],
              [-68.5037, -24.6123],
              [-68.2445, -24.3854],
              [-67.3624, -24.0304],
              [-67.3398, -24.0006],
              [-67.014, -23.0007],
              [-67.1939, -22.8222],
              [-67.0377, -22.6546],
              [-67.0327, -22.5246],
              [-66.7965, -22.4349],
              [-66.7359, -22.2251],
              [-66.3775, -22.1271],
              [-66.2972, -22.0503],
              [-66.24, -21.7924],
              [-66.0945, -21.8329],
              [-65.7446, -22.114],
              [-65.0204, -22.0966],
              [-64.5869, -22.2128],
              [-64.572, -22.3432],
              [-64.294, -22.6909],
              [-63.9476, -22.0076],
              [-63.7404, -22.0506],
              [-63.6394, -21.9975],
              [-62.8044, -22.0041],
              [-62.7909, -22.1132],
              [-62.6504, -22.2345],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Argentina",
        NAME_LONG: "Argentina",
        ADM0_A3: "ARG",
        ISO_A2: "AR",
        ISO_A3: "ARG",
        WB_A2: "AR",
        WB_A3: "ARG",
      },
      id: 8,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [45.0024, 41.2905],
            [45.1868, 41.1569],
            [45.2216, 41.0144],
            [45.4164, 40.9613],
            [45.5559, 40.7766],
            [45.3736, 40.6753],
            [45.5262, 40.441],
            [45.9792, 40.2236],
            [45.8738, 40.0098],
            [45.6396, 40.0254],
            [45.8619, 39.8045],
            [46.2065, 39.5879],
            [46.3437, 39.6186],
            [46.5273, 39.5382],
            [46.3746, 39.3788],
            [46.5979, 39.2251],
            [46.4793, 39.1148],
            [46.514, 38.8822],
            [46.3919, 38.9224],
            [46.1359, 38.8637],
            [45.9581, 39.1515],
            [45.9588, 39.2724],
            [45.767, 39.3544],
            [45.7881, 39.549],
            [45.4575, 39.4893],
            [45.1679, 39.5655],
            [45.0335, 39.7704],
            [44.7746, 39.7028],
            [44.4643, 39.9723],
            [44.2553, 40.0434],
            [44.0324, 40.0086],
            [43.7214, 40.1694],
            [43.5942, 40.3454],
            [43.7294, 40.6771],
            [43.6049, 40.9633],
            [43.4404, 41.1066],
            [43.7297, 41.1067],
            [44.1247, 41.1781],
            [44.191, 41.23],
            [44.5515, 41.177],
            [45.0024, 41.2905],
          ],
        ],
      },
      properties: {
        NAME: "Armenia",
        NAME_LONG: "Armenia",
        ADM0_A3: "ARM",
        ISO_A2: "AM",
        ISO_A3: "ARM",
        WB_A2: "AM",
        WB_A3: "ARM",
      },
      id: 9,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.9829, -48.7985],
            [69.1637, -48.8461],
            [69.0838, -48.9734],
            [69.2145, -49.1213],
            [69.438, -49.0146],
            [69.4959, -49.2397],
            [69.7123, -49.3068],
            [69.8538, -49.2091],
            [70.273, -49.0764],
            [70.522, -49.0959],
            [70.5685, -49.2194],
            [70.4639, -49.3566],
            [70.1399, -49.3388],
            [69.8138, -49.5325],
            [70.0653, -49.5735],
            [70.1232, -49.7136],
            [69.4697, -49.6314],
            [69.3734, -49.5661],
            [69.1145, -49.6031],
            [68.8317, -49.7172],
            [68.7782, -49.5925],
            [68.9017, -49.4311],
            [68.7555, -49.0476],
            [68.9829, -48.7985],
          ],
        ],
      },
      properties: {
        NAME: "Fr. S. Antarctic Lands",
        NAME_LONG: "French Southern and Antarctic Lands",
        ADM0_A3: "ATF",
        ISO_A2: "TF",
        ISO_A3: "ATF",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 13,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [144.7663, -40.7265],
              [144.9847, -40.7448],
              [145.7116, -40.9304],
              [145.7913, -41.017],
              [146.2144, -41.1554],
              [146.4237, -41.1662],
              [147.0176, -40.9767],
              [147.286, -40.9413],
              [147.4448, -41.0084],
              [147.5974, -40.8469],
              [147.8198, -40.8935],
              [147.9842, -40.7365],
              [148.2093, -40.8408],
              [148.3314, -41.0022],
              [148.2689, -41.1516],
              [148.3311, -41.3225],
              [148.2795, -41.4504],
              [148.3269, -41.9158],
              [148.147, -41.9993],
              [147.9834, -42.319],
              [148.006, -42.5141],
              [147.8887, -42.8195],
              [148.0047, -42.9089],
              [147.7925, -43.2441],
              [147.6478, -43.0728],
              [147.8871, -43.0344],
              [147.8403, -42.902],
              [147.5856, -42.8212],
              [147.3602, -42.9252],
              [147.2576, -43.1144],
              [147.2576, -43.2543],
              [147.0998, -43.2994],
              [146.936, -43.4952],
              [146.927, -43.6117],
              [146.7107, -43.629],
              [146.5135, -43.5339],
              [146.0308, -43.4827],
              [146.0254, -43.3249],
              [145.8733, -43.2584],
              [145.5823, -42.9636],
              [145.5103, -42.9584],
              [145.3527, -42.6508],
              [145.2642, -42.5953],
              [145.2024, -42.2475],
              [145.2769, -42.1821],
              [145.2066, -41.9666],
              [144.926, -41.7091],
              [144.6058, -40.9965],
              [144.7663, -40.7265],
            ],
          ],
          [
            [
              [148.4002, -40.3617],
              [148.082, -40.4543],
              [148.0741, -40.3512],
              [148.4002, -40.3617],
            ],
          ],
          [
            [
              [147.9533, -39.7117],
              [148.167, -39.9356],
              [148.299, -39.9618],
              [148.3074, -40.2377],
              [148.0747, -40.2551],
              [147.9092, -39.9762],
              [147.7577, -39.8793],
              [147.9533, -39.7117],
            ],
          ],
          [
            [
              [144.0715, -40.0512],
              [143.9225, -40.1274],
              [143.8381, -39.9345],
              [143.862, -39.7006],
              [144.0842, -39.6262],
              [144.1448, -39.9264],
              [144.0715, -40.0512],
            ],
          ],
          [
            [
              [137.6229, -35.5821],
              [137.5808, -35.7268],
              [138.0647, -35.761],
              [138.0477, -35.9186],
              [137.7567, -35.8715],
              [137.4592, -36.0827],
              [137.2117, -35.9802],
              [136.7156, -36.0628],
              [136.5326, -35.9119],
              [136.6211, -35.7512],
              [137.0745, -35.6716],
              [137.3522, -35.5856],
              [137.6229, -35.5821],
            ],
          ],
          [
            [
              [112.9595, -25.4825],
              [113.1375, -25.8859],
              [113.0462, -25.94],
              [112.9196, -25.6316],
              [112.9595, -25.4825],
            ],
          ],
          [
            [
              [153.2861, -24.7365],
              [153.3023, -24.9295],
              [153.3735, -25.0118],
              [153.0835, -25.6658],
              [152.9483, -25.5522],
              [153.0685, -25.329],
              [153.0383, -25.1835],
              [153.2261, -25.0275],
              [153.1633, -24.8124],
              [153.2861, -24.7365],
            ],
          ],
          [
            [
              [151.0369, -23.4509],
              [151.1914, -23.5138],
              [151.2976, -23.7482],
              [151.2139, -23.7781],
              [150.9796, -23.4874],
              [151.0369, -23.4509],
            ],
          ],
          [
            [
              [139.6973, -16.4477],
              [139.6105, -16.5532],
              [139.3021, -16.7245],
              [139.137, -16.6972],
              [139.2879, -16.4706],
              [139.6038, -16.3984],
              [139.6973, -16.4477],
            ],
          ],
          [
            [
              [136.7241, -13.6872],
              [136.7914, -13.9177],
              [136.7168, -14.0637],
              [136.9031, -14.2989],
              [136.6394, -14.2802],
              [136.4608, -14.2164],
              [136.4302, -13.8771],
              [136.5947, -13.8123],
              [136.7241, -13.6872],
            ],
          ],
          [
            [
              [130.3865, -11.4685],
              [130.4617, -11.5921],
              [130.448, -11.6945],
              [130.5486, -11.7014],
              [130.6282, -11.8046],
              [130.4963, -11.839],
              [130.3211, -11.7702],
              [130.0535, -11.8228],
              [130.0643, -11.6913],
              [130.2015, -11.6535],
              [130.147, -11.489],
              [130.2698, -11.3381],
              [130.3865, -11.4685],
            ],
          ],
          [
            [
              [130.5065, -11.277],
              [130.6917, -11.4228],
              [130.888, -11.3132],
              [131.0098, -11.3549],
              [131.2744, -11.1896],
              [131.4277, -11.2497],
              [131.4746, -11.3906],
              [131.4324, -11.607],
              [130.98, -11.9264],
              [130.7126, -11.7995],
              [130.4966, -11.6495],
              [130.4303, -11.4843],
              [130.366, -11.2324],
              [130.5065, -11.277],
            ],
          ],
          [
            [
              [142.5496, -10.7082],
              [142.5496, -10.838],
              [142.742, -10.975],
              [142.8013, -11.2778],
              [142.8729, -11.8507],
              [142.9743, -11.9252],
              [143.2039, -11.9825],
              [143.0863, -12.149],
              [143.0772, -12.3288],
              [143.2529, -12.3889],
              [143.3235, -12.5578],
              [143.4339, -12.6131],
              [143.3651, -12.8049],
              [143.4972, -12.8565],
              [143.5266, -13.3298],
              [143.5946, -13.4358],
              [143.5344, -13.7657],
              [143.6873, -14.0143],
              [143.7827, -14.4023],
              [143.952, -14.499],
              [144.5149, -14.1728],
              [144.6776, -14.5558],
              [144.886, -14.6096],
              [144.9417, -14.7263],
              [145.2771, -14.947],
              [145.2359, -15.1659],
              [145.3026, -15.2846],
              [145.2738, -15.5357],
              [145.3664, -15.7493],
              [145.3558, -15.9034],
              [145.4624, -16.0605],
              [145.4624, -16.2732],
              [145.4004, -16.4433],
              [145.7777, -16.8853],
              [146.0711, -17.392],
              [146.0979, -17.9313],
              [146.0037, -18.1564],
              [146.0238, -18.2631],
              [146.3394, -18.527],
              [146.2766, -18.8743],
              [146.4465, -19.0597],
              [146.6548, -19.1825],
              [146.7463, -19.1728],
              [147.1672, -19.4],
              [147.4214, -19.4128],
              [147.5505, -19.5209],
              [147.5921, -19.7221],
              [147.6843, -19.8281],
              [147.8592, -19.8249],
              [148.2539, -19.9635],
              [148.2649, -20.0831],
              [148.5667, -20.06],
              [148.6109, -20.2136],
              [148.7923, -20.2873],
              [148.8508, -20.4211],
              [148.6553, -20.5554],
              [148.7398, -20.7189],
              [149.0462, -20.9799],
              [149.2312, -21.0898],
              [149.1961, -21.1809],
              [149.293, -21.3193],
              [149.4783, -21.7184],
              [149.4354, -21.7897],
              [149.6086, -22.2443],
              [149.7051, -22.3839],
              [149.8308, -22.3886],
              [150.0536, -22.1415],
              [150.2156, -22.3948],
              [150.513, -22.5354],
              [150.5676, -22.3232],
              [150.7583, -22.4824],
              [150.8264, -22.6979],
              [150.7644, -23.1589],
              [150.8566, -23.52],
              [151.0274, -23.58],
              [151.1872, -23.8041],
              [151.5806, -24.0575],
              [151.7804, -24.0226],
              [151.9334, -24.211],
              [152.0586, -24.5139],
              [152.1504, -24.6191],
              [152.4048, -24.7487],
              [152.506, -24.9994],
              [152.6771, -25.2419],
              [152.9138, -25.2877],
              [152.9386, -25.4757],
              [152.8938, -25.6882],
              [153.1216, -25.9338],
              [153.1418, -26.0615],
              [153.0775, -26.2809],
              [153.1509, -26.6847],
              [153.1525, -26.925],
              [153.2062, -27.0461],
              [153.0384, -27.1734],
              [153.1136, -27.2424],
              [153.3154, -27.6549],
              [153.4456, -27.8105],
              [153.4495, -28.0732],
              [153.5911, -28.2685],
              [153.5635, -28.4609],
              [153.6306, -28.6616],
              [153.606, -28.8674],
              [153.4538, -29.0356],
              [153.3403, -29.3227],
              [153.37, -29.3967],
              [153.3216, -29.6975],
              [153.2038, -30.1292],
              [153.0505, -30.4733],
              [152.9878, -30.7346],
              [153.0879, -30.9267],
              [152.9806, -31.1659],
              [152.9345, -31.4828],
              [152.8454, -31.5693],
              [152.6929, -31.8853],
              [152.5101, -32.1619],
              [152.5216, -32.4275],
              [152.2359, -32.607],
              [152.1895, -32.7586],
              [151.834, -32.865],
              [151.6706, -33.0611],
              [151.4242, -33.5278],
              [151.3431, -33.5483],
              [151.2925, -33.8918],
              [151.169, -34.1101],
              [150.9249, -34.3304],
              [150.8338, -34.7824],
              [150.6956, -35.0261],
              [150.7048, -35.1994],
              [150.554, -35.2136],
              [150.4094, -35.5385],
              [150.1634, -35.9566],
              [150.1423, -36.32],
              [149.9038, -36.9284],
              [150.0195, -37.2209],
              [149.951, -37.2843],
              [149.9854, -37.4959],
              [149.7871, -37.5528],
              [149.6667, -37.7001],
              [149.479, -37.7859],
              [148.9439, -37.7895],
              [148.7241, -37.8235],
              [148.3064, -37.8234],
              [147.9165, -37.9186],
              [147.5894, -38.0807],
              [146.8786, -38.6443],
              [146.4118, -38.7398],
              [146.2557, -38.7018],
              [145.9158, -38.8958],
              [145.8093, -38.6502],
              [145.5708, -38.6617],
              [145.4312, -38.4428],
              [145.5569, -38.3852],
              [145.4766, -38.2449],
              [145.2966, -38.2317],
              [145.0247, -38.4939],
              [144.8864, -38.4821],
              [145.1193, -38.1594],
              [144.9827, -37.8993],
              [144.8045, -37.9409],
              [144.5233, -38.0842],
              [144.7251, -38.1648],
              [144.6528, -38.2858],
              [144.4019, -38.3012],
              [144.0525, -38.4858],
              [143.8449, -38.6912],
              [143.537, -38.8583],
              [143.2415, -38.7628],
              [143.0601, -38.6359],
              [142.8102, -38.5784],
              [142.3977, -38.3671],
              [142.2246, -38.4057],
              [141.9468, -38.2829],
              [141.6725, -38.2843],
              [141.6014, -38.3923],
              [141.4344, -38.3751],
              [141.1343, -38.1276],
              [140.9514, -38.0543],
              [140.7136, -38.0706],
              [140.3599, -37.8821],
              [140.1143, -37.5175],
              [139.988, -37.4871],
              [139.7415, -37.1808],
              [139.672, -36.9534],
              [139.8635, -36.7673],
              [139.8537, -36.6218],
              [139.4754, -35.8964],
              [139.0414, -35.6041],
              [139.1354, -35.4983],
              [139.3755, -35.464],
              [139.2232, -35.3188],
              [138.9612, -35.4138],
              [138.882, -35.5606],
              [138.7459, -35.5388],
              [138.5313, -35.6459],
              [138.1768, -35.6682],
              [138.1227, -35.5554],
              [138.4274, -35.3526],
              [138.5073, -35.0566],
              [138.5363, -34.7409],
              [138.2599, -34.4384],
              [138.2266, -34.3205],
              [138.0378, -34.308],
              [137.9309, -34.4148],
              [137.9104, -34.624],
              [137.7469, -35.0589],
              [137.6714, -35.1808],
              [137.4035, -35.1242],
              [137.1692, -35.2423],
              [136.9534, -35.2839],
              [137.0306, -34.8998],
              [137.4461, -34.9174],
              [137.5127, -34.6178],
              [137.4595, -34.4475],
              [137.4539, -34.164],
              [137.5542, -34.072],
              [137.6021, -33.8766],
              [137.9375, -33.5278],
              [137.8104, -33.26],
              [138.044, -33.1251],
              [137.9024, -32.7875],
              [137.4522, -33.1401],
              [137.3767, -33.3899],
              [137.2249, -33.6533],
              [136.6074, -33.9029],
              [136.3694, -34.0734],
              [136.1248, -34.4529],
              [135.9368, -34.5346],
              [135.8621, -34.6982],
              [136.0125, -34.9797],
              [135.7415, -34.861],
              [135.604, -34.8801],
              [135.4295, -34.7127],
              [135.3464, -34.2756],
              [135.1898, -33.893],
              [134.8499, -33.6384],
              [134.814, -33.3528],
              [134.6624, -33.2255],
              [134.4461, -33.154],
              [134.2788, -33.1627],
              [134.0741, -32.714],
              [134.2715, -32.7321],
              [134.2733, -32.5881],
              [134.1483, -32.457],
              [133.95, -32.3894],
              [133.8123, -32.2472],
              [133.5862, -32.1031],
              [133.4118, -32.2114],
              [133.1478, -32.1967],
              [132.798, -31.9563],
              [132.5873, -31.9347],
              [132.4832, -32.0265],
              [132.2337, -32.033],
              [132.0779, -31.9078],
              [131.5359, -31.6056],
              [131.1775, -31.4776],
              [130.8338, -31.6017],
              [130.1569, -31.5739],
              [128.99, -31.6905],
              [128.6553, -31.8458],
              [128.0173, -32.0842],
              [127.7663, -32.1231],
              [127.2887, -32.2734],
              [126.6777, -32.3166],
              [126.1857, -32.2325],
              [125.87, -32.333],
              [125.5373, -32.5484],
              [125.0003, -32.7372],
              [124.7088, -32.9039],
              [124.3569, -32.9528],
              [124.1165, -33.1215],
              [123.9525, -33.5673],
              [123.5667, -33.8844],
              [123.3436, -33.9035],
              [123.147, -34.0021],
              [123.085, -33.8784],
              [122.7879, -33.881],
              [122.5603, -33.9592],
              [122.3123, -33.9249],
              [122.1873, -34.0207],
              [122.0156, -33.8254],
              [121.813, -33.8951],
              [121.5329, -33.8195],
              [121.1664, -33.8679],
              [120.5723, -33.8915],
              [120.4227, -33.9699],
              [120.0022, -33.926],
              [119.8062, -33.985],
              [119.572, -34.142],
              [119.3821, -34.4401],
              [119.117, -34.4724],
              [118.932, -34.4459],
              [118.7273, -34.558],
              [118.7059, -34.6477],
              [118.4798, -34.7171],
              [118.3874, -34.8259],
              [118.1199, -34.9871],
              [117.5386, -35.0922],
              [117.3595, -35.0242],
              [116.8996, -35.0504],
              [116.4651, -35.0007],
              [116.2484, -34.8775],
              [116.0198, -34.8294],
              [115.7455, -34.5402],
              [115.3347, -34.3142],
              [115.0178, -34.2621],
              [114.971, -34.0827],
              [114.9568, -33.6823],
              [115.1188, -33.6165],
              [115.2827, -33.6506],
              [115.4341, -33.5955],
              [115.6668, -33.2877],
              [115.6746, -33.025],
              [115.6009, -32.6617],
              [115.7693, -32.6023],
              [115.7144, -32.303],
              [115.7568, -32.1856],
              [115.7437, -31.9278],
              [115.6803, -31.6486],
              [115.4546, -31.3035],
              [115.313, -30.9908],
              [115.1809, -30.8272],
              [114.9749, -30.214],
              [114.9461, -29.6581],
              [114.9788, -29.4799],
              [114.832, -29.0921],
              [114.5775, -28.7851],
              [114.5311, -28.5224],
              [114.3101, -28.2302],
              [114.166, -28.1067],
              [114.0972, -27.8194],
              [114.1439, -27.6878],
              [114.0125, -27.3154],
              [113.5906, -26.6757],
              [113.2978, -26.3973],
              [113.2447, -26.2401],
              [113.4501, -26.2006],
              [113.5983, -26.5185],
              [113.6949, -26.6142],
              [113.8525, -26.5448],
              [113.8848, -26.3427],
              [113.6841, -26.2216],
              [113.5772, -26.0893],
              [113.5576, -25.941],
              [113.4208, -25.7282],
              [113.5329, -25.6183],
              [113.7606, -25.9048],
              [113.7044, -26.148],
              [113.9355, -26.1917],
              [113.97, -26.3317],
              [114.1038, -26.4479],
              [114.2344, -26.3166],
              [114.1919, -26.1959],
              [114.2586, -25.8419],
              [114.1553, -25.7897],
              [113.9284, -25.4498],
              [113.8024, -25.1718],
              [113.7131, -25.1249],
              [113.6106, -24.8928],
              [113.6043, -24.7283],
              [113.4395, -24.5328],
              [113.3853, -24.2491],
              [113.4597, -23.9103],
              [113.5995, -23.634],
              [113.7376, -23.527],
              [113.7825, -23.3271],
              [113.7483, -23.1623],
              [113.8115, -23.0514],
              [113.7598, -22.7888],
              [113.6589, -22.7278],
              [113.6467, -22.5777],
              [113.804, -22.3308],
              [113.898, -22.0492],
              [114.0115, -21.86],
              [114.1592, -21.7919],
              [114.0775, -22.1755],
              [114.1714, -22.3175],
              [114.1797, -22.5217],
              [114.3919, -22.358],
              [114.6467, -21.8434],
              [114.929, -21.6939],
              [115.1052, -21.6652],
              [115.4546, -21.5138],
              [115.6027, -21.3435],
              [115.8062, -21.2354],
              [115.9367, -21.059],
              [116.1515, -20.97],
              [116.2659, -20.8626],
              [116.4347, -20.8224],
              [116.7432, -20.6169],
              [116.8796, -20.7131],
              [117.0625, -20.6217],
              [117.3538, -20.7277],
              [117.8394, -20.6169],
              [118.1356, -20.3621],
              [118.3688, -20.3621],
              [118.8318, -20.262],
              [119.0685, -19.9828],
              [119.2124, -19.9612],
              [119.5667, -20.0688],
              [119.8023, -19.9658],
              [120.2383, -19.9068],
              [120.9544, -19.6337],
              [121.1935, -19.4724],
              [121.5194, -19.0696],
              [121.6426, -18.8102],
              [121.7691, -18.6574],
              [121.8237, -18.4518],
              [121.9103, -18.4645],
              [122.2204, -18.1972],
              [122.324, -18.1506],
              [122.3607, -18.0002],
              [122.1961, -17.9454],
              [122.2054, -17.7109],
              [122.1432, -17.5637],
              [122.172, -17.2612],
              [122.2761, -17.0893],
              [122.5304, -16.874],
              [122.7043, -16.7867],
              [122.7588, -16.6044],
              [122.8864, -16.5123],
              [123.1509, -16.8077],
              [123.1433, -16.9138],
              [123.2604, -17.0147],
              [123.3997, -17.3293],
              [123.5676, -17.362],
              [123.6443, -17.1847],
              [123.6359, -16.9914],
              [123.8371, -17.1324],
              [123.6931, -16.7299],
              [123.5662, -16.616],
              [123.6516, -16.3603],
              [123.5397, -16.2652],
              [123.595, -16.1559],
              [123.8352, -16.3683],
              [124.1262, -16.2722],
              [124.2227, -16.3956],
              [124.4002, -16.3531],
              [124.3784, -16.2149],
              [124.59, -16.0942],
              [124.4043, -15.8748],
              [124.4033, -15.5593],
              [124.5042, -15.4675],
              [124.6296, -15.505],
              [124.7979, -15.2919],
              [125.0498, -15.4982],
              [125.0896, -15.3473],
              [124.9289, -15.3381],
              [124.842, -15.1412],
              [125.1261, -15.0495],
              [125.3108, -15.1506],
              [125.3545, -14.9939],
              [125.1443, -14.7284],
              [125.4663, -14.5248],
              [125.5838, -14.5265],
              [125.6581, -14.3603],
              [125.9251, -14.5186],
              [126.0413, -14.5087],
              [126.1413, -14.1955],
              [126.2477, -14.2232],
              [126.3903, -14.0051],
              [126.501, -13.9972],
              [126.5692, -14.1952],
              [126.7048, -14.1181],
              [126.8182, -13.9426],
              [126.7474, -13.7918],
              [126.9529, -13.7236],
              [127.1074, -13.9528],
              [127.3224, -13.9174],
              [127.666, -14.1784],
              [127.9056, -14.4975],
              [128.1753, -14.6984],
              [128.0671, -15.1252],
              [128.1958, -15.0568],
              [128.4026, -14.823],
              [128.5324, -14.7529],
              [129.051, -14.8801],
              [129.2214, -14.8435],
              [129.6118, -14.9426],
              [129.7241, -14.7907],
              [129.5852, -14.5663],
              [129.3581, -14.4088],
              [129.4959, -14.0891],
              [129.7175, -14.0156],
              [129.7149, -13.8805],
              [129.8159, -13.762],
              [129.8377, -13.5105],
              [129.9689, -13.5305],
              [130.1637, -13.4329],
              [130.2732, -13.3188],
              [130.1187, -13.1562],
              [130.1299, -12.9405],
              [130.3518, -12.8425],
              [130.345, -12.695],
              [130.5794, -12.5685],
              [130.6204, -12.4369],
              [130.7839, -12.435],
              [130.8976, -12.3342],
              [131.0252, -12.3605],
              [131.0002, -12.1713],
              [131.2165, -12.2149],
              [131.3279, -12.1853],
              [131.4929, -12.2978],
              [131.7844, -12.2704],
              [131.9046, -12.2198],
              [132.0457, -12.3074],
              [132.2688, -12.1808],
              [132.4396, -12.1549],
              [132.6272, -12.0439],
              [132.6511, -11.8658],
              [132.595, -11.622],
              [132.4954, -11.4762],
              [132.3699, -11.435],
              [132.0847, -11.518],
              [131.8562, -11.3034],
              [132.0143, -11.1396],
              [132.1883, -11.3513],
              [132.241, -11.1965],
              [132.3499, -11.1463],
              [132.4749, -11.2341],
              [132.6742, -11.5084],
              [132.911, -11.3318],
              [133.0111, -11.4328],
              [133.1411, -11.6746],
              [133.3467, -11.6882],
              [133.6221, -11.8333],
              [133.8025, -11.7149],
              [133.9431, -11.9137],
              [134.1322, -11.8864],
              [134.3822, -12.0427],
              [134.6047, -12.0495],
              [134.7686, -11.9477],
              [134.8611, -12.1266],
              [135.0702, -12.2599],
              [135.2583, -12.2221],
              [135.3029, -12.1283],
              [135.5353, -12.0806],
              [135.5707, -11.9946],
              [135.7393, -11.9415],
              [135.7654, -12.0719],
              [135.6501, -12.1646],
              [135.7653, -12.2571],
              [135.8801, -12.1516],
              [136.0261, -12.3115],
              [136.0232, -12.4557],
              [136.2527, -12.4411],
              [136.3639, -12.2564],
              [136.18, -12.1878],
              [136.4507, -11.9423],
              [136.5333, -11.9932],
              [136.5906, -12.1743],
              [136.6906, -12.2828],
              [136.8764, -12.2234],
              [136.9493, -12.3559],
              [136.7402, -12.4758],
              [136.6792, -12.6713],
              [136.4738, -12.7923],
              [136.6369, -12.9549],
              [136.4505, -13.2164],
              [136.3084, -13.1628],
              [135.8834, -13.3258],
              [135.8448, -13.6002],
              [136.0049, -13.798],
              [135.9231, -13.9596],
              [135.9033, -14.1358],
              [135.7591, -14.2654],
              [135.3913, -14.7399],
              [135.4696, -14.948],
              [135.861, -15.176],
              [135.9517, -15.2644],
              [136.2401, -15.4241],
              [136.2679, -15.545],
              [136.6121, -15.7547],
              [136.7679, -15.9008],
              [137.0459, -15.9223],
              [137.3612, -16.1222],
              [137.7461, -16.2521],
              [137.8669, -16.4371],
              [138.1843, -16.6972],
              [138.4927, -16.7944],
              [138.6572, -16.7797],
              [139.0373, -16.9156],
              [139.2742, -17.3483],
              [139.4386, -17.3757],
              [139.641, -17.5324],
              [139.9253, -17.6214],
              [139.9995, -17.7061],
              [140.2405, -17.7021],
              [140.5075, -17.6363],
              [140.8333, -17.4508],
              [140.96, -17.074],
              [140.947, -17.0183],
              [141.1995, -16.7031],
              [141.4248, -16.0817],
              [141.4017, -15.9036],
              [141.4871, -15.4789],
              [141.6657, -15.0114],
              [141.6043, -14.8777],
              [141.5217, -14.4838],
              [141.5921, -14.2453],
              [141.584, -14.0761],
              [141.485, -13.9485],
              [141.4741, -13.7763],
              [141.5598, -13.492],
              [141.6908, -13.2599],
              [141.5855, -12.9775],
              [141.7547, -12.8185],
              [141.8114, -12.5841],
              [141.6657, -12.4411],
              [141.801, -12.0551],
              [141.9476, -11.8962],
              [142.1318, -11.3381],
              [142.1746, -10.9301],
              [142.3234, -10.9016],
              [142.4486, -10.7145],
              [142.5496, -10.7082],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Australia",
        NAME_LONG: "Australia",
        ADM0_A3: "AUS",
        ISO_A2: "AU",
        ISO_A3: "AUS",
        WB_A2: "AU",
        WB_A3: "AUS",
      },
      id: 15,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.945, 48.6042],
            [16.8445, 48.3656],
            [17.1483, 48.0054],
            [17.0395, 47.8374],
            [17.0548, 47.702],
            [16.7975, 47.6755],
            [16.688, 47.5226],
            [16.425, 47.3511],
            [16.4372, 47.0318],
            [16.2749, 47.0043],
            [16.094, 46.8628],
            [15.9705, 46.743],
            [15.6521, 46.7108],
            [15.4627, 46.6146],
            [15.062, 46.6496],
            [14.7886, 46.5066],
            [14.5022, 46.4184],
            [14.1499, 46.4401],
            [13.701, 46.5197],
            [13.2311, 46.5522],
            [12.405, 46.6901],
            [12.1112, 46.993],
            [11.9436, 47.0382],
            [11.7464, 46.9725],
            [11.3138, 46.9873],
            [11.0919, 46.9124],
            [10.9969, 46.7691],
            [10.4538, 46.8644],
            [10.3679, 46.9955],
            [10.1113, 46.8471],
            [9.5812, 47.0569],
            [9.5212, 47.2628],
            [9.6495, 47.4097],
            [9.5475, 47.5345],
            [9.9487, 47.5242],
            [10.3059, 47.3022],
            [10.6079, 47.5623],
            [11.0837, 47.3895],
            [11.2588, 47.4007],
            [11.6205, 47.5897],
            [12.1656, 47.604],
            [12.4241, 47.6916],
            [12.7448, 47.6654],
            [12.7789, 47.5548],
            [13.0373, 47.493],
            [13.0748, 47.6467],
            [12.892, 47.7235],
            [12.9912, 47.8471],
            [12.745, 48.1206],
            [13.0328, 48.2636],
            [13.4056, 48.3766],
            [13.5207, 48.5846],
            [13.7143, 48.5232],
            [13.8157, 48.7664],
            [14.0748, 48.5917],
            [14.3157, 48.5579],
            [14.4436, 48.6365],
            [14.6957, 48.5895],
            [14.8004, 48.7765],
            [14.9193, 48.7616],
            [14.9821, 49.0079],
            [15.2629, 48.9826],
            [15.6813, 48.8585],
            [15.8185, 48.8723],
            [16.0323, 48.7581],
            [16.3583, 48.7273],
            [16.4357, 48.7945],
            [16.873, 48.719],
            [16.945, 48.6042],
          ],
        ],
      },
      properties: {
        NAME: "Austria",
        NAME_LONG: "Austria",
        ADM0_A3: "AUT",
        ISO_A2: "AT",
        ISO_A3: "AUT",
        WB_A2: "AT",
        WB_A3: "AUT",
      },
      id: 16,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [46.1359, 38.8637],
              [45.4386, 39.0042],
              [45.3482, 39.1639],
              [45.1789, 39.2198],
              [44.957, 39.4345],
              [44.807, 39.6399],
              [44.7746, 39.7028],
              [45.0335, 39.7704],
              [45.1679, 39.5655],
              [45.4575, 39.4893],
              [45.7881, 39.549],
              [45.767, 39.3544],
              [45.9588, 39.2724],
              [45.9581, 39.1515],
              [46.1359, 38.8637],
            ],
          ],
          [
            [
              [46.4309, 41.8904],
              [46.5499, 41.7978],
              [46.745, 41.8401],
              [47.027, 41.5569],
              [47.2315, 41.4275],
              [47.2684, 41.3028],
              [47.7437, 41.1927],
              [47.8711, 41.2082],
              [48.0535, 41.4613],
              [48.3702, 41.5802],
              [48.5789, 41.8453],
              [49.0425, 41.4144],
              [49.1955, 41.0573],
              [49.382, 40.8666],
              [49.5388, 40.7881],
              [49.5896, 40.6252],
              [49.8587, 40.5666],
              [50.052, 40.5843],
              [50.3359, 40.406],
              [50.23, 40.3428],
              [49.8934, 40.369],
              [49.5772, 40.2167],
              [49.4426, 40.0784],
              [49.4839, 39.9887],
              [49.318, 39.6214],
              [49.2957, 39.4767],
              [49.4089, 39.3864],
              [49.2371, 39.279],
              [49.1939, 39.0163],
              [48.9676, 39.1838],
              [48.8631, 38.7567],
              [48.8743, 38.4341],
              [48.6079, 38.396],
              [48.2104, 38.7298],
              [48.013, 38.8216],
              [48.0585, 38.9484],
              [48.3068, 39.0454],
              [48.1422, 39.1812],
              [48.1246, 39.2976],
              [48.3389, 39.3789],
              [48.052, 39.6501],
              [47.8469, 39.6853],
              [47.2914, 39.3796],
              [47.1022, 39.3084],
              [47.0205, 39.19],
              [46.8662, 39.1654],
              [46.514, 38.8822],
              [46.4793, 39.1148],
              [46.5979, 39.2251],
              [46.3746, 39.3788],
              [46.5273, 39.5382],
              [46.3437, 39.6186],
              [46.2065, 39.5879],
              [45.8619, 39.8045],
              [45.6396, 40.0254],
              [45.8738, 40.0098],
              [45.9792, 40.2236],
              [45.5262, 40.441],
              [45.3736, 40.6753],
              [45.5559, 40.7766],
              [45.4164, 40.9613],
              [45.2216, 41.0144],
              [45.1868, 41.1569],
              [45.0024, 41.2905],
              [45.2496, 41.4445],
              [45.4648, 41.4302],
              [45.7207, 41.3424],
              [45.7097, 41.2677],
              [45.9692, 41.1683],
              [46.2106, 41.2002],
              [46.4968, 41.0448],
              [46.6948, 41.2698],
              [46.5916, 41.3729],
              [46.2967, 41.5104],
              [46.1928, 41.6102],
              [46.4309, 41.8904],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Azerbaijan",
        NAME_LONG: "Azerbaijan",
        ADM0_A3: "AZE",
        ISO_A2: "AZ",
        ISO_A3: "AZE",
        WB_A2: "AZ",
        WB_A3: "AZE",
      },
      id: 17,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.5546, -2.4006],
            [30.416, -2.6456],
            [30.4158, -2.8517],
            [30.7189, -2.9895],
            [30.8322, -3.1728],
            [30.7998, -3.2746],
            [30.6405, -3.3329],
            [30.6129, -3.4449],
            [30.4689, -3.513],
            [30.2088, -3.9305],
            [30.1498, -4.0868],
            [30.003, -4.2719],
            [29.8003, -4.3639],
            [29.7283, -4.4616],
            [29.4042, -4.4498],
            [29.3395, -4.0932],
            [29.215, -3.8999],
            [29.2067, -3.3345],
            [29.2346, -3.0466],
            [29.0898, -2.9261],
            [29.0154, -2.7207],
            [29.1297, -2.597],
            [29.3066, -2.6601],
            [29.3397, -2.8264],
            [29.6975, -2.8083],
            [29.9033, -2.6491],
            [29.9317, -2.3169],
            [30.1391, -2.4308],
            [30.2075, -2.3392],
            [30.3786, -2.3031],
            [30.5546, -2.4006],
          ],
        ],
      },
      properties: {
        NAME: "Burundi",
        NAME_LONG: "Burundi",
        ADM0_A3: "BDI",
        ISO_A2: "BI",
        ISO_A3: "BDI",
        WB_A2: "BI",
        WB_A3: "BDI",
      },
      id: 18,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.9949, 50.7499],
            [6.2329, 50.5871],
            [6.3377, 50.3681],
            [6.1175, 50.1205],
            [5.9616, 50.1656],
            [5.7149, 49.8819],
            [5.8855, 49.6435],
            [5.7907, 49.5378],
            [5.4777, 49.4952],
            [5.4021, 49.6025],
            [4.9891, 49.7906],
            [4.849, 49.7942],
            [4.7883, 49.9743],
            [4.4351, 49.9323],
            [4.21, 50.0601],
            [4.0227, 50.3381],
            [3.755, 50.3463],
            [3.4629, 50.5191],
            [3.271, 50.5269],
            [3.1462, 50.7689],
            [2.7867, 50.7234],
            [2.6204, 50.8165],
            [2.5218, 51.0875],
            [3.1248, 51.3297],
            [3.3494, 51.3752],
            [3.3917, 51.2468],
            [3.6351, 51.2878],
            [3.927, 51.2063],
            [4.2215, 51.368],
            [4.2611, 51.3694],
            [4.5252, 51.4759],
            [4.7312, 51.4856],
            [5.0681, 51.3745],
            [5.2154, 51.2587],
            [5.4716, 51.2881],
            [5.8405, 51.1389],
            [5.6407, 50.815],
            [5.9949, 50.7499],
          ],
        ],
      },
      properties: {
        NAME: "Belgium",
        NAME_LONG: "Belgium",
        ADM0_A3: "BEL",
        ISO_A2: "BE",
        ISO_A3: "BEL",
        WB_A2: "BE",
        WB_A3: "BEL",
      },
      id: 19,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.5964, 11.6958],
            [3.5049, 11.5566],
            [3.4833, 11.3923],
            [3.7221, 11.1123],
            [3.7313, 10.825],
            [3.8374, 10.5999],
            [3.7569, 10.4053],
            [3.6521, 10.4358],
            [3.5724, 10.2857],
            [3.6556, 10.1748],
            [3.5996, 9.9687],
            [3.5131, 9.8466],
            [3.3171, 9.7817],
            [3.3386, 9.6782],
            [3.1286, 9.4296],
            [3.1514, 9.2886],
            [3.0913, 9.1264],
            [2.9831, 9.0606],
            [2.7692, 9.057],
            [2.7229, 8.7723],
            [2.7448, 8.4983],
            [2.6971, 8.3207],
            [2.735, 8.2119],
            [2.6722, 7.8896],
            [2.7272, 7.7933],
            [2.7206, 7.5866],
            [2.7739, 7.0508],
            [2.7151, 6.9798],
            [2.7786, 6.6988],
            [2.7038, 6.3684],
            [1.8845, 6.2748],
            [1.6196, 6.2139],
            [1.7824, 6.2773],
            [1.7441, 6.4257],
            [1.566, 6.6781],
            [1.6257, 6.9968],
            [1.6213, 7.9966],
            [1.6012, 9.0495],
            [1.4253, 9.2845],
            [1.3513, 9.5951],
            [1.331, 9.9965],
            [0.7688, 10.3671],
            [0.7813, 10.693],
            [0.8629, 10.7961],
            [0.9015, 10.9927],
            [1.035, 11.0493],
            [1.1306, 11.2476],
            [1.2684, 11.2479],
            [1.4338, 11.4594],
            [1.6016, 11.3887],
            [1.8798, 11.4449],
            [2.0108, 11.4269],
            [2.2911, 11.6748],
            [2.3902, 11.8965],
            [2.3612, 12.2189],
            [2.8443, 12.3992],
            [3.2551, 12.0188],
            [3.2927, 11.9187],
            [3.5035, 11.83],
            [3.5964, 11.6958],
          ],
        ],
      },
      properties: {
        NAME: "Benin",
        NAME_LONG: "Benin",
        ADM0_A3: "BEN",
        ISO_A2: "BJ",
        ISO_A3: "BEN",
        WB_A2: "BJ",
        WB_A3: "BEN",
      },
      id: 20,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.2185, 14.911],
            [0.2197, 14.7312],
            [0.1529, 14.5467],
            [0.1886, 14.4477],
            [0.3888, 14.2516],
            [0.3392, 14.1258],
            [0.6207, 13.68],
            [0.951, 13.5832],
            [1.0482, 13.4419],
            [0.9835, 13.3684],
            [0.9718, 13.0673],
            [1.114, 12.9962],
            [1.5359, 12.6475],
            [1.8261, 12.6042],
            [1.9717, 12.7242],
            [2.109, 12.7056],
            [2.245, 12.4242],
            [2.0709, 12.3069],
            [2.3902, 11.8965],
            [2.2911, 11.6748],
            [2.0108, 11.4269],
            [1.8798, 11.4449],
            [1.6016, 11.3887],
            [1.4338, 11.4594],
            [1.2684, 11.2479],
            [1.1306, 11.2476],
            [1.035, 11.0493],
            [0.9015, 10.9927],
            [0.4866, 11.0032],
            [-0.1661, 11.135],
            [-0.4059, 11.1015],
            [-0.5165, 10.9886],
            [-1.5987, 10.9971],
            [-2.7507, 10.9858],
            [-2.8642, 10.8643],
            [-2.9325, 10.6343],
            [-2.8146, 10.3937],
            [-2.8332, 10.306],
            [-2.7368, 9.8326],
            [-2.7953, 9.72],
            [-2.6892, 9.4887],
            [-2.7637, 9.3919],
            [-2.9102, 9.5427],
            [-2.99, 9.7084],
            [-3.1369, 9.8295],
            [-3.3892, 9.9132],
            [-3.6617, 9.9489],
            [-3.8897, 9.8945],
            [-4.0599, 9.7963],
            [-4.2703, 9.7439],
            [-4.3225, 9.6089],
            [-4.604, 9.7272],
            [-4.7055, 9.7021],
            [-4.8385, 9.8695],
            [-4.9661, 9.901],
            [-5.1118, 10.2787],
            [-5.4025, 10.3006],
            [-5.5226, 10.4255],
            [-5.4314, 10.8447],
            [-5.4898, 11.0821],
            [-5.3223, 11.1355],
            [-5.2183, 11.4222],
            [-5.3078, 11.629],
            [-5.2682, 11.8431],
            [-5.1678, 11.9437],
            [-4.9537, 12.0051],
            [-4.8068, 11.9963],
            [-4.6537, 12.0691],
            [-4.5855, 12.1972],
            [-4.4061, 12.3075],
            [-4.4516, 12.4353],
            [-4.3869, 12.5302],
            [-4.4825, 12.6463],
            [-4.2972, 12.7121],
            [-4.2119, 12.8192],
            [-4.2293, 12.949],
            [-4.3511, 13.1183],
            [-3.984, 13.3965],
            [-3.7986, 13.3471],
            [-3.5969, 13.1994],
            [-3.2486, 13.2928],
            [-3.2597, 13.6583],
            [-3.0675, 13.6068],
            [-2.8952, 13.6516],
            [-2.9281, 13.7996],
            [-2.8409, 14.043],
            [-2.4617, 14.2809],
            [-2.1519, 14.1561],
            [-2.0234, 14.1986],
            [-1.967, 14.4837],
            [-1.6969, 14.4961],
            [-1.3503, 14.715],
            [-1.0433, 14.8179],
            [-0.7529, 15.0697],
            [-0.4679, 15.0799],
            [-0.3977, 15.0021],
            [-0.2367, 15.0656],
            [0.2185, 14.911],
          ],
        ],
      },
      properties: {
        NAME: "Burkina Faso",
        NAME_LONG: "Burkina Faso",
        ADM0_A3: "BFA",
        ISO_A2: "BF",
        ISO_A3: "BFA",
        WB_A2: "BF",
        WB_A3: "BFA",
      },
      id: 21,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [90.8279, 22.1524],
              [90.6035, 22.044],
              [90.688, 22.3776],
              [90.6475, 22.5483],
              [90.5588, 22.6029],
              [90.6506, 22.7874],
              [90.7669, 22.5175],
              [90.855, 22.4347],
              [90.8279, 22.1524],
            ],
          ],
          [
            [
              [92.5759, 21.9776],
              [92.5902, 21.4955],
              [92.6429, 21.2984],
              [92.4231, 21.3637],
              [92.3227, 21.4619],
              [92.1837, 21.3172],
              [92.175, 21.1756],
              [92.2651, 21.0611],
              [92.1929, 20.9957],
              [92.0525, 21.1548],
              [91.9597, 21.424],
              [92.0221, 21.5994],
              [91.9087, 21.7812],
              [91.8552, 22.1092],
              [91.676, 22.5346],
              [91.4563, 22.7848],
              [91.2434, 22.7022],
              [91.2027, 22.5432],
              [90.9549, 22.5758],
              [90.7742, 22.7781],
              [90.6272, 23.0867],
              [90.4661, 23.0768],
              [90.507, 22.9421],
              [90.4064, 22.7631],
              [90.4899, 22.7202],
              [90.6066, 22.4658],
              [90.6135, 22.3149],
              [90.4596, 22.0919],
              [90.3322, 22.0381],
              [90.2727, 21.8574],
              [90.1848, 21.801],
              [90.0244, 21.8625],
              [89.9344, 21.9977],
              [89.8142, 21.8408],
              [89.4658, 21.7813],
              [89.3659, 21.8289],
              [89.1047, 21.8159],
              [89.0604, 22.1299],
              [88.9081, 22.7408],
              [88.9445, 22.848],
              [88.8428, 22.9646],
              [88.9547, 23.1838],
              [88.6859, 23.2933],
              [88.7194, 23.4681],
              [88.5401, 23.65],
              [88.5936, 23.8669],
              [88.7142, 23.9252],
              [88.6741, 24.0826],
              [88.7375, 24.2871],
              [88.4754, 24.3155],
              [88.1388, 24.4953],
              [88.0218, 24.6456],
              [88.2025, 24.9453],
              [88.3228, 24.8747],
              [88.4315, 25.173],
              [88.8986, 25.1693],
              [88.7723, 25.502],
              [88.5303, 25.5002],
              [88.4194, 25.6538],
              [88.0744, 25.9081],
              [88.1638, 26.1405],
              [88.3087, 26.2061],
              [88.4955, 26.3781],
              [88.6563, 26.4151],
              [88.808, 26.2334],
              [89.0437, 26.3811],
              [89.1316, 26.1338],
              [89.3515, 25.9969],
              [89.6029, 26.0553],
              [89.6584, 26.2079],
              [89.8301, 25.908],
              [89.7831, 25.8144],
              [89.8344, 25.6348],
              [89.795, 25.3742],
              [89.9081, 25.2969],
              [90.3646, 25.15],
              [90.738, 25.1694],
              [90.822, 25.1423],
              [91.2357, 25.2019],
              [91.471, 25.1339],
              [92.0018, 25.183],
              [92.3035, 25.0743],
              [92.4581, 24.9533],
              [92.3807, 24.8367],
              [92.2338, 24.7775],
              [92.1076, 24.406],
              [91.95, 24.3754],
              [91.9059, 24.2606],
              [91.5965, 24.105],
              [91.363, 24.0998],
              [91.2163, 23.9084],
              [91.1408, 23.6121],
              [91.2273, 23.4688],
              [91.3005, 23.1423],
              [91.4636, 23.1841],
              [91.5366, 22.9819],
              [91.6947, 22.9881],
              [91.7956, 23.0895],
              [91.7437, 23.2723],
              [91.9408, 23.4952],
              [92.0035, 23.6474],
              [92.1508, 23.7317],
              [92.2528, 23.6093],
              [92.3569, 23.2891],
              [92.3276, 23.1714],
              [92.3787, 22.9004],
              [92.4959, 22.6956],
              [92.577, 22.1687],
              [92.5759, 21.9776],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Bangladesh",
        NAME_LONG: "Bangladesh",
        ADM0_A3: "BGD",
        ISO_A2: "BD",
        ISO_A3: "BGD",
        WB_A2: "BD",
        WB_A3: "BGD",
      },
      id: 22,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.5784, 43.7413],
            [28.6035, 43.5382],
            [28.4595, 43.3807],
            [28.3216, 43.4283],
            [28.0916, 43.364],
            [27.8854, 43.0086],
            [27.8923, 42.7105],
            [27.7432, 42.7161],
            [27.5115, 42.553],
            [27.6917, 42.4283],
            [27.7511, 42.277],
            [27.9866, 42.0721],
            [28.0168, 41.9726],
            [27.808, 41.9935],
            [27.5332, 41.9081],
            [27.2734, 42.0917],
            [27.0226, 42.0739],
            [26.9389, 41.9962],
            [26.6236, 41.969],
            [26.5261, 41.8242],
            [26.3334, 41.713],
            [26.0481, 41.6891],
            [26.1645, 41.5177],
            [26.1209, 41.3578],
            [25.882, 41.304],
            [25.5515, 41.3157],
            [25.2857, 41.2394],
            [24.8864, 41.4006],
            [24.5958, 41.4298],
            [24.5309, 41.5475],
            [24.0473, 41.5257],
            [23.6124, 41.371],
            [23.2877, 41.3982],
            [23.1152, 41.3127],
            [22.917, 41.3358],
            [22.9337, 41.5846],
            [23.0096, 41.7164],
            [22.8437, 42.0145],
            [22.5311, 42.1291],
            [22.345, 42.3134],
            [22.5088, 42.4049],
            [22.4297, 42.5714],
            [22.506, 42.8701],
            [22.727, 42.8869],
            [22.9353, 43.0856],
            [22.8045, 43.329],
            [22.5189, 43.4742],
            [22.3495, 43.8079],
            [22.3996, 43.9933],
            [22.6047, 44.0794],
            [22.6916, 44.2284],
            [23.023, 44.0316],
            [22.8859, 43.9945],
            [22.9196, 43.8342],
            [23.3252, 43.8866],
            [24.1594, 43.7529],
            [24.5001, 43.7995],
            [24.7056, 43.7438],
            [24.9637, 43.7496],
            [25.3596, 43.6543],
            [25.7811, 43.732],
            [26.1507, 44.0124],
            [27.0275, 44.177],
            [27.2511, 44.1224],
            [27.3838, 44.0151],
            [27.6563, 44.0239],
            [27.9358, 43.9644],
            [27.981, 43.8493],
            [28.2213, 43.762],
            [28.5784, 43.7413],
          ],
        ],
      },
      properties: {
        NAME: "Bulgaria",
        NAME_LONG: "Bulgaria",
        ADM0_A3: "BGR",
        ISO_A2: "BG",
        ISO_A3: "BGR",
        WB_A2: "BG",
        WB_A3: "BGR",
      },
      id: 23,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [50.5516, 26.1942],
            [50.6148, 26.1112],
            [50.6035, 25.8546],
            [50.4605, 25.9853],
            [50.453, 26.1496],
            [50.5516, 26.1942],
          ],
        ],
      },
      properties: {
        NAME: "Bahrain",
        NAME_LONG: "Bahrain",
        ADM0_A3: "BHR",
        ISO_A2: "BH",
        ISO_A3: "BHR",
        WB_A2: "BH",
        WB_A3: "BHR",
      },
      id: 24,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-73.0339, 21.1567],
              [-73.1298, 20.9937],
              [-73.4926, 20.9245],
              [-73.6761, 20.9352],
              [-73.7032, 21.0198],
              [-73.5146, 21.1869],
              [-73.3982, 21.2248],
              [-73.2552, 21.1351],
              [-73.0339, 21.1567],
            ],
          ],
          [
            [
              [-77.7481, 24.1318],
              [-77.5495, 24.1259],
              [-77.5175, 23.8642],
              [-77.643, 23.7549],
              [-77.8129, 23.8767],
              [-77.8335, 24.0218],
              [-77.7481, 24.1318],
            ],
          ],
          [
            [
              [-78.107, 25.1631],
              [-77.7372, 24.7002],
              [-77.7187, 24.4998],
              [-77.9086, 24.3508],
              [-78.0633, 24.3162],
              [-78.18, 24.4649],
              [-78.3516, 24.5305],
              [-78.3351, 24.6728],
              [-78.2081, 24.8618],
              [-78.107, 25.1631],
            ],
          ],
          [
            [
              [-78.5203, 26.729],
              [-78.2871, 26.6855],
              [-77.9717, 26.7296],
              [-77.9029, 26.6816],
              [-78.5402, 26.565],
              [-78.5203, 26.729],
            ],
          ],
          [
            [
              [-77.7745, 26.9272],
              [-77.5113, 26.8695],
              [-77.2699, 26.6251],
              [-77.0588, 26.5294],
              [-77.0148, 26.3157],
              [-77.1649, 26.2454],
              [-77.1907, 25.9163],
              [-77.3479, 26.0706],
              [-77.2564, 26.2039],
              [-77.1607, 26.5642],
              [-77.3122, 26.5947],
              [-77.5175, 26.8522],
              [-77.7745, 26.9272],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Bahamas",
        NAME_LONG: "Bahamas",
        ADM0_A3: "BHS",
        ISO_A2: "BS",
        ISO_A3: "BHS",
        WB_A2: "BS",
        WB_A3: "BHS",
      },
      id: 25,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.0158, 44.8656],
            [19.1743, 44.9254],
            [19.3687, 44.8871],
            [19.3284, 44.734],
            [19.1299, 44.5183],
            [19.1772, 44.287],
            [19.6189, 44.0357],
            [19.2754, 43.9333],
            [19.4617, 43.7621],
            [19.3634, 43.6016],
            [19.1953, 43.5328],
            [18.911, 43.5073],
            [18.8991, 43.3519],
            [18.6795, 43.2495],
            [18.639, 43.0202],
            [18.4528, 42.9934],
            [18.5384, 42.6183],
            [18.4374, 42.5592],
            [18.2241, 42.6281],
            [17.6533, 42.8909],
            [17.5807, 42.9421],
            [17.5874, 43.0821],
            [17.4421, 43.1524],
            [17.2676, 43.3533],
            [17.2395, 43.4784],
            [17.0846, 43.5132],
            [16.7126, 43.7715],
            [16.6786, 43.8407],
            [16.2158, 44.2082],
            [16.1165, 44.5215],
            [15.8956, 44.7186],
            [15.7173, 44.7865],
            [15.78, 45.1603],
            [15.9975, 45.2185],
            [16.213, 45.0315],
            [16.3406, 45.0059],
            [16.4819, 45.1995],
            [16.7863, 45.1788],
            [16.9326, 45.2788],
            [17.1871, 45.1486],
            [17.8357, 45.0644],
            [17.9885, 45.1438],
            [18.2379, 45.1575],
            [18.4661, 45.074],
            [18.6167, 45.0977],
            [19.0158, 44.8656],
          ],
        ],
      },
      properties: {
        NAME: "Bosnia and Herz.",
        NAME_LONG: "Bosnia and Herzegovina",
        ADM0_A3: "BIH",
        ISO_A2: "BA",
        ISO_A3: "BIH",
        WB_A2: "BA",
        WB_A3: "BIH",
      },
      id: 26,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.1489, 56.1424],
            [28.2898, 56.0466],
            [28.6207, 56.083],
            [28.7064, 55.9598],
            [29.0308, 56.0242],
            [29.3774, 55.954],
            [29.3436, 55.787],
            [29.461, 55.6873],
            [29.8695, 55.8305],
            [30.2178, 55.8551],
            [30.4688, 55.7935],
            [30.5964, 55.6653],
            [30.9128, 55.5716],
            [30.9186, 55.4076],
            [30.7943, 55.2855],
            [31.0064, 55.0424],
            [30.8148, 54.928],
            [30.7706, 54.786],
            [31.1679, 54.6216],
            [31.3247, 54.2292],
            [31.7481, 54.0863],
            [31.8465, 53.9923],
            [31.7538, 53.8196],
            [32.0836, 53.8096],
            [32.4806, 53.6624],
            [32.4529, 53.5464],
            [32.7195, 53.4395],
            [32.4055, 53.1927],
            [32.2059, 53.1004],
            [31.9072, 53.0919],
            [31.7386, 53.1925],
            [31.3785, 53.182],
            [31.2471, 53.0144],
            [31.5051, 52.8489],
            [31.5702, 52.7253],
            [31.6081, 52.3725],
            [31.7643, 52.1006],
            [31.4749, 52.1178],
            [30.9405, 52.0201],
            [30.662, 51.8194],
            [30.5341, 51.5533],
            [30.6383, 51.3359],
            [30.3551, 51.3053],
            [30.1486, 51.4844],
            [29.8286, 51.43],
            [29.618, 51.4856],
            [29.297, 51.3737],
            [29.1604, 51.6033],
            [28.7999, 51.5326],
            [28.3469, 51.5252],
            [27.4773, 51.6237],
            [27.254, 51.5954],
            [27.1779, 51.7471],
            [26.8548, 51.7493],
            [26.4078, 51.8506],
            [25.7679, 51.9285],
            [25.1833, 51.9498],
            [24.7218, 51.8823],
            [24.3908, 51.88],
            [24.2441, 51.7182],
            [23.9813, 51.586],
            [23.7497, 51.6445],
            [23.6062, 51.5174],
            [23.5603, 51.7545],
            [23.6764, 51.9941],
            [23.6375, 52.0845],
            [23.1656, 52.2894],
            [23.3923, 52.5096],
            [23.869, 52.67],
            [23.9225, 52.7426],
            [23.8937, 53.152],
            [23.5909, 53.6113],
            [23.4856, 53.9393],
            [23.6426, 53.899],
            [24.2011, 53.9525],
            [24.3779, 53.8868],
            [24.8214, 54.0199],
            [25.1935, 54.2437],
            [25.53, 54.3461],
            [25.7827, 54.8697],
            [26.1386, 54.9689],
            [26.3095, 55.1446],
            [26.5789, 55.1185],
            [26.7686, 55.3002],
            [26.4455, 55.3375],
            [26.5945, 55.667],
            [26.8228, 55.7061],
            [26.9811, 55.8269],
            [27.5927, 55.7942],
            [27.645, 55.9228],
            [27.9115, 56.1002],
            [28.1489, 56.1424],
          ],
        ],
      },
      properties: {
        NAME: "Belarus",
        NAME_LONG: "Belarus",
        ADM0_A3: "BLR",
        ISO_A2: "BY",
        ISO_A3: "BLR",
        WB_A2: "BY",
        WB_A3: "BLR",
      },
      id: 29,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.3038, 18.4814],
            [-88.0915, 18.1184],
            [-88.2083, 17.882],
            [-88.2833, 17.5803],
            [-88.2308, 17.4965],
            [-88.3005, 17.1779],
            [-88.2151, 16.9671],
            [-88.2833, 16.8913],
            [-88.3062, 16.6529],
            [-88.536, 16.2741],
            [-88.7401, 16.2302],
            [-88.7457, 16.1549],
            [-88.9276, 15.9839],
            [-88.914, 15.8939],
            [-89.2365, 15.8939],
            [-89.1492, 17.0363],
            [-89.1605, 17.8143],
            [-89.0494, 17.9992],
            [-88.8803, 17.8885],
            [-88.7364, 18.0519],
            [-88.4892, 18.4845],
            [-88.3038, 18.4814],
          ],
        ],
      },
      properties: {
        NAME: "Belize",
        NAME_LONG: "Belize",
        ADM0_A3: "BLZ",
        ISO_A2: "BZ",
        ISO_A3: "BLZ",
        WB_A2: "BZ",
        WB_A3: "BLZ",
      },
      id: 30,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.1588, -20.1651],
            [-58.1753, -19.8214],
            [-59.0895, -19.2867],
            [-60.0064, -19.2981],
            [-60.8339, -19.4679],
            [-61.7612, -19.6578],
            [-61.9443, -20.1041],
            [-62.2773, -20.5798],
            [-62.2757, -21.0666],
            [-62.6504, -22.2345],
            [-62.7909, -22.1132],
            [-62.8044, -22.0041],
            [-63.6394, -21.9975],
            [-63.7404, -22.0506],
            [-63.9476, -22.0076],
            [-64.294, -22.6909],
            [-64.572, -22.3432],
            [-64.5869, -22.2128],
            [-65.0204, -22.0966],
            [-65.7446, -22.114],
            [-66.0945, -21.8329],
            [-66.24, -21.7924],
            [-66.2972, -22.0503],
            [-66.3775, -22.1271],
            [-66.7359, -22.2251],
            [-66.7965, -22.4349],
            [-67.0327, -22.5246],
            [-67.0377, -22.6546],
            [-67.1939, -22.8222],
            [-67.5471, -22.8925],
            [-67.7714, -22.8887],
            [-67.8763, -22.8336],
            [-67.8587, -22.5668],
            [-67.9453, -22.3542],
            [-67.9902, -22.0576],
            [-68.0962, -21.954],
            [-68.1076, -21.7896],
            [-68.1984, -21.5719],
            [-68.2075, -21.2843],
            [-68.4163, -20.9598],
            [-68.572, -20.8724],
            [-68.4816, -20.643],
            [-68.6855, -20.5168],
            [-68.6785, -20.3286],
            [-68.7755, -20.0897],
            [-68.5883, -20.0556],
            [-68.5535, -19.8579],
            [-68.6984, -19.7463],
            [-68.4964, -19.4584],
            [-68.4932, -19.377],
            [-68.6633, -19.2736],
            [-68.9896, -18.9465],
            [-68.9546, -18.857],
            [-69.0428, -18.6005],
            [-69.0344, -18.4783],
            [-69.1408, -18.0308],
            [-69.3024, -17.9762],
            [-69.3342, -17.8058],
            [-69.4758, -17.6524],
            [-69.5101, -17.5066],
            [-69.5226, -17.3691],
            [-69.6665, -17.2883],
            [-69.6228, -17.1856],
            [-69.4274, -17.0869],
            [-69.1917, -16.743],
            [-69.0371, -16.6702],
            [-69.0017, -16.4228],
            [-68.8436, -16.302],
            [-68.982, -16.21],
            [-69.1447, -16.2235],
            [-69.2196, -16.1528],
            [-69.4243, -15.6563],
            [-69.2912, -15.3508],
            [-69.1481, -15.2333],
            [-69.3907, -14.9644],
            [-69.3614, -14.788],
            [-69.2676, -14.7507],
            [-69.2475, -14.5937],
            [-68.9903, -14.379],
            [-69.0101, -14.2459],
            [-68.8837, -14.2115],
            [-68.983, -13.9722],
            [-69.016, -13.7531],
            [-68.9628, -13.2836],
            [-68.981, -12.8679],
            [-68.6843, -12.5025],
            [-69.1316, -11.726],
            [-69.5776, -10.9523],
            [-69.3959, -10.935],
            [-68.884, -11.0164],
            [-68.787, -11.126],
            [-68.6157, -11.1125],
            [-68.2933, -10.979],
            [-68.0979, -10.6977],
            [-67.8626, -10.6588],
            [-67.7558, -10.7142],
            [-67.5846, -10.5018],
            [-67.3378, -10.3261],
            [-67.0643, -10.2569],
            [-66.9027, -10.0931],
            [-66.6319, -9.9043],
            [-66.4529, -9.8887],
            [-66.1907, -9.8008],
            [-65.8744, -9.7813],
            [-65.7886, -9.733],
            [-65.584, -9.8372],
            [-65.4518, -9.6814],
            [-65.3044, -9.8257],
            [-65.3333, -9.9268],
            [-65.2881, -10.2162],
            [-65.45, -10.4681],
            [-65.4047, -10.7992],
            [-65.303, -10.9907],
            [-65.36, -11.2188],
            [-65.353, -11.3906],
            [-65.1954, -11.5922],
            [-65.1962, -11.7418],
            [-65.0656, -11.7531],
            [-64.9974, -11.9963],
            [-64.7925, -12.0325],
            [-64.7163, -12.1465],
            [-64.4691, -12.2611],
            [-64.3957, -12.4573],
            [-63.9212, -12.5443],
            [-63.8014, -12.4549],
            [-63.4961, -12.5533],
            [-63.3933, -12.6645],
            [-63.2473, -12.7013],
            [-63.075, -12.6527],
            [-62.9753, -12.8529],
            [-62.8074, -12.9889],
            [-62.4927, -13.065],
            [-62.3818, -13.1399],
            [-62.2216, -13.1213],
            [-61.8686, -13.4639],
            [-61.848, -13.5308],
            [-61.3477, -13.4937],
            [-60.8967, -13.5529],
            [-60.4653, -13.8166],
            [-60.3897, -13.9749],
            [-60.4735, -14.1172],
            [-60.2917, -14.6301],
            [-60.275, -15.0951],
            [-60.5295, -15.1437],
            [-60.238, -15.5027],
            [-60.1798, -16.222],
            [-60.1298, -16.2731],
            [-58.4647, -16.3313],
            [-58.3396, -16.2893],
            [-58.3561, -16.5095],
            [-58.4803, -16.6837],
            [-58.4663, -16.8873],
            [-58.3812, -17.2672],
            [-58.2316, -17.3297],
            [-58.0102, -17.4968],
            [-57.7908, -17.5558],
            [-57.7301, -17.8461],
            [-57.5511, -18.1836],
            [-57.7823, -18.9104],
            [-57.789, -19.0592],
            [-58.1246, -19.7299],
            [-57.8597, -19.9801],
            [-58.1588, -20.1651],
          ],
        ],
      },
      properties: {
        NAME: "Bolivia",
        NAME_LONG: "Bolivia",
        ADM0_A3: "BOL",
        ISO_A2: "BO",
        ISO_A3: "BOL",
        WB_A2: "BO",
        WB_A3: "BOL",
      },
      id: 32,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-48.5426, -27.8167],
              [-48.5171, -27.4283],
              [-48.3773, -27.4582],
              [-48.5426, -27.8167],
            ],
          ],
          [
            [
              [-45.2281, -23.7724],
              [-45.2904, -23.9119],
              [-45.4474, -23.8772],
              [-45.3247, -23.7253],
              [-45.2281, -23.7724],
            ],
          ],
          [
            [
              [-50.8288, -0.5834],
              [-50.8431, -0.716],
              [-51.1004, -0.8263],
              [-51.1004, -0.7306],
              [-50.9564, -0.6071],
              [-50.8288, -0.5834],
            ],
          ],
          [
            [
              [-51.2233, -0.5579],
              [-51.1516, -0.6371],
              [-51.2711, -1.0177],
              [-51.4348, -1.1867],
              [-51.7856, -1.45],
              [-51.9541, -1.4225],
              [-51.8725, -1.2433],
              [-51.7179, -1.1297],
              [-51.6758, -0.8468],
              [-51.5051, -0.6071],
              [-51.3083, -0.5187],
              [-51.2233, -0.5579],
            ],
          ],
          [
            [
              [-50.0958, -0.1323],
              [-49.6535, -0.2411],
              [-49.4252, -0.2304],
              [-49.2154, -0.1412],
              [-48.927, -0.1588],
              [-48.892, -0.2159],
              [-48.4272, -0.2599],
              [-48.3731, -0.3737],
              [-48.4547, -0.4856],
              [-48.5375, -0.8945],
              [-48.6428, -1.0836],
              [-48.842, -1.2133],
              [-48.8392, -1.4426],
              [-49.0658, -1.5319],
              [-49.1819, -1.4016],
              [-49.2228, -1.6212],
              [-49.5565, -1.6454],
              [-49.5587, -1.7242],
              [-49.8236, -1.8166],
              [-50.0521, -1.7039],
              [-50.155, -1.779],
              [-50.4052, -1.831],
              [-50.5597, -1.7995],
              [-50.5879, -1.6845],
              [-50.8042, -1.425],
              [-50.7782, -1.1725],
              [-50.7799, -0.6606],
              [-50.6964, -0.36],
              [-50.5834, -0.1922],
              [-50.3682, -0.1056],
              [-50.0958, -0.1323],
            ],
          ],
          [
            [
              [-50.8949, -0.3062],
              [-51.0328, -0.1682],
              [-50.9473, -0.012],
              [-50.766, 0.0464],
              [-50.6274, -0.0857],
              [-50.8949, -0.3062],
            ],
          ],
          [
            [
              [-49.4857, 0.0692],
              [-49.3475, -0.0097],
              [-49.4387, -0.1205],
              [-49.6869, -0.1354],
              [-49.6281, 0.0576],
              [-49.4857, 0.0692],
            ],
          ],
          [
            [
              [-49.5289, 0.3008],
              [-49.6924, 0.205],
              [-49.8297, 0.0113],
              [-50.0045, -0.0528],
              [-50.0694, 0.0166],
              [-50.2976, 0.012],
              [-50.3668, 0.2145],
              [-50.1073, 0.2222],
              [-49.6502, 0.3864],
              [-49.5289, 0.3008],
            ],
          ],
          [
            [
              [-50.3461, 0.5275],
              [-50.3232, 0.3406],
              [-50.4263, 0.1684],
              [-50.5168, 0.2086],
              [-50.3461, 0.5275],
            ],
          ],
          [
            [
              [-50.0314, 0.5849],
              [-50.0427, 0.5103],
              [-50.2814, 0.3566],
              [-50.2421, 0.593],
              [-50.0314, 0.5849],
            ],
          ],
          [
            [
              [-50.3996, 2.1151],
              [-50.3125, 1.9471],
              [-50.4586, 1.881],
              [-50.5238, 2.0015],
              [-50.3996, 2.1151],
            ],
          ],
          [
            [
              [-56.4818, 1.9416],
              [-56.0197, 1.8335],
              [-55.9168, 1.9222],
              [-55.9258, 2.0617],
              [-56.1168, 2.3331],
              [-55.9892, 2.5208],
              [-55.7243, 2.3969],
              [-55.3996, 2.4302],
              [-55.3545, 2.4949],
              [-55.1125, 2.5278],
              [-55.0177, 2.5906],
              [-54.8803, 2.4473],
              [-54.7032, 2.4458],
              [-54.6153, 2.3263],
              [-54.4766, 2.2141],
              [-54.1349, 2.1107],
              [-53.8668, 2.3049],
              [-53.7482, 2.3193],
              [-53.5318, 2.247],
              [-53.3441, 2.3496],
              [-53.2342, 2.251],
              [-52.9597, 2.1752],
              [-52.7077, 2.3589],
              [-52.5535, 2.5734],
              [-52.5716, 2.6386],
              [-52.3532, 3.0584],
              [-52.0128, 3.6191],
              [-51.9884, 3.7046],
              [-51.6832, 4.0394],
              [-51.6317, 4.0678],
              [-51.5165, 4.4411],
              [-51.3892, 4.3589],
              [-51.2244, 4.165],
              [-51.0799, 3.8823],
              [-51.0927, 3.3763],
              [-50.9445, 2.8222],
              [-50.9028, 2.7938],
              [-50.7424, 2.3785],
              [-50.7126, 2.2368],
              [-50.4665, 1.8154],
              [-50.1832, 1.8189],
              [-49.9102, 1.6643],
              [-49.873, 1.3599],
              [-49.9788, 1.0722],
              [-50.3125, 0.8172],
              [-50.4659, 0.6464],
              [-50.609, 0.3739],
              [-50.7906, 0.1695],
              [-50.9289, 0.1554],
              [-51.0692, -0.0193],
              [-51.2016, -0.0491],
              [-51.3225, -0.2153],
              [-51.4452, -0.4714],
              [-51.6975, -0.7472],
              [-51.7242, -1.0392],
              [-51.9294, -1.1783],
              [-51.9215, -1.3258],
              [-52.0658, -1.405],
              [-52.1954, -1.345],
              [-52.402, -1.4221],
              [-52.1238, -1.6191],
              [-51.9146, -1.571],
              [-51.6695, -1.4016],
              [-51.2683, -1.2204],
              [-51.0329, -1.038],
              [-50.9427, -0.9088],
              [-50.8245, -1.0309],
              [-50.9018, -1.121],
              [-50.8199, -1.2317],
              [-50.8192, -1.4363],
              [-50.6893, -1.6121],
              [-50.5653, -1.8907],
              [-50.3728, -1.9737],
              [-50.2808, -1.8938],
              [-49.9769, -1.8261],
              [-49.6761, -1.9086],
              [-49.4852, -1.7639],
              [-49.3792, -1.8964],
              [-49.4455, -2.1838],
              [-49.3316, -2.2048],
              [-49.232, -1.9482],
              [-48.9656, -1.5997],
              [-48.8119, -1.6213],
              [-48.6421, -1.3857],
              [-48.535, -1.5815],
              [-48.4828, -1.2813],
              [-48.3397, -1.316],
              [-48.1608, -0.9601],
              [-48.154, -0.7818],
              [-47.9461, -0.7405],
              [-47.5845, -0.5782],
              [-47.4147, -0.6613],
              [-47.294, -0.5929],
              [-47.0845, -0.7506],
              [-46.8586, -0.7657],
              [-46.7699, -0.8671],
              [-46.6138, -0.8132],
              [-46.4801, -1.0222],
              [-46.2212, -1.0112],
              [-45.9557, -1.22],
              [-45.8098, -1.2751],
              [-45.696, -1.1606],
              [-45.4013, -1.4379],
              [-45.3402, -1.5832],
              [-45.0914, -1.4641],
              [-44.725, -1.7234],
              [-44.7079, -1.8132],
              [-44.5217, -1.8473],
              [-44.4973, -2.1479],
              [-44.3625, -2.3103],
              [-44.4739, -2.4021],
              [-44.6462, -2.7682],
              [-44.6936, -2.9949],
              [-44.6136, -3.112],
              [-44.433, -2.9503],
              [-44.3681, -2.5496],
              [-44.073, -2.3995],
              [-44.0141, -2.6347],
              [-43.7386, -2.5172],
              [-43.477, -2.5377],
              [-43.4544, -2.3414],
              [-43.3118, -2.3465],
              [-42.71, -2.5603],
              [-42.453, -2.7522],
              [-42.2222, -2.8124],
              [-42.004, -2.8126],
              [-41.8213, -2.7336],
              [-41.675, -2.8796],
              [-41.3431, -2.9203],
              [-40.5816, -2.8273],
              [-40.4996, -2.7863],
              [-39.9857, -2.8484],
              [-39.4855, -3.1458],
              [-39.3583, -3.1835],
              [-38.6597, -3.6764],
              [-38.4573, -3.7184],
              [-38.378, -3.8697],
              [-38.2513, -3.9618],
              [-38.1067, -4.1579],
              [-37.8542, -4.3759],
              [-37.7686, -4.3964],
              [-37.6072, -4.6081],
              [-37.3248, -4.6965],
              [-37.1829, -4.9109],
              [-36.8988, -4.9382],
              [-36.7909, -5.0512],
              [-36.59, -5.103],
              [-36.1312, -5.0959],
              [-35.9972, -5.0475],
              [-35.5106, -5.1437],
              [-35.4205, -5.2115],
              [-35.2623, -5.4833],
              [-35.0928, -6.1858],
              [-35.0382, -6.2365],
              [-34.97, -6.6173],
              [-34.8594, -7.0715],
              [-34.7974, -7.1585],
              [-34.8053, -7.6227],
              [-34.9, -7.718],
              [-34.8116, -7.9059],
              [-34.9321, -8.2421],
              [-34.9399, -8.3508],
              [-35.1273, -8.8062],
              [-35.1475, -8.9119],
              [-35.3051, -9.193],
              [-35.482, -9.3679],
              [-35.5826, -9.5329],
              [-35.8532, -9.7312],
              [-35.8827, -9.8556],
              [-36.0363, -10.0641],
              [-36.251, -10.2482],
              [-36.4081, -10.5021],
              [-36.5766, -10.5505],
              [-36.9031, -10.7729],
              [-37.0397, -10.9492],
              [-37.1725, -11.2035],
              [-37.2858, -11.3171],
              [-37.4347, -11.5693],
              [-37.6547, -12.0497],
              [-38.0509, -12.641],
              [-38.3204, -12.9352],
              [-38.5097, -13.0214],
              [-38.5089, -12.7229],
              [-38.7381, -12.7366],
              [-38.7363, -12.8827],
              [-38.8418, -13.1612],
              [-39.0625, -13.4],
              [-38.9678, -13.674],
              [-39.0112, -13.8325],
              [-38.9237, -14.0041],
              [-39.0671, -14.675],
              [-39.0227, -14.8159],
              [-39.0018, -15.232],
              [-38.9239, -15.7156],
              [-38.8644, -15.8434],
              [-39.0541, -16.4365],
              [-39.136, -16.8002],
              [-39.1236, -16.8979],
              [-39.2086, -17.1622],
              [-39.1837, -17.6008],
              [-39.1371, -17.6796],
              [-39.2751, -17.8669],
              [-39.4991, -18.0056],
              [-39.625, -18.1926],
              [-39.7247, -18.5187],
              [-39.7411, -18.9021],
              [-39.6981, -19.2399],
              [-39.7098, -19.4161],
              [-39.8349, -19.6572],
              [-40.0268, -19.7644],
              [-40.191, -20.1899],
              [-40.3487, -20.5001],
              [-40.5834, -20.802],
              [-40.7317, -20.8438],
              [-40.9651, -21.274],
              [-41.0719, -21.5233],
              [-40.9673, -21.9475],
              [-40.9953, -22.0143],
              [-41.2437, -22.1452],
              [-41.6665, -22.2818],
              [-41.9663, -22.5354],
              [-41.9896, -22.8263],
              [-42.0582, -22.9538],
              [-42.6243, -22.9395],
              [-43.0314, -22.9743],
              [-43.0756, -22.6657],
              [-43.2702, -22.747],
              [-43.1621, -22.8957],
              [-43.2201, -22.9953],
              [-43.5422, -23.0494],
              [-43.8644, -22.8962],
              [-44.0562, -22.9395],
              [-44.191, -23.0488],
              [-44.4357, -22.9968],
              [-44.6732, -23.0593],
              [-44.7141, -23.1866],
              [-44.5838, -23.3532],
              [-44.9193, -23.3488],
              [-45.0802, -23.4977],
              [-45.4335, -23.6647],
              [-45.5093, -23.8376],
              [-45.8644, -23.7564],
              [-46.0797, -23.8278],
              [-46.2341, -23.986],
              [-46.4067, -24.009],
              [-46.9665, -24.2991],
              [-47.1814, -24.5334],
              [-47.5408, -24.7034],
              [-47.8256, -24.8941],
              [-47.9518, -25.2013],
              [-48.2473, -25.4109],
              [-48.4072, -25.2839],
              [-48.4602, -25.4709],
              [-48.4327, -25.6225],
              [-48.6127, -26.0406],
              [-48.5826, -26.1572],
              [-48.7982, -26.3049],
              [-48.6436, -26.3849],
              [-48.6822, -26.7192],
              [-48.5752, -27.0162],
              [-48.6475, -27.4796],
              [-48.6475, -27.6474],
              [-48.5655, -27.8603],
              [-48.6553, -28.2128],
              [-48.8153, -28.6104],
              [-48.9406, -28.6454],
              [-49.3428, -28.9235],
              [-49.5523, -29.1274],
              [-49.8107, -29.4431],
              [-50.0453, -29.8145],
              [-50.3157, -30.4615],
              [-50.7214, -31.0445],
              [-51.1518, -31.4815],
              [-51.5633, -31.7774],
              [-51.8635, -31.9317],
              [-52.0326, -32.109],
              [-52.088, -31.8586],
              [-51.9228, -31.8687],
              [-51.6662, -31.7697],
              [-51.435, -31.4965],
              [-51.3345, -31.5192],
              [-51.1796, -31.359],
              [-51.1761, -31.1493],
              [-50.9877, -31.0456],
              [-50.9393, -30.8961],
              [-50.7311, -30.7924],
              [-50.5373, -30.3204],
              [-50.8975, -30.3105],
              [-51.1225, -30.256],
              [-51.2902, -30.6244],
              [-51.3833, -30.6589],
              [-51.3619, -30.8792],
              [-51.443, -31.081],
              [-51.6236, -31.1353],
              [-51.6528, -31.2592],
              [-51.9228, -31.3069],
              [-52.0008, -31.5512],
              [-52.2456, -31.841],
              [-52.1698, -31.9365],
              [-52.2586, -32.0536],
              [-52.1274, -32.1775],
              [-52.2534, -32.2825],
              [-52.3753, -32.4987],
              [-52.4709, -32.801],
              [-52.6217, -33.102],
              [-52.8195, -33.3225],
              [-53.3791, -33.7407],
              [-53.5396, -33.6493],
              [-53.5115, -33.0992],
              [-53.3273, -32.9736],
              [-53.1269, -32.7548],
              [-53.1861, -32.6467],
              [-53.4156, -32.5642],
              [-53.6487, -32.3386],
              [-53.7718, -32.0471],
              [-54.0688, -31.8799],
              [-54.1351, -31.909],
              [-54.4637, -31.6719],
              [-54.5915, -31.471],
              [-54.8198, -31.4353],
              [-55.26, -31.2287],
              [-55.3539, -31.0563],
              [-55.5919, -30.8483],
              [-55.8643, -31.0768],
              [-56.0114, -31.0821],
              [-56.0114, -30.7982],
              [-56.2351, -30.5654],
              [-56.3726, -30.4859],
              [-56.8313, -30.102],
              [-57.1023, -30.1212],
              [-57.2126, -30.2873],
              [-57.536, -30.2744],
              [-57.6117, -30.183],
              [-57.3252, -29.981],
              [-57.3085, -29.8485],
              [-57.0942, -29.752],
              [-56.677, -29.3134],
              [-56.6173, -29.1609],
              [-56.4158, -29.0514],
              [-56.1847, -28.7442],
              [-55.8981, -28.4607],
              [-55.7725, -28.232],
              [-55.6047, -28.1169],
              [-55.4407, -28.0789],
              [-55.3299, -27.9281],
              [-55.0258, -27.8402],
              [-54.9132, -27.7369],
              [-54.8275, -27.5451],
              [-54.6997, -27.553],
              [-54.4608, -27.4126],
              [-54.2869, -27.4284],
              [-54.1895, -27.2609],
              [-53.8192, -27.1397],
              [-53.7107, -26.8738],
              [-53.746, -26.6939],
              [-53.7244, -26.3761],
              [-53.6667, -26.2192],
              [-53.8262, -25.9727],
              [-53.91, -25.6292],
              [-54.2148, -25.5315],
              [-54.4606, -25.6421],
              [-54.6002, -25.5749],
              [-54.6098, -25.4328],
              [-54.4297, -25.1306],
              [-54.4623, -25.0372],
              [-54.3213, -24.6284],
              [-54.2611, -24.3295],
              [-54.3346, -24.1489],
              [-54.2453, -24.0506],
              [-54.6126, -23.8112],
              [-54.9436, -23.9692],
              [-55.2009, -24.0195],
              [-55.398, -23.9768],
              [-55.4453, -23.7354],
              [-55.5305, -23.6035],
              [-55.5352, -23.2291],
              [-55.6378, -23.0007],
              [-55.6312, -22.6268],
              [-55.7516, -22.4163],
              [-55.8929, -22.3068],
              [-56.1968, -22.2793],
              [-56.4218, -22.0745],
              [-56.6547, -22.2373],
              [-56.8429, -22.289],
              [-57.2138, -22.1882],
              [-57.385, -22.2133],
              [-57.8439, -22.1437],
              [-57.9868, -22.0353],
              [-57.8956, -21.6884],
              [-57.9299, -21.4531],
              [-57.8556, -21.3307],
              [-57.8254, -20.9986],
              [-57.9087, -20.8816],
              [-57.8602, -20.7303],
              [-57.991, -20.6206],
              [-57.9894, -20.4331],
              [-58.083, -20.3762],
              [-58.1588, -20.1651],
              [-57.8597, -19.9801],
              [-58.1246, -19.7299],
              [-57.789, -19.0592],
              [-57.7823, -18.9104],
              [-57.5511, -18.1836],
              [-57.7301, -17.8461],
              [-57.7908, -17.5558],
              [-58.0102, -17.4968],
              [-58.2316, -17.3297],
              [-58.3812, -17.2672],
              [-58.4663, -16.8873],
              [-58.4803, -16.6837],
              [-58.3561, -16.5095],
              [-58.3396, -16.2893],
              [-58.4647, -16.3313],
              [-60.1298, -16.2731],
              [-60.1798, -16.222],
              [-60.238, -15.5027],
              [-60.5295, -15.1437],
              [-60.275, -15.0951],
              [-60.2917, -14.6301],
              [-60.4735, -14.1172],
              [-60.3897, -13.9749],
              [-60.4653, -13.8166],
              [-60.8967, -13.5529],
              [-61.3477, -13.4937],
              [-61.848, -13.5308],
              [-61.8686, -13.4639],
              [-62.2216, -13.1213],
              [-62.3818, -13.1399],
              [-62.4927, -13.065],
              [-62.8074, -12.9889],
              [-62.9753, -12.8529],
              [-63.075, -12.6527],
              [-63.2473, -12.7013],
              [-63.3933, -12.6645],
              [-63.4961, -12.5533],
              [-63.8014, -12.4549],
              [-63.9212, -12.5443],
              [-64.3957, -12.4573],
              [-64.4691, -12.2611],
              [-64.7163, -12.1465],
              [-64.7925, -12.0325],
              [-64.9974, -11.9963],
              [-65.0656, -11.7531],
              [-65.1962, -11.7418],
              [-65.1954, -11.5922],
              [-65.353, -11.3906],
              [-65.36, -11.2188],
              [-65.303, -10.9907],
              [-65.4047, -10.7992],
              [-65.45, -10.4681],
              [-65.2881, -10.2162],
              [-65.3333, -9.9268],
              [-65.3044, -9.8257],
              [-65.4518, -9.6814],
              [-65.584, -9.8372],
              [-65.7886, -9.733],
              [-65.8744, -9.7813],
              [-66.1907, -9.8008],
              [-66.4529, -9.8887],
              [-66.6319, -9.9043],
              [-66.9027, -10.0931],
              [-67.0643, -10.2569],
              [-67.3378, -10.3261],
              [-67.5846, -10.5018],
              [-67.7558, -10.7142],
              [-67.8626, -10.6588],
              [-68.0979, -10.6977],
              [-68.2933, -10.979],
              [-68.6157, -11.1125],
              [-68.787, -11.126],
              [-68.884, -11.0164],
              [-69.3959, -10.935],
              [-69.5776, -10.9523],
              [-69.9561, -10.9193],
              [-70.2894, -11.0647],
              [-70.4373, -11.0367],
              [-70.5341, -10.9381],
              [-70.6413, -11.0108],
              [-70.6384, -9.8518],
              [-70.5434, -9.727],
              [-70.6808, -9.5277],
              [-70.8755, -9.6605],
              [-71.0154, -9.8175],
              [-71.1965, -9.9399],
              [-71.3914, -10.0068],
              [-72.1957, -10.0056],
              [-72.1604, -9.8877],
              [-72.2617, -9.6674],
              [-72.4302, -9.4822],
              [-72.8133, -9.4109],
              [-73.215, -9.409],
              [-72.9594, -9.0856],
              [-72.9761, -8.9854],
              [-73.1746, -8.7207],
              [-73.3506, -8.5983],
              [-73.3564, -8.4798],
              [-73.5265, -8.3724],
              [-73.668, -8.0138],
              [-73.7788, -7.9541],
              [-73.7122, -7.7882],
              [-73.9694, -7.5847],
              [-73.9794, -7.3745],
              [-73.7608, -7.3374],
              [-73.7345, -7.2251],
              [-73.8123, -7.1029],
              [-73.7655, -6.9042],
              [-73.6851, -6.8119],
              [-73.3707, -6.5839],
              [-73.199, -6.5456],
              [-73.1318, -6.4353],
              [-73.2348, -6.0776],
              [-73.14, -5.8785],
              [-73.0014, -5.7088],
              [-72.9735, -5.4661],
              [-72.8967, -5.2716],
              [-72.9179, -5.1321],
              [-72.6273, -5.0224],
              [-72.2511, -4.7588],
              [-72.0711, -4.6051],
              [-71.7743, -4.4815],
              [-70.9687, -4.3853],
              [-70.8322, -4.1794],
              [-70.5853, -4.1945],
              [-70.4299, -4.1342],
              [-70.3111, -4.1709],
              [-70.2313, -4.3037],
              [-69.9649, -4.2365],
              [-69.9472, -4.2011],
              [-69.7712, -3.2342],
              [-69.5657, -2.106],
              [-69.3995, -1.1827],
              [-69.45, -0.9968],
              [-69.6284, -0.7334],
              [-69.6321, -0.5069],
              [-69.9444, -0.3055],
              [-70.0738, -0.1249],
              [-70.0542, 0.5881],
              [-69.6192, 0.6507],
              [-69.5037, 0.7296],
              [-69.3626, 0.6409],
              [-69.1375, 0.6501],
              [-69.2043, 0.9437],
              [-69.3709, 1.063],
              [-69.7626, 1.0911],
              [-69.8522, 1.0594],
              [-69.8488, 1.6689],
              [-69.5803, 1.7702],
              [-69.3524, 1.7202],
              [-68.1633, 1.7213],
              [-68.2608, 1.8582],
              [-68.2008, 2.0078],
              [-67.9982, 1.75],
              [-67.7905, 1.8126],
              [-67.5929, 2.0548],
              [-67.4397, 2.1396],
              [-67.3406, 2.0901],
              [-67.1172, 1.7098],
              [-67.0736, 1.5412],
              [-67.0982, 1.2534],
              [-67.0652, 1.1727],
              [-66.8751, 1.2225],
              [-66.3462, 0.7594],
              [-66.1563, 0.733],
              [-65.9741, 0.807],
              [-65.7454, 0.9742],
              [-65.5829, 0.9777],
              [-65.517, 0.8628],
              [-65.5873, 0.7391],
              [-65.4512, 0.6901],
              [-65.3272, 0.9103],
              [-65.203, 0.9238],
              [-65.1368, 1.1269],
              [-65.0225, 1.14],
              [-64.8393, 1.2708],
              [-64.7309, 1.2476],
              [-64.409, 1.5075],
              [-64.3015, 1.4467],
              [-64.0809, 1.6474],
              [-64.0536, 1.8937],
              [-63.995, 1.958],
              [-63.7626, 1.9857],
              [-63.6175, 2.1012],
              [-63.4118, 2.1494],
              [-63.3848, 2.4206],
              [-63.8313, 2.4286],
              [-64.048, 2.4713],
              [-63.9979, 2.706],
              [-64.2229, 3.124],
              [-64.2456, 3.4189],
              [-64.203, 3.5947],
              [-64.3242, 3.7241],
              [-64.5314, 3.8535],
              [-64.6629, 3.997],
              [-64.5897, 4.1189],
              [-64.365, 4.1519],
              [-64.1251, 4.0885],
              [-64.0638, 3.9116],
              [-63.8751, 3.9498],
              [-63.6228, 3.9352],
              [-63.5107, 3.8545],
              [-63.4254, 3.9684],
              [-63.286, 3.9208],
              [-62.9971, 3.5991],
              [-62.8894, 3.5608],
              [-62.7496, 3.6604],
              [-62.7662, 4.0207],
              [-62.561, 4.0378],
              [-62.3842, 4.1735],
              [-62.1927, 4.0947],
              [-61.9315, 4.1464],
              [-61.7189, 4.2547],
              [-61.5421, 4.263],
              [-61.4582, 4.4191],
              [-61.2973, 4.5237],
              [-61.012, 4.5183],
              [-60.9034, 4.6996],
              [-60.7637, 4.7551],
              [-60.6126, 4.9006],
              [-60.6643, 5.1703],
              [-60.7399, 5.2021],
              [-60.4564, 5.1747],
              [-60.2134, 5.2672],
              [-59.9831, 5.0859],
              [-60.0877, 4.6075],
              [-59.6867, 4.3812],
              [-59.7385, 4.2028],
              [-59.5292, 3.9319],
              [-59.6801, 3.7017],
              [-59.8377, 3.6093],
              [-59.8285, 3.3834],
              [-59.9594, 3.0665],
              [-60.0001, 2.694],
              [-59.9031, 2.3596],
              [-59.7417, 2.2638],
              [-59.7645, 1.9205],
              [-59.6825, 1.7656],
              [-59.5727, 1.7272],
              [-59.2421, 1.3778],
              [-59.0111, 1.3276],
              [-58.8979, 1.2178],
              [-58.6826, 1.2825],
              [-58.5189, 1.2673],
              [-58.5137, 1.4389],
              [-58.3319, 1.5934],
              [-58.0272, 1.5169],
              [-57.991, 1.6504],
              [-57.7908, 1.7049],
              [-57.5612, 1.709],
              [-57.4295, 1.9003],
              [-57.1045, 2.0215],
              [-57.0112, 1.9186],
              [-56.8065, 1.8736],
              [-56.4818, 1.9416],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Brazil",
        NAME_LONG: "Brazil",
        ADM0_A3: "BRA",
        ISO_A2: "BR",
        ISO_A3: "BRA",
        WB_A2: "BR",
        WB_A3: "BRA",
      },
      id: 33,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-59.4269, 13.1604],
            [-59.6081, 13.0847],
            [-59.6542, 13.2951],
            [-59.4269, 13.1604],
          ],
        ],
      },
      properties: {
        NAME: "Barbados",
        NAME_LONG: "Barbados",
        ADM0_A3: "BRB",
        ISO_A2: "BB",
        ISO_A3: "BRB",
        WB_A2: "BB",
        WB_A3: "BRB",
      },
      id: 34,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [115.1462, 4.9085],
              [115.2727, 4.6368],
              [115.3244, 4.3146],
              [115.113, 4.3824],
              [115.0355, 4.6212],
              [115.0298, 4.8206],
              [115.1462, 4.9085],
            ],
          ],
          [
            [
              [113.9988, 4.6011],
              [114.2517, 4.5927],
              [114.5293, 4.6938],
              [114.7227, 4.876],
              [114.9284, 5.0006],
              [114.9817, 4.8891],
              [114.7518, 4.7385],
              [114.8364, 4.3572],
              [114.7669, 4.1318],
              [114.5866, 4.0214],
              [114.4199, 4.2601],
              [114.2967, 4.3167],
              [114.2456, 4.4814],
              [113.9988, 4.6011],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Brunei",
        NAME_LONG: "Brunei Darussalam",
        ADM0_A3: "BRN",
        ISO_A2: "BN",
        ISO_A3: "BRN",
        WB_A2: "BN",
        WB_A3: "BRN",
      },
      id: 35,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [91.6329, 27.7594],
            [91.5951, 27.5464],
            [91.7501, 27.4162],
            [91.9751, 27.4724],
            [92.0848, 27.3042],
            [91.9959, 27.158],
            [92.0839, 26.9373],
            [92.0359, 26.8548],
            [91.7953, 26.8535],
            [91.5206, 26.7976],
            [91.4063, 26.8695],
            [91.1982, 26.8023],
            [90.5878, 26.7801],
            [90.3826, 26.8918],
            [90.1774, 26.8321],
            [90.0892, 26.7417],
            [89.8221, 26.701],
            [89.3417, 26.8543],
            [89.1846, 26.8106],
            [88.9545, 26.9126],
            [88.7301, 27.151],
            [88.8923, 27.3155],
            [89.051, 27.6083],
            [89.336, 27.8691],
            [89.5615, 28.1346],
            [89.7558, 28.1844],
            [89.8629, 28.2958],
            [90.2618, 28.3354],
            [90.5877, 28.2333],
            [90.6839, 28.0871],
            [91.0926, 27.9717],
            [91.2697, 28.0729],
            [91.6217, 27.9507],
            [91.6329, 27.7594],
          ],
        ],
      },
      properties: {
        NAME: "Bhutan",
        NAME_LONG: "Bhutan",
        ADM0_A3: "BTN",
        ISO_A2: "BT",
        ISO_A3: "BTN",
        WB_A2: "BT",
        WB_A3: "BTN",
      },
      id: 36,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.2598, -17.7941],
            [25.2261, -17.9319],
            [25.3875, -18.139],
            [25.5085, -18.3991],
            [25.7619, -18.6303],
            [25.8153, -18.814],
            [25.9407, -18.9213],
            [25.9565, -19.1221],
            [26.1303, -19.5011],
            [26.3122, -19.6514],
            [26.731, -19.9359],
            [27.0266, -20.0101],
            [27.2149, -20.1105],
            [27.3407, -20.473],
            [27.6981, -20.5091],
            [27.6748, -21.0901],
            [27.885, -21.3102],
            [27.9904, -21.5519],
            [28.4646, -21.6603],
            [28.6297, -21.6513],
            [29.0387, -21.7979],
            [29.0216, -21.9828],
            [29.2393, -22.0726],
            [29.3501, -22.1867],
            [29.0478, -22.2203],
            [28.9125, -22.4537],
            [28.6239, -22.5628],
            [28.3386, -22.5846],
            [27.9375, -22.9638],
            [27.8955, -23.0795],
            [27.608, -23.2167],
            [27.5489, -23.3608],
            [27.35, -23.3915],
            [27.0042, -23.6458],
            [26.8497, -24.2481],
            [26.5312, -24.4587],
            [26.4044, -24.6328],
            [26.2788, -24.6285],
            [25.8684, -24.7482],
            [25.8767, -24.886],
            [25.5873, -25.6195],
            [25.3865, -25.7435],
            [25.0517, -25.7379],
            [24.7986, -25.8292],
            [24.2967, -25.7235],
            [24.2277, -25.649],
            [23.9248, -25.6292],
            [23.4593, -25.2822],
            [23.2657, -25.264],
            [23.007, -25.3108],
            [22.8446, -25.4811],
            [22.7464, -25.7553],
            [22.7194, -25.9843],
            [22.5451, -26.2074],
            [22.2573, -26.3409],
            [22.0577, -26.6176],
            [21.7675, -26.7043],
            [21.6872, -26.8552],
            [21.3808, -26.824],
            [21.1225, -26.8653],
            [20.8519, -26.8065],
            [20.6935, -26.889],
            [20.6089, -26.6861],
            [20.6225, -26.4275],
            [20.8414, -26.1313],
            [20.7941, -25.8939],
            [20.3649, -25.0332],
            [20.1189, -24.8874],
            [19.9814, -24.7525],
            [19.9798, -23.2045],
            [19.9783, -22.0007],
            [20.972, -22.0007],
            [20.9848, -21.964],
            [20.9807, -20.7507],
            [20.9776, -19.7508],
            [20.9751, -18.3193],
            [21.4757, -18.2995],
            [22.9814, -18.02],
            [23.3115, -18.0098],
            [23.5015, -18.2375],
            [23.5792, -18.4679],
            [23.6454, -18.466],
            [24.1831, -18.0294],
            [24.4216, -17.9565],
            [24.5778, -18.0444],
            [24.7253, -17.8959],
            [24.9581, -17.8009],
            [25.2598, -17.7941],
          ],
        ],
      },
      properties: {
        NAME: "Botswana",
        NAME_LONG: "Botswana",
        ADM0_A3: "BWA",
        ISO_A2: "BW",
        ISO_A3: "BWA",
        WB_A2: "BW",
        WB_A3: "BWA",
      },
      id: 37,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8611, 10.9192],
            [23.0056, 10.6868],
            [23.2912, 10.4397],
            [23.624, 9.9078],
            [23.674, 9.6903],
            [23.6061, 9.5372],
            [23.6322, 9.2776],
            [23.4577, 9.1462],
            [23.4823, 8.7834],
            [23.7213, 8.702],
            [24.1703, 8.6893],
            [24.2437, 8.5702],
            [24.1314, 8.3431],
            [24.3321, 8.2457],
            [24.4311, 8.2714],
            [24.8321, 8.1657],
            [25.0896, 7.8849],
            [25.2169, 7.8641],
            [25.2794, 7.6595],
            [25.1904, 7.5012],
            [25.36, 7.3356],
            [26.0261, 6.9967],
            [26.081, 6.8423],
            [26.378, 6.6533],
            [26.277, 6.4774],
            [26.2896, 6.3872],
            [26.452, 6.2803],
            [26.528, 6.0432],
            [26.705, 6.0098],
            [26.8189, 5.8946],
            [26.9811, 5.8592],
            [27.1704, 5.7203],
            [27.2607, 5.5503],
            [27.2201, 5.4409],
            [27.3013, 5.2052],
            [27.4413, 5.0707],
            [27.1164, 5.2003],
            [26.9616, 5.1512],
            [26.8671, 5.0375],
            [26.7606, 5.0881],
            [26.4627, 5.0596],
            [26.13, 5.2589],
            [26.0269, 5.19],
            [25.7328, 5.2589],
            [25.5813, 5.3749],
            [25.3637, 5.3106],
            [25.3076, 5.0323],
            [24.9581, 4.9914],
            [24.6644, 4.9244],
            [24.4596, 5.1074],
            [24.1629, 4.9078],
            [23.9483, 4.8177],
            [23.8167, 4.8206],
            [23.5666, 4.7244],
            [23.3884, 4.5873],
            [23.1687, 4.7381],
            [22.8984, 4.8236],
            [22.786, 4.7246],
            [22.6895, 4.4917],
            [22.4927, 4.174],
            [22.2073, 4.1503],
            [22.0089, 4.2424],
            [21.7229, 4.2951],
            [21.5381, 4.2447],
            [21.2989, 4.3302],
            [21.1581, 4.3102],
            [21.0842, 4.3882],
            [20.8715, 4.4534],
            [20.6031, 4.4097],
            [20.4523, 4.5289],
            [20.3389, 4.7719],
            [19.7196, 5.136],
            [19.409, 5.1304],
            [19.0833, 4.9093],
            [19.0116, 4.765],
            [18.8282, 4.56],
            [18.7211, 4.3774],
            [18.5371, 4.3061],
            [18.6197, 4.1696],
            [18.6453, 3.9824],
            [18.593, 3.7098],
            [18.6264, 3.4769],
            [18.5134, 3.5922],
            [17.8564, 3.5373],
            [17.8083, 3.6086],
            [17.6273, 3.6263],
            [17.4589, 3.7083],
            [17.3344, 3.6185],
            [16.9686, 3.5335],
            [16.6394, 3.5286],
            [16.5677, 3.4644],
            [16.4908, 3.0678],
            [16.4791, 2.8363],
            [16.1967, 2.2365],
            [16.0903, 2.5105],
            [16.0924, 2.8633],
            [15.914, 3.098],
            [15.7856, 3.108],
            [15.1713, 3.759],
            [15.0849, 3.8858],
            [15.1026, 4.1124],
            [15.0006, 4.4032],
            [14.7173, 4.622],
            [14.6632, 5.1418],
            [14.5239, 5.2797],
            [14.5973, 5.4115],
            [14.6311, 5.738],
            [14.5848, 5.9234],
            [14.3977, 6.0192],
            [14.535, 6.1901],
            [14.719, 6.2579],
            [14.9325, 6.7103],
            [15.0422, 6.7908],
            [15.2239, 7.2475],
            [15.4328, 7.3897],
            [15.481, 7.5233],
            [15.7939, 7.458],
            [16.0652, 7.5912],
            [16.3708, 7.6725],
            [16.4074, 7.7961],
            [16.5487, 7.87],
            [16.6245, 7.6687],
            [16.7686, 7.5502],
            [16.8807, 7.6329],
            [17.1019, 7.6777],
            [17.2509, 7.8228],
            [17.6798, 7.9852],
            [17.8979, 7.9675],
            [18.0705, 8.0192],
            [18.5893, 8.0479],
            [18.6726, 8.2075],
            [18.813, 8.2764],
            [19.1241, 8.6751],
            [18.9291, 8.7965],
            [18.8927, 8.8979],
            [19.1006, 9.0153],
            [19.3694, 9.0004],
            [19.8895, 9.0464],
            [20.0687, 9.1381],
            [20.3594, 9.1164],
            [20.4961, 9.2709],
            [20.8261, 9.4181],
            [21.2569, 9.9757],
            [21.3743, 9.9731],
            [21.4854, 10.1643],
            [21.6563, 10.2337],
            [21.7511, 10.4118],
            [21.7226, 10.6367],
            [22.1764, 10.8162],
            [22.253, 10.9153],
            [22.4605, 11.0008],
            [22.8611, 10.9192],
          ],
        ],
      },
      properties: {
        NAME: "Central African Rep.",
        NAME_LONG: "Central African Republic",
        ADM0_A3: "CAF",
        ISO_A2: "CF",
        ISO_A3: "CAF",
        WB_A2: "CF",
        WB_A3: "CAF",
      },
      id: 38,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-60.5011, 47.0012],
              [-60.3108, 46.8544],
              [-60.3505, 46.6159],
              [-60.5094, 46.3685],
              [-60.4269, 46.284],
              [-60.7008, 46.088],
              [-60.9819, 45.8207],
              [-60.7301, 45.7139],
              [-60.7223, 45.8955],
              [-60.6213, 46.101],
              [-60.351, 46.3121],
              [-59.8822, 46.1767],
              [-59.862, 45.9357],
              [-60.3954, 45.6425],
              [-60.7269, 45.5656],
              [-61.0097, 45.6081],
              [-61.3349, 45.5662],
              [-61.4553, 45.7099],
              [-61.5411, 46.0405],
              [-61.0948, 46.4539],
              [-60.8692, 46.8],
              [-60.6033, 47.0336],
              [-60.5011, 47.0012],
            ],
          ],
          [
            [
              [-64.0349, 46.8265],
              [-63.8336, 46.6124],
              [-63.1947, 46.4311],
              [-62.9532, 46.4241],
              [-62.5169, 46.4758],
              [-62.0991, 46.4837],
              [-62.4373, 46.2163],
              [-62.5764, 46.239],
              [-62.5452, 45.9718],
              [-62.7774, 45.9643],
              [-62.9316, 46.0426],
              [-62.9974, 46.1793],
              [-63.2334, 46.1455],
              [-63.6278, 46.2238],
              [-64.108, 46.5444],
              [-64.4168, 46.6828],
              [-64.2313, 46.9033],
              [-64.0349, 46.8265],
            ],
          ],
          [
            [
              [-74.713, 44.9993],
              [-74.3996, 45.1448],
              [-74.0485, 45.2325],
              [-73.6874, 45.4312],
              [-73.5573, 45.4143],
              [-73.4128, 45.7515],
              [-73.2593, 45.8541],
              [-73.1398, 46.0583],
              [-72.731, 46.1766],
              [-72.496, 46.3605],
              [-72.2743, 46.4206],
              [-72.1899, 46.5262],
              [-71.8457, 46.6683],
              [-71.7142, 46.6322],
              [-71.3025, 46.7452],
              [-71.1579, 46.8408],
              [-70.5349, 47.0106],
              [-70.0311, 47.4696],
              [-69.8022, 47.6224],
              [-69.0544, 48.2324],
              [-68.2056, 48.6419],
              [-67.962, 48.7016],
              [-66.6221, 49.1226],
              [-66.2193, 49.2079],
              [-65.5658, 49.2665],
              [-64.9635, 49.2193],
              [-64.6134, 49.123],
              [-64.2212, 48.8944],
              [-64.2782, 48.7258],
              [-64.1622, 48.6296],
              [-64.3291, 48.4196],
              [-64.7047, 48.3237],
              [-64.7794, 48.204],
              [-65.2856, 48.0126],
              [-65.7838, 48.1156],
              [-65.953, 48.1954],
              [-66.262, 48.0058],
              [-65.8179, 47.9074],
              [-65.6288, 47.6706],
              [-65.4774, 47.6837],
              [-65.2093, 47.8197],
              [-64.8263, 47.8155],
              [-64.6849, 47.7227],
              [-64.8922, 47.5615],
              [-64.9596, 47.2943],
              [-65.3315, 47.1006],
              [-64.8254, 47.0891],
              [-64.9058, 46.8869],
              [-64.7136, 46.6872],
              [-64.7198, 46.5763],
              [-64.514, 46.2394],
              [-64.2533, 46.2344],
              [-63.8131, 46.1403],
              [-63.9049, 45.9803],
              [-63.8059, 45.8887],
              [-63.1898, 45.7364],
              [-63.0705, 45.7784],
              [-62.7386, 45.7625],
              [-62.4704, 45.6145],
              [-61.9243, 45.8891],
              [-61.8942, 45.7037],
              [-61.6198, 45.6143],
              [-61.4705, 45.6831],
              [-61.0712, 45.2239],
              [-61.3318, 45.2307],
              [-61.6396, 45.0873],
              [-62.5515, 44.8676],
              [-63.0243, 44.6968],
              [-63.2813, 44.6435],
              [-63.5876, 44.6642],
              [-63.5233, 44.5181],
              [-63.7159, 44.4561],
              [-63.9294, 44.5113],
              [-63.943, 44.6205],
              [-64.3539, 44.4561],
              [-64.3397, 44.3326],
              [-64.5609, 44.08],
              [-64.8157, 43.9541],
              [-65.0344, 43.7123],
              [-65.2504, 43.6734],
              [-65.4626, 43.5276],
              [-65.6677, 43.5001],
              [-65.7716, 43.6688],
              [-66.1217, 43.7436],
              [-66.2103, 44.1057],
              [-66.1137, 44.3434],
              [-65.8479, 44.5565],
              [-65.7859, 44.6964],
              [-64.8632, 45.1396],
              [-64.5107, 45.2286],
              [-64.2235, 45.1084],
              [-64.1151, 45.2161],
              [-63.8253, 45.2796],
              [-63.593, 45.3952],
              [-64.066, 45.413],
              [-64.3881, 45.3673],
              [-64.5762, 45.4156],
              [-64.7174, 45.3246],
              [-64.8331, 45.484],
              [-64.4291, 45.7099],
              [-64.5854, 45.8259],
              [-64.7563, 45.6278],
              [-65.3151, 45.4592],
              [-65.545, 45.3296],
              [-65.9094, 45.2103],
              [-66.1285, 45.2791],
              [-66.1894, 45.1778],
              [-66.491, 45.1556],
              [-66.7458, 45.0645],
              [-67.0864, 45.0976],
              [-67.176, 45.1787],
              [-67.3563, 45.1659],
              [-67.475, 45.2824],
              [-67.45, 45.6108],
              [-67.8001, 45.6998],
              [-67.7728, 45.8281],
              [-67.8052, 47.0356],
              [-68.197, 47.3414],
              [-68.5397, 47.2999],
              [-68.9062, 47.1902],
              [-69.0402, 47.2491],
              [-69.0697, 47.4319],
              [-69.2676, 47.4398],
              [-70.0078, 46.7041],
              [-70.0743, 46.4195],
              [-70.1884, 46.3584],
              [-70.3114, 46.0719],
              [-70.2625, 45.923],
              [-70.4074, 45.7315],
              [-70.6868, 45.5727],
              [-70.8338, 45.3931],
              [-71.1417, 45.2523],
              [-71.3949, 45.2415],
              [-71.5041, 45.0137],
              [-73.3992, 45.0044],
              [-74.713, 44.9993],
            ],
          ],
          [
            [
              [-126.7422, 49.8564],
              [-126.6426, 49.7587],
              [-126.6794, 49.5918],
              [-126.9125, 49.6916],
              [-126.7422, 49.8564],
            ],
          ],
          [
            [
              [-64.1328, 49.9501],
              [-63.9447, 49.8961],
              [-63.3747, 49.8294],
              [-62.9621, 49.7405],
              [-62.5343, 49.6047],
              [-62.2043, 49.4142],
              [-61.8826, 49.3545],
              [-61.6608, 49.151],
              [-61.8116, 49.069],
              [-62.2482, 49.0679],
              [-63.0933, 49.2329],
              [-63.5868, 49.39],
              [-63.6414, 49.5135],
              [-63.9252, 49.6749],
              [-64.4247, 49.8085],
              [-64.4513, 49.9095],
              [-64.1328, 49.9501],
            ],
          ],
          [
            [
              [-126.1859, 50.7416],
              [-126.2881, 50.6546],
              [-126.5877, 50.7011],
              [-126.2723, 50.83],
              [-126.1859, 50.7416],
            ],
          ],
          [
            [
              [-127.8825, 50.8669],
              [-127.5052, 50.7733],
              [-127.1986, 50.6213],
              [-126.4598, 50.4748],
              [-126.1173, 50.4554],
              [-125.4362, 50.3136],
              [-125.1783, 49.925],
              [-124.8821, 49.7249],
              [-124.7947, 49.4726],
              [-124.5451, 49.3772],
              [-124.1592, 49.3105],
              [-123.8134, 49.1186],
              [-123.7799, 48.9859],
              [-123.5938, 48.837],
              [-123.5409, 48.6365],
              [-123.39, 48.6204],
              [-123.5546, 48.3279],
              [-123.8908, 48.3669],
              [-124.6654, 48.5732],
              [-125.094, 48.73],
              [-125.1852, 48.801],
              [-124.9676, 48.9879],
              [-125.3663, 48.9874],
              [-125.4894, 48.9186],
              [-125.7358, 49.053],
              [-125.6986, 49.1811],
              [-125.956, 49.2329],
              [-126.0765, 49.3837],
              [-126.5727, 49.4143],
              [-126.5626, 49.5874],
              [-126.6497, 49.898],
              [-127.1419, 49.8666],
              [-127.4879, 50.1304],
              [-127.7847, 50.085],
              [-127.7753, 50.2329],
              [-127.9155, 50.4663],
              [-128.0602, 50.4526],
              [-128.3198, 50.6098],
              [-128.4068, 50.7805],
              [-128.077, 50.8703],
              [-127.8825, 50.8669],
            ],
          ],
          [
            [
              [-55.884, 51.617],
              [-55.8902, 51.5009],
              [-55.5141, 51.6039],
              [-55.4919, 51.3884],
              [-55.6174, 51.3044],
              [-55.9669, 51.3466],
              [-56.0184, 51.2182],
              [-55.7191, 51.1843],
              [-55.8731, 50.9173],
              [-56.4278, 50.3757],
              [-56.5234, 50.1964],
              [-56.7388, 50.0268],
              [-56.8021, 49.8015],
              [-56.7253, 49.6903],
              [-56.1488, 50.1557],
              [-55.7226, 49.9312],
              [-55.9386, 49.6097],
              [-55.6448, 49.3903],
              [-55.5824, 49.4724],
              [-55.1557, 49.5493],
              [-55.2229, 49.2646],
              [-54.7429, 49.3198],
              [-54.0411, 49.4809],
              [-53.6791, 49.3668],
              [-53.4537, 49.2422],
              [-53.609, 49.0437],
              [-53.9239, 48.9177],
              [-53.8779, 48.748],
              [-53.7042, 48.661],
              [-53.7745, 48.5508],
              [-53.5215, 48.4622],
              [-53.4142, 48.6238],
              [-52.9829, 48.6042],
              [-53.0585, 48.4359],
              [-53.6395, 48.1693],
              [-53.6216, 48.005],
              [-53.8468, 47.7068],
              [-53.6051, 47.5394],
              [-53.4987, 47.7464],
              [-53.2765, 48.0164],
              [-53.0536, 47.9326],
              [-53.2436, 47.5926],
              [-53.1138, 47.4306],
              [-52.7042, 47.756],
              [-52.6166, 47.5227],
              [-52.7856, 47.3115],
              [-52.9413, 46.7913],
              [-53.2113, 46.6353],
              [-53.3739, 46.7348],
              [-53.6229, 46.6558],
              [-53.5726, 47.1621],
              [-54.0504, 46.8081],
              [-54.1885, 46.8284],
              [-54.1505, 47.0053],
              [-53.9106, 47.3727],
              [-53.8859, 47.5275],
              [-54.0133, 47.8072],
              [-54.141, 47.8358],
              [-54.4584, 47.4994],
              [-54.6029, 47.41],
              [-54.8661, 47.3796],
              [-54.9892, 47.2803],
              [-55.0951, 47.0599],
              [-55.2509, 46.9189],
              [-55.8513, 46.871],
              [-55.98, 46.9704],
              [-55.8699, 47.0751],
              [-55.4872, 47.1403],
              [-55.3013, 47.2676],
              [-55.0722, 47.5974],
              [-55.3759, 47.6865],
              [-55.4378, 47.4714],
              [-55.7742, 47.4577],
              [-55.9685, 47.7598],
              [-56.235, 47.632],
              [-56.672, 47.6267],
              [-56.7775, 47.5334],
              [-57.1207, 47.5676],
              [-57.3297, 47.6328],
              [-57.8473, 47.6524],
              [-58.0978, 47.6952],
              [-59.1116, 47.5608],
              [-59.3011, 47.6142],
              [-59.2829, 47.7594],
              [-59.3988, 47.8803],
              [-59.0018, 48.1225],
              [-58.5362, 48.4536],
              [-58.7032, 48.5545],
              [-58.6666, 48.7258],
              [-58.4433, 49.0568],
              [-58.1362, 49.0949],
              [-58.2295, 49.2685],
              [-58.1859, 49.4427],
              [-57.9261, 49.5893],
              [-57.9398, 49.7053],
              [-57.6102, 50.1657],
              [-57.4569, 50.4831],
              [-57.2996, 50.6495],
              [-56.9772, 50.8815],
              [-56.9799, 50.9878],
              [-56.7952, 51.1378],
              [-56.7202, 51.3193],
              [-55.884, 51.617],
            ],
          ],
          [
            [
              [-127.8763, 51.4742],
              [-128.086, 51.4869],
              [-128.1566, 51.6279],
              [-128.0099, 51.7237],
              [-127.8763, 51.4742],
            ],
          ],
          [
            [
              [-127.9704, 52.0632],
              [-128.036, 51.8436],
              [-128.2222, 51.9542],
              [-127.9704, 52.0632],
            ],
          ],
          [
            [
              [-81.4244, 53.2288],
              [-81.1228, 53.2022],
              [-80.7795, 52.9487],
              [-80.6696, 52.742],
              [-81.0268, 52.7526],
              [-82.0627, 53.0228],
              [-81.8828, 53.1799],
              [-81.4244, 53.2288],
            ],
          ],
          [
            [
              [-131.7941, 53.2521],
              [-131.613, 53.0563],
              [-131.6189, 52.9202],
              [-131.8524, 52.8672],
              [-131.6608, 52.5424],
              [-131.4144, 52.4537],
              [-131.3322, 52.2972],
              [-131.1426, 52.3162],
              [-131.175, 52.1245],
              [-131.38, 52.2029],
              [-131.6829, 52.4897],
              [-131.8826, 52.5762],
              [-132.5044, 53.0404],
              [-132.3731, 53.1327],
              [-132.0655, 53.1613],
              [-131.7941, 53.2521],
            ],
          ],
          [
            [
              [-128.6219, 53.1557],
              [-128.5323, 52.8466],
              [-128.6056, 52.6112],
              [-128.7554, 52.6039],
              [-129.057, 52.741],
              [-129.1898, 53.0233],
              [-129.0906, 53.0886],
              [-129.0781, 53.3028],
              [-128.905, 53.2934],
              [-128.6219, 53.1557],
            ],
          ],
          [
            [
              [-130.2366, 53.5708],
              [-129.974, 53.458],
              [-129.7429, 53.2353],
              [-129.9394, 53.1689],
              [-130.0862, 53.3123],
              [-130.3145, 53.3928],
              [-130.5305, 53.5598],
              [-130.2366, 53.5708],
            ],
          ],
          [
            [
              [-129.76, 53.6696],
              [-129.3365, 53.3606],
              [-129.5675, 53.2153],
              [-129.7969, 53.3785],
              [-129.9331, 53.6003],
              [-130.2024, 53.7303],
              [-130.1962, 53.9166],
              [-129.76, 53.6696],
            ],
          ],
          [
            [
              [-130.2647, 54.0151],
              [-130.3518, 53.8378],
              [-130.6739, 53.9892],
              [-130.3925, 54.0954],
              [-130.2647, 54.0151],
            ],
          ],
          [
            [
              [-132.9979, 54.1636],
              [-132.2967, 54.1123],
              [-132.1304, 54.0337],
              [-131.6933, 53.9919],
              [-131.8705, 53.7829],
              [-131.9426, 53.5216],
              [-131.9078, 53.3581],
              [-132.0079, 53.2536],
              [-132.4696, 53.1519],
              [-132.7576, 53.3171],
              [-132.5179, 53.4127],
              [-132.9289, 53.5225],
              [-132.8911, 53.6197],
              [-133.1444, 53.8969],
              [-132.9979, 54.1636],
            ],
          ],
          [
            [
              [-79.1657, 56.6594],
              [-79.0426, 56.3659],
              [-79.4693, 55.8794],
              [-79.8671, 55.8435],
              [-80.0257, 55.9003],
              [-79.7372, 56.1614],
              [-79.4093, 56.2329],
              [-79.1657, 56.6594],
            ],
          ],
          [
            [
              [-61.4294, 56.9366],
              [-61.3728, 56.6921],
              [-61.6471, 56.7438],
              [-61.6055, 56.8873],
              [-61.4294, 56.9366],
            ],
          ],
          [
            [
              [-68.0785, 60.5899],
              [-67.8265, 60.4657],
              [-67.9601, 60.3326],
              [-68.321, 60.1971],
              [-68.439, 60.2593],
              [-68.2413, 60.5856],
              [-68.0785, 60.5899],
            ],
          ],
          [
            [
              [-64.7921, 61.6225],
              [-64.6966, 61.458],
              [-64.8512, 61.3337],
              [-65.4858, 61.5993],
              [-65.0466, 61.6977],
              [-64.7921, 61.6225],
            ],
          ],
          [
            [
              [-79.4404, 62.3798],
              [-79.2695, 62.1452],
              [-79.5485, 61.7595],
              [-79.7502, 61.5859],
              [-80.1804, 61.7743],
              [-80.2856, 61.9304],
              [-80.215, 62.1377],
              [-79.8308, 62.4108],
              [-79.4404, 62.3798],
            ],
          ],
          [
            [
              [-70.7886, 62.8415],
              [-70.4925, 62.767],
              [-70.1771, 62.59],
              [-70.6822, 62.5473],
              [-70.9596, 62.8032],
              [-70.7886, 62.8415],
            ],
          ],
          [
            [
              [-82.1096, 62.9685],
              [-81.8624, 62.9248],
              [-81.9569, 62.6977],
              [-82.296, 62.5852],
              [-82.7467, 62.3105],
              [-83.1346, 62.1766],
              [-83.2806, 62.2525],
              [-83.673, 62.1451],
              [-83.907, 62.4914],
              [-83.5528, 62.6997],
              [-83.5198, 62.8264],
              [-83.1189, 62.8472],
              [-82.7394, 62.9384],
              [-82.1096, 62.9685],
            ],
          ],
          [
            [
              [-78.4144, 63.4932],
              [-77.8443, 63.4725],
              [-77.496, 63.2761],
              [-77.6448, 63.1432],
              [-77.9408, 63.0871],
              [-78.5317, 63.3902],
              [-78.4144, 63.4932],
            ],
          ],
          [
            [
              [-77.1507, 63.6677],
              [-76.7093, 63.5678],
              [-76.702, 63.3697],
              [-76.9821, 63.4073],
              [-77.4183, 63.5906],
              [-77.1507, 63.6677],
            ],
          ],
          [
            [
              [-85.4883, 65.7966],
              [-85.201, 65.8093],
              [-85.0524, 65.6142],
              [-85.0767, 65.4564],
              [-84.848, 65.2135],
              [-84.5774, 65.4894],
              [-84.1523, 65.3322],
              [-84.0985, 65.2095],
              [-83.3977, 65.1366],
              [-83.2084, 64.9477],
              [-82.7145, 64.781],
              [-82.366, 64.768],
              [-81.7713, 64.512],
              [-81.6272, 64.1236],
              [-81.0585, 64.0265],
              [-80.8888, 64.1226],
              [-80.1964, 63.7529],
              [-80.4664, 63.7273],
              [-81.0189, 63.4503],
              [-82.0746, 63.6918],
              [-82.4818, 63.6852],
              [-82.3558, 63.853],
              [-82.5339, 63.973],
              [-83.1383, 64.0067],
              [-83.0137, 64.1916],
              [-83.5394, 64.1107],
              [-83.6853, 64.0141],
              [-83.6338, 63.771],
              [-84.0639, 63.6207],
              [-84.4053, 63.5548],
              [-84.5902, 63.3151],
              [-85.2739, 63.1234],
              [-85.6352, 63.2431],
              [-85.617, 63.6749],
              [-85.7936, 63.7134],
              [-86.2854, 63.6461],
              [-86.6612, 63.6577],
              [-87.0644, 63.5548],
              [-87.2238, 63.6601],
              [-86.8959, 63.9302],
              [-86.1929, 64.1034],
              [-86.4058, 64.4385],
              [-86.3529, 64.6508],
              [-86.1593, 64.9394],
              [-86.1739, 65.2865],
              [-86.0284, 65.665],
              [-85.6098, 65.9105],
              [-85.4883, 65.7966],
            ],
          ],
          [
            [
              [-85.0129, 66.0082],
              [-84.715, 65.8353],
              [-84.5741, 65.6356],
              [-84.7132, 65.5552],
              [-85.1127, 65.7621],
              [-85.1861, 65.9468],
              [-85.0129, 66.0082],
            ],
          ],
          [
            [
              [-84.3461, 66.1179],
              [-83.6927, 65.9257],
              [-83.8697, 65.7829],
              [-84.4735, 66.0759],
              [-84.3461, 66.1179],
            ],
          ],
          [
            [
              [-74.277, 68.0776],
              [-73.4129, 67.9684],
              [-73.4095, 67.776],
              [-74.4023, 67.7789],
              [-74.6402, 67.8567],
              [-74.756, 68.0634],
              [-74.277, 68.0776],
            ],
          ],
          [
            [
              [-86.3725, 67.9366],
              [-86.5975, 67.7282],
              [-86.8366, 67.7918],
              [-86.9857, 68.0675],
              [-86.66, 68.3104],
              [-86.4078, 68.2077],
              [-86.3725, 67.9366],
            ],
          ],
          [
            [
              [-75.5975, 68.3138],
              [-75.1376, 68.2363],
              [-75.0072, 68.1105],
              [-75.1614, 67.9531],
              [-75.0441, 67.817],
              [-75.0651, 67.5524],
              [-75.3905, 67.3577],
              [-75.8893, 67.258],
              [-76.9816, 67.2433],
              [-77.178, 67.4255],
              [-77.3227, 67.708],
              [-77.2122, 67.8704],
              [-76.6889, 68.2626],
              [-76.2991, 68.3348],
              [-75.5975, 68.3138],
            ],
          ],
          [
            [
              [-74.9033, 68.5529],
              [-74.8113, 68.324],
              [-75.379, 68.4988],
              [-75.2604, 68.7206],
              [-74.9033, 68.5529],
            ],
          ],
          [
            [
              [-101.778, 68.7886],
              [-101.8292, 68.5784],
              [-102.2904, 68.6636],
              [-102.0246, 68.8238],
              [-101.778, 68.7886],
            ],
          ],
          [
            [
              [-100.1542, 68.9234],
              [-100.3635, 68.7221],
              [-100.6224, 68.7724],
              [-100.6313, 68.9957],
              [-100.1542, 68.9234],
            ],
          ],
          [
            [
              [-78.4567, 69.3935],
              [-78.4567, 69.2291],
              [-78.83, 68.9174],
              [-79.3967, 68.8632],
              [-79.2042, 69.0849],
              [-78.8633, 69.1484],
              [-78.7309, 69.3253],
              [-78.4567, 69.3935],
            ],
          ],
          [
            [
              [-77.0478, 69.4197],
              [-76.6752, 69.311],
              [-76.9915, 69.1451],
              [-77.265, 69.1467],
              [-77.3605, 69.3898],
              [-77.0478, 69.4197],
            ],
          ],
          [
            [
              [-95.6803, 69.6093],
              [-95.3808, 69.5233],
              [-95.413, 69.3863],
              [-96.0114, 69.4828],
              [-95.6803, 69.6093],
            ],
          ],
          [
            [
              [-77.9706, 69.6845],
              [-78.0923, 69.5783],
              [-78.5172, 69.4848],
              [-78.5665, 69.6467],
              [-77.9706, 69.6845],
            ],
          ],
          [
            [
              [-79.6724, 69.8204],
              [-79.3481, 69.6945],
              [-79.6129, 69.6198],
              [-80.2104, 69.6187],
              [-79.6724, 69.8204],
            ],
          ],
          [
            [
              [-97.3907, 69.6002],
              [-96.2198, 69.314],
              [-95.952, 69.1396],
              [-95.8455, 68.8906],
              [-95.571, 68.8351],
              [-96.2959, 68.4801],
              [-96.5205, 68.4476],
              [-97.0368, 68.5538],
              [-97.562, 68.5643],
              [-97.7606, 68.6521],
              [-99.4172, 68.8904],
              [-99.5915, 69.0411],
              [-99.3093, 69.1588],
              [-98.7128, 69.2021],
              [-98.4111, 69.3525],
              [-98.6079, 69.4427],
              [-98.3693, 69.5683],
              [-98.2193, 69.7976],
              [-97.9252, 69.8959],
              [-97.4752, 69.7772],
              [-97.3907, 69.6002],
            ],
          ],
          [
            [
              [-71.8276, 71.0613],
              [-71.4387, 70.9099],
              [-72.1182, 70.8254],
              [-72.228, 70.9217],
              [-72.0461, 70.9459],
              [-72.0116, 71.0522],
              [-71.8276, 71.0613],
            ],
          ],
          [
            [
              [-74.713, 44.9993],
              [-74.8685, 45.0101],
              [-75.2702, 44.8638],
              [-75.6997, 44.5817],
              [-75.8703, 44.4086],
              [-76.0673, 44.348],
              [-76.4546, 44.0842],
              [-76.8415, 43.6255],
              [-78.6881, 43.6318],
              [-79.1743, 43.4645],
              [-79.0538, 43.2604],
              [-79.032, 43.0134],
              [-78.9153, 42.9476],
              [-79.0194, 42.8027],
              [-80.2466, 42.3655],
              [-81.2767, 42.2087],
              [-82.4249, 41.6768],
              [-82.7111, 41.6802],
              [-83.0684, 41.8477],
              [-83.157, 42.0004],
              [-83.1285, 42.24],
              [-82.8001, 42.418],
              [-82.5113, 42.6467],
              [-82.4111, 43.0513],
              [-82.1536, 43.5496],
              [-82.2918, 44.2422],
              [-82.5321, 45.2935],
              [-82.5888, 45.3718],
              [-83.5953, 45.8164],
              [-83.4659, 45.9931],
              [-83.6139, 46.1156],
              [-83.9473, 46.0606],
              [-84.0868, 46.2012],
              [-84.1588, 46.4333],
              [-84.1104, 46.5265],
              [-84.5894, 46.4751],
              [-84.7733, 46.6334],
              [-84.8787, 46.8979],
              [-86.6371, 47.6241],
              [-88.3473, 48.2987],
              [-88.6427, 48.2566],
              [-89.3404, 47.9842],
              [-89.9803, 48.0101],
              [-90.1786, 48.1164],
              [-90.7865, 48.1005],
              [-90.9232, 48.2271],
              [-91.4279, 48.0364],
              [-91.6925, 48.0979],
              [-91.9573, 48.2283],
              [-92.0434, 48.3346],
              [-92.3031, 48.3109],
              [-92.6486, 48.5363],
              [-92.9797, 48.6177],
              [-93.2577, 48.6303],
              [-93.4918, 48.5423],
              [-93.7568, 48.5165],
              [-93.8749, 48.6362],
              [-94.2322, 48.652],
              [-94.5929, 48.7264],
              [-94.7112, 48.9146],
              [-94.8273, 49.2929],
              [-95.1606, 49.3695],
              [-95.1771, 48.9927],
              [-96.7158, 48.9927],
              [-97.8148, 48.9927],
              [-99.3535, 48.9927],
              [-100.4525, 48.9927],
              [-102.4307, 48.9926],
              [-104.409, 48.9926],
              [-106.6072, 48.9926],
              [-108.3656, 48.9926],
              [-110.5637, 48.9926],
              [-111.6627, 48.9926],
              [-113.641, 48.9925],
              [-115.1797, 48.9925],
              [-116.2788, 48.9925],
              [-117.8174, 48.9925],
              [-119.356, 48.9925],
              [-121.1145, 48.9925],
              [-122.753, 48.9925],
              [-123.0353, 48.9925],
              [-123.0905, 48.9925],
              [-123.212, 49.13],
              [-123.2486, 49.5978],
              [-123.4895, 49.515],
              [-123.5546, 49.39],
              [-123.9638, 49.5211],
              [-124.171, 49.781],
              [-124.4037, 49.7673],
              [-124.7322, 49.9543],
              [-124.6052, 50.2329],
              [-124.7054, 50.3223],
              [-125.0617, 50.3276],
              [-125.1278, 50.437],
              [-125.391, 50.4973],
              [-125.7033, 50.4379],
              [-126.2423, 50.501],
              [-126.2215, 50.6426],
              [-126.0187, 50.666],
              [-126.1789, 50.8532],
              [-126.4887, 50.8167],
              [-126.8586, 50.9431],
              [-127.0218, 50.8219],
              [-127.429, 50.9431],
              [-127.777, 51.1672],
              [-127.6643, 51.3981],
              [-127.8737, 51.6734],
              [-127.8496, 51.9201],
              [-127.6381, 52.0837],
              [-127.2836, 52.2377],
              [-127.159, 52.3555],
              [-127.225, 52.4561],
              [-127.8005, 52.2563],
              [-128.0159, 52.3467],
              [-128.0924, 52.5133],
              [-128.243, 52.5588],
              [-128.1256, 52.7528],
              [-128.4779, 52.8683],
              [-128.5467, 53.136],
              [-128.8755, 53.3062],
              [-128.7755, 53.5556],
              [-128.8616, 53.8302],
              [-129.2283, 53.6327],
              [-129.335, 53.3969],
              [-130.1047, 53.9471],
              [-130.1122, 54.1593],
              [-130.4582, 54.3486],
              [-130.3724, 54.6643],
              [-130.2015, 54.7285],
              [-130.0638, 55.0029],
              [-130.1674, 55.0854],
              [-129.9579, 55.2735],
              [-130.1306, 55.7363],
              [-130.0196, 55.908],
              [-130.0715, 56.0841],
              [-130.404, 56.1219],
              [-130.4955, 56.2324],
              [-130.8391, 56.3725],
              [-131.1005, 56.4077],
              [-131.5859, 56.595],
              [-131.7991, 56.5877],
              [-131.8716, 56.7935],
              [-132.1074, 56.8588],
              [-132.0319, 57.0284],
              [-132.3412, 57.0804],
              [-132.231, 57.1968],
              [-132.5524, 57.4991],
              [-133.1656, 58.1473],
              [-133.3746, 58.291],
              [-133.4631, 58.4622],
              [-133.8712, 58.7359],
              [-134.2222, 58.8427],
              [-134.398, 59.052],
              [-134.7055, 59.2401],
              [-134.9324, 59.2706],
              [-135.0958, 59.4189],
              [-135.0162, 59.5434],
              [-135.4828, 59.7925],
              [-135.9238, 59.6667],
              [-136.2585, 59.6216],
              [-136.4676, 59.4616],
              [-136.4665, 59.2878],
              [-136.6135, 59.1542],
              [-136.8408, 59.1482],
              [-137.4231, 58.9077],
              [-137.6114, 59.2393],
              [-138.5992, 59.7538],
              [-138.6921, 59.8869],
              [-139.1821, 60.0734],
              [-139.1016, 60.3466],
              [-139.6283, 60.3341],
              [-140.0158, 60.1874],
              [-140.4241, 60.2932],
              [-140.5337, 60.2185],
              [-141.0012, 60.3211],
              [-141.0018, 61.6331],
              [-141.0023, 62.6536],
              [-141.0026, 63.3825],
              [-141.0031, 64.5487],
              [-141.0036, 65.5692],
              [-141.0042, 66.8811],
              [-141.005, 68.4847],
              [-141.0056, 69.6509],
              [-140.2227, 69.5991],
              [-139.918, 69.6213],
              [-139.1438, 69.5137],
              [-138.6367, 69.2837],
              [-138.0955, 69.1401],
              [-137.1912, 68.9479],
              [-136.663, 68.8758],
              [-136.0676, 68.8853],
              [-135.9678, 69.2222],
              [-135.8379, 69.3091],
              [-135.1554, 69.4797],
              [-134.6413, 69.4822],
              [-134.4101, 69.6569],
              [-133.6319, 69.3992],
              [-133.1958, 69.414],
              [-132.8873, 69.6611],
              [-132.5484, 69.743],
              [-132.1389, 69.6879],
              [-130.9429, 70.0788],
              [-130.3245, 70.1402],
              [-129.5998, 69.9912],
              [-131.0858, 69.6331],
              [-131.9836, 69.5369],
              [-132.1143, 69.3615],
              [-132.7712, 69.2563],
              [-132.8622, 69.0733],
              [-132.3728, 69.1493],
              [-131.8804, 69.2847],
              [-131.5952, 69.4743],
              [-130.7502, 69.4543],
              [-130.3382, 69.7023],
              [-129.1737, 69.8323],
              [-128.9941, 69.6794],
              [-128.3104, 69.9894],
              [-128.3702, 70.0974],
              [-128.0324, 70.2937],
              [-128.1959, 70.447],
              [-127.9283, 70.5671],
              [-127.1365, 70.2529],
              [-126.7018, 69.7674],
              [-125.9386, 69.414],
              [-125.4488, 69.3192],
              [-125.151, 69.4543],
              [-125.4159, 69.6032],
              [-125.0323, 69.7473],
              [-124.8971, 69.9422],
              [-124.9877, 70.0456],
              [-124.3839, 70.1402],
              [-124.5067, 69.7362],
              [-124.0718, 69.6658],
              [-124.4291, 69.4735],
              [-124.4385, 69.3696],
              [-123.5447, 69.377],
              [-123.1776, 69.4959],
              [-123.1573, 69.7702],
              [-122.951, 69.8386],
              [-122.036, 69.8187],
              [-121.4278, 69.7668],
              [-120.8074, 69.6235],
              [-120.2786, 69.4078],
              [-118.6664, 69.2255],
              [-118.0409, 69.0246],
              [-116.2081, 68.8312],
              [-116.1605, 68.9841],
              [-115.5878, 68.9752],
              [-114.9878, 68.8674],
              [-114.201, 68.5719],
              [-114.0568, 68.3042],
              [-114.837, 68.2626],
              [-115.5338, 67.9344],
              [-115.1182, 67.8039],
              [-114.7892, 67.817],
              [-113.8588, 67.6962],
              [-112.421, 67.6824],
              [-111.292, 67.8261],
              [-111.019, 67.7681],
              [-110.4208, 67.9484],
              [-109.9712, 67.9673],
              [-109.6984, 67.8101],
              [-109.0746, 67.722],
              [-108.9294, 67.5318],
              [-108.5991, 67.6253],
              [-108.444, 67.434],
              [-107.9925, 67.2825],
              [-107.8797, 67.0534],
              [-108.215, 67.0274],
              [-107.6587, 66.7077],
              [-107.2464, 67.0842],
              [-107.527, 67.2123],
              [-107.6567, 67.3725],
              [-107.5858, 67.5012],
              [-107.9896, 67.6933],
              [-107.9416, 67.8543],
              [-107.4287, 68.0427],
              [-106.4944, 68.2458],
              [-106.276, 68.3869],
              [-105.6976, 68.4888],
              [-105.8791, 68.6393],
              [-106.522, 68.5267],
              [-106.5732, 68.4202],
              [-107.1311, 68.3007],
              [-107.8473, 68.3332],
              [-107.7296, 68.2072],
              [-108.3376, 68.1551],
              [-108.4022, 68.2689],
              [-108.8151, 68.2742],
              [-108.2808, 68.6216],
              [-107.2926, 68.7014],
              [-106.1975, 68.9444],
              [-105.7508, 68.8659],
              [-105.4696, 68.722],
              [-105.536, 68.4499],
              [-105.0951, 68.2695],
              [-104.602, 68.2353],
              [-104.4839, 68.0339],
              [-103.8786, 68.0233],
              [-103.5284, 68.133],
              [-103.3661, 68.0185],
              [-102.9484, 67.9268],
              [-102.7626, 67.8151],
              [-102.2421, 67.7251],
              [-101.9391, 67.7858],
              [-101.5294, 67.6804],
              [-101.2524, 67.7697],
              [-100.9569, 67.7492],
              [-100.717, 67.847],
              [-99.4237, 67.7941],
              [-98.9154, 67.7116],
              [-98.361, 67.8113],
              [-98.727, 67.9578],
              [-98.5223, 68.0456],
              [-98.0921, 67.7603],
              [-97.5903, 67.603],
              [-97.0611, 67.6804],
              [-97.1201, 67.7794],
              [-97.6409, 68.0094],
              [-98.1715, 67.8757],
              [-98.449, 68.0709],
              [-98.5444, 68.3397],
              [-98.1924, 68.3077],
              [-97.793, 68.4095],
              [-97.8563, 68.5438],
              [-97.2604, 68.4687],
              [-97.0099, 68.35],
              [-96.3981, 68.1714],
              [-96.0094, 68.2523],
              [-96.2164, 67.7803],
              [-96.4652, 67.5495],
              [-96.0751, 67.2258],
              [-95.735, 67.3765],
              [-95.5219, 67.2196],
              [-95.2556, 67.2623],
              [-95.4632, 67.6393],
              [-95.6957, 67.7115],
              [-95.582, 67.8995],
              [-95.2335, 68.0697],
              [-94.861, 68.0309],
              [-94.3409, 68.2443],
              [-93.7305, 68.6118],
              [-93.5772, 68.8622],
              [-93.8967, 69.0176],
              [-94.1099, 68.7591],
              [-94.6361, 68.756],
              [-94.5505, 68.8907],
              [-94.1352, 69.1328],
              [-94.2379, 69.3365],
              [-93.7034, 69.2254],
              [-93.4344, 69.3568],
              [-93.558, 69.5307],
              [-93.9304, 69.4354],
              [-94.3222, 69.453],
              [-94.561, 69.6406],
              [-94.8751, 69.5716],
              [-95.6503, 69.7839],
              [-95.9759, 69.7862],
              [-96.4863, 70.097],
              [-96.5615, 70.3153],
              [-96.1903, 70.6247],
              [-96.6164, 70.8258],
              [-96.5615, 71.1271],
              [-96.2117, 71.3868],
              [-95.6583, 71.2915],
              [-95.4647, 71.484],
              [-95.9445, 71.5522],
              [-95.4921, 71.723],
              [-95.2252, 71.9495],
              [-94.5487, 72.0021],
              [-94.5399, 71.7713],
              [-94.0541, 71.7892],
              [-93.8205, 71.6553],
              [-92.9906, 71.3571],
              [-92.8587, 71.1498],
              [-92.9077, 70.902],
              [-92.6145, 70.6889],
              [-92.2499, 70.6254],
              [-91.9907, 70.3251],
              [-91.7232, 70.3558],
              [-91.5452, 70.145],
              [-91.9429, 70.0241],
              [-92.4714, 69.6976],
              [-91.8027, 69.5133],
              [-91.426, 69.6684],
              [-91.11, 69.5133],
              [-90.6544, 69.5369],
              [-91.0336, 69.2195],
              [-90.6706, 69.0882],
              [-90.4465, 68.8863],
              [-90.6035, 68.4408],
              [-90.0002, 68.3793],
              [-89.9449, 68.6158],
              [-89.688, 68.8175],
              [-89.6722, 69.0445],
              [-89.3317, 69.2495],
              [-88.942, 69.2336],
              [-88.7286, 69.0839],
              [-88.0226, 68.8087],
              [-87.7922, 68.3312],
              [-87.9438, 68.2009],
              [-88.2833, 68.126],
              [-88.3736, 67.9758],
              [-88.153, 67.6804],
              [-87.4838, 67.3631],
              [-87.3278, 67.1733],
              [-86.9607, 67.2552],
              [-86.779, 67.4186],
              [-86.5019, 67.3794],
              [-86.5293, 67.6872],
              [-85.9017, 68.0544],
              [-85.7172, 68.3983],
              [-85.6681, 68.727],
              [-85.2248, 68.7213],
              [-84.7672, 69.088],
              [-85.22, 69.1328],
              [-85.534, 69.4633],
              [-85.5441, 69.6535],
              [-85.3332, 69.7914],
              [-84.3398, 69.8597],
              [-83.7541, 69.7117],
              [-82.5755, 69.6741],
              [-82.618, 69.57],
              [-82.2816, 69.4194],
              [-82.2373, 69.2352],
              [-81.753, 69.2679],
              [-81.3372, 69.1876],
              [-81.7025, 68.9075],
              [-81.3652, 68.8617],
              [-81.2665, 68.6387],
              [-81.6295, 68.5128],
              [-82.3697, 68.3563],
              [-82.3148, 68.1466],
              [-82.1094, 68.1118],
              [-82.0882, 67.8995],
              [-81.4572, 67.6284],
              [-81.2379, 67.4605],
              [-81.5274, 66.996],
              [-81.9549, 66.9768],
              [-82.1844, 66.7686],
              [-82.6335, 66.5633],
              [-83.0274, 66.4952],
              [-83.3694, 66.3503],
              [-83.6781, 66.5253],
              [-83.9632, 66.4779],
              [-83.6908, 66.1992],
              [-84.3817, 66.19],
              [-85.2678, 66.2869],
              [-85.4161, 66.567],
              [-85.7516, 66.5177],
              [-86.6205, 66.5069],
              [-86.6206, 66.3144],
              [-85.8567, 66.1628],
              [-85.9744, 66.0356],
              [-86.4125, 65.9045],
              [-86.4747, 65.734],
              [-87.3808, 65.3297],
              [-87.9275, 65.3334],
              [-88.2055, 65.4051],
              [-88.5846, 65.6452],
              [-88.9701, 65.6965],
              [-89.688, 65.9468],
              [-89.8375, 65.8309],
              [-89.3938, 65.5012],
              [-89.0399, 65.3282],
              [-87.0469, 65.238],
              [-86.9382, 65.142],
              [-87.6, 64.5582],
              [-87.7643, 64.5224],
              [-88.0443, 64.1843],
              [-88.7346, 63.9724],
              [-89.1774, 64.0385],
              [-89.8127, 63.9602],
              [-90.1684, 63.6577],
              [-90.9745, 63.5787],
              [-91.371, 63.7017],
              [-92.3738, 63.7791],
              [-92.7862, 63.901],
              [-93.4789, 64.0339],
              [-93.7659, 63.9935],
              [-93.5331, 63.8496],
              [-93.2172, 63.911],
              [-92.6349, 63.7839],
              [-92.1275, 63.7112],
              [-91.7784, 63.7144],
              [-91.5867, 63.5823],
              [-91.0174, 63.4711],
              [-90.6954, 63.2748],
              [-90.6436, 63.0689],
              [-91.442, 62.7869],
              [-91.9794, 62.8474],
              [-92.4539, 62.7939],
              [-92.2609, 62.5626],
              [-92.7183, 62.4647],
              [-92.5934, 62.2394],
              [-93.5021, 61.8558],
              [-93.3029, 61.7519],
              [-93.838, 61.5617],
              [-93.985, 61.458],
              [-93.8336, 61.3283],
              [-94.0068, 61.2378],
              [-94.2423, 60.9034],
              [-94.3516, 60.8581],
              [-94.7118, 60.2657],
              [-94.817, 59.9629],
              [-94.8219, 59.6503],
              [-94.7184, 59.3667],
              [-94.8074, 59.0181],
              [-94.3995, 58.7125],
              [-93.7174, 58.7922],
              [-93.1895, 58.7597],
              [-93.1285, 58.5284],
              [-92.8078, 58.0758],
              [-92.757, 57.8659],
              [-92.4569, 57.449],
              [-92.4568, 57.223],
              [-92.6658, 57.0006],
              [-92.2235, 57.0215],
              [-91.0311, 57.2646],
              [-90.5855, 57.2299],
              [-89.827, 56.976],
              [-88.9208, 56.8531],
              [-88.3114, 56.538],
              [-87.9796, 56.4429],
              [-87.6223, 56.0956],
              [-87.3163, 55.9623],
              [-86.8749, 55.9113],
              [-86.2893, 55.7256],
              [-85.6826, 55.5866],
              [-85.4392, 55.4284],
              [-85.1266, 55.3026],
              [-84.6131, 55.2452],
              [-83.9291, 55.3265],
              [-83.2395, 55.2236],
              [-82.9203, 55.2205],
              [-82.8151, 55.1468],
              [-82.4585, 55.1412],
              [-82.2786, 55.0455],
              [-82.2014, 54.8339],
              [-82.421, 54.3839],
              [-82.4309, 54.2282],
              [-82.2426, 54.065],
              [-82.126, 53.8122],
              [-82.2192, 53.6044],
              [-82.1185, 53.2773],
              [-82.2381, 53.2214],
              [-82.3012, 52.9676],
              [-81.9881, 52.7984],
              [-81.9182, 52.6804],
              [-81.5638, 52.4562],
              [-81.5815, 52.3165],
              [-81.3906, 52.1278],
              [-80.9791, 52.0018],
              [-80.5949, 51.7114],
              [-80.4298, 51.3572],
              [-80.1083, 51.2961],
              [-79.7445, 51.1235],
              [-79.7008, 51.3854],
              [-79.5602, 51.5555],
              [-79.346, 51.6618],
              [-78.8822, 51.4033],
              [-78.7924, 51.6108],
              [-79.015, 51.7961],
              [-78.8406, 51.8423],
              [-78.5913, 52.1061],
              [-78.5215, 52.4809],
              [-78.7856, 52.6591],
              [-78.7831, 52.8715],
              [-78.9774, 53.07],
              [-78.9017, 53.201],
              [-79.0563, 53.6203],
              [-79.0135, 53.8459],
              [-79.1294, 54.1075],
              [-79.464, 54.3102],
              [-79.5294, 54.5772],
              [-79.6763, 54.6938],
              [-78.9674, 54.852],
              [-78.3922, 55.0275],
              [-77.7816, 55.2735],
              [-77.1129, 55.6754],
              [-77.0445, 55.783],
              [-76.6587, 56.073],
              [-76.5452, 56.295],
              [-76.5037, 56.7838],
              [-76.5893, 57.2713],
              [-76.8087, 57.6755],
              [-77.0793, 57.9615],
              [-77.6007, 58.2713],
              [-78.0315, 58.3881],
              [-78.4737, 58.7202],
              [-78.56, 58.9446],
              [-78.3047, 58.9599],
              [-78.1066, 59.2099],
              [-77.8192, 59.293],
              [-77.8812, 59.4849],
              [-77.7162, 59.6214],
              [-77.4629, 59.6766],
              [-77.2831, 60.0266],
              [-77.556, 60.0528],
              [-77.4697, 60.2172],
              [-77.7423, 60.4258],
              [-77.5917, 60.5026],
              [-77.8293, 60.6433],
              [-77.6068, 60.7591],
              [-78.1655, 60.8684],
              [-77.8124, 61.0881],
              [-77.7853, 61.4547],
              [-77.7084, 61.6163],
              [-77.9398, 61.6936],
              [-78.0848, 61.888],
              [-78.1549, 62.2973],
              [-77.4908, 62.5883],
              [-76.7577, 62.5108],
              [-75.8297, 62.3133],
              [-75.3277, 62.3175],
              [-75.0061, 62.269],
              [-74.4169, 62.2525],
              [-73.6707, 62.4821],
              [-73.2095, 62.3128],
              [-73.0622, 62.1876],
              [-72.6214, 62.1019],
              [-72.6052, 61.9437],
              [-72.2323, 61.8813],
              [-71.9568, 61.7112],
              [-71.652, 61.6498],
              [-71.8553, 61.4238],
              [-71.6964, 61.2774],
              [-71.395, 61.1495],
              [-70.6037, 61.0328],
              [-70.3251, 61.1004],
              [-70.1102, 61.0601],
              [-70.0648, 60.8581],
              [-69.9159, 60.81],
              [-69.5366, 61.0797],
              [-69.3813, 60.7999],
              [-69.7198, 60.684],
              [-69.7533, 60.333],
              [-69.6024, 60.2247],
              [-69.7698, 59.9711],
              [-69.5898, 59.8761],
              [-69.6577, 59.7033],
              [-69.568, 59.6026],
              [-69.6715, 59.457],
              [-69.2548, 59.3248],
              [-69.5281, 59.186],
              [-69.4516, 58.8952],
              [-70.0345, 58.8126],
              [-69.834, 58.6041],
              [-69.2563, 58.8992],
              [-68.7869, 58.9225],
              [-68.3616, 58.7835],
              [-68.3502, 58.6215],
              [-67.649, 58.2518],
              [-67.0642, 58.3929],
              [-66.9466, 58.4984],
              [-66.6288, 58.5929],
              [-66.3912, 58.8513],
              [-66.1086, 58.7791],
              [-65.9822, 58.9172],
              [-65.6425, 59.0733],
              [-65.7427, 59.2679],
              [-65.4067, 59.4174],
              [-65.5463, 59.7313],
              [-65.1378, 59.9002],
              [-65.1177, 60.0084],
              [-64.8488, 60.3657],
              [-64.4166, 60.2597],
              [-64.4515, 60.0661],
              [-64.2205, 60.0466],
              [-64.1264, 59.9075],
              [-64.1479, 59.6828],
              [-63.7281, 59.5091],
              [-63.8059, 59.3749],
              [-63.5316, 59.3477],
              [-63.3733, 59.2167],
              [-63.4422, 59.1206],
              [-62.9269, 58.8232],
              [-62.8465, 58.6693],
              [-63.1756, 58.5035],
              [-63.0097, 58.4287],
              [-62.5786, 58.5042],
              [-62.6614, 58.1758],
              [-62.305, 58.0312],
              [-61.8899, 57.625],
              [-62.2892, 57.5236],
              [-61.817, 57.3774],
              [-61.8651, 57.1706],
              [-61.3819, 57.1211],
              [-61.5018, 56.9789],
              [-61.8092, 56.7885],
              [-61.8689, 56.6373],
              [-61.7566, 56.4858],
              [-61.8302, 56.3769],
              [-61.547, 56.2004],
              [-61.3728, 56.2288],
              [-61.3454, 55.9693],
              [-60.7816, 55.8597],
              [-60.3548, 55.5751],
              [-60.4189, 55.4277],
              [-60.2871, 55.2334],
              [-59.9392, 55.2354],
              [-59.862, 55.1409],
              [-59.43, 55.142],
              [-59.2523, 55.2159],
              [-59.008, 55.1537],
              [-59.0259, 55.0199],
              [-58.6815, 54.8185],
              [-58.2035, 54.7974],
              [-57.847, 54.7387],
              [-57.6676, 54.6334],
              [-57.4498, 54.6494],
              [-57.391, 54.5019],
              [-57.6783, 54.3774],
              [-58.1861, 54.3586],
              [-58.9745, 53.9715],
              [-59.5316, 53.8216],
              [-59.7913, 53.848],
              [-60.1083, 53.7577],
              [-60.0417, 53.4802],
              [-59.5111, 53.5554],
              [-59.3678, 53.6524],
              [-59.0768, 53.687],
              [-59.0707, 53.7963],
              [-58.8531, 53.942],
              [-58.2203, 54.0638],
              [-58.2285, 54.2357],
              [-58.0116, 54.2397],
              [-57.3569, 54.1395],
              [-57.1527, 53.7399],
              [-56.6313, 53.7416],
              [-56.6327, 53.6603],
              [-56.0362, 53.5804],
              [-56.0349, 53.4337],
              [-55.8121, 53.3301],
              [-55.7469, 53.1457],
              [-55.9557, 53.0222],
              [-55.8116, 52.8304],
              [-56.0594, 52.7657],
              [-55.9333, 52.6379],
              [-56.0004, 52.5151],
              [-55.6476, 52.4432],
              [-55.8201, 52.3082],
              [-55.6848, 52.1117],
              [-55.9342, 51.9252],
              [-56.3379, 51.7821],
              [-56.9665, 51.4252],
              [-57.25, 51.5083],
              [-57.6706, 51.4349],
              [-58.234, 51.2769],
              [-58.4537, 51.3166],
              [-58.7164, 51.1065],
              [-58.9477, 51.0416],
              [-58.9933, 50.7908],
              [-59.4099, 50.6666],
              [-59.5815, 50.4841],
              [-59.8031, 50.4457],
              [-59.8507, 50.3136],
              [-60.0637, 50.254],
              [-60.5404, 50.226],
              [-60.8356, 50.2563],
              [-61.3114, 50.1993],
              [-61.7179, 50.1],
              [-61.8458, 50.2198],
              [-62.8524, 50.2943],
              [-63.1767, 50.2901],
              [-63.4422, 50.2229],
              [-63.7222, 50.3043],
              [-64.2475, 50.2707],
              [-64.47, 50.3223],
              [-64.8883, 50.2745],
              [-65.2583, 50.3223],
              [-65.7372, 50.2574],
              [-65.9292, 50.2928],
              [-66.1619, 50.1958],
              [-66.5431, 50.2161],
              [-66.7459, 50.0537],
              [-66.9509, 49.9864],
              [-67.1315, 49.8313],
              [-67.2451, 49.4588],
              [-67.3763, 49.3392],
              [-68.1224, 49.2713],
              [-68.1811, 49.1245],
              [-68.3719, 49.1403],
              [-69.0696, 48.7562],
              [-69.1444, 48.6005],
              [-69.4466, 48.2962],
              [-69.736, 48.1225],
              [-69.932, 47.7731],
              [-70.1996, 47.5955],
              [-70.2369, 47.4956],
              [-70.4971, 47.4293],
              [-70.715, 47.1176],
              [-70.9569, 47.0207],
              [-71.354, 46.739],
              [-71.694, 46.6744],
              [-71.8805, 46.6907],
              [-72.2279, 46.5534],
              [-72.258, 46.4709],
              [-72.4623, 46.4023],
              [-72.6025, 46.3013],
              [-72.9945, 46.2108],
              [-73.1586, 46.0749],
              [-73.2899, 45.8751],
              [-73.4954, 45.7279],
              [-73.7554, 45.6764],
              [-74.0777, 45.4567],
              [-73.9615, 45.3542],
              [-74.4936, 45.1462],
              [-74.713, 44.9993],
            ],
          ],
          [
            [
              [-114.1966, 73.3081],
              [-113.962, 73.1229],
              [-114.0231, 72.7978],
              [-114.3561, 72.7463],
              [-114.3218, 72.5918],
              [-113.604, 72.7775],
              [-113.015, 73.016],
              [-112.0773, 72.898],
              [-111.2196, 72.7241],
              [-111.4071, 72.4262],
              [-111.0863, 72.4083],
              [-110.7291, 72.5713],
              [-109.7877, 72.5044],
              [-110.4835, 72.8392],
              [-110.7037, 73.0091],
              [-110.0764, 72.9958],
              [-108.6334, 72.5656],
              [-108.6631, 72.361],
              [-108.1883, 71.9495],
              [-108.2869, 71.7987],
              [-107.9919, 71.6834],
              [-107.6684, 71.7082],
              [-107.2516, 71.9017],
              [-107.7792, 72.1413],
              [-107.7178, 72.3027],
              [-108.2983, 73.1338],
              [-107.662, 73.3284],
              [-107.1724, 73.195],
              [-106.8234, 73.3096],
              [-106.234, 73.0872],
              [-105.8293, 73.0513],
              [-105.3325, 72.7511],
              [-105.4565, 72.703],
              [-105.2769, 72.44],
              [-104.9596, 72.1784],
              [-105.0389, 72.114],
              [-104.3803, 71.6],
              [-104.2824, 71.364],
              [-104.5912, 71.0732],
              [-104.1052, 70.8981],
              [-104.024, 70.7845],
              [-103.5377, 70.5995],
              [-103.1252, 70.5761],
              [-102.4093, 70.4255],
              [-101.5823, 70.1325],
              [-100.9957, 70.175],
              [-100.8722, 69.8078],
              [-100.9643, 69.6645],
              [-101.7068, 69.6912],
              [-102.0248, 69.8086],
              [-102.5823, 69.7435],
              [-102.5096, 69.5642],
              [-103.0411, 69.5153],
              [-103.0371, 69.3662],
              [-102.0548, 69.4844],
              [-101.7684, 69.123],
              [-101.9645, 68.9696],
              [-102.5231, 68.8655],
              [-103.3832, 68.7823],
              [-104.4468, 68.9416],
              [-104.5936, 68.8645],
              [-105.123, 68.8964],
              [-104.9171, 69.0479],
              [-105.8859, 69.1765],
              [-106.204, 69.1499],
              [-106.3198, 69.4047],
              [-106.5839, 69.5036],
              [-106.9264, 69.239],
              [-107.3348, 69.0256],
              [-107.9412, 68.9411],
              [-108.5395, 68.947],
              [-108.9516, 68.7424],
              [-109.6246, 68.6417],
              [-110.6051, 68.6169],
              [-111.251, 68.5197],
              [-111.5312, 68.5438],
              [-112.5833, 68.5097],
              [-112.749, 68.4631],
              [-113.3505, 68.5915],
              [-113.6555, 68.8005],
              [-113.584, 69.2012],
              [-114.3973, 69.2882],
              [-115.189, 69.2427],
              [-115.9748, 69.3002],
              [-116.6327, 69.4618],
              [-116.6196, 69.5717],
              [-117.2521, 69.7641],
              [-117.442, 69.9894],
              [-116.9651, 70.1291],
              [-116.0327, 70.2203],
              [-114.1847, 70.3157],
              [-112.5863, 70.2],
              [-112.2609, 70.3093],
              [-111.8088, 70.3521],
              [-112.144, 70.4939],
              [-112.9259, 70.5654],
              [-113.2071, 70.6479],
              [-113.9884, 70.7162],
              [-114.1831, 70.6678],
              [-115.5809, 70.5801],
              [-116.2334, 70.6397],
              [-117.5854, 70.6064],
              [-118.1868, 70.8394],
              [-118.3865, 71.0222],
              [-117.798, 71.1704],
              [-117.4749, 71.1855],
              [-115.9039, 71.3935],
              [-116.1849, 71.5003],
              [-117.5513, 71.3742],
              [-118.3088, 71.4322],
              [-118.0763, 71.665],
              [-119.0509, 71.6268],
              [-119.0543, 71.9353],
              [-118.6782, 72.1423],
              [-118.1278, 72.2272],
              [-118.5934, 72.4287],
              [-118.1898, 72.6354],
              [-117.8673, 72.7015],
              [-117.3693, 72.9178],
              [-115.5704, 73.2018],
              [-114.5843, 73.3846],
              [-114.1966, 73.3081],
            ],
          ],
          [
            [
              [-104.5932, 73.3132],
              [-105.2025, 72.9445],
              [-105.4857, 72.9558],
              [-106.1207, 73.2927],
              [-107.0393, 73.4844],
              [-106.5996, 73.7106],
              [-105.1671, 73.7567],
              [-104.5008, 73.5672],
              [-104.5932, 73.3132],
            ],
          ],
          [
            [
              [-80.3342, 73.7589],
              [-79.4503, 73.6353],
              [-78.1385, 73.6689],
              [-77.2135, 73.5108],
              [-76.7572, 73.3206],
              [-76.5215, 73.1292],
              [-76.2716, 73.1021],
              [-76.319, 72.8167],
              [-78.1709, 72.8986],
              [-79.1754, 72.7546],
              [-79.9486, 72.8475],
              [-80.1761, 73.0456],
              [-80.1638, 73.2334],
              [-80.795, 73.2853],
              [-80.9034, 73.6074],
              [-80.8058, 73.7204],
              [-80.3342, 73.7589],
            ],
          ],
          [
            [
              [-86.5584, 73.8553],
              [-85.1057, 73.812],
              [-84.952, 73.6677],
              [-85.5772, 73.5124],
              [-86.0303, 73.3024],
              [-86.6986, 72.8161],
              [-86.6802, 72.6462],
              [-86.2534, 72.3946],
              [-86.4604, 72.2034],
              [-86.4082, 72.0058],
              [-86.1276, 71.7943],
              [-85.4969, 71.5144],
              [-84.9597, 71.4288],
              [-84.5283, 71.4731],
              [-84.8916, 71.633],
              [-85.2579, 71.6684],
              [-85.7473, 71.9486],
              [-85.5009, 72.2144],
              [-85.0358, 72.2547],
              [-85.7025, 72.6347],
              [-85.4636, 73.1119],
              [-84.8367, 73.3891],
              [-84.0075, 73.505],
              [-82.8311, 73.7367],
              [-81.5284, 73.7162],
              [-81.2291, 73.5397],
              [-81.1335, 73.2471],
              [-80.6428, 73.1698],
              [-80.6403, 72.9279],
              [-80.2689, 72.7252],
              [-80.5585, 72.6276],
              [-80.5124, 72.3815],
              [-80.8316, 72.2607],
              [-80.7594, 71.9836],
              [-80.3967, 72.0501],
              [-80.4829, 72.1807],
              [-80.049, 72.3979],
              [-79.2834, 72.3944],
              [-78.9433, 72.1959],
              [-78.5696, 72.3557],
              [-78.5351, 72.5146],
              [-77.9771, 72.6984],
              [-77.601, 72.7558],
              [-76.8891, 72.7435],
              [-76.652, 72.6387],
              [-75.7351, 72.5826],
              [-75.1984, 72.4976],
              [-74.9417, 72.2574],
              [-75.0632, 72.1282],
              [-74.2368, 72.0761],
              [-74.2627, 71.8198],
              [-73.5964, 71.7639],
              [-73.6879, 71.5932],
              [-73.2539, 71.33],
              [-72.4726, 71.6473],
              [-71.5819, 71.5151],
              [-71.1171, 71.2705],
              [-71.445, 71.0735],
              [-72.0451, 71.0848],
              [-72.3563, 70.8787],
              [-72.1642, 70.8304],
              [-72.3373, 70.7578],
              [-71.9531, 70.7913],
              [-71.3228, 70.8872],
              [-71.1509, 71.0377],
              [-70.7872, 71.1146],
              [-70.5164, 70.9371],
              [-70.6174, 70.7299],
              [-69.915, 70.8799],
              [-69.5903, 70.7734],
              [-68.3623, 70.5843],
              [-68.4586, 70.373],
              [-69.4702, 70.2481],
              [-68.6436, 70.147],
              [-68.3369, 70.0688],
              [-68.157, 70.3183],
              [-67.8085, 70.2637],
              [-67.2495, 69.9634],
              [-67.1155, 69.7287],
              [-68.0131, 69.7761],
              [-68.5875, 69.591],
              [-68.0051, 69.462],
              [-67.2276, 69.4643],
              [-66.788, 69.34],
              [-66.6688, 69.1846],
              [-67.678, 69.1772],
              [-67.7355, 69.0401],
              [-68.043, 68.9722],
              [-68.1332, 68.8372],
              [-68.5472, 68.7531],
              [-68.3605, 68.63],
              [-67.9312, 68.5282],
              [-67.6641, 68.5647],
              [-67.064, 68.4749],
              [-66.766, 68.2421],
              [-66.3135, 68.1291],
              [-66.2141, 68.0051],
              [-65.1815, 67.9672],
              [-64.7196, 67.9852],
              [-64.607, 67.8318],
              [-64.2897, 67.7294],
              [-63.8885, 67.2416],
              [-63.4121, 67.2177],
              [-63.2504, 67.1323],
              [-63.3491, 66.8907],
              [-62.905, 66.973],
              [-62.284, 66.9461],
              [-62.0041, 67.0368],
              [-61.6056, 66.8716],
              [-61.2705, 66.6048],
              [-61.5763, 66.5505],
              [-61.4923, 66.3674],
              [-61.9346, 66.2895],
              [-62.2196, 66.3972],
              [-62.5385, 66.2274],
              [-61.9542, 66.0198],
              [-62.4289, 65.9787],
              [-62.3064, 65.8289],
              [-62.5942, 65.664],
              [-63.1601, 65.6492],
              [-63.4975, 65.3708],
              [-63.4052, 65.0328],
              [-63.6551, 64.9184],
              [-63.8851, 65.0861],
              [-64.1785, 65.1767],
              [-64.9021, 65.282],
              [-65.1147, 65.5043],
              [-65.5031, 65.7668],
              [-65.3533, 65.9141],
              [-65.8994, 65.9537],
              [-66.4718, 66.3441],
              [-66.7307, 66.3736],
              [-66.9613, 66.5408],
              [-67.3032, 66.2807],
              [-67.5748, 66.4192],
              [-67.7529, 66.2547],
              [-67.2936, 66.1113],
              [-67.1701, 65.9326],
              [-67.81, 65.8784],
              [-67.927, 65.6201],
              [-67.4549, 65.6789],
              [-67.4331, 65.5036],
              [-67.0603, 65.4534],
              [-67.1688, 65.2685],
              [-66.9675, 65.0866],
              [-66.6494, 64.9699],
              [-65.824, 64.8828],
              [-65.5828, 64.7568],
              [-65.7261, 64.5152],
              [-65.081, 64.4792],
              [-65.3074, 64.1934],
              [-65.0767, 64.0834],
              [-64.663, 64.0278],
              [-64.8953, 63.8974],
              [-64.6761, 63.7403],
              [-64.4844, 63.2878],
              [-64.7717, 63.3288],
              [-64.9317, 63.5714],
              [-65.0993, 63.5495],
              [-64.765, 63.0551],
              [-64.8871, 62.8694],
              [-65.2037, 62.8956],
              [-64.9625, 62.6627],
              [-65.1696, 62.5684],
              [-65.6659, 62.9123],
              [-66.5192, 63.0773],
              [-66.6979, 63.0385],
              [-67.0124, 63.2878],
              [-67.5007, 63.4428],
              [-67.8689, 63.4637],
              [-68.4962, 63.7403],
              [-68.9282, 63.7607],
              [-68.7603, 63.5548],
              [-68.4034, 63.3759],
              [-68.1469, 63.1606],
              [-67.7987, 63.0889],
              [-67.198, 62.8769],
              [-66.982, 62.7031],
              [-66.5515, 62.5607],
              [-66.302, 62.3511],
              [-65.93, 62.1978],
              [-66.1285, 62.0886],
              [-65.9504, 61.8969],
              [-66.2745, 61.8581],
              [-66.7735, 62.0172],
              [-67.4006, 62.1498],
              [-68.5537, 62.2497],
              [-69.0272, 62.3798],
              [-69.4451, 62.5535],
              [-69.6269, 62.791],
              [-70.2403, 62.7541],
              [-70.4932, 62.8666],
              [-70.8297, 62.8968],
              [-71.0289, 63.0786],
              [-71.3726, 63.0485],
              [-71.6885, 63.1738],
              [-71.7665, 63.2841],
              [-71.5656, 63.6908],
              [-72.3176, 63.6789],
              [-72.3199, 63.7807],
              [-72.5981, 63.8671],
              [-73.1301, 64.2917],
              [-73.3863, 64.2815],
              [-73.4523, 64.5915],
              [-73.8916, 64.5542],
              [-74.0839, 64.6409],
              [-74.5507, 64.631],
              [-74.7131, 64.382],
              [-75.2382, 64.4551],
              [-75.7069, 64.5793],
              [-75.8621, 64.3776],
              [-76.7279, 64.2961],
              [-77.7578, 64.343],
              [-78.1848, 64.5867],
              [-78.0731, 64.8159],
              [-78.144, 64.9573],
              [-77.9636, 65.0519],
              [-77.3128, 65.2001],
              [-77.5141, 65.3261],
              [-77.4357, 65.4596],
              [-76.6769, 65.4111],
              [-75.7573, 65.2138],
              [-75.7154, 65.3088],
              [-75.2154, 65.2541],
              [-74.8722, 65.3981],
              [-74.5029, 65.3435],
              [-74.1793, 65.5285],
              [-73.496, 65.494],
              [-73.7178, 65.7746],
              [-74.0123, 65.8551],
              [-74.4204, 66.0834],
              [-74.3454, 66.2258],
              [-73.4556, 66.5506],
              [-73.0055, 66.7792],
              [-72.8035, 67.043],
              [-72.3509, 67.1195],
              [-72.4361, 67.5315],
              [-72.8574, 67.8605],
              [-72.9884, 68.1733],
              [-73.2003, 68.2753],
              [-73.728, 68.3137],
              [-73.9474, 68.4135],
              [-73.8852, 68.5549],
              [-74.3725, 68.5438],
              [-74.8789, 68.8255],
              [-75.4967, 68.9342],
              [-76.3256, 68.6985],
              [-76.6732, 68.7658],
              [-76.4225, 69.0599],
              [-75.9899, 69.0109],
              [-75.5717, 69.157],
              [-75.6874, 69.2871],
              [-76.6296, 69.546],
              [-77.0384, 69.6058],
              [-76.8733, 69.8187],
              [-77.4909, 69.8627],
              [-77.6826, 70.1837],
              [-77.8916, 70.2637],
              [-78.3891, 70.2164],
              [-78.7447, 70.4561],
              [-79.1562, 70.4971],
              [-79.5651, 70.3902],
              [-78.7669, 70.1914],
              [-78.6651, 70.0138],
              [-78.8881, 69.8938],
              [-79.6809, 69.8486],
              [-79.8352, 69.9534],
              [-81.0715, 70.0993],
              [-81.1932, 70.0008],
              [-81.6, 69.9774],
              [-82.1258, 69.8181],
              [-82.4415, 69.8441],
              [-83.0516, 70.0105],
              [-83.6728, 69.9464],
              [-84.6948, 70.0066],
              [-84.952, 70.0666],
              [-85.7829, 69.9952],
              [-86.2697, 70.1135],
              [-87.0314, 70.4685],
              [-87.425, 70.3153],
              [-87.8525, 70.238],
              [-88.1413, 70.3959],
              [-88.6941, 70.465],
              [-89.4491, 70.908],
              [-89.1263, 71.0316],
              [-88.6424, 71.0546],
              [-88.3249, 70.9584],
              [-87.3586, 70.9496],
              [-87.2283, 71.0111],
              [-88.067, 71.2128],
              [-89.8134, 71.329],
              [-89.963, 71.4151],
              [-89.9766, 71.6579],
              [-89.7979, 71.7646],
              [-90.1047, 71.9163],
              [-89.7617, 72.1256],
              [-89.963, 72.2574],
              [-89.7532, 72.6108],
              [-89.3209, 72.8823],
              [-89.3119, 73.0463],
              [-89.0512, 73.2517],
              [-88.4138, 73.5255],
              [-87.7645, 73.7167],
              [-86.5584, 73.8553],
            ],
          ],
          [
            [
              [-99.831, 73.8969],
              [-99.2184, 73.7394],
              [-98.0965, 73.8183],
              [-97.5705, 73.8937],
              [-96.9564, 73.7373],
              [-96.9947, 73.6259],
              [-97.6683, 73.4863],
              [-97.2951, 73.3664],
              [-97.8283, 73.2783],
              [-98.3486, 73.0436],
              [-97.6607, 73.0367],
              [-96.932, 72.6757],
              [-96.4523, 72.6422],
              [-96.3036, 72.4267],
              [-96.7185, 72.2995],
              [-96.4848, 72.1285],
              [-96.5597, 71.8398],
              [-96.9616, 71.8068],
              [-97.4612, 71.6174],
              [-97.9698, 71.6615],
              [-98.2152, 71.4094],
              [-98.5415, 71.2922],
              [-99.2351, 71.3496],
              [-99.8644, 71.8594],
              [-100.0355, 71.8676],
              [-100.6365, 72.1865],
              [-101.8401, 72.3265],
              [-101.9665, 72.4971],
              [-102.6142, 72.6613],
              [-102.756, 72.8123],
              [-102.4848, 73.0412],
              [-101.7628, 73.019],
              [-101.2962, 72.7186],
              [-100.4312, 72.7404],
              [-100.1112, 73.0327],
              [-100.4674, 73.2033],
              [-101.289, 73.3601],
              [-101.5616, 73.4686],
              [-100.8858, 73.8321],
              [-99.831, 73.8969],
            ],
          ],
          [
            [
              [-97.6432, 74.0722],
              [-98.1434, 73.8784],
              [-98.8262, 73.8321],
              [-99.3897, 73.9237],
              [-98.7525, 74.0336],
              [-97.7252, 74.1216],
              [-97.6432, 74.0722],
            ],
          ],
          [
            [
              [-92.7471, 74.0851],
              [-92.1138, 73.9843],
              [-91.8145, 74.0288],
              [-90.9939, 74.0059],
              [-90.1957, 73.9022],
              [-91.1758, 73.3542],
              [-91.8577, 72.848],
              [-92.3849, 72.7099],
              [-93.4032, 72.7943],
              [-93.7932, 72.6931],
              [-93.4642, 72.4634],
              [-93.811, 72.3163],
              [-94.0878, 72.0635],
              [-95.1453, 71.9659],
              [-95.2118, 72.1967],
              [-95.1357, 72.4634],
              [-95.5927, 72.6994],
              [-95.7192, 73.0115],
              [-95.582, 73.1623],
              [-95.6129, 73.5964],
              [-95.2486, 74.0099],
              [-94.3739, 74.1323],
              [-93.2726, 74.178],
              [-92.7471, 74.0851],
            ],
          ],
          [
            [
              [-121.5808, 74.5559],
              [-120.1566, 74.279],
              [-119.6622, 74.225],
              [-118.7254, 74.2208],
              [-118.066, 74.2807],
              [-117.4165, 74.2325],
              [-116.867, 74.0876],
              [-115.3236, 73.5087],
              [-115.7538, 73.3575],
              [-116.4591, 73.2612],
              [-117.2155, 73.0661],
              [-119.1639, 72.6298],
              [-119.3402, 72.3467],
              [-119.7728, 72.2296],
              [-120.1967, 72.217],
              [-120.434, 71.9643],
              [-120.3834, 71.7015],
              [-120.6352, 71.4914],
              [-121.4232, 71.3803],
              [-121.7006, 71.4681],
              [-122.1561, 71.2612],
              [-123.0999, 71.0846],
              [-123.4357, 71.2486],
              [-123.6235, 71.4622],
              [-124.0646, 71.7004],
              [-125.2323, 71.9413],
              [-125.7904, 71.9492],
              [-125.7521, 72.1477],
              [-124.9455, 72.703],
              [-125.0384, 72.8325],
              [-124.4904, 72.9251],
              [-124.8599, 73.0903],
              [-124.4453, 73.4193],
              [-124.0679, 73.5397],
              [-123.7738, 73.7656],
              [-124.1453, 73.8511],
              [-124.7557, 74.3486],
              [-123.3035, 74.4494],
              [-122.6232, 74.4631],
              [-121.5808, 74.5559],
            ],
          ],
          [
            [
              [-103.7452, 75.2879],
              [-103.5858, 75.1772],
              [-104.2295, 75.0189],
              [-104.9065, 75.1241],
              [-104.4032, 75.4326],
              [-103.7452, 75.2879],
            ],
          ],
          [
            [
              [-94.3939, 75.6042],
              [-93.4994, 75.268],
              [-93.409, 74.8891],
              [-93.6077, 74.6542],
              [-94.7242, 74.6318],
              [-95.3054, 74.8055],
              [-95.8976, 74.8259],
              [-96.4038, 74.9369],
              [-96.6046, 75.0648],
              [-95.7915, 75.501],
              [-94.8995, 75.6447],
              [-94.3939, 75.6042],
            ],
          ],
          [
            [
              [-96.2869, 75.6503],
              [-96.2357, 75.4661],
              [-96.9596, 75.3847],
              [-97.0549, 75.4992],
              [-96.2869, 75.6503],
            ],
          ],
          [
            [
              [-102.0707, 75.9722],
              [-102.5045, 75.7991],
              [-103.0449, 75.9062],
              [-102.0707, 75.9722],
            ],
          ],
          [
            [
              [-94.3165, 75.7643],
              [-94.6578, 75.7465],
              [-94.9105, 75.9374],
              [-94.4575, 75.9659],
              [-94.3165, 75.7643],
            ],
          ],
          [
            [
              [-102.328, 76.0654],
              [-102.3559, 76.0131],
              [-103.6916, 75.889],
              [-103.7255, 75.9783],
              [-102.328, 76.0654],
            ],
          ],
          [
            [
              [-117.6597, 76.1259],
              [-117.4625, 76.0883],
              [-117.9325, 75.7398],
              [-118.6139, 75.5037],
              [-119.409, 75.6016],
              [-118.6212, 75.9221],
              [-117.6597, 76.1259],
            ],
          ],
          [
            [
              [-79.0111, 76.1289],
              [-78.7999, 76.0849],
              [-79.2792, 75.8765],
              [-79.7176, 75.8825],
              [-79.0111, 76.1289],
            ],
          ],
          [
            [
              [-102.6418, 76.2711],
              [-102.8376, 76.0806],
              [-103.8114, 76.0336],
              [-104.3677, 76.0764],
              [-104.312, 76.2124],
              [-102.7266, 76.3156],
              [-102.6418, 76.2711],
            ],
          ],
          [
            [
              [-103.9356, 76.622],
              [-103.0226, 76.4156],
              [-103.3363, 76.3317],
              [-104.3777, 76.3249],
              [-104.652, 76.602],
              [-103.9356, 76.622],
            ],
          ],
          [
            [
              [-98.408, 76.6677],
              [-97.6651, 76.4862],
              [-97.7886, 76.3223],
              [-97.5004, 76.1573],
              [-97.5702, 75.8652],
              [-97.4249, 75.5197],
              [-97.9237, 75.5196],
              [-98.1167, 75.298],
              [-97.6849, 75.1702],
              [-98.035, 75.0263],
              [-99.6454, 74.976],
              [-100.3302, 75.0141],
              [-100.2523, 75.263],
              [-100.6758, 75.3365],
              [-100.2825, 75.5272],
              [-100.2233, 75.6667],
              [-102.0638, 75.5477],
              [-102.7761, 75.545],
              [-102.2623, 75.8698],
              [-101.5895, 75.9245],
              [-102.1028, 76.2208],
              [-102.0351, 76.4128],
              [-101.4134, 76.4377],
              [-101.1277, 76.2603],
              [-100.4787, 76.2338],
              [-100.963, 76.4803],
              [-100.2696, 76.6496],
              [-98.9047, 76.6244],
              [-98.408, 76.6677],
            ],
          ],
          [
            [
              [-89.7125, 76.5209],
              [-90.0648, 76.4862],
              [-90.568, 76.7156],
              [-89.9822, 76.8424],
              [-89.6964, 76.7535],
              [-89.7125, 76.5209],
            ],
          ],
          [
            [
              [-108.6674, 76.8249],
              [-108.6159, 76.4235],
              [-108.0809, 76.2869],
              [-108.4576, 76.1025],
              [-107.8576, 76.0659],
              [-107.0461, 75.889],
              [-106.6068, 76.0616],
              [-105.7599, 75.9902],
              [-105.5118, 75.8906],
              [-105.3905, 75.6597],
              [-105.6893, 75.5272],
              [-105.6614, 75.3552],
              [-106.0089, 75.0566],
              [-106.7602, 75.003],
              [-107.0052, 74.9238],
              [-107.6863, 74.9822],
              [-108.4166, 74.9171],
              [-108.8242, 75.0715],
              [-109.5819, 74.8581],
              [-110.344, 74.7966],
              [-111.001, 74.6164],
              [-112.4374, 74.4135],
              [-113.0238, 74.3959],
              [-113.693, 74.4445],
              [-114.4398, 74.6837],
              [-112.7978, 74.9851],
              [-111.5555, 75.0126],
              [-111.0279, 75.1772],
              [-113.8866, 75.0582],
              [-113.7843, 75.3397],
              [-114.0465, 75.3664],
              [-114.4408, 75.0679],
              [-114.9585, 74.9705],
              [-115.4058, 75.1029],
              [-115.7154, 74.9704],
              [-117.5101, 75.2011],
              [-117.6749, 75.2932],
              [-117.2022, 75.4808],
              [-116.0419, 75.4848],
              [-115.5897, 75.588],
              [-117.1983, 75.5755],
              [-116.7081, 75.8996],
              [-116.5262, 76.1601],
              [-115.8411, 76.185],
              [-115.8749, 76.3636],
              [-115.5123, 76.4562],
              [-114.2059, 76.471],
              [-113.963, 76.1906],
              [-112.9942, 76.2733],
              [-112.4325, 76.1708],
              [-112.3646, 76.0336],
              [-111.8555, 75.9535],
              [-111.3614, 75.7152],
              [-111.2743, 75.5272],
              [-110.5136, 75.5733],
              [-108.8964, 75.4924],
              [-109.102, 75.7501],
              [-110.0546, 75.8965],
              [-109.4333, 76.0036],
              [-109.5422, 76.1894],
              [-110.3836, 76.3005],
              [-110.3972, 76.3967],
              [-109.5543, 76.6301],
              [-109.1844, 76.8219],
              [-108.6674, 76.8249],
            ],
          ],
          [
            [
              [-113.734, 76.8834],
              [-113.4535, 76.7735],
              [-114.4219, 76.7297],
              [-114.4346, 76.8824],
              [-113.734, 76.8834],
            ],
          ],
          [
            [
              [-95.2388, 77.0137],
              [-93.7009, 76.921],
              [-92.9503, 76.6301],
              [-92.693, 76.596],
              [-91.4411, 76.6937],
              [-90.5717, 76.564],
              [-90.4478, 76.4113],
              [-89.2976, 76.3005],
              [-89.3801, 76.1845],
              [-91.2436, 76.164],
              [-90.1882, 76.0678],
              [-89.1977, 75.7963],
              [-89.2572, 75.6358],
              [-88.5799, 75.6585],
              [-88.2454, 75.4784],
              [-87.7508, 75.5809],
              [-87.1115, 75.5948],
              [-86.7778, 75.476],
              [-86.1964, 75.4154],
              [-86.0104, 75.5359],
              [-85.2991, 75.5749],
              [-85.0449, 75.6558],
              [-84.2896, 75.7133],
              [-83.9181, 75.8145],
              [-83.089, 75.7403],
              [-82.331, 75.8436],
              [-81.1131, 75.7791],
              [-79.501, 75.3919],
              [-79.6285, 75.1802],
              [-80.4434, 75.0399],
              [-80.197, 74.958],
              [-79.592, 75.0206],
              [-79.3952, 74.8763],
              [-80.2454, 74.7587],
              [-80.1617, 74.6238],
              [-80.5419, 74.5635],
              [-81.3202, 74.5618],
              [-81.7887, 74.4584],
              [-83.4935, 74.5778],
              [-84.2583, 74.5065],
              [-87.6436, 74.4583],
              [-88.5178, 74.5012],
              [-88.4118, 74.759],
              [-89.0837, 74.7197],
              [-89.1474, 74.6079],
              [-89.9486, 74.5328],
              [-90.7013, 74.6461],
              [-90.8941, 74.7609],
              [-91.6208, 74.7116],
              [-92.0185, 74.7775],
              [-92.0611, 74.9628],
              [-92.504, 75.218],
              [-92.3, 75.5125],
              [-92.0044, 75.6016],
              [-92.1846, 75.8949],
              [-93.1283, 76.3702],
              [-93.5911, 76.2997],
              [-94.8481, 76.2692],
              [-95.8233, 76.3991],
              [-96.4996, 76.6957],
              [-96.3144, 76.8083],
              [-96.8511, 76.9296],
              [-95.7707, 77.0767],
              [-95.2388, 77.0137],
            ],
          ],
          [
            [
              [-116.1256, 77.4724],
              [-115.4042, 77.3184],
              [-116.2472, 77.1959],
              [-116.2217, 77.0417],
              [-115.8314, 76.9728],
              [-116.0402, 76.8283],
              [-115.8928, 76.6984],
              [-116.3248, 76.5817],
              [-117.0748, 76.5261],
              [-116.9343, 76.3558],
              [-117.3524, 76.2596],
              [-118.007, 76.4025],
              [-118.6464, 76.4324],
              [-118.901, 76.1723],
              [-119.4917, 76.0369],
              [-119.8629, 75.8591],
              [-120.489, 75.8471],
              [-120.7174, 76.0268],
              [-121.4368, 75.9443],
              [-121.8471, 76.0452],
              [-122.4259, 75.9328],
              [-122.4926, 76.1435],
              [-122.8617, 76.1824],
              [-122.3806, 76.4085],
              [-121.5536, 76.437],
              [-121.2043, 76.6782],
              [-120.368, 76.8145],
              [-120.0911, 77.0056],
              [-118.8205, 77.361],
              [-117.8524, 77.3904],
              [-117.2311, 77.2857],
              [-116.9136, 77.4743],
              [-116.1256, 77.4724],
            ],
          ],
          [
            [
              [-90.3041, 77.6294],
              [-89.7159, 77.4587],
              [-89.6571, 77.3242],
              [-90.0939, 77.2041],
              [-90.9245, 77.307],
              [-91.1996, 77.4079],
              [-91.1687, 77.6175],
              [-90.3041, 77.6294],
            ],
          ],
          [
            [
              [-105.0159, 77.4146],
              [-104.4213, 77.3079],
              [-104.754, 77.1112],
              [-105.5363, 77.3044],
              [-105.765, 77.4662],
              [-105.503, 77.7211],
              [-104.9961, 77.5371],
              [-105.0159, 77.4146],
            ],
          ],
          [
            [
              [-95.4032, 77.7815],
              [-93.608, 77.7815],
              [-93.2079, 77.723],
              [-93.4784, 77.4881],
              [-95.9876, 77.4791],
              [-96.2355, 77.7055],
              [-95.4032, 77.7815],
            ],
          ],
          [
            [
              [-100.932, 77.7445],
              [-101.7906, 77.6794],
              [-102.4487, 77.7331],
              [-102.3982, 77.8889],
              [-101.6452, 77.8903],
              [-100.932, 77.7445],
            ],
          ],
          [
            [
              [-114.3169, 78.0142],
              [-113.7373, 77.9017],
              [-113.7225, 77.7692],
              [-114.3007, 77.7133],
              [-115.1182, 77.9597],
              [-114.3169, 78.0142],
            ],
          ],
          [
            [
              [-109.6037, 78.0739],
              [-109.6861, 77.9714],
              [-110.8415, 77.8698],
              [-110.1295, 77.7815],
              [-110.2354, 77.5042],
              [-112.1092, 77.3258],
              [-113.1996, 77.5277],
              [-113.2413, 77.905],
              [-112.2709, 78.0153],
              [-110.7117, 78.1038],
              [-109.6037, 78.0739],
            ],
          ],
          [
            [
              [-110.3972, 78.7535],
              [-109.3467, 78.5353],
              [-109.3389, 78.3547],
              [-109.8232, 78.2954],
              [-110.8403, 78.3152],
              [-111.7785, 78.2702],
              [-112.1755, 78.3774],
              [-113.0615, 78.2744],
              [-113.2142, 78.4066],
              [-112.1501, 78.557],
              [-111.816, 78.5489],
              [-110.7147, 78.7531],
              [-110.3972, 78.7535],
            ],
          ],
          [
            [
              [-96.8942, 78.7029],
              [-96.2735, 78.5351],
              [-95.6998, 78.5261],
              [-94.8279, 78.3637],
              [-95.3699, 78.2468],
              [-94.8893, 78.1093],
              [-95.0607, 77.9726],
              [-96.3655, 77.863],
              [-97.7423, 78.0423],
              [-96.8693, 78.1372],
              [-97.7668, 78.247],
              [-98.3597, 78.4517],
              [-98.3559, 78.7739],
              [-97.451, 78.7971],
              [-96.8942, 78.7029],
            ],
          ],
          [
            [
              [-103.657, 79.3413],
              [-103.1209, 79.2907],
              [-102.6072, 79.0765],
              [-101.6484, 79.081],
              [-99.5579, 78.5987],
              [-99.8027, 78.3085],
              [-99.0445, 78.0819],
              [-99.0172, 77.8938],
              [-99.9386, 77.7829],
              [-100.61, 77.864],
              [-101.036, 78.1992],
              [-102.1608, 78.2883],
              [-102.7441, 78.3806],
              [-103.9465, 78.2428],
              [-104.8196, 78.3616],
              [-105.0177, 78.5245],
              [-104.641, 78.5834],
              [-103.4972, 78.5065],
              [-103.384, 78.7746],
              [-105.6278, 79.165],
              [-105.1568, 79.2999],
              [-103.657, 79.3413],
            ],
          ],
          [
            [
              [-99.4489, 80.1125],
              [-98.8738, 80.0838],
              [-98.6514, 79.7922],
              [-99.3001, 79.7594],
              [-100.1937, 80.0323],
              [-99.4489, 80.1125],
            ],
          ],
          [
            [
              [-92.7729, 81.3177],
              [-91.8291, 81.1619],
              [-91.7081, 81.038],
              [-90.7098, 80.7033],
              [-90.6392, 80.5685],
              [-89.3249, 80.5368],
              [-87.7006, 80.4126],
              [-87.5756, 80.1796],
              [-86.9713, 79.8816],
              [-87.1887, 79.6278],
              [-86.3091, 79.6481],
              [-85.5638, 79.5798],
              [-84.9186, 79.296],
              [-85.5105, 79.1662],
              [-86.5039, 79.0687],
              [-86.6146, 78.9742],
              [-87.8588, 78.6927],
              [-88.0421, 78.4793],
              [-88.5512, 78.4328],
              [-88.77, 78.1855],
              [-89.7135, 78.2261],
              [-90.8841, 78.1446],
              [-92.1163, 78.227],
              [-92.9527, 78.4367],
              [-92.6559, 78.6119],
              [-93.3468, 78.6083],
              [-94.2716, 78.9682],
              [-93.433, 79.1565],
              [-92.6273, 79.2454],
              [-92.5804, 79.3848],
              [-94.3884, 79.371],
              [-94.9607, 79.2916],
              [-95.646, 79.3943],
              [-95.6115, 79.559],
              [-96.5427, 79.8484],
              [-96.773, 80.0727],
              [-94.8457, 80.0454],
              [-96.3216, 80.2616],
              [-96.027, 80.5839],
              [-94.3754, 80.6174],
              [-94.6952, 80.7341],
              [-95.4997, 80.808],
              [-95.2318, 81.0153],
              [-93.2172, 81.2145],
              [-94.3959, 81.2617],
              [-94.1885, 81.3666],
              [-92.7729, 81.3177],
            ],
          ],
          [
            [
              [-69.6875, 83.1165],
              [-69.4834, 83.0456],
              [-67.5968, 82.9712],
              [-65.8516, 82.8424],
              [-63.4471, 82.8267],
              [-63.6515, 82.7178],
              [-63.0655, 82.5373],
              [-61.5789, 82.4889],
              [-61.137, 82.3823],
              [-61.3124, 82.1972],
              [-62.2576, 82.0235],
              [-64.3726, 81.725],
              [-65.3612, 81.7344],
              [-66.6541, 81.4999],
              [-64.5461, 81.5504],
              [-64.6921, 81.3908],
              [-65.9889, 81.2283],
              [-66.6721, 81.0399],
              [-68.2794, 80.7599],
              [-69.3742, 80.3974],
              [-70.2108, 80.1882],
              [-71.1424, 79.7895],
              [-72.2317, 79.6626],
              [-73.3863, 79.7547],
              [-73.1278, 79.5592],
              [-75.028, 79.3733],
              [-76.8865, 79.3529],
              [-75.9482, 79.2372],
              [-74.4687, 79.2272],
              [-75.087, 79.0318],
              [-76.1335, 79.1241],
              [-75.7443, 78.8972],
              [-74.7738, 78.8348],
              [-75.1077, 78.3755],
              [-75.5819, 78.113],
              [-76.8807, 77.905],
              [-77.1538, 77.9487],
              [-77.9807, 77.8167],
              [-77.7368, 77.6008],
              [-78.2891, 77.3775],
              [-78.8198, 77.3837],
              [-79.4324, 77.2386],
              [-78.8675, 76.9244],
              [-78.0909, 77.0228],
              [-77.7162, 76.822],
              [-77.7708, 76.6643],
              [-78.1413, 76.5272],
              [-80.6672, 76.164],
              [-81.0952, 76.2186],
              [-80.7725, 76.42],
              [-81.9353, 76.495],
              [-82.8009, 76.39],
              [-84.235, 76.4468],
              [-84.942, 76.4223],
              [-85.2491, 76.3161],
              [-86.2174, 76.375],
              [-87.6299, 76.3356],
              [-89.1702, 76.4242],
              [-89.6567, 76.5654],
              [-89.419, 76.6875],
              [-89.5372, 76.8568],
              [-88.2936, 77.1363],
              [-87.4746, 77.1114],
              [-87.1326, 77.2136],
              [-87.6812, 77.3534],
              [-87.6466, 77.4868],
              [-88.1883, 77.6404],
              [-88.181, 77.8015],
              [-87.192, 77.9045],
              [-86.2156, 77.7933],
              [-85.7818, 77.5044],
              [-85.4023, 77.4007],
              [-84.617, 77.3837],
              [-84.761, 77.5202],
              [-85.2825, 77.6597],
              [-85.1895, 77.7983],
              [-86.2277, 78.0621],
              [-87.4879, 78.1272],
              [-87.4825, 78.452],
              [-86.6099, 78.8093],
              [-85.7573, 78.8317],
              [-84.7521, 79.0389],
              [-84.3296, 79.1924],
              [-84.4866, 79.4127],
              [-85.3216, 79.6797],
              [-86.4499, 79.7542],
              [-86.6431, 80.1532],
              [-86.4377, 80.32],
              [-85.3157, 80.2685],
              [-83.7879, 80.2509],
              [-82.1709, 79.8689],
              [-82.1687, 80.0183],
              [-83.1592, 80.3354],
              [-80.3523, 80.4726],
              [-79.9643, 80.6113],
              [-78.5319, 80.7807],
              [-79.1532, 81.103],
              [-79.6145, 80.8276],
              [-81.2171, 80.6277],
              [-82.9131, 80.5394],
              [-84.397, 80.51],
              [-86.1178, 80.5368],
              [-88.1011, 80.6808],
              [-89.3864, 80.8789],
              [-88.4132, 80.9988],
              [-86.6992, 81.0054],
              [-86.5219, 81.1252],
              [-89.8262, 81.013],
              [-90.3528, 81.1737],
              [-90.3502, 81.376],
              [-90.8336, 81.4643],
              [-89.9148, 81.5964],
              [-91.9492, 81.6323],
              [-91.7085, 81.7344],
              [-90.2429, 81.8998],
              [-89.6448, 81.864],
              [-88.6807, 82.0584],
              [-86.9648, 82.0515],
              [-86.848, 82.2206],
              [-85.3506, 82.2903],
              [-85.7619, 82.4675],
              [-82.4008, 82.2472],
              [-82.7116, 82.3815],
              [-81.5762, 82.7912],
              [-79.3405, 82.9755],
              [-78.4206, 82.8723],
              [-76.1355, 83.0537],
              [-74.2, 83.0021],
              [-73.6531, 82.9271],
              [-72.5916, 83.1024],
              [-69.6875, 83.1165],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Canada",
        NAME_LONG: "Canada",
        ADM0_A3: "CAN",
        ISO_A2: "CA",
        ISO_A3: "CAN",
        WB_A2: "CA",
        WB_A3: "CAN",
      },
      id: 39,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.5475, 47.5345],
            [9.6495, 47.4097],
            [9.5212, 47.2628],
            [9.5812, 47.0569],
            [10.1113, 46.8471],
            [10.3679, 46.9955],
            [10.4538, 46.8644],
            [10.2954, 46.5511],
            [10.1334, 46.414],
            [9.6743, 46.2918],
            [9.2632, 46.4851],
            [9.2248, 46.2312],
            [9.0021, 46.0393],
            [8.6018, 46.1228],
            [8.4272, 46.2514],
            [8.4416, 46.4349],
            [8.0773, 46.262],
            [8.1106, 46.127],
            [7.8312, 45.9145],
            [7.5411, 45.9841],
            [7.1837, 45.8805],
            [7.0221, 45.9253],
            [6.8539, 46.1226],
            [6.7627, 46.4293],
            [6.4829, 46.4486],
            [6.2141, 46.3155],
            [6.0642, 46.4711],
            [6.1319, 46.5956],
            [6.4292, 46.7608],
            [6.4426, 46.9442],
            [6.6654, 47.0213],
            [7.0443, 47.3405],
            [7.1037, 47.4963],
            [7.3785, 47.4306],
            [7.586, 47.5846],
            [8.0872, 47.5674],
            [8.5582, 47.8012],
            [8.9062, 47.6518],
            [9.2732, 47.6501],
            [9.5475, 47.5345],
          ],
        ],
      },
      properties: {
        NAME: "Switzerland",
        NAME_LONG: "Switzerland",
        ADM0_A3: "CHE",
        ISO_A2: "CH",
        ISO_A3: "CHE",
        WB_A2: "CH",
        WB_A3: "CHE",
      },
      id: 40,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-68.3457, -54.9985],
              [-68.5306, -55.1098],
              [-68.2392, -55.2923],
              [-68.2525, -55.5245],
              [-68.9674, -55.4299],
              [-68.8558, -55.2575],
              [-69.2721, -55.2135],
              [-69.6077, -55.3655],
              [-70.0315, -55.1533],
              [-69.886, -55.0636],
              [-69.3197, -54.9933],
              [-68.4412, -54.9397],
              [-68.3457, -54.9985],
            ],
          ],
          [
            [
              [-67.8058, -54.9138],
              [-67.3018, -54.9289],
              [-67.0619, -55.072],
              [-67.2706, -55.3069],
              [-67.6088, -55.1887],
              [-68.1569, -55.1961],
              [-68.2115, -54.908],
              [-67.8058, -54.9138],
            ],
          ],
          [
            [
              [-70.5054, -54.9508],
              [-70.4081, -55.052],
              [-70.7158, -55.1228],
              [-70.9911, -55.0336],
              [-70.5054, -54.9508],
            ],
          ],
          [
            [
              [-69.5093, -54.9081],
              [-69.8622, -55.039],
              [-69.9283, -54.8976],
              [-69.5093, -54.9081],
            ],
          ],
          [
            [
              [-71.524, -53.986],
              [-71.3015, -53.9915],
              [-71.2145, -54.1501],
              [-70.9901, -54.2658],
              [-71.1139, -54.3798],
              [-71.6998, -54.1662],
              [-71.524, -53.986],
            ],
          ],
          [
            [
              [-71.8664, -53.8865],
              [-71.7273, -53.8986],
              [-71.7134, -54.1477],
              [-71.9369, -54.2459],
              [-72.228, -54.0584],
              [-72.2582, -53.9451],
              [-71.8664, -53.8865],
            ],
          ],
          [
            [
              [-70.4197, -53.8712],
              [-70.3363, -54.0074],
              [-70.5404, -54.2216],
              [-70.8227, -54.1135],
              [-70.8815, -53.8423],
              [-70.4695, -53.5663],
              [-70.4197, -53.8712],
            ],
          ],
          [
            [
              [-73.0114, -53.4892],
              [-72.6559, -53.5368],
              [-72.187, -53.7765],
              [-72.3285, -53.8396],
              [-72.5433, -54.1],
              [-72.817, -54.1266],
              [-72.9337, -54.0584],
              [-72.7431, -53.8936],
              [-73.0665, -53.8313],
              [-73.2041, -53.9894],
              [-73.3625, -53.7133],
              [-73.34, -53.5479],
              [-73.0114, -53.4892],
            ],
          ],
          [
            [
              [-74.6744, -52.7213],
              [-74.4072, -52.9138],
              [-73.8759, -53.0032],
              [-73.4351, -53.1402],
              [-73.2862, -53.2633],
              [-73.5596, -53.3237],
              [-73.6059, -53.2111],
              [-73.8804, -53.0577],
              [-74.2626, -53.0912],
              [-74.6744, -52.7213],
            ],
          ],
          [
            [
              [-68.6276, -52.6396],
              [-68.6347, -53.7109],
              [-68.6419, -54.783],
              [-68.642, -54.7992],
              [-68.6541, -54.8862],
              [-69.067, -54.9488],
              [-69.7949, -54.7877],
              [-70.2397, -54.8578],
              [-71.2897, -54.6764],
              [-71.4774, -54.6764],
              [-71.94, -54.5108],
              [-71.725, -54.4055],
              [-71.0109, -54.4487],
              [-70.8603, -54.3178],
              [-70.9327, -54.1135],
              [-70.3225, -54.3253],
              [-70.073, -54.2488],
              [-69.5005, -54.3422],
              [-70.0568, -54.087],
              [-70.1853, -53.8113],
              [-70.137, -53.7387],
              [-69.6514, -53.6362],
              [-69.3608, -53.5217],
              [-69.3527, -53.3557],
              [-69.6755, -53.3584],
              [-70.2567, -53.461],
              [-70.4735, -53.3093],
              [-70.419, -53.0117],
              [-70.2435, -53.0617],
              [-70.0011, -52.8417],
              [-69.7344, -52.7745],
              [-69.591, -52.6419],
              [-69.5839, -52.5099],
              [-69.3742, -52.4703],
              [-69.1402, -52.6838],
              [-68.746, -52.5569],
              [-68.6276, -52.6396],
            ],
          ],
          [
            [
              [-74.8321, -52.2175],
              [-74.5893, -52.2129],
              [-74.5938, -52.3948],
              [-74.8154, -52.3504],
              [-74.8321, -52.2175],
            ],
          ],
          [
            [
              [-74.2367, -52.1095],
              [-73.951, -52.3071],
              [-74.1873, -52.3674],
              [-74.2367, -52.1095],
            ],
          ],
          [
            [
              [-74.6777, -51.2014],
              [-74.7987, -51.4323],
              [-74.9628, -51.3294],
              [-74.6777, -51.2014],
            ],
          ],
          [
            [
              [-74.4755, -50.7997],
              [-74.3856, -50.9811],
              [-74.8932, -51.0492],
              [-74.8787, -50.8863],
              [-74.6255, -50.9249],
              [-74.6431, -50.8478],
              [-74.4755, -50.7997],
            ],
          ],
          [
            [
              [-74.7424, -50.7072],
              [-74.6605, -50.8239],
              [-74.693, -50.8901],
              [-74.9416, -50.8376],
              [-74.7424, -50.7072],
            ],
          ],
          [
            [
              [-74.4892, -50.4738],
              [-74.3831, -50.456],
              [-74.2424, -50.714],
              [-74.3738, -50.8274],
              [-74.5812, -50.6336],
              [-74.4892, -50.4738],
            ],
          ],
          [
            [
              [-75.2916, -50.0289],
              [-74.9521, -50.1088],
              [-74.8339, -50.2195],
              [-75.0593, -50.2978],
              [-75.4609, -50.35],
              [-75.2916, -50.0289],
            ],
          ],
          [
            [
              [-75.4852, -49.5647],
              [-75.3845, -49.7482],
              [-75.6268, -49.7563],
              [-75.4852, -49.5647],
            ],
          ],
          [
            [
              [-74.681, -48.7267],
              [-74.5255, -48.7228],
              [-74.4482, -49.3224],
              [-74.5364, -49.508],
              [-74.4205, -49.6896],
              [-74.4855, -49.9531],
              [-74.6606, -50.0506],
              [-74.8677, -50.0162],
              [-74.9615, -49.7853],
              [-75.1339, -49.8583],
              [-75.4673, -49.3327],
              [-75.4272, -49.2795],
              [-74.9302, -49.3142],
              [-74.8045, -49.1039],
              [-75.0515, -48.857],
              [-74.985, -48.759],
              [-74.681, -48.7267],
            ],
          ],
          [
            [
              [-75.3792, -48.4558],
              [-75.3399, -48.6148],
              [-75.6261, -48.632],
              [-75.6064, -48.4392],
              [-75.3792, -48.4558],
            ],
          ],
          [
            [
              [-74.7213, -48.2219],
              [-74.5869, -48.4034],
              [-74.621, -48.6918],
              [-74.9471, -48.6196],
              [-75.0331, -48.5045],
              [-74.82, -48.4035],
              [-74.7213, -48.2219],
            ],
          ],
          [
            [
              [-75.1205, -48.1026],
              [-74.8278, -48.1455],
              [-74.8374, -48.3448],
              [-75.0311, -48.4438],
              [-75.1558, -48.3122],
              [-75.1205, -48.1026],
            ],
          ],
          [
            [
              [-75.3491, -48.0592],
              [-75.0987, -48.4659],
              [-75.555, -48.3786],
              [-75.5853, -48.0857],
              [-75.3491, -48.0592],
            ],
          ],
          [
            [
              [-74.8314, -47.8108],
              [-74.7274, -48.1059],
              [-75.0335, -48.0312],
              [-74.8314, -47.8108],
            ],
          ],
          [
            [
              [-73.9757, -47.8598],
              [-74.3185, -47.9812],
              [-74.2452, -47.8064],
              [-73.9757, -47.8598],
            ],
          ],
          [
            [
              [-74.7211, -45.8098],
              [-74.7663, -45.9889],
              [-74.9197, -46.1042],
              [-75.0743, -46.0938],
              [-75.1129, -45.8857],
              [-74.7211, -45.8098],
            ],
          ],
          [
            [
              [-73.8456, -45.0012],
              [-73.7146, -45.1142],
              [-73.8301, -45.2431],
              [-74.0074, -45.2321],
              [-74.2069, -45.0427],
              [-73.8456, -45.0012],
            ],
          ],
          [
            [
              [-73.9178, -44.9565],
              [-74.3569, -45.0115],
              [-74.1883, -44.8691],
              [-73.9178, -44.9565],
            ],
          ],
          [
            [
              [-73.9268, -44.6288],
              [-74.0118, -44.7165],
              [-74.2797, -44.8038],
              [-74.4005, -44.6261],
              [-73.9268, -44.6288],
            ],
          ],
          [
            [
              [-72.9339, -44.4509],
              [-72.7212, -44.528],
              [-72.8376, -44.7101],
              [-73.1475, -44.9119],
              [-73.4076, -44.8224],
              [-73.4681, -44.6425],
              [-73.2974, -44.6061],
              [-73.2612, -44.4772],
              [-73.002, -44.3613],
              [-72.9339, -44.4509],
            ],
          ],
          [
            [
              [-73.9546, -41.8107],
              [-73.866, -41.8923],
              [-73.691, -41.809],
              [-73.537, -41.84],
              [-73.4891, -42.1456],
              [-73.374, -42.2574],
              [-73.6815, -42.37],
              [-73.8092, -42.6087],
              [-73.5167, -42.7892],
              [-73.6497, -42.8832],
              [-73.4901, -43.1],
              [-73.7214, -43.1417],
              [-73.6817, -43.3811],
              [-73.877, -43.422],
              [-74.401, -43.2604],
              [-74.2295, -43.0229],
              [-74.1275, -42.6711],
              [-74.1896, -42.4319],
              [-74.1471, -42.2165],
              [-74.0533, -42.1303],
              [-74.0553, -41.945],
              [-73.9546, -41.8107],
            ],
          ],
          [
            [
              [-67.1939, -22.8222],
              [-67.014, -23.0007],
              [-67.3398, -24.0006],
              [-67.3624, -24.0304],
              [-68.2445, -24.3854],
              [-68.5037, -24.6123],
              [-68.5724, -24.7699],
              [-68.3674, -25.1034],
              [-68.516, -25.1836],
              [-68.606, -25.437],
              [-68.4985, -25.7549],
              [-68.4199, -26.1793],
              [-68.5752, -26.3035],
              [-68.5702, -26.5506],
              [-68.3201, -26.8701],
              [-68.3305, -27.045],
              [-68.518, -27.0769],
              [-68.5859, -27.1628],
              [-68.8011, -27.1128],
              [-69.1243, -27.7344],
              [-69.173, -27.9241],
              [-69.3032, -27.9995],
              [-69.4203, -28.2126],
              [-69.653, -28.3979],
              [-69.7409, -28.6406],
              [-69.7358, -28.8124],
              [-69.8039, -29.0987],
              [-69.9109, -29.1432],
              [-70.0426, -29.3631],
              [-69.9291, -29.7183],
              [-69.9222, -29.9396],
              [-69.9756, -30.0586],
              [-69.8497, -30.1266],
              [-69.9028, -30.3127],
              [-70.2171, -30.5151],
              [-70.3195, -30.8008],
              [-70.3398, -31.0417],
              [-70.4798, -31.0967],
              [-70.569, -31.3042],
              [-70.5914, -31.5496],
              [-70.4283, -31.8701],
              [-70.2417, -31.9615],
              [-70.3352, -32.14],
              [-70.3218, -32.2801],
              [-70.1725, -32.4649],
              [-70.14, -32.7683],
              [-70.0002, -32.8766],
              [-70.1112, -33.0384],
              [-70.1008, -33.1869],
              [-69.9053, -33.2382],
              [-69.7877, -33.3794],
              [-69.8954, -33.6623],
              [-69.9096, -33.9557],
              [-69.8328, -34.2432],
              [-70.0403, -34.277],
              [-70.0587, -34.3958],
              [-70.2278, -34.5853],
              [-70.3537, -34.9533],
              [-70.3866, -35.1667],
              [-70.5429, -35.2093],
              [-70.4082, -35.5062],
              [-70.4202, -35.8684],
              [-70.3803, -36.046],
              [-70.7031, -36.2601],
              [-70.7187, -36.4146],
              [-70.8904, -36.4004],
              [-71.0433, -36.4843],
              [-71.1453, -36.6883],
              [-71.1231, -37.4432],
              [-71.185, -37.7061],
              [-71.01, -38.0655],
              [-70.9738, -38.4247],
              [-70.8487, -38.5276],
              [-70.8738, -38.6914],
              [-71.228, -38.8088],
              [-71.4158, -38.9353],
              [-71.402, -39.236],
              [-71.52, -39.615],
              [-71.7187, -39.6234],
              [-71.6248, -39.931],
              [-71.6837, -40.099],
              [-71.8238, -40.2101],
              [-71.6785, -40.3402],
              [-71.8042, -40.4264],
              [-71.9556, -40.7204],
              [-71.8514, -40.9383],
              [-71.904, -41.3677],
              [-71.8533, -41.5673],
              [-71.926, -41.6229],
              [-71.794, -41.8675],
              [-71.7363, -42.0839],
              [-72.1247, -42.2633],
              [-72.0419, -42.491],
              [-72.1484, -42.5926],
              [-72.1127, -42.8638],
              [-72.1485, -42.9987],
              [-72.0098, -43.1204],
              [-71.743, -43.1901],
              [-71.9017, -43.322],
              [-71.9373, -43.4569],
              [-71.7238, -43.5948],
              [-71.8192, -43.7581],
              [-71.6599, -43.9263],
              [-71.8587, -44.1078],
              [-71.822, -44.4032],
              [-71.2098, -44.4276],
              [-71.1227, -44.5303],
              [-71.336, -44.8016],
              [-71.4971, -44.7429],
              [-71.8545, -44.791],
              [-72.0478, -44.7548],
              [-72.0809, -44.8866],
              [-71.5887, -44.9781],
              [-71.3115, -45.2995],
              [-71.5083, -45.4084],
              [-71.5258, -45.519],
              [-71.7651, -45.5724],
              [-71.7985, -45.7399],
              [-71.6123, -45.9705],
              [-71.7702, -46.1126],
              [-71.6872, -46.6901],
              [-71.9341, -46.7995],
              [-72.0098, -47.1982],
              [-72.1706, -47.4075],
              [-72.3287, -47.4265],
              [-72.3444, -47.6023],
              [-72.5439, -47.9148],
              [-72.343, -48.0704],
              [-72.3256, -48.2855],
              [-72.5768, -48.4521],
              [-72.5923, -48.7911],
              [-72.7429, -48.9138],
              [-73.0097, -48.9904],
              [-73.1713, -49.1893],
              [-73.145, -49.3037],
              [-73.4579, -49.3077],
              [-73.5866, -49.53],
              [-73.4651, -49.76],
              [-73.5727, -49.9324],
              [-73.5306, -50.1408],
              [-73.3229, -50.2684],
              [-73.2619, -50.5566],
              [-73.0961, -50.7706],
              [-72.7784, -50.6196],
              [-72.506, -50.6013],
              [-72.3028, -50.6489],
              [-72.2635, -50.8363],
              [-72.3822, -51.1606],
              [-72.3134, -51.298],
              [-72.3515, -51.5835],
              [-72.2811, -51.7015],
              [-71.9585, -51.868],
              [-71.9177, -51.9901],
              [-69.9528, -52.0074],
              [-69.4853, -52.1325],
              [-69.2123, -52.138],
              [-68.4484, -52.3469],
              [-68.8985, -52.3035],
              [-69.2347, -52.2031],
              [-69.4825, -52.2905],
              [-69.6724, -52.5309],
              [-69.8466, -52.4865],
              [-70.3218, -52.6381],
              [-70.583, -52.672],
              [-70.8121, -52.8214],
              [-70.8169, -52.9924],
              [-70.9927, -53.383],
              [-70.9736, -53.756],
              [-71.2287, -53.8747],
              [-71.6446, -53.8237],
              [-72.0535, -53.707],
              [-72.2841, -53.5812],
              [-72.4533, -53.4073],
              [-72.2823, -53.2428],
              [-71.835, -53.252],
              [-71.2541, -53.0168],
              [-71.1693, -52.8128],
              [-71.5308, -52.8667],
              [-71.9062, -53.0109],
              [-72.0856, -53.1344],
              [-72.4669, -53.2831],
              [-72.5225, -53.5444],
              [-73.1991, -53.2408],
              [-72.891, -53.0568],
              [-72.9604, -52.825],
              [-72.6116, -52.7419],
              [-72.5622, -52.8337],
              [-72.1609, -52.6521],
              [-71.8505, -52.6947],
              [-71.5716, -52.5613],
              [-72.3278, -52.5252],
              [-72.4136, -52.6489],
              [-72.6561, -52.5217],
              [-72.7987, -52.6154],
              [-72.7908, -52.7527],
              [-73.0053, -52.8333],
              [-72.9536, -53.0236],
              [-73.3273, -53.0715],
              [-73.2689, -52.6736],
              [-73.6879, -52.5978],
              [-73.5309, -52.457],
              [-73.5806, -52.2084],
              [-73.3339, -52.2187],
              [-73.0869, -52.0825],
              [-72.8945, -52.0571],
              [-72.8028, -51.9374],
              [-72.529, -51.9548],
              [-72.4669, -51.7897],
              [-72.9194, -51.8586],
              [-73.1453, -51.94],
              [-73.3162, -52.1661],
              [-73.54, -52.0553],
              [-73.6804, -51.7692],
              [-73.9042, -51.6117],
              [-73.9343, -51.3993],
              [-73.7196, -51.3565],
              [-73.7843, -51.2236],
              [-74.1295, -51.1877],
              [-74.2564, -50.9394],
              [-74.1261, -50.7625],
              [-74.5737, -50.0993],
              [-74.2507, -50.081],
              [-74.3223, -49.6325],
              [-74.2149, -49.5231],
              [-74.4241, -49.3712],
              [-74.4005, -48.9748],
              [-74.4535, -48.831],
              [-74.3922, -48.5991],
              [-74.1971, -48.4733],
              [-74.3179, -48.2121],
              [-74.4425, -48.1824],
              [-74.6254, -48.0042],
              [-74.4154, -47.9837],
              [-73.8911, -48.0222],
              [-73.5917, -48.1407],
              [-73.7097, -47.7761],
              [-73.9237, -47.8427],
              [-74.0737, -47.773],
              [-74.6284, -47.7678],
              [-74.7362, -47.7045],
              [-74.5289, -47.4223],
              [-74.3247, -47.2189],
              [-74.1391, -47.2223],
              [-73.9576, -47.0603],
              [-74.1186, -46.9881],
              [-74.2668, -46.7817],
              [-74.6509, -46.7883],
              [-74.681, -46.8839],
              [-75.0834, -46.6409],
              [-75.4372, -46.6498],
              [-75.3526, -46.9006],
              [-75.5517, -46.9483],
              [-75.707, -46.7866],
              [-75.7047, -46.6393],
              [-75.5171, -46.5543],
              [-75.0907, -46.2161],
              [-74.9581, -46.2153],
              [-74.7134, -45.9845],
              [-74.6909, -45.8416],
              [-74.1894, -45.7936],
              [-73.7698, -46.2346],
              [-73.5802, -46.3102],
              [-73.6606, -45.9757],
              [-73.5548, -45.8776],
              [-73.5173, -45.4604],
              [-73.3277, -45.3409],
              [-73.4607, -45.2663],
              [-73.3044, -45.1448],
              [-73.3483, -44.9578],
              [-73.1171, -44.9483],
              [-72.7277, -44.7586],
              [-72.6868, -44.4885],
              [-73.2777, -44.11],
              [-72.9794, -43.7612],
              [-72.973, -43.6214],
              [-73.1113, -43.461],
              [-73.0846, -43.3163],
              [-72.9122, -43.2354],
              [-72.9344, -43.0982],
              [-72.7611, -43.009],
              [-72.8422, -42.8381],
              [-72.8238, -42.5042],
              [-72.6798, -42.5042],
              [-72.8097, -42.2433],
              [-72.6199, -42.1847],
              [-72.488, -42.0647],
              [-72.7373, -42.0139],
              [-72.8636, -41.9059],
              [-72.6451, -41.7228],
              [-72.7516, -41.5484],
              [-72.9573, -41.4836],
              [-73.1119, -41.5862],
              [-73.0592, -41.6974],
              [-73.2014, -41.7848],
              [-73.515, -41.8036],
              [-73.66, -41.7575],
              [-73.7982, -41.5657],
              [-73.9469, -40.9728],
              [-73.8415, -40.6667],
              [-73.7285, -40.5442],
              [-73.7809, -40.4373],
              [-73.6832, -39.9425],
              [-73.4019, -39.8041],
              [-73.4061, -39.6949],
              [-73.2416, -39.4895],
              [-73.2211, -39.3417],
              [-73.2834, -39.0753],
              [-73.5433, -38.4207],
              [-73.4628, -38.0917],
              [-73.5032, -37.9333],
              [-73.6681, -37.7194],
              [-73.6041, -37.5124],
              [-73.6718, -37.3626],
              [-73.6076, -37.1555],
              [-73.4674, -37.2346],
              [-73.2826, -37.214],
              [-73.1733, -37.0763],
              [-73.1597, -36.735],
              [-73.002, -36.7138],
              [-72.8323, -36.308],
              [-72.7801, -35.9526],
              [-72.598, -35.8102],
              [-72.6466, -35.5666],
              [-72.5297, -35.4904],
              [-72.364, -35.2078],
              [-72.2306, -35.1161],
              [-72.1878, -34.8855],
              [-72.0572, -34.6588],
              [-72.0268, -34.1627],
              [-71.8081, -33.777],
              [-71.6877, -33.7209],
              [-71.6408, -33.5006],
              [-71.7203, -33.1676],
              [-71.5682, -33.0019],
              [-71.4459, -32.6428],
              [-71.4125, -32.3696],
              [-71.5439, -32.1933],
              [-71.515, -31.7755],
              [-71.6678, -31.1505],
              [-71.6549, -30.976],
              [-71.7134, -30.6149],
              [-71.6392, -30.2522],
              [-71.5176, -30.2786],
              [-71.3826, -30.1477],
              [-71.4125, -29.9918],
              [-71.2866, -29.9103],
              [-71.336, -29.525],
              [-71.3072, -29.4212],
              [-71.4943, -29.1926],
              [-71.522, -28.9401],
              [-71.3019, -28.6749],
              [-71.3126, -28.5834],
              [-71.1739, -28.3606],
              [-71.1381, -27.946],
              [-71.0387, -27.6664],
              [-70.9124, -27.6218],
              [-70.96, -27.1614],
              [-70.7886, -26.9963],
              [-70.8227, -26.887],
              [-70.7325, -26.7493],
              [-70.7067, -26.4929],
              [-70.6424, -26.3153],
              [-70.6307, -26.0095],
              [-70.7104, -25.8354],
              [-70.6514, -25.5408],
              [-70.4448, -25.3449],
              [-70.4727, -24.9753],
              [-70.5764, -24.7317],
              [-70.5828, -24.5248],
              [-70.5003, -24.0974],
              [-70.4985, -23.7846],
              [-70.4053, -23.6508],
              [-70.4131, -23.5113],
              [-70.5851, -23.4236],
              [-70.5622, -23.0598],
              [-70.4508, -23.0971],
              [-70.297, -22.9177],
              [-70.2001, -22.0055],
              [-70.152, -21.6648],
              [-70.0587, -21.4334],
              [-70.0801, -21.2333],
              [-70.1644, -21.0405],
              [-70.1275, -20.9353],
              [-70.2056, -20.7394],
              [-70.1921, -20.3668],
              [-70.1233, -20.0722],
              [-70.1512, -19.7194],
              [-70.2877, -19.301],
              [-70.2774, -19.109],
              [-70.3566, -18.7734],
              [-70.3036, -18.4454],
              [-70.3947, -18.3377],
              [-70.1342, -18.3189],
              [-69.9703, -18.2506],
              [-69.7831, -17.9816],
              [-69.8576, -17.7342],
              [-69.6839, -17.656],
              [-69.5101, -17.5066],
              [-69.4758, -17.6524],
              [-69.3342, -17.8058],
              [-69.3024, -17.9762],
              [-69.1408, -18.0308],
              [-69.0344, -18.4783],
              [-69.0428, -18.6005],
              [-68.9546, -18.857],
              [-68.9896, -18.9465],
              [-68.6633, -19.2736],
              [-68.4932, -19.377],
              [-68.4964, -19.4584],
              [-68.6984, -19.7463],
              [-68.5535, -19.8579],
              [-68.5883, -20.0556],
              [-68.7755, -20.0897],
              [-68.6785, -20.3286],
              [-68.6855, -20.5168],
              [-68.4816, -20.643],
              [-68.572, -20.8724],
              [-68.4163, -20.9598],
              [-68.2075, -21.2843],
              [-68.1984, -21.5719],
              [-68.1076, -21.7896],
              [-68.0962, -21.954],
              [-67.9902, -22.0576],
              [-67.9453, -22.3542],
              [-67.8587, -22.5668],
              [-67.8763, -22.8336],
              [-67.7714, -22.8887],
              [-67.5471, -22.8925],
              [-67.1939, -22.8222],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Chile",
        NAME_LONG: "Chile",
        ADM0_A3: "CHL",
        ISO_A2: "CL",
        ISO_A3: "CHL",
        WB_A2: "CL",
        WB_A3: "CHL",
      },
      id: 41,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [110.6851, 20.1533],
              [110.7883, 20.0104],
              [110.9346, 19.9951],
              [111, 19.7217],
              [110.8765, 19.5398],
              [110.6983, 19.4169],
              [110.5632, 19.0557],
              [110.4656, 18.8819],
              [110.4268, 18.6809],
              [110.2708, 18.6682],
              [110.1033, 18.5394],
              [110.0526, 18.3902],
              [109.7461, 18.3959],
              [109.6773, 18.2451],
              [109.568, 18.1932],
              [109.44, 18.289],
              [109.0007, 18.3672],
              [108.695, 18.5048],
              [108.6936, 18.7199],
              [108.6152, 19.1016],
              [108.6317, 19.2863],
              [109.1565, 19.6496],
              [109.1668, 19.8055],
              [109.3037, 19.9217],
              [109.465, 19.8341],
              [109.527, 19.9514],
              [109.719, 20.0106],
              [110.0896, 19.9781],
              [110.383, 20.081],
              [110.5693, 20.0798],
              [110.6851, 20.1533],
            ],
          ],
          [
            [
              [121.9129, 31.4563],
              [121.8514, 31.4392],
              [121.3145, 31.6556],
              [121.2641, 31.8411],
              [121.6632, 31.6524],
              [121.9801, 31.5403],
              [121.9129, 31.4563],
            ],
          ],
          [
            [
              [130.5308, 42.5305],
              [130.243, 42.7434],
              [130.239, 42.9022],
              [130.1215, 42.9818],
              [129.8798, 42.996],
              [129.7613, 42.746],
              [129.7033, 42.4424],
              [129.2431, 42.3787],
              [129.2112, 42.22],
              [128.9638, 42.0885],
              [128.5024, 41.9961],
              [128.262, 42.0329],
              [128.0346, 41.9937],
              [128.04, 41.8796],
              [128.303, 41.5834],
              [128.1857, 41.4045],
              [127.9442, 41.4709],
              [127.6383, 41.4299],
              [127.2691, 41.505],
              [126.8871, 41.7849],
              [126.6179, 41.6659],
              [126.5013, 41.4344],
              [126.2607, 41.1431],
              [126.1474, 41.0923],
              [126.0078, 40.8993],
              [125.7609, 40.8857],
              [125.3636, 40.6467],
              [124.8955, 40.4836],
              [124.37, 40.0983],
              [124.2022, 39.8786],
              [123.9857, 39.8102],
              [123.6226, 39.8717],
              [123.1743, 39.6658],
              [123.0091, 39.6805],
              [122.3963, 39.411],
              [122.115, 39.2008],
              [122.1275, 39.142],
              [121.6548, 38.8579],
              [121.3447, 38.8146],
              [121.1531, 38.7276],
              [121.0904, 38.8991],
              [121.6741, 39.088],
              [121.436, 39.5086],
              [121.4573, 39.7485],
              [121.5359, 39.8618],
              [121.7744, 39.9208],
              [122.0916, 40.2272],
              [122.3018, 40.5023],
              [122.0491, 40.7461],
              [121.8511, 40.8323],
              [121.575, 40.8415],
              [121.3613, 40.9396],
              [120.991, 40.8313],
              [120.9402, 40.6912],
              [120.6209, 40.4744],
              [120.436, 40.1945],
              [119.9503, 40.0565],
              [119.912, 39.9981],
              [119.5356, 39.8908],
              [119.3396, 39.7156],
              [119.262, 39.5351],
              [119.3051, 39.433],
              [118.9402, 39.1389],
              [118.6194, 39.161],
              [118.3443, 39.0307],
              [118.0454, 39.219],
              [117.8856, 39.2011],
              [117.7183, 39.093],
              [117.584, 38.8116],
              [117.566, 38.6125],
              [117.7161, 38.3785],
              [118.0848, 38.1387],
              [118.543, 38.0683],
              [118.8401, 38.1526],
              [118.9966, 37.9631],
              [119.2431, 37.7695],
              [119.0371, 37.7221],
              [118.9767, 37.6109],
              [118.9724, 37.2807],
              [119.1455, 37.1789],
              [119.4412, 37.1206],
              [119.7706, 37.1518],
              [119.8973, 37.2494],
              [119.8589, 37.3602],
              [120.0598, 37.4404],
              [120.4634, 37.7477],
              [120.738, 37.834],
              [120.9258, 37.8193],
              [121.138, 37.7012],
              [121.1915, 37.5752],
              [121.3817, 37.5661],
              [121.4769, 37.468],
              [122.1299, 37.4835],
              [122.5491, 37.3109],
              [122.4476, 37.1474],
              [122.5254, 36.9445],
              [122.3198, 36.8229],
              [122.0396, 36.9846],
              [121.5601, 36.7676],
              [121.4089, 36.754],
              [120.7506, 36.4592],
              [120.6477, 36.3309],
              [120.6968, 36.144],
              [120.3535, 36.0408],
              [120.3616, 36.1916],
              [120.1053, 36.2009],
              [120.1002, 36.0961],
              [120.2428, 35.9736],
              [119.8116, 35.6479],
              [119.648, 35.5839],
              [119.5515, 35.38],
              [119.4239, 35.3135],
              [119.358, 35.0971],
              [119.2073, 35.0484],
              [119.2019, 34.785],
              [119.3467, 34.7808],
              [119.641, 34.5505],
              [119.94, 34.4579],
              [120.2571, 34.3118],
              [120.4071, 33.8598],
              [120.6278, 33.478],
              [120.7302, 33.2049],
              [120.8958, 33.0136],
              [120.8614, 32.7197],
              [120.9112, 32.6301],
              [121.3541, 32.4148],
              [121.4434, 32.1143],
              [121.6554, 32.0629],
              [121.8219, 31.9525],
              [121.9221, 31.7544],
              [121.6756, 31.7236],
              [121.2864, 31.8906],
              [121.1151, 31.7963],
              [121.1444, 31.6847],
              [121.3203, 31.5048],
              [121.7252, 31.2816],
              [121.9774, 30.9149],
              [121.9074, 30.8571],
              [121.6311, 30.8366],
              [121.4106, 30.7674],
              [120.9778, 30.5433],
              [120.9395, 30.4219],
              [120.7344, 30.2322],
              [120.8928, 30.1698],
              [121.1497, 30.3258],
              [121.3774, 30.3251],
              [121.6778, 30.0006],
              [121.9149, 29.9187],
              [121.983, 29.8232],
              [121.7041, 29.572],
              [121.4583, 29.5252],
              [121.4651, 29.4209],
              [121.9158, 29.6409],
              [121.9663, 29.6025],
              [121.9059, 29.2846],
              [121.8406, 29.1611],
              [121.6148, 29.206],
              [121.6817, 29.0268],
              [121.6699, 28.8253],
              [121.5101, 28.6571],
              [121.6563, 28.3393],
              [121.2368, 28.3061],
              [120.8353, 27.956],
              [120.6665, 27.6254],
              [120.6768, 27.4946],
              [120.5459, 27.4033],
              [120.5178, 27.2016],
              [120.3579, 27.0984],
              [120.2374, 26.9272],
              [120.1257, 26.9273],
              [120.0797, 26.6461],
              [119.5846, 26.7946],
              [119.6751, 26.6188],
              [119.6736, 26.2805],
              [119.5664, 26.0502],
              [119.6165, 25.8926],
              [119.5584, 25.5638],
              [119.1096, 25.4068],
              [119.2887, 25.3254],
              [119.2888, 25.1891],
              [118.9873, 25.2222],
              [118.8779, 25.0407],
              [118.9973, 24.9534],
              [118.7161, 24.8436],
              [118.7468, 24.7207],
              [118.5711, 24.569],
              [118.131, 24.6201],
              [118.0448, 24.576],
              [117.9893, 24.3948],
              [118.1265, 24.262],
              [117.9893, 24.1931],
              [117.8936, 24.028],
              [117.5824, 23.8893],
              [117.4635, 23.7883],
              [117.2923, 23.7622],
              [117.2636, 23.6554],
              [117.0784, 23.5646],
              [116.9176, 23.6379],
              [116.8666, 23.4502],
              [116.7313, 23.2577],
              [116.5336, 23.1091],
              [116.4947, 22.9394],
              [116.2985, 22.9632],
              [116.171, 22.8645],
              [115.8099, 22.8083],
              [115.584, 22.8803],
              [115.5239, 22.7418],
              [115.3735, 22.6942],
              [115.2456, 22.8354],
              [115.0655, 22.796],
              [114.8658, 22.6017],
              [114.7438, 22.6094],
              [114.7167, 22.7908],
              [114.2298, 22.5558],
              [114.0824, 22.5294],
              [113.8903, 22.4527],
              [113.7444, 22.7612],
              [113.6626, 22.7981],
              [113.6121, 22.9947],
              [113.3679, 22.8807],
              [113.5992, 22.5752],
              [113.5464, 22.4376],
              [113.5877, 22.2379],
              [113.4837, 22.1551],
              [113.3027, 22.1833],
              [113.2306, 22.0432],
              [113.0286, 21.9611],
              [112.826, 21.9661],
              [112.608, 21.7751],
              [112.4432, 21.8284],
              [112.2449, 21.7065],
              [112.1727, 21.8144],
              [111.992, 21.7638],
              [111.6904, 21.6038],
              [111.6305, 21.5299],
              [111.3013, 21.4993],
              [111.2063, 21.532],
              [110.8694, 21.3847],
              [110.7905, 21.4038],
              [110.6365, 21.2302],
              [110.4815, 21.2172],
              [110.1567, 20.986],
              [110.1567, 20.8489],
              [110.3862, 20.826],
              [110.3375, 20.7258],
              [110.5342, 20.4783],
              [110.4, 20.3008],
              [110.1275, 20.248],
              [110.0155, 20.4184],
              [109.8221, 20.5165],
              [109.7459, 20.6298],
              [109.7732, 20.8215],
              [109.6612, 20.9276],
              [109.6702, 21.1232],
              [109.7732, 21.2154],
              [109.918, 21.4894],
              [109.5308, 21.4916],
              [109.2146, 21.4174],
              [109.1358, 21.603],
              [108.7516, 21.6501],
              [108.5714, 21.8128],
              [108.4481, 21.6581],
              [108.1353, 21.5819],
              [107.9912, 21.4857],
              [107.7448, 21.6575],
              [107.6132, 21.6056],
              [107.3482, 21.5994],
              [107.1724, 21.7151],
              [107.0211, 21.8923],
              [106.7224, 22.007],
              [106.6629, 22.2233],
              [106.5269, 22.4278],
              [106.5688, 22.5746],
              [106.7355, 22.6954],
              [106.6675, 22.8675],
              [106.4878, 22.9254],
              [106.3157, 22.8538],
              [106.2024, 22.947],
              [105.9992, 22.9754],
              [105.8539, 22.9046],
              [105.3323, 23.318],
              [105.0611, 23.2325],
              [104.7979, 23.0856],
              [104.827, 22.9543],
              [104.7282, 22.8391],
              [104.5535, 22.836],
              [104.3402, 22.6864],
              [104.2133, 22.825],
              [104.0238, 22.7192],
              [103.9854, 22.5272],
              [103.8668, 22.5754],
              [103.6469, 22.799],
              [103.4731, 22.5916],
              [103.3096, 22.7879],
              [103.142, 22.5378],
              [102.9891, 22.4376],
              [102.8452, 22.5852],
              [102.4427, 22.7652],
              [102.218, 22.4107],
              [102.1187, 22.3975],
              [102.0146, 22.4461],
              [101.8178, 22.4063],
              [101.6545, 22.4461],
              [101.5182, 22.2282],
              [101.6068, 21.9676],
              [101.7515, 21.8064],
              [101.7225, 21.3042],
              [101.756, 21.1433],
              [101.4951, 21.2428],
              [101.2753, 21.1741],
              [101.209, 21.2455],
              [101.159, 21.5527],
              [101.0687, 21.762],
              [100.797, 21.6261],
              [100.6217, 21.4691],
              [100.4567, 21.455],
              [100.3265, 21.5243],
              [100.1871, 21.4279],
              [100.09, 21.5289],
              [100.0823, 21.6845],
              [99.9503, 21.7212],
              [99.9424, 22.0455],
              [99.4386, 22.1242],
              [99.1664, 22.1321],
              [99.1664, 22.2046],
              [99.3578, 22.4955],
              [99.3079, 22.7196],
              [99.5346, 22.9493],
              [99.351, 23.1193],
              [99.2166, 23.0574],
              [99.0264, 23.1604],
              [98.9107, 23.2976],
              [98.8574, 23.6043],
              [98.7779, 23.7693],
              [98.6637, 23.7969],
              [98.6633, 23.9695],
              [98.5033, 24.1213],
              [98.1815, 24.1187],
              [97.89, 24.0226],
              [97.6371, 23.8636],
              [97.5165, 23.9428],
              [97.7077, 24.1253],
              [97.7184, 24.2777],
              [97.6399, 24.4393],
              [97.5196, 24.4308],
              [97.5361, 24.745],
              [97.6639, 24.8298],
              [97.7375, 25.0905],
              [98.1084, 25.3889],
              [98.1502, 25.6131],
              [98.3326, 25.567],
              [98.4613, 25.7947],
              [98.6924, 25.879],
              [98.5455, 26.1056],
              [98.7055, 26.1749],
              [98.7492, 26.7714],
              [98.6726, 27.1756],
              [98.7042, 27.3042],
              [98.6793, 27.5773],
              [98.4, 27.6757],
              [98.2947, 27.5366],
              [98.1071, 27.9731],
              [98.1187, 28.1408],
              [97.8975, 28.3553],
              [97.74, 28.3826],
              [97.6996, 28.488],
              [97.5277, 28.5295],
              [97.4367, 28.2864],
              [97.3235, 28.2175],
              [97.1155, 28.3666],
              [96.9658, 28.3305],
              [96.598, 28.7099],
              [96.4576, 28.9946],
              [96.142, 29.3685],
              [95.7448, 29.3404],
              [95.5113, 29.1318],
              [95.1915, 29.0968],
              [94.7766, 29.1667],
              [94.6304, 29.3195],
              [94.2708, 29.0979],
              [94.2502, 28.9331],
              [94.0268, 28.8642],
              [93.6255, 28.6724],
              [93.4462, 28.6719],
              [93.1554, 28.4949],
              [93.0932, 28.3666],
              [92.8503, 28.1919],
              [92.6548, 28.1058],
              [92.6285, 27.9158],
              [92.3039, 27.7862],
              [92.0697, 27.794],
              [91.9751, 27.7266],
              [91.6329, 27.7594],
              [91.6217, 27.9507],
              [91.2697, 28.0729],
              [91.0926, 27.9717],
              [90.6839, 28.0871],
              [90.5877, 28.2333],
              [90.2618, 28.3354],
              [89.8629, 28.2958],
              [89.7558, 28.1844],
              [89.5615, 28.1346],
              [89.336, 27.8691],
              [89.051, 27.6083],
              [88.8923, 27.3155],
              [88.7736, 27.4085],
              [88.741, 27.5457],
              [88.8539, 27.8437],
              [88.8177, 27.9947],
              [88.6105, 28.1058],
              [88.3788, 27.9826],
              [88.1267, 27.9504],
              [88.1182, 27.8609],
              [87.8259, 27.9066],
              [87.7564, 27.8204],
              [87.3868, 27.8044],
              [87.1558, 27.8258],
              [87.0305, 27.9381],
              [86.8931, 27.9542],
              [86.662, 28.1068],
              [86.5442, 28.093],
              [86.4388, 27.9109],
              [86.203, 28.0026],
              [85.9803, 27.8852],
              [85.8483, 28.1589],
              [85.6751, 28.3064],
              [85.2596, 28.2669],
              [85.0802, 28.3188],
              [85.168, 28.5832],
              [85.0549, 28.6387],
              [84.9187, 28.5357],
              [84.6778, 28.6044],
              [84.2351, 28.9],
              [84.2314, 29.026],
              [84.0991, 29.247],
              [83.9456, 29.2918],
              [83.5171, 29.1917],
              [83.328, 29.485],
              [83.1621, 29.6252],
              [82.8366, 29.6598],
              [82.5418, 29.9232],
              [82.1445, 30.0694],
              [82.0888, 30.3301],
              [81.779, 30.358],
              [81.5916, 30.4143],
              [81.1945, 30.0045],
              [81.0978, 30.0169],
              [80.996, 30.197],
              [80.7231, 30.392],
              [80.2528, 30.565],
              [80.0625, 30.7848],
              [79.8335, 30.9615],
              [79.5296, 30.9536],
              [79.2957, 31.1241],
              [79.1984, 31.3249],
              [79.0633, 31.4336],
              [78.9762, 31.32],
              [78.7385, 31.335],
              [78.6995, 31.51],
              [78.8189, 31.6074],
              [78.6709, 31.7705],
              [78.7446, 31.9642],
              [78.458, 32.2297],
              [78.4475, 32.5663],
              [78.6302, 32.578],
              [78.9434, 32.3464],
              [79.1129, 32.4724],
              [79.276, 32.4902],
              [79.4764, 32.6454],
              [79.5, 32.7468],
              [79.3342, 32.9568],
              [79.3819, 33.1273],
              [78.9733, 33.3096],
              [78.7814, 33.5528],
              [78.7941, 33.7439],
              [78.7301, 34.0793],
              [78.9032, 34.1581],
              [78.9854, 34.2864],
              [78.6073, 34.5465],
              [78.3794, 34.5787],
              [78.2732, 34.6589],
              [78.13, 35.0554],
              [78.0012, 35.2689],
              [78.0556, 35.4529],
              [77.8003, 35.4954],
              [77.4128, 35.4694],
              [76.7774, 35.6461],
              [76.5708, 35.7513],
              [76.5653, 35.8655],
              [76.166, 35.8062],
              [76.0614, 35.9916],
              [75.8943, 36.0974],
              [76.0144, 36.2304],
              [75.9766, 36.4626],
              [75.7905, 36.6878],
              [75.5705, 36.7667],
              [75.4105, 36.7473],
              [75.3513, 36.9158],
              [75.1001, 36.9941],
              [74.9105, 36.9626],
              [74.6624, 37.0545],
              [74.5424, 37.0217],
              [74.3661, 37.1478],
              [74.4873, 37.2259],
              [74.8923, 37.2311],
              [75.1641, 37.4006],
              [74.8919, 37.5992],
              [74.9652, 37.7675],
              [74.8928, 37.994],
              [74.7907, 38.0814],
              [74.7763, 38.5107],
              [74.3342, 38.667],
              [74.1404, 38.6692],
              [73.9329, 38.5297],
              [73.7974, 38.6028],
              [73.6837, 38.8549],
              [73.816, 38.9938],
              [73.6023, 39.2358],
              [73.6326, 39.4483],
              [73.8207, 39.4682],
              [73.9268, 39.5929],
              [73.823, 39.8057],
              [74.004, 40.0608],
              [74.3699, 40.1058],
              [74.7058, 40.3313],
              [74.8354, 40.5116],
              [75.1939, 40.4413],
              [75.5599, 40.6329],
              [75.6669, 40.4502],
              [75.6818, 40.2917],
              [75.9215, 40.3091],
              [76.2442, 40.4412],
              [76.4491, 40.4155],
              [76.6242, 40.6275],
              [76.6308, 40.7289],
              [76.861, 41.0132],
              [77.333, 41.0206],
              [77.4749, 40.982],
              [77.8667, 41.0641],
              [78.075, 41.0395],
              [78.3597, 41.2875],
              [78.3599, 41.3775],
              [78.6721, 41.5384],
              [79.3041, 41.7876],
              [79.7475, 41.8797],
              [79.8429, 42.0018],
              [80.1819, 42.021],
              [80.2103, 42.1895],
              [80.2684, 42.238],
              [80.14, 42.623],
              [80.2275, 42.791],
              [80.4762, 42.8639],
              [80.3681, 43.0285],
              [80.5759, 43.1194],
              [80.7734, 43.1129],
              [80.7127, 43.4589],
              [80.3451, 44.1031],
              [80.3329, 44.4174],
              [80.3763, 44.6713],
              [80.2183, 44.8181],
              [79.9917, 44.7936],
              [79.8582, 44.9037],
              [80.0617, 45.019],
              [80.365, 45.0432],
              [80.6971, 45.1437],
              [80.8539, 45.1265],
              [81.7393, 45.3381],
              [81.8073, 45.1887],
              [81.9501, 45.1445],
              [82.2946, 45.2282],
              [82.4758, 45.1157],
              [82.5852, 45.1768],
              [82.6157, 45.4354],
              [82.2915, 45.5332],
              [82.4889, 45.9017],
              [82.5683, 46.1965],
              [82.6744, 46.3311],
              [82.839, 46.7324],
              [82.9855, 46.9425],
              [83.0135, 47.1946],
              [83.1504, 47.2115],
              [83.5245, 47.0671],
              [83.9053, 46.974],
              [84.6635, 46.9839],
              [84.7396, 46.8148],
              [84.916, 46.8506],
              [85.2051, 47.0337],
              [85.4986, 47.0518],
              [85.6597, 47.2065],
              [85.6704, 47.3843],
              [85.5154, 47.9203],
              [85.5864, 48.1412],
              [85.7837, 48.4076],
              [86.192, 48.4182],
              [86.5651, 48.5273],
              [86.7445, 48.7035],
              [86.7275, 48.9903],
              [86.8873, 49.1068],
              [87.3238, 49.0853],
              [87.8163, 49.1658],
              [87.7569, 48.9204],
              [87.8059, 48.8006],
              [88.0338, 48.7291],
              [87.9428, 48.5995],
              [88.5651, 48.343],
              [88.5808, 48.212],
              [89.0456, 47.993],
              [89.5418, 48.031],
              [89.7513, 47.8243],
              [90.0446, 47.8798],
              [90.0829, 47.7561],
              [90.3274, 47.623],
              [90.4412, 47.493],
              [90.4687, 47.3089],
              [90.7121, 47.0147],
              [90.8282, 46.9823],
              [91.0048, 46.7404],
              [91.0475, 46.5664],
              [90.8962, 46.302],
              [91.0024, 46.0218],
              [90.7108, 45.7522],
              [90.6511, 45.4931],
              [90.9055, 45.186],
              [91.135, 45.1979],
              [91.663, 45.0595],
              [92.0094, 45.0762],
              [92.4751, 44.9975],
              [92.7527, 45.038],
              [93.5253, 44.9513],
              [93.714, 44.8746],
              [93.9751, 44.66],
              [94.195, 44.6543],
              [94.3385, 44.5121],
              [94.6982, 44.3435],
              [95.0368, 44.255],
              [95.3794, 44.2871],
              [95.3275, 44.0067],
              [95.5103, 43.9791],
              [95.8328, 43.4089],
              [95.9085, 43.2145],
              [96.2943, 42.9333],
              [96.3506, 42.7409],
              [97.1933, 42.7873],
              [98.6622, 42.6436],
              [99.4745, 42.5642],
              [100.017, 42.6765],
              [101.2187, 42.5298],
              [101.6376, 42.5154],
              [102.0342, 42.1846],
              [103.0735, 42.0045],
              [103.721, 41.7556],
              [104.5008, 41.8706],
              [104.498, 41.6658],
              [104.8923, 41.6448],
              [105.0148, 41.5961],
              [105.2745, 41.7555],
              [105.8686, 41.9935],
              [106.7678, 42.2866],
              [108.1774, 42.4543],
              [108.7969, 42.3984],
              [108.9945, 42.4495],
              [109.4851, 42.4493],
              [109.6673, 42.549],
              [110.0749, 42.6435],
              [110.4067, 42.7686],
              [110.9337, 43.2878],
              [111.5972, 43.5234],
              [111.7538, 43.6632],
              [111.9334, 43.6966],
              [111.8388, 43.9388],
              [111.5194, 44.1886],
              [111.4064, 44.4165],
              [111.7385, 44.9663],
              [112.0115, 45.0875],
              [112.4413, 45.0524],
              [112.6142, 44.9088],
              [113.1049, 44.7944],
              [113.6351, 44.7463],
              [113.8893, 44.9083],
              [114.0553, 44.9406],
              [114.4189, 45.2015],
              [114.5337, 45.3855],
              [114.7373, 45.425],
              [114.9389, 45.3738],
              [115.6378, 45.4444],
              [116.012, 45.6786],
              [116.1699, 45.7091],
              [116.2132, 45.9082],
              [116.3572, 46.1056],
              [116.6036, 46.3093],
              [116.8046, 46.3829],
              [117.3017, 46.3501],
              [117.3938, 46.5714],
              [117.6962, 46.5123],
              [118.2383, 46.7154],
              [118.7201, 46.6767],
              [118.8162, 46.7582],
              [119.3169, 46.6117],
              [119.6801, 46.5916],
              [119.9042, 46.7089],
              [119.9029, 46.8424],
              [119.7, 47.1595],
              [119.1576, 47.5177],
              [119.0835, 47.6616],
              [118.7674, 47.7562],
              [118.5423, 47.9662],
              [118.1825, 48.0282],
              [117.7417, 47.9776],
              [117.3171, 47.6541],
              [117.0697, 47.8102],
              [116.8533, 47.8721],
              [116.4997, 47.8364],
              [116.2439, 47.8629],
              [115.9729, 47.7091],
              [115.8527, 47.7056],
              [115.5619, 47.9332],
              [115.5145, 48.1221],
              [115.8093, 48.274],
              [115.8004, 48.5304],
              [116.0282, 48.7675],
              [116.0378, 48.8701],
              [116.6843, 49.8233],
              [117.0576, 49.6729],
              [117.4729, 49.6143],
              [117.7588, 49.5127],
              [118.1686, 49.6715],
              [118.6444, 49.9546],
              [119.0917, 49.9866],
              [119.3162, 50.0927],
              [119.3529, 50.3455],
              [119.2437, 50.4455],
              [119.2936, 50.5992],
              [119.4967, 50.7371],
              [119.518, 50.9048],
              [119.7483, 51.0834],
              [120.1082, 51.6652],
              [120.4931, 51.8757],
              [120.6476, 51.911],
              [120.7792, 52.1176],
              [120.6262, 52.3468],
              [120.724, 52.5422],
              [120.4299, 52.6361],
              [120.0564, 52.6005],
              [120.033, 52.7607],
              [120.2802, 52.8659],
              [120.8743, 53.2802],
              [121.2035, 53.2767],
              [121.889, 53.4306],
              [122.3378, 53.4913],
              [122.8597, 53.4739],
              [123.2526, 53.5678],
              [123.6396, 53.5513],
              [124.2327, 53.3817],
              [124.4082, 53.2357],
              [124.7202, 53.1488],
              [125.1274, 53.2065],
              [125.5213, 53.0424],
              [125.6214, 53.0621],
              [126.0351, 52.7271],
              [125.9732, 52.6316],
              [126.2123, 52.533],
              [126.5312, 52.1527],
              [126.4461, 51.9825],
              [126.9546, 51.3296],
              [126.9318, 51.0638],
              [127.2874, 50.751],
              [127.3648, 50.5547],
              [127.3307, 50.3282],
              [127.5784, 50.2206],
              [127.4867, 50.0256],
              [127.5081, 49.8223],
              [127.8309, 49.5939],
              [128.0925, 49.5416],
              [128.6662, 49.6095],
              [129.0506, 49.3767],
              [129.7112, 49.2742],
              [129.9134, 49.0395],
              [130.2493, 48.8645],
              [130.6743, 48.8708],
              [130.5333, 48.6358],
              [130.7159, 48.4991],
              [130.8194, 48.2819],
              [130.6634, 48.1164],
              [130.907, 47.8843],
              [131.0234, 47.6823],
              [131.4184, 47.7426],
              [131.5774, 47.6686],
              [132.5247, 47.7075],
              [132.6588, 47.8698],
              [133.092, 48.1068],
              [133.4637, 48.0895],
              [133.7213, 48.2448],
              [134.3863, 48.3813],
              [134.7009, 48.2615],
              [134.5672, 48.0404],
              [134.7726, 47.7107],
              [134.4846, 47.4302],
              [134.2928, 47.4097],
              [134.1541, 47.2579],
              [133.988, 46.6252],
              [133.8493, 46.4803],
              [133.9025, 46.259],
              [133.6874, 46.1418],
              [133.6581, 45.9345],
              [133.5144, 45.8793],
              [133.4135, 45.5733],
              [133.2021, 45.5113],
              [133.0975, 45.2435],
              [133.1154, 45.123],
              [132.9534, 45.0244],
              [131.9696, 45.2338],
              [131.818, 45.3328],
              [131.6091, 45.0897],
              [131.4195, 44.959],
              [130.9334, 44.8417],
              [131.0658, 44.682],
              [131.2645, 44.0542],
              [131.1703, 43.7811],
              [131.2809, 43.3802],
              [130.9924, 42.8487],
              [130.7695, 42.8658],
              [130.4072, 42.7354],
              [130.5308, 42.5305],
            ],
          ],
        ],
      },
      properties: {
        NAME: "China",
        NAME_LONG: "China",
        ADM0_A3: "CHN",
        ISO_A2: "CN",
        ISO_A3: "CHN",
        WB_A2: "CN",
        WB_A3: "CHN",
      },
      id: 42,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.5226, 10.4255],
            [-5.4025, 10.3006],
            [-5.1118, 10.2787],
            [-4.9661, 9.901],
            [-4.8385, 9.8695],
            [-4.7055, 9.7021],
            [-4.604, 9.7272],
            [-4.3225, 9.6089],
            [-4.2703, 9.7439],
            [-4.0599, 9.7963],
            [-3.8897, 9.8945],
            [-3.6617, 9.9489],
            [-3.3892, 9.9132],
            [-3.1369, 9.8295],
            [-2.99, 9.7084],
            [-2.9102, 9.5427],
            [-2.7637, 9.3919],
            [-2.6892, 9.4887],
            [-2.6598, 9.2729],
            [-2.7748, 9.055],
            [-2.6194, 8.9236],
            [-2.5063, 8.2093],
            [-2.6194, 8.1231],
            [-2.84, 7.8202],
            [-2.9415, 7.5772],
            [-3.0459, 7.0712],
            [-3.2249, 6.8494],
            [-3.2625, 6.6171],
            [-3.1836, 6.2639],
            [-3.1181, 6.1635],
            [-2.9695, 5.6214],
            [-2.7818, 5.5789],
            [-2.7327, 5.3557],
            [-2.8437, 5.1491],
            [-2.9467, 5.1771],
            [-3.3122, 5.1199],
            [-3.8409, 5.2012],
            [-4.1255, 5.3074],
            [-4.7847, 5.1439],
            [-5.5248, 5.0998],
            [-5.8513, 5.03],
            [-6.5839, 4.7604],
            [-6.9142, 4.6557],
            [-7.4568, 4.3441],
            [-7.5407, 4.3528],
            [-7.6055, 4.8974],
            [-7.5782, 5.0547],
            [-7.4889, 5.1407],
            [-7.45, 5.4228],
            [-7.3936, 5.524],
            [-7.4465, 5.8459],
            [-7.5591, 5.8399],
            [-7.7996, 5.9745],
            [-7.8441, 6.184],
            [-7.9349, 6.2714],
            [-8.1706, 6.274],
            [-8.3888, 6.3551],
            [-8.5661, 6.5509],
            [-8.3518, 6.7554],
            [-8.2841, 7.0179],
            [-8.4436, 7.5371],
            [-8.4854, 7.558],
            [-8.229, 7.5443],
            [-8.1274, 7.713],
            [-8.1289, 7.8826],
            [-8.0217, 8.1177],
            [-8.266, 8.246],
            [-8.2003, 8.4918],
            [-7.9705, 8.497],
            [-7.771, 8.3865],
            [-7.6896, 8.4387],
            [-7.6916, 8.6061],
            [-7.8037, 8.7491],
            [-7.9589, 8.782],
            [-7.9296, 9.1839],
            [-7.8654, 9.4099],
            [-8.0872, 9.4154],
            [-8.1616, 9.5756],
            [-8.1235, 9.8449],
            [-8.1738, 9.9419],
            [-7.9897, 10.162],
            [-7.829, 10.2117],
            [-7.7086, 10.4025],
            [-7.4662, 10.4295],
            [-7.347, 10.2479],
            [-6.9594, 10.1855],
            [-6.9731, 10.3323],
            [-6.7003, 10.3417],
            [-6.6679, 10.6509],
            [-6.5214, 10.5642],
            [-6.434, 10.6679],
            [-6.28, 10.7233],
            [-6.2078, 10.6375],
            [-6.1809, 10.2158],
            [-6.0159, 10.1899],
            [-5.8063, 10.4135],
            [-5.5226, 10.4255],
          ],
        ],
      },
      properties: {
        NAME: "Côte d'Ivoire",
        NAME_LONG: "Côte d'Ivoire",
        ADM0_A3: "CIV",
        ISO_A2: "CI",
        ISO_A3: "CIV",
        WB_A2: "CI",
        WB_A3: "CIV",
      },
      id: 43,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.481, 7.5233],
            [15.4328, 7.3897],
            [15.2239, 7.2475],
            [15.0422, 6.7908],
            [14.9325, 6.7103],
            [14.719, 6.2579],
            [14.535, 6.1901],
            [14.3977, 6.0192],
            [14.5848, 5.9234],
            [14.6311, 5.738],
            [14.5973, 5.4115],
            [14.5239, 5.2797],
            [14.6632, 5.1418],
            [14.7173, 4.622],
            [15.0006, 4.4032],
            [15.1026, 4.1124],
            [15.0849, 3.8858],
            [15.1713, 3.759],
            [15.7856, 3.108],
            [15.914, 3.098],
            [16.0924, 2.8633],
            [16.0903, 2.5105],
            [16.1967, 2.2365],
            [16.1044, 2.1936],
            [16.0649, 1.979],
            [16.159, 1.7348],
            [15.8899, 1.7933],
            [15.7646, 1.9087],
            [15.4829, 1.9758],
            [15.3492, 1.9233],
            [15.2337, 2.0313],
            [15.0916, 1.9844],
            [14.8715, 2.1017],
            [14.7057, 2.1055],
            [14.5621, 2.2088],
            [14.4187, 2.1353],
            [14.267, 2.1524],
            [13.2946, 2.1611],
            [13.2429, 2.2715],
            [12.7499, 2.2367],
            [12.334, 2.3096],
            [12.2026, 2.2796],
            [11.7527, 2.2819],
            [11.6712, 2.321],
            [11.3516, 2.3006],
            [11.3221, 2.1658],
            [9.991, 2.1656],
            [9.8109, 2.2649],
            [9.7996, 2.3417],
            [9.8778, 2.8814],
            [9.958, 3.0822],
            [9.9031, 3.2729],
            [9.6421, 3.539],
            [9.5892, 3.8096],
            [9.6648, 3.9404],
            [9.5794, 4.013],
            [9.4121, 3.9022],
            [8.9712, 4.1008],
            [8.8719, 4.6025],
            [8.7017, 4.5013],
            [8.5051, 4.5509],
            [8.5942, 4.8153],
            [8.7989, 5.1596],
            [8.8937, 5.6218],
            [8.8193, 5.7035],
            [8.8559, 5.8475],
            [9.2733, 6.2028],
            [9.3274, 6.2985],
            [9.5881, 6.5027],
            [9.693, 6.5313],
            [9.7698, 6.7737],
            [9.8746, 6.7877],
            [10.1195, 6.9944],
            [10.2384, 6.871],
            [10.496, 6.8747],
            [10.6025, 7.0581],
            [10.8319, 6.9452],
            [10.8775, 6.8161],
            [11.0559, 6.7004],
            [11.0971, 6.4491],
            [11.3195, 6.4373],
            [11.5098, 6.6123],
            [11.6232, 6.9323],
            [11.8813, 7.102],
            [11.7802, 7.2411],
            [11.8447, 7.3964],
            [12.025, 7.5966],
            [12.055, 7.7839],
            [12.1978, 7.9752],
            [12.25, 8.4188],
            [12.4039, 8.4965],
            [12.377, 8.5951],
            [12.6604, 8.6593],
            [12.8057, 8.8315],
            [12.8221, 9.0968],
            [12.8884, 9.2267],
            [12.862, 9.3829],
            [12.9824, 9.4746],
            [13.1955, 9.5422],
            [13.2629, 9.8017],
            [13.2127, 9.8701],
            [13.248, 10.0794],
            [13.4349, 10.1534],
            [13.5663, 10.679],
            [13.7444, 10.9305],
            [13.7548, 11.0167],
            [13.9823, 11.2763],
            [14.1655, 11.2383],
            [14.5938, 11.4964],
            [14.6214, 11.6298],
            [14.5465, 11.7186],
            [14.6699, 12.1674],
            [14.5144, 12.3235],
            [14.1798, 12.3856],
            [14.1774, 12.5456],
            [14.0649, 13.078],
            [14.4182, 13.0811],
            [14.4906, 12.8863],
            [14.604, 12.7608],
            [14.8308, 12.6182],
            [14.9083, 12.3269],
            [14.9031, 12.1459],
            [15.0406, 12.0556],
            [15.0683, 11.7001],
            [15.1356, 11.5308],
            [15.0606, 11.4161],
            [15.0212, 11.1825],
            [15.0659, 10.7931],
            [15.3014, 10.3117],
            [15.5361, 10.0805],
            [15.383, 9.9302],
            [15.2149, 9.9841],
            [14.7325, 9.9238],
            [14.4405, 9.9953],
            [14.1817, 9.9782],
            [13.9476, 9.6378],
            [14.3213, 9.2432],
            [14.3495, 9.1684],
            [14.7939, 8.8136],
            [15.0313, 8.6587],
            [15.1837, 8.4791],
            [15.3453, 8.136],
            [15.4407, 7.8394],
            [15.5629, 7.7924],
            [15.481, 7.5233],
          ],
        ],
      },
      properties: {
        NAME: "Cameroon",
        NAME_LONG: "Cameroon",
        ADM0_A3: "CMR",
        ISO_A2: "CM",
        ISO_A3: "CMR",
        WB_A2: "CM",
        WB_A3: "CMR",
      },
      id: 45,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [34.0123, 35.0638],
              [34.022, 35.057],
              [33.8981, 35.0613],
              [33.9065, 35.0691],
              [34.0123, 35.0638],
            ],
          ],
          [
            [
              [32.5848, 35.1725],
              [32.6019, 35.1786],
              [32.6407, 35.1871],
              [32.6598, 35.1871],
              [32.5848, 35.1725],
            ],
          ],
          [
            [
              [33.6794, 35.0339],
              [33.7029, 34.9879],
              [33.497, 35.0261],
              [33.4463, 35.0012],
              [33.4573, 35.1011],
              [33.4159, 35.1603],
              [33.1739, 35.1627],
              [32.9219, 35.0723],
              [32.8159, 35.0628],
              [32.8065, 35.0948],
              [32.7127, 35.1338],
              [32.6915, 35.1837],
              [32.7114, 35.1816],
              [32.8549, 35.0792],
              [33.0958, 35.1697],
              [33.1903, 35.1899],
              [33.4003, 35.1977],
              [33.4768, 35.0948],
              [33.4768, 35.0574],
              [33.6794, 35.0339],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Cyprus U.N. Buffer Zone",
        NAME_LONG: "Cyprus U.N. Buffer Zone",
        ADM0_A3: "CNM",
        ISO_A2: "-99",
        ISO_A3: "-99",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 46,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.4413, 5.0707],
            [27.5527, 4.9005],
            [27.641, 4.8909],
            [27.7669, 4.7354],
            [27.7724, 4.5958],
            [28.0089, 4.4991],
            [28.1776, 4.3472],
            [28.4041, 4.2778],
            [28.7554, 4.554],
            [28.8153, 4.4783],
            [28.9862, 4.4959],
            [29.1735, 4.348],
            [29.3039, 4.3871],
            [29.4941, 4.6683],
            [29.7777, 4.5239],
            [29.7762, 4.4056],
            [30.1227, 4.1025],
            [30.2059, 3.9504],
            [30.5389, 3.8415],
            [30.5726, 3.601],
            [30.6934, 3.6319],
            [30.8395, 3.4902],
            [30.91, 3.3934],
            [30.8042, 3.246],
            [30.7451, 3.0363],
            [30.8548, 2.8932],
            [30.7394, 2.6033],
            [30.7249, 2.4408],
            [30.8718, 2.3321],
            [31.1776, 2.3029],
            [31.2675, 2.1733],
            [31.2428, 2.0512],
            [31.0258, 1.7782],
            [30.6817, 1.5003],
            [30.4783, 1.2386],
            [30.2389, 1.136],
            [30.1451, 0.9032],
            [29.9283, 0.785],
            [29.9405, 0.4983],
            [29.8326, 0.337],
            [29.8006, 0.1724],
            [29.7118, 0.0996],
            [29.6294, -0.4424],
            [29.6108, -0.864],
            [29.556, -0.9195],
            [29.5779, -1.3884],
            [29.3585, -1.5099],
            [29.1493, -1.7993],
            [29.1506, -2.1504],
            [28.8588, -2.4182],
            [28.8913, -2.6527],
            [29.0154, -2.7207],
            [29.0898, -2.9261],
            [29.2346, -3.0466],
            [29.2067, -3.3345],
            [29.215, -3.8999],
            [29.3395, -4.0932],
            [29.4042, -4.4498],
            [29.321, -4.8363],
            [29.3778, -5.0875],
            [29.4556, -5.23],
            [29.5935, -5.6203],
            [29.603, -5.7817],
            [29.4761, -6.0021],
            [29.5255, -6.2731],
            [29.7386, -6.6524],
            [30.0909, -6.89],
            [30.2347, -7.0571],
            [30.3696, -7.3103],
            [30.4416, -7.5963],
            [30.655, -7.9274],
            [30.7521, -8.1941],
            [29.8581, -8.3298],
            [28.9153, -8.4729],
            [28.9305, -8.6799],
            [28.7654, -8.934],
            [28.5991, -9.0964],
            [28.3723, -9.2351],
            [28.516, -9.3783],
            [28.6685, -9.8216],
            [28.6239, -9.951],
            [28.6349, -10.5195],
            [28.6996, -10.6432],
            [28.5635, -10.8119],
            [28.4768, -11.078],
            [28.4396, -11.3482],
            [28.357, -11.4469],
            [28.4972, -11.8574],
            [28.835, -12.0922],
            [29.0304, -12.3762],
            [29.4466, -12.3351],
            [29.4743, -12.2427],
            [29.7993, -12.1541],
            [29.7976, -13.4242],
            [29.6017, -13.3744],
            [29.5744, -13.2254],
            [29.1689, -13.4339],
            [28.994, -13.3959],
            [28.9034, -13.1623],
            [28.6679, -12.8493],
            [28.584, -12.8887],
            [28.4227, -12.5213],
            [28.3397, -12.4505],
            [28.1313, -12.4292],
            [27.9339, -12.3033],
            [27.6382, -12.2936],
            [27.5205, -12.1796],
            [27.4207, -11.922],
            [27.2342, -11.8091],
            [27.1801, -11.5696],
            [27.0103, -11.6098],
            [26.9673, -11.8704],
            [26.8742, -11.9643],
            [26.6435, -11.9855],
            [26.4146, -11.9118],
            [26.298, -11.9412],
            [25.9941, -11.9048],
            [25.8507, -11.7938],
            [25.352, -11.6461],
            [25.279, -11.3963],
            [25.2788, -11.1999],
            [24.6741, -11.3416],
            [24.5647, -11.4447],
            [24.3101, -11.4066],
            [24.3922, -11.2791],
            [24.3818, -11.1206],
            [24.1242, -11.0433],
            [24.1142, -10.9192],
            [23.9675, -10.8723],
            [23.8337, -11.0285],
            [23.4948, -10.9643],
            [23.0143, -11.1025],
            [22.8292, -11.0521],
            [22.7277, -11.0968],
            [22.5376, -11.0372],
            [22.2818, -11.2367],
            [22.1655, -10.8524],
            [22.3224, -10.7422],
            [22.274, -10.503],
            [22.3134, -10.3686],
            [22.2028, -10.1306],
            [22.1599, -9.9315],
            [21.9984, -9.8091],
            [21.8541, -9.6178],
            [21.7933, -9.3935],
            [21.8411, -9.2758],
            [21.8599, -8.8464],
            [21.936, -8.413],
            [21.8512, -8.1435],
            [21.7585, -8.0151],
            [21.7705, -7.8068],
            [21.8541, -7.586],
            [21.8424, -7.3699],
            [21.785, -7.2834],
            [20.5205, -7.2864],
            [20.5245, -7.1431],
            [20.6115, -6.916],
            [20.3311, -6.9168],
            [20.2943, -7.0019],
            [19.5218, -7.0019],
            [19.4764, -7.3159],
            [19.5132, -7.4794],
            [19.4247, -7.5797],
            [19.3338, -7.8673],
            [19.3555, -8.002],
            [18.7738, -8.002],
            [18.7385, -7.9323],
            [18.5076, -7.9975],
            [18.1766, -7.9992],
            [18.0982, -8.1093],
            [17.9014, -8.0481],
            [17.5443, -8.0848],
            [17.5365, -8.0151],
            [17.2889, -7.6995],
            [17.0981, -7.4023],
            [16.9961, -7.298],
            [16.9271, -7.0857],
            [16.927, -6.8886],
            [16.8329, -6.8278],
            [16.7308, -6.5698],
            [16.7268, -6.1892],
            [16.6043, -6.0619],
            [16.5974, -5.9247],
            [16.3157, -5.8546],
            [14.7037, -5.8814],
            [14.5766, -5.9047],
            [14.0212, -5.8725],
            [13.9436, -5.8416],
            [13.6415, -5.8658],
            [13.1838, -5.8565],
            [12.8224, -5.8298],
            [12.7226, -5.9793],
            [12.4495, -6.0519],
            [12.2105, -5.7634],
            [12.509, -5.7263],
            [12.5307, -5.1627],
            [12.4444, -5.0551],
            [12.6789, -4.9243],
            [12.8332, -4.7227],
            [13.0737, -4.6353],
            [13.3588, -4.7948],
            [13.3926, -4.8853],
            [13.6901, -4.6911],
            [13.711, -4.4645],
            [13.9549, -4.4919],
            [14.3686, -4.2784],
            [14.4665, -4.448],
            [14.35, -4.5669],
            [14.4069, -4.676],
            [14.3965, -4.8559],
            [14.6345, -4.9036],
            [14.8312, -4.8151],
            [14.931, -4.6469],
            [15.0979, -4.4999],
            [15.204, -4.339],
            [15.4589, -4.2139],
            [15.5668, -4.038],
            [15.883, -3.9453],
            [16.2077, -3.3619],
            [16.177, -2.8825],
            [16.2256, -2.6352],
            [16.1769, -2.2868],
            [16.2313, -2.129],
            [16.5225, -1.8617],
            [16.8397, -1.2625],
            [17.0287, -1.0945],
            [17.2977, -1.0211],
            [17.7495, -0.5234],
            [17.7164, -0.1958],
            [17.854, 0.2411],
            [17.9399, 0.3613],
            [17.8666, 1.0166],
            [17.9634, 1.1915],
            [18.0714, 1.5515],
            [18.0724, 2.16],
            [18.2462, 2.5189],
            [18.3932, 2.7137],
            [18.5284, 3.0648],
            [18.6131, 3.1282],
            [18.6264, 3.4769],
            [18.593, 3.7098],
            [18.6453, 3.9824],
            [18.6197, 4.1696],
            [18.5371, 4.3061],
            [18.7211, 4.3774],
            [18.8282, 4.56],
            [19.0116, 4.765],
            [19.0833, 4.9093],
            [19.409, 5.1304],
            [19.7196, 5.136],
            [20.3389, 4.7719],
            [20.4523, 4.5289],
            [20.6031, 4.4097],
            [20.8715, 4.4534],
            [21.0842, 4.3882],
            [21.1581, 4.3102],
            [21.2989, 4.3302],
            [21.5381, 4.2447],
            [21.7229, 4.2951],
            [22.0089, 4.2424],
            [22.2073, 4.1503],
            [22.4927, 4.174],
            [22.6895, 4.4917],
            [22.786, 4.7246],
            [22.8984, 4.8236],
            [23.1687, 4.7381],
            [23.3884, 4.5873],
            [23.5666, 4.7244],
            [23.8167, 4.8206],
            [23.9483, 4.8177],
            [24.1629, 4.9078],
            [24.4596, 5.1074],
            [24.6644, 4.9244],
            [24.9581, 4.9914],
            [25.3076, 5.0323],
            [25.3637, 5.3106],
            [25.5813, 5.3749],
            [25.7328, 5.2589],
            [26.0269, 5.19],
            [26.13, 5.2589],
            [26.4627, 5.0596],
            [26.7606, 5.0881],
            [26.8671, 5.0375],
            [26.9616, 5.1512],
            [27.1164, 5.2003],
            [27.4413, 5.0707],
          ],
        ],
      },
      properties: {
        NAME: "Dem. Rep. Congo",
        NAME_LONG: "Democratic Republic of the Congo",
        ADM0_A3: "COD",
        ISO_A2: "CD",
        ISO_A3: "COD",
        WB_A2: "ZR",
        WB_A3: "ZAR",
      },
      id: 47,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.6264, 3.4769],
            [18.6131, 3.1282],
            [18.5284, 3.0648],
            [18.3932, 2.7137],
            [18.2462, 2.5189],
            [18.0724, 2.16],
            [18.0714, 1.5515],
            [17.9634, 1.1915],
            [17.8666, 1.0166],
            [17.9399, 0.3613],
            [17.854, 0.2411],
            [17.7164, -0.1958],
            [17.7495, -0.5234],
            [17.2977, -1.0211],
            [17.0287, -1.0945],
            [16.8397, -1.2625],
            [16.5225, -1.8617],
            [16.2313, -2.129],
            [16.1769, -2.2868],
            [16.2256, -2.6352],
            [16.177, -2.8825],
            [16.2077, -3.3619],
            [15.883, -3.9453],
            [15.5668, -4.038],
            [15.4589, -4.2139],
            [15.204, -4.339],
            [15.0979, -4.4999],
            [14.931, -4.6469],
            [14.8312, -4.8151],
            [14.6345, -4.9036],
            [14.3965, -4.8559],
            [14.4069, -4.676],
            [14.35, -4.5669],
            [14.4665, -4.448],
            [14.3686, -4.2784],
            [13.9549, -4.4919],
            [13.711, -4.4645],
            [13.6901, -4.6911],
            [13.3926, -4.8853],
            [13.3588, -4.7948],
            [13.0737, -4.6353],
            [12.7617, -4.3912],
            [12.6081, -4.565],
            [12.3776, -4.6194],
            [12.3324, -4.7659],
            [12.2352, -4.804],
            [12.0096, -5.0196],
            [11.7816, -4.6804],
            [11.8064, -4.5771],
            [11.4014, -4.2214],
            [11.3582, -4.1119],
            [11.114, -3.9369],
            [11.2125, -3.6973],
            [11.5698, -3.545],
            [11.6492, -3.6733],
            [11.8197, -3.7104],
            [11.8278, -3.5481],
            [11.9444, -3.3032],
            [11.6875, -3.171],
            [11.7637, -2.9826],
            [11.6368, -2.8333],
            [11.5582, -2.3494],
            [11.7561, -2.4151],
            [11.9384, -2.3291],
            [12.0623, -2.415],
            [12.4593, -2.3299],
            [12.4702, -2.1563],
            [12.4231, -1.8976],
            [12.5832, -1.8265],
            [12.8046, -1.9191],
            [13.0107, -2.2878],
            [13.361, -2.4288],
            [13.5934, -2.3268],
            [13.7707, -2.1191],
            [13.8639, -2.3215],
            [13.885, -2.4885],
            [14.0809, -2.4999],
            [14.227, -2.3231],
            [14.1512, -2.259],
            [14.2384, -2.1202],
            [14.2522, -1.9676],
            [14.3707, -1.9213],
            [14.3738, -1.6041],
            [14.4821, -1.3886],
            [14.3918, -1.0233],
            [14.499, -0.6309],
            [14.2927, -0.4364],
            [14.1597, -0.4551],
            [14.1214, -0.3022],
            [13.8312, -0.2054],
            [13.9211, -0.053],
            [13.8712, 0.1964],
            [14.076, 0.5395],
            [14.2318, 0.5426],
            [14.4476, 0.8162],
            [14.4681, 0.9132],
            [14.2747, 1.1556],
            [14.1839, 1.3808],
            [13.7947, 1.4344],
            [13.5434, 1.2835],
            [13.4139, 1.2955],
            [13.2502, 1.2218],
            [13.2395, 1.428],
            [13.1296, 1.5925],
            [13.2069, 1.9936],
            [13.2946, 2.1611],
            [14.267, 2.1524],
            [14.4187, 2.1353],
            [14.5621, 2.2088],
            [14.7057, 2.1055],
            [14.8715, 2.1017],
            [15.0916, 1.9844],
            [15.2337, 2.0313],
            [15.3492, 1.9233],
            [15.4829, 1.9758],
            [15.7646, 1.9087],
            [15.8899, 1.7933],
            [16.159, 1.7348],
            [16.0649, 1.979],
            [16.1044, 2.1936],
            [16.1967, 2.2365],
            [16.4791, 2.8363],
            [16.4908, 3.0678],
            [16.5677, 3.4644],
            [16.6394, 3.5286],
            [16.9686, 3.5335],
            [17.3344, 3.6185],
            [17.4589, 3.7083],
            [17.6273, 3.6263],
            [17.8083, 3.6086],
            [17.8564, 3.5373],
            [18.5134, 3.5922],
            [18.6264, 3.4769],
          ],
        ],
      },
      properties: {
        NAME: "Congo",
        NAME_LONG: "Republic of Congo",
        ADM0_A3: "COG",
        ISO_A2: "CG",
        ISO_A3: "COG",
        WB_A2: "CG",
        WB_A3: "COG",
      },
      id: 48,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.3275, 11.85],
            [-71.4495, 11.7955],
            [-71.9711, 11.6619],
            [-72.2566, 11.1678],
            [-72.4815, 11.1325],
            [-72.7061, 10.8113],
            [-72.9076, 10.4525],
            [-72.9877, 9.9994],
            [-72.9776, 9.8381],
            [-73.3778, 9.1647],
            [-73.1762, 9.1909],
            [-73.0097, 9.2954],
            [-72.9364, 9.0992],
            [-72.7831, 9.0599],
            [-72.6754, 8.6515],
            [-72.3868, 8.3386],
            [-72.3358, 8.1039],
            [-72.4878, 7.9492],
            [-72.4513, 7.4402],
            [-72.2062, 7.3819],
            [-72.081, 7.0666],
            [-71.8326, 6.9864],
            [-71.6543, 7.0534],
            [-71.2835, 7.0188],
            [-71.184, 6.9626],
            [-70.7033, 7.0999],
            [-70.3191, 6.9383],
            [-70.0966, 6.9444],
            [-69.4436, 6.1222],
            [-69.2461, 6.0807],
            [-69.0611, 6.2178],
            [-68.6353, 6.1359],
            [-68.449, 6.195],
            [-68.3042, 6.177],
            [-67.9241, 6.2346],
            [-67.8391, 6.3075],
            [-67.574, 6.2662],
            [-67.4286, 6.0385],
            [-67.6012, 5.8206],
            [-67.647, 5.4834],
            [-67.8437, 5.2972],
            [-67.7931, 5.0633],
            [-67.8537, 4.5758],
            [-67.8049, 4.2685],
            [-67.6318, 3.7619],
            [-67.4714, 3.6801],
            [-67.4039, 3.5045],
            [-67.3046, 3.4257],
            [-67.4522, 3.2437],
            [-67.8386, 2.8861],
            [-67.6266, 2.8134],
            [-67.3254, 2.4746],
            [-67.1976, 2.402],
            [-67.2177, 2.2845],
            [-67.0668, 1.8941],
            [-66.8825, 1.326],
            [-66.8751, 1.2225],
            [-67.0652, 1.1727],
            [-67.0982, 1.2534],
            [-67.0736, 1.5412],
            [-67.1172, 1.7098],
            [-67.3406, 2.0901],
            [-67.4397, 2.1396],
            [-67.5929, 2.0548],
            [-67.7905, 1.8126],
            [-67.9982, 1.75],
            [-68.2008, 2.0078],
            [-68.2608, 1.8582],
            [-68.1633, 1.7213],
            [-69.3524, 1.7202],
            [-69.5803, 1.7702],
            [-69.8488, 1.6689],
            [-69.8522, 1.0594],
            [-69.7626, 1.0911],
            [-69.3709, 1.063],
            [-69.2043, 0.9437],
            [-69.1375, 0.6501],
            [-69.3626, 0.6409],
            [-69.5037, 0.7296],
            [-69.6192, 0.6507],
            [-70.0542, 0.5881],
            [-70.0738, -0.1249],
            [-69.9444, -0.3055],
            [-69.6321, -0.5069],
            [-69.6284, -0.7334],
            [-69.45, -0.9968],
            [-69.3995, -1.1827],
            [-69.5657, -2.106],
            [-69.7712, -3.2342],
            [-69.9472, -4.2011],
            [-69.9649, -4.2365],
            [-70.1884, -4.029],
            [-70.3111, -3.8293],
            [-70.5443, -3.8652],
            [-70.7341, -3.782],
            [-70.0506, -2.7151],
            [-70.2361, -2.6251],
            [-70.3575, -2.4869],
            [-70.457, -2.4927],
            [-70.8742, -2.2296],
            [-70.9927, -2.1974],
            [-71.2199, -2.3415],
            [-71.387, -2.3689],
            [-71.4565, -2.2551],
            [-71.7679, -2.1422],
            [-71.9663, -2.3285],
            [-72.3966, -2.4465],
            [-72.6443, -2.3341],
            [-72.9433, -2.419],
            [-73.1953, -2.1872],
            [-73.1111, -2.0734],
            [-73.1863, -1.7996],
            [-73.3403, -1.7922],
            [-73.531, -1.6737],
            [-73.4972, -1.478],
            [-73.6366, -1.2552],
            [-73.8808, -1.191],
            [-73.9199, -1.1138],
            [-74.2891, -0.943],
            [-74.4127, -0.5731],
            [-74.8247, -0.1705],
            [-74.9335, -0.2094],
            [-75.1417, -0.0435],
            [-75.2835, -0.107],
            [-75.6467, 0.0854],
            [-75.7897, 0.0844],
            [-75.9271, 0.181],
            [-76.0535, 0.3635],
            [-76.3116, 0.4585],
            [-76.4159, 0.3954],
            [-76.408, 0.2545],
            [-76.5843, 0.2226],
            [-76.7369, 0.2729],
            [-76.8968, 0.2453],
            [-77.1174, 0.3576],
            [-77.2069, 0.3342],
            [-77.4244, 0.4083],
            [-77.4681, 0.6509],
            [-77.6456, 0.7163],
            [-77.7279, 0.8433],
            [-78.12, 0.9212],
            [-78.5701, 1.1958],
            [-78.8287, 1.4343],
            [-78.8533, 1.5434],
            [-78.998, 1.6079],
            [-78.8461, 1.8216],
            [-78.5712, 1.7822],
            [-78.5863, 2.0004],
            [-78.7032, 2.1898],
            [-78.5857, 2.4031],
            [-78.4322, 2.587],
            [-78.0867, 2.5106],
            [-78.0961, 2.6515],
            [-77.9498, 2.6745],
            [-77.9043, 2.58],
            [-77.7515, 2.6273],
            [-77.8118, 2.7485],
            [-77.6434, 2.8981],
            [-77.6477, 3.0739],
            [-77.5087, 3.1946],
            [-77.4574, 3.3561],
            [-77.2049, 3.6212],
            [-77.1515, 3.8004],
            [-77.425, 4.0046],
            [-77.4352, 4.1552],
            [-77.3196, 4.5466],
            [-77.3667, 4.9989],
            [-77.3592, 5.2973],
            [-77.4908, 5.5948],
            [-77.3556, 5.6079],
            [-77.2464, 5.7873],
            [-77.4151, 6.2391],
            [-77.343, 6.5438],
            [-77.4112, 6.6938],
            [-77.5318, 6.7121],
            [-77.7013, 6.9184],
            [-77.6527, 6.9766],
            [-77.8958, 7.2351],
            [-77.8202, 7.4765],
            [-77.58, 7.5284],
            [-77.3397, 7.7072],
            [-77.3216, 7.881],
            [-77.2012, 7.982],
            [-77.4804, 8.5262],
            [-77.3735, 8.6647],
            [-77.2747, 8.4958],
            [-77.1336, 8.4084],
            [-77.0528, 8.2763],
            [-76.8323, 8.1365],
            [-76.9337, 7.9646],
            [-76.7572, 7.9236],
            [-76.732, 8.0793],
            [-76.7701, 8.398],
            [-76.8937, 8.6203],
            [-76.6732, 8.6804],
            [-76.2563, 9.0074],
            [-76.1681, 9.247],
            [-75.91, 9.4282],
            [-75.8119, 9.3915],
            [-75.6206, 9.4529],
            [-75.5764, 9.6211],
            [-75.6578, 9.7052],
            [-75.5738, 10.074],
            [-75.6464, 10.1935],
            [-75.5157, 10.319],
            [-75.5098, 10.5858],
            [-75.3073, 10.7095],
            [-75.2674, 10.7953],
            [-75.036, 10.9167],
            [-74.8444, 11.1097],
            [-74.5028, 10.9743],
            [-74.4566, 10.748],
            [-74.3622, 10.7757],
            [-74.2181, 11.0791],
            [-74.1875, 11.317],
            [-74.0046, 11.3553],
            [-73.826, 11.2768],
            [-73.2924, 11.294],
            [-73.0548, 11.494],
            [-72.7597, 11.6971],
            [-72.4521, 11.7913],
            [-72.2631, 11.886],
            [-72.1387, 12.1046],
            [-72.1705, 12.2343],
            [-72.0049, 12.2629],
            [-71.9053, 12.2077],
            [-71.6562, 12.4653],
            [-71.2423, 12.3277],
            [-71.1139, 12.0944],
            [-71.3275, 11.85],
          ],
        ],
      },
      properties: {
        NAME: "Colombia",
        NAME_LONG: "Colombia",
        ADM0_A3: "COL",
        ISO_A2: "CO",
        ISO_A3: "COL",
        WB_A2: "CO",
        WB_A3: "COL",
      },
      id: 50,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [44.4841, -12.0855],
              [44.5285, -12.3548],
              [44.3306, -12.2363],
              [44.4841, -12.0855],
            ],
          ],
          [
            [
              [43.4631, -11.9309],
              [43.2629, -11.818],
              [43.2765, -11.3796],
              [43.3994, -11.4184],
              [43.3771, -11.6051],
              [43.4988, -11.8736],
              [43.4631, -11.9309],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Comoros",
        NAME_LONG: "Comoros",
        ADM0_A3: "COM",
        ISO_A2: "KM",
        ISO_A3: "COM",
        WB_A2: "KM",
        WB_A3: "COM",
      },
      id: 51,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-24.3345, 14.8319],
              [-24.5262, 14.9247],
              [-24.4034, 15.038],
              [-24.2958, 14.9305],
              [-24.3345, 14.8319],
            ],
          ],
          [
            [
              [-23.7399, 15.3173],
              [-23.5114, 15.113],
              [-23.494, 14.9187],
              [-23.6765, 14.9355],
              [-23.7721, 15.038],
              [-23.7399, 15.3173],
            ],
          ],
          [
            [
              [-22.8525, 16.2066],
              [-22.6825, 16.1686],
              [-22.6798, 16.0632],
              [-22.8061, 15.9859],
              [-22.9455, 16.0197],
              [-22.8525, 16.2066],
            ],
          ],
          [
            [
              [-25.0911, 17.193],
              [-24.9713, 17.1073],
              [-25.1879, 16.9282],
              [-25.2987, 16.9174],
              [-25.3168, 17.1061],
              [-25.0911, 17.193],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Cape Verde",
        NAME_LONG: "Cape Verde",
        ADM0_A3: "CPV",
        ISO_A2: "CV",
        ISO_A3: "CPV",
        WB_A2: "CV",
        WB_A3: "CPV",
      },
      id: 52,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.6444, 10.9259],
            [-83.4662, 10.4945],
            [-83.2037, 10.1293],
            [-83.0167, 9.9631],
            [-82.7723, 9.661],
            [-82.5736, 9.5762],
            [-82.6189, 9.4867],
            [-82.829, 9.6027],
            [-82.9443, 9.4372],
            [-82.9394, 9.0596],
            [-82.7491, 8.9741],
            [-82.9242, 8.7412],
            [-82.8498, 8.6299],
            [-82.8704, 8.4381],
            [-83.0532, 8.3151],
            [-82.9437, 8.2486],
            [-82.8976, 8.0347],
            [-82.9685, 8.2212],
            [-83.1509, 8.3693],
            [-83.1434, 8.5502],
            [-83.3837, 8.7398],
            [-83.4048, 8.589],
            [-83.2869, 8.5172],
            [-83.2764, 8.4016],
            [-83.5756, 8.445],
            [-83.7399, 8.6238],
            [-83.5876, 8.8354],
            [-83.6299, 9.0353],
            [-83.9181, 9.2947],
            [-84.2727, 9.4816],
            [-84.5216, 9.5193],
            [-84.6784, 9.6926],
            [-84.6346, 9.7834],
            [-84.7623, 9.9757],
            [-84.8509, 9.9669],
            [-85.0899, 10.1724],
            [-85.2401, 10.1163],
            [-85.1445, 9.9993],
            [-84.9596, 9.9465],
            [-84.8646, 9.8229],
            [-85.1458, 9.6126],
            [-85.3324, 9.8266],
            [-85.6386, 9.9055],
            [-85.7786, 10.0799],
            [-85.8616, 10.347],
            [-85.8092, 10.4945],
            [-85.6317, 10.6212],
            [-85.741, 10.9396],
            [-85.7017, 11.0809],
            [-85.6312, 11.1962],
            [-84.9314, 10.9419],
            [-84.6765, 11.0704],
            [-84.0227, 10.7872],
            [-83.9333, 10.7181],
            [-83.663, 10.807],
            [-83.6444, 10.9259],
          ],
        ],
      },
      properties: {
        NAME: "Costa Rica",
        NAME_LONG: "Costa Rica",
        ADM0_A3: "CRI",
        ISO_A2: "CR",
        ISO_A3: "CRI",
        WB_A2: "CR",
        WB_A3: "CRI",
      },
      id: 53,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-82.859, 21.9337],
              [-82.6902, 21.8818],
              [-82.5924, 21.7291],
              [-82.5946, 21.5418],
              [-82.8356, 21.4447],
              [-83.0785, 21.4692],
              [-82.9692, 21.5913],
              [-83.0735, 21.7606],
              [-82.9895, 21.9484],
              [-82.859, 21.9337],
            ],
          ],
          [
            [
              [-75.095, 19.8972],
              [-75.137, 19.9716],
              [-75.1602, 19.9706],
              [-75.2329, 19.9001],
              [-75.6047, 19.892],
              [-75.7218, 19.9575],
              [-75.9801, 19.9524],
              [-76.2482, 19.9906],
              [-76.5244, 19.9439],
              [-76.8664, 19.9377],
              [-76.9589, 19.8911],
              [-77.189, 19.9126],
              [-77.3585, 19.8645],
              [-77.7372, 19.8557],
              [-77.6204, 20.0203],
              [-77.4014, 20.1981],
              [-77.1818, 20.2949],
              [-77.1134, 20.5124],
              [-77.3148, 20.7091],
              [-77.4934, 20.6775],
              [-78.0655, 20.7117],
              [-78.272, 20.9011],
              [-78.4947, 21.0479],
              [-78.5633, 21.3834],
              [-78.7665, 21.6397],
              [-79.2494, 21.5519],
              [-80.2699, 21.887],
              [-80.4372, 22.0412],
              [-80.9921, 22.0543],
              [-81.1976, 22.2125],
              [-81.2913, 22.0976],
              [-81.3961, 22.1707],
              [-81.6515, 22.213],
              [-81.8245, 22.1889],
              [-82.0882, 22.3422],
              [-82.1187, 22.4318],
              [-81.6835, 22.4636],
              [-81.6433, 22.5727],
              [-81.8775, 22.6797],
              [-82.6329, 22.6802],
              [-83.0861, 22.4628],
              [-83.1669, 22.3395],
              [-83.2874, 22.3187],
              [-83.4155, 22.1861],
              [-83.9254, 22.1715],
              [-83.993, 21.9514],
              [-84.3052, 21.8845],
              [-84.66, 21.9121],
              [-84.5833, 22.0075],
              [-84.3221, 22.048],
              [-84.4293, 22.2017],
              [-84.391, 22.3528],
              [-84.0211, 22.6787],
              [-83.8728, 22.7508],
              [-83.3694, 22.894],
              [-83.2076, 23.0027],
              [-82.5755, 23.054],
              [-82.4069, 23.1474],
              [-82.0332, 23.2005],
              [-81.5812, 23.1578],
              [-81.4807, 23.1003],
              [-81.2722, 23.142],
              [-81.1849, 23.0521],
              [-80.6222, 23.0895],
              [-80.5568, 23.0074],
              [-80.2796, 22.912],
              [-80.0246, 22.9467],
              [-79.9879, 22.8841],
              [-79.7002, 22.7469],
              [-79.5118, 22.5346],
              [-79.3418, 22.4132],
              [-79.1487, 22.3776],
              [-78.7818, 22.3906],
              [-78.3815, 22.2466],
              [-78.3014, 22.1638],
              [-78.0651, 22.087],
              [-77.895, 21.8769],
              [-77.6647, 21.7812],
              [-77.5076, 21.8625],
              [-77.0699, 21.5958],
              [-76.9626, 21.4726],
              [-76.6536, 21.2941],
              [-76.3876, 21.2843],
              [-76.1378, 21.1672],
              [-76.1168, 21.0915],
              [-75.8218, 21.1369],
              [-75.5785, 21.0134],
              [-75.7839, 20.7464],
              [-75.7089, 20.6906],
              [-75.3942, 20.739],
              [-75.1491, 20.6831],
              [-74.9984, 20.7008],
              [-74.6052, 20.4857],
              [-74.4011, 20.3073],
              [-74.2136, 20.311],
              [-74.142, 20.1826],
              [-74.2674, 20.0683],
              [-74.813, 20.0373],
              [-75.095, 19.8972],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Cuba",
        NAME_LONG: "Cuba",
        ADM0_A3: "CUB",
        ISO_A2: "CU",
        ISO_A3: "CUB",
        WB_A2: "CU",
        WB_A3: "CUB",
      },
      id: 55,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.0123, 35.0638],
            [33.9065, 35.0691],
            [33.6794, 35.0339],
            [33.4768, 35.0574],
            [33.4768, 35.0948],
            [33.4003, 35.1977],
            [33.1903, 35.1899],
            [33.0958, 35.1697],
            [32.8549, 35.0792],
            [32.7114, 35.1816],
            [32.9294, 35.233],
            [32.9204, 35.3978],
            [33.4275, 35.3289],
            [33.6603, 35.3593],
            [34.0356, 35.4714],
            [34.1817, 35.5542],
            [34.5728, 35.6435],
            [33.9714, 35.3213],
            [33.8999, 35.1645],
            [34.0123, 35.0638],
          ],
        ],
      },
      properties: {
        NAME: "N. Cyprus",
        NAME_LONG: "Northern Cyprus",
        ADM0_A3: "CYN",
        ISO_A2: "-99",
        ISO_A3: "-99",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 58,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [34.022, 35.057],
              [33.8918, 34.9581],
              [33.8981, 35.0613],
              [34.022, 35.057],
            ],
          ],
          [
            [
              [33.7029, 34.9879],
              [33.7015, 34.9729],
              [33.6056, 34.8218],
              [33.0156, 34.6344],
              [32.7607, 34.6532],
              [32.4133, 34.7535],
              [32.3294, 34.8864],
              [32.3723, 35.0415],
              [32.5848, 35.1725],
              [32.6598, 35.1871],
              [32.6915, 35.1837],
              [32.7127, 35.1338],
              [32.8065, 35.0948],
              [32.8159, 35.0628],
              [32.9219, 35.0723],
              [33.1739, 35.1627],
              [33.4159, 35.1603],
              [33.4573, 35.1011],
              [33.4463, 35.0012],
              [33.497, 35.0261],
              [33.7029, 34.9879],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Cyprus",
        NAME_LONG: "Cyprus",
        ADM0_A3: "CYP",
        ISO_A2: "CY",
        ISO_A3: "CYP",
        WB_A2: "CY",
        WB_A3: "CYP",
      },
      id: 59,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.8104, 50.8584],
            [14.9965, 51.0078],
            [15.2271, 50.9774],
            [15.3497, 50.7837],
            [15.7674, 50.7442],
            [15.8482, 50.6752],
            [16.3316, 50.644],
            [16.3342, 50.3719],
            [16.7012, 50.0949],
            [16.9603, 50.232],
            [16.9158, 50.4311],
            [17.1854, 50.3757],
            [17.3348, 50.27],
            [17.6064, 50.2582],
            [17.5842, 50.1459],
            [17.8394, 49.9736],
            [18.0916, 50.0172],
            [18.3069, 49.9097],
            [18.5592, 49.9072],
            [18.6373, 49.7003],
            [18.7735, 49.6758],
            [18.8332, 49.5103],
            [18.5356, 49.4817],
            [18.19, 49.2769],
            [18.1029, 49.0923],
            [17.8603, 48.9217],
            [17.5351, 48.813],
            [17.1675, 48.8598],
            [16.945, 48.6042],
            [16.873, 48.719],
            [16.4357, 48.7945],
            [16.3583, 48.7273],
            [16.0323, 48.7581],
            [15.8185, 48.8723],
            [15.6813, 48.8585],
            [15.2629, 48.9826],
            [14.9821, 49.0079],
            [14.9193, 48.7616],
            [14.8004, 48.7765],
            [14.6957, 48.5895],
            [14.4436, 48.6365],
            [14.3157, 48.5579],
            [14.0748, 48.5917],
            [13.8157, 48.7664],
            [13.6087, 48.9462],
            [13.4823, 48.9376],
            [13.1785, 49.1183],
            [12.9998, 49.2949],
            [12.7978, 49.3278],
            [12.3836, 49.7429],
            [12.5241, 49.905],
            [12.2469, 50.045],
            [12.1073, 50.2909],
            [12.3365, 50.2586],
            [12.5102, 50.3888],
            [12.6788, 50.3937],
            [13.2323, 50.582],
            [13.4479, 50.5973],
            [13.6019, 50.7122],
            [13.8345, 50.7237],
            [14.3819, 50.9209],
            [14.4935, 51.0167],
            [14.8104, 50.8584],
          ],
        ],
      },
      properties: {
        NAME: "Czechia",
        NAME_LONG: "Czech Republic",
        ADM0_A3: "CZE",
        ISO_A2: "CZ",
        ISO_A3: "CZE",
        WB_A2: "CZ",
        WB_A3: "CZE",
      },
      id: 60,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [14.2101, 53.9385],
              [14.2008, 53.8782],
              [13.8753, 53.8441],
              [13.9317, 53.8993],
              [13.9019, 53.9698],
              [14.2101, 53.9385],
            ],
          ],
          [
            [
              [13.3909, 54.6505],
              [13.6405, 54.5857],
              [13.4997, 54.3449],
              [13.347, 54.2375],
              [13.1664, 54.5494],
              [13.3909, 54.6505],
            ],
          ],
          [
            [
              [9.4375, 54.8104],
              [9.5544, 54.8471],
              [9.9705, 54.7626],
              [10.02, 54.5458],
              [10.2039, 54.461],
              [10.7771, 54.3151],
              [11.0774, 54.3422],
              [11.0666, 54.1839],
              [10.7526, 54.0439],
              [10.8874, 53.9639],
              [11.1751, 54.018],
              [11.459, 53.9167],
              [11.6579, 54.1422],
              [12.1089, 54.183],
              [12.3421, 54.3039],
              [12.502, 54.4736],
              [12.876, 54.3654],
              [13.4793, 54.1252],
              [13.6834, 54.168],
              [13.7581, 54.031],
              [13.8901, 53.9129],
              [13.8185, 53.878],
              [13.9842, 53.774],
              [14.2639, 53.7],
              [14.4094, 53.2724],
              [14.3433, 53.0486],
              [14.1445, 52.9599],
              [14.216, 52.818],
              [14.6448, 52.5769],
              [14.5398, 52.4219],
              [14.7614, 52.0767],
              [14.5858, 51.8039],
              [14.7195, 51.6756],
              [14.7322, 51.5158],
              [14.9451, 51.4492],
              [15.0195, 51.2717],
              [14.9553, 51.064],
              [14.8104, 50.8584],
              [14.4935, 51.0167],
              [14.3819, 50.9209],
              [13.8345, 50.7237],
              [13.6019, 50.7122],
              [13.4479, 50.5973],
              [13.2323, 50.582],
              [12.6788, 50.3937],
              [12.5102, 50.3888],
              [12.3365, 50.2586],
              [12.1073, 50.2909],
              [12.2469, 50.045],
              [12.5241, 49.905],
              [12.3836, 49.7429],
              [12.7978, 49.3278],
              [12.9998, 49.2949],
              [13.1785, 49.1183],
              [13.4823, 48.9376],
              [13.6087, 48.9462],
              [13.8157, 48.7664],
              [13.7143, 48.5232],
              [13.5207, 48.5846],
              [13.4056, 48.3766],
              [13.0328, 48.2636],
              [12.745, 48.1206],
              [12.9912, 47.8471],
              [12.892, 47.7235],
              [13.0748, 47.6467],
              [13.0373, 47.493],
              [12.7789, 47.5548],
              [12.7448, 47.6654],
              [12.4241, 47.6916],
              [12.1656, 47.604],
              [11.6205, 47.5897],
              [11.2588, 47.4007],
              [11.0837, 47.3895],
              [10.6079, 47.5623],
              [10.3059, 47.3022],
              [9.9487, 47.5242],
              [9.5475, 47.5345],
              [9.2732, 47.6501],
              [8.9062, 47.6518],
              [8.5582, 47.8012],
              [8.0872, 47.5674],
              [7.586, 47.5846],
              [7.5259, 47.7832],
              [7.6189, 48.0027],
              [7.5726, 48.095],
              [7.752, 48.3553],
              [7.8103, 48.615],
              [8.1027, 48.8213],
              [8.1887, 48.9657],
              [7.9319, 49.0348],
              [7.6488, 49.0357],
              [7.3911, 49.17],
              [7.2681, 49.1056],
              [6.8435, 49.2111],
              [6.7256, 49.1556],
              [6.4945, 49.4355],
              [6.3453, 49.4553],
              [6.5001, 49.7945],
              [6.2227, 49.8871],
              [6.1175, 50.1205],
              [6.3377, 50.3681],
              [6.2329, 50.5871],
              [5.9949, 50.7499],
              [6.0637, 50.9075],
              [5.9829, 51.0747],
              [6.1836, 51.3357],
              [6.1933, 51.5093],
              [5.928, 51.8067],
              [6.2252, 51.8625],
              [6.3825, 51.8276],
              [6.7167, 51.8993],
              [6.7659, 52.1084],
              [7.0263, 52.2306],
              [7.0482, 52.3651],
              [6.6951, 52.4756],
              [6.7018, 52.6191],
              [7.0367, 52.6474],
              [7.1836, 52.9661],
              [7.1946, 53.245],
              [7.0233, 53.4505],
              [7.2261, 53.6665],
              [7.9514, 53.7219],
              [8.168, 53.5532],
              [8.4839, 53.6836],
              [8.5733, 53.8577],
              [8.8607, 53.831],
              [9.0183, 54.0979],
              [8.6519, 54.2748],
              [8.6292, 54.3877],
              [9.0116, 54.5063],
              [8.6887, 54.7353],
              [8.6608, 54.8963],
              [8.8242, 54.9059],
              [9.4375, 54.8104],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Germany",
        NAME_LONG: "Germany",
        ADM0_A3: "DEU",
        ISO_A2: "DE",
        ISO_A3: "DEU",
        WB_A2: "DE",
        WB_A3: "DEU",
      },
      id: 61,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.2407, 11.4879],
            [42.9237, 10.9988],
            [42.7718, 10.9965],
            [42.6156, 11.0897],
            [42.3918, 11.0059],
            [41.7987, 10.9707],
            [41.7491, 11.538],
            [41.8082, 11.7362],
            [41.9364, 11.8274],
            [42.3795, 12.4659],
            [42.5216, 12.4962],
            [42.6783, 12.36],
            [42.7923, 12.4292],
            [42.8521, 12.6117],
            [43.1177, 12.7079],
            [43.313, 12.4777],
            [43.4114, 12.2418],
            [43.3771, 12.0048],
            [43.2066, 11.9578],
            [43.0415, 11.7985],
            [42.7541, 11.708],
            [42.6841, 11.5823],
            [43.1231, 11.5815],
            [43.2407, 11.4879],
          ],
        ],
      },
      properties: {
        NAME: "Djibouti",
        NAME_LONG: "Djibouti",
        ADM0_A3: "DJI",
        ISO_A2: "DJ",
        ISO_A3: "DJI",
        WB_A2: "DJ",
        WB_A3: "DJI",
      },
      id: 62,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.3629, 15.2018],
            [-61.4889, 15.5357],
            [-61.3077, 15.5793],
            [-61.2567, 15.2853],
            [-61.3629, 15.2018],
          ],
        ],
      },
      properties: {
        NAME: "Dominica",
        NAME_LONG: "Dominica",
        ADM0_A3: "DMA",
        ISO_A2: "DM",
        ISO_A3: "DMA",
        WB_A2: "DM",
        WB_A3: "DMA",
      },
      id: 63,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [11.256, 54.9546],
              [11.3689, 54.8811],
              [11.7258, 54.8734],
              [11.8486, 54.7732],
              [11.7661, 54.6466],
              [11.4518, 54.6284],
              [11.0305, 54.7606],
              [11.0881, 54.9442],
              [11.256, 54.9546],
            ],
          ],
          [
            [
              [14.889, 55.2332],
              [15.1316, 55.1455],
              [15.0727, 54.9916],
              [14.6996, 55.0892],
              [14.889, 55.2332],
            ],
          ],
          [
            [
              [10.3384, 55.6101],
              [10.5025, 55.4636],
              [10.6979, 55.4403],
              [10.8284, 55.3066],
              [10.7337, 55.0718],
              [10.3128, 55.0637],
              [9.884, 55.2535],
              [9.8882, 55.5087],
              [10.3384, 55.6101],
            ],
          ],
          [
            [
              [12.3285, 56.1269],
              [12.6219, 56.0438],
              [12.5127, 55.9208],
              [12.6022, 55.7086],
              [12.2482, 55.5461],
              [12.2249, 55.4349],
              [12.3924, 55.2553],
              [12.0466, 55.1401],
              [12.1702, 54.8383],
              [11.9607, 54.6941],
              [11.7497, 54.8731],
              [11.8828, 54.9755],
              [11.6494, 55.181],
              [11.2454, 55.2148],
              [11.2131, 55.4158],
              [11.0711, 55.6769],
              [11.2522, 55.7291],
              [11.6409, 55.9434],
              [11.7627, 55.7495],
              [12.1654, 56.1037],
              [12.3285, 56.1269],
            ],
          ],
          [
            [
              [9.4375, 54.8104],
              [8.8242, 54.9059],
              [8.6608, 54.8963],
              [8.6895, 55.1416],
              [8.6177, 55.4379],
              [8.2902, 55.5838],
              [8.094, 55.5639],
              [8.1936, 55.8118],
              [8.3869, 55.9265],
              [8.2824, 56.0759],
              [8.1065, 56.1195],
              [8.1224, 56.5517],
              [8.4365, 56.5802],
              [8.6011, 56.5011],
              [8.8766, 56.7643],
              [9.106, 56.8001],
              [9.2887, 56.9971],
              [9.1128, 57.0386],
              [8.6795, 56.9525],
              [8.4915, 56.7314],
              [8.2704, 56.8432],
              [8.5863, 57.1064],
              [8.9805, 57.161],
              [9.4121, 57.1654],
              [9.5733, 57.2429],
              [9.967, 57.5914],
              [10.4304, 57.5705],
              [10.5466, 57.4353],
              [10.5391, 57.2367],
              [10.2659, 56.9077],
              [10.3609, 56.5617],
              [10.7891, 56.5364],
              [10.9641, 56.4484],
              [10.75, 56.2161],
              [10.377, 56.2033],
              [10.259, 56.099],
              [10.2454, 55.9146],
              [9.5868, 55.4274],
              [9.7103, 55.2487],
              [9.4944, 55.1605],
              [9.7386, 54.9809],
              [9.4375, 54.8104],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Denmark",
        NAME_LONG: "Denmark",
        ADM0_A3: "DNK",
        ISO_A2: "DK",
        ISO_A3: "DNK",
        WB_A2: "DK",
        WB_A3: "DNK",
      },
      id: 64,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.7762, 18.0393],
            [-71.7093, 18.3133],
            [-71.9122, 18.4307],
            [-72.0009, 18.5825],
            [-71.8416, 18.618],
            [-71.7188, 18.7842],
            [-71.7837, 18.9963],
            [-71.6391, 19.2121],
            [-71.7765, 19.3275],
            [-71.7039, 19.4146],
            [-71.7574, 19.7101],
            [-71.6486, 19.8967],
            [-71.5046, 19.9103],
            [-71.2121, 19.8371],
            [-71.0009, 19.9377],
            [-70.831, 19.9025],
            [-70.6237, 19.7665],
            [-70.4337, 19.7752],
            [-70.3013, 19.6577],
            [-69.8979, 19.636],
            [-69.868, 19.4253],
            [-69.6029, 19.229],
            [-69.6085, 19.0935],
            [-69.0425, 18.9868],
            [-68.9794, 19.0329],
            [-68.7397, 18.9644],
            [-68.3562, 18.6566],
            [-68.4443, 18.4195],
            [-68.5745, 18.3782],
            [-68.6584, 18.2109],
            [-68.743, 18.2045],
            [-68.8496, 18.3748],
            [-69.2363, 18.4505],
            [-69.5253, 18.4084],
            [-69.6021, 18.4557],
            [-69.8837, 18.471],
            [-70.0166, 18.4114],
            [-70.1581, 18.2426],
            [-70.5528, 18.2043],
            [-70.6014, 18.4119],
            [-70.7038, 18.4301],
            [-71.0611, 18.1421],
            [-71.3093, 17.8164],
            [-71.4172, 17.6048],
            [-71.6309, 17.8168],
            [-71.6588, 17.9533],
            [-71.7762, 18.0393],
          ],
        ],
      },
      properties: {
        NAME: "Dominican Rep.",
        NAME_LONG: "Dominican Republic",
        ADM0_A3: "DOM",
        ISO_A2: "DO",
        ISO_A3: "DOM",
        WB_A2: "DO",
        WB_A3: "DOM",
      },
      id: 65,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.6025, 36.9395],
            [8.6238, 36.8261],
            [8.444, 36.7962],
            [8.4079, 36.6426],
            [8.1699, 36.5258],
            [8.3492, 36.4488],
            [8.3171, 36.1459],
            [8.2414, 35.8277],
            [8.3236, 35.6522],
            [8.3137, 35.1031],
            [8.2489, 34.902],
            [8.2365, 34.6477],
            [8.0941, 34.5301],
            [7.8315, 34.4144],
            [7.7743, 34.261],
            [7.5175, 34.095],
            [7.4798, 33.8939],
            [7.553, 33.6587],
            [7.6932, 33.4541],
            [7.7507, 33.2077],
            [8.0868, 33.0943],
            [8.2829, 32.8364],
            [8.3196, 32.5606],
            [8.4827, 32.4348],
            [9.045, 32.0718],
            [9.2644, 31.2193],
            [9.5197, 30.2289],
            [9.2865, 30.1171],
            [9.5497, 29.8023],
            [9.6677, 29.6083],
            [9.8261, 29.1285],
            [9.8513, 28.786],
            [9.777, 28.2676],
            [9.9359, 27.8667],
            [9.8216, 27.5057],
            [9.7213, 27.2919],
            [9.8255, 26.9206],
            [9.9106, 26.8431],
            [9.8358, 26.5042],
            [9.4826, 26.3526],
            [9.4012, 26.1134],
            [9.9695, 25.3954],
            [10.0214, 25.268],
            [10.032, 24.8563],
            [10.1934, 24.7499],
            [10.2603, 24.5766],
            [10.4502, 24.4769],
            [10.7207, 24.5523],
            [11.5086, 24.3138],
            [11.5671, 24.2668],
            [11.9689, 23.5174],
            [11.0844, 22.9958],
            [9.7233, 22.1934],
            [8.6345, 21.5515],
            [7.4827, 20.8726],
            [6.61, 20.1429],
            [5.8376, 19.4786],
            [5.7493, 19.4337],
            [4.2286, 19.1422],
            [3.3084, 18.9817],
            [3.1027, 19.1536],
            [3.2582, 19.4104],
            [3.1994, 19.5538],
            [3.2168, 19.7941],
            [2.946, 19.9417],
            [2.4004, 20.0566],
            [2.1824, 20.2785],
            [2.0563, 20.215],
            [1.7781, 20.3043],
            [1.6492, 20.4121],
            [1.644, 20.5227],
            [1.1453, 20.7959],
            [1.1465, 21.1017],
            [-0.0104, 21.8582],
            [-0.9636, 22.4813],
            [-1.83, 23.0478],
            [-2.5232, 23.501],
            [-3.4485, 24.106],
            [-4.8216, 24.9951],
            [-6.1399, 25.7902],
            [-7.297, 26.4707],
            [-8.6824, 27.2854],
            [-8.6824, 27.6614],
            [-8.6824, 28.6659],
            [-8.3835, 28.9058],
            [-8.0697, 29.0793],
            [-7.6195, 29.3894],
            [-7.3496, 29.3834],
            [-7.1469, 29.5092],
            [-6.7835, 29.4463],
            [-6.6996, 29.5162],
            [-6.2733, 29.5791],
            [-5.7562, 29.6141],
            [-5.7212, 29.5232],
            [-5.5395, 29.5232],
            [-5.2739, 29.8866],
            [-4.8756, 30.1802],
            [-4.624, 30.285],
            [-4.3724, 30.5086],
            [-4.1557, 30.5855],
            [-3.8342, 30.6275],
            [-3.6455, 30.7113],
            [-3.6595, 30.8371],
            [-3.5547, 30.9559],
            [-3.6359, 31.0957],
            [-3.815, 31.2205],
            [-3.6735, 31.3892],
            [-3.6595, 31.6478],
            [-2.8278, 31.7946],
            [-2.9387, 32.0486],
            [-2.5161, 32.1322],
            [-1.2496, 32.0817],
            [-1.289, 32.1509],
            [-1.218, 32.3926],
            [-1.0475, 32.517],
            [-1.4233, 32.7424],
            [-1.5588, 32.9336],
            [-1.4995, 33.0602],
            [-1.6742, 33.238],
            [-1.6127, 33.5215],
            [-1.7187, 33.8981],
            [-1.6748, 34.106],
            [-1.8096, 34.3725],
            [-1.7507, 34.4942],
            [-1.8711, 34.5966],
            [-1.7877, 34.7567],
            [-2.1938, 35.0036],
            [-2.2226, 35.0893],
            [-2.0524, 35.075],
            [-1.7611, 35.1296],
            [-1.5104, 35.2955],
            [-1.2677, 35.3903],
            [-1.1903, 35.5661],
            [-0.825, 35.7685],
            [-0.6826, 35.7174],
            [-0.3485, 35.907],
            [-0.1239, 35.7869],
            [0.0345, 35.8633],
            [0.1273, 36.0508],
            [0.6633, 36.3326],
            [0.8648, 36.3774],
            [1.0448, 36.4869],
            [1.9138, 36.5702],
            [2.3098, 36.6305],
            [2.633, 36.6051],
            [2.9331, 36.8086],
            [3.1794, 36.7428],
            [3.2378, 36.8131],
            [3.4607, 36.7751],
            [3.8275, 36.9129],
            [4.1048, 36.8837],
            [4.4407, 36.9116],
            [4.7871, 36.8954],
            [5.1048, 36.7813],
            [5.3044, 36.643],
            [5.5762, 36.7614],
            [6.197, 36.9023],
            [6.3387, 37.0693],
            [6.9094, 36.8931],
            [7.239, 36.9715],
            [7.3835, 37.083],
            [7.7127, 36.9632],
            [7.9085, 36.8496],
            [8.2332, 36.9581],
            [8.4976, 36.9042],
            [8.6025, 36.9395],
          ],
        ],
      },
      properties: {
        NAME: "Algeria",
        NAME_LONG: "Algeria",
        ADM0_A3: "DZA",
        ISO_A2: "DZ",
        ISO_A3: "DZA",
        WB_A2: "DZ",
        WB_A3: "DZA",
      },
      id: 66,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.1151, -2.998],
              [-80.2659, -3.0185],
              [-80.2107, -2.7249],
              [-80.0599, -2.6628],
              [-79.9458, -2.7066],
              [-80.1151, -2.998],
            ],
          ],
          [
            [
              [-89.3081, -0.6856],
              [-89.4347, -0.9293],
              [-89.6314, -0.9167],
              [-89.4132, -0.7135],
              [-89.3081, -0.6856],
            ],
          ],
          [
            [
              [-90.2406, -0.507],
              [-90.1988, -0.6816],
              [-90.3371, -0.777],
              [-90.5445, -0.6816],
              [-90.4877, -0.5255],
              [-90.2406, -0.507],
            ],
          ],
          [
            [
              [-91.4557, -0.2576],
              [-91.4011, -0.4529],
              [-91.5076, -0.4904],
              [-91.6437, -0.4036],
              [-91.6686, -0.2849],
              [-91.4557, -0.2576],
            ],
          ],
          [
            [
              [-90.7773, -0.1477],
              [-90.552, -0.2781],
              [-90.5868, -0.3637],
              [-90.8321, -0.3227],
              [-90.7773, -0.1477],
            ],
          ],
          [
            [
              [-91.3397, 0.1538],
              [-91.2093, -0.0107],
              [-91.1846, -0.211],
              [-90.9623, -0.442],
              [-90.956, -0.6071],
              [-90.7965, -0.7599],
              [-90.9287, -0.9703],
              [-91.1598, -1.0302],
              [-91.3997, -1.0185],
              [-91.5023, -0.8963],
              [-91.4359, -0.7784],
              [-91.0942, -0.5694],
              [-91.352, -0.3072],
              [-91.4233, -0.0286],
              [-91.3397, 0.1538],
            ],
          ],
          [
            [
              [-75.2835, -0.107],
              [-75.4553, -0.1612],
              [-75.4342, -0.403],
              [-75.272, -0.5257],
              [-75.297, -0.747],
              [-75.2273, -0.9698],
              [-75.4126, -0.9239],
              [-75.56, -1.5026],
              [-76.0912, -2.1289],
              [-76.6846, -2.5736],
              [-77.849, -2.9806],
              [-78.1964, -3.3637],
              [-78.3629, -3.4887],
              [-78.5018, -3.9537],
              [-78.6824, -4.3269],
              [-78.6727, -4.551],
              [-78.8531, -4.6523],
              [-78.9002, -4.8437],
              [-79.0093, -4.9601],
              [-79.3032, -4.9612],
              [-79.4506, -4.8102],
              [-79.5072, -4.5316],
              [-79.658, -4.4387],
              [-79.7957, -4.4847],
              [-80.0797, -4.309],
              [-80.4085, -4.4775],
              [-80.4885, -4.396],
              [-80.4811, -3.9988],
              [-80.3046, -4.0052],
              [-80.1653, -3.89],
              [-80.2164, -3.6316],
              [-80.3407, -3.3935],
              [-80.1356, -3.3276],
              [-79.9532, -3.1974],
              [-79.8346, -2.8068],
              [-79.7251, -2.5698],
              [-79.8395, -2.377],
              [-79.9235, -2.5739],
              [-80.1751, -2.5867],
              [-80.3205, -2.7106],
              [-80.4679, -2.6179],
              [-80.6566, -2.4158],
              [-80.9136, -2.3188],
              [-80.9512, -2.2376],
              [-80.745, -2.1029],
              [-80.733, -1.904],
              [-80.8495, -1.6158],
              [-80.7443, -1.3438],
              [-80.9162, -1.0655],
              [-80.8164, -0.9463],
              [-80.6163, -0.9414],
              [-80.422, -0.5996],
              [-80.4965, -0.3814],
              [-80.3712, -0.2228],
              [-80.2318, -0.1397],
              [-80.0665, 0.0522],
              [-80.017, 0.5589],
              [-80.0903, 0.6522],
              [-80.0758, 0.8161],
              [-79.8622, 0.8764],
              [-79.7656, 0.9551],
              [-79.5718, 0.9837],
              [-79.4565, 1.0725],
              [-79.1473, 1.1096],
              [-79.0718, 1.2095],
              [-78.9578, 1.1634],
              [-78.8287, 1.4343],
              [-78.5701, 1.1958],
              [-78.12, 0.9212],
              [-77.7279, 0.8433],
              [-77.6456, 0.7163],
              [-77.4681, 0.6509],
              [-77.4244, 0.4083],
              [-77.2069, 0.3342],
              [-77.1174, 0.3576],
              [-76.8968, 0.2453],
              [-76.7369, 0.2729],
              [-76.5843, 0.2226],
              [-76.408, 0.2545],
              [-76.4159, 0.3954],
              [-76.3116, 0.4585],
              [-76.0535, 0.3635],
              [-75.9271, 0.181],
              [-75.7897, 0.0844],
              [-75.6467, 0.0854],
              [-75.2835, -0.107],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Ecuador",
        NAME_LONG: "Ecuador",
        ADM0_A3: "ECU",
        ISO_A2: "EC",
        ISO_A3: "ECU",
        WB_A2: "EC",
        WB_A3: "ECU",
      },
      id: 67,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.2003, 31.3143],
            [34.2484, 31.2114],
            [34.4804, 30.6512],
            [34.5338, 30.4002],
            [34.5995, 30.3445],
            [34.8244, 29.7417],
            [34.8867, 29.4901],
            [34.7366, 29.3051],
            [34.6746, 29.1065],
            [34.6335, 28.7707],
            [34.4069, 28.308],
            [34.4553, 28.1675],
            [34.4274, 27.9831],
            [34.2395, 27.7941],
            [34.0785, 27.8007],
            [33.7634, 28.024],
            [33.5647, 28.2971],
            [33.4339, 28.3664],
            [33.2287, 28.5694],
            [33.145, 29.0317],
            [32.8856, 29.2431],
            [32.6808, 29.5999],
            [32.6907, 29.7336],
            [32.5635, 29.9724],
            [32.3377, 29.5999],
            [32.5915, 29.3495],
            [32.6597, 29.0891],
            [32.6255, 28.9727],
            [32.8206, 28.7479],
            [32.8689, 28.5789],
            [33.0029, 28.4895],
            [33.1265, 28.2875],
            [33.5125, 27.9591],
            [33.5579, 27.5368],
            [33.6917, 27.3419],
            [33.8254, 27.2597],
            [33.8368, 27.1208],
            [34.0036, 26.8489],
            [33.9414, 26.6535],
            [34.014, 26.6116],
            [34.3387, 26.0018],
            [34.5466, 25.725],
            [34.6819, 25.4287],
            [34.9898, 24.905],
            [35.2355, 24.4001],
            [35.3919, 24.2828],
            [35.5139, 23.9771],
            [35.4894, 23.497],
            [35.6953, 22.9321],
            [35.886, 22.7357],
            [36.1978, 22.6517],
            [36.4398, 22.3564],
            [36.8954, 22.0661],
            [36.8836, 21.9957],
            [35.4864, 21.9956],
            [34.3231, 21.9955],
            [33.1811, 21.9954],
            [31.4355, 21.9954],
            [31.4906, 22.1482],
            [31.3595, 22.1881],
            [31.2484, 21.9944],
            [30.4315, 21.9945],
            [29.4582, 21.9946],
            [28.2903, 21.9948],
            [27.317, 21.9949],
            [26.3438, 21.9951],
            [24.9812, 21.9954],
            [24.9812, 22.579],
            [24.9812, 23.7463],
            [24.9812, 25.2054],
            [24.9812, 26.6645],
            [24.9812, 27.6665],
            [24.9812, 29.1814],
            [24.8738, 29.4683],
            [24.8116, 29.8909],
            [24.6883, 30.1442],
            [24.9077, 30.4987],
            [24.9949, 30.7851],
            [24.8595, 31.146],
            [24.8613, 31.3804],
            [25.1509, 31.6565],
            [25.1909, 31.5315],
            [25.4019, 31.5032],
            [25.8241, 31.6149],
            [25.9668, 31.6166],
            [26.3622, 31.5219],
            [26.9655, 31.4497],
            [27.3206, 31.381],
            [27.3835, 31.2685],
            [27.6603, 31.1802],
            [27.8633, 31.2335],
            [27.8971, 31.1144],
            [28.3184, 31.0598],
            [28.405, 31.0865],
            [28.818, 30.9512],
            [29.0281, 30.8271],
            [29.2189, 30.838],
            [29.5033, 30.9567],
            [29.8384, 31.1541],
            [30.0422, 31.3205],
            [30.2678, 31.2573],
            [30.3622, 31.5087],
            [30.7407, 31.4055],
            [30.9094, 31.4238],
            [31.221, 31.5799],
            [31.5237, 31.4497],
            [31.8379, 31.5253],
            [31.7902, 31.3435],
            [32.057, 31.0797],
            [32.2482, 31.1069],
            [32.3005, 31.2814],
            [32.5095, 31.1069],
            [32.7313, 31.0388],
            [32.9688, 31.0865],
            [33.0986, 31.1895],
            [33.1877, 31.0617],
            [33.4995, 31.1281],
            [33.648, 31.1173],
            [34.047, 31.2292],
            [34.2003, 31.3143],
          ],
        ],
      },
      properties: {
        NAME: "Egypt",
        NAME_LONG: "Egypt",
        ADM0_A3: "EGY",
        ISO_A2: "EG",
        ISO_A3: "EGY",
        WB_A2: "EG",
        WB_A3: "EGY",
      },
      id: 68,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [40.0262, 15.8826],
              [40.1389, 15.7922],
              [40.1638, 15.6444],
              [40.0608, 15.5922],
              [40.0262, 15.8826],
            ],
          ],
          [
            [
              [43.1177, 12.7079],
              [42.8521, 12.6117],
              [42.7923, 12.4292],
              [42.6783, 12.36],
              [42.5216, 12.4962],
              [42.3795, 12.4659],
              [42.1373, 12.7733],
              [41.9466, 12.8762],
              [41.7118, 13.2479],
              [41.192, 13.6159],
              [41.0436, 13.8725],
              [40.8332, 14.106],
              [40.4216, 14.2738],
              [40.2771, 14.4046],
              [40.1046, 14.466],
              [39.9112, 14.4234],
              [39.7669, 14.5048],
              [39.4894, 14.5245],
              [39.2082, 14.4402],
              [39.0755, 14.6374],
              [38.8661, 14.4937],
              [38.4268, 14.4172],
              [38.2293, 14.6797],
              [38.0067, 14.7244],
              [37.8915, 14.8795],
              [37.565, 14.1167],
              [37.2938, 14.4574],
              [37.1211, 14.4208],
              [37.0727, 14.2853],
              [36.7481, 14.3321],
              [36.5264, 14.2635],
              [36.4236, 15.1115],
              [36.5249, 15.2479],
              [36.671, 15.7231],
              [36.7595, 15.8375],
              [36.8859, 16.1849],
              [36.944, 16.2529],
              [36.8773, 16.5273],
              [36.985, 16.7657],
              [37.0005, 17.0726],
              [37.134, 17.0164],
              [37.4034, 17.0299],
              [37.5068, 17.3185],
              [37.872, 17.4373],
              [38.0154, 17.54],
              [38.1606, 17.5573],
              [38.3437, 17.6559],
              [38.4593, 17.8907],
              [38.6016, 18.0048],
              [38.929, 17.3971],
              [39.0559, 17.0341],
              [39.1633, 16.6282],
              [39.2356, 16.1087],
              [39.3135, 15.8939],
              [39.427, 15.8204],
              [39.4828, 15.6149],
              [39.5872, 15.5314],
              [39.7183, 15.2639],
              [39.8753, 15.5041],
              [40.0724, 15.3507],
              [40.0266, 15.2372],
              [40.1594, 14.9822],
              [40.3018, 14.9169],
              [40.3757, 14.9867],
              [40.5542, 14.9902],
              [40.8081, 14.7028],
              [41.1561, 14.6416],
              [41.6209, 14.0636],
              [41.6765, 13.9403],
              [41.981, 13.8378],
              [42.0996, 13.6544],
              [42.2884, 13.5746],
              [42.3794, 13.2226],
              [42.5207, 13.2355],
              [42.7376, 13.0177],
              [42.7739, 12.8621],
              [43.0864, 12.8209],
              [43.1177, 12.7079],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Eritrea",
        NAME_LONG: "Eritrea",
        ADM0_A3: "ERI",
        ISO_A2: "ER",
        ISO_A3: "ERI",
        WB_A2: "ER",
        WB_A3: "ERI",
      },
      id: 69,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.9065, 35.0691],
            [33.8981, 35.0613],
            [33.8918, 34.9581],
            [33.7818, 34.9762],
            [33.7809, 34.9763],
            [33.7015, 34.9729],
            [33.7029, 34.9879],
            [33.6794, 35.0339],
            [33.9065, 35.0691],
          ],
        ],
      },
      properties: {
        NAME: "Dhekelia",
        NAME_LONG: "Dhekelia",
        ADM0_A3: "ESB",
        ISO_A2: "-99",
        ISO_A3: "-99",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 70,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-15.4156, 28.1415],
              [-15.3778, 27.8523],
              [-15.578, 27.7344],
              [-15.786, 27.8442],
              [-15.8161, 27.996],
              [-15.7062, 28.1674],
              [-15.4156, 28.1415],
            ],
          ],
          [
            [
              [-16.1177, 28.5721],
              [-16.3555, 28.3811],
              [-16.4277, 28.1497],
              [-16.5458, 28.024],
              [-16.6763, 27.9971],
              [-16.9161, 28.355],
              [-16.5071, 28.4241],
              [-16.3002, 28.5666],
              [-16.1177, 28.5721],
            ],
          ],
          [
            [
              [-14.2445, 28.1292],
              [-14.1464, 28.4233],
              [-13.9999, 28.7167],
              [-13.8355, 28.7155],
              [-13.8478, 28.3974],
              [-13.9668, 28.228],
              [-14.2445, 28.1292],
            ],
          ],
          [
            [
              [-17.8658, 28.8406],
              [-17.721, 28.7306],
              [-17.8309, 28.4554],
              [-18.0022, 28.7496],
              [-17.8658, 28.8406],
            ],
          ],
          [
            [
              [-13.4587, 29.1474],
              [-13.482, 28.9995],
              [-13.8597, 28.8594],
              [-13.7506, 29.0806],
              [-13.4587, 29.1474],
            ],
          ],
          [
            [
              [1.5784, 39.1049],
              [1.6231, 39.0292],
              [1.3435, 38.8624],
              [1.2122, 38.9032],
              [1.3568, 39.0776],
              [1.5784, 39.1049],
            ],
          ],
          [
            [
              [3.1795, 39.9688],
              [3.1248, 39.8111],
              [3.4685, 39.7393],
              [3.221, 39.3599],
              [3.0608, 39.2691],
              [2.9588, 39.3632],
              [2.7617, 39.4002],
              [2.6553, 39.5647],
              [2.4927, 39.4702],
              [2.3577, 39.6119],
              [2.9333, 39.9145],
              [3.1795, 39.9688],
            ],
          ],
          [
            [
              [4.1168, 40.0596],
              [4.3073, 39.9105],
              [4.2481, 39.8165],
              [3.8219, 40.0476],
              [4.1168, 40.0596],
            ],
          ],
          [
            [
              [-1.7941, 43.386],
              [-1.7474, 43.291],
              [-1.4035, 43.2433],
              [-1.435, 43.0357],
              [-1.2749, 43.0468],
              [-0.9815, 42.957],
              [-0.7597, 42.9465],
              [-0.5612, 42.7735],
              [-0.3074, 42.8318],
              [-0.0389, 42.6851],
              [0.6082, 42.688],
              [0.6563, 42.8384],
              [1.3191, 42.7134],
              [1.4293, 42.5954],
              [1.4479, 42.4346],
              [1.707, 42.5028],
              [2.0517, 42.3524],
              [2.2774, 42.4286],
              [2.4649, 42.3406],
              [2.9337, 42.4702],
              [3.181, 42.4315],
              [3.1225, 42.1405],
              [3.2357, 41.9655],
              [3.1502, 41.8454],
              [2.7785, 41.6456],
              [2.2967, 41.4653],
              [2.0603, 41.2746],
              [1.5392, 41.1874],
              [0.9904, 41.0397],
              [0.7142, 40.8152],
              [0.8724, 40.7071],
              [0.5485, 40.5723],
              [0.3506, 40.2849],
              [0.2053, 40.1715],
              [-0.1945, 39.7154],
              [-0.3234, 39.5162],
              [-0.21, 39.0787],
              [-0.0098, 38.8697],
              [0.2341, 38.7579],
              [-0.0902, 38.5353],
              [-0.3294, 38.47],
              [-0.6095, 38.178],
              [-0.6545, 37.9893],
              [-0.8526, 37.7238],
              [-0.9252, 37.5559],
              [-1.3237, 37.562],
              [-1.6709, 37.3633],
              [-1.8127, 37.2071],
              [-1.8987, 36.9501],
              [-2.0712, 36.7753],
              [-2.2036, 36.7371],
              [-2.3617, 36.8368],
              [-2.5769, 36.8113],
              [-2.7567, 36.6804],
              [-2.9333, 36.7517],
              [-3.2279, 36.7548],
              [-3.4152, 36.6955],
              [-3.6011, 36.7426],
              [-4.0567, 36.748],
              [-4.4333, 36.7107],
              [-4.5248, 36.5807],
              [-4.7228, 36.493],
              [-4.9027, 36.5065],
              [-5.1866, 36.4112],
              [-5.3388, 36.1411],
              [-5.3584, 36.1411],
              [-5.6118, 36.0065],
              [-5.9172, 36.1841],
              [-6.0368, 36.1895],
              [-6.2184, 36.4025],
              [-6.2349, 36.5758],
              [-6.3947, 36.6369],
              [-6.3589, 36.7858],
              [-6.4996, 36.9597],
              [-6.7449, 37.1014],
              [-7.0294, 37.2055],
              [-7.4144, 37.1928],
              [-7.5297, 37.5672],
              [-7.2686, 37.9492],
              [-7.024, 38.0226],
              [-6.9475, 38.1966],
              [-7.1269, 38.1909],
              [-7.3592, 38.4464],
              [-7.2704, 38.7375],
              [-7.0561, 38.8551],
              [-6.9733, 39.014],
              [-7.1675, 39.1135],
              [-7.3267, 39.3413],
              [-7.3135, 39.4574],
              [-7.5573, 39.6798],
              [-7.0358, 39.6893],
              [-6.8797, 40.0092],
              [-7.0431, 40.1814],
              [-6.7944, 40.3564],
              [-6.8571, 40.4421],
              [-6.8367, 40.8402],
              [-6.9425, 41.016],
              [-6.818, 41.0541],
              [-6.6466, 41.2676],
              [-6.4959, 41.2949],
              [-6.2059, 41.5703],
              [-6.3659, 41.6636],
              [-6.5553, 41.6749],
              [-6.5242, 41.8674],
              [-6.6568, 41.9331],
              [-7.1454, 41.9875],
              [-7.2515, 41.8637],
              [-7.5884, 41.8242],
              [-7.722, 41.8993],
              [-8.0486, 41.8164],
              [-8.2317, 41.886],
              [-8.108, 42.0109],
              [-8.2224, 42.1536],
              [-8.6269, 42.051],
              [-8.7508, 41.969],
              [-8.8986, 42.1018],
              [-8.7958, 42.2582],
              [-8.8675, 42.4147],
              [-8.823, 42.5695],
              [-9.0709, 42.5961],
              [-9.1414, 42.9214],
              [-9.251, 43.0168],
              [-9.2176, 43.1553],
              [-9.0524, 43.1964],
              [-8.8412, 43.3389],
              [-8.506, 43.3315],
              [-8.3082, 43.5463],
              [-8.0593, 43.7094],
              [-7.7738, 43.7426],
              [-7.3671, 43.6801],
              [-7.2301, 43.5686],
              [-6.6487, 43.579],
              [-6.5101, 43.5475],
              [-6.2228, 43.5959],
              [-5.4242, 43.5611],
              [-5.1779, 43.4894],
              [-4.7349, 43.4171],
              [-4.1886, 43.4054],
              [-3.8267, 43.497],
              [-3.5544, 43.5169],
              [-3.1124, 43.3541],
              [-2.7558, 43.4503],
              [-2.3674, 43.3157],
              [-2.0989, 43.3041],
              [-1.7941, 43.386],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Spain",
        NAME_LONG: "Spain",
        ADM0_A3: "ESP",
        ISO_A2: "ES",
        ISO_A3: "ESP",
        WB_A2: "ES",
        WB_A3: "ESP",
      },
      id: 71,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [22.6408, 58.5889],
              [23.0911, 58.5709],
              [23.0868, 58.395],
              [22.7567, 58.2423],
              [22.3234, 58.2066],
              [21.8678, 58.2646],
              [22.1136, 58.498],
              [22.322, 58.5848],
              [22.6408, 58.5889],
            ],
          ],
          [
            [
              [22.7205, 59.0116],
              [22.9341, 58.984],
              [23.0405, 58.8408],
              [22.7367, 58.8065],
              [22.5557, 58.687],
              [22.325, 58.8966],
              [22.5977, 59.0895],
              [22.7205, 59.0116],
            ],
          ],
          [
            [
              [28.0191, 59.4818],
              [28.1865, 59.3749],
              [27.9039, 59.2904],
              [27.6944, 58.9759],
              [27.4106, 58.7549],
              [27.5549, 58.3952],
              [27.4954, 58.2215],
              [27.6731, 57.9128],
              [27.4077, 57.689],
              [27.3529, 57.5276],
              [26.8385, 57.6099],
              [26.4996, 57.5158],
              [26.0247, 57.7744],
              [26.0027, 57.846],
              [25.6015, 57.9122],
              [25.167, 58.0587],
              [24.3062, 57.8682],
              [24.4641, 58.0722],
              [24.5288, 58.3507],
              [24.3618, 58.3973],
              [24.1117, 58.241],
              [23.741, 58.3431],
              [23.4932, 58.6734],
              [23.5803, 58.7922],
              [23.4224, 58.9087],
              [23.4709, 59.2121],
              [24.0921, 59.3126],
              [24.0806, 59.3955],
              [24.3997, 59.4788],
              [25.1941, 59.5227],
              [25.4077, 59.491],
              [25.6848, 59.6286],
              [26.665, 59.5519],
              [26.8269, 59.477],
              [27.8733, 59.4076],
              [28.0191, 59.4818],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Estonia",
        NAME_LONG: "Estonia",
        ADM0_A3: "EST",
        ISO_A2: "EE",
        ISO_A3: "EST",
        WB_A2: "EE",
        WB_A3: "EST",
      },
      id: 72,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3795, 12.4659],
            [41.9364, 11.8274],
            [41.8082, 11.7362],
            [41.7491, 11.538],
            [41.7987, 10.9707],
            [42.3918, 11.0059],
            [42.6156, 11.0897],
            [42.7718, 10.9965],
            [42.9237, 10.9988],
            [42.6996, 10.6585],
            [42.8363, 10.2081],
            [42.9582, 10.1156],
            [43.0677, 9.9225],
            [43.1872, 9.8833],
            [43.2353, 9.6918],
            [43.419, 9.413],
            [43.6215, 9.3369],
            [44.0239, 8.9855],
            [45.5144, 8.4965],
            [46.9205, 8.0256],
            [46.9792, 7.9966],
            [47.9792, 7.9966],
            [47.3959, 7.4342],
            [46.4239, 6.4967],
            [45.936, 5.9967],
            [44.9415, 4.9115],
            [44.9126, 4.8994],
            [43.969, 4.954],
            [43.5285, 4.8416],
            [43.1193, 4.6477],
            [42.9604, 4.5174],
            [42.8689, 4.3268],
            [42.7898, 4.2856],
            [42.2973, 4.2017],
            [42.1353, 4.2001],
            [41.9235, 4.0706],
            [41.885, 3.9772],
            [41.6998, 3.9969],
            [41.4295, 3.9489],
            [41.1145, 3.9623],
            [40.7637, 4.2849],
            [39.8485, 3.8673],
            [39.7451, 3.6606],
            [39.575, 3.4979],
            [39.4362, 3.4624],
            [38.8952, 3.5136],
            [38.4969, 3.6239],
            [38.1019, 3.6126],
            [37.9767, 3.7264],
            [37.1119, 4.2823],
            [36.8441, 4.4322],
            [36.0413, 4.4437],
            [35.9208, 4.6193],
            [35.7845, 4.7642],
            [35.7559, 5.0634],
            [35.8042, 5.318],
            [35.4302, 5.4272],
            [35.3208, 5.3487],
            [35.262, 5.5119],
            [35.0987, 5.6225],
            [34.9844, 5.841],
            [34.9515, 6.0812],
            [34.8447, 6.2487],
            [34.7335, 6.6376],
            [34.5247, 6.7529],
            [34.5037, 6.89],
            [34.2976, 6.9686],
            [34.1958, 7.129],
            [34.0311, 7.2554],
            [34.0065, 7.4099],
            [33.7161, 7.6572],
            [33.4623, 7.7495],
            [33.0513, 7.8011],
            [32.9898, 7.9172],
            [33.2069, 8.2407],
            [33.1741, 8.4045],
            [33.6001, 8.464],
            [33.6955, 8.3729],
            [33.9708, 8.4454],
            [34.1119, 8.6266],
            [34.0707, 9.4546],
            [34.0982, 9.6797],
            [34.3249, 10.2687],
            [34.2795, 10.5655],
            [34.4373, 10.7957],
            [34.5871, 10.8793],
            [34.8385, 10.7291],
            [34.9338, 10.8453],
            [34.9143, 10.964],
            [34.9803, 11.1553],
            [34.9471, 11.2749],
            [35.0731, 11.549],
            [35.0482, 11.7714],
            [35.2156, 11.8966],
            [35.6169, 12.5752],
            [35.7284, 12.6744],
            [36.1236, 12.7214],
            [36.1144, 12.9339],
            [36.2336, 13.3616],
            [36.3821, 13.571],
            [36.4582, 13.8201],
            [36.4366, 13.9912],
            [36.5264, 14.2635],
            [36.7481, 14.3321],
            [37.0727, 14.2853],
            [37.1211, 14.4208],
            [37.2938, 14.4574],
            [37.565, 14.1167],
            [37.8915, 14.8795],
            [38.0067, 14.7244],
            [38.2293, 14.6797],
            [38.4268, 14.4172],
            [38.8661, 14.4937],
            [39.0755, 14.6374],
            [39.2082, 14.4402],
            [39.4894, 14.5245],
            [39.7669, 14.5048],
            [39.9112, 14.4234],
            [40.1046, 14.466],
            [40.2771, 14.4046],
            [40.4216, 14.2738],
            [40.8332, 14.106],
            [41.0436, 13.8725],
            [41.192, 13.6159],
            [41.7118, 13.2479],
            [41.9466, 12.8762],
            [42.1373, 12.7733],
            [42.3795, 12.4659],
          ],
        ],
      },
      properties: {
        NAME: "Ethiopia",
        NAME_LONG: "Ethiopia",
        ADM0_A3: "ETH",
        ISO_A2: "ET",
        ISO_A3: "ETH",
        WB_A2: "ET",
        WB_A3: "ETH",
      },
      id: 73,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [22.8335, 60.1931],
              [22.8469, 60.111],
              [22.7305, 60.0466],
              [22.4699, 59.9975],
              [22.4665, 60.1837],
              [22.8335, 60.1931],
            ],
          ],
          [
            [
              [28.9541, 69.0273],
              [28.4562, 68.9001],
              [28.8015, 68.8355],
              [28.4474, 68.5149],
              [28.6638, 68.204],
              [29.2987, 68.0818],
              [29.6512, 67.8236],
              [30.0094, 67.6858],
              [29.9162, 67.5081],
              [29.5198, 67.2982],
              [29.1001, 66.9912],
              [29.0892, 66.8375],
              [29.5245, 66.4912],
              [29.5932, 66.3459],
              [29.9002, 66.1081],
              [30.1117, 65.712],
              [29.7029, 65.6294],
              [29.7201, 65.3291],
              [29.8205, 65.0937],
              [29.5881, 64.9914],
              [29.7803, 64.7956],
              [30.1705, 64.6817],
              [29.9658, 64.5786],
              [30.0931, 64.3612],
              [30.5585, 64.2197],
              [30.5093, 63.9914],
              [30.2015, 63.7926],
              [29.9808, 63.7415],
              [30.4887, 63.4621],
              [30.8209, 63.3757],
              [31.2257, 63.196],
              [31.5695, 62.9059],
              [31.2222, 62.4917],
              [30.635, 62.184],
              [29.7791, 61.5931],
              [29.2032, 61.2459],
              [28.7976, 61.0968],
              [27.8079, 60.553],
              [27.4568, 60.4637],
              [27.0544, 60.5468],
              [26.6873, 60.4182],
              [26.324, 60.383],
              [26.0946, 60.4233],
              [25.7355, 60.2473],
              [25.1975, 60.2452],
              [25.177, 60.1833],
              [24.2959, 60.0722],
              [23.2372, 59.898],
              [23.2051, 60.0497],
              [23.0594, 60.0397],
              [22.8672, 60.1837],
              [22.7362, 60.2382],
              [22.6276, 60.3754],
              [22.0645, 60.4363],
              [21.7739, 60.6039],
              [21.47, 60.6083],
              [21.3259, 60.8349],
              [21.3357, 61.0046],
              [21.5435, 61.2251],
              [21.5103, 61.5195],
              [21.6234, 61.5459],
              [21.3714, 61.9391],
              [21.2366, 61.985],
              [21.339, 62.3555],
              [21.1156, 62.4958],
              [21.1135, 62.7812],
              [21.3828, 62.8746],
              [21.6154, 63.2039],
              [21.9055, 63.1754],
              [22.3737, 63.3214],
              [22.4972, 63.623],
              [22.9224, 63.8017],
              [23.6045, 64.0277],
              [23.7244, 64.2089],
              [24.3441, 64.5307],
              [24.4229, 64.6756],
              [24.7731, 64.8658],
              [25.4435, 64.9668],
              [25.2825, 65.1914],
              [25.3518, 65.4841],
              [25.0855, 65.6052],
              [24.6711, 65.645],
              [24.5188, 65.796],
              [24.1631, 65.8227],
              [24.0196, 66.0489],
              [23.7242, 66.2066],
              [23.6423, 66.4363],
              [23.8819, 66.5635],
              [24.0013, 66.8124],
              [23.5813, 67.1531],
              [23.7652, 67.4201],
              [23.5535, 67.587],
              [23.4989, 67.8822],
              [23.6612, 67.9329],
              [23.1517, 68.1303],
              [23.0522, 68.2982],
              [22.6076, 68.4249],
              [22.0722, 68.477],
              [21.4636, 68.6869],
              [20.9346, 68.967],
              [20.6232, 69.0364],
              [21.3136, 69.2914],
              [21.6629, 69.2632],
              [22.0231, 69.012],
              [22.5965, 68.7246],
              [23.1976, 68.618],
              [23.7348, 68.7154],
              [23.929, 68.8162],
              [24.6634, 68.6661],
              [25.1176, 68.6377],
              [25.2013, 68.8126],
              [25.6516, 68.8835],
              [25.8011, 69.0132],
              [25.7341, 69.1913],
              [26.032, 69.6967],
              [26.5007, 69.9183],
              [27.3118, 69.9295],
              [27.8669, 70.0753],
              [28.4046, 69.8023],
              [29.1312, 69.6742],
              [29.3445, 69.4644],
              [28.8648, 69.2166],
              [28.9541, 69.0273],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Finland",
        NAME_LONG: "Finland",
        ADM0_A3: "FIN",
        ISO_A2: "FI",
        ISO_A3: "FIN",
        WB_A2: "FI",
        WB_A3: "FIN",
      },
      id: 74,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [178.2727, -17.3517],
              [178.2796, -17.4074],
              [178.6023, -17.65],
              [178.5949, -17.926],
              [178.6846, -18.0704],
              [178.4702, -18.1419],
              [178.277, -18.1463],
              [178.1683, -18.2485],
              [177.8272, -18.2345],
              [177.3359, -18.1056],
              [177.2516, -17.9522],
              [177.2821, -17.8517],
              [177.4353, -17.7433],
              [177.3889, -17.6406],
              [177.6221, -17.4547],
              [177.8333, -17.3665],
              [178.2727, -17.3517],
            ],
          ],
          [
            [
              [179.9038, -16.2311],
              [179.7385, -16.4592],
              [179.5979, -16.5398],
              [179.4888, -16.7076],
              [179.5481, -16.766],
              [179.8511, -16.5086],
              [179.9038, -16.7177],
              [179.5969, -16.7888],
              [179.2969, -16.8068],
              [179.2664, -16.6899],
              [179.0444, -16.8238],
              [178.8838, -16.8577],
              [178.7053, -16.9988],
              [178.6023, -16.7797],
              [178.6809, -16.6681],
              [178.917, -16.4706],
              [179.2404, -16.3981],
              [179.7644, -16.1814],
              [180, -16.1491],
              [180, -16.169],
              [179.9038, -16.2311],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Fiji",
        NAME_LONG: "Fiji",
        ADM0_A3: "FJI",
        ISO_A2: "FJ",
        ISO_A3: "FJI",
        WB_A2: "FJ",
        WB_A3: "FJI",
      },
      id: 75,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-59.3649, -51.3549],
              [-59.2618, -51.457],
              [-59.5225, -51.6183],
              [-59.8717, -51.949],
              [-60.2599, -51.9854],
              [-60.3604, -52.1536],
              [-60.5366, -52.2171],
              [-60.7968, -52.1721],
              [-60.8868, -52.0362],
              [-60.3999, -51.9166],
              [-60.6102, -51.7428],
              [-60.2488, -51.6116],
              [-60.0296, -51.3925],
              [-59.3928, -51.4328],
              [-59.3649, -51.3549],
            ],
          ],
          [
            [
              [-58.7335, -51.3283],
              [-58.4814, -51.301],
              [-58.3291, -51.4163],
              [-57.9064, -51.3766],
              [-57.8026, -51.5495],
              [-57.8638, -51.7263],
              [-58.1444, -51.7623],
              [-58.6045, -52.002],
              [-58.9477, -52.0566],
              [-59.1383, -51.9833],
              [-59.2217, -52.2072],
              [-59.386, -52.1119],
              [-59.6283, -52.1106],
              [-59.5813, -51.9113],
              [-59.3638, -51.8224],
              [-59.1007, -51.6218],
              [-59.0843, -51.406],
              [-58.7335, -51.3283],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Falkland Is.",
        NAME_LONG: "Falkland Islands",
        ADM0_A3: "FLK",
        ISO_A2: "FK",
        ISO_A3: "FLK",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 76,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [55.5013, -20.8732],
              [55.6506, -20.898],
              [55.8545, -21.157],
              [55.8208, -21.325],
              [55.5545, -21.3635],
              [55.3545, -21.2746],
              [55.2254, -21.0483],
              [55.303, -20.9052],
              [55.5013, -20.8732],
            ],
          ],
          [
            [
              [-51.6832, 4.0394],
              [-51.9884, 3.7046],
              [-52.0128, 3.6191],
              [-52.3532, 3.0584],
              [-52.5716, 2.6386],
              [-52.5535, 2.5734],
              [-52.7077, 2.3589],
              [-52.9597, 2.1752],
              [-53.2342, 2.251],
              [-53.3441, 2.3496],
              [-53.5318, 2.247],
              [-53.7482, 2.3193],
              [-53.8668, 2.3049],
              [-54.1349, 2.1107],
              [-54.4766, 2.2141],
              [-54.6153, 2.3263],
              [-54.5203, 2.3489],
              [-54.3594, 2.508],
              [-54.2125, 2.7764],
              [-54.1634, 2.9697],
              [-54.1905, 3.1781],
              [-54.003, 3.4554],
              [-53.9888, 3.611],
              [-54.1874, 3.8147],
              [-54.3552, 4.0665],
              [-54.4508, 4.4842],
              [-54.4355, 4.7094],
              [-54.4821, 4.9128],
              [-54.4302, 5.0534],
              [-54.171, 5.3484],
              [-54.0611, 5.4365],
              [-53.9443, 5.7446],
              [-53.7767, 5.7142],
              [-53.5173, 5.5808],
              [-53.2517, 5.5486],
              [-52.9946, 5.4576],
              [-52.4023, 4.9738],
              [-52.0941, 4.7581],
              [-51.9313, 4.5904],
              [-51.8015, 4.6127],
              [-51.6507, 4.19],
              [-51.6832, 4.0394],
            ],
          ],
          [
            [
              [-60.979, 14.7847],
              [-60.8104, 14.462],
              [-61.006, 14.4763],
              [-61.2288, 14.822],
              [-61.102, 14.8662],
              [-60.979, 14.7847],
            ],
          ],
          [
            [
              [-61.6046, 16.2823],
              [-61.5577, 16.0863],
              [-61.6949, 15.9492],
              [-61.7686, 16.0876],
              [-61.7708, 16.3488],
              [-61.6046, 16.2823],
            ],
          ],
          [
            [
              [9.4494, 42.6826],
              [9.5281, 42.5635],
              [9.5527, 42.113],
              [9.4032, 41.9529],
              [9.4016, 41.7064],
              [9.2107, 41.4409],
              [8.9357, 41.4892],
              [8.6956, 41.7294],
              [8.8054, 41.9069],
              [8.738, 42.0439],
              [8.5658, 42.1745],
              [8.6675, 42.5097],
              [9.1071, 42.7259],
              [9.3425, 42.7491],
              [9.4494, 42.6826],
            ],
          ],
          [
            [
              [5.7907, 49.5378],
              [5.9607, 49.4413],
              [6.1361, 49.4953],
              [6.3453, 49.4553],
              [6.4945, 49.4355],
              [6.7256, 49.1556],
              [6.8435, 49.2111],
              [7.2681, 49.1056],
              [7.3911, 49.17],
              [7.6488, 49.0357],
              [7.9319, 49.0348],
              [8.1887, 48.9657],
              [8.1027, 48.8213],
              [7.8103, 48.615],
              [7.752, 48.3553],
              [7.5726, 48.095],
              [7.6189, 48.0027],
              [7.5259, 47.7832],
              [7.586, 47.5846],
              [7.3785, 47.4306],
              [7.1037, 47.4963],
              [7.0443, 47.3405],
              [6.6654, 47.0213],
              [6.4426, 46.9442],
              [6.4292, 46.7608],
              [6.1319, 46.5956],
              [6.0642, 46.4711],
              [6.2141, 46.3155],
              [6.4829, 46.4486],
              [6.7627, 46.4293],
              [6.8539, 46.1226],
              [7.0221, 45.9253],
              [6.8008, 45.8265],
              [6.7959, 45.7181],
              [6.9634, 45.6407],
              [6.9828, 45.5111],
              [7.1607, 45.4109],
              [7.0622, 45.2185],
              [6.8441, 45.1301],
              [6.6027, 45.1034],
              [6.7279, 44.9287],
              [6.9828, 44.847],
              [7.019, 44.7389],
              [6.8388, 44.5029],
              [6.8661, 44.3721],
              [7.0335, 44.2429],
              [7.3808, 44.1229],
              [7.6247, 44.1801],
              [7.6919, 44.0846],
              [7.4779, 43.8655],
              [7.5023, 43.7922],
              [7.4375, 43.7434],
              [7.3658, 43.7227],
              [6.9756, 43.5469],
              [6.894, 43.4276],
              [6.7311, 43.4078],
              [6.6798, 43.2016],
              [6.027, 43.0782],
              [5.5378, 43.2074],
              [5.347, 43.2159],
              [5.3389, 43.3343],
              [5.0648, 43.328],
              [5.0223, 43.5522],
              [4.8203, 43.3464],
              [4.5663, 43.3711],
              [4.513, 43.4558],
              [4.2059, 43.4599],
              [3.9393, 43.5325],
              [3.3854, 43.2839],
              [3.114, 43.1063],
              [3.039, 42.9428],
              [3.0518, 42.5549],
              [3.181, 42.4315],
              [2.9337, 42.4702],
              [2.4649, 42.3406],
              [2.2774, 42.4286],
              [2.0517, 42.3524],
              [1.707, 42.5028],
              [1.722, 42.6099],
              [1.4293, 42.5954],
              [1.3191, 42.7134],
              [0.6563, 42.8384],
              [0.6082, 42.688],
              [-0.0389, 42.6851],
              [-0.3074, 42.8318],
              [-0.5612, 42.7735],
              [-0.7597, 42.9465],
              [-0.9815, 42.957],
              [-1.2749, 43.0468],
              [-1.435, 43.0357],
              [-1.4035, 43.2433],
              [-1.7474, 43.291],
              [-1.7941, 43.386],
              [-1.661, 43.3997],
              [-1.4769, 43.5801],
              [-1.3461, 44.0192],
              [-1.2473, 44.5045],
              [-1.1995, 45.1208],
              [-1.14, 45.4901],
              [-0.8418, 45.5146],
              [-1.1942, 45.7102],
              [-1.0487, 46.0388],
              [-1.1796, 46.2295],
              [-1.5045, 46.4193],
              [-1.8048, 46.5036],
              [-1.8574, 46.6353],
              [-2.1188, 46.82],
              [-1.9926, 47.0523],
              [-2.4049, 47.2864],
              [-2.5017, 47.5267],
              [-2.7582, 47.6391],
              [-3.0738, 47.5765],
              [-3.3451, 47.7213],
              [-3.8517, 47.801],
              [-4.0944, 47.8755],
              [-4.2252, 47.8024],
              [-4.4346, 47.9757],
              [-4.3619, 48.1157],
              [-4.4576, 48.249],
              [-4.7834, 48.3764],
              [-4.7646, 48.5202],
              [-4.5636, 48.6296],
              [-3.974, 48.7328],
              [-3.614, 48.6827],
              [-3.5153, 48.8419],
              [-3.0771, 48.8283],
              [-2.6363, 48.5388],
              [-2.3125, 48.687],
              [-2.2192, 48.6037],
              [-1.977, 48.6911],
              [-1.8382, 48.6238],
              [-1.5797, 48.6433],
              [-1.5536, 48.7471],
              [-1.6104, 49.2159],
              [-1.8196, 49.3968],
              [-1.8847, 49.5309],
              [-1.8118, 49.6907],
              [-1.5973, 49.6523],
              [-1.3913, 49.7127],
              [-1.3063, 49.5581],
              [-1.0723, 49.3968],
              [-0.4111, 49.3428],
              [-0.2195, 49.2801],
              [0.072, 49.3593],
              [0.2568, 49.4641],
              [0.0797, 49.5422],
              [0.2359, 49.7293],
              [0.6748, 49.8758],
              [1.1726, 49.961],
              [1.521, 50.2147],
              [1.5735, 50.4454],
              [1.5806, 50.8688],
              [1.9735, 51.0071],
              [2.5218, 51.0875],
              [2.6204, 50.8165],
              [2.7867, 50.7234],
              [3.1462, 50.7689],
              [3.271, 50.5269],
              [3.4629, 50.5191],
              [3.755, 50.3463],
              [4.0227, 50.3381],
              [4.21, 50.0601],
              [4.4351, 49.9323],
              [4.7883, 49.9743],
              [4.849, 49.7942],
              [4.9891, 49.7906],
              [5.4021, 49.6025],
              [5.4777, 49.4952],
              [5.7907, 49.5378],
            ],
          ],
        ],
      },
      properties: {
        NAME: "France",
        NAME_LONG: "France",
        ADM0_A3: "FRA",
        ISO_A2: "FR",
        ISO_A3: "FRA",
        WB_A2: "FR",
        WB_A3: "FRA",
      },
      id: 77,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.1903, 62.3098],
            [-6.7156, 62.034],
            [-6.8569, 61.9948],
            [-7.2321, 62.1764],
            [-7.1903, 62.3098],
          ],
        ],
      },
      properties: {
        NAME: "Faeroe Is.",
        NAME_LONG: "Faeroe Islands",
        ADM0_A3: "FRO",
        ISO_A2: "FO",
        ISO_A3: "FRO",
        WB_A2: "FO",
        WB_A3: "FRO",
      },
      id: 78,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.2946, 2.1611],
            [13.2069, 1.9936],
            [13.1296, 1.5925],
            [13.2395, 1.428],
            [13.2502, 1.2218],
            [13.4139, 1.2955],
            [13.5434, 1.2835],
            [13.7947, 1.4344],
            [14.1839, 1.3808],
            [14.2747, 1.1556],
            [14.4681, 0.9132],
            [14.4476, 0.8162],
            [14.2318, 0.5426],
            [14.076, 0.5395],
            [13.8712, 0.1964],
            [13.9211, -0.053],
            [13.8312, -0.2054],
            [14.1214, -0.3022],
            [14.1597, -0.4551],
            [14.2927, -0.4364],
            [14.499, -0.6309],
            [14.3918, -1.0233],
            [14.4821, -1.3886],
            [14.3738, -1.6041],
            [14.3707, -1.9213],
            [14.2522, -1.9676],
            [14.2384, -2.1202],
            [14.1512, -2.259],
            [14.227, -2.3231],
            [14.0809, -2.4999],
            [13.885, -2.4885],
            [13.8639, -2.3215],
            [13.7707, -2.1191],
            [13.5934, -2.3268],
            [13.361, -2.4288],
            [13.0107, -2.2878],
            [12.8046, -1.9191],
            [12.5832, -1.8265],
            [12.4231, -1.8976],
            [12.4702, -2.1563],
            [12.4593, -2.3299],
            [12.0623, -2.415],
            [11.9384, -2.3291],
            [11.7561, -2.4151],
            [11.5582, -2.3494],
            [11.6368, -2.8333],
            [11.7637, -2.9826],
            [11.6875, -3.171],
            [11.9444, -3.3032],
            [11.8278, -3.5481],
            [11.8197, -3.7104],
            [11.6492, -3.6733],
            [11.5698, -3.545],
            [11.2125, -3.6973],
            [11.114, -3.9369],
            [10.9846, -3.7146],
            [10.6464, -3.4519],
            [10.6354, -3.3137],
            [10.4526, -3.1333],
            [9.9024, -2.7004],
            [9.8425, -2.5006],
            [9.597, -2.3522],
            [9.5658, -2.1926],
            [9.368, -1.9952],
            [9.2443, -1.7858],
            [9.2998, -1.5774],
            [9.2853, -1.4158],
            [9.03, -1.2991],
            [9.0158, -1.1699],
            [8.8775, -0.9737],
            [8.8003, -0.7337],
            [9.1008, -0.5997],
            [9.2674, -0.4124],
            [9.3553, 0.0162],
            [9.4981, 0.291],
            [9.3088, 0.614],
            [9.52, 0.6835],
            [9.6182, 0.8068],
            [9.5615, 0.9628],
            [9.6688, 1.0582],
            [9.8044, 0.9984],
            [9.9086, 0.9351],
            [10.0294, 1.0035],
            [11.3363, 0.9992],
            [11.3221, 2.1658],
            [11.3516, 2.3006],
            [11.6712, 2.321],
            [11.7527, 2.2819],
            [12.2026, 2.2796],
            [12.334, 2.3096],
            [12.7499, 2.2367],
            [13.2429, 2.2715],
            [13.2946, 2.1611],
          ],
        ],
      },
      properties: {
        NAME: "Gabon",
        NAME_LONG: "Gabon",
        ADM0_A3: "GAB",
        ISO_A2: "GA",
        ISO_A3: "GAB",
        WB_A2: "GA",
        WB_A3: "GAB",
      },
      id: 80,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-1.2958, 50.7732],
              [-1.0623, 50.6928],
              [-1.3155, 50.5892],
              [-1.5059, 50.7077],
              [-1.2958, 50.7732],
            ],
          ],
          [
            [
              [-4.2032, 53.2985],
              [-4.3439, 53.1321],
              [-4.571, 53.2755],
              [-4.5535, 53.4006],
              [-4.2767, 53.401],
              [-4.2032, 53.2985],
            ],
          ],
          [
            [
              [-6.2699, 54.0979],
              [-6.6165, 54.0373],
              [-6.6403, 54.168],
              [-6.8239, 54.2323],
              [-7.0493, 54.4115],
              [-7.3103, 54.1147],
              [-7.837, 54.2043],
              [-8.156, 54.4391],
              [-7.9066, 54.6613],
              [-7.5517, 54.7547],
              [-7.4053, 55.0036],
              [-7.2471, 55.0693],
              [-7.0172, 55.0711],
              [-6.4759, 55.2487],
              [-6.0371, 55.1679],
              [-6.0621, 55.0741],
              [-5.7976, 54.8445],
              [-5.748, 54.6804],
              [-5.5366, 54.6575],
              [-5.4335, 54.4663],
              [-5.6126, 54.2556],
              [-5.862, 54.2305],
              [-6.0405, 54.0364],
              [-6.2699, 54.0979],
            ],
          ],
          [
            [
              [-5.1178, 55.4398],
              [-5.3457, 55.4827],
              [-5.3552, 55.6896],
              [-5.16, 55.6762],
              [-5.1178, 55.4398],
            ],
          ],
          [
            [
              [-6.1024, 55.8312],
              [-6.0235, 55.6819],
              [-6.2153, 55.6267],
              [-6.4091, 55.8636],
              [-6.1024, 55.8312],
            ],
          ],
          [
            [
              [-6.0335, 56.6188],
              [-5.9521, 56.5242],
              [-5.6484, 56.4477],
              [-5.72, 56.3528],
              [-6.3275, 56.2772],
              [-6.0335, 56.6188],
            ],
          ],
          [
            [
              [-6.3109, 57.7028],
              [-6.1539, 57.5895],
              [-6.1066, 57.3235],
              [-5.6909, 57.1895],
              [-5.9189, 57.0501],
              [-6.0235, 57.1957],
              [-6.3183, 57.161],
              [-6.4822, 57.3118],
              [-6.7148, 57.3766],
              [-6.7065, 57.5109],
              [-6.3109, 57.7028],
            ],
          ],
          [
            [
              [-6.1955, 58.3563],
              [-6.3179, 58.2683],
              [-6.3524, 58.0386],
              [-6.9808, 57.7351],
              [-7.1265, 58.124],
              [-6.8186, 58.2851],
              [-6.3456, 58.4632],
              [-6.1955, 58.3563],
            ],
          ],
          [
            [
              [-3.3479, 58.652],
              [-3.0191, 58.6403],
              [-3.0863, 58.4142],
              [-3.2205, 58.3056],
              [-4.0301, 57.9532],
              [-3.9148, 57.8077],
              [-4.1186, 57.593],
              [-3.8375, 57.593],
              [-3.4919, 57.7131],
              [-3.0787, 57.6694],
              [-2.9161, 57.7028],
              [-2.4329, 57.6681],
              [-2.1081, 57.705],
              [-1.8295, 57.6135],
              [-1.7593, 57.4736],
              [-1.9803, 57.3193],
              [-2.2199, 56.8726],
              [-2.4407, 56.7348],
              [-2.53, 56.5808],
              [-2.8216, 56.3762],
              [-2.5768, 56.2839],
              [-2.9643, 56.2056],
              [-3.3035, 55.9776],
              [-2.9399, 55.9693],
              [-2.6351, 56.0581],
              [-2.1468, 55.9173],
              [-1.8217, 55.6364],
              [-1.6308, 55.5851],
              [-1.5701, 55.2862],
              [-1.2755, 54.7484],
              [-1.1036, 54.6247],
              [-0.563, 54.4774],
              [-0.3976, 54.2692],
              [-0.1114, 54.1316],
              [-0.2195, 54.0228],
              [-0.1511, 53.8993],
              [0.1335, 53.6426],
              [-0.0466, 53.5704],
              [0.2195, 53.4195],
              [0.3421, 53.2263],
              [0.3298, 53.0865],
              [0.0221, 52.9126],
              [0.3792, 52.7908],
              [0.5696, 52.9693],
              [0.9661, 52.9744],
              [1.2747, 52.9292],
              [1.6442, 52.7758],
              [1.7712, 52.4859],
              [1.5823, 52.0815],
              [1.1354, 51.7817],
              [0.887, 51.7821],
              [0.9245, 51.5883],
              [0.5545, 51.4122],
              [0.9772, 51.3492],
              [1.4238, 51.3921],
              [1.3572, 51.131],
              [1.0276, 51.0417],
              [0.6242, 50.8587],
              [0.271, 50.7474],
              [-0.1732, 50.829],
              [-0.569, 50.8021],
              [-0.8128, 50.7348],
              [-1.3173, 50.8002],
              [-1.9493, 50.7133],
              [-1.9837, 50.5966],
              [-2.4359, 50.6375],
              [-2.8648, 50.7338],
              [-3.2598, 50.6745],
              [-3.4673, 50.5856],
              [-3.4875, 50.4116],
              [-3.6456, 50.226],
              [-4.0329, 50.2949],
              [-4.2161, 50.4157],
              [-4.4954, 50.3284],
              [-4.7521, 50.3303],
              [-4.8002, 50.2296],
              [-5.0204, 50.192],
              [-5.2104, 49.9667],
              [-5.438, 50.1943],
              [-5.0503, 50.4287],
              [-5.0253, 50.5242],
              [-4.7925, 50.5952],
              [-4.5501, 50.8096],
              [-4.5264, 51.015],
              [-4.3377, 50.9966],
              [-4.1523, 51.2116],
              [-3.7692, 51.2477],
              [-3.385, 51.1862],
              [-3.0575, 51.2082],
              [-2.9596, 51.3743],
              [-3.5392, 51.3985],
              [-3.9746, 51.563],
              [-4.2725, 51.5548],
              [-4.3793, 51.7269],
              [-4.6454, 51.7321],
              [-4.941, 51.5965],
              [-5.1259, 51.856],
              [-5.2889, 51.9169],
              [-4.5138, 52.1357],
              [-4.1971, 52.2793],
              [-4.0531, 52.5424],
              [-4.1299, 52.8892],
              [-4.3582, 53.0285],
              [-4.1515, 53.2281],
              [-3.7689, 53.3192],
              [-3.5623, 53.2944],
              [-3.0583, 53.4348],
              [-3.0995, 53.572],
              [-2.9517, 53.7081],
              [-3.0176, 53.932],
              [-2.796, 54.1252],
              [-2.8509, 54.1846],
              [-3.241, 54.1121],
              [-3.6342, 54.5129],
              [-3.3879, 54.8834],
              [-3.8443, 54.8663],
              [-3.9642, 54.7719],
              [-4.3476, 54.8073],
              [-4.3481, 54.702],
              [-4.8591, 54.8663],
              [-5.1282, 54.8489],
              [-5.1708, 55.0087],
              [-4.6178, 55.4956],
              [-4.9205, 55.7086],
              [-4.9831, 55.871],
              [-5.3136, 56.0124],
              [-5.3178, 55.783],
              [-5.64, 55.3039],
              [-5.7723, 55.3013],
              [-5.7218, 55.5646],
              [-5.5812, 55.7576],
              [-5.6964, 55.916],
              [-5.5225, 56.1658],
              [-5.5981, 56.2586],
              [-5.4109, 56.5521],
              [-5.6674, 56.4985],
              [-6.0034, 56.6187],
              [-5.7355, 56.8941],
              [-5.8317, 56.9971],
              [-5.5994, 57.2792],
              [-5.8078, 57.3549],
              [-5.8697, 57.5009],
              [-5.7628, 57.634],
              [-5.8074, 57.8439],
              [-5.487, 57.8559],
              [-5.2926, 57.9806],
              [-5.3108, 58.2434],
              [-5.0501, 58.4538],
              [-4.9882, 58.6283],
              [-4.3824, 58.5246],
              [-3.8101, 58.5731],
              [-3.3479, 58.652],
            ],
          ],
          [
            [
              [-3.1562, 59.1422],
              [-2.9736, 58.9538],
              [-3.2028, 58.9159],
              [-3.3667, 59.0161],
              [-3.1562, 59.1422],
            ],
          ],
          [
            [
              [-1.2921, 60.5996],
              [-1.0792, 60.355],
              [-1.2626, 60.1151],
              [-1.6697, 60.2396],
              [-1.3964, 60.3218],
              [-1.5484, 60.5394],
              [-1.2921, 60.5996],
            ],
          ],
        ],
      },
      properties: {
        NAME: "United Kingdom",
        NAME_LONG: "United Kingdom",
        ADM0_A3: "GBR",
        ISO_A2: "GB",
        ISO_A3: "GBR",
        WB_A2: "GB",
        WB_A3: "GBR",
      },
      id: 81,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.4309, 41.8904],
            [46.1928, 41.6102],
            [46.2967, 41.5104],
            [46.5916, 41.3729],
            [46.6948, 41.2698],
            [46.4968, 41.0448],
            [46.2106, 41.2002],
            [45.9692, 41.1683],
            [45.7097, 41.2677],
            [45.7207, 41.3424],
            [45.4648, 41.4302],
            [45.2496, 41.4445],
            [45.0024, 41.2905],
            [44.5515, 41.177],
            [44.191, 41.23],
            [44.1247, 41.1781],
            [43.7297, 41.1067],
            [43.4404, 41.1066],
            [43.2306, 41.1726],
            [42.9577, 41.437],
            [42.5652, 41.5671],
            [42.4379, 41.4309],
            [41.948, 41.5056],
            [41.8008, 41.4257],
            [41.5208, 41.5142],
            [41.7739, 41.8216],
            [41.7557, 42.0064],
            [41.6641, 42.114],
            [41.5002, 42.6406],
            [41.3835, 42.7573],
            [41.1502, 42.7956],
            [41.0231, 42.99],
            [40.856, 43.0631],
            [40.3351, 43.1409],
            [40.2191, 43.319],
            [39.986, 43.389],
            [40.0592, 43.5352],
            [40.2331, 43.5758],
            [40.4798, 43.5087],
            [40.6519, 43.539],
            [41.0118, 43.3754],
            [41.3807, 43.3348],
            [41.5506, 43.2263],
            [42.0753, 43.1853],
            [42.4229, 43.229],
            [42.5959, 43.14],
            [42.7515, 43.177],
            [43.1943, 42.9268],
            [43.5174, 42.8605],
            [43.8006, 42.7465],
            [43.7511, 42.5987],
            [43.9397, 42.5522],
            [44.272, 42.7024],
            [44.8594, 42.7595],
            [45.1842, 42.6748],
            [45.356, 42.52],
            [45.6987, 42.5076],
            [45.6214, 42.2245],
            [45.9476, 42.0229],
            [46.2243, 41.9935],
            [46.4309, 41.8904],
          ],
        ],
      },
      properties: {
        NAME: "Georgia",
        NAME_LONG: "Georgia",
        ADM0_A3: "GEO",
        ISO_A2: "GE",
        ISO_A3: "GEO",
        WB_A2: "GE",
        WB_A3: "GEO",
      },
      id: 82,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.1661, 11.135],
            [0.0162, 11.0626],
            [-0.0881, 10.6335],
            [0.0203, 10.588],
            [0.177, 10.3977],
            [0.3666, 10.3045],
            [0.3702, 9.947],
            [0.3224, 9.7607],
            [0.3546, 9.5711],
            [0.2302, 9.5757],
            [0.2314, 9.419],
            [0.4304, 9.4892],
            [0.5376, 9.3776],
            [0.4313, 9.0276],
            [0.5056, 8.8663],
            [0.3659, 8.7743],
            [0.4435, 8.6069],
            [0.6163, 8.4888],
            [0.7052, 8.2828],
            [0.5738, 8.2039],
            [0.6123, 7.6994],
            [0.4994, 7.5624],
            [0.5006, 7.455],
            [0.6457, 7.3236],
            [0.598, 7.0312],
            [0.4946, 6.9731],
            [0.5193, 6.8322],
            [0.7146, 6.5839],
            [0.7668, 6.4194],
            [1.1854, 6.1005],
            [1.0988, 6.047],
            [0.9953, 5.8393],
            [0.7235, 5.7554],
            [0.322, 5.7792],
            [0.0345, 5.6284],
            [-0.3716, 5.4927],
            [-0.5074, 5.3688],
            [-0.6378, 5.3409],
            [-0.7995, 5.2148],
            [-1.0498, 5.1987],
            [-1.2336, 5.1007],
            [-1.5695, 5.0188],
            [-1.7486, 4.8772],
            [-2.0906, 4.7371],
            [-2.3566, 4.9196],
            [-2.6904, 5.0102],
            [-3.1196, 5.0913],
            [-3.1153, 5.1078],
            [-2.9763, 5.0846],
            [-2.8437, 5.1491],
            [-2.7327, 5.3557],
            [-2.7818, 5.5789],
            [-2.9695, 5.6214],
            [-3.1181, 6.1635],
            [-3.1836, 6.2639],
            [-3.2625, 6.6171],
            [-3.2249, 6.8494],
            [-3.0459, 7.0712],
            [-2.9415, 7.5772],
            [-2.84, 7.8202],
            [-2.6194, 8.1231],
            [-2.5063, 8.2093],
            [-2.6194, 8.9236],
            [-2.7748, 9.055],
            [-2.6598, 9.2729],
            [-2.6892, 9.4887],
            [-2.7953, 9.72],
            [-2.7368, 9.8326],
            [-2.8332, 10.306],
            [-2.8146, 10.3937],
            [-2.9325, 10.6343],
            [-2.8642, 10.8643],
            [-2.7507, 10.9858],
            [-1.5987, 10.9971],
            [-0.5165, 10.9886],
            [-0.4059, 11.1015],
            [-0.1661, 11.135],
          ],
        ],
      },
      properties: {
        NAME: "Ghana",
        NAME_LONG: "Ghana",
        ADM0_A3: "GHA",
        ISO_A2: "GH",
        ISO_A3: "GHA",
        WB_A2: "GH",
        WB_A3: "GHA",
      },
      id: 84,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-11.3884, 12.4039],
            [-11.5078, 12.1916],
            [-11.3513, 12.0404],
            [-11.1912, 12.0144],
            [-11.0523, 12.2025],
            [-10.9098, 12.2001],
            [-10.8049, 12.096],
            [-10.7114, 11.8904],
            [-10.3814, 12.1801],
            [-10.2669, 12.2178],
            [-9.8707, 12.0521],
            [-9.723, 12.0254],
            [-9.6282, 12.1702],
            [-9.3085, 12.345],
            [-9.3215, 12.4966],
            [-8.9938, 12.3875],
            [-8.9263, 12.0643],
            [-8.797, 11.9132],
            [-8.8472, 11.6579],
            [-8.7129, 11.6401],
            [-8.679, 11.5298],
            [-8.5276, 11.4234],
            [-8.4832, 11.2849],
            [-8.6969, 10.996],
            [-8.5058, 11.0528],
            [-8.3111, 11.0008],
            [-8.2955, 10.5374],
            [-8.2289, 10.4234],
            [-8.0157, 10.3393],
            [-7.9897, 10.162],
            [-8.1738, 9.9419],
            [-8.1235, 9.8449],
            [-8.1616, 9.5756],
            [-8.0872, 9.4154],
            [-7.8654, 9.4099],
            [-7.9296, 9.1839],
            [-7.9589, 8.782],
            [-7.8037, 8.7491],
            [-7.6916, 8.6061],
            [-7.6896, 8.4387],
            [-7.771, 8.3865],
            [-7.9705, 8.497],
            [-8.2003, 8.4918],
            [-8.266, 8.246],
            [-8.0217, 8.1177],
            [-8.1289, 7.8826],
            [-8.1274, 7.713],
            [-8.229, 7.5443],
            [-8.4854, 7.558],
            [-8.5674, 7.6882],
            [-8.6866, 7.6944],
            [-8.7296, 7.5087],
            [-8.9254, 7.2486],
            [-9.1196, 7.238],
            [-9.2343, 7.3813],
            [-9.4381, 7.4216],
            [-9.3535, 7.6224],
            [-9.4387, 7.8662],
            [-9.4223, 8.0158],
            [-9.5792, 8.3873],
            [-9.6835, 8.4871],
            [-10.2822, 8.4846],
            [-10.3898, 8.4891],
            [-10.5193, 8.3348],
            [-10.6691, 8.3991],
            [-10.6389, 8.5199],
            [-10.4819, 8.6725],
            [-10.6002, 8.819],
            [-10.606, 9.0638],
            [-10.7463, 9.0838],
            [-10.6751, 9.3065],
            [-10.8549, 9.4553],
            [-11.0101, 9.7427],
            [-11.2727, 9.996],
            [-11.9101, 9.9925],
            [-11.9218, 9.9222],
            [-12.1413, 9.8749],
            [-12.2536, 9.9284],
            [-12.5083, 9.8604],
            [-12.5375, 9.7157],
            [-12.7016, 9.4202],
            [-12.8358, 9.282],
            [-12.9579, 9.2724],
            [-13.0289, 9.096],
            [-13.3011, 9.0415],
            [-13.3222, 9.3454],
            [-13.432, 9.3711],
            [-13.5614, 9.509],
            [-13.6244, 9.7203],
            [-13.7472, 9.7678],
            [-13.9454, 9.9669],
            [-14.1532, 10.0557],
            [-14.463, 10.33],
            [-14.6287, 10.6173],
            [-14.7928, 10.745],
            [-14.7493, 10.8161],
            [-14.8988, 10.9932],
            [-15.021, 10.9675],
            [-14.8282, 11.3348],
            [-14.6868, 11.5107],
            [-14.5209, 11.5129],
            [-14.2787, 11.6651],
            [-14.0151, 11.6371],
            [-13.7299, 11.7098],
            [-13.7239, 12.0028],
            [-13.9649, 12.1479],
            [-13.84, 12.2771],
            [-13.7118, 12.2675],
            [-13.6634, 12.4758],
            [-13.7283, 12.6734],
            [-13.0765, 12.6359],
            [-13.0827, 12.5157],
            [-12.9131, 12.5363],
            [-12.7733, 12.4351],
            [-12.5051, 12.3903],
            [-12.3609, 12.3056],
            [-12.0792, 12.4081],
            [-11.7578, 12.3833],
            [-11.5154, 12.4316],
            [-11.3884, 12.4039],
          ],
        ],
      },
      properties: {
        NAME: "Guinea",
        NAME_LONG: "Guinea",
        ADM0_A3: "GIN",
        ISO_A2: "GN",
        ISO_A3: "GIN",
        WB_A2: "GN",
        WB_A3: "GIN",
      },
      id: 86,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.7537, 13.065],
            [-16.8297, 13.3385],
            [-16.5614, 13.5869],
            [-15.5182, 13.5831],
            [-15.3941, 13.7715],
            [-15.2673, 13.7418],
            [-15.0977, 13.82],
            [-14.9158, 13.7924],
            [-14.7286, 13.6193],
            [-14.5855, 13.6605],
            [-14.4703, 13.5224],
            [-14.3299, 13.4564],
            [-13.9675, 13.5756],
            [-13.8187, 13.4294],
            [-13.851, 13.3358],
            [-14.2017, 13.2296],
            [-14.395, 13.243],
            [-14.5922, 13.3531],
            [-15.0151, 13.4957],
            [-15.1377, 13.59],
            [-15.2278, 13.4252],
            [-15.3792, 13.3625],
            [-15.5195, 13.3866],
            [-15.8227, 13.3194],
            [-15.8333, 13.1568],
            [-16.6735, 13.1643],
            [-16.7537, 13.065],
          ],
        ],
      },
      properties: {
        NAME: "Gambia",
        NAME_LONG: "The Gambia",
        ADM0_A3: "GMB",
        ISO_A2: "GM",
        ISO_A3: "GMB",
        WB_A2: "GM",
        WB_A3: "GMB",
      },
      id: 87,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-15.021, 10.9675],
            [-15.1172, 10.9843],
            [-15.2066, 11.1251],
            [-15.4095, 11.1896],
            [-15.3961, 11.5321],
            [-15.5514, 11.7331],
            [-15.585, 11.8513],
            [-15.8304, 11.7484],
            [-15.9696, 11.7683],
            [-15.9816, 11.9213],
            [-16.1343, 11.8889],
            [-16.3318, 12.0046],
            [-16.3653, 12.1923],
            [-16.4719, 12.1863],
            [-16.7284, 12.3325],
            [-16.382, 12.3738],
            [-16.2329, 12.4529],
            [-15.677, 12.4393],
            [-15.1951, 12.6794],
            [-13.7283, 12.6734],
            [-13.6634, 12.4758],
            [-13.7118, 12.2675],
            [-13.84, 12.2771],
            [-13.9649, 12.1479],
            [-13.7239, 12.0028],
            [-13.7299, 11.7098],
            [-14.0151, 11.6371],
            [-14.2787, 11.6651],
            [-14.5209, 11.5129],
            [-14.6868, 11.5107],
            [-14.8282, 11.3348],
            [-15.021, 10.9675],
          ],
        ],
      },
      properties: {
        NAME: "Guinea-Bissau",
        NAME_LONG: "Guinea-Bissau",
        ADM0_A3: "GNB",
        ISO_A2: "GW",
        ISO_A3: "GNB",
        WB_A2: "GW",
        WB_A3: "GNB",
      },
      id: 88,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [11.3221, 2.1658],
              [11.3363, 0.9992],
              [10.0294, 1.0035],
              [9.9086, 0.9351],
              [9.8044, 0.9984],
              [9.7551, 1.1059],
              [9.5843, 1.043],
              [9.4076, 1.2838],
              [9.5642, 1.5034],
              [9.6175, 1.6715],
              [9.7991, 1.9011],
              [9.7655, 2.0854],
              [9.7996, 2.3417],
              [9.8109, 2.2649],
              [9.991, 2.1656],
              [11.3221, 2.1658],
            ],
          ],
          [
            [
              [8.7682, 3.7517],
              [8.9227, 3.7507],
              [8.9575, 3.643],
              [8.6955, 3.2086],
              [8.4502, 3.2729],
              [8.4739, 3.4631],
              [8.5628, 3.4565],
              [8.6279, 3.6483],
              [8.7682, 3.7517],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Eq. Guinea",
        NAME_LONG: "Equatorial Guinea",
        ADM0_A3: "GNQ",
        ISO_A2: "GQ",
        ISO_A3: "GNQ",
        WB_A2: "GQ",
        WB_A3: "GNQ",
      },
      id: 89,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [23.9014, 35.5272],
              [24.0447, 35.5277],
              [24.3401, 35.3483],
              [24.721, 35.4272],
              [24.9712, 35.4245],
              [25.0553, 35.3552],
              [25.376, 35.3432],
              [25.4819, 35.2974],
              [25.7664, 35.3357],
              [25.7127, 35.1828],
              [25.8303, 35.1224],
              [26.0674, 35.2333],
              [26.3118, 35.1824],
              [26.241, 35.039],
              [25.6012, 35.0136],
              [25.0974, 34.9379],
              [24.7385, 34.9316],
              [24.7075, 35.0961],
              [24.4021, 35.1911],
              [23.8267, 35.2526],
              [23.5872, 35.2395],
              [23.5735, 35.4348],
              [23.9014, 35.5272],
            ],
          ],
          [
            [
              [28.2398, 36.4325],
              [28.1305, 36.1855],
              [27.7804, 35.89],
              [27.7007, 36.1634],
              [27.8997, 36.3402],
              [28.2398, 36.4325],
            ],
          ],
          [
            [
              [25.5951, 37.0997],
              [25.4476, 36.9248],
              [25.3418, 37.0767],
              [25.5337, 37.2007],
              [25.5951, 37.0997],
            ],
          ],
          [
            [
              [20.5718, 38.4739],
              [20.7977, 38.1313],
              [20.7184, 38.0663],
              [20.4729, 38.1821],
              [20.5718, 38.4739],
            ],
          ],
          [
            [
              [26.0663, 38.5832],
              [26.1636, 38.3026],
              [25.9749, 38.166],
              [25.8675, 38.2597],
              [25.989, 38.3793],
              [25.8433, 38.5153],
              [26.0663, 38.5832],
            ],
          ],
          [
            [
              [23.4422, 38.8983],
              [23.62, 38.7655],
              [23.8576, 38.6825],
              [24.0731, 38.6794],
              [24.2161, 38.5395],
              [24.1851, 38.4076],
              [24.327, 38.1859],
              [24.5877, 38.1592],
              [24.5521, 37.9782],
              [24.3718, 37.9719],
              [24.0491, 38.3945],
              [23.6385, 38.4155],
              [23.6355, 38.5286],
              [23.2023, 38.8299],
              [22.9694, 38.8891],
              [23.2991, 39.0425],
              [23.4422, 38.8983],
            ],
          ],
          [
            [
              [26.4114, 39.3209],
              [26.6123, 39.0493],
              [26.4242, 38.9674],
              [26.1657, 39.0224],
              [25.8889, 39.1447],
              [25.8587, 39.2592],
              [26.343, 39.383],
              [26.4114, 39.3209],
            ],
          ],
          [
            [
              [25.4368, 40.0225],
              [25.2953, 39.8077],
              [25.0674, 39.8581],
              [25.0454, 39.9847],
              [25.4368, 40.0225],
            ],
          ],
          [
            [
              [24.7824, 40.6202],
              [24.6423, 40.5706],
              [24.512, 40.6668],
              [24.6423, 40.8039],
              [24.7824, 40.6202],
            ],
          ],
          [
            [
              [26.3334, 41.713],
              [26.5963, 41.5975],
              [26.6366, 41.3785],
              [26.3317, 41.2541],
              [26.3591, 40.9647],
              [26.044, 40.7385],
              [25.9186, 40.853],
              [25.5378, 40.8704],
              [25.2997, 40.9518],
              [24.9898, 40.9421],
              [24.802, 40.8535],
              [24.4155, 40.9455],
              [24.3407, 40.8313],
              [24.1101, 40.7279],
              [23.8093, 40.7804],
              [23.6917, 40.6954],
              [23.8776, 40.5602],
              [23.8682, 40.4135],
              [23.6997, 40.3264],
              [23.7786, 40.2027],
              [23.9912, 40.1187],
              [23.8241, 40.0219],
              [23.6755, 40.2179],
              [23.3983, 40.2777],
              [23.2991, 40.2354],
              [23.0291, 40.3258],
              [22.7109, 40.5116],
              [22.5525, 40.1666],
              [22.5713, 40.0543],
              [22.8587, 39.7733],
              [22.9251, 39.5995],
              [23.1018, 39.4974],
              [23.3423, 39.1926],
              [23.0205, 39.3175],
              [22.8262, 39.2213],
              [23.0233, 39.0084],
              [22.8465, 38.9264],
              [22.7695, 38.7848],
              [23.2049, 38.6827],
              [23.4185, 38.5043],
              [23.6013, 38.4739],
              [23.594, 38.4261],
              [23.71, 38.3367],
              [23.951, 38.2909],
              [24.0669, 37.8644],
              [24.0721, 37.6895],
              [23.7645, 37.816],
              [23.6802, 37.9459],
              [23.45, 38.0214],
              [23.0941, 37.911],
              [23.1824, 37.8091],
              [23.1686, 37.6142],
              [23.3743, 37.5559],
              [23.4465, 37.4146],
              [23.132, 37.4486],
              [22.9639, 37.5218],
              [22.7232, 37.5417],
              [22.758, 37.3983],
              [22.9998, 37.0333],
              [23.0956, 36.807],
              [23.0806, 36.5793],
              [22.9724, 36.5202],
              [22.7651, 36.8042],
              [22.5335, 36.7072],
              [22.4699, 36.4523],
              [22.3545, 36.5119],
              [22.3706, 36.6736],
              [22.1532, 37.0212],
              [21.9373, 36.98],
              [21.9451, 36.7899],
              [21.7074, 36.818],
              [21.7026, 36.9573],
              [21.5676, 37.1563],
              [21.6823, 37.2839],
              [21.55, 37.5615],
              [21.2987, 37.6726],
              [21.2953, 37.7717],
              [21.1221, 37.8374],
              [21.3259, 38.0214],
              [21.3757, 38.2043],
              [21.603, 38.1518],
              [21.7642, 38.2958],
              [21.9559, 38.3367],
              [22.2266, 38.1859],
              [22.6804, 38.0666],
              [22.8672, 37.9395],
              [22.9429, 38.0835],
              [23.1096, 38.2101],
              [22.6914, 38.3222],
              [22.5457, 38.3026],
              [21.9876, 38.4106],
              [21.7625, 38.3284],
              [21.5354, 38.314],
              [21.3699, 38.4082],
              [21.1556, 38.3039],
              [21.0247, 38.6085],
              [20.7645, 38.8918],
              [21.1599, 38.9977],
              [20.8939, 39.0772],
              [20.6865, 39.0725],
              [20.2928, 39.3334],
              [19.9998, 39.6935],
              [20.1999, 39.6401],
              [20.2995, 39.7282],
              [20.318, 39.9184],
              [20.4328, 40.0638],
              [20.6402, 40.0901],
              [20.8267, 40.4648],
              [21.0367, 40.6397],
              [20.9653, 40.8494],
              [21.5816, 40.8663],
              [21.737, 40.9192],
              [21.9091, 41.0975],
              [22.1832, 41.1599],
              [22.4218, 41.1146],
              [22.7272, 41.1658],
              [22.7513, 41.3152],
              [22.917, 41.3358],
              [23.1152, 41.3127],
              [23.2877, 41.3982],
              [23.6124, 41.371],
              [24.0473, 41.5257],
              [24.5309, 41.5475],
              [24.5958, 41.4298],
              [24.8864, 41.4006],
              [25.2857, 41.2394],
              [25.5515, 41.3157],
              [25.882, 41.304],
              [26.1209, 41.3578],
              [26.1645, 41.5177],
              [26.0481, 41.6891],
              [26.3334, 41.713],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Greece",
        NAME_LONG: "Greece",
        ADM0_A3: "GRC",
        ISO_A2: "GR",
        ISO_A3: "GRC",
        WB_A2: "GR",
        WB_A3: "GRC",
      },
      id: 90,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-43.6225, 60.1251],
              [-43.5901, 59.923],
              [-43.8998, 59.9922],
              [-44.0733, 60.1763],
              [-43.6225, 60.1251],
            ],
          ],
          [
            [
              [-37.3969, 65.8232],
              [-37.3131, 65.6793],
              [-37.7379, 65.5694],
              [-37.9498, 65.6241],
              [-37.9543, 65.7964],
              [-37.6412, 65.905],
              [-37.3969, 65.8232],
            ],
          ],
          [
            [
              [-51.0048, 69.9205],
              [-50.6676, 69.8293],
              [-50.9715, 69.7195],
              [-50.9511, 69.5594],
              [-51.2454, 69.5383],
              [-51.3406, 69.743],
              [-51.0048, 69.9205],
            ],
          ],
          [
            [
              [-52.7281, 69.9287],
              [-51.9982, 69.8051],
              [-51.8364, 69.6318],
              [-52.1416, 69.4773],
              [-53.5874, 69.2391],
              [-54.1485, 69.3378],
              [-53.7987, 69.4263],
              [-53.8811, 69.5611],
              [-54.7374, 69.5891],
              [-54.9927, 69.702],
              [-54.7668, 69.9695],
              [-54.8292, 70.2008],
              [-54.453, 70.3133],
              [-53.9034, 70.2904],
              [-53.266, 70.2023],
              [-52.7281, 69.9287],
            ],
          ],
          [
            [
              [-25.478, 70.7712],
              [-25.3011, 70.6573],
              [-26.2818, 70.5849],
              [-26.5751, 70.5238],
              [-28.0677, 70.4307],
              [-27.9273, 70.6383],
              [-27.6781, 70.6541],
              [-27.0675, 70.8935],
              [-26.6625, 70.8965],
              [-25.7038, 71.0858],
              [-25.4101, 70.9248],
              [-25.478, 70.7712],
            ],
          ],
          [
            [
              [-53.5124, 71.0377],
              [-53.9857, 71.1177],
              [-53.6118, 71.3217],
              [-53.3913, 71.1549],
              [-53.5124, 71.0377],
            ],
          ],
          [
            [
              [-52.6616, 71.1684],
              [-53.1197, 71.2128],
              [-52.9718, 71.3872],
              [-52.4091, 71.3537],
              [-52.6616, 71.1684],
            ],
          ],
          [
            [
              [-54.9779, 72.8047],
              [-55.5289, 72.5552],
              [-55.6072, 72.759],
              [-54.9779, 72.8047],
            ],
          ],
          [
            [
              [-23.9304, 72.8815],
              [-23.1468, 72.8402],
              [-22.7175, 72.6347],
              [-22.6752, 72.3616],
              [-22.0453, 72.2717],
              [-22.5249, 72.1348],
              [-23.0651, 72.31],
              [-23.9056, 72.4661],
              [-24.3518, 72.5913],
              [-24.4853, 72.8266],
              [-23.9304, 72.8815],
            ],
          ],
          [
            [
              [-23.2383, 73.0753],
              [-21.9007, 72.916],
              [-22.654, 72.6981],
              [-23.1633, 72.8779],
              [-24.5262, 72.9017],
              [-24.5365, 72.9919],
              [-23.2383, 73.0753],
            ],
          ],
          [
            [
              [-24.0073, 73.3608],
              [-23.2302, 73.2511],
              [-22.9807, 73.1071],
              [-24.4675, 73.0361],
              [-25.7073, 73.1847],
              [-25.2435, 73.4111],
              [-24.4601, 73.425],
              [-24.0073, 73.3608],
            ],
          ],
          [
            [
              [-20.9424, 74.4304],
              [-20.5476, 74.4099],
              [-20.1615, 74.1688],
              [-21.0255, 74.0963],
              [-21.9329, 74.2077],
              [-21.84, 74.3512],
              [-21.2471, 74.4571],
              [-20.9424, 74.4304],
            ],
          ],
          [
            [
              [-20.1764, 74.9039],
              [-19.7411, 74.8813],
              [-20.1383, 74.6993],
              [-20.6807, 74.8209],
              [-20.1764, 74.9039],
            ],
          ],
          [
            [
              [-17.8029, 75.3143],
              [-18.1984, 75.2416],
              [-17.8134, 75.0889],
              [-18.4479, 74.9853],
              [-18.9276, 75.0468],
              [-18.7973, 75.3362],
              [-17.8029, 75.3143],
            ],
          ],
          [
            [
              [-17.3144, 79.9783],
              [-17.8363, 79.7425],
              [-18.4852, 79.6861],
              [-19.3575, 79.8707],
              [-18.5776, 80.017],
              [-17.7408, 80.0912],
              [-17.3144, 79.9783],
            ],
          ],
          [
            [
              [-51.2375, 81.9885],
              [-52.1052, 81.9954],
              [-53.1901, 82.1124],
              [-52.4652, 82.1999],
              [-51.2375, 81.9885],
            ],
          ],
          [
            [
              [-45.9884, 82.6405],
              [-44.4221, 82.3847],
              [-45.0704, 82.2226],
              [-46.876, 82.36],
              [-47.6981, 82.6438],
              [-45.9884, 82.6405],
            ],
          ],
          [
            [
              [-32.2079, 83.6135],
              [-30.6471, 83.6018],
              [-25.6496, 83.2976],
              [-26.0754, 83.2085],
              [-30.7364, 83.1371],
              [-27.381, 83.0606],
              [-25.2214, 83.1679],
              [-24.7537, 82.9983],
              [-23.6622, 82.8383],
              [-22.5654, 82.7965],
              [-21.3185, 82.6055],
              [-22.4373, 82.34],
              [-23.8867, 82.2789],
              [-29.8523, 82.1393],
              [-29.9432, 82.0854],
              [-31.774, 81.9614],
              [-30.8033, 81.8653],
              [-27.762, 82.0624],
              [-24.6199, 81.9875],
              [-24.7062, 81.7812],
              [-26.3148, 81.6168],
              [-25.6169, 81.561],
              [-23.3077, 81.7925],
              [-23.1954, 82.0223],
              [-21.3884, 82.0834],
              [-21.0256, 81.7716],
              [-21.2568, 81.5175],
              [-22.2711, 81.1504],
              [-21.3628, 81.2004],
              [-19.3862, 81.6352],
              [-19.2463, 81.5548],
              [-17.1468, 81.4134],
              [-16.4518, 81.6397],
              [-16.4852, 81.7495],
              [-15.2067, 81.8148],
              [-13.9205, 81.8122],
              [-12.0277, 81.6373],
              [-11.6386, 81.3884],
              [-12.5382, 81.187],
              [-14.2037, 80.9144],
              [-14.2949, 80.7843],
              [-15.4204, 80.6484],
              [-17.063, 80.6628],
              [-18.5939, 80.5734],
              [-18.0551, 80.4918],
              [-16.8962, 80.5837],
              [-15.7892, 80.4047],
              [-16.4473, 80.2193],
              [-17.8729, 80.1611],
              [-19.4744, 80.2602],
              [-20.4231, 79.8396],
              [-19.2078, 79.7085],
              [-19.5659, 79.3859],
              [-18.9379, 79.2615],
              [-18.9552, 79.149],
              [-19.9106, 79.0183],
              [-19.9259, 78.8287],
              [-20.979, 78.7668],
              [-20.8898, 78.5644],
              [-21.2997, 78.2698],
              [-21.3223, 78.0768],
              [-21.63, 77.9981],
              [-21.3739, 77.7768],
              [-20.7606, 77.9706],
              [-18.9792, 77.6168],
              [-19.4118, 77.5429],
              [-20.0204, 77.6925],
              [-20.7748, 77.617],
              [-20.2301, 77.3725],
              [-19.0825, 77.3184],
              [-18.3245, 77.2085],
              [-18.2659, 76.9206],
              [-18.5022, 76.7487],
              [-19.8951, 76.9254],
              [-20.5134, 76.9457],
              [-21.9355, 76.6227],
              [-21.5673, 76.3847],
              [-20.4891, 76.2236],
              [-19.9377, 76.258],
              [-19.6724, 76.1273],
              [-20.2694, 75.9923],
              [-19.7494, 75.889],
              [-19.3815, 75.5413],
              [-19.4272, 75.2255],
              [-19.8798, 75.1498],
              [-20.1687, 75.3411],
              [-20.6678, 75.2932],
              [-20.7582, 74.8313],
              [-20.5211, 74.6843],
              [-20.0715, 74.6632],
              [-18.9748, 74.4844],
              [-19.3744, 74.2668],
              [-20.2007, 74.2798],
              [-20.3573, 74.4453],
              [-21.255, 74.4742],
              [-21.7716, 74.4387],
              [-22.0516, 74.3205],
              [-22.2294, 74.1013],
              [-21.5901, 74.0577],
              [-20.6886, 73.8865],
              [-20.483, 73.7589],
              [-20.5433, 73.449],
              [-21.2809, 73.4572],
              [-22.3141, 73.2504],
              [-23.1142, 73.3542],
              [-24.4095, 73.7278],
              [-24.3754, 73.5596],
              [-25.2922, 73.4735],
              [-25.7049, 73.2722],
              [-25.7206, 73.1381],
              [-24.9843, 73.0258],
              [-25.5228, 72.8402],
              [-24.8484, 72.7241],
              [-24.342, 72.3317],
              [-23.7612, 72.2445],
              [-23.5779, 72.1472],
              [-22.5034, 71.9008],
              [-23.06, 71.7121],
              [-22.3051, 71.6972],
              [-22.5314, 71.4645],
              [-21.9357, 71.4866],
              [-21.668, 71.4014],
              [-21.785, 71.0861],
              [-21.5724, 70.9558],
              [-21.7301, 70.579],
              [-22.0229, 70.4995],
              [-22.6609, 70.4385],
              [-23.3454, 70.4406],
              [-23.9298, 70.6132],
              [-24.183, 70.805],
              [-24.2649, 71.0814],
              [-24.6694, 71.272],
              [-25.122, 71.3115],
              [-25.5756, 71.5204],
              [-26.076, 71.5864],
              [-26.7851, 71.5465],
              [-27.8817, 71.9788],
              [-27.968, 71.8712],
              [-27.3314, 71.7188],
              [-27.0344, 71.5312],
              [-25.7274, 71.4744],
              [-25.4101, 71.3537],
              [-25.6019, 71.1696],
              [-26.4256, 70.9746],
              [-27.5062, 70.9421],
              [-27.7897, 71.0225],
              [-28.0105, 70.7398],
              [-28.3103, 70.5639],
              [-28.2329, 70.372],
              [-26.7433, 70.4841],
              [-26.3213, 70.3798],
              [-26.8454, 70.2608],
              [-26.3262, 70.1983],
              [-25.3053, 70.4132],
              [-24.4847, 70.247],
              [-23.5598, 70.1129],
              [-22.3717, 70.1151],
              [-22.38, 69.9713],
              [-22.8813, 69.9627],
              [-23.703, 69.7181],
              [-23.8142, 69.5123],
              [-24.0988, 69.5335],
              [-24.6151, 69.2801],
              [-25.6677, 68.8704],
              [-26.2352, 68.7926],
              [-26.4167, 68.6589],
              [-27.1822, 68.6093],
              [-27.3144, 68.5351],
              [-27.9145, 68.5128],
              [-28.0919, 68.4438],
              [-29.0067, 68.3476],
              [-29.5113, 68.2217],
              [-29.9919, 68.3798],
              [-30.1478, 68.2379],
              [-30.0082, 68.126],
              [-30.9127, 68.0451],
              [-31.4111, 68.1183],
              [-32.1254, 68.0507],
              [-32.098, 67.9063],
              [-32.5192, 67.8722],
              [-33.1867, 67.5577],
              [-33.7509, 66.9883],
              [-33.9483, 66.9269],
              [-34.2565, 66.5848],
              [-34.6433, 66.3804],
              [-35.2164, 66.241],
              [-35.6552, 66.2752],
              [-35.5863, 66.1113],
              [-36.0738, 65.9355],
              [-36.3888, 66.0155],
              [-36.8264, 65.9196],
              [-37.2101, 65.7753],
              [-37.642, 65.9257],
              [-37.7979, 66.0487],
              [-37.2154, 66.3462],
              [-37.7587, 66.2956],
              [-37.9679, 66.118],
              [-37.9852, 65.9499],
              [-38.2206, 65.6464],
              [-38.6693, 65.59],
              [-39.8745, 65.5059],
              [-39.8576, 65.2358],
              [-40.1229, 65.1662],
              [-40.1843, 65.0419],
              [-41.0776, 65.0677],
              [-41.0546, 64.8774],
              [-40.6291, 64.6921],
              [-40.5611, 64.515],
              [-40.3583, 64.359],
              [-40.8362, 64.3803],
              [-41.0777, 64.3111],
              [-41.5249, 64.3261],
              [-41.3873, 64.1785],
              [-40.712, 64.158],
              [-40.5609, 64.1069],
              [-40.6141, 63.8076],
              [-40.8795, 63.6577],
              [-41.1691, 63.3906],
              [-41.9071, 63.4635],
              [-41.4729, 63.2258],
              [-41.7501, 62.8439],
              [-42.0177, 62.7968],
              [-42.4231, 62.8158],
              [-42.7082, 62.6929],
              [-42.3837, 62.5715],
              [-42.2537, 62.4374],
              [-42.2675, 62.2466],
              [-42.4181, 62.0129],
              [-42.1172, 62.0067],
              [-42.5275, 61.5338],
              [-42.5001, 61.3618],
              [-42.7075, 61.2918],
              [-42.6725, 61.0251],
              [-42.8851, 60.8454],
              [-42.8263, 60.6113],
              [-43.2003, 60.5533],
              [-43.3154, 60.4375],
              [-43.0898, 60.2589],
              [-43.1382, 60.0808],
              [-43.9906, 60.1909],
              [-44.4813, 60.1237],
              [-44.5902, 59.9891],
              [-44.9748, 60.0397],
              [-45.1873, 60.2126],
              [-44.9264, 60.3271],
              [-44.9389, 60.4836],
              [-45.2142, 60.439],
              [-45.3937, 60.5163],
              [-45.9816, 60.5804],
              [-45.7215, 60.7387],
              [-46.0006, 60.8029],
              [-46.0328, 60.9139],
              [-46.3757, 61.0847],
              [-46.6317, 60.9926],
              [-46.8623, 60.8001],
              [-47.0565, 60.8608],
              [-47.4383, 60.8274],
              [-47.9321, 60.8417],
              [-48.0734, 61.0884],
              [-48.6191, 61.2121],
              [-48.5458, 61.3813],
              [-48.8529, 61.4028],
              [-49.2411, 61.6076],
              [-49.4349, 61.8474],
              [-49.3104, 61.9962],
              [-49.6958, 62.2668],
              [-50.0138, 62.3204],
              [-50.3077, 62.4913],
              [-50.1785, 62.7664],
              [-50.563, 62.9809],
              [-50.593, 63.2303],
              [-51.0563, 63.1848],
              [-51.1273, 63.4956],
              [-51.5392, 63.685],
              [-51.4029, 63.8127],
              [-51.4399, 64.0823],
              [-51.1347, 64.2077],
              [-50.8995, 64.4067],
              [-50.6245, 64.4667],
              [-50.7848, 64.6024],
              [-50.4803, 64.7129],
              [-50.6551, 64.8216],
              [-50.9097, 64.685],
              [-51.2526, 64.7602],
              [-51.5051, 64.4727],
              [-51.8351, 64.225],
              [-52.1426, 64.2222],
              [-52.1562, 64.6788],
              [-51.8793, 64.8371],
              [-52.2786, 65.0788],
              [-52.1986, 65.2846],
              [-52.2729, 65.4465],
              [-52.7917, 65.5347],
              [-52.7556, 65.6723],
              [-53.1904, 65.6752],
              [-53.0714, 65.8103],
              [-53.4554, 65.9608],
              [-53.3537, 66.1121],
              [-53.6953, 66.3588],
              [-53.4269, 66.6453],
              [-52.6675, 66.693],
              [-53.0958, 66.932],
              [-53.647, 66.9116],
              [-53.9649, 67.1044],
              [-53.8057, 67.4186],
              [-53.3788, 67.5323],
              [-53.0477, 67.6958],
              [-52.4091, 67.7692],
              [-51.8641, 67.6359],
              [-51.4448, 67.7471],
              [-52.824, 67.7965],
              [-53.5767, 67.5189],
              [-53.7353, 67.6133],
              [-53.6877, 67.8118],
              [-53.2004, 67.9678],
              [-53.1359, 68.1091],
              [-52.5829, 68.1911],
              [-51.9315, 68.0468],
              [-51.2171, 68.0709],
              [-51.2233, 68.2748],
              [-51.6481, 68.2746],
              [-52.4451, 68.1815],
              [-53.0885, 68.3179],
              [-52.6164, 68.5259],
              [-51.9647, 68.5876],
              [-51.5688, 68.5239],
              [-50.9291, 68.6741],
              [-51.1019, 68.8662],
              [-51.0799, 69.1328],
              [-50.8875, 69.1745],
              [-50.8472, 69.6263],
              [-50.3329, 69.89],
              [-50.9391, 69.9636],
              [-52.3295, 70.0536],
              [-52.61, 70.2171],
              [-53.2318, 70.3531],
              [-54.0187, 70.4132],
              [-54.5881, 70.6826],
              [-53.9796, 70.8297],
              [-53.434, 70.7527],
              [-52.7906, 70.7568],
              [-52.4503, 70.7026],
              [-51.5494, 70.4316],
              [-51.1898, 70.8869],
              [-51.9149, 71.0214],
              [-51.5358, 71.3127],
              [-52.0494, 71.2473],
              [-52.2577, 71.3655],
              [-52.9923, 71.4213],
              [-52.7009, 71.5259],
              [-52.663, 71.6798],
              [-53.6789, 71.7425],
              [-53.9518, 71.4378],
              [-54.6252, 71.3513],
              [-54.9808, 71.4362],
              [-55.5145, 71.4492],
              [-55.8475, 71.722],
              [-55.3701, 71.826],
              [-55.5517, 72.0382],
              [-54.8835, 72.2512],
              [-55.6513, 72.4504],
              [-54.9683, 72.517],
              [-54.6503, 72.845],
              [-54.9814, 72.999],
              [-55.4657, 73.0729],
              [-55.2691, 73.3866],
              [-55.8431, 73.6831],
              [-55.9455, 73.8612],
              [-56.3313, 74.0445],
              [-56.2569, 74.2379],
              [-56.6034, 74.2448],
              [-56.6136, 74.4509],
              [-56.2419, 74.4977],
              [-56.8774, 74.7116],
              [-56.846, 74.8112],
              [-58.1315, 75.1082],
              [-58.6595, 75.3773],
              [-58.1667, 75.5004],
              [-58.5572, 75.6541],
              [-59.0416, 75.7014],
              [-59.2722, 75.8801],
              [-60.1242, 76.0319],
              [-60.8321, 76.0336],
              [-60.8725, 76.1581],
              [-62.3976, 76.2446],
              [-63.4769, 76.3769],
              [-63.7233, 76.1845],
              [-64.3812, 76.2603],
              [-65.3545, 76.0268],
              [-65.8889, 76.1025],
              [-66.4137, 76.0861],
              [-67.0471, 76.267],
              [-67.3078, 76.164],
              [-66.4871, 75.9474],
              [-68.4325, 76.0794],
              [-69.5773, 76.4376],
              [-68.7328, 76.5862],
              [-68.9103, 76.6649],
              [-70.6601, 76.802],
              [-71.3456, 76.9918],
              [-70.9033, 77.1914],
              [-69.0619, 77.2573],
              [-68.3695, 77.3769],
              [-66.7728, 77.3564],
              [-66.0534, 77.4935],
              [-66.6134, 77.6739],
              [-67.1409, 77.6723],
              [-67.5917, 77.5244],
              [-68.6384, 77.5097],
              [-69.2226, 77.4529],
              [-70.2554, 77.5574],
              [-71.3128, 77.7698],
              [-72.7843, 78.1076],
              [-72.5902, 78.5212],
              [-71.5546, 78.6435],
              [-69.4386, 78.8088],
              [-69.0339, 78.98],
              [-68.0402, 79.0768],
              [-66.6789, 79.1444],
              [-66.1171, 79.1028],
              [-64.8599, 79.5078],
              [-65.1286, 79.8503],
              [-64.926, 80.0727],
              [-67.0501, 80.0612],
              [-67.4624, 80.1847],
              [-67.4554, 80.3425],
              [-66.7584, 80.5524],
              [-65.1247, 80.7797],
              [-64.7535, 80.9962],
              [-63.9294, 81.0569],
              [-62.9768, 81.2283],
              [-61.5015, 81.1079],
              [-60.9395, 81.1846],
              [-61.2963, 81.371],
              [-60.7869, 81.5089],
              [-61.4543, 81.745],
              [-61.2811, 81.8151],
              [-58.6109, 82.1052],
              [-57.0296, 82.1888],
              [-54.4407, 82.3727],
              [-53.0238, 82.008],
              [-50.8907, 81.8781],
              [-49.4645, 81.9472],
              [-50.5813, 82.154],
              [-51.1072, 82.5099],
              [-49.0113, 82.4545],
              [-48.7353, 82.3581],
              [-46.0082, 82.0362],
              [-45.1561, 81.7899],
              [-44.3027, 81.8142],
              [-44.8848, 81.9794],
              [-44.3988, 82.1052],
              [-44.7023, 82.2578],
              [-43.7272, 82.4069],
              [-45.6627, 82.7261],
              [-44.9614, 82.7689],
              [-41.8185, 82.7529],
              [-40.6308, 82.5751],
              [-39.9531, 82.6931],
              [-40.9766, 82.7744],
              [-46.5575, 82.8929],
              [-46.43, 83.0621],
              [-45.1538, 83.1623],
              [-43.0395, 83.2117],
              [-41.7407, 83.1946],
              [-40.6903, 82.9865],
              [-39.4376, 82.9618],
              [-37.6283, 83.1536],
              [-38.8617, 83.2567],
              [-38.6903, 83.4255],
              [-36.7386, 83.538],
              [-35.2436, 83.5372],
              [-32.2079, 83.6135],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Greenland",
        NAME_LONG: "Greenland",
        ADM0_A3: "GRL",
        ISO_A2: "GL",
        ISO_A3: "GRL",
        WB_A2: "GL",
        WB_A3: "GRL",
      },
      id: 92,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.1605, 17.8143],
            [-89.1492, 17.0363],
            [-89.2365, 15.8939],
            [-88.914, 15.8939],
            [-88.626, 15.7539],
            [-88.4479, 15.8587],
            [-88.2209, 15.7257],
            [-88.399, 15.5728],
            [-88.9734, 15.1404],
            [-89.1728, 15.0422],
            [-89.2277, 14.8346],
            [-89.1445, 14.7083],
            [-89.3616, 14.4155],
            [-89.5699, 14.412],
            [-89.5309, 14.2256],
            [-89.8801, 14.0427],
            [-90.099, 13.8585],
            [-90.093, 13.7289],
            [-90.4012, 13.8586],
            [-90.7073, 13.9289],
            [-90.9103, 13.9111],
            [-91.3165, 13.9557],
            [-91.7626, 14.1802],
            [-92.2462, 14.5463],
            [-92.1617, 14.7023],
            [-92.1542, 14.9959],
            [-92.0738, 15.0737],
            [-92.2047, 15.2895],
            [-91.7519, 16.0463],
            [-91.7238, 16.0688],
            [-90.4857, 16.0707],
            [-90.3988, 16.3476],
            [-90.6482, 16.5296],
            [-90.7353, 16.7466],
            [-91.0669, 16.9182],
            [-91.2904, 17.1891],
            [-90.992, 17.2519],
            [-90.9909, 17.802],
            [-90.2597, 17.8153],
            [-89.1605, 17.8143],
          ],
        ],
      },
      properties: {
        NAME: "Guatemala",
        NAME_LONG: "Guatemala",
        ADM0_A3: "GTM",
        ISO_A2: "GT",
        ISO_A3: "GTM",
        WB_A2: "GT",
        WB_A3: "GTM",
      },
      id: 93,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.2477, 5.4849],
            [-57.3241, 5.3386],
            [-57.3152, 5.0261],
            [-57.7205, 4.9898],
            [-57.9185, 4.8298],
            [-57.8374, 4.6509],
            [-57.9527, 4.4097],
            [-57.965, 4.282],
            [-58.0677, 4.1511],
            [-58.0321, 3.9877],
            [-57.8539, 3.7667],
            [-57.8298, 3.6624],
            [-57.644, 3.5167],
            [-57.5651, 3.3498],
            [-57.2836, 3.3438],
            [-57.2749, 3.1343],
            [-57.1746, 2.819],
            [-57.0272, 2.6482],
            [-56.9941, 2.5049],
            [-56.7052, 2.0296],
            [-56.4818, 1.9416],
            [-56.8065, 1.8736],
            [-57.0112, 1.9186],
            [-57.1045, 2.0215],
            [-57.4295, 1.9003],
            [-57.5612, 1.709],
            [-57.7908, 1.7049],
            [-57.991, 1.6504],
            [-58.0272, 1.5169],
            [-58.3319, 1.5934],
            [-58.5137, 1.4389],
            [-58.5189, 1.2673],
            [-58.6826, 1.2825],
            [-58.8979, 1.2178],
            [-59.0111, 1.3276],
            [-59.2421, 1.3778],
            [-59.5727, 1.7272],
            [-59.6825, 1.7656],
            [-59.7645, 1.9205],
            [-59.7417, 2.2638],
            [-59.9031, 2.3596],
            [-60.0001, 2.694],
            [-59.9594, 3.0665],
            [-59.8285, 3.3834],
            [-59.8377, 3.6093],
            [-59.6801, 3.7017],
            [-59.5292, 3.9319],
            [-59.7385, 4.2028],
            [-59.6867, 4.3812],
            [-60.0877, 4.6075],
            [-59.9831, 5.0859],
            [-60.2134, 5.2672],
            [-60.4564, 5.1747],
            [-60.7399, 5.2021],
            [-61.3796, 5.9053],
            [-61.2694, 6.1072],
            [-61.1216, 6.1867],
            [-61.139, 6.4248],
            [-61.2048, 6.5958],
            [-61.1298, 6.7158],
            [-60.9338, 6.7317],
            [-60.421, 6.9422],
            [-60.2923, 7.1053],
            [-60.6431, 7.2209],
            [-60.6018, 7.3301],
            [-60.7306, 7.5254],
            [-60.6013, 7.6339],
            [-60.5033, 7.8209],
            [-60.3748, 7.8236],
            [-60.2311, 7.9641],
            [-60.0195, 8.0603],
            [-59.9933, 8.1685],
            [-59.8156, 8.2878],
            [-60.021, 8.558],
            [-59.8094, 8.3547],
            [-59.7558, 8.4109],
            [-59.1652, 8.0587],
            [-58.7786, 7.6624],
            [-58.6632, 7.5852],
            [-58.4642, 7.3122],
            [-58.485, 7.0108],
            [-58.5507, 6.9471],
            [-58.6114, 6.6878],
            [-58.3984, 6.8834],
            [-58.0679, 6.8217],
            [-57.8919, 6.6964],
            [-57.5986, 6.4272],
            [-57.2898, 6.2347],
            [-57.1673, 6.0851],
            [-57.1373, 5.8821],
            [-57.1615, 5.6389],
            [-57.2477, 5.4849],
          ],
        ],
      },
      properties: {
        NAME: "Guyana",
        NAME_LONG: "Guyana",
        ADM0_A3: "GUY",
        ISO_A2: "GY",
        ISO_A3: "GUY",
        WB_A2: "GY",
        WB_A3: "GUY",
      },
      id: 95,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [114.2298, 22.5558],
            [114.3381, 22.4464],
            [114.2783, 22.2705],
            [114.111, 22.366],
            [114.0824, 22.5294],
            [114.2298, 22.5558],
          ],
        ],
      },
      properties: {
        NAME: "Hong Kong",
        NAME_LONG: "Hong Kong",
        ADM0_A3: "HKG",
        ISO_A2: "HK",
        ISO_A3: "HKG",
        WB_A2: "HK",
        WB_A3: "HKG",
      },
      id: 96,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1304, 14.997],
            [-83.3903, 15.031],
            [-83.8695, 14.7771],
            [-84.0236, 14.7583],
            [-84.4827, 14.6195],
            [-84.6993, 14.6747],
            [-84.7702, 14.8051],
            [-85.002, 14.7407],
            [-85.0296, 14.589],
            [-85.4317, 14.1328],
            [-85.8242, 13.8477],
            [-86.0967, 14.0441],
            [-86.4101, 13.749],
            [-86.5417, 13.7821],
            [-86.7662, 13.7453],
            [-86.7019, 13.3142],
            [-86.9108, 13.2396],
            [-86.9484, 13.0697],
            [-87.0847, 12.9959],
            [-87.314, 12.9816],
            [-87.6224, 13.423],
            [-87.8172, 13.4066],
            [-87.7126, 13.8005],
            [-87.8329, 13.9152],
            [-88.0236, 13.8912],
            [-88.0875, 13.9805],
            [-88.488, 13.8653],
            [-88.4954, 13.9751],
            [-88.7466, 14.0525],
            [-88.9572, 14.1852],
            [-89.0266, 14.3113],
            [-89.3616, 14.4155],
            [-89.1445, 14.7083],
            [-89.2277, 14.8346],
            [-89.1728, 15.0422],
            [-88.9734, 15.1404],
            [-88.399, 15.5728],
            [-88.2209, 15.7257],
            [-88.1189, 15.6959],
            [-87.9088, 15.8681],
            [-87.7222, 15.9229],
            [-87.5096, 15.7987],
            [-87.3451, 15.8503],
            [-86.8787, 15.7641],
            [-86.4706, 15.8042],
            [-86.3383, 15.7814],
            [-86.2071, 15.874],
            [-85.9949, 15.9007],
            [-85.9032, 15.9835],
            [-85.7032, 15.9765],
            [-85.4886, 15.8696],
            [-85.2543, 15.8841],
            [-84.9999, 15.9879],
            [-84.6766, 15.8762],
            [-84.6601, 15.7745],
            [-84.2946, 15.811],
            [-84.0245, 15.5351],
            [-84.0825, 15.3601],
            [-83.9051, 15.3835],
            [-83.7745, 15.2857],
            [-83.408, 15.2664],
            [-83.2538, 15.0446],
            [-83.1304, 14.997],
          ],
        ],
      },
      properties: {
        NAME: "Honduras",
        NAME_LONG: "Honduras",
        ADM0_A3: "HND",
        ISO_A2: "HN",
        ISO_A3: "HND",
        WB_A2: "HN",
        WB_A3: "HND",
      },
      id: 98,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [17.6533, 42.8909],
              [18.2241, 42.6281],
              [18.4374, 42.5592],
              [18.4964, 42.4163],
              [18.2175, 42.6201],
              [17.6533, 42.8909],
            ],
          ],
          [
            [
              [16.5153, 46.5017],
              [16.8377, 46.3819],
              [16.9748, 46.2106],
              [17.2486, 46.0802],
              [17.3453, 45.9557],
              [17.5911, 45.9362],
              [17.6565, 45.8454],
              [17.8898, 45.7921],
              [18.4117, 45.7432],
              [18.6743, 45.9104],
              [18.9013, 45.9312],
              [18.845, 45.8157],
              [18.9124, 45.6192],
              [19.1621, 45.285],
              [19.0823, 45.0849],
              [19.1317, 44.9532],
              [19.0158, 44.8656],
              [18.6167, 45.0977],
              [18.4661, 45.074],
              [18.2379, 45.1575],
              [17.9885, 45.1438],
              [17.8357, 45.0644],
              [17.1871, 45.1486],
              [16.9326, 45.2788],
              [16.7863, 45.1788],
              [16.4819, 45.1995],
              [16.3406, 45.0059],
              [16.213, 45.0315],
              [15.9975, 45.2185],
              [15.78, 45.1603],
              [15.7173, 44.7865],
              [15.8956, 44.7186],
              [16.1165, 44.5215],
              [16.2158, 44.2082],
              [16.6786, 43.8407],
              [16.7126, 43.7715],
              [17.0846, 43.5132],
              [17.2395, 43.4784],
              [17.2676, 43.3533],
              [17.4421, 43.1524],
              [17.5874, 43.0821],
              [17.5807, 42.9421],
              [17.3163, 43.1273],
              [17.0735, 43.239],
              [16.8806, 43.406],
              [16.6258, 43.449],
              [16.4338, 43.5498],
              [16.0985, 43.48],
              [15.9312, 43.6765],
              [15.4661, 43.9217],
              [15.1445, 44.1955],
              [15.308, 44.3544],
              [14.9014, 44.6902],
              [14.9216, 44.9588],
              [14.8274, 45.114],
              [14.5105, 45.2998],
              [14.3298, 45.354],
              [14.2326, 45.1494],
              [13.9727, 44.8266],
              [13.86, 44.8257],
              [13.6438, 45.0705],
              [13.5134, 45.4362],
              [13.5895, 45.4888],
              [13.889, 45.4236],
              [14.2187, 45.4972],
              [14.3728, 45.4778],
              [14.5561, 45.6567],
              [14.6685, 45.534],
              [14.9226, 45.5149],
              [15.1393, 45.43],
              [15.314, 45.4505],
              [15.2489, 45.7118],
              [15.4513, 45.8151],
              [15.6661, 45.8317],
              [15.6613, 46.2153],
              [16.1918, 46.3698],
              [16.3441, 46.547],
              [16.5153, 46.5017],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Croatia",
        NAME_LONG: "Croatia",
        ADM0_A3: "HRV",
        ISO_A2: "HR",
        ISO_A3: "HRV",
        WB_A2: "HR",
        WB_A3: "HRV",
      },
      id: 99,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-72.8205, 18.7007],
              [-73.2146, 18.8366],
              [-73.2008, 18.9687],
              [-72.8461, 18.8271],
              [-72.8205, 18.7007],
            ],
          ],
          [
            [
              [-71.7574, 19.7101],
              [-71.7039, 19.4146],
              [-71.7765, 19.3275],
              [-71.6391, 19.2121],
              [-71.7837, 18.9963],
              [-71.7188, 18.7842],
              [-71.8416, 18.618],
              [-72.0009, 18.5825],
              [-71.9122, 18.4307],
              [-71.7093, 18.3133],
              [-71.7762, 18.0393],
              [-71.9281, 18.1838],
              [-72.0718, 18.2375],
              [-72.4403, 18.2239],
              [-72.8859, 18.1414],
              [-73.3773, 18.2581],
              [-73.6542, 18.2527],
              [-73.8093, 18.1592],
              [-73.9678, 18.1414],
              [-74.1834, 18.2933],
              [-74.3309, 18.286],
              [-74.4892, 18.4294],
              [-74.4263, 18.6082],
              [-74.2559, 18.6703],
              [-73.6047, 18.4983],
              [-73.5267, 18.5235],
              [-72.755, 18.4294],
              [-72.6283, 18.5541],
              [-72.3538, 18.5301],
              [-72.3251, 18.6714],
              [-72.5495, 18.7856],
              [-72.8135, 19.0529],
              [-72.8034, 19.2319],
              [-72.7283, 19.4256],
              [-73.0189, 19.6007],
              [-73.2211, 19.6496],
              [-73.4095, 19.636],
              [-73.4681, 19.7248],
              [-73.3446, 19.8341],
              [-73.1437, 19.9203],
              [-72.776, 19.9439],
              [-72.3976, 19.807],
              [-72.3304, 19.7521],
              [-71.7574, 19.7101],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Haiti",
        NAME_LONG: "Haiti",
        ADM0_A3: "HTI",
        ISO_A2: "HT",
        ISO_A3: "HTI",
        WB_A2: "HT",
        WB_A3: "HTI",
      },
      id: 100,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.1328, 48.4048],
            [22.2718, 48.4035],
            [22.3571, 48.2431],
            [22.6001, 48.1011],
            [22.831, 48.0724],
            [22.8776, 47.9467],
            [22.7458, 47.825],
            [22.2617, 47.7158],
            [21.9889, 47.4929],
            [22.0016, 47.3938],
            [21.6328, 47.0227],
            [21.4836, 46.6849],
            [21.3162, 46.6166],
            [21.1645, 46.3183],
            [21.0515, 46.2361],
            [20.8196, 46.2717],
            [20.664, 46.1378],
            [20.2428, 46.1081],
            [19.9292, 46.1635],
            [19.5499, 46.1642],
            [19.2635, 45.9814],
            [18.9013, 45.9312],
            [18.6743, 45.9104],
            [18.4117, 45.7432],
            [17.8898, 45.7921],
            [17.6565, 45.8454],
            [17.5911, 45.9362],
            [17.3453, 45.9557],
            [17.2486, 46.0802],
            [16.9748, 46.2106],
            [16.8377, 46.3819],
            [16.5153, 46.5017],
            [16.3764, 46.6291],
            [16.272, 46.864],
            [16.094, 46.8628],
            [16.2749, 47.0043],
            [16.4372, 47.0318],
            [16.425, 47.3511],
            [16.688, 47.5226],
            [16.7975, 47.6755],
            [17.0548, 47.702],
            [17.0395, 47.8374],
            [17.1483, 48.0054],
            [17.3379, 47.9987],
            [17.472, 47.8888],
            [17.8257, 47.75],
            [18.2359, 47.7539],
            [18.7677, 47.8223],
            [18.8385, 48.04],
            [19.4282, 48.0859],
            [19.6232, 48.227],
            [19.8843, 48.1296],
            [20.2724, 48.2525],
            [20.4817, 48.5261],
            [20.7841, 48.5691],
            [21.1093, 48.4891],
            [21.4246, 48.5613],
            [21.7277, 48.3409],
            [22.1328, 48.4048],
          ],
        ],
      },
      properties: {
        NAME: "Hungary",
        NAME_LONG: "Hungary",
        ADM0_A3: "HUN",
        ISO_A2: "HU",
        ISO_A3: "HUN",
        WB_A2: "HU",
        WB_A3: "HUN",
      },
      id: 101,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [123.3716, -10.5717],
              [123.2092, -10.8131],
              [122.8418, -10.9211],
              [122.8125, -10.7784],
              [123.0492, -10.7114],
              [123.2251, -10.5436],
              [123.3716, -10.5717],
            ],
          ],
          [
            [
              [120.0117, -9.3576],
              [120.2439, -9.509],
              [120.3303, -9.6694],
              [120.4363, -9.6313],
              [120.5803, -9.735],
              [120.6697, -9.8852],
              [120.7925, -9.9609],
              [120.8267, -10.0912],
              [120.7112, -10.2067],
              [120.3916, -10.2631],
              [120.1556, -10.221],
              [119.9763, -9.9924],
              [119.4773, -9.7486],
              [119.4061, -9.7901],
              [119.0374, -9.6759],
              [118.9416, -9.5705],
              [119.0247, -9.4377],
              [119.1687, -9.3855],
              [119.6292, -9.3419],
              [119.8213, -9.3786],
              [120.0117, -9.3576],
            ],
          ],
          [
            [
              [125.0616, -9.4858],
              [124.9707, -9.6624],
              [124.7345, -9.8995],
              [124.4185, -10.1638],
              [124.1224, -10.1725],
              [124.0339, -10.2761],
              [123.8263, -10.3639],
              [123.5154, -10.3355],
              [123.5471, -10.1868],
              [123.7485, -10.0978],
              [123.5762, -10.0139],
              [123.6502, -9.8894],
              [123.6888, -9.6114],
              [124.03, -9.342],
              [124.1975, -9.3732],
              [124.3101, -9.4887],
              [124.4505, -9.1802],
              [124.9195, -8.962],
              [124.9479, -9.0785],
              [125.1624, -9.0606],
              [125.1425, -9.1891],
              [124.9745, -9.1919],
              [125.0616, -9.4858],
            ],
          ],
          [
            [
              [123.2879, -8.2464],
              [123.3211, -8.3849],
              [123.041, -8.4108],
              [123.1882, -8.235],
              [123.2879, -8.2464],
            ],
          ],
          [
            [
              [116.7432, -8.3918],
              [116.681, -8.5563],
              [116.5004, -8.8027],
              [116.5654, -8.8956],
              [116.2048, -8.92],
              [116.0021, -8.8989],
              [115.8286, -8.7918],
              [116.0603, -8.7139],
              [116.0279, -8.445],
              [116.3762, -8.2018],
              [116.6333, -8.2751],
              [116.7432, -8.3918],
            ],
          ],
          [
            [
              [123.9412, -8.2437],
              [123.7754, -8.3058],
              [123.7289, -8.3933],
              [123.3994, -8.586],
              [123.3576, -8.4113],
              [123.7982, -8.1799],
              [123.9412, -8.2437],
            ],
          ],
          [
            [
              [138.9045, -8.3517],
              [138.8941, -8.4055],
              [138.5584, -8.3666],
              [138.5642, -8.3073],
              [138.6888, -8.1813],
              [138.8314, -8.1856],
              [138.9045, -8.3517],
            ],
          ],
          [
            [
              [117.5537, -8.379],
              [117.4939, -8.1762],
              [117.6643, -8.1481],
              [117.5537, -8.379],
            ],
          ],
          [
            [
              [124.5687, -8.131],
              [124.665, -8.1697],
              [125.0925, -8.1616],
              [125.1233, -8.3523],
              [124.4182, -8.4646],
              [124.4507, -8.149],
              [124.5687, -8.131],
            ],
          ],
          [
            [
              [117.9712, -8.1004],
              [118.1497, -8.1345],
              [118.304, -8.3683],
              [118.4436, -8.2579],
              [118.9883, -8.309],
              [119.0511, -8.4705],
              [118.9915, -8.7551],
              [118.8984, -8.6884],
              [118.7466, -8.7763],
              [118.465, -8.865],
              [118.2993, -8.7579],
              [117.8905, -8.9368],
              [117.7378, -8.9098],
              [117.3341, -9.0594],
              [117.2261, -9.0238],
              [117.0097, -9.1073],
              [116.763, -9.0156],
              [116.7352, -8.8734],
              [116.8213, -8.7873],
              [116.7901, -8.5837],
              [117.1951, -8.3576],
              [117.3532, -8.4467],
              [117.5581, -8.4055],
              [117.6491, -8.5519],
              [117.7717, -8.6041],
              [117.8052, -8.72],
              [117.969, -8.7481],
              [118.0681, -8.6559],
              [118.2909, -8.6059],
              [118.0575, -8.4506],
              [118.0032, -8.4737],
              [117.7063, -8.2297],
              [117.7295, -8.1589],
              [117.9712, -8.1004],
            ],
          ],
          [
            [
              [122.9829, -8.1603],
              [123.0187, -8.3341],
              [122.9041, -8.3634],
              [122.8074, -8.4867],
              [122.8074, -8.6109],
              [122.4079, -8.7415],
              [122.1145, -8.7324],
              [121.7937, -8.884],
              [121.396, -8.7941],
              [121.3406, -8.9181],
              [121.1653, -8.8951],
              [120.9229, -8.9262],
              [120.5939, -8.7958],
              [120.2439, -8.8232],
              [120.131, -8.7708],
              [119.9758, -8.8139],
              [119.824, -8.7804],
              [119.8037, -8.5968],
              [119.9194, -8.4612],
              [120.1089, -8.3919],
              [120.277, -8.2692],
              [120.6546, -8.2617],
              [120.7446, -8.3298],
              [120.9761, -8.3375],
              [121.5095, -8.6021],
              [121.8347, -8.4737],
              [122.0256, -8.4998],
              [122.2884, -8.6339],
              [122.5007, -8.5763],
              [122.4942, -8.475],
              [122.7581, -8.361],
              [122.7957, -8.11],
              [122.9829, -8.1603],
            ],
          ],
          [
            [
              [115.5025, -8.1726],
              [115.7142, -8.3752],
              [115.6048, -8.5011],
              [115.3226, -8.6238],
              [115.2539, -8.7146],
              [114.927, -8.4675],
              [114.639, -8.3986],
              [114.4684, -8.2185],
              [114.4479, -8.1218],
              [114.6914, -8.135],
              [114.8576, -8.1931],
              [115.0226, -8.1726],
              [115.2155, -8.0595],
              [115.5025, -8.1726],
            ],
          ],
          [
            [
              [129.7661, -7.8131],
              [129.8591, -7.9325],
              [129.7764, -8.0554],
              [129.5942, -7.924],
              [129.6325, -7.796],
              [129.7661, -7.8131],
            ],
          ],
          [
            [
              [126.7217, -7.6681],
              [126.7134, -7.7475],
              [126.4219, -7.9325],
              [125.8084, -7.8642],
              [125.9479, -7.6783],
              [126.3284, -7.6957],
              [126.6314, -7.5654],
              [126.7217, -7.6681],
            ],
          ],
          [
            [
              [138.8926, -8.0413],
              [138.7466, -8.1515],
              [138.6609, -8.169],
              [138.6025, -8.2547],
              [138.4442, -8.3791],
              [137.637, -8.3894],
              [137.759, -8.0734],
              [138.0281, -7.61],
              [138.2442, -7.4565],
              [138.6209, -7.3647],
              [138.8045, -7.3742],
              [138.939, -7.5421],
              [139.0464, -7.5619],
              [138.9827, -7.8298],
              [138.8838, -7.9325],
              [138.8926, -8.0413],
            ],
          ],
          [
            [
              [131.6536, -7.1662],
              [131.6992, -7.414],
              [131.6433, -7.5991],
              [131.3899, -7.8471],
              [131.3609, -7.9474],
              [131.1162, -8.0007],
              [131.1196, -7.7338],
              [131.2547, -7.4723],
              [131.347, -7.4382],
              [131.5288, -7.1701],
              [131.6536, -7.1662],
            ],
          ],
          [
            [
              [113.9899, -6.8779],
              [114.0511, -7.0114],
              [113.8325, -7.132],
              [113.6652, -7.11],
              [113.549, -7.2338],
              [113.1279, -7.2311],
              [112.7425, -7.1614],
              [112.6926, -7.0585],
              [112.9153, -6.8917],
              [113.9899, -6.8779],
            ],
          ],
          [
            [
              [134.4365, -6.4452],
              [134.4993, -6.6378],
              [134.3107, -6.8595],
              [134.1851, -6.9186],
              [134.0734, -6.8268],
              [134.1238, -6.1716],
              [134.4365, -6.4452],
            ],
          ],
          [
            [
              [106.1592, -6.0095],
              [106.2565, -5.9416],
              [106.4954, -6.0348],
              [106.7209, -6.0309],
              [106.7932, -6.1041],
              [106.9935, -6.0785],
              [107.1501, -5.9731],
              [107.3541, -5.976],
              [107.4703, -6.1454],
              [107.6782, -6.238],
              [107.8108, -6.1893],
              [108.0937, -6.3268],
              [108.2164, -6.238],
              [108.5396, -6.4869],
              [108.6055, -6.7617],
              [108.923, -6.8447],
              [109.0013, -6.7892],
              [109.1428, -6.8562],
              [109.3519, -6.8757],
              [109.532, -6.8351],
              [109.8963, -6.9182],
              [110.198, -6.8909],
              [110.3557, -6.9729],
              [110.5715, -6.8362],
              [110.7305, -6.457],
              [110.9248, -6.4037],
              [111.0518, -6.4567],
              [111.1569, -6.6921],
              [111.4287, -6.6992],
              [111.4934, -6.6235],
              [111.7404, -6.7817],
              [111.9762, -6.7848],
              [112.0679, -6.8936],
              [112.3635, -6.8705],
              [112.5828, -6.9899],
              [112.7007, -7.2547],
              [112.8233, -7.2626],
              [112.7615, -7.5312],
              [113.2993, -7.7885],
              [113.5286, -7.7004],
              [113.8039, -7.7218],
              [114.0161, -7.6181],
              [114.2586, -7.7004],
              [114.4673, -7.831],
              [114.3587, -8.4091],
              [114.4612, -8.6183],
              [114.5891, -8.6642],
              [114.5972, -8.7756],
              [114.3889, -8.7481],
              [114.3026, -8.6119],
              [114.197, -8.6456],
              [113.7239, -8.5283],
              [113.1979, -8.2773],
              [112.9873, -8.3235],
              [112.6727, -8.447],
              [112.3662, -8.342],
              [111.9273, -8.3025],
              [111.792, -8.2565],
              [111.6734, -8.3679],
              [111.4421, -8.2761],
              [111.1712, -8.2826],
              [110.4767, -8.1119],
              [110.0342, -7.8847],
              [109.6567, -7.7817],
              [109.4263, -7.7742],
              [109.3688, -7.7135],
              [108.8897, -7.6786],
              [108.5396, -7.6923],
              [108.4537, -7.8185],
              [108.3268, -7.8175],
              [107.6865, -7.6621],
              [107.4114, -7.4959],
              [106.5227, -7.411],
              [106.3754, -7.3102],
              [106.5371, -6.983],
              [106.2834, -6.9833],
              [105.9596, -6.8176],
              [105.5552, -6.8557],
              [105.4536, -6.8088],
              [105.8241, -6.4346],
              [105.8879, -6.0706],
              [106.0527, -5.881],
              [106.1592, -6.0095],
            ],
          ],
          [
            [
              [120.4905, -6.4714],
              [120.4636, -6.3839],
              [120.4483, -5.8439],
              [120.5552, -5.9794],
              [120.4905, -6.4714],
            ],
          ],
          [
            [
              [132.7029, -5.6168],
              [132.8054, -5.8625],
              [132.6678, -5.8881],
              [132.7029, -5.6168],
            ],
          ],
          [
            [
              [134.5812, -5.4242],
              [134.6965, -5.5341],
              [134.7649, -5.8904],
              [134.7722, -6.0896],
              [134.7018, -6.3219],
              [134.6078, -6.3696],
              [134.3335, -6.2214],
              [134.2759, -6.0836],
              [134.3604, -5.8495],
              [134.3518, -5.7145],
              [134.4912, -5.4482],
              [134.5812, -5.4242],
            ],
          ],
          [
            [
              [133.047, -5.6486],
              [133.023, -5.5485],
              [133.1101, -5.311],
              [133.1843, -5.3103],
              [133.1133, -5.5956],
              [133.047, -5.6486],
            ],
          ],
          [
            [
              [121.9194, -5.0955],
              [122.0642, -5.1899],
              [122.053, -5.4323],
              [121.9683, -5.468],
              [121.8068, -5.2599],
              [121.9194, -5.0955],
            ],
          ],
          [
            [
              [122.7786, -4.9495],
              [122.6434, -5.0788],
              [122.6554, -5.3225],
              [122.5435, -5.4288],
              [122.2752, -5.3276],
              [122.4026, -5.0618],
              [122.3275, -4.8352],
              [122.405, -4.7379],
              [122.657, -4.6243],
              [122.7355, -4.6437],
              [122.7786, -4.9495],
            ],
          ],
          [
            [
              [123.2113, -4.6775],
              [123.0464, -4.7529],
              [122.9836, -5.0878],
              [123.2131, -5.3017],
              [123.0656, -5.4184],
              [122.9775, -5.3942],
              [122.8276, -5.6762],
              [122.6384, -5.6622],
              [122.5671, -5.5069],
              [122.7634, -5.2079],
              [122.855, -4.8178],
              [122.848, -4.6489],
              [122.9089, -4.4745],
              [123.0195, -4.3902],
              [123.1904, -4.5668],
              [123.2113, -4.6775],
            ],
          ],
          [
            [
              [123.0818, -4.0012],
              [123.2534, -4.0898],
              [123.0866, -4.2345],
              [122.9507, -4.0471],
              [123.0818, -4.0012],
            ],
          ],
          [
            [
              [128.3234, -3.5702],
              [128.2365, -3.7223],
              [128.1271, -3.7494],
              [127.9164, -3.6939],
              [128.0345, -3.589],
              [128.3234, -3.5702],
            ],
          ],
          [
            [
              [116.1247, -4.0304],
              [116.013, -3.6505],
              [116.1262, -3.3186],
              [116.263, -3.4055],
              [116.308, -3.8786],
              [116.1247, -4.0304],
            ],
          ],
          [
            [
              [126.8753, -3.1095],
              [127.0935, -3.2008],
              [127.0462, -3.3054],
              [127.2598, -3.3712],
              [127.2434, -3.6061],
              [126.7422, -3.8594],
              [126.5893, -3.8166],
              [126.1736, -3.6025],
              [126.0526, -3.4463],
              [125.9917, -3.2482],
              [126.0909, -3.1121],
              [126.1851, -3.1809],
              [126.4214, -3.0705],
              [126.7202, -3.0602],
              [126.8753, -3.1095],
            ],
          ],
          [
            [
              [129.541, -2.78],
              [130.0466, -2.9921],
              [130.345, -2.9776],
              [130.5785, -3.1228],
              [130.6667, -3.4036],
              [130.7615, -3.4059],
              [130.8732, -3.574],
              [130.8179, -3.8688],
              [130.6069, -3.7785],
              [130.4188, -3.6369],
              [129.9832, -3.4597],
              [129.8905, -3.3331],
              [129.5449, -3.2978],
              [129.4856, -3.4635],
              [129.1135, -3.3488],
              [128.9608, -3.3556],
              [128.8775, -3.2026],
              [128.6926, -3.3487],
              [128.6776, -3.4323],
              [128.4841, -3.4625],
              [128.2821, -3.2209],
              [128.0666, -3.197],
              [128.0222, -3.3686],
              [127.8753, -3.2088],
              [128.1063, -2.9755],
              [128.1755, -2.857],
              [128.5159, -2.8342],
              [128.8652, -2.8621],
              [129.0529, -2.8045],
              [129.1218, -2.9577],
              [129.541, -2.78],
            ],
          ],
          [
            [
              [100.4675, -3.067],
              [100.3452, -3.1266],
              [100.1895, -2.9666],
              [100.2554, -2.7914],
              [100.4675, -3.067],
            ],
          ],
          [
            [
              [107.8482, -2.5499],
              [108.0666, -2.5945],
              [108.2673, -2.7574],
              [108.2887, -2.8666],
              [108.1877, -3.1369],
              [107.9661, -3.151],
              [107.8884, -3.0875],
              [107.7531, -3.1985],
              [107.5811, -3.0107],
              [107.5942, -2.7863],
              [107.6703, -2.5615],
              [107.8482, -2.5499],
            ],
          ],
          [
            [
              [100.2041, -2.7599],
              [100.0078, -2.8195],
              [99.9841, -2.4846],
              [100.2014, -2.6782],
              [100.2041, -2.7599],
            ],
          ],
          [
            [
              [125.4221, -1.7941],
              [125.7029, -1.8193],
              [125.9934, -1.7858],
              [125.9802, -1.9018],
              [125.438, -1.9325],
              [125.4221, -1.7941],
            ],
          ],
          [
            [
              [130.3572, -1.6949],
              [130.4378, -1.8507],
              [130.2561, -2.0458],
              [130.1265, -2.067],
              [129.9255, -2.0038],
              [129.7453, -1.8635],
              [130.012, -1.7502],
              [130.3572, -1.6949],
            ],
          ],
          [
            [
              [124.5296, -1.6357],
              [124.6328, -1.6311],
              [125.023, -1.7231],
              [125.284, -1.7311],
              [125.3216, -1.8644],
              [125.0135, -1.9443],
              [124.8533, -1.8969],
              [124.5432, -2.0065],
              [124.406, -2.0041],
              [124.3314, -1.8269],
              [124.3849, -1.6679],
              [124.5296, -1.6357],
            ],
          ],
          [
            [
              [135.4773, -1.5899],
              [135.8967, -1.648],
              [136.1913, -1.6539],
              [136.3903, -1.7258],
              [136.7046, -1.7306],
              [136.6449, -1.8764],
              [136.2732, -1.9094],
              [135.718, -1.7033],
              [135.4839, -1.675],
              [135.4773, -1.5899],
            ],
          ],
          [
            [
              [105.8757, -1.4892],
              [106.0339, -1.5759],
              [106.0355, -1.6783],
              [106.1746, -1.8791],
              [106.1626, -2.007],
              [106.2642, -2.3513],
              [106.354, -2.463],
              [106.683, -2.5325],
              [106.613, -2.9426],
              [106.7073, -3.0942],
              [106.5322, -3.1056],
              [106.447, -2.9811],
              [105.9865, -2.8212],
              [105.9016, -2.6287],
              [105.9443, -2.4988],
              [105.8288, -2.3951],
              [105.7957, -2.1718],
              [105.6074, -2.1148],
              [105.3064, -2.135],
              [105.1475, -2.08],
              [105.1543, -1.936],
              [105.3757, -1.8219],
              [105.3499, -1.6487],
              [105.4596, -1.5629],
              [105.8757, -1.4892],
            ],
          ],
          [
            [
              [127.8308, -1.4316],
              [127.8989, -1.4267],
              [128.1511, -1.6697],
              [127.7033, -1.6902],
              [127.5327, -1.7314],
              [127.3787, -1.6323],
              [127.4036, -1.4916],
              [127.6245, -1.3295],
              [127.8308, -1.4316],
            ],
          ],
          [
            [
              [123.201, -1.1552],
              [123.5391, -1.2727],
              [123.5286, -1.433],
              [123.4084, -1.5165],
              [123.3268, -1.4212],
              [123.1848, -1.4856],
              [123.0946, -1.3464],
              [122.9932, -1.5092],
              [122.874, -1.5846],
              [122.812, -1.4436],
              [122.8987, -1.1843],
              [123.201, -1.1552],
            ],
          ],
          [
            [
              [109.6063, -0.9747],
              [109.7598, -1.0317],
              [109.7803, -1.1316],
              [109.4787, -1.2985],
              [109.4172, -1.2545],
              [109.4523, -1.004],
              [109.6063, -0.9747],
            ],
          ],
          [
            [
              [98.918, -0.9401],
              [98.9053, -0.9907],
              [99.2725, -1.6018],
              [99.2442, -1.779],
              [99.1006, -1.7994],
              [98.8848, -1.6794],
              [98.5938, -1.1984],
              [98.6582, -0.9635],
              [98.918, -0.9401],
            ],
          ],
          [
            [
              [130.9349, -0.9259],
              [131.0348, -0.9148],
              [131.0725, -1.0776],
              [130.9826, -1.3486],
              [130.8533, -1.3307],
              [130.7497, -1.2234],
              [130.6807, -0.9566],
              [130.9349, -0.9259],
            ],
          ],
          [
            [
              [135.4234, -0.6549],
              [135.8474, -0.6959],
              [136.0658, -0.8717],
              [136.2936, -1.1832],
              [136.1428, -1.2234],
              [135.8283, -1.1078],
              [135.781, -0.8727],
              [135.6406, -0.8816],
              [135.4234, -0.6549],
            ],
          ],
          [
            [
              [140.9745, -2.6005],
              [140.9735, -3.8],
              [140.9758, -4.5959],
              [140.9769, -5.6212],
              [140.9769, -6.3351],
              [140.8492, -6.7028],
              [140.9772, -6.8966],
              [140.9769, -7.9621],
              [140.977, -9.1061],
              [140.8569, -9.0491],
              [140.6216, -8.8067],
              [140.5075, -8.6381],
              [140.0319, -8.2464],
              [139.9324, -8.1086],
              [139.6305, -8.1249],
              [139.3567, -8.1994],
              [139.2012, -8.0956],
              [139.0895, -8.1336],
              [138.9144, -8.2921],
              [138.8386, -8.1384],
              [138.9058, -8.0436],
              [138.911, -7.9225],
              [138.994, -7.8655],
              [139.093, -7.56],
              [138.958, -7.5073],
              [138.8861, -7.3609],
              [138.6882, -7.1926],
              [138.762, -7.1139],
              [138.572, -6.9425],
              [138.7398, -6.8971],
              [138.8224, -6.8061],
              [138.6729, -6.6949],
              [138.6776, -6.603],
              [138.4661, -6.4021],
              [138.2708, -5.887],
              [138.0711, -5.7286],
              [138.0598, -5.5753],
              [137.9146, -5.3869],
              [137.7063, -5.2219],
              [137.5798, -5.1819],
              [137.2671, -4.9994],
              [136.9539, -4.9228],
              [136.8221, -4.9283],
              [136.3555, -4.6774],
              [136.0358, -4.586],
              [135.9705, -4.5196],
              [135.4295, -4.4304],
              [135.1856, -4.4489],
              [134.8235, -4.2594],
              [134.4304, -3.9078],
              [134.3153, -4.0203],
              [134.1912, -3.9435],
              [134.1731, -3.7879],
              [133.959, -3.8376],
              [133.8436, -3.6384],
              [133.5862, -3.5666],
              [133.4089, -3.7224],
              [133.4556, -3.8469],
              [133.2694, -4.052],
              [132.9077, -4.0881],
              [132.7918, -3.9272],
              [132.7561, -3.6275],
              [132.8319, -3.461],
              [132.8117, -3.2817],
              [132.6921, -3.3381],
              [132.5531, -3.107],
              [132.3257, -2.9465],
              [131.9969, -2.9148],
              [132.035, -2.7936],
              [132.2019, -2.6764],
              [132.5857, -2.7175],
              [132.7366, -2.81],
              [132.8353, -2.736],
              [133.0335, -2.4794],
              [133.2585, -2.4164],
              [133.3206, -2.4919],
              [133.735, -2.5168],
              [133.9209, -2.3876],
              [133.863, -2.218],
              [133.5486, -2.245],
              [133.2962, -2.2042],
              [132.9436, -2.2834],
              [132.8159, -2.245],
              [132.6987, -2.3054],
              [132.5604, -2.1856],
              [132.3479, -2.2701],
              [132.0734, -2.1052],
              [131.9729, -1.9646],
              [131.9273, -1.7062],
              [131.8215, -1.5982],
              [131.5731, -1.4802],
              [131.3237, -1.4692],
              [131.2057, -1.5236],
              [131.0036, -1.4562],
              [131.03, -1.2756],
              [131.1817, -1.2172],
              [131.2915, -0.915],
              [131.243, -0.8194],
              [131.8843, -0.6819],
              [132.0996, -0.4699],
              [132.4372, -0.3471],
              [132.7092, -0.36],
              [133.1548, -0.532],
              [133.3916, -0.7232],
              [133.7454, -0.7507],
              [133.9772, -0.7232],
              [134.1008, -0.8126],
              [134.0361, -0.9578],
              [134.1275, -1.1484],
              [134.282, -1.3502],
              [134.2072, -1.5735],
              [134.0871, -1.6828],
              [134.1934, -2.3791],
              [134.3303, -2.5222],
              [134.4639, -2.8547],
              [134.5327, -2.8342],
              [134.4744, -2.5807],
              [134.5563, -2.4648],
              [134.6581, -2.5628],
              [134.7013, -2.9587],
              [134.8242, -3.0504],
              [134.8806, -3.2429],
              [134.9847, -3.3297],
              [135.322, -3.3865],
              [135.4705, -3.3631],
              [135.6389, -3.1782],
              [135.9099, -2.9858],
              [135.9646, -2.7758],
              [136.2664, -2.5603],
              [136.4038, -2.2197],
              [136.7586, -2.2487],
              [136.9515, -2.1422],
              [137.2263, -2.0744],
              [137.1428, -1.787],
              [137.3488, -1.7101],
              [137.7944, -1.4841],
              [137.9248, -1.4841],
              [138.0749, -1.6103],
              [138.6652, -1.7892],
              [138.8032, -1.9208],
              [139.1067, -2.0128],
              [139.2832, -2.1218],
              [139.8648, -2.372],
              [140.1047, -2.3201],
              [140.3323, -2.4557],
              [140.3885, -2.3971],
              [140.7308, -2.4915],
              [140.7346, -2.6349],
              [140.9745, -2.6005],
            ],
          ],
          [
            [
              [104.5485, -0.398],
              [104.522, -0.5966],
              [104.2559, -0.4755],
              [104.3247, -0.3648],
              [104.5485, -0.398],
            ],
          ],
          [
            [
              [127.632, -0.4153],
              [127.6065, -0.6291],
              [127.8982, -0.7673],
              [127.7488, -0.8853],
              [127.6257, -0.7524],
              [127.4699, -0.8185],
              [127.3986, -0.6202],
              [127.303, -0.5109],
              [127.3167, -0.3396],
              [127.632, -0.4153],
            ],
          ],
          [
            [
              [127.2136, -0.2502],
              [127.252, -0.4972],
              [127.1023, -0.4378],
              [127.1104, -0.2877],
              [127.2136, -0.2502],
            ],
          ],
          [
            [
              [98.4868, -0.5109],
              [98.3167, -0.5351],
              [98.3689, -0.3492],
              [98.5073, -0.3649],
              [98.4868, -0.5109],
            ],
          ],
          [
            [
              [130.8247, -0.0038],
              [131.1537, -0.0857],
              [131.2974, -0.1593],
              [131.3386, -0.2776],
              [130.9517, -0.3464],
              [130.6956, -0.0795],
              [130.6262, -0.1257],
              [130.811, -0.3185],
              [130.6807, -0.3396],
              [130.3163, -0.2599],
              [130.3687, -0.1218],
              [130.4985, -0.0627],
              [130.8247, -0.0038],
            ],
          ],
          [
            [
              [98.5598, -0.2783],
              [98.5125, -0.319],
              [98.3435, -0.0447],
              [98.4061, -0.0074],
              [98.5598, -0.2783],
            ],
          ],
          [
            [
              [104.5879, 0.0083],
              [104.7663, -0.1882],
              [104.5414, -0.2713],
              [104.4373, -0.1811],
              [104.5879, 0.0083],
            ],
          ],
          [
            [
              [102.6453, 0.9972],
              [102.7638, 1.0112],
              [103.0217, 0.8453],
              [102.8522, 0.7982],
              [102.5579, 0.7803],
              [102.4065, 0.8732],
              [102.5017, 0.9729],
              [102.6453, 0.9972],
            ],
          ],
          [
            [
              [103.0371, 1.0446],
              [103.0863, 0.841],
              [102.7907, 1.0315],
              [102.8018, 1.1633],
              [103.0371, 1.0446],
            ],
          ],
          [
            [
              [104.1524, 1.14],
              [104.0492, 0.9842],
              [103.9026, 1.0905],
              [104.1032, 1.1895],
              [104.1524, 1.14],
            ],
          ],
          [
            [
              [104.5925, 1.1988],
              [104.6616, 0.9372],
              [104.4834, 0.859],
              [104.4525, 1.0582],
              [104.3154, 1.0036],
              [104.2319, 1.0933],
              [104.3997, 1.2026],
              [104.5925, 1.1988],
            ],
          ],
          [
            [
              [102.4649, 0.9634],
              [102.3338, 0.9637],
              [102.2329, 1.0995],
              [102.201, 1.2978],
              [102.2909, 1.4045],
              [102.4734, 1.242],
              [102.4649, 0.9634],
            ],
          ],
          [
            [
              [97.3745, 1.5185],
              [97.4863, 1.4765],
              [97.6936, 1.186],
              [97.9392, 0.9861],
              [97.883, 0.643],
              [97.7041, 0.5727],
              [97.6138, 0.8236],
              [97.3888, 0.9521],
              [97.2331, 1.2773],
              [97.2359, 1.4167],
              [97.3745, 1.5185],
            ],
          ],
          [
            [
              [102.2376, 1.5703],
              [102.4905, 1.4897],
              [102.5115, 1.2953],
              [102.3303, 1.4133],
              [102.1345, 1.4557],
              [102.2376, 1.5703],
            ],
          ],
          [
            [
              [125.0617, 1.6745],
              [125.2492, 1.4847],
              [125.1443, 1.4213],
              [125.0337, 1.1754],
              [124.8987, 0.973],
              [124.6638, 0.8056],
              [124.5034, 0.534],
              [124.3218, 0.3989],
              [123.7458, 0.3109],
              [123.2388, 0.3245],
              [123.0742, 0.5163],
              [122.9779, 0.4828],
              [122.3244, 0.4971],
              [121.8005, 0.4265],
              [121.6901, 0.5238],
              [121.5078, 0.4868],
              [120.8924, 0.4233],
              [120.6795, 0.5306],
              [120.3753, 0.4777],
              [120.1284, 0.1811],
              [119.9956, -0.2092],
              [120.0892, -0.6573],
              [120.2439, -0.8876],
              [120.4124, -0.905],
              [120.5824, -1.1052],
              [120.5654, -1.2155],
              [120.676, -1.3947],
              [120.8039, -1.3429],
              [121.0733, -1.4213],
              [121.5007, -0.8593],
              [121.6597, -0.8126],
              [121.7142, -0.9355],
              [122.1053, -0.9262],
              [122.2077, -0.7647],
              [122.6125, -0.7522],
              [122.7165, -0.7924],
              [122.9124, -0.7629],
              [122.823, -0.6122],
              [123.2395, -0.5705],
              [123.4035, -0.6427],
              [123.4544, -0.7582],
              [123.3833, -1.0037],
              [123.2388, -1.0248],
              [123.0608, -0.8805],
              [122.8117, -0.9241],
              [122.6483, -1.1594],
              [122.3688, -1.4928],
              [122.1839, -1.6142],
              [122.0365, -1.6139],
              [121.7986, -1.718],
              [121.6921, -1.9081],
              [121.54, -1.9234],
              [121.4409, -1.8136],
              [121.327, -1.919],
              [121.8229, -2.2873],
              [122.0191, -2.615],
              [122.0156, -2.6935],
              [122.3125, -2.9269],
              [122.2796, -3.0396],
              [122.3859, -3.132],
              [122.2517, -3.3761],
              [122.2005, -3.5566],
              [122.2505, -3.6562],
              [122.4414, -3.7311],
              [122.6244, -3.9524],
              [122.6751, -4.1227],
              [122.9071, -4.196],
              [122.9002, -4.3888],
              [122.7688, -4.4784],
              [122.5991, -4.3967],
              [122.4304, -4.4169],
              [122.1078, -4.52],
              [121.9917, -4.8557],
              [121.7044, -4.8352],
              [121.4846, -4.6564],
              [121.5363, -4.2306],
              [121.6277, -4.0871],
              [121.3543, -3.9684],
              [120.8938, -3.5392],
              [120.8703, -3.4457],
              [121.0667, -3.1938],
              [121.0669, -2.7522],
              [121.0059, -2.6608],
              [120.7761, -2.6149],
              [120.2316, -2.9504],
              [120.2853, -3.1625],
              [120.4216, -3.2675],
              [120.3953, -3.3823],
              [120.4465, -3.7287],
              [120.3535, -3.9308],
              [120.4021, -4.5745],
              [120.3866, -4.8293],
              [120.3094, -4.8713],
              [120.2643, -5.15],
              [120.3811, -5.3373],
              [120.3233, -5.5095],
              [120.1004, -5.5956],
              [119.9149, -5.5689],
              [119.8057, -5.6925],
              [119.648, -5.6788],
              [119.4375, -5.5217],
              [119.3528, -5.3556],
              [119.3799, -5.1917],
              [119.5252, -4.8831],
              [119.4912, -4.7541],
              [119.5911, -4.5641],
              [119.6268, -4.3145],
              [119.5852, -3.9703],
              [119.4567, -3.7145],
              [119.4976, -3.4953],
              [119.2976, -3.4279],
              [119.209, -3.4898],
              [118.9268, -3.5661],
              [118.7642, -3.0909],
              [118.8533, -3.0534],
              [118.8806, -2.861],
              [118.7573, -2.7758],
              [119.1414, -2.442],
              [119.134, -2.2035],
              [119.2161, -2.0099],
              [119.354, -1.9347],
              [119.3084, -1.6949],
              [119.2986, -1.3879],
              [119.3284, -1.2145],
              [119.4531, -1.0964],
              [119.5374, -0.85],
              [119.6821, -0.7369],
              [119.8672, -0.7883],
              [119.7578, -0.4699],
              [119.792, -0.1068],
              [119.8882, 0.0713],
              [119.794, 0.1966],
              [119.9118, 0.4737],
              [120.0322, 0.5163],
              [120.0628, 0.7429],
              [120.3403, 0.8724],
              [120.5681, 0.7821],
              [120.6834, 0.9919],
              [120.8032, 1.0684],
              [120.8208, 1.31],
              [120.9673, 1.3462],
              [121.2649, 1.2296],
              [121.43, 1.233],
              [121.4807, 1.1209],
              [121.6309, 1.062],
              [121.9261, 1.0987],
              [122.2354, 1.0098],
              [122.4541, 1.0207],
              [122.6238, 0.9664],
              [122.8489, 0.818],
              [122.968, 0.9626],
              [123.3687, 0.9274],
              [123.563, 0.8669],
              [123.9479, 0.8389],
              [124.315, 1.0377],
              [124.3894, 1.1861],
              [124.5938, 1.1959],
              [124.5484, 1.3598],
              [124.8504, 1.4897],
              [124.8369, 1.5889],
              [125.0617, 1.6745],
            ],
          ],
          [
            [
              [101.7212, 2.0581],
              [101.7756, 1.948],
              [101.7151, 1.7782],
              [101.5773, 1.7093],
              [101.4601, 1.7655],
              [101.3938, 1.9306],
              [101.4409, 2.0649],
              [101.6493, 2.1285],
              [101.7212, 2.0581],
            ],
          ],
          [
            [
              [128.0567, 2.1925],
              [127.8517, 1.8254],
              [128.0149, 1.7192],
              [128.0353, 1.538],
              [127.9739, 1.2978],
              [127.8943, 1.1566],
              [127.6375, 1.0007],
              [127.6551, 0.89],
              [127.7957, 0.7991],
              [127.923, 0.8698],
              [127.9364, 1.0073],
              [128.1961, 1.1964],
              [128.1868, 1.3742],
              [128.4597, 1.545],
              [128.7195, 1.5706],
              [128.741, 1.4049],
              [128.7012, 1.0709],
              [128.3154, 0.9016],
              [128.2136, 0.7739],
              [128.3135, 0.6682],
              [128.6843, 0.5417],
              [128.7039, 0.332],
              [128.4748, 0.3872],
              [128.2622, 0.3913],
              [127.9602, 0.4828],
              [127.8809, 0.3017],
              [127.9212, 0.1456],
              [127.8924, -0.0172],
              [128.0427, -0.4153],
              [128.2278, -0.7096],
              [128.0488, -0.7128],
              [127.8513, -0.3655],
              [127.6724, -0.2302],
              [127.7137, 0.2773],
              [127.5723, 0.4223],
              [127.5269, 0.5606],
              [127.5554, 0.7399],
              [127.492, 1.0549],
              [127.4059, 1.2299],
              [127.5408, 1.4757],
              [127.5809, 1.7518],
              [127.69, 1.9253],
              [127.9172, 2.1721],
              [128.0567, 2.1925],
            ],
          ],
          [
            [
              [128.596, 2.6086],
              [128.6945, 2.4389],
              [128.5667, 2.1099],
              [128.345, 2.0399],
              [128.2461, 2.1061],
              [128.1971, 2.2662],
              [128.3211, 2.4586],
              [128.4666, 2.5943],
              [128.596, 2.6086],
            ],
          ],
          [
            [
              [95.9119, 2.9062],
              [96.2461, 2.6],
              [96.4441, 2.4904],
              [96.3076, 2.3741],
              [96.0657, 2.5794],
              [95.9314, 2.5956],
              [95.698, 2.7788],
              [95.8141, 2.9221],
              [95.9119, 2.9062],
            ],
          ],
          [
            [
              [117.5062, 3.5021],
              [117.2549, 3.5071],
              [117.2684, 3.5929],
              [117.5062, 3.5021],
            ],
          ],
          [
            [
              [117.7036, 4.1634],
              [117.907, 4.1567],
              [117.8386, 4.0402],
              [117.7036, 4.1634],
            ],
          ],
          [
            [
              [108.3068, 4.0865],
              [108.4063, 3.8743],
              [108.3176, 3.6838],
              [108.1745, 3.6519],
              [108.1803, 3.8168],
              [108.0623, 3.8559],
              [107.9846, 4.012],
              [108.1703, 4.1794],
              [108.3068, 4.0865],
            ],
          ],
          [
            [
              [117.5671, 4.1597],
              [117.5203, 4.0493],
              [117.7772, 3.7991],
              [117.7485, 3.6369],
              [117.5466, 3.664],
              [117.2195, 3.6474],
              [117.2, 3.555],
              [117.3255, 3.436],
              [117.3527, 3.1828],
              [117.6172, 3.0859],
              [117.6333, 2.8122],
              [117.784, 2.7582],
              [117.8262, 2.6074],
              [118.0635, 2.3669],
              [118.0915, 2.262],
              [117.8599, 2.0383],
              [117.9241, 1.8192],
              [118.1267, 1.6547],
              [118.4251, 1.4857],
              [118.7764, 1.202],
              [118.8569, 1.0582],
              [119.0029, 0.9625],
              [118.7982, 0.8049],
              [118.5653, 0.804],
              [118.1878, 0.8752],
              [117.8113, 0.8118],
              [117.5173, 0.291],
              [117.4756, 0.0576],
              [117.5303, -0.0323],
              [117.4339, -0.2638],
              [117.596, -0.4226],
              [117.5759, -0.63],
              [117.4519, -0.8665],
              [117.3011, -0.816],
              [117.0237, -1.2029],
              [116.8198, -1.2614],
              [116.7624, -1.3653],
              [116.5392, -1.4812],
              [116.5575, -1.6062],
              [116.3249, -1.7039],
              [116.4666, -1.9075],
              [116.3928, -2.1911],
              [116.6033, -2.2242],
              [116.54, -2.5129],
              [116.3904, -2.589],
              [116.3342, -2.9256],
              [116.1113, -2.9303],
              [116.2649, -3.1369],
              [116.1633, -3.1729],
              [115.9679, -3.6088],
              [115.8362, -3.6332],
              [115.5833, -3.7805],
              [115.0603, -3.9858],
              [114.7129, -4.1708],
              [114.6316, -4.0129],
              [114.6111, -3.6839],
              [114.5239, -3.4758],
              [114.4124, -3.4853],
              [114.1082, -3.3473],
              [113.7131, -3.4726],
              [113.6325, -3.4568],
              [113.6204, -3.1853],
              [113.3452, -3.2465],
              [113.1826, -3.0771],
              [113.0371, -2.9931],
              [113.0215, -3.1625],
              [112.618, -3.4238],
              [112.5247, -3.4378],
              [112.3027, -3.3139],
              [112.1169, -3.3823],
              [111.9053, -3.572],
              [111.822, -3.5451],
              [111.8325, -3.0616],
              [111.7603, -2.9076],
              [111.5843, -3.0089],
              [111.4475, -2.9298],
              [111.2962, -2.9365],
              [111.0608, -3.0611],
              [110.9065, -3.0833],
              [110.5081, -2.8746],
              [110.2632, -3.0029],
              [110.221, -2.5328],
              [110.0956, -2.1974],
              [110.1292, -2.0695],
              [110.075, -1.9225],
              [109.9216, -1.7696],
              [110.0339, -1.6462],
              [110.0685, -1.4325],
              [110.0296, -1.2859],
              [109.9299, -1.2232],
              [109.7341, -0.954],
              [109.4382, -0.8456],
              [109.2732, -0.8536],
              [109.2453, -0.6959],
              [109.1233, -0.4997],
              [109.0908, -0.2872],
              [109.176, -0.212],
              [109.1668, 0.1123],
              [109.1023, 0.2431],
              [108.9209, 0.3403],
              [108.9273, 0.6408],
              [108.8555, 0.8322],
              [108.9803, 0.9956],
              [108.927, 1.1404],
              [109.0608, 1.3839],
              [109.096, 1.5606],
              [109.2527, 1.6684],
              [109.3478, 1.8096],
              [109.341, 1.9355],
              [109.5612, 1.9905],
              [109.6453, 2.0832],
              [109.5284, 1.89],
              [109.6472, 1.7619],
              [109.6773, 1.5729],
              [109.9763, 1.2877],
              [110.1704, 1.1745],
              [110.2566, 1.0391],
              [110.5539, 0.8514],
              [110.784, 0.9082],
              [110.8527, 0.9972],
              [111.1976, 1.0752],
              [111.3518, 1.0139],
              [111.8231, 1.0085],
              [111.9101, 1.114],
              [112.1001, 1.1549],
              [112.1801, 1.449],
              [112.4705, 1.5677],
              [113.0234, 1.537],
              [113.089, 1.4313],
              [113.5034, 1.3145],
              [113.6495, 1.2333],
              [113.9097, 1.4425],
              [114.1604, 1.4248],
              [114.3557, 1.502],
              [114.5006, 1.436],
              [114.669, 1.694],
              [114.6714, 1.821],
              [114.832, 1.9025],
              [114.8453, 2.025],
              [114.753, 2.1348],
              [114.7788, 2.2472],
              [114.9196, 2.2957],
              [115.1265, 2.4792],
              [115.0565, 2.6086],
              [115.0669, 2.8282],
              [115.2421, 3.0416],
              [115.4641, 3.0308],
              [115.5176, 3.3572],
              [115.6006, 3.4412],
              [115.5485, 3.6053],
              [115.6469, 4.1691],
              [115.8788, 4.3521],
              [116.0206, 4.2709],
              [116.1258, 4.3789],
              [116.3107, 4.3834],
              [116.4941, 4.3249],
              [117.1647, 4.3331],
              [117.4129, 4.2001],
              [117.5671, 4.1597],
            ],
          ],
          [
            [
              [126.875, 4.4943],
              [126.9221, 4.282],
              [126.8099, 4.1572],
              [126.81, 4.0373],
              [126.6648, 4.0341],
              [126.7337, 4.5359],
              [126.875, 4.4943],
            ],
          ],
          [
            [
              [95.5027, 5.6051],
              [95.5762, 5.6313],
              [95.8875, 5.5122],
              [95.9304, 5.4145],
              [96.1262, 5.2818],
              [96.3622, 5.2255],
              [96.6506, 5.2163],
              [96.9734, 5.2734],
              [97.2684, 5.1551],
              [97.4968, 5.2519],
              [97.6644, 5.0734],
              [97.9092, 4.8896],
              [98.054, 4.5532],
              [98.2756, 4.4254],
              [98.263, 4.1773],
              [98.3057, 4.0803],
              [98.4772, 4.0462],
              [98.6766, 3.9145],
              [98.7966, 3.7254],
              [98.9575, 3.6713],
              [99.4898, 3.3362],
              [99.5894, 3.228],
              [99.7507, 3.1805],
              [99.9795, 2.9485],
              [100.0186, 2.7317],
              [100.2034, 2.7099],
              [100.3084, 2.5695],
              [100.3817, 2.355],
              [100.561, 2.1568],
              [100.8557, 1.9992],
              [100.8128, 2.2498],
              [100.9079, 2.2995],
              [101.3, 2.0466],
              [101.3776, 1.7733],
              [101.5916, 1.6547],
              [101.7786, 1.6443],
              [102.1182, 1.3865],
              [102.2401, 0.9821],
              [102.5519, 0.7381],
              [102.8771, 0.7264],
              [103.0563, 0.5583],
              [103.0747, 0.4423],
              [103.3622, 0.5363],
              [103.7112, 0.3115],
              [103.7915, -0.0125],
              [103.6712, -0.0174],
              [103.4944, -0.1184],
              [103.4822, -0.3737],
              [103.376, -0.7268],
              [103.6504, -0.943],
              [103.8391, -1.0328],
              [103.9558, -0.977],
              [104.2124, -1.0733],
              [104.3772, -1.0317],
              [104.445, -1.3046],
              [104.4544, -1.531],
              [104.5645, -1.8577],
              [104.6638, -2.0313],
              [104.8743, -2.08],
              [104.8833, -2.2849],
              [105.1167, -2.3805],
              [105.2022, -2.3474],
              [105.6238, -2.4018],
              [105.6084, -2.5523],
              [105.7781, -2.6901],
              [105.7918, -2.8621],
              [106.0156, -2.9695],
              [106.0632, -3.2667],
              [105.929, -3.3654],
              [105.8196, -3.6725],
              [105.9525, -3.8338],
              [105.8128, -4.2387],
              [105.9063, -4.4693],
              [105.8333, -5.3832],
              [105.8296, -5.6547],
              [105.7826, -5.8285],
              [105.6021, -5.8098],
              [105.3107, -5.466],
              [105.1184, -5.7822],
              [104.6228, -5.4907],
              [104.5332, -5.5598],
              [104.6887, -5.8079],
              [104.5418, -5.8419],
              [104.2977, -5.6433],
              [104.258, -5.5398],
              [104.1109, -5.4462],
              [103.9014, -5.2185],
              [103.9134, -5.1393],
              [103.7054, -4.955],
              [103.4146, -4.8693],
              [103.1729, -4.6179],
              [102.9204, -4.4926],
              [102.7012, -4.2704],
              [102.3125, -3.9915],
              [102.2222, -3.6428],
              [101.6375, -3.2507],
              [101.419, -2.9273],
              [101.327, -2.7392],
              [101.1048, -2.5871],
              [100.9231, -2.3334],
              [100.8316, -2.1413],
              [100.8872, -2.0372],
              [100.5706, -1.4426],
              [100.5692, -1.3308],
              [100.406, -1.2644],
              [100.4129, -1.0392],
              [100.3014, -0.8163],
              [100.0013, -0.4563],
              [99.8138, -0.2946],
              [99.7472, -0.0298],
              [99.6109, 0.0986],
              [99.3162, 0.2426],
              [99.1393, 0.2737],
              [99.0899, 0.622],
              [98.9812, 0.8317],
              [98.9173, 1.1172],
              [98.7302, 1.5144],
              [98.8189, 1.6197],
              [98.7601, 1.7641],
              [98.6582, 1.7639],
              [98.5352, 1.9365],
              [98.3545, 2.0038],
              [97.9836, 2.2444],
              [97.7444, 2.2811],
              [97.6582, 2.4116],
              [97.6027, 2.8662],
              [97.4221, 2.9284],
              [97.2708, 3.2144],
              [97.1826, 3.246],
              [97.0186, 3.5227],
              [96.8836, 3.6824],
              [96.7605, 3.7487],
              [96.5598, 3.7466],
              [96.4303, 3.8253],
              [96.1909, 4.126],
              [96.01, 4.2257],
              [95.4263, 4.8273],
              [95.2913, 5.1916],
              [95.199, 5.2929],
              [95.2517, 5.3956],
              [95.1973, 5.5464],
              [95.4129, 5.6496],
              [95.5027, 5.6051],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Indonesia",
        NAME_LONG: "Indonesia",
        ADM0_A3: "IDN",
        ISO_A2: "ID",
        ISO_A3: "IDN",
        WB_A2: "ID",
        WB_A3: "IDN",
      },
      id: 102,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.6121, 54.057],
            [-4.7103, 54.2206],
            [-4.442, 54.4044],
            [-4.3119, 54.2872],
            [-4.6121, 54.057],
          ],
        ],
      },
      properties: {
        NAME: "Isle of Man",
        NAME_LONG: "Isle of Man",
        ADM0_A3: "IMN",
        ISO_A2: "IM",
        ISO_A3: "IMN",
        WB_A2: "IM",
        WB_A3: "IMY",
      },
      id: 103,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [93.8553, 7.2142],
              [93.9287, 7.0124],
              [93.8137, 6.8083],
              [93.7175, 6.9926],
              [93.6764, 7.1819],
              [93.8553, 7.2142],
            ],
          ],
          [
            [
              [92.5821, 10.7903],
              [92.5742, 10.5806],
              [92.4026, 10.5752],
              [92.3757, 10.775],
              [92.5276, 10.8955],
              [92.5821, 10.7903],
            ],
          ],
          [
            [
              [93.0163, 13.5674],
              [93.0794, 13.2598],
              [92.964, 12.8649],
              [92.9906, 12.5192],
              [92.8926, 12.2145],
              [92.7688, 12.0847],
              [92.7969, 11.8651],
              [92.7267, 11.5062],
              [92.6336, 11.5826],
              [92.5397, 11.8944],
              [92.6401, 12.1361],
              [92.7927, 12.2077],
              [92.7178, 12.3625],
              [92.7375, 12.8072],
              [92.8003, 12.8578],
              [92.8542, 13.1461],
              [92.8474, 13.3449],
              [93.0163, 13.5674],
            ],
          ],
          [
            [
              [80.996, 30.197],
              [80.5713, 29.9469],
              [80.369, 29.7579],
              [80.3852, 29.6048],
              [80.2137, 29.4169],
              [80.2787, 29.2681],
              [80.1326, 29.1102],
              [80.0364, 28.837],
              [80.3498, 28.6202],
              [80.4975, 28.6701],
              [80.6483, 28.6198],
              [81.0004, 28.397],
              [81.1463, 28.3722],
              [81.3071, 28.1238],
              [81.6889, 27.9632],
              [81.8557, 27.8509],
              [82.0516, 27.9052],
              [82.4019, 27.6772],
              [82.6521, 27.7041],
              [82.7521, 27.495],
              [83.1328, 27.4442],
              [83.3049, 27.3319],
              [83.361, 27.4622],
              [83.8341, 27.434],
              [84.0796, 27.5095],
              [84.2894, 27.3761],
              [84.577, 27.329],
              [84.6577, 27.2034],
              [84.6402, 27.0284],
              [84.8019, 27.0138],
              [85.044, 26.8437],
              [85.162, 26.851],
              [85.2873, 26.737],
              [85.5986, 26.8544],
              [85.7129, 26.6532],
              [85.8216, 26.5717],
              [86.0114, 26.6544],
              [86.2844, 26.612],
              [86.7136, 26.4146],
              [87.0413, 26.5802],
              [87.0833, 26.4321],
              [87.3262, 26.3533],
              [87.3842, 26.4186],
              [87.587, 26.378],
              [87.8707, 26.4607],
              [88.0742, 26.4539],
              [88.1691, 26.744],
              [88.0769, 26.9918],
              [87.9707, 27.1027],
              [88.0351, 27.3226],
              [88.0233, 27.4949],
              [88.1597, 27.7741],
              [88.1182, 27.8609],
              [88.1267, 27.9504],
              [88.3788, 27.9826],
              [88.6105, 28.1058],
              [88.8177, 27.9947],
              [88.8539, 27.8437],
              [88.741, 27.5457],
              [88.7736, 27.4085],
              [88.8923, 27.3155],
              [88.7301, 27.151],
              [88.9545, 26.9126],
              [89.1846, 26.8106],
              [89.3417, 26.8543],
              [89.8221, 26.701],
              [90.0892, 26.7417],
              [90.1774, 26.8321],
              [90.3826, 26.8918],
              [90.5878, 26.7801],
              [91.1982, 26.8023],
              [91.4063, 26.8695],
              [91.5206, 26.7976],
              [91.7953, 26.8535],
              [92.0359, 26.8548],
              [92.0839, 26.9373],
              [91.9959, 27.158],
              [92.0848, 27.3042],
              [91.9751, 27.4724],
              [91.7501, 27.4162],
              [91.5951, 27.5464],
              [91.6329, 27.7594],
              [91.9751, 27.7266],
              [92.0697, 27.794],
              [92.3039, 27.7862],
              [92.6285, 27.9158],
              [92.6548, 28.1058],
              [92.8503, 28.1919],
              [93.0932, 28.3666],
              [93.1554, 28.4949],
              [93.4462, 28.6719],
              [93.6255, 28.6724],
              [94.0268, 28.8642],
              [94.2502, 28.9331],
              [94.2708, 29.0979],
              [94.6304, 29.3195],
              [94.7766, 29.1667],
              [95.1915, 29.0968],
              [95.5113, 29.1318],
              [95.7448, 29.3404],
              [96.142, 29.3685],
              [96.4576, 28.9946],
              [96.598, 28.7099],
              [96.9658, 28.3305],
              [97.1155, 28.3666],
              [97.3235, 28.2175],
              [97.3335, 27.8942],
              [97.2229, 27.8997],
              [96.8702, 27.6191],
              [96.9051, 27.4084],
              [97.1348, 27.1273],
              [97.0268, 27.0915],
              [96.8653, 27.1716],
              [96.7589, 27.3417],
              [96.1426, 27.2575],
              [96.0132, 27.1908],
              [95.8889, 27.027],
              [95.778, 26.9951],
              [95.3927, 26.6918],
              [95.1191, 26.6042],
              [95.0406, 26.4756],
              [95.0463, 26.248],
              [95.1395, 26.0299],
              [95.0006, 25.922],
              [95.0088, 25.7371],
              [94.8508, 25.5628],
              [94.608, 25.3946],
              [94.5501, 25.2453],
              [94.6891, 25.1385],
              [94.7086, 25.0259],
              [94.5895, 24.7473],
              [94.5163, 24.705],
              [94.29, 24.3168],
              [94.1903, 23.9953],
              [94.0997, 23.8426],
              [93.9979, 23.917],
              [93.6124, 24.0091],
              [93.4567, 23.96],
              [93.303, 24.0411],
              [93.4324, 23.6473],
              [93.3749, 23.1297],
              [93.0795, 22.7727],
              [93.0992, 22.512],
              [93.169, 22.2469],
              [92.9672, 22.0627],
              [92.8539, 22.0241],
              [92.7081, 22.148],
              [92.5759, 21.9776],
              [92.577, 22.1687],
              [92.4959, 22.6956],
              [92.3787, 22.9004],
              [92.3276, 23.1714],
              [92.3569, 23.2891],
              [92.2528, 23.6093],
              [92.1508, 23.7317],
              [92.0035, 23.6474],
              [91.9408, 23.4952],
              [91.7437, 23.2723],
              [91.7956, 23.0895],
              [91.6947, 22.9881],
              [91.5366, 22.9819],
              [91.4636, 23.1841],
              [91.3005, 23.1423],
              [91.2273, 23.4688],
              [91.1408, 23.6121],
              [91.2163, 23.9084],
              [91.363, 24.0998],
              [91.5965, 24.105],
              [91.9059, 24.2606],
              [91.95, 24.3754],
              [92.1076, 24.406],
              [92.2338, 24.7775],
              [92.3807, 24.8367],
              [92.4581, 24.9533],
              [92.3035, 25.0743],
              [92.0018, 25.183],
              [91.471, 25.1339],
              [91.2357, 25.2019],
              [90.822, 25.1423],
              [90.738, 25.1694],
              [90.3646, 25.15],
              [89.9081, 25.2969],
              [89.795, 25.3742],
              [89.8344, 25.6348],
              [89.7831, 25.8144],
              [89.8301, 25.908],
              [89.6584, 26.2079],
              [89.6029, 26.0553],
              [89.3515, 25.9969],
              [89.1316, 26.1338],
              [89.0437, 26.3811],
              [88.808, 26.2334],
              [88.6563, 26.4151],
              [88.4955, 26.3781],
              [88.3087, 26.2061],
              [88.1638, 26.1405],
              [88.0744, 25.9081],
              [88.4194, 25.6538],
              [88.5303, 25.5002],
              [88.7723, 25.502],
              [88.8986, 25.1693],
              [88.4315, 25.173],
              [88.3228, 24.8747],
              [88.2025, 24.9453],
              [88.0218, 24.6456],
              [88.1388, 24.4953],
              [88.4754, 24.3155],
              [88.7375, 24.2871],
              [88.6741, 24.0826],
              [88.7142, 23.9252],
              [88.5936, 23.8669],
              [88.5401, 23.65],
              [88.7194, 23.4681],
              [88.6859, 23.2933],
              [88.9547, 23.1838],
              [88.8428, 22.9646],
              [88.9445, 22.848],
              [88.9081, 22.7408],
              [89.0604, 22.1299],
              [88.993, 21.9127],
              [89.0827, 21.6173],
              [88.8816, 21.672],
              [88.7625, 21.5559],
              [88.3931, 21.6002],
              [88.2493, 21.5583],
              [88.2038, 21.8054],
              [88.0508, 21.985],
              [87.9705, 21.8365],
              [87.7038, 21.6555],
              [87.2009, 21.552],
              [86.9177, 21.3274],
              [86.844, 21.0822],
              [87.0036, 20.657],
              [86.7339, 20.4622],
              [86.7017, 20.2847],
              [86.4951, 20.1841],
              [86.3724, 19.965],
              [86.2686, 19.9103],
              [85.7444, 19.7657],
              [85.5492, 19.6906],
              [85.5703, 19.8756],
              [85.4636, 19.9029],
              [85.221, 19.736],
              [85.1045, 19.5149],
              [85.1804, 19.475],
              [84.8721, 19.2199],
              [84.4237, 18.6365],
              [84.2817, 18.5256],
              [84.0774, 18.2716],
              [83.6139, 18.0452],
              [83.4312, 17.8542],
              [83.2389, 17.593],
              [82.4749, 17.206],
              [82.2993, 17.0303],
              [82.2679, 16.8675],
              [82.3656, 16.8116],
              [82.3074, 16.5798],
              [81.9927, 16.4084],
              [81.763, 16.3226],
              [81.3174, 16.3675],
              [81.2485, 16.3201],
              [81.1554, 15.9855],
              [80.9989, 15.8467],
              [80.6904, 15.9007],
              [80.4691, 15.8347],
              [80.2632, 15.6745],
              [80.0906, 15.3049],
              [80.0545, 15.0141],
              [80.1107, 14.7048],
              [80.197, 14.565],
              [80.1211, 14.242],
              [80.1639, 13.988],
              [80.2372, 13.8397],
              [80.1194, 13.5007],
              [80.327, 13.4304],
              [80.3328, 13.1981],
              [80.2858, 13.0676],
              [80.2287, 12.675],
              [80.156, 12.4745],
              [79.8618, 12.022],
              [79.752, 11.577],
              [79.8553, 11.1599],
              [79.8407, 10.9501],
              [79.8638, 10.3787],
              [79.7598, 10.2582],
              [79.3981, 10.3247],
              [79.2363, 10.1666],
              [79.2791, 10.0358],
              [78.9808, 9.6672],
              [78.9133, 9.4743],
              [78.9568, 9.2748],
              [78.6188, 9.1405],
              [78.4499, 9.1166],
              [78.2398, 8.9656],
              [78.1779, 8.8611],
              [78.1283, 8.488],
              [78.0628, 8.3732],
              [77.5109, 8.076],
              [77.3156, 8.1278],
              [76.9959, 8.3681],
              [76.544, 8.9125],
              [76.3118, 9.493],
              [76.3592, 9.9154],
              [76.1775, 10.1706],
              [76.0524, 10.514],
              [75.922, 10.7656],
              [75.8328, 11.0978],
              [75.6952, 11.42],
              [75.6165, 11.4736],
              [75.5308, 11.6937],
              [75.2904, 11.9811],
              [75.1937, 12.0223],
              [75.1309, 12.2078],
              [74.9553, 12.5373],
              [74.8176, 12.8618],
              [74.6146, 13.8355],
              [74.5047, 14.0102],
              [74.3942, 14.4108],
              [74.2632, 14.7016],
              [74.1113, 14.7803],
              [73.9473, 15.1608],
              [73.8859, 15.4352],
              [73.7663, 15.4973],
              [73.5876, 15.9029],
              [73.447, 16.0689],
              [73.3192, 16.5098],
              [73.3216, 16.6925],
              [73.2347, 17.3023],
              [73.1317, 17.5394],
              [73.1232, 17.7233],
              [72.981, 18.0806],
              [72.9774, 18.2951],
              [72.8897, 18.4413],
              [72.9212, 18.5549],
              [72.8524, 18.7699],
              [72.9269, 18.8266],
              [72.8242, 19.0535],
              [72.718, 19.6067],
              [72.6501, 19.842],
              [72.7482, 20.2803],
              [72.8792, 20.5124],
              [72.945, 20.77],
              [72.7839, 21.0404],
              [72.7996, 21.1288],
              [72.6061, 21.2666],
              [72.6196, 21.4874],
              [72.7981, 21.6735],
              [72.5321, 21.7139],
              [72.5071, 21.9623],
              [72.5531, 22.2825],
              [72.3172, 22.1849],
              [72.2577, 22.0122],
              [72.1281, 21.9835],
              [72.166, 21.7939],
              [72.2957, 21.6539],
              [72.2422, 21.4808],
              [72.1045, 21.315],
              [72.1047, 21.2051],
              [71.7176, 21.032],
              [71.4623, 20.8853],
              [70.9824, 20.7102],
              [70.8402, 20.7019],
              [70.5415, 20.8078],
              [70.3367, 20.9271],
              [70.0576, 21.1492],
              [69.8147, 21.4337],
              [69.4785, 21.7526],
              [69.1984, 21.9797],
              [68.9942, 22.2039],
              [68.9744, 22.4047],
              [69.2057, 22.3987],
              [69.3599, 22.3292],
              [69.6161, 22.3559],
              [69.9621, 22.5353],
              [70.1785, 22.562],
              [70.3988, 22.9455],
              [70.2693, 22.9586],
              [69.8332, 22.8585],
              [69.7158, 22.7469],
              [69.2388, 22.8419],
              [68.8662, 23.02],
              [68.5523, 23.2597],
              [68.561, 23.3639],
              [68.4237, 23.4458],
              [68.1637, 23.7577],
              [68.183, 23.8421],
              [68.4316, 23.9671],
              [68.7245, 23.9652],
              [68.7259, 24.2892],
              [68.8903, 24.3195],
              [68.9808, 24.2554],
              [69.5631, 24.2768],
              [69.6706, 24.1887],
              [69.972, 24.1652],
              [70.1097, 24.3049],
              [70.5629, 24.4241],
              [70.5602, 24.2873],
              [70.7555, 24.2314],
              [70.9177, 24.3617],
              [70.9548, 24.5845],
              [71.0639, 24.6826],
              [70.8933, 25.0019],
              [70.8599, 25.1394],
              [70.6466, 25.4314],
              [70.6575, 25.6336],
              [70.2646, 25.6973],
              [70.0646, 25.9803],
              [70.132, 26.1805],
              [70.1581, 26.5301],
              [70.0561, 26.5891],
              [69.8156, 26.5803],
              [69.5042, 26.7352],
              [69.4651, 26.8078],
              [69.5756, 27.1884],
              [69.848, 27.4104],
              [70.0169, 27.6006],
              [70.0907, 27.7936],
              [70.3419, 28.0115],
              [70.4562, 28.0398],
              [70.6332, 27.9316],
              [70.7104, 27.7412],
              [70.8316, 27.7015],
              [71.3114, 27.8617],
              [71.5607, 27.8685],
              [71.8609, 27.9502],
              [71.8916, 28.0971],
              [72.1499, 28.3538],
              [72.2565, 28.6456],
              [72.3822, 28.784],
              [72.9015, 29.0226],
              [73.2329, 29.5366],
              [73.3703, 29.9273],
              [73.7397, 30.0485],
              [73.9444, 30.1883],
              [73.8518, 30.3727],
              [74.1637, 30.6649],
              [74.3298, 30.8996],
              [74.5193, 30.9625],
              [74.601, 31.0566],
              [74.5064, 31.1754],
              [74.6119, 31.4402],
              [74.4894, 31.7112],
              [74.5656, 31.8405],
              [74.8286, 32.0254],
              [74.9557, 32.0322],
              [75.2947, 32.1489],
              [75.359, 32.2617],
              [75.0237, 32.4663],
              [74.725, 32.4608],
              [74.6296, 32.5881],
              [74.6497, 32.7231],
              [74.419, 32.7846],
              [74.322, 32.972],
              [74.1539, 33.0402],
              [74.0023, 33.1777],
              [74.1055, 33.3452],
              [74.1416, 33.5494],
              [73.9767, 33.6484],
              [74.0346, 33.8286],
              [74.2395, 33.901],
              [74.2284, 34.0129],
              [73.9458, 34.0098],
              [73.9983, 34.1968],
              [73.7749, 34.371],
              [73.9622, 34.6682],
              [74.2858, 34.7689],
              [75.0178, 34.6297],
              [75.2129, 34.645],
              [75.3481, 34.5572],
              [75.7771, 34.5038],
              [76.0234, 34.6772],
              [76.5532, 34.7258],
              [76.9211, 34.9207],
              [77.049, 35.1104],
              [77.8003, 35.4954],
              [78.0556, 35.4529],
              [78.0012, 35.2689],
              [78.13, 35.0554],
              [78.2732, 34.6589],
              [78.3794, 34.5787],
              [78.6073, 34.5465],
              [78.9854, 34.2864],
              [78.9032, 34.1581],
              [78.7301, 34.0793],
              [78.7941, 33.7439],
              [78.7814, 33.5528],
              [78.9733, 33.3096],
              [79.3819, 33.1273],
              [79.3342, 32.9568],
              [79.5, 32.7468],
              [79.4764, 32.6454],
              [79.276, 32.4902],
              [79.1129, 32.4724],
              [78.9434, 32.3464],
              [78.6302, 32.578],
              [78.4475, 32.5663],
              [78.458, 32.2297],
              [78.7446, 31.9642],
              [78.6709, 31.7705],
              [78.8189, 31.6074],
              [78.6995, 31.51],
              [78.7385, 31.335],
              [78.9762, 31.32],
              [79.0633, 31.4336],
              [79.1984, 31.3249],
              [79.2957, 31.1241],
              [79.5296, 30.9536],
              [79.8335, 30.9615],
              [80.0625, 30.7848],
              [80.2528, 30.565],
              [80.7231, 30.392],
              [80.996, 30.197],
            ],
          ],
        ],
      },
      properties: {
        NAME: "India",
        NAME_LONG: "India",
        ADM0_A3: "IND",
        ISO_A2: "IN",
        ISO_A3: "IND",
        WB_A2: "IN",
        WB_A3: "IND",
      },
      id: 104,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.2471, 55.0693],
            [-7.4053, 55.0036],
            [-7.5517, 54.7547],
            [-7.9066, 54.6613],
            [-8.156, 54.4391],
            [-7.837, 54.2043],
            [-7.3103, 54.1147],
            [-7.0493, 54.4115],
            [-6.8239, 54.2323],
            [-6.6403, 54.168],
            [-6.6165, 54.0373],
            [-6.2699, 54.0979],
            [-6.379, 53.9169],
            [-6.2432, 53.8651],
            [-6.2228, 53.6461],
            [-6.0924, 53.5771],
            [-6.097, 53.2855],
            [-5.9966, 52.9649],
            [-6.2153, 52.666],
            [-6.2091, 52.5458],
            [-6.4481, 52.3559],
            [-6.3699, 52.18],
            [-6.7863, 52.21],
            [-6.8971, 52.152],
            [-7.4355, 52.1257],
            [-7.5899, 51.9912],
            [-8.3017, 51.7582],
            [-8.5392, 51.6484],
            [-9.1077, 51.5494],
            [-9.425, 51.5576],
            [-9.8066, 51.4457],
            [-9.7703, 51.5922],
            [-10.2346, 51.851],
            [-10.2758, 52.2681],
            [-9.8372, 52.2543],
            [-9.8484, 52.4284],
            [-9.577, 52.5704],
            [-9.4376, 52.9667],
            [-9.2722, 53.1467],
            [-8.9635, 53.1518],
            [-9.0221, 53.2755],
            [-9.5117, 53.2283],
            [-10.0257, 53.3854],
            [-10.1527, 53.5436],
            [-9.9307, 53.5998],
            [-9.9084, 53.7621],
            [-9.5664, 53.7963],
            [-9.8385, 53.9636],
            [-9.9882, 54.2131],
            [-9.7855, 54.3381],
            [-9.2095, 54.2676],
            [-8.486, 54.2886],
            [-8.6129, 54.3755],
            [-8.2873, 54.4794],
            [-8.1208, 54.6466],
            [-8.5969, 54.6193],
            [-8.7955, 54.6984],
            [-8.4907, 54.852],
            [-8.4566, 54.9954],
            [-8.263, 55.1611],
            [-7.8142, 55.2018],
            [-7.5278, 55.1326],
            [-7.5136, 55.2744],
            [-7.3325, 55.3728],
            [-6.9396, 55.2391],
            [-7.2471, 55.0693],
          ],
        ],
      },
      properties: {
        NAME: "Ireland",
        NAME_LONG: "Ireland",
        ADM0_A3: "IRL",
        ISO_A2: "IE",
        ISO_A3: "IRL",
        WB_A2: "IE",
        WB_A3: "IRL",
      },
      id: 107,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [56.1714, 26.9068],
              [55.947, 26.6963],
              [55.8766, 26.7429],
              [55.5029, 26.5949],
              [55.3519, 26.6474],
              [55.7368, 26.7824],
              [56.041, 26.9757],
              [56.1714, 26.9068],
            ],
          ],
          [
            [
              [46.1359, 38.8637],
              [46.3919, 38.9224],
              [46.514, 38.8822],
              [46.8662, 39.1654],
              [47.0205, 39.19],
              [47.1022, 39.3084],
              [47.2914, 39.3796],
              [47.8469, 39.6853],
              [48.052, 39.6501],
              [48.3389, 39.3789],
              [48.1246, 39.2976],
              [48.1422, 39.1812],
              [48.3068, 39.0454],
              [48.0585, 38.9484],
              [48.013, 38.8216],
              [48.2104, 38.7298],
              [48.6079, 38.396],
              [48.8743, 38.4341],
              [48.9077, 38.0835],
              [49.0145, 37.7392],
              [49.1325, 37.6234],
              [49.4417, 37.4949],
              [49.9353, 37.4536],
              [50.1488, 37.4039],
              [50.3223, 37.1533],
              [50.4789, 37.035],
              [51.0886, 36.737],
              [51.909, 36.5827],
              [52.7793, 36.7404],
              [53.4924, 36.8879],
              [53.701, 36.8166],
              [54.0143, 36.8211],
              [54.0335, 36.9491],
              [53.9137, 37.3428],
              [54.1866, 37.3237],
              [54.6169, 37.433],
              [54.7837, 37.5175],
              [54.8566, 37.7611],
              [55.1303, 37.9523],
              [55.4231, 38.0759],
              [55.7087, 38.1187],
              [55.9784, 38.0659],
              [56.2997, 38.0802],
              [56.4365, 38.2551],
              [56.739, 38.2696],
              [57.0384, 38.1873],
              [57.2183, 38.2651],
              [57.3524, 37.9675],
              [57.6912, 37.9203],
              [58.1812, 37.7749],
              [58.3718, 37.6304],
              [58.5143, 37.6915],
              [58.861, 37.6681],
              [59.3517, 37.4938],
              [59.3704, 37.3194],
              [59.5095, 37.1898],
              [60.0192, 37.0258],
              [60.3423, 36.6371],
              [61.0755, 36.6478],
              [61.186, 36.5777],
              [61.1525, 36.4063],
              [61.2228, 36.1175],
              [61.174, 36.0065],
              [61.2474, 35.8762],
              [61.2697, 35.6185],
              [61.29, 35.5481],
              [61.1083, 35.278],
              [61.1159, 35.0599],
              [61.0655, 34.8147],
              [60.9475, 34.638],
              [60.7144, 34.5374],
              [60.8905, 34.3189],
              [60.6437, 34.3066],
              [60.4868, 34.0943],
              [60.528, 33.8414],
              [60.5118, 33.6384],
              [60.6556, 33.5599],
              [60.8796, 33.5487],
              [60.8186, 33.4049],
              [60.5676, 33.1513],
              [60.5775, 32.9943],
              [60.8303, 32.2489],
              [60.7749, 32.0272],
              [60.7926, 31.6601],
              [60.855, 31.4827],
              [61.6612, 31.3819],
              [61.7492, 31.3024],
              [61.8263, 31.0346],
              [61.7852, 30.8314],
              [60.8444, 29.8582],
              [61.2792, 29.4066],
              [61.4878, 29.0807],
              [61.653, 28.7563],
              [61.8928, 28.5426],
              [62.3628, 28.4189],
              [62.5756, 28.229],
              [62.7664, 28.2115],
              [62.7357, 27.995],
              [62.8147, 27.495],
              [62.756, 27.3481],
              [62.8006, 27.2252],
              [63.167, 27.2587],
              [63.2515, 27.219],
              [63.2649, 26.89],
              [63.1823, 26.8302],
              [63.1413, 26.6254],
              [62.7536, 26.6442],
              [62.292, 26.5012],
              [62.2434, 26.3506],
              [61.8571, 26.2424],
              [61.8099, 26.1674],
              [61.7601, 25.8376],
              [61.6603, 25.7692],
              [61.62, 25.2855],
              [61.5882, 25.2021],
              [61.4117, 25.0594],
              [61.0306, 25.2098],
              [60.6335, 25.275],
              [60.5497, 25.4469],
              [60.3626, 25.3306],
              [60.082, 25.3659],
              [59.9038, 25.3299],
              [59.7749, 25.3899],
              [59.538, 25.3997],
              [59.4539, 25.4616],
              [59.1172, 25.3942],
              [58.9943, 25.4196],
              [58.8137, 25.5636],
              [58.5464, 25.5937],
              [58.0731, 25.5636],
              [57.9632, 25.6932],
              [57.3262, 25.777],
              [57.1646, 26.0949],
              [57.0763, 26.4475],
              [57.09, 26.6188],
              [56.9733, 26.9688],
              [56.8088, 27.1396],
              [56.353, 27.2016],
              [56.1237, 27.1606],
              [55.9393, 27.0267],
              [55.6848, 27.003],
              [55.5545, 26.8105],
              [55.2708, 26.7925],
              [54.8015, 26.4982],
              [54.62, 26.5082],
              [54.3894, 26.6051],
              [54.3081, 26.715],
              [53.9951, 26.7456],
              [53.7161, 26.7086],
              [53.4915, 26.8543],
              [53.4121, 26.9936],
              [53.072, 27.0969],
              [52.6013, 27.3529],
              [52.5676, 27.5407],
              [52.4231, 27.6466],
              [52.2449, 27.6814],
              [52.0112, 27.8327],
              [51.5851, 27.8493],
              [51.3803, 27.9914],
              [51.2648, 28.1605],
              [51.2648, 28.2705],
              [51.1419, 28.4008],
              [51.0588, 28.7359],
              [50.813, 28.9147],
              [50.9202, 29.069],
              [50.8403, 29.1406],
              [50.6438, 29.1435],
              [50.6553, 29.4491],
              [50.397, 29.6547],
              [50.1449, 29.9381],
              [50.0734, 30.1957],
              [49.9146, 30.2039],
              [49.6008, 30.0301],
              [49.4798, 30.1561],
              [49.2451, 30.1565],
              [49.0037, 30.3094],
              [49.1668, 30.5088],
              [48.9815, 30.5156],
              [48.8623, 30.3456],
              [48.9348, 30.0797],
              [48.6406, 30.0381],
              [48.531, 29.9613],
              [48.4531, 30.0014],
              [48.397, 30.221],
              [48.1577, 30.4263],
              [48.0136, 30.4639],
              [48.0154, 30.9762],
              [47.6729, 30.9947],
              [47.6789, 31.4079],
              [47.8313, 31.7618],
              [47.6681, 32.0124],
              [47.3958, 32.337],
              [47.3433, 32.4587],
              [47.121, 32.461],
              [46.7159, 32.756],
              [46.2734, 32.9595],
              [46.097, 32.9543],
              [46.1553, 33.2602],
              [46.0362, 33.3679],
              [45.886, 33.6309],
              [45.6588, 33.6817],
              [45.4199, 33.9944],
              [45.5344, 34.1282],
              [45.5576, 34.312],
              [45.448, 34.3621],
              [45.5007, 34.5917],
              [45.835, 34.8902],
              [45.857, 35.0213],
              [46.0092, 35.0609],
              [46.1655, 35.1899],
              [45.964, 35.4942],
              [46.0604, 35.8572],
              [45.8142, 35.8093],
              [45.3483, 36.0875],
              [45.2389, 36.403],
              [45.1108, 36.4025],
              [44.9914, 36.5337],
              [44.9962, 36.7417],
              [44.8312, 36.7919],
              [44.8808, 37.0249],
              [44.7661, 37.1419],
              [44.8016, 37.2927],
              [44.5659, 37.4472],
              [44.5961, 37.7164],
              [44.3779, 37.8541],
              [44.2196, 37.8753],
              [44.4247, 38.377],
              [44.2895, 38.3823],
              [44.275, 38.8436],
              [44.1392, 38.994],
              [44.1866, 39.1451],
              [44.0777, 39.2001],
              [44.0614, 39.4003],
              [44.4048, 39.4344],
              [44.4595, 39.6985],
              [44.5657, 39.7659],
              [44.807, 39.6399],
              [44.957, 39.4345],
              [45.1789, 39.2198],
              [45.3482, 39.1639],
              [45.4386, 39.0042],
              [46.1359, 38.8637],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Iran",
        NAME_LONG: "Iran",
        ADM0_A3: "IRN",
        ISO_A2: "IR",
        ISO_A3: "IRN",
        WB_A2: "IR",
        WB_A3: "IRN",
      },
      id: 108,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [44.7661, 37.1419],
            [44.8808, 37.0249],
            [44.8312, 36.7919],
            [44.9962, 36.7417],
            [44.9914, 36.5337],
            [45.1108, 36.4025],
            [45.2389, 36.403],
            [45.3483, 36.0875],
            [45.8142, 35.8093],
            [46.0604, 35.8572],
            [45.964, 35.4942],
            [46.1655, 35.1899],
            [46.0092, 35.0609],
            [45.857, 35.0213],
            [45.835, 34.8902],
            [45.5007, 34.5917],
            [45.448, 34.3621],
            [45.5576, 34.312],
            [45.5344, 34.1282],
            [45.4199, 33.9944],
            [45.6588, 33.6817],
            [45.886, 33.6309],
            [46.0362, 33.3679],
            [46.1553, 33.2602],
            [46.097, 32.9543],
            [46.2734, 32.9595],
            [46.7159, 32.756],
            [47.121, 32.461],
            [47.3433, 32.4587],
            [47.3958, 32.337],
            [47.6681, 32.0124],
            [47.8313, 31.7618],
            [47.6789, 31.4079],
            [47.6729, 30.9947],
            [48.0154, 30.9762],
            [48.0136, 30.4639],
            [48.1577, 30.4263],
            [48.397, 30.221],
            [48.4531, 30.0014],
            [48.531, 29.9613],
            [48.2092, 30.0245],
            [47.948, 29.994],
            [47.6742, 30.0982],
            [47.3582, 30.0921],
            [47.1105, 29.9609],
            [46.8534, 29.4446],
            [46.5324, 29.0957],
            [46.3575, 29.0631],
            [45.4349, 29.1362],
            [44.6918, 29.2018],
            [43.8901, 29.7674],
            [42.8587, 30.4947],
            [42.0754, 31.0799],
            [40.4241, 31.9205],
            [40.0293, 31.9944],
            [39.1464, 32.1181],
            [39.292, 32.2445],
            [39.2358, 32.3529],
            [39.0463, 32.3085],
            [39.0572, 32.4966],
            [38.7745, 33.3717],
            [39.8804, 33.9256],
            [40.6905, 34.3315],
            [40.988, 34.4285],
            [41.1957, 34.7685],
            [41.1915, 35.1821],
            [41.2613, 35.4943],
            [41.3635, 35.6552],
            [41.3437, 35.8577],
            [41.2406, 36.043],
            [41.277, 36.3548],
            [41.4149, 36.5274],
            [41.8438, 36.6179],
            [42.2816, 36.994],
            [42.3572, 37.11],
            [42.5613, 37.1466],
            [42.7716, 37.3749],
            [42.937, 37.3202],
            [43.0837, 37.3688],
            [43.4796, 37.2434],
            [43.8936, 37.2249],
            [43.9904, 37.3125],
            [44.2232, 37.2541],
            [44.2343, 36.9837],
            [44.5782, 37.1664],
            [44.7661, 37.1419],
          ],
        ],
      },
      properties: {
        NAME: "Iraq",
        NAME_LONG: "Iraq",
        ADM0_A3: "IRQ",
        ISO_A2: "IQ",
        ISO_A3: "IRQ",
        WB_A2: "IQ",
        WB_A3: "IRQ",
      },
      id: 109,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.0229, 66.5366],
            [-15.7751, 66.2895],
            [-14.9591, 66.2965],
            [-15.076, 66.0496],
            [-14.6773, 66.0562],
            [-14.6047, 65.9639],
            [-14.823, 65.8159],
            [-13.6096, 65.5142],
            [-13.6877, 65.3064],
            [-13.527, 65.0555],
            [-14.0485, 64.7197],
            [-14.4935, 64.6583],
            [-14.5105, 64.4423],
            [-14.9955, 64.2626],
            [-15.3636, 64.3368],
            [-15.464, 64.2513],
            [-15.9921, 64.1445],
            [-16.3505, 63.9656],
            [-16.8958, 63.8078],
            [-17.8603, 63.737],
            [-17.965, 63.5225],
            [-18.732, 63.3967],
            [-19.2873, 63.4387],
            [-19.7699, 63.5342],
            [-20.1857, 63.5444],
            [-20.4421, 63.6714],
            [-21.1683, 63.8756],
            [-21.6599, 63.8319],
            [-22.3364, 63.8632],
            [-22.6859, 63.9457],
            [-21.9634, 64.0823],
            [-21.9626, 64.2973],
            [-22.391, 64.6378],
            [-22.3197, 64.7539],
            [-23.2462, 64.8305],
            [-23.8336, 64.7259],
            [-24.06, 64.8911],
            [-22.6744, 65.0076],
            [-22.5577, 65.1689],
            [-22.2013, 65.3177],
            [-22.1992, 65.4323],
            [-22.9258, 65.5732],
            [-23.8975, 65.4046],
            [-24.1967, 65.5012],
            [-24.0499, 65.8035],
            [-23.8666, 65.8756],
            [-23.7926, 66.0704],
            [-23.4614, 66.2042],
            [-23.0277, 66.1087],
            [-23.0981, 66.3183],
            [-22.8503, 66.467],
            [-22.3767, 66.4326],
            [-22.2141, 66.2897],
            [-21.7499, 66.1858],
            [-21.723, 66.0822],
            [-21.3185, 65.9878],
            [-21.4179, 65.7635],
            [-21.3699, 65.4871],
            [-21.1952, 65.4081],
            [-20.9181, 65.6014],
            [-20.2633, 65.7445],
            [-20.4489, 66.0294],
            [-20.0306, 66.0834],
            [-19.6504, 65.765],
            [-19.3627, 66.0884],
            [-18.9447, 66.1907],
            [-18.6923, 66.17],
            [-18.54, 65.9742],
            [-18.2281, 65.9394],
            [-18.297, 66.1796],
            [-17.9127, 66.1523],
            [-17.673, 66.0071],
            [-17.4193, 65.994],
            [-17.2174, 66.1942],
            [-16.9197, 66.1243],
            [-16.487, 66.1895],
            [-16.5433, 66.507],
            [-16.0229, 66.5366],
          ],
        ],
      },
      properties: {
        NAME: "Iceland",
        NAME_LONG: "Iceland",
        ADM0_A3: "ISL",
        ISO_A2: "IS",
        ISO_A3: "ISL",
        WB_A2: "IS",
        WB_A3: "ISL",
      },
      id: 110,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.7576, 32.7443],
            [35.5627, 32.6442],
            [35.561, 32.3847],
            [35.2086, 32.5426],
            [35.0644, 32.4631],
            [34.9461, 32.1773],
            [34.9761, 31.9482],
            [35.1814, 31.8041],
            [34.9736, 31.6304],
            [34.8672, 31.3964],
            [34.9274, 31.3449],
            [35.2233, 31.381],
            [35.4581, 31.4919],
            [35.3957, 31.2577],
            [35.4385, 31.1037],
            [35.14, 30.4302],
            [35.1453, 30.1549],
            [34.9556, 29.559],
            [34.8867, 29.4901],
            [34.8244, 29.7417],
            [34.5995, 30.3445],
            [34.5338, 30.4002],
            [34.4804, 30.6512],
            [34.2484, 31.2114],
            [34.4812, 31.5831],
            [34.7119, 31.9516],
            [34.8377, 32.2807],
            [34.9475, 32.8142],
            [35.0796, 32.9058],
            [35.1052, 33.089],
            [35.4801, 33.0874],
            [35.52, 33.2222],
            [35.8211, 33.4067],
            [35.8036, 33.2485],
            [35.8881, 32.9449],
            [35.7576, 32.7443],
          ],
        ],
      },
      properties: {
        NAME: "Israel",
        NAME_LONG: "Israel",
        ADM0_A3: "ISR",
        ISO_A2: "IL",
        ISO_A3: "ISR",
        WB_A2: "IL",
        WB_A3: "ISR",
      },
      id: 111,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [15.6424, 38.2623],
              [15.2066, 37.7477],
              [15.0967, 37.4945],
              [15.1107, 37.3218],
              [15.3294, 37.0277],
              [15.1747, 36.9441],
              [15.0736, 36.6591],
              [14.7808, 36.6994],
              [14.4873, 36.7934],
              [14.2788, 37.044],
              [14.0886, 37.1177],
              [13.8944, 37.1008],
              [13.4526, 37.2988],
              [13.1741, 37.487],
              [12.9426, 37.5685],
              [12.6978, 37.5627],
              [12.489, 37.6827],
              [12.427, 37.797],
              [12.5042, 38.0112],
              [12.7254, 38.1259],
              [12.9011, 38.0281],
              [13.1009, 38.1901],
              [13.3102, 38.2195],
              [13.7908, 37.9736],
              [14.0205, 38.0494],
              [14.2732, 38.0151],
              [14.6346, 38.0815],
              [14.9071, 38.1877],
              [15.0879, 38.1282],
              [15.5129, 38.2976],
              [15.6424, 38.2623],
            ],
          ],
          [
            [
              [9.2383, 41.2488],
              [9.5462, 41.1209],
              [9.5733, 40.9064],
              [9.8267, 40.5196],
              [9.6261, 40.2631],
              [9.7323, 40.0839],
              [9.6449, 39.484],
              [9.5578, 39.1397],
              [9.2525, 39.2252],
              [9.0789, 39.2123],
              [9.0183, 38.9953],
              [8.8415, 38.8806],
              [8.6428, 38.89],
              [8.3718, 39.2306],
              [8.3869, 39.4698],
              [8.5552, 39.8527],
              [8.3983, 39.9053],
              [8.49, 40.1045],
              [8.4629, 40.3145],
              [8.312, 40.5781],
              [8.1338, 40.7289],
              [8.3102, 40.8507],
              [8.5205, 40.827],
              [8.7644, 40.9138],
              [9.0248, 41.1289],
              [9.2383, 41.2488],
            ],
          ],
          [
            [
              [13.701, 46.5197],
              [13.3653, 46.2903],
              [13.6228, 45.9664],
              [13.5813, 45.8092],
              [13.7787, 45.7434],
              [13.7118, 45.5932],
              [13.7262, 45.7038],
              [13.1314, 45.7689],
              [12.9439, 45.6286],
              [12.4878, 45.4567],
              [12.2727, 45.4636],
              [12.1688, 45.2622],
              [12.2961, 45.2266],
              [12.3762, 45.039],
              [12.527, 44.9433],
              [12.4446, 44.8205],
              [12.2536, 44.7513],
              [12.2837, 44.4876],
              [12.3688, 44.2507],
              [12.7103, 43.9829],
              [12.9473, 43.8976],
              [13.2533, 43.7032],
              [13.617, 43.5313],
              [13.8348, 43.1273],
              [13.9447, 42.7983],
              [14.0754, 42.5988],
              [14.7407, 42.0846],
              [15.1234, 41.9343],
              [15.4461, 41.9069],
              [16.0271, 41.9441],
              [16.1929, 41.7909],
              [15.9253, 41.64],
              [16.0217, 41.4278],
              [16.5939, 41.207],
              [17.0571, 41.0819],
              [17.4761, 40.8301],
              [18.007, 40.6507],
              [18.0374, 40.557],
              [18.2383, 40.4577],
              [18.4373, 40.2679],
              [18.5174, 40.1142],
              [18.384, 39.8139],
              [18.2113, 39.8393],
              [18.0073, 39.9861],
              [17.9972, 40.1204],
              [17.8477, 40.29],
              [17.5121, 40.3037],
              [17.2129, 40.4062],
              [17.0496, 40.5191],
              [16.9121, 40.4452],
              [16.6047, 40.0845],
              [16.6316, 39.9662],
              [16.4917, 39.8055],
              [16.5156, 39.6896],
              [16.7498, 39.62],
              [17.1587, 39.4062],
              [17.1222, 39.3383],
              [17.1277, 38.9288],
              [16.8386, 38.9182],
              [16.6106, 38.8166],
              [16.5388, 38.7233],
              [16.5696, 38.4295],
              [16.3399, 38.3008],
              [16.1694, 38.1432],
              [16.09, 37.9492],
              [15.7615, 37.9252],
              [15.6658, 37.9669],
              [15.6506, 38.241],
              [15.7865, 38.278],
              [15.9048, 38.4739],
              [15.8445, 38.6604],
              [16.1209, 38.7209],
              [16.2201, 38.8991],
              [16.083, 39.0753],
              [15.9954, 39.4388],
              [15.8675, 39.5639],
              [15.7819, 39.8686],
              [15.6102, 40.0732],
              [15.4101, 39.994],
              [15.1272, 40.1696],
              [14.9111, 40.2416],
              [14.9983, 40.3976],
              [14.7522, 40.6767],
              [14.5442, 40.6136],
              [14.2942, 40.8388],
              [14.0523, 40.8376],
              [13.8517, 41.1473],
              [13.7219, 41.2524],
              [13.5144, 41.2297],
              [13.3244, 41.2948],
              [13.0446, 41.2275],
              [12.8936, 41.3993],
              [12.6194, 41.4591],
              [12.448, 41.6308],
              [12.239, 41.7363],
              [12.1475, 41.9035],
              [11.8275, 42.0342],
              [11.6582, 42.2794],
              [11.1869, 42.5208],
              [10.99, 42.7103],
              [10.7313, 42.8044],
              [10.6814, 42.949],
              [10.4995, 42.9405],
              [10.5466, 43.1471],
              [10.437, 43.3886],
              [10.3263, 43.4769],
              [10.264, 43.8094],
              [10.1058, 44.0168],
              [9.7718, 44.0794],
              [9.2306, 44.3537],
              [8.7624, 44.4321],
              [8.4661, 44.3043],
              [8.2332, 44.1036],
              [8.1447, 43.9526],
              [7.9597, 43.8529],
              [7.5023, 43.7922],
              [7.4779, 43.8655],
              [7.6919, 44.0846],
              [7.6247, 44.1801],
              [7.3808, 44.1229],
              [7.0335, 44.2429],
              [6.8661, 44.3721],
              [6.8388, 44.5029],
              [7.019, 44.7389],
              [6.9828, 44.847],
              [6.7279, 44.9287],
              [6.6027, 45.1034],
              [6.8441, 45.1301],
              [7.0622, 45.2185],
              [7.1607, 45.4109],
              [6.9828, 45.5111],
              [6.9634, 45.6407],
              [6.7959, 45.7181],
              [6.8008, 45.8265],
              [7.0221, 45.9253],
              [7.1837, 45.8805],
              [7.5411, 45.9841],
              [7.8312, 45.9145],
              [8.1106, 46.127],
              [8.0773, 46.262],
              [8.4416, 46.4349],
              [8.4272, 46.2514],
              [8.6018, 46.1228],
              [9.0021, 46.0393],
              [9.2248, 46.2312],
              [9.2632, 46.4851],
              [9.6743, 46.2918],
              [10.1334, 46.414],
              [10.2954, 46.5511],
              [10.4538, 46.8644],
              [10.9969, 46.7691],
              [11.0919, 46.9124],
              [11.3138, 46.9873],
              [11.7464, 46.9725],
              [11.9436, 47.0382],
              [12.1112, 46.993],
              [12.405, 46.6901],
              [13.2311, 46.5522],
              [13.701, 46.5197],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Italy",
        NAME_LONG: "Italy",
        ADM0_A3: "ITA",
        ISO_A2: "IT",
        ISO_A3: "ITA",
        WB_A2: "IT",
        WB_A3: "ITA",
      },
      id: 112,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.2637, 18.0124],
            [-76.3074, 17.8701],
            [-76.5235, 17.8603],
            [-76.85, 17.9923],
            [-76.9553, 17.8334],
            [-77.1417, 17.8811],
            [-77.2161, 17.7167],
            [-77.441, 17.8647],
            [-77.7239, 17.8536],
            [-77.9563, 18.0322],
            [-78.0752, 18.1984],
            [-78.3407, 18.2219],
            [-78.3319, 18.3674],
            [-78.195, 18.4555],
            [-77.9814, 18.4414],
            [-77.9023, 18.5188],
            [-77.2726, 18.4594],
            [-77.1374, 18.4089],
            [-76.8949, 18.4093],
            [-76.7646, 18.273],
            [-76.3511, 18.156],
            [-76.2637, 18.0124],
          ],
        ],
      },
      properties: {
        NAME: "Jamaica",
        NAME_LONG: "Jamaica",
        ADM0_A3: "JAM",
        ISO_A2: "JM",
        ISO_A3: "JAM",
        WB_A2: "JM",
        WB_A3: "JAM",
      },
      id: 113,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.1464, 32.1181],
            [38.9633, 31.9944],
            [36.9595, 31.491],
            [37.48, 31.0073],
            [37.9811, 30.4995],
            [37.6345, 30.3128],
            [37.4702, 29.9946],
            [36.7562, 29.8655],
            [36.4771, 29.4946],
            [36.0164, 29.19],
            [34.9494, 29.3517],
            [34.9556, 29.559],
            [35.1453, 30.1549],
            [35.14, 30.4302],
            [35.4385, 31.1037],
            [35.3957, 31.2577],
            [35.4581, 31.4919],
            [35.5594, 31.7653],
            [35.5228, 32.0578],
            [35.561, 32.3847],
            [35.5627, 32.6442],
            [35.7576, 32.7443],
            [35.8957, 32.7133],
            [36.0699, 32.5166],
            [36.1774, 32.5273],
            [36.3731, 32.3864],
            [36.8194, 32.3168],
            [38.0567, 32.9943],
            [38.7745, 33.3717],
            [39.0572, 32.4966],
            [39.0463, 32.3085],
            [39.2358, 32.3529],
            [39.292, 32.2445],
            [39.1464, 32.1181],
          ],
        ],
      },
      properties: {
        NAME: "Jordan",
        NAME_LONG: "Jordan",
        ADM0_A3: "JOR",
        ISO_A2: "JO",
        ISO_A3: "JOR",
        WB_A2: "JO",
        WB_A3: "JOR",
      },
      id: 115,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [128.3097, 26.7047],
              [127.9403, 26.4477],
              [127.7775, 26.2333],
              [127.7313, 26.4399],
              [127.9375, 26.5321],
              [128.2259, 26.801],
              [128.3097, 26.7047],
            ],
          ],
          [
            [
              [129.721, 28.4349],
              [129.4202, 28.2499],
              [129.2277, 28.3046],
              [129.6262, 28.4827],
              [129.721, 28.4349],
            ],
          ],
          [
            [
              [130.499, 30.468],
              [130.6746, 30.3746],
              [130.5773, 30.2359],
              [130.3863, 30.3396],
              [130.499, 30.468],
            ],
          ],
          [
            [
              [130.1895, 32.5218],
              [130.2034, 32.3389],
              [130.012, 32.1906],
              [129.9753, 32.3554],
              [130.033, 32.5219],
              [130.1895, 32.5218],
            ],
          ],
          [
            [
              [128.8423, 32.7595],
              [128.8993, 32.6425],
              [128.6629, 32.6015],
              [128.6418, 32.7614],
              [128.8423, 32.7595],
            ],
          ],
          [
            [
              [130.9929, 33.8977],
              [131.0813, 33.635],
              [131.4177, 33.5817],
              [131.521, 33.6764],
              [131.7295, 33.5802],
              [131.7109, 33.2702],
              [131.8722, 33.2003],
              [131.9292, 32.7783],
              [131.6821, 32.5351],
              [131.4473, 31.8769],
              [131.4753, 31.7622],
              [131.3455, 31.395],
              [131.0718, 31.4496],
              [131.104, 31.3288],
              [130.924, 31.113],
              [130.7254, 31.047],
              [130.7953, 31.3444],
              [130.613, 31.6824],
              [130.5291, 31.5133],
              [130.6398, 31.1865],
              [130.4511, 31.2494],
              [130.2222, 31.2463],
              [130.3362, 31.6262],
              [130.1714, 31.7901],
              [130.2204, 31.9296],
              [130.1799, 32.0935],
              [130.3319, 32.1286],
              [130.562, 32.4271],
              [130.6413, 32.6075],
              [130.593, 32.8159],
              [130.4331, 32.9417],
              [130.4222, 33.0826],
              [130.2193, 33.1595],
              [130.1319, 33.102],
              [130.2436, 32.8753],
              [130.3394, 32.8566],
              [130.3417, 32.6673],
              [130.0938, 32.7949],
              [129.8949, 32.6627],
              [129.6981, 32.8395],
              [129.6324, 32.9791],
              [129.7285, 33.1223],
              [129.5521, 33.2258],
              [129.5804, 33.371],
              [129.8075, 33.3481],
              [129.8546, 33.5329],
              [130.0553, 33.4952],
              [130.3909, 33.6049],
              [130.4544, 33.8077],
              [130.6844, 33.9342],
              [130.9929, 33.8977],
            ],
          ],
          [
            [
              [134.1616, 34.3625],
              [134.4398, 34.2083],
              [134.6283, 34.1779],
              [134.5998, 34.0095],
              [134.7323, 33.826],
              [134.3886, 33.6236],
              [134.2732, 33.508],
              [134.1903, 33.2483],
              [133.9252, 33.4937],
              [133.7394, 33.5375],
              [133.4659, 33.4543],
              [133.2694, 33.3469],
              [133.2533, 33.2191],
              [133.0183, 33.024],
              [132.915, 32.778],
              [132.6234, 32.7995],
              [132.4723, 32.9337],
              [132.4518, 33.0529],
              [132.5368, 33.2426],
              [132.4201, 33.3044],
              [132.3811, 33.4661],
              [132.5006, 33.625],
              [132.6766, 33.7156],
              [132.7699, 33.9963],
              [132.9191, 34.0684],
              [133.127, 33.9333],
              [133.3226, 33.9906],
              [133.511, 33.9652],
              [133.6209, 34.0456],
              [133.6726, 34.2231],
              [133.9465, 34.3762],
              [134.1616, 34.3625],
            ],
          ],
          [
            [
              [134.9163, 34.3383],
              [134.7287, 34.1909],
              [134.6572, 34.2882],
              [134.799, 34.4468],
              [134.9163, 34.3383],
            ],
          ],
          [
            [
              [141.2761, 41.3532],
              [141.4575, 41.3775],
              [141.3908, 41.161],
              [141.4374, 40.6701],
              [141.7438, 40.3839],
              [141.9496, 39.9966],
              [142.0693, 39.5405],
              [141.9053, 39.3305],
              [141.9089, 39.1098],
              [141.6384, 38.9854],
              [141.5726, 38.7187],
              [141.4671, 38.5695],
              [141.4714, 38.4357],
              [141.0978, 38.3715],
              [140.9668, 38.1705],
              [140.9334, 37.8892],
              [141.0222, 37.727],
              [141.0418, 37.3771],
              [140.9603, 36.9662],
              [140.8044, 36.886],
              [140.6235, 36.5061],
              [140.5641, 36.2831],
              [140.646, 36.0133],
              [140.8416, 35.7424],
              [140.4835, 35.566],
              [140.3859, 35.381],
              [140.3942, 35.1966],
              [139.8677, 35.0133],
              [139.8242, 35.3284],
              [140.0059, 35.4734],
              [140.0796, 35.6036],
              [139.8068, 35.6382],
              [139.7786, 35.5019],
              [139.5266, 35.302],
              [139.4149, 35.3188],
              [139.146, 35.2352],
              [139.0865, 34.9973],
              [139.1409, 34.8706],
              [138.8353, 34.5959],
              [138.744, 34.7289],
              [138.8033, 35.1219],
              [138.5541, 35.0961],
              [138.5143, 34.9802],
              [138.3671, 34.9173],
              [138.216, 34.7123],
              [137.7922, 34.6394],
              [137.4276, 34.6677],
              [137.2979, 34.7972],
              [136.9156, 34.776],
              [136.8235, 34.9549],
              [136.6592, 34.984],
              [136.5195, 34.6809],
              [136.916, 34.434],
              [136.8464, 34.3076],
              [136.523, 34.2731],
              [136.299, 34.1543],
              [136.2732, 33.9714],
              [136.1047, 33.8914],
              [135.9599, 33.5931],
              [135.7656, 33.4815],
              [135.4448, 33.5508],
              [135.3916, 33.7136],
              [135.0655, 33.8856],
              [135.0945, 34.0676],
              [135.187, 34.1359],
              [135.105, 34.3181],
              [135.3197, 34.4288],
              [135.4144, 34.5581],
              [135.3413, 34.7085],
              [135.0458, 34.625],
              [134.7444, 34.7665],
              [134.3267, 34.7043],
              [133.9832, 34.5286],
              [133.9363, 34.4512],
              [133.7, 34.5259],
              [133.1658, 34.2923],
              [132.8362, 34.3111],
              [132.6563, 34.1993],
              [132.4968, 34.3625],
              [132.3835, 34.3657],
              [132.2225, 34.239],
              [132.2132, 33.9897],
              [132.0578, 33.8952],
              [131.7415, 34.0562],
              [131.4829, 34.032],
              [131.2722, 33.9268],
              [130.8616, 34.1127],
              [130.8716, 34.2792],
              [131.0747, 34.4248],
              [131.2362, 34.3823],
              [131.4143, 34.424],
              [131.5969, 34.6596],
              [131.8584, 34.7145],
              [132.0633, 34.9075],
              [132.3168, 35.0623],
              [132.4081, 35.1751],
              [132.6387, 35.2946],
              [132.6354, 35.441],
              [132.9697, 35.517],
              [133.1054, 35.5975],
              [133.4174, 35.4565],
              [133.5802, 35.5336],
              [133.7311, 35.5029],
              [134.2378, 35.5467],
              [134.537, 35.6722],
              [134.927, 35.6412],
              [135.2268, 35.7723],
              [135.4524, 35.5718],
              [135.6451, 35.4802],
              [135.7061, 35.5621],
              [135.9817, 35.6436],
              [136.0995, 35.7757],
              [135.9573, 35.9731],
              [136.1348, 36.2246],
              [136.4302, 36.4325],
              [136.64, 36.6626],
              [136.7606, 36.8706],
              [136.6703, 37.15],
              [136.7491, 37.3579],
              [137.2957, 37.5315],
              [137.2302, 37.2921],
              [137.1087, 37.2834],
              [137.0055, 36.8359],
              [137.3333, 36.7627],
              [137.4271, 36.922],
              [137.8967, 37.0575],
              [138.0975, 37.1711],
              [138.2449, 37.1838],
              [138.5506, 37.3779],
              [138.7656, 37.6347],
              [138.8577, 37.8278],
              [139.3045, 38.0419],
              [139.4324, 38.1691],
              [139.4622, 38.375],
              [139.5992, 38.6483],
              [139.7738, 38.8171],
              [139.9318, 39.2871],
              [140.0486, 39.5047],
              [140.0276, 39.8245],
              [139.8221, 39.9611],
              [139.978, 40.1255],
              [140.0243, 40.355],
              [139.8604, 40.6088],
              [140.046, 40.7678],
              [140.2708, 40.8124],
              [140.3328, 41.2372],
              [140.6427, 41.1714],
              [140.673, 40.8956],
              [140.7992, 40.8391],
              [140.9751, 40.9378],
              [141.1852, 40.9112],
              [141.2705, 41.1852],
              [140.7982, 41.1286],
              [140.7656, 41.196],
              [140.9071, 41.5008],
              [141.1078, 41.4639],
              [141.2761, 41.3532],
            ],
          ],
          [
            [
              [142.0178, 45.4557],
              [142.5393, 45.0185],
              [142.6307, 44.8837],
              [142.9815, 44.5865],
              [143.7832, 44.1007],
              [144.2151, 44.1115],
              [144.4304, 43.9426],
              [144.8312, 43.9422],
              [145.2017, 44.2058],
              [145.3499, 44.2116],
              [145.1223, 43.9171],
              [145.0747, 43.7537],
              [145.1948, 43.6207],
              [145.3117, 43.3668],
              [145.3025, 43.1735],
              [145.1255, 43.1201],
              [145.0002, 42.9862],
              [144.742, 42.9242],
              [144.4959, 42.9333],
              [144.323, 43.0026],
              [144.042, 42.9266],
              [143.6388, 42.6611],
              [143.349, 42.3192],
              [143.2529, 41.9419],
              [142.9597, 42.1054],
              [142.46, 42.2713],
              [142.1678, 42.4599],
              [141.7261, 42.6172],
              [141.4277, 42.5644],
              [141.0168, 42.3161],
              [140.7337, 42.5642],
              [140.5256, 42.5806],
              [140.3809, 42.5051],
              [140.2918, 42.2596],
              [140.5488, 42.1055],
              [140.7136, 42.1335],
              [140.9743, 41.9138],
              [141.1828, 41.8183],
              [140.96, 41.722],
              [140.6416, 41.8151],
              [140.4529, 41.6811],
              [140.4006, 41.513],
              [140.209, 41.4011],
              [140.0383, 41.4426],
              [139.9837, 41.5605],
              [140.1297, 41.8232],
              [140.145, 41.9827],
              [139.7874, 42.2468],
              [139.877, 42.6634],
              [140.2608, 42.761],
              [140.5271, 43.0154],
              [140.3381, 43.2105],
              [140.4872, 43.3724],
              [140.8099, 43.1925],
              [140.9883, 43.227],
              [141.1691, 43.1409],
              [141.3709, 43.2507],
              [141.4401, 43.3617],
              [141.3374, 43.7128],
              [141.6406, 43.9379],
              [141.6554, 44.2769],
              [141.7918, 44.597],
              [141.7219, 44.9551],
              [141.5864, 45.1644],
              [141.6921, 45.4023],
              [142.0178, 45.4557],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Japan",
        NAME_LONG: "Japan",
        ADM0_A3: "JPN",
        ISO_A2: "JP",
        ISO_A3: "JPN",
        WB_A2: "JP",
        WB_A3: "JPN",
      },
      id: 116,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [63.3839, 45.5658],
            [63.2105, 45.5844],
            [62.9003, 45.7477],
            [62.8125, 45.8952],
            [62.9003, 46.209],
            [63.2105, 46.3723],
            [63.4435, 46.3887],
            [63.7549, 46.2967],
            [63.9553, 46.0614],
            [63.8676, 45.7477],
            [63.5574, 45.5844],
            [63.3839, 45.5658],
          ],
        ],
      },
      properties: {
        NAME: "Baikonur",
        NAME_LONG: "Baikonur Cosmodrome",
        ADM0_A3: "KAB",
        ISO_A2: "KZ",
        ISO_A3: "KAZ",
        WB_A2: "KZ",
        WB_A3: "KAZ",
      },
      id: 117,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [77.8003, 35.4954],
            [77.049, 35.1104],
            [76.7774, 35.6461],
            [77.4128, 35.4694],
            [77.8003, 35.4954],
          ],
        ],
      },
      properties: {
        NAME: "Siachen Glacier",
        NAME_LONG: "Siachen Glacier",
        ADM0_A3: "KAS",
        ISO_A2: "-99",
        ISO_A3: "-99",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 118,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [87.3238, 49.0853],
            [86.8873, 49.1068],
            [86.7275, 48.9903],
            [86.7445, 48.7035],
            [86.5651, 48.5273],
            [86.192, 48.4182],
            [85.7837, 48.4076],
            [85.5864, 48.1412],
            [85.5154, 47.9203],
            [85.6704, 47.3843],
            [85.6597, 47.2065],
            [85.4986, 47.0518],
            [85.2051, 47.0337],
            [84.916, 46.8506],
            [84.7396, 46.8148],
            [84.6635, 46.9839],
            [83.9053, 46.974],
            [83.5245, 47.0671],
            [83.1504, 47.2115],
            [83.0135, 47.1946],
            [82.9855, 46.9425],
            [82.839, 46.7324],
            [82.6744, 46.3311],
            [82.5683, 46.1965],
            [82.4889, 45.9017],
            [82.2915, 45.5332],
            [82.6157, 45.4354],
            [82.5852, 45.1768],
            [82.4758, 45.1157],
            [82.2946, 45.2282],
            [81.9501, 45.1445],
            [81.8073, 45.1887],
            [81.7393, 45.3381],
            [80.8539, 45.1265],
            [80.6971, 45.1437],
            [80.365, 45.0432],
            [80.0617, 45.019],
            [79.8582, 44.9037],
            [79.9917, 44.7936],
            [80.2183, 44.8181],
            [80.3763, 44.6713],
            [80.3329, 44.4174],
            [80.3451, 44.1031],
            [80.7127, 43.4589],
            [80.7734, 43.1129],
            [80.5759, 43.1194],
            [80.3681, 43.0285],
            [80.4762, 42.8639],
            [80.2275, 42.791],
            [80.14, 42.623],
            [80.2684, 42.238],
            [80.2103, 42.1895],
            [79.9179, 42.4244],
            [79.4764, 42.454],
            [79.0309, 42.7562],
            [78.6871, 42.8046],
            [78.4961, 42.8756],
            [78.0306, 42.8547],
            [77.907, 42.8913],
            [77.1637, 42.922],
            [76.9569, 42.9885],
            [76.6888, 42.9103],
            [75.7706, 42.9256],
            [75.6462, 42.8061],
            [75.1786, 42.8497],
            [74.7139, 42.9999],
            [74.5582, 43.115],
            [74.2587, 43.2158],
            [73.6341, 43.0625],
            [73.493, 42.895],
            [73.41, 42.5896],
            [73.5055, 42.4209],
            [73.1151, 42.5508],
            [72.8408, 42.5557],
            [72.7258, 42.6529],
            [72.2924, 42.7611],
            [72.1049, 42.7503],
            [71.8477, 42.8341],
            [71.5836, 42.7596],
            [71.4046, 42.7949],
            [71.0032, 42.5635],
            [70.9478, 42.2481],
            [70.7343, 42.1973],
            [70.6102, 42.0274],
            [70.4554, 42.0937],
            [70.3151, 42.0346],
            [70.1364, 41.8481],
            [69.8709, 41.6996],
            [69.6246, 41.6683],
            [69.0444, 41.3792],
            [69.0392, 41.2203],
            [68.7463, 41.0638],
            [68.5497, 40.835],
            [68.6274, 40.6671],
            [68.4617, 40.5847],
            [68.0675, 40.788],
            [68.1313, 41.0296],
            [67.9374, 41.2004],
            [66.8153, 41.1419],
            [66.6882, 41.1992],
            [66.5389, 41.7408],
            [66.5041, 41.9935],
            [66.0172, 41.9976],
            [66.0137, 42.403],
            [66.0532, 42.4409],
            [66.1013, 42.9903],
            [65.7952, 42.8772],
            [65.5169, 43.2972],
            [65.2714, 43.417],
            [64.9565, 43.6974],
            [64.855, 43.7129],
            [64.4267, 43.547],
            [63.208, 43.6275],
            [62.0261, 43.4806],
            [61.9857, 43.4933],
            [61.1617, 44.1681],
            [61.0363, 44.3828],
            [59.6758, 45.0291],
            [58.5822, 45.5487],
            [58.5314, 45.5587],
            [57.4783, 45.3369],
            [55.9784, 44.9962],
            [55.9768, 43.6164],
            [55.9774, 42.6985],
            [55.9784, 41.3217],
            [55.6355, 41.2644],
            [55.4295, 41.2908],
            [55.2284, 41.4728],
            [54.9507, 41.7956],
            [54.9071, 41.9321],
            [54.7383, 42.0482],
            [54.1953, 42.3181],
            [54.0472, 42.3454],
            [53.4123, 42.2582],
            [52.9787, 42.1266],
            [52.4377, 41.7489],
            [52.4845, 41.9416],
            [52.402, 42.0925],
            [52.5991, 42.3447],
            [52.7446, 42.6574],
            [52.5742, 42.7985],
            [52.1692, 42.887],
            [51.9328, 42.8349],
            [51.6511, 43.1914],
            [51.2663, 43.1876],
            [51.3196, 43.4425],
            [51.1419, 43.6337],
            [50.9832, 43.919],
            [50.8528, 44.0388],
            [50.8472, 44.1893],
            [50.6963, 44.2643],
            [50.3446, 44.3113],
            [50.2302, 44.3909],
            [50.2991, 44.6553],
            [50.8479, 44.6328],
            [51.1433, 44.4848],
            [51.3193, 44.5964],
            [51.2009, 44.7409],
            [50.9707, 44.8472],
            [50.9822, 44.9895],
            [51.2434, 45.1011],
            [51.3972, 45.3326],
            [51.7925, 45.4055],
            [51.9578, 45.3716],
            [52.5667, 45.4007],
            [52.9097, 45.3127],
            [53.1429, 45.384],
            [52.7385, 45.5256],
            [52.7456, 45.6391],
            [53.0866, 46.0205],
            [53.1567, 46.3417],
            [53.0829, 46.435],
            [53.1882, 46.718],
            [52.9199, 46.9575],
            [52.6148, 46.95],
            [52.4559, 47.0012],
            [52.2389, 46.8068],
            [51.7637, 46.9157],
            [51.5026, 47.0613],
            [51.187, 47.1169],
            [50.8574, 47.04],
            [50.6069, 46.8681],
            [50.1688, 46.7491],
            [49.8174, 46.5404],
            [49.4692, 46.5626],
            [49.2271, 46.3279],
            [48.4551, 46.6608],
            [48.5567, 46.7617],
            [48.764, 46.6828],
            [49.0063, 46.7691],
            [48.6973, 47.101],
            [48.5251, 47.4102],
            [48.3606, 47.4931],
            [48.1744, 47.7129],
            [48.0445, 47.7697],
            [47.6497, 47.7656],
            [47.4353, 47.8344],
            [47.3597, 47.6833],
            [47.1745, 47.7705],
            [47.0512, 47.9746],
            [47.0783, 48.2753],
            [46.4793, 48.4102],
            [46.7538, 48.9141],
            [47.0209, 49.0968],
            [47.0286, 49.2015],
            [46.7751, 49.3325],
            [46.8999, 49.8203],
            [47.3087, 50.0448],
            [47.2948, 50.2754],
            [47.6247, 50.4408],
            [47.9932, 50.1818],
            [48.2393, 49.8648],
            [48.4176, 49.8117],
            [48.7846, 49.9334],
            [48.8857, 50.0199],
            [48.7013, 50.3346],
            [48.6734, 50.5795],
            [48.8304, 50.596],
            [49.1441, 50.7817],
            [49.3694, 50.8268],
            [49.4231, 51.102],
            [49.7791, 51.1022],
            [49.9869, 51.2298],
            [50.3254, 51.3035],
            [50.5183, 51.4598],
            [50.5817, 51.6353],
            [50.7907, 51.5973],
            [50.9254, 51.6797],
            [51.2339, 51.6855],
            [51.3719, 51.6296],
            [51.3795, 51.4671],
            [51.6205, 51.5187],
            [51.8692, 51.6731],
            [52.0918, 51.6587],
            [52.3349, 51.7637],
            [52.5058, 51.4789],
            [53.1533, 51.5127],
            [53.4073, 51.4842],
            [53.6103, 51.3884],
            [53.6739, 51.2199],
            [53.9359, 51.1856],
            [54.1766, 50.9679],
            [54.4786, 50.8535],
            [54.3798, 50.6194],
            [54.6763, 50.6025],
            [54.6382, 51.0277],
            [55.3054, 50.665],
            [55.4911, 50.6459],
            [55.66, 50.53],
            [56.0789, 50.7248],
            [56.1749, 50.9087],
            [56.3357, 50.8959],
            [56.508, 51.0663],
            [56.8651, 51.0518],
            [57.1682, 51.0938],
            [57.3527, 50.886],
            [57.7373, 50.9382],
            [57.7688, 51.1197],
            [58.595, 51.0234],
            [58.6644, 50.7899],
            [58.9259, 50.6781],
            [59.4376, 50.6311],
            [59.5228, 50.4746],
            [59.7747, 50.5337],
            [60.0213, 50.8541],
            [60.3004, 50.6847],
            [60.7185, 50.6527],
            [61.3721, 50.7825],
            [61.5932, 51.2778],
            [61.4738, 51.4258],
            [61.0233, 51.4855],
            [60.9374, 51.6112],
            [60.5151, 51.6179],
            [60.4521, 51.8055],
            [60.1381, 51.8889],
            [60.2028, 51.9927],
            [60.5065, 52.1516],
            [60.6722, 52.1443],
            [61.0399, 52.335],
            [60.8314, 52.5132],
            [60.6962, 52.7349],
            [60.8291, 52.7716],
            [61.1903, 53.0131],
            [61.5743, 52.9558],
            [62.0942, 52.9925],
            [62.0507, 53.1248],
            [61.2029, 53.2764],
            [61.1372, 53.3731],
            [61.2807, 53.5424],
            [61.0076, 53.6357],
            [61.202, 53.8196],
            [61.1935, 54.0183],
            [61.4786, 54.0029],
            [62.5506, 54.0268],
            [62.8121, 54.095],
            [63.0847, 54.0856],
            [63.7086, 54.2504],
            [64.5785, 54.3856],
            [64.9106, 54.4027],
            [65.2129, 54.343],
            [65.2143, 54.5279],
            [65.4774, 54.6327],
            [65.9775, 54.6117],
            [66.4465, 54.7109],
            [67.6227, 54.8539],
            [67.8173, 54.9522],
            [68.1661, 54.9561],
            [68.3082, 55.0728],
            [68.2624, 55.194],
            [68.6053, 55.1956],
            [68.7225, 55.3528],
            [69.1861, 55.3258],
            [69.3346, 55.3851],
            [69.6739, 55.334],
            [70.197, 55.1335],
            [70.4601, 55.2874],
            [70.6657, 55.3092],
            [70.9771, 55.078],
            [71.006, 54.7742],
            [71.1803, 54.7274],
            [71.1823, 54.1016],
            [71.7087, 54.1322],
            [71.7625, 54.2476],
            [72.0667, 54.2224],
            [72.1518, 54.3683],
            [72.4829, 54.1356],
            [72.3569, 54.0818],
            [72.4556, 53.9053],
            [72.6562, 54.0425],
            [72.9568, 54.1175],
            [73.0628, 53.9791],
            [73.3603, 53.9384],
            [73.7406, 54.0579],
            [73.6971, 53.9008],
            [73.4853, 53.8749],
            [73.2297, 53.6624],
            [73.3912, 53.5202],
            [73.8727, 53.6321],
            [74.2139, 53.597],
            [74.6165, 53.6807],
            [74.772, 53.8309],
            [75.0279, 53.8079],
            [75.6404, 54.0989],
            [76.4331, 54.3166],
            [76.7661, 54.3646],
            [76.8377, 54.2969],
            [76.6646, 54.1341],
            [76.3744, 54.1478],
            [76.5257, 53.9613],
            [77.6128, 53.4428],
            [77.8668, 53.2723],
            [78.4765, 52.6379],
            [79.4698, 51.4926],
            [79.9901, 50.7881],
            [80.0493, 50.8523],
            [80.439, 50.9581],
            [80.4762, 51.2096],
            [80.6828, 51.302],
            [81.1472, 51.1359],
            [81.0583, 50.9471],
            [81.4008, 50.9591],
            [81.4602, 50.7303],
            [81.7635, 50.7843],
            [82.0731, 50.7157],
            [82.698, 50.811],
            [82.9759, 50.8819],
            [83.1076, 50.9927],
            [83.434, 50.9927],
            [83.8205, 50.868],
            [84.1017, 50.6286],
            [84.25, 50.2599],
            [84.642, 50.1849],
            [84.9758, 50.0752],
            [85.0326, 49.885],
            [85.2631, 49.5815],
            [85.384, 49.6157],
            [86.1115, 49.5202],
            [86.3918, 49.625],
            [86.6102, 49.7955],
            [86.7759, 49.695],
            [86.5918, 49.5872],
            [86.8243, 49.5092],
            [86.9051, 49.3519],
            [87.218, 49.2298],
            [87.3238, 49.0853],
          ],
          [
            [63.3839, 45.5658],
            [63.5574, 45.5844],
            [63.8676, 45.7477],
            [63.9553, 46.0614],
            [63.7549, 46.2967],
            [63.4435, 46.3887],
            [63.2105, 46.3723],
            [62.9003, 46.209],
            [62.8125, 45.8952],
            [62.9003, 45.7477],
            [63.2105, 45.5844],
            [63.3839, 45.5658],
          ],
        ],
      },
      properties: {
        NAME: "Kazakhstan",
        NAME_LONG: "Kazakhstan",
        ADM0_A3: "KAZ",
        ISO_A2: "KZ",
        ISO_A3: "KAZ",
        WB_A2: "KZ",
        WB_A3: "KAZ",
      },
      id: 119,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.9208, 4.6193],
            [36.0413, 4.4437],
            [36.8441, 4.4322],
            [37.1119, 4.2823],
            [37.9767, 3.7264],
            [38.1019, 3.6126],
            [38.4969, 3.6239],
            [38.8952, 3.5136],
            [39.4362, 3.4624],
            [39.575, 3.4979],
            [39.7451, 3.6606],
            [39.8485, 3.8673],
            [40.7637, 4.2849],
            [41.1145, 3.9623],
            [41.4295, 3.9489],
            [41.6998, 3.9969],
            [41.885, 3.9772],
            [41.3427, 3.2011],
            [40.9654, 2.8141],
            [40.9685, 2.1036],
            [40.9685, 0.8722],
            [40.9685, -0.0027],
            [40.9798, -0.8708],
            [41.5229, -1.5728],
            [41.5351, -1.6963],
            [41.3035, -1.9561],
            [41.199, -1.9431],
            [40.9043, -2.0803],
            [40.8286, -2.3748],
            [40.6159, -2.5525],
            [40.3898, -2.5603],
            [40.175, -2.7626],
            [40.2251, -2.9937],
            [40.1291, -3.2519],
            [39.9652, -3.3992],
            [39.849, -3.6252],
            [39.7864, -3.9172],
            [39.6697, -4.088],
            [39.5511, -4.4021],
            [39.3625, -4.634],
            [39.1906, -4.6775],
            [38.5083, -4.1862],
            [37.771, -3.6554],
            [37.5903, -3.4041],
            [37.7001, -3.3144],
            [37.6449, -3.046],
            [36.7035, -2.5204],
            [35.4156, -1.8013],
            [34.0525, -1.0404],
            [33.9042, -1.0026],
            [33.8948, -0.6627],
            [33.9535, -0.1544],
            [33.8936, 0.1098],
            [34.088, 0.3674],
            [34.1324, 0.5728],
            [34.388, 0.8158],
            [34.5075, 1.1025],
            [34.7979, 1.2319],
            [34.779, 1.3885],
            [34.9787, 1.6759],
            [34.9675, 2.1019],
            [34.8656, 2.3475],
            [34.9236, 2.4773],
            [34.8187, 2.598],
            [34.7301, 2.8529],
            [34.5747, 2.9461],
            [34.5456, 3.0974],
            [34.434, 3.182],
            [34.3821, 3.466],
            [34.4397, 3.6677],
            [34.2789, 3.7097],
            [34.0611, 4.0078],
            [33.9771, 4.2197],
            [34.3812, 4.6202],
            [34.6663, 4.765],
            [35.2457, 4.9817],
            [35.5708, 4.9043],
            [35.5222, 4.7805],
            [35.6106, 4.62],
            [35.9208, 4.6193],
          ],
        ],
      },
      properties: {
        NAME: "Kenya",
        NAME_LONG: "Kenya",
        ADM0_A3: "KEN",
        ISO_A2: "KE",
        ISO_A3: "KEN",
        WB_A2: "KE",
        WB_A3: "KEN",
      },
      id: 120,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.2103, 42.1895],
            [80.1819, 42.021],
            [79.8429, 42.0018],
            [79.7475, 41.8797],
            [79.3041, 41.7876],
            [78.6721, 41.5384],
            [78.3599, 41.3775],
            [78.3597, 41.2875],
            [78.075, 41.0395],
            [77.8667, 41.0641],
            [77.4749, 40.982],
            [77.333, 41.0206],
            [76.861, 41.0132],
            [76.6308, 40.7289],
            [76.6242, 40.6275],
            [76.4491, 40.4155],
            [76.2442, 40.4412],
            [75.9215, 40.3091],
            [75.6818, 40.2917],
            [75.6669, 40.4502],
            [75.5599, 40.6329],
            [75.1939, 40.4413],
            [74.8354, 40.5116],
            [74.7058, 40.3313],
            [74.3699, 40.1058],
            [74.004, 40.0608],
            [73.823, 39.8057],
            [73.9268, 39.5929],
            [73.8207, 39.4682],
            [73.6326, 39.4483],
            [73.3676, 39.4438],
            [73.1679, 39.3554],
            [72.6505, 39.3938],
            [72.3163, 39.3288],
            [72.2033, 39.2408],
            [72.0498, 39.3689],
            [71.7329, 39.2851],
            [71.7403, 39.4483],
            [71.4921, 39.4937],
            [71.4595, 39.6121],
            [71.0093, 39.4076],
            [70.7397, 39.3862],
            [70.6221, 39.5635],
            [70.46, 39.5995],
            [70.206, 39.5241],
            [70.0197, 39.5686],
            [69.6694, 39.5778],
            [69.2862, 39.5397],
            [69.2263, 39.751],
            [69.314, 39.9869],
            [69.5755, 40.1036],
            [69.9696, 40.2116],
            [70.6274, 39.9939],
            [70.6394, 40.0849],
            [70.959, 40.2384],
            [71.3651, 40.2941],
            [71.6731, 40.1479],
            [72.0993, 40.4264],
            [72.3705, 40.3856],
            [72.4479, 40.5605],
            [72.5858, 40.5087],
            [72.7839, 40.6697],
            [73.118, 40.7829],
            [73.0034, 40.8702],
            [72.6195, 40.8801],
            [72.4767, 41.0118],
            [72.1856, 41.0411],
            [72.1699, 41.1687],
            [71.8716, 41.1945],
            [71.863, 41.3122],
            [71.6841, 41.5341],
            [71.5855, 41.3235],
            [71.4126, 41.3347],
            [71.4215, 41.1621],
            [71.2761, 41.1132],
            [70.9142, 41.193],
            [70.7563, 41.2696],
            [70.6338, 41.4675],
            [70.4772, 41.4047],
            [70.1693, 41.5783],
            [70.4537, 41.7121],
            [70.5501, 41.8241],
            [70.8251, 41.9363],
            [71.2179, 42.2064],
            [70.9478, 42.2481],
            [71.0032, 42.5635],
            [71.4046, 42.7949],
            [71.5836, 42.7596],
            [71.8477, 42.8341],
            [72.1049, 42.7503],
            [72.2924, 42.7611],
            [72.7258, 42.6529],
            [72.8408, 42.5557],
            [73.1151, 42.5508],
            [73.5055, 42.4209],
            [73.41, 42.5896],
            [73.493, 42.895],
            [73.6341, 43.0625],
            [74.2587, 43.2158],
            [74.5582, 43.115],
            [74.7139, 42.9999],
            [75.1786, 42.8497],
            [75.6462, 42.8061],
            [75.7706, 42.9256],
            [76.6888, 42.9103],
            [76.9569, 42.9885],
            [77.1637, 42.922],
            [77.907, 42.8913],
            [78.0306, 42.8547],
            [78.4961, 42.8756],
            [78.6871, 42.8046],
            [79.0309, 42.7562],
            [79.4764, 42.454],
            [79.9179, 42.4244],
            [80.2103, 42.1895],
          ],
          [
            [71.0086, 40.1578],
            [70.953, 40.0792],
            [71.1137, 39.8745],
            [71.2211, 39.9317],
            [71.0086, 40.1578],
          ],
        ],
      },
      properties: {
        NAME: "Kyrgyzstan",
        NAME_LONG: "Kyrgyzstan",
        ADM0_A3: "KGZ",
        ISO_A2: "KG",
        ISO_A3: "KGZ",
        WB_A2: "KG",
        WB_A3: "KGZ",
      },
      id: 121,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [104.4513, 10.4197],
            [104.2863, 10.5405],
            [103.8543, 10.5865],
            [103.6239, 10.5009],
            [103.5068, 10.6354],
            [103.6722, 10.7579],
            [103.7224, 10.8948],
            [103.6595, 11.0298],
            [103.4745, 11.1337],
            [103.4021, 10.8843],
            [103.0956, 10.934],
            [103.0789, 11.2735],
            [103.1145, 11.4582],
            [102.9136, 11.6459],
            [102.9138, 11.7655],
            [102.7, 12.1387],
            [102.7651, 12.4163],
            [102.5586, 12.6312],
            [102.4732, 13.0061],
            [102.3282, 13.2752],
            [102.3322, 13.5646],
            [102.5132, 13.5672],
            [102.5744, 13.6939],
            [102.6986, 13.7618],
            [102.8698, 14.0206],
            [102.9186, 14.1855],
            [103.085, 14.2958],
            [103.5502, 14.4219],
            [103.8703, 14.3433],
            [104.2475, 14.4011],
            [104.462, 14.3571],
            [104.7717, 14.4399],
            [104.9738, 14.3806],
            [105.1014, 14.2305],
            [105.1843, 14.3457],
            [105.3479, 14.1061],
            [105.5332, 14.1643],
            [105.744, 14.0869],
            [105.8731, 13.9369],
            [106.0492, 13.9155],
            [106.1446, 14.0724],
            [105.9692, 14.332],
            [106.1541, 14.3675],
            [106.236, 14.4844],
            [106.4192, 14.4665],
            [106.5125, 14.5904],
            [106.793, 14.3226],
            [106.9461, 14.3235],
            [107.2112, 14.4935],
            [107.2873, 14.5985],
            [107.4149, 14.5434],
            [107.5204, 14.7046],
            [107.5053, 14.541],
            [107.3869, 14.4219],
            [107.3199, 14.1198],
            [107.4307, 13.9845],
            [107.4432, 13.7835],
            [107.5969, 13.5351],
            [107.607, 13.3698],
            [107.4604, 13.0216],
            [107.5651, 12.6064],
            [107.5143, 12.3438],
            [107.1525, 12.277],
            [106.9777, 12.0972],
            [106.7492, 12.0523],
            [106.7016, 11.9708],
            [106.394, 11.9698],
            [106.3989, 11.7463],
            [106.2873, 11.6746],
            [106.0154, 11.7705],
            [105.8532, 11.5469],
            [105.8434, 11.3041],
            [106.0631, 11.0933],
            [106.155, 10.7848],
            [105.861, 10.8393],
            [105.7529, 11.0156],
            [105.4739, 10.9409],
            [105.3275, 10.8483],
            [105.0291, 10.9141],
            [105.0662, 10.7464],
            [104.8544, 10.5313],
            [104.554, 10.5183],
            [104.4513, 10.4197],
          ],
        ],
      },
      properties: {
        NAME: "Cambodia",
        NAME_LONG: "Cambodia",
        ADM0_A3: "KHM",
        ISO_A2: "KH",
        ISO_A3: "KHM",
        WB_A2: "KH",
        WB_A3: "KHM",
      },
      id: 122,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [126.8234, 33.5594],
              [126.9392, 33.4309],
              [126.8406, 33.3102],
              [126.2861, 33.2004],
              [126.1714, 33.2694],
              [126.3106, 33.4645],
              [126.8234, 33.5594],
            ],
          ],
          [
            [
              [128.7239, 34.9545],
              [128.6182, 34.7256],
              [128.4885, 34.869],
              [128.7239, 34.9545],
            ],
          ],
          [
            [
              [126.6675, 37.8278],
              [126.7619, 37.979],
              [127.0267, 38.2545],
              [127.1575, 38.3072],
              [127.7537, 38.3254],
              [128.0399, 38.3043],
              [128.2681, 38.4535],
              [128.3649, 38.6243],
              [128.6316, 38.145],
              [129.0642, 37.6789],
              [129.1482, 37.5051],
              [129.3311, 37.2822],
              [129.4712, 36.7719],
              [129.4409, 36.4079],
              [129.3823, 36.2018],
              [129.5426, 36.0659],
              [129.4546, 35.5133],
              [129.3578, 35.4518],
              [129.1943, 35.1555],
              [128.997, 35.0757],
              [128.5264, 35.1035],
              [128.4353, 34.8432],
              [128.0193, 34.9987],
              [127.6411, 34.8991],
              [127.7745, 34.8293],
              [127.638, 34.6982],
              [127.511, 34.8847],
              [127.3853, 34.7602],
              [127.5008, 34.6122],
              [127.3159, 34.4449],
              [127.2235, 34.5468],
              [127.2908, 34.7214],
              [127.0103, 34.6125],
              [126.8098, 34.4512],
              [126.4796, 34.3488],
              [126.4902, 34.5086],
              [126.2711, 34.6425],
              [126.4093, 34.8445],
              [126.4503, 35.0666],
              [126.3616, 35.269],
              [126.5132, 35.5769],
              [126.7332, 35.8856],
              [126.5349, 36.2196],
              [126.5715, 36.3056],
              [126.4242, 36.6066],
              [126.131, 36.7681],
              [126.336, 36.8247],
              [126.5662, 36.9908],
              [126.7552, 37.0488],
              [126.596, 37.4397],
              [126.531, 37.7547],
              [126.6869, 37.7736],
              [126.6675, 37.8278],
            ],
          ],
        ],
      },
      properties: {
        NAME: "South Korea",
        NAME_LONG: "Republic of Korea",
        ADM0_A3: "KOR",
        ISO_A2: "KR",
        ISO_A3: "KOR",
        WB_A2: "KR",
        WB_A3: "KOR",
      },
      id: 125,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.5641, 42.2463],
            [21.3538, 42.216],
            [21.2378, 42.0974],
            [21.0985, 42.196],
            [20.7654, 42.0643],
            [20.7505, 41.9068],
            [20.5671, 41.8732],
            [20.5993, 41.9479],
            [20.5008, 42.2112],
            [20.2496, 42.3186],
            [20.065, 42.5468],
            [20.0763, 42.7734],
            [20.3454, 42.8274],
            [20.6438, 43.0523],
            [20.5975, 43.185],
            [20.8386, 43.1705],
            [21.0926, 43.0907],
            [21.3787, 42.7441],
            [21.7387, 42.6822],
            [21.5641, 42.2463],
          ],
        ],
      },
      properties: {
        NAME: "Kosovo",
        NAME_LONG: "Kosovo",
        ADM0_A3: "KOS",
        ISO_A2: "KV",
        ISO_A3: "KSV",
        WB_A2: "KV",
        WB_A3: "KSV",
      },
      id: 126,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [48.1887, 29.9835],
              [48.3445, 29.7906],
              [48.229, 29.5999],
              [48.0461, 29.8005],
              [48.1887, 29.9835],
            ],
          ],
          [
            [
              [48.4328, 28.5405],
              [47.6681, 28.5335],
              [47.5968, 28.6088],
              [47.4341, 28.9946],
              [46.5324, 29.0957],
              [46.8534, 29.4446],
              [47.1105, 29.9609],
              [47.3582, 30.0921],
              [47.6742, 30.0982],
              [47.948, 29.994],
              [48.0217, 29.7797],
              [48.1809, 29.5622],
              [47.9655, 29.5763],
              [47.727, 29.3619],
              [48.0921, 29.2872],
              [48.1585, 29.0173],
              [48.4328, 28.5405],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Kuwait",
        NAME_LONG: "Kuwait",
        ADM0_A3: "KWT",
        ISO_A2: "KW",
        ISO_A3: "KWT",
        WB_A2: "KW",
        WB_A3: "KWT",
      },
      id: 127,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [102.1187, 22.3975],
            [102.2262, 22.2284],
            [102.591, 21.9014],
            [102.6507, 21.6578],
            [102.7867, 21.7399],
            [102.9478, 21.7371],
            [102.9741, 21.5753],
            [102.895, 21.4953],
            [102.8879, 21.2269],
            [103.1153, 20.8684],
            [103.3638, 20.7876],
            [103.4266, 20.8207],
            [103.6654, 20.6583],
            [103.7756, 20.8349],
            [104.0567, 20.9588],
            [104.2953, 20.8798],
            [104.3891, 20.771],
            [104.6007, 20.6605],
            [104.5426, 20.5357],
            [104.6809, 20.341],
            [104.6571, 20.2127],
            [104.908, 20.1753],
            [104.9532, 20.0093],
            [104.7807, 19.9081],
            [104.805, 19.7909],
            [104.5058, 19.6028],
            [104.229, 19.706],
            [104.0887, 19.6519],
            [104.09, 19.4837],
            [103.8567, 19.3172],
            [104.1477, 19.1829],
            [104.4809, 18.9777],
            [104.722, 18.792],
            [105.1127, 18.6983],
            [105.1695, 18.6306],
            [105.08, 18.4544],
            [105.1567, 18.3198],
            [105.3683, 18.15],
            [105.476, 18.1273],
            [105.7353, 17.664],
            [105.846, 17.598],
            [106.1854, 17.2574],
            [106.26, 17.2892],
            [106.4203, 16.9887],
            [106.5334, 16.9727],
            [106.5351, 16.6835],
            [106.6552, 16.4708],
            [106.8599, 16.4118],
            [106.9673, 16.2999],
            [107.1169, 16.2546],
            [107.3906, 15.9358],
            [107.1752, 15.8485],
            [107.1603, 15.7587],
            [107.3693, 15.4961],
            [107.5732, 15.4112],
            [107.6576, 15.2824],
            [107.578, 15.0472],
            [107.5204, 14.7046],
            [107.4149, 14.5434],
            [107.2873, 14.5985],
            [107.2112, 14.4935],
            [106.9461, 14.3235],
            [106.793, 14.3226],
            [106.5125, 14.5904],
            [106.4192, 14.4665],
            [106.236, 14.4844],
            [106.1541, 14.3675],
            [105.9692, 14.332],
            [106.1446, 14.0724],
            [106.0492, 13.9155],
            [105.8731, 13.9369],
            [105.744, 14.0869],
            [105.5332, 14.1643],
            [105.3479, 14.1061],
            [105.1843, 14.3457],
            [105.416, 14.4282],
            [105.5101, 14.5936],
            [105.4891, 14.7863],
            [105.5836, 14.9776],
            [105.4438, 15.1338],
            [105.564, 15.2725],
            [105.651, 15.6346],
            [105.6129, 15.7215],
            [105.448, 15.7649],
            [105.3726, 15.8821],
            [105.4049, 16.0188],
            [105.0425, 16.1414],
            [105.0155, 16.2767],
            [104.7545, 16.5289],
            [104.7783, 16.7158],
            [104.7453, 17.0248],
            [104.8099, 17.1717],
            [104.8164, 17.3728],
            [104.7145, 17.5153],
            [104.4812, 17.6404],
            [104.1109, 18.1146],
            [104, 18.3184],
            [103.8321, 18.3207],
            [103.3968, 18.4414],
            [103.2608, 18.4002],
            [103.0379, 17.9907],
            [102.9386, 18.0089],
            [102.5956, 17.8501],
            [102.5675, 17.9708],
            [102.3067, 18.0515],
            [102.0785, 18.2138],
            [101.9043, 18.0371],
            [101.7904, 18.0736],
            [101.7291, 17.9122],
            [101.5776, 17.8681],
            [101.247, 17.5922],
            [101.1323, 17.4617],
            [100.9019, 17.5619],
            [101.0083, 17.8952],
            [101.1652, 18.0534],
            [101.1455, 18.3362],
            [101.0303, 18.4278],
            [101.2401, 18.6736],
            [101.2329, 18.8927],
            [101.3179, 19.0501],
            [101.2291, 19.1436],
            [101.1929, 19.4528],
            [101.1247, 19.5651],
            [100.8746, 19.6129],
            [100.7455, 19.485],
            [100.462, 19.5371],
            [100.3835, 19.7639],
            [100.4743, 19.8654],
            [100.5434, 20.0666],
            [100.3644, 20.369],
            [100.0993, 20.3178],
            [100.2113, 20.6974],
            [100.5157, 20.8865],
            [100.6376, 21.0697],
            [100.7254, 21.3117],
            [101.0034, 21.406],
            [101.159, 21.5527],
            [101.209, 21.2455],
            [101.2753, 21.1741],
            [101.4951, 21.2428],
            [101.756, 21.1433],
            [101.7225, 21.3042],
            [101.7515, 21.8064],
            [101.6068, 21.9676],
            [101.5182, 22.2282],
            [101.6545, 22.4461],
            [101.8178, 22.4063],
            [102.0146, 22.4461],
            [102.1187, 22.3975],
          ],
        ],
      },
      properties: {
        NAME: "Lao PDR",
        NAME_LONG: "Lao PDR",
        ADM0_A3: "LAO",
        ISO_A2: "LA",
        ISO_A3: "LAO",
        WB_A2: "LA",
        WB_A3: "LAO",
      },
      id: 128,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.8211, 33.4067],
            [35.52, 33.2222],
            [35.4801, 33.0874],
            [35.1052, 33.089],
            [35.271, 33.4575],
            [35.3491, 33.5139],
            [35.4773, 33.8039],
            [35.5784, 33.9167],
            [35.6272, 34.2011],
            [35.8054, 34.4587],
            [35.9832, 34.5386],
            [35.9699, 34.6498],
            [36.2616, 34.6272],
            [36.5306, 34.4142],
            [36.6041, 34.1991],
            [36.2672, 33.9104],
            [36.0504, 33.8164],
            [36.0129, 33.608],
            [35.8211, 33.4067],
          ],
        ],
      },
      properties: {
        NAME: "Lebanon",
        NAME_LONG: "Lebanon",
        ADM0_A3: "LBN",
        ISO_A2: "LB",
        ISO_A3: "LBN",
        WB_A2: "LB",
        WB_A3: "LBN",
      },
      id: 129,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.4854, 7.558],
            [-8.4436, 7.5371],
            [-8.2841, 7.0179],
            [-8.3518, 6.7554],
            [-8.5661, 6.5509],
            [-8.3888, 6.3551],
            [-8.1706, 6.274],
            [-7.9349, 6.2714],
            [-7.8441, 6.184],
            [-7.7996, 5.9745],
            [-7.5591, 5.8399],
            [-7.4465, 5.8459],
            [-7.3936, 5.524],
            [-7.45, 5.4228],
            [-7.4889, 5.1407],
            [-7.5782, 5.0547],
            [-7.6055, 4.8974],
            [-7.5407, 4.3528],
            [-7.7127, 4.3615],
            [-7.8329, 4.4522],
            [-8.2573, 4.58],
            [-8.6075, 4.7869],
            [-9.0148, 4.9785],
            [-9.2787, 5.1457],
            [-9.4975, 5.3894],
            [-10.0399, 5.8543],
            [-10.0462, 5.8964],
            [-10.3956, 6.177],
            [-10.6594, 6.2275],
            [-10.7883, 6.293],
            [-10.8243, 6.4377],
            [-11.0536, 6.5842],
            [-11.3532, 6.7012],
            [-11.4762, 6.9194],
            [-11.3732, 7.1388],
            [-11.1263, 7.368],
            [-10.8746, 7.5309],
            [-10.7306, 7.7055],
            [-10.6152, 7.769],
            [-10.6177, 8.0108],
            [-10.5292, 8.1243],
            [-10.3649, 8.1482],
            [-10.2822, 8.4846],
            [-9.6835, 8.4871],
            [-9.5792, 8.3873],
            [-9.4223, 8.0158],
            [-9.4387, 7.8662],
            [-9.3535, 7.6224],
            [-9.4381, 7.4216],
            [-9.2343, 7.3813],
            [-9.1196, 7.238],
            [-8.9254, 7.2486],
            [-8.7296, 7.5087],
            [-8.6866, 7.6944],
            [-8.5674, 7.6882],
            [-8.4854, 7.558],
          ],
        ],
      },
      properties: {
        NAME: "Liberia",
        NAME_LONG: "Liberia",
        ADM0_A3: "LBR",
        ISO_A2: "LR",
        ISO_A3: "LBR",
        WB_A2: "LR",
        WB_A3: "LBR",
      },
      id: 130,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.1509, 31.6565],
            [24.8613, 31.3804],
            [24.8595, 31.146],
            [24.9949, 30.7851],
            [24.9077, 30.4987],
            [24.6883, 30.1442],
            [24.8116, 29.8909],
            [24.8738, 29.4683],
            [24.9812, 29.1814],
            [24.9812, 27.6665],
            [24.9812, 26.6645],
            [24.9812, 25.2054],
            [24.9812, 23.7463],
            [24.9812, 22.579],
            [24.9812, 21.9954],
            [24.9808, 20.5004],
            [24.968, 19.995],
            [23.9813, 19.9954],
            [23.9813, 19.4961],
            [23.2402, 19.8621],
            [22.2521, 20.3501],
            [21.6345, 20.655],
            [20.5228, 21.2039],
            [19.1641, 21.8749],
            [18.176, 22.3628],
            [17.4348, 22.7288],
            [15.9851, 23.4447],
            [14.9799, 22.9957],
            [14.2317, 22.6179],
            [14.2016, 22.6232],
            [13.5996, 23.119],
            [13.4823, 23.1797],
            [11.9689, 23.5174],
            [11.5671, 24.2668],
            [11.5086, 24.3138],
            [10.7207, 24.5523],
            [10.4502, 24.4769],
            [10.2603, 24.5766],
            [10.1934, 24.7499],
            [10.032, 24.8563],
            [10.0214, 25.268],
            [9.9695, 25.3954],
            [9.4012, 26.1134],
            [9.4826, 26.3526],
            [9.8358, 26.5042],
            [9.9106, 26.8431],
            [9.8255, 26.9206],
            [9.7213, 27.2919],
            [9.8216, 27.5057],
            [9.9359, 27.8667],
            [9.777, 28.2676],
            [9.8513, 28.786],
            [9.8261, 29.1285],
            [9.6677, 29.6083],
            [9.5497, 29.8023],
            [9.2865, 30.1171],
            [9.5197, 30.2289],
            [9.8713, 30.3552],
            [10.1922, 30.7313],
            [10.2702, 30.9156],
            [10.2448, 31.0782],
            [10.1081, 31.4118],
            [10.264, 31.6805],
            [10.5135, 31.757],
            [10.6059, 31.9536],
            [10.8056, 32.0324],
            [10.8732, 32.1367],
            [11.444, 32.3685],
            [11.5641, 32.4655],
            [11.4499, 32.638],
            [11.5051, 33.1812],
            [11.847, 33.0828],
            [12.1224, 32.9213],
            [12.3483, 32.8327],
            [12.7298, 32.7986],
            [13.3517, 32.9042],
            [13.5715, 32.8028],
            [13.7871, 32.7996],
            [14.1755, 32.7177],
            [14.4695, 32.519],
            [14.7268, 32.4508],
            [15.196, 32.3898],
            [15.3705, 32.1591],
            [15.3705, 31.9294],
            [15.4904, 31.6649],
            [15.7615, 31.3881],
            [16.0446, 31.2755],
            [16.3587, 31.2271],
            [16.728, 31.2238],
            [17.3659, 31.0866],
            [17.8486, 30.9237],
            [18.1753, 30.7861],
            [18.6532, 30.4223],
            [19.0568, 30.2662],
            [19.3386, 30.2988],
            [19.7489, 30.5109],
            [20.0365, 30.8191],
            [20.1467, 31.0456],
            [20.1467, 31.2168],
            [20.0222, 31.3968],
            [19.9207, 31.7177],
            [19.9401, 31.9577],
            [20.0715, 32.1764],
            [20.5627, 32.5578],
            [21.0967, 32.7811],
            [21.4546, 32.8126],
            [21.6087, 32.9307],
            [22.0962, 32.9231],
            [22.3792, 32.8703],
            [22.5138, 32.791],
            [23.0864, 32.6465],
            [23.1489, 32.4781],
            [23.0956, 32.3236],
            [23.2435, 32.2206],
            [23.6697, 32.1833],
            [23.9987, 32.0939],
            [24.0907, 32.0058],
            [24.5461, 31.9914],
            [24.6703, 32.0324],
            [24.9817, 31.9679],
            [25.1509, 31.6565],
          ],
        ],
      },
      properties: {
        NAME: "Libya",
        NAME_LONG: "Libya",
        ADM0_A3: "LBY",
        ISO_A2: "LY",
        ISO_A3: "LBY",
        WB_A2: "LY",
        WB_A3: "LBY",
      },
      id: 131,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.8868, 14.0101],
            [-60.9408, 13.7221],
            [-61.0759, 13.8904],
            [-60.8868, 14.0101],
          ],
        ],
      },
      properties: {
        NAME: "Saint Lucia",
        NAME_LONG: "Saint Lucia",
        ADM0_A3: "LCA",
        ISO_A2: "LC",
        ISO_A3: "LCA",
        WB_A2: "LC",
        WB_A3: "LCA",
      },
      id: 132,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [80.4683, 9.5814],
            [80.7444, 9.3597],
            [80.9307, 9.021],
            [81.2317, 8.6511],
            [81.2131, 8.4724],
            [81.3657, 8.4822],
            [81.4485, 8.128],
            [81.6968, 7.753],
            [81.7576, 7.5054],
            [81.8807, 7.3271],
            [81.8903, 7.0199],
            [81.7756, 6.6104],
            [81.6815, 6.4621],
            [81.3621, 6.2254],
            [81.1126, 6.1203],
            [80.8091, 6.0351],
            [80.5908, 5.9237],
            [80.2476, 6.0092],
            [80.1038, 6.1271],
            [80.032, 6.2663],
            [79.9696, 6.5353],
            [79.863, 6.8072],
            [79.8616, 7.0119],
            [79.7847, 7.5898],
            [79.7638, 7.9901],
            [79.8272, 8.1365],
            [79.8738, 8.5381],
            [79.9417, 8.6425],
            [79.9471, 8.9487],
            [80.048, 9.0263],
            [80.116, 9.2338],
            [80.053, 9.3915],
            [80.2722, 9.5041],
            [80.1736, 9.6487],
            [79.947, 9.6932],
            [79.9885, 9.8229],
            [80.2268, 9.8296],
            [80.4683, 9.5814],
          ],
        ],
      },
      properties: {
        NAME: "Sri Lanka",
        NAME_LONG: "Sri Lanka",
        ADM0_A3: "LKA",
        ISO_A2: "LK",
        ISO_A3: "LKA",
        WB_A2: "LK",
        WB_A3: "LKA",
      },
      id: 134,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.9808, -28.909],
            [29.3118, -29.0898],
            [29.4359, -29.3424],
            [29.3038, -29.4668],
            [29.2786, -29.6136],
            [29.1613, -29.6669],
            [29.1442, -29.9197],
            [28.86, -30.0664],
            [28.3641, -30.1593],
            [28.216, -30.2674],
            [28.2182, -30.3874],
            [28.0547, -30.6497],
            [27.7436, -30.6003],
            [27.4594, -30.3166],
            [27.3662, -30.311],
            [27.2659, -30.0333],
            [27.1988, -29.9785],
            [27.0149, -29.6256],
            [27.2418, -29.549],
            [27.4176, -29.3966],
            [27.4533, -29.2916],
            [27.665, -29.0657],
            [27.7474, -28.9086],
            [28.0187, -28.8568],
            [28.155, -28.7023],
            [28.4034, -28.6189],
            [28.6665, -28.5972],
            [28.6995, -28.6885],
            [28.9808, -28.909],
          ],
        ],
      },
      properties: {
        NAME: "Lesotho",
        NAME_LONG: "Lesotho",
        ADM0_A3: "LSO",
        ISO_A2: "LS",
        ISO_A3: "LSO",
        WB_A2: "LS",
        WB_A3: "LSO",
      },
      id: 135,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.5945, 55.667],
            [26.4455, 55.3375],
            [26.7686, 55.3002],
            [26.5789, 55.1185],
            [26.3095, 55.1446],
            [26.1386, 54.9689],
            [25.7827, 54.8697],
            [25.53, 54.3461],
            [25.1935, 54.2437],
            [24.8214, 54.0199],
            [24.3779, 53.8868],
            [24.2011, 53.9525],
            [23.6426, 53.899],
            [23.4856, 53.9393],
            [23.4325, 54.1693],
            [22.9733, 54.3754],
            [22.7672, 54.3563],
            [22.6804, 54.4532],
            [22.8089, 54.8938],
            [22.5807, 55.0576],
            [22.0999, 55.0299],
            [21.2676, 55.2487],
            [21.2229, 55.5202],
            [21.0744, 55.7449],
            [21.0534, 56.0726],
            [21.596, 56.3079],
            [22.0941, 56.4174],
            [23.0167, 56.3238],
            [23.2884, 56.373],
            [23.8581, 56.3344],
            [24.1086, 56.2607],
            [24.4472, 56.2605],
            [24.8923, 56.4387],
            [25.1092, 56.1831],
            [25.6497, 56.1438],
            [26.1786, 55.8496],
            [26.2797, 55.7432],
            [26.5945, 55.667],
          ],
        ],
      },
      properties: {
        NAME: "Lithuania",
        NAME_LONG: "Lithuania",
        ADM0_A3: "LTU",
        ISO_A2: "LT",
        ISO_A3: "LTU",
        WB_A2: "LT",
        WB_A3: "LTU",
      },
      id: 136,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.1175, 50.1205],
            [6.2227, 49.8871],
            [6.5001, 49.7945],
            [6.3453, 49.4553],
            [6.1361, 49.4953],
            [5.9607, 49.4413],
            [5.7907, 49.5378],
            [5.8855, 49.6435],
            [5.7149, 49.8819],
            [5.9616, 50.1656],
            [6.1175, 50.1205],
          ],
        ],
      },
      properties: {
        NAME: "Luxembourg",
        NAME_LONG: "Luxembourg",
        ADM0_A3: "LUX",
        ISO_A2: "LU",
        ISO_A3: "LUX",
        WB_A2: "LU",
        WB_A3: "LUX",
      },
      id: 137,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.3529, 57.5276],
            [27.8403, 57.2906],
            [27.6487, 56.8793],
            [27.9189, 56.8059],
            [27.9974, 56.6038],
            [28.1261, 56.5478],
            [28.2148, 56.2814],
            [28.1489, 56.1424],
            [27.9115, 56.1002],
            [27.645, 55.9228],
            [27.5927, 55.7942],
            [26.9811, 55.8269],
            [26.8228, 55.7061],
            [26.5945, 55.667],
            [26.2797, 55.7432],
            [26.1786, 55.8496],
            [25.6497, 56.1438],
            [25.1092, 56.1831],
            [24.8923, 56.4387],
            [24.4472, 56.2605],
            [24.1086, 56.2607],
            [23.8581, 56.3344],
            [23.2884, 56.373],
            [23.0167, 56.3238],
            [22.0941, 56.4174],
            [21.596, 56.3079],
            [21.0534, 56.0726],
            [20.9691, 56.2535],
            [21.0652, 56.8463],
            [21.4016, 57.0375],
            [21.4214, 57.2887],
            [21.6992, 57.5553],
            [21.9993, 57.6003],
            [22.4837, 57.7425],
            [22.6561, 57.5858],
            [23.1302, 57.3708],
            [23.2505, 57.1144],
            [23.6952, 56.9673],
            [23.9529, 57.0133],
            [24.3792, 57.2302],
            [24.4055, 57.3445],
            [24.3062, 57.8682],
            [25.167, 58.0587],
            [25.6015, 57.9122],
            [26.0027, 57.846],
            [26.0247, 57.7744],
            [26.4996, 57.5158],
            [26.8385, 57.6099],
            [27.3529, 57.5276],
          ],
        ],
      },
      properties: {
        NAME: "Latvia",
        NAME_LONG: "Latvia",
        ADM0_A3: "LVA",
        ISO_A2: "LV",
        ISO_A3: "LVA",
        WB_A2: "LV",
        WB_A3: "LVA",
      },
      id: 138,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.2226, 35.0893],
            [-2.1938, 35.0036],
            [-1.7877, 34.7567],
            [-1.8711, 34.5966],
            [-1.7507, 34.4942],
            [-1.8096, 34.3725],
            [-1.6748, 34.106],
            [-1.7187, 33.8981],
            [-1.6127, 33.5215],
            [-1.6742, 33.238],
            [-1.4995, 33.0602],
            [-1.5588, 32.9336],
            [-1.4233, 32.7424],
            [-1.0475, 32.517],
            [-1.218, 32.3926],
            [-1.289, 32.1509],
            [-1.2496, 32.0817],
            [-2.5161, 32.1322],
            [-2.9387, 32.0486],
            [-2.8278, 31.7946],
            [-3.6595, 31.6478],
            [-3.6735, 31.3892],
            [-3.815, 31.2205],
            [-3.6359, 31.0957],
            [-3.5547, 30.9559],
            [-3.6595, 30.8371],
            [-3.6455, 30.7113],
            [-3.8342, 30.6275],
            [-4.1557, 30.5855],
            [-4.3724, 30.5086],
            [-4.624, 30.285],
            [-4.8756, 30.1802],
            [-5.2739, 29.8866],
            [-5.5395, 29.5232],
            [-5.7212, 29.5232],
            [-5.7562, 29.6141],
            [-6.2733, 29.5791],
            [-6.6996, 29.5162],
            [-6.7835, 29.4463],
            [-7.1469, 29.5092],
            [-7.3496, 29.3834],
            [-7.6195, 29.3894],
            [-8.0697, 29.0793],
            [-8.3835, 28.9058],
            [-8.6824, 28.6659],
            [-8.6824, 27.6614],
            [-8.817, 27.6615],
            [-8.7529, 27.1905],
            [-9.0009, 27.0899],
            [-9.4121, 27.088],
            [-9.7344, 26.8604],
            [-10.0317, 26.9102],
            [-10.2505, 26.8604],
            [-10.5503, 26.9903],
            [-10.9218, 27.0098],
            [-11.3916, 26.8829],
            [-11.3369, 26.6329],
            [-11.6362, 26.295],
            [-11.7172, 26.1036],
            [-12.0298, 26.0304],
            [-12.1699, 25.6397],
            [-12.31, 25.1104],
            [-12.4301, 24.8302],
            [-13.06, 24.4005],
            [-13.31, 23.9806],
            [-13.77, 23.7901],
            [-13.8901, 23.6905],
            [-14.02, 23.4103],
            [-14.1699, 22.7599],
            [-14.2202, 22.3096],
            [-14.6299, 21.8604],
            [-14.6699, 21.5997],
            [-14.8398, 21.4503],
            [-15.4599, 21.4503],
            [-15.75, 21.4903],
            [-16.73, 21.4698],
            [-17.0137, 21.42],
            [-16.9669, 21.7812],
            [-16.8166, 22.1306],
            [-16.7068, 22.2755],
            [-16.5218, 22.3149],
            [-16.3573, 22.5831],
            [-16.2896, 22.8998],
            [-16.173, 22.9735],
            [-16.1351, 23.1851],
            [-15.9672, 23.5129],
            [-15.7726, 23.7878],
            [-15.7681, 23.9599],
            [-15.6201, 24.0263],
            [-15.1719, 24.4941],
            [-15.0148, 24.5624],
            [-14.9078, 24.6855],
            [-14.836, 24.9187],
            [-14.8497, 25.2142],
            [-14.7821, 25.4469],
            [-14.621, 25.7836],
            [-14.5195, 25.9238],
            [-14.4928, 26.1327],
            [-14.4101, 26.2601],
            [-14.1571, 26.4338],
            [-13.9237, 26.5009],
            [-13.62, 26.6889],
            [-13.5009, 26.8583],
            [-13.4025, 27.1772],
            [-13.3011, 27.3244],
            [-13.1713, 27.685],
            [-13.0614, 27.7429],
            [-12.9681, 27.9146],
            [-12.8389, 27.9704],
            [-12.5135, 27.9954],
            [-12.0614, 28.089],
            [-11.4856, 28.3256],
            [-11.3259, 28.5152],
            [-11.0476, 28.7617],
            [-10.5738, 28.9904],
            [-10.1418, 29.4286],
            [-10.053, 29.5999],
            [-9.8202, 29.8389],
            [-9.6551, 30.1269],
            [-9.6116, 30.4044],
            [-9.7001, 30.5427],
            [-9.8867, 30.6867],
            [-9.8202, 30.7923],
            [-9.8475, 31.4024],
            [-9.6673, 31.7311],
            [-9.3661, 32.0263],
            [-9.27, 32.2275],
            [-9.2599, 32.5769],
            [-8.8678, 32.8819],
            [-8.6291, 33.1276],
            [-8.2432, 33.4045],
            [-8.1066, 33.4309],
            [-7.4971, 33.65],
            [-7.0995, 33.8403],
            [-6.8221, 34.0396],
            [-6.5579, 34.4177],
            [-6.2921, 34.8808],
            [-6.0239, 35.5012],
            [-5.9272, 35.7807],
            [-5.599, 35.8223],
            [-5.3989, 35.9245],
            [-5.3407, 35.8474],
            [-5.2494, 35.5846],
            [-5.0663, 35.4175],
            [-4.7705, 35.241],
            [-4.376, 35.1518],
            [-3.9613, 35.2504],
            [-3.7648, 35.2185],
            [-3.6736, 35.2879],
            [-3.4129, 35.1979],
            [-2.9478, 35.3298],
            [-2.9129, 35.2769],
            [-2.9051, 35.1736],
            [-2.5632, 35.0961],
            [-2.4167, 35.1492],
            [-2.2226, 35.0893],
          ],
        ],
      },
      properties: {
        NAME: "Morocco",
        NAME_LONG: "Morocco",
        ADM0_A3: "MAR",
        ISO_A2: "MA",
        ISO_A3: "MAR",
        WB_A2: "MA",
        WB_A3: "MAR",
      },
      id: 141,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.1995, 45.4618],
            [28.0861, 46.0006],
            [28.2461, 46.4279],
            [28.2341, 46.6624],
            [28.037, 47.0165],
            [27.8066, 47.1446],
            [27.5632, 47.4683],
            [27.272, 47.715],
            [27.048, 48.1222],
            [26.8049, 48.2583],
            [26.6179, 48.259],
            [26.8428, 48.3937],
            [27.1758, 48.3618],
            [27.5038, 48.4724],
            [27.7518, 48.452],
            [28.1999, 48.2117],
            [28.7993, 48.1118],
            [28.9504, 47.9348],
            [29.124, 47.976],
            [29.2386, 47.756],
            [29.1174, 47.5333],
            [29.3589, 47.3527],
            [29.5566, 47.324],
            [29.5587, 46.9457],
            [29.8436, 46.8541],
            [29.9526, 46.7247],
            [29.9161, 46.5188],
            [30.0811, 46.3742],
            [29.8478, 46.3415],
            [29.7269, 46.4558],
            [29.2007, 46.3571],
            [29.1627, 46.5381],
            [28.9458, 46.4548],
            [28.9335, 46.259],
            [29.0154, 46.1826],
            [28.932, 45.9932],
            [28.7576, 45.9612],
            [28.7383, 45.8376],
            [28.474, 45.6579],
            [28.4809, 45.502],
            [28.1995, 45.4618],
          ],
        ],
      },
      properties: {
        NAME: "Moldova",
        NAME_LONG: "Moldova",
        ADM0_A3: "MDA",
        ISO_A2: "MD",
        ISO_A3: "MDA",
        WB_A2: "MD",
        WB_A3: "MDA",
      },
      id: 143,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [49.3517, -12.0905],
            [49.2492, -12.1803],
            [49.3494, -12.2985],
            [49.5394, -12.3782],
            [49.5632, -12.6168],
            [49.8643, -12.8805],
            [49.9421, -13.0339],
            [49.9727, -13.3313],
            [50.1429, -13.7831],
            [50.1909, -14.4566],
            [50.2837, -14.8792],
            [50.4766, -15.1951],
            [50.5039, -15.3162],
            [50.4299, -15.6003],
            [50.2415, -15.9583],
            [50.0389, -15.8595],
            [49.9011, -15.4223],
            [49.7503, -15.436],
            [49.6384, -15.5446],
            [49.7337, -15.9057],
            [49.7026, -16.115],
            [49.8457, -16.2121],
            [49.8495, -16.5524],
            [49.7232, -16.7081],
            [49.7783, -16.8556],
            [49.6245, -16.8857],
            [49.4313, -17.2839],
            [49.5041, -17.663],
            [49.3592, -18.424],
            [49.0769, -19.0656],
            [48.9859, -19.3788],
            [48.8596, -19.6847],
            [48.8237, -19.9028],
            [48.5623, -20.5771],
            [48.4556, -21.0141],
            [48.2018, -21.7962],
            [47.9006, -22.4811],
            [47.8338, -22.901],
            [47.6195, -23.5906],
            [47.5687, -23.8526],
            [47.3384, -24.3243],
            [47.3123, -24.4859],
            [47.1375, -24.9093],
            [46.9647, -25.0657],
            [46.6537, -25.1839],
            [46.3866, -25.1648],
            [46.2376, -25.1982],
            [45.7742, -25.4081],
            [45.4922, -25.5737],
            [45.1361, -25.5842],
            [44.8347, -25.344],
            [44.3695, -25.2568],
            [44.1872, -25.067],
            [44.0288, -24.999],
            [43.8399, -24.5058],
            [43.7067, -24.4062],
            [43.6563, -24.1853],
            [43.6416, -23.6389],
            [43.758, -23.454],
            [43.6286, -23.3393],
            [43.5832, -23.0759],
            [43.3567, -22.8297],
            [43.2229, -22.2548],
            [43.2952, -22.1661],
            [43.2468, -22.0351],
            [43.3362, -21.7688],
            [43.4727, -21.6721],
            [43.4727, -21.4052],
            [43.5869, -21.2748],
            [43.8128, -21.2243],
            [43.9015, -20.8829],
            [44.0364, -20.7255],
            [44.1258, -20.4983],
            [44.2547, -20.3761],
            [44.4793, -19.9128],
            [44.3709, -19.774],
            [44.4596, -19.4177],
            [44.2275, -19.0546],
            [44.2573, -18.8039],
            [44.1859, -18.6108],
            [44.0497, -18.4265],
            [44.001, -17.9379],
            [44.0357, -17.7695],
            [43.9292, -17.6017],
            [43.9431, -17.4557],
            [44.4307, -16.7004],
            [44.4006, -16.3728],
            [44.4528, -16.1867],
            [44.8543, -16.2254],
            [45.0402, -16.1181],
            [45.2664, -15.9239],
            [45.3794, -15.9738],
            [45.6384, -15.943],
            [45.6939, -15.7831],
            [46.0158, -15.7923],
            [46.1442, -15.7037],
            [46.293, -15.9407],
            [46.3831, -15.8349],
            [46.3327, -15.6301],
            [46.8794, -15.225],
            [46.9714, -15.1984],
            [47.0747, -15.324],
            [46.9671, -15.4923],
            [47.2321, -15.4333],
            [47.0579, -15.2162],
            [47.2918, -14.9234],
            [47.2899, -14.8469],
            [47.4448, -14.6716],
            [47.5034, -14.8469],
            [47.4207, -14.9656],
            [47.4824, -15.0803],
            [47.7529, -14.6005],
            [47.7095, -14.3696],
            [47.8145, -14.216],
            [47.9268, -14.2463],
            [48.0448, -14.1516],
            [47.9411, -14.0041],
            [47.8758, -13.7843],
            [47.9043, -13.6032],
            [48.0313, -13.5172],
            [48.1424, -13.5954],
            [48.1813, -13.7468],
            [48.332, -13.6614],
            [48.4276, -13.5242],
            [48.7474, -13.4116],
            [48.8392, -13.2604],
            [48.8435, -13.0808],
            [48.9553, -12.8117],
            [48.8662, -12.6371],
            [48.9846, -12.3334],
            [49.2015, -12.1467],
            [49.3517, -12.0905],
          ],
        ],
      },
      properties: {
        NAME: "Madagascar",
        NAME_LONG: "Madagascar",
        ADM0_A3: "MDG",
        ISO_A2: "MG",
        ISO_A3: "MDG",
        WB_A2: "MG",
        WB_A3: "MDG",
      },
      id: 144,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-86.749, 20.5677],
              [-86.9265, 20.3137],
              [-87.0189, 20.3325],
              [-86.9368, 20.5472],
              [-86.749, 20.5677],
            ],
          ],
          [
            [
              [-112.2816, 29.2255],
              [-112.1956, 29.0213],
              [-112.2747, 28.7684],
              [-112.583, 28.8754],
              [-112.4859, 28.9716],
              [-112.4387, 29.2021],
              [-112.2816, 29.2255],
            ],
          ],
          [
            [
              [-113.4995, 29.5351],
              [-113.1828, 29.2897],
              [-113.1743, 29.1292],
              [-113.354, 29.1707],
              [-113.5891, 29.4096],
              [-113.4995, 29.5351],
            ],
          ],
          [
            [
              [-97.1393, 25.9658],
              [-97.1652, 25.7233],
              [-97.3194, 25.39],
              [-97.459, 25.1794],
              [-97.6514, 24.5159],
              [-97.7167, 24.0081],
              [-97.7685, 23.2601],
              [-97.7423, 22.9721],
              [-97.8894, 22.6254],
              [-97.7743, 22.2535],
              [-97.7606, 22.0959],
              [-97.6105, 21.8358],
              [-97.6576, 21.6365],
              [-97.4968, 21.4006],
              [-97.1972, 20.8102],
              [-97.1505, 20.647],
              [-96.676, 20.1579],
              [-96.4561, 19.8688],
              [-96.2783, 19.3146],
              [-95.9772, 19.0583],
              [-95.852, 18.7168],
              [-95.7284, 18.7572],
              [-95.4952, 18.7073],
              [-95.2084, 18.7086],
              [-95.0026, 18.5554],
              [-94.8079, 18.5236],
              [-94.5809, 18.1898],
              [-94.4669, 18.1442],
              [-94.2071, 18.1896],
              [-93.4447, 18.4418],
              [-92.9398, 18.4457],
              [-92.681, 18.6229],
              [-92.4051, 18.6711],
              [-91.9481, 18.6912],
              [-91.9907, 18.5393],
              [-91.8291, 18.4773],
              [-91.5444, 18.4492],
              [-91.3328, 18.5734],
              [-91.2655, 18.7357],
              [-91.4312, 18.8801],
              [-90.9355, 19.1631],
              [-90.7301, 19.3759],
              [-90.7095, 19.6875],
              [-90.4778, 19.9221],
              [-90.4899, 20.5341],
              [-90.3601, 21.0004],
              [-90.0995, 21.1641],
              [-89.786, 21.2846],
              [-89.1232, 21.3635],
              [-88.7027, 21.4586],
              [-88.4478, 21.5751],
              [-88.2827, 21.5567],
              [-88.1295, 21.616],
              [-87.9621, 21.6001],
              [-87.4741, 21.4671],
              [-87.2382, 21.443],
              [-86.9979, 21.5659],
              [-86.828, 21.4333],
              [-86.8151, 21.2048],
              [-86.7416, 21.1641],
              [-86.8753, 20.855],
              [-87.0675, 20.6192],
              [-87.235, 20.4926],
              [-87.444, 20.1911],
              [-87.4715, 19.8847],
              [-87.6287, 19.7086],
              [-87.6664, 19.5525],
              [-87.4232, 19.558],
              [-87.7234, 18.6812],
              [-87.7712, 18.4064],
              [-88.0778, 18.4986],
              [-88.0022, 18.6901],
              [-88.1039, 18.8027],
              [-88.3038, 18.4814],
              [-88.4892, 18.4845],
              [-88.7364, 18.0519],
              [-88.8803, 17.8885],
              [-89.0494, 17.9992],
              [-89.1605, 17.8143],
              [-90.2597, 17.8153],
              [-90.9909, 17.802],
              [-90.992, 17.2519],
              [-91.2904, 17.1891],
              [-91.0669, 16.9182],
              [-90.7353, 16.7466],
              [-90.6482, 16.5296],
              [-90.3988, 16.3476],
              [-90.4857, 16.0707],
              [-91.7238, 16.0688],
              [-91.7519, 16.0463],
              [-92.2047, 15.2895],
              [-92.0738, 15.0737],
              [-92.1542, 14.9959],
              [-92.1617, 14.7023],
              [-92.2462, 14.5463],
              [-92.6999, 14.9991],
              [-92.824, 15.1728],
              [-92.9831, 15.2656],
              [-93.2286, 15.5019],
              [-93.656, 15.8393],
              [-93.9434, 16.0106],
              [-94.1278, 16.2209],
              [-94.3636, 16.287],
              [-94.4396, 16.1868],
              [-94.6544, 16.3636],
              [-94.7633, 16.2935],
              [-94.8521, 16.4289],
              [-95.2332, 16.1582],
              [-95.4345, 15.9754],
              [-95.9599, 15.8279],
              [-96.2366, 15.6833],
              [-96.556, 15.659],
              [-96.8451, 15.7326],
              [-97.266, 15.9359],
              [-97.7727, 15.9795],
              [-98.1238, 16.2092],
              [-98.5682, 16.324],
              [-98.7769, 16.5551],
              [-98.8566, 16.5282],
              [-99.3743, 16.6679],
              [-99.7412, 16.7306],
              [-99.9917, 16.9097],
              [-101.0082, 17.253],
              [-101.1822, 17.4125],
              [-101.4558, 17.5263],
              [-101.6445, 17.6683],
              [-101.8111, 17.8903],
              [-102.0367, 17.9907],
              [-102.1906, 17.916],
              [-102.5972, 18.0491],
              [-102.7423, 18.067],
              [-103.0244, 18.1858],
              [-103.3616, 18.2668],
              [-103.4984, 18.3335],
              [-103.7002, 18.6543],
              [-103.98, 18.8749],
              [-104.3317, 19.0223],
              [-104.4516, 19.1044],
              [-104.8069, 19.2291],
              [-105.0171, 19.3685],
              [-105.0877, 19.5611],
              [-105.2508, 19.6661],
              [-105.5256, 20.0319],
              [-105.5532, 20.2015],
              [-105.6987, 20.4069],
              [-105.5756, 20.4877],
              [-105.3287, 20.5137],
              [-105.2343, 20.6361],
              [-105.3616, 20.7628],
              [-105.5141, 20.7703],
              [-105.3097, 21.0307],
              [-105.2343, 21.061],
              [-105.2431, 21.3421],
              [-105.185, 21.443],
              [-105.4141, 21.6011],
              [-105.6323, 21.9557],
              [-105.655, 22.2563],
              [-105.8002, 22.6385],
              [-106.032, 22.8301],
              [-106.2203, 23.0519],
              [-106.4298, 23.2304],
              [-106.5228, 23.4075],
              [-106.7932, 23.6324],
              [-106.902, 23.8466],
              [-107.0359, 23.9808],
              [-107.3778, 24.2069],
              [-107.6241, 24.4585],
              [-107.8205, 24.5952],
              [-107.9952, 24.6484],
              [-108.0362, 24.7852],
              [-108.1864, 24.9866],
              [-108.1069, 25.1169],
              [-108.3336, 25.161],
              [-108.4508, 25.2694],
              [-108.8878, 25.4897],
              [-109.1161, 25.5363],
              [-109.1712, 25.6487],
              [-109.4405, 25.7934],
              [-109.4333, 26.0217],
              [-109.2681, 26.1799],
              [-109.2557, 26.498],
              [-109.5499, 26.728],
              [-109.6944, 26.6782],
              [-109.8563, 26.801],
              [-109.917, 27.0601],
              [-110.2876, 27.1484],
              [-110.5548, 27.3848],
              [-110.6267, 27.6085],
              [-110.5866, 27.8371],
              [-110.8082, 27.9278],
              [-111.0591, 27.937],
              [-111.2507, 28.052],
              [-111.4735, 28.381],
              [-111.7084, 28.4601],
              [-111.8944, 28.7807],
              [-112.1507, 28.963],
              [-112.2635, 29.33],
              [-112.506, 29.6169],
              [-112.6864, 29.9078],
              [-112.7515, 29.9243],
              [-112.7532, 30.1859],
              [-112.8707, 30.4285],
              [-113.0799, 30.6704],
              [-113.1288, 30.8124],
              [-113.0487, 31.1567],
              [-113.1169, 31.2176],
              [-113.5489, 31.2957],
              [-113.6596, 31.4975],
              [-113.8757, 31.5967],
              [-114.0289, 31.4915],
              [-114.159, 31.4945],
              [-114.5847, 31.7608],
              [-114.828, 31.7898],
              [-114.7806, 31.6558],
              [-114.8529, 31.5266],
              [-114.8887, 31.1629],
              [-114.8324, 31.0067],
              [-114.7203, 30.9411],
              [-114.6985, 30.6372],
              [-114.6295, 30.4248],
              [-114.6603, 30.1833],
              [-114.3922, 29.783],
              [-114.2712, 29.7721],
              [-113.7709, 29.4078],
              [-113.4787, 28.9462],
              [-113.2604, 28.8401],
              [-113.103, 28.5046],
              [-112.9024, 28.4753],
              [-112.7938, 28.1903],
              [-112.7732, 27.8639],
              [-112.598, 27.6543],
              [-112.3731, 27.5641],
              [-112.1549, 27.162],
              [-112.0112, 27.1168],
              [-112.0172, 26.9785],
              [-111.8181, 26.8962],
              [-111.5601, 26.6957],
              [-111.5691, 26.5762],
              [-111.4422, 26.5175],
              [-111.4616, 26.4001],
              [-111.3183, 26.0737],
              [-111.3603, 25.9641],
              [-111.3071, 25.7792],
              [-111.0263, 25.4586],
              [-110.9045, 25.143],
              [-110.7578, 25.0122],
              [-110.657, 24.8069],
              [-110.7386, 24.5546],
              [-110.6818, 24.3607],
              [-110.5624, 24.2125],
              [-110.4021, 24.1755],
              [-110.1728, 24.253],
              [-110.0181, 24.1693],
              [-109.9877, 24.0463],
              [-109.6984, 23.7984],
              [-109.6798, 23.6482],
              [-109.4765, 23.5602],
              [-109.4256, 23.2972],
              [-109.5317, 23.1082],
              [-109.7132, 23.0311],
              [-109.8954, 22.8742],
              [-110.0984, 23.0176],
              [-110.1673, 23.3211],
              [-110.3074, 23.541],
              [-110.5773, 23.6816],
              [-111.0397, 24.1042],
              [-111.4444, 24.3244],
              [-111.679, 24.5863],
              [-111.8489, 24.6537],
              [-111.9667, 24.7783],
              [-112.088, 24.7734],
              [-112.1425, 25.086],
              [-112.0779, 25.2587],
              [-112.076, 25.7134],
              [-112.1983, 25.9951],
              [-112.3435, 26.1781],
              [-112.7284, 26.3702],
              [-113.0607, 26.5991],
              [-113.1431, 26.7577],
              [-113.5039, 26.7934],
              [-113.6371, 26.7255],
              [-113.8164, 26.949],
              [-114.0052, 26.9774],
              [-114.1144, 27.1055],
              [-114.4875, 27.2381],
              [-114.5065, 27.4062],
              [-114.7342, 27.5213],
              [-114.8866, 27.6949],
              [-114.9234, 27.8363],
              [-114.4991, 27.7741],
              [-114.3253, 27.8747],
              [-114.1732, 27.8268],
              [-114.0397, 28.0277],
              [-114.1249, 28.2557],
              [-114.0426, 28.4584],
              [-114.1658, 28.647],
              [-114.2718, 28.6588],
              [-114.4023, 28.8602],
              [-114.5299, 28.9255],
              [-114.972, 29.3778],
              [-115.1871, 29.4286],
              [-115.2857, 29.5299],
              [-115.4986, 29.6114],
              [-115.6976, 29.7558],
              [-115.8373, 30.3465],
              [-116.0493, 30.4796],
              [-116.068, 30.8132],
              [-116.2497, 30.9568],
              [-116.3094, 31.1485],
              [-116.5301, 31.4403],
              [-116.6756, 31.5526],
              [-116.6197, 31.8576],
              [-116.8423, 31.9865],
              [-116.9483, 32.247],
              [-117.025, 32.2769],
              [-117.1251, 32.5317],
              [-115.7941, 32.632],
              [-114.7243, 32.7128],
              [-114.8221, 32.5002],
              [-113.9617, 32.2341],
              [-112.3247, 31.728],
              [-111.0671, 31.3336],
              [-109.9663, 31.3272],
              [-108.2148, 31.3274],
              [-108.2151, 31.7778],
              [-106.5172, 31.7738],
              [-106.3585, 31.7175],
              [-106.1643, 31.4478],
              [-106.0047, 31.3969],
              [-105.7682, 31.18],
              [-105.5909, 31.0714],
              [-105.3638, 30.8504],
              [-105.0085, 30.677],
              [-104.6816, 30.1929],
              [-104.6805, 29.9423],
              [-104.5308, 29.6679],
              [-104.2672, 29.5266],
              [-104.0573, 29.339],
              [-103.7942, 29.2776],
              [-103.6731, 29.1736],
              [-103.3015, 29.0024],
              [-103.148, 28.9851],
              [-102.8831, 29.3438],
              [-102.6835, 29.7437],
              [-102.4061, 29.7773],
              [-102.3022, 29.8894],
              [-102.1093, 29.8021],
              [-101.9722, 29.8182],
              [-101.4093, 29.7658],
              [-101.1636, 29.5013],
              [-100.8161, 29.2708],
              [-100.669, 29.1162],
              [-100.6243, 28.9247],
              [-100.3971, 28.5927],
              [-100.2843, 28.2965],
              [-100.0749, 28.1544],
              [-99.8788, 27.9018],
              [-99.8634, 27.8047],
              [-99.7004, 27.6592],
              [-99.5072, 27.5738],
              [-99.4976, 27.3388],
              [-99.432, 27.2076],
              [-99.4617, 27.057],
              [-99.2483, 26.8271],
              [-99.1649, 26.5404],
              [-99.0855, 26.4076],
              [-98.7959, 26.3683],
              [-98.6406, 26.2418],
              [-98.3693, 26.194],
              [-98.2227, 26.0754],
              [-97.6796, 26.0346],
              [-97.4254, 25.8549],
              [-97.1393, 25.9658],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Mexico",
        NAME_LONG: "Mexico",
        ADM0_A3: "MEX",
        ISO_A2: "MX",
        ISO_A3: "MEX",
        WB_A2: "MX",
        WB_A3: "MEX",
      },
      id: 146,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.345, 42.3134],
            [22.5311, 42.1291],
            [22.8437, 42.0145],
            [23.0096, 41.7164],
            [22.9337, 41.5846],
            [22.917, 41.3358],
            [22.7513, 41.3152],
            [22.7272, 41.1658],
            [22.4218, 41.1146],
            [22.1832, 41.1599],
            [21.9091, 41.0975],
            [21.737, 40.9192],
            [21.5816, 40.8663],
            [20.9653, 40.8494],
            [20.7027, 40.9363],
            [20.4777, 41.3196],
            [20.5402, 41.4007],
            [20.4442, 41.5497],
            [20.5671, 41.8732],
            [20.7505, 41.9068],
            [20.7654, 42.0643],
            [21.0985, 42.196],
            [21.2378, 42.0974],
            [21.3538, 42.216],
            [21.5641, 42.2463],
            [21.9415, 42.3331],
            [22.345, 42.3134],
          ],
        ],
      },
      properties: {
        NAME: "Macedonia",
        NAME_LONG: "Macedonia",
        ADM0_A3: "MKD",
        ISO_A2: "MK",
        ISO_A3: "MKD",
        WB_A2: "MK",
        WB_A3: "MKD",
      },
      id: 148,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.2286, 19.1422],
            [4.2319, 18.1531],
            [4.2356, 16.9959],
            [4.182, 16.8096],
            [4.184, 16.4161],
            [4.0606, 16.2983],
            [3.9035, 15.8863],
            [3.8712, 15.7148],
            [3.5265, 15.496],
            [3.5071, 15.354],
            [3.073, 15.4272],
            [3.0002, 15.3391],
            [1.3315, 15.2836],
            [0.9737, 14.9913],
            [0.6701, 14.9397],
            [0.5148, 14.9936],
            [0.2185, 14.911],
            [-0.2367, 15.0656],
            [-0.3977, 15.0021],
            [-0.4679, 15.0799],
            [-0.7529, 15.0697],
            [-1.0433, 14.8179],
            [-1.3503, 14.715],
            [-1.6969, 14.4961],
            [-1.967, 14.4837],
            [-2.0234, 14.1986],
            [-2.1519, 14.1561],
            [-2.4617, 14.2809],
            [-2.8409, 14.043],
            [-2.9281, 13.7996],
            [-2.8952, 13.6516],
            [-3.0675, 13.6068],
            [-3.2597, 13.6583],
            [-3.2486, 13.2928],
            [-3.5969, 13.1994],
            [-3.7986, 13.3471],
            [-3.984, 13.3965],
            [-4.3511, 13.1183],
            [-4.2293, 12.949],
            [-4.2119, 12.8192],
            [-4.2972, 12.7121],
            [-4.4825, 12.6463],
            [-4.3869, 12.5302],
            [-4.4516, 12.4353],
            [-4.4061, 12.3075],
            [-4.5855, 12.1972],
            [-4.6537, 12.0691],
            [-4.8068, 11.9963],
            [-4.9537, 12.0051],
            [-5.1678, 11.9437],
            [-5.2682, 11.8431],
            [-5.3078, 11.629],
            [-5.2183, 11.4222],
            [-5.3223, 11.1355],
            [-5.4898, 11.0821],
            [-5.4314, 10.8447],
            [-5.5226, 10.4255],
            [-5.8063, 10.4135],
            [-6.0159, 10.1899],
            [-6.1809, 10.2158],
            [-6.2078, 10.6375],
            [-6.28, 10.7233],
            [-6.434, 10.6679],
            [-6.5214, 10.5642],
            [-6.6679, 10.6509],
            [-6.7003, 10.3417],
            [-6.9731, 10.3323],
            [-6.9594, 10.1855],
            [-7.347, 10.2479],
            [-7.4662, 10.4295],
            [-7.7086, 10.4025],
            [-7.829, 10.2117],
            [-7.9897, 10.162],
            [-8.0157, 10.3393],
            [-8.2289, 10.4234],
            [-8.2955, 10.5374],
            [-8.3111, 11.0008],
            [-8.5058, 11.0528],
            [-8.6969, 10.996],
            [-8.4832, 11.2849],
            [-8.5276, 11.4234],
            [-8.679, 11.5298],
            [-8.7129, 11.6401],
            [-8.8472, 11.6579],
            [-8.797, 11.9132],
            [-8.9263, 12.0643],
            [-8.9938, 12.3875],
            [-9.3215, 12.4966],
            [-9.3085, 12.345],
            [-9.6282, 12.1702],
            [-9.723, 12.0254],
            [-9.8707, 12.0521],
            [-10.2669, 12.2178],
            [-10.3814, 12.1801],
            [-10.7114, 11.8904],
            [-10.8049, 12.096],
            [-10.9098, 12.2001],
            [-11.0523, 12.2025],
            [-11.1912, 12.0144],
            [-11.3513, 12.0404],
            [-11.5078, 12.1916],
            [-11.3884, 12.4039],
            [-11.461, 12.6584],
            [-11.4022, 12.7317],
            [-11.4503, 13.0751],
            [-11.5331, 13.1118],
            [-11.6131, 13.3608],
            [-11.7565, 13.3998],
            [-11.9968, 13.5442],
            [-12.0976, 13.7044],
            [-11.9629, 13.9092],
            [-12.0252, 14.0267],
            [-11.9976, 14.1662],
            [-12.1156, 14.3551],
            [-12.2217, 14.3912],
            [-12.1659, 14.6252],
            [-12.2641, 14.7749],
            [-11.974, 14.7798],
            [-11.8371, 14.8931],
            [-11.8457, 15.1791],
            [-11.7271, 15.5412],
            [-11.4096, 15.6022],
            [-11.2982, 15.451],
            [-11.0454, 15.2702],
            [-10.9152, 15.1029],
            [-10.7364, 15.4226],
            [-10.3087, 15.4352],
            [-10.1316, 15.3726],
            [-9.8356, 15.3711],
            [-9.6721, 15.4307],
            [-9.4239, 15.4405],
            [-9.3492, 15.4956],
            [-8.327, 15.4953],
            [-6.6739, 15.4947],
            [-5.511, 15.4959],
            [-5.3533, 16.312],
            [-5.6233, 16.5278],
            [-5.7565, 17.741],
            [-5.8575, 18.6608],
            [-5.9635, 19.6206],
            [-6.1137, 20.9196],
            [-6.2194, 21.8229],
            [-6.3747, 23.1503],
            [-6.4984, 24.2084],
            [-6.5931, 24.9941],
            [-4.8216, 24.9951],
            [-3.4485, 24.106],
            [-2.5232, 23.501],
            [-1.83, 23.0478],
            [-0.9636, 22.4813],
            [-0.0104, 21.8582],
            [1.1465, 21.1017],
            [1.1453, 20.7959],
            [1.644, 20.5227],
            [1.6492, 20.4121],
            [1.7781, 20.3043],
            [2.0563, 20.215],
            [2.1824, 20.2785],
            [2.4004, 20.0566],
            [2.946, 19.9417],
            [3.2168, 19.7941],
            [3.1994, 19.5538],
            [3.2582, 19.4104],
            [3.1027, 19.1536],
            [3.3084, 18.9817],
            [4.2286, 19.1422],
          ],
        ],
      },
      properties: {
        NAME: "Mali",
        NAME_LONG: "Mali",
        ADM0_A3: "MLI",
        ISO_A2: "ML",
        ISO_A3: "MLI",
        WB_A2: "ML",
        WB_A3: "MLI",
      },
      id: 149,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.5437, 11.799],
              [98.549, 11.6217],
              [98.4419, 11.5641],
              [98.3821, 11.7762],
              [98.5437, 11.799],
            ],
          ],
          [
            [
              [97.5714, 16.2227],
              [97.4593, 16.3014],
              [97.4522, 16.4494],
              [97.6063, 16.4835],
              [97.5714, 16.2227],
            ],
          ],
          [
            [
              [93.6836, 18.869],
              [93.759, 18.7556],
              [93.6453, 18.6793],
              [93.4978, 18.8409],
              [93.6836, 18.869],
            ],
          ],
          [
            [
              [93.8031, 19.4874],
              [93.9707, 19.4169],
              [93.9658, 19.363],
              [93.8569, 19.2789],
              [93.7738, 19.2988],
              [93.6418, 19.492],
              [93.8031, 19.4874],
            ],
          ],
          [
            [
              [101.159, 21.5527],
              [101.0034, 21.406],
              [100.7254, 21.3117],
              [100.6376, 21.0697],
              [100.5157, 20.8865],
              [100.2113, 20.6974],
              [100.0993, 20.3178],
              [99.942, 20.444],
              [99.7696, 20.3284],
              [99.5029, 20.3452],
              [99.5258, 20.1962],
              [99.2957, 20.0625],
              [99.1175, 20.1178],
              [99.0168, 20.041],
              [99.0088, 19.8459],
              [98.8078, 19.8065],
              [98.5326, 19.6759],
              [98.2179, 19.708],
              [98.0179, 19.7894],
              [98.0083, 19.6391],
              [97.8398, 19.5553],
              [97.7671, 19.3975],
              [97.8108, 19.1122],
              [97.6578, 18.9257],
              [97.7191, 18.8646],
              [97.7518, 18.5825],
              [97.4381, 18.4881],
              [97.5606, 18.3283],
              [97.7541, 17.969],
              [97.6855, 17.8807],
              [97.7689, 17.6792],
              [97.9828, 17.5054],
              [98.1006, 17.3026],
              [98.3133, 17.052],
              [98.4362, 17.0116],
              [98.5246, 16.8504],
              [98.4567, 16.7232],
              [98.6316, 16.4631],
              [98.657, 16.3016],
              [98.9033, 16.3634],
              [98.8072, 16.1105],
              [98.6587, 16.1163],
              [98.5838, 15.977],
              [98.5431, 15.7354],
              [98.5599, 15.3553],
              [98.4034, 15.3601],
              [98.283, 15.29],
              [98.165, 15.1258],
              [98.2433, 14.8051],
              [98.4182, 14.6076],
              [98.5477, 14.3777],
              [98.9453, 14.0691],
              [99.1525, 13.7149],
              [99.1902, 13.2295],
              [99.1021, 13.1713],
              [99.2141, 12.7347],
              [99.3938, 12.5898],
              [99.387, 12.4659],
              [99.4663, 12.126],
              [99.63, 11.8158],
              [99.2433, 11.1977],
              [99.213, 11.1083],
              [99.0015, 10.9576],
              [98.7667, 10.6888],
              [98.7913, 10.5204],
              [98.7474, 10.3505],
              [98.5899, 10.0421],
              [98.4907, 10.2043],
              [98.5285, 10.3642],
              [98.4593, 10.7154],
              [98.7124, 10.9244],
              [98.7593, 11.2689],
              [98.698, 11.388],
              [98.6843, 11.6828],
              [98.6104, 11.7313],
              [98.7202, 11.9643],
              [98.552, 12.2796],
              [98.6912, 12.7104],
              [98.5838, 13.0266],
              [98.5785, 13.1868],
              [98.48, 13.2839],
              [98.4317, 13.4787],
              [98.2448, 13.7141],
              [98.1033, 13.701],
              [98.0617, 14.0101],
              [98.1086, 14.1326],
              [97.7948, 14.8803],
              [97.8071, 15.175],
              [97.7264, 15.3396],
              [97.7948, 15.4079],
              [97.7271, 15.8432],
              [97.5823, 16.0091],
              [97.6499, 16.2838],
              [97.6266, 16.4376],
              [97.6519, 16.5072],
              [97.5484, 16.5382],
              [97.4026, 16.5169],
              [97.2554, 16.6979],
              [97.199, 16.9975],
              [97.0686, 17.2514],
              [96.8494, 17.213],
              [96.9109, 17.0248],
              [96.801, 16.7374],
              [96.6159, 16.518],
              [96.3364, 16.4394],
              [96.2853, 16.366],
              [96.0676, 16.3102],
              [95.98, 16.2231],
              [95.7555, 16.1452],
              [95.6647, 15.9492],
              [95.3965, 15.7164],
              [95.1736, 15.7778],
              [95.1152, 15.8939],
              [94.9756, 15.7641],
              [94.8587, 15.7826],
              [94.8396, 15.9621],
              [94.6587, 15.8545],
              [94.5623, 15.9619],
              [94.6838, 16.2092],
              [94.507, 16.1896],
              [94.34, 15.9962],
              [94.2151, 16.1577],
              [94.2731, 16.5382],
              [94.4027, 16.7883],
              [94.3789, 16.8869],
              [94.5725, 17.3111],
              [94.5952, 17.6155],
              [94.4474, 17.9733],
              [94.481, 18.0942],
              [94.3897, 18.3627],
              [94.2796, 18.4802],
              [94.2124, 18.7418],
              [94.1336, 18.8465],
              [93.9304, 18.8586],
              [93.7503, 18.9704],
              [93.4978, 19.3139],
              [93.7969, 19.2352],
              [93.9299, 19.2998],
              [93.9873, 19.3867],
              [93.9844, 19.4641],
              [93.9146, 19.4728],
              [93.7396, 19.6175],
              [93.6014, 19.7834],
              [93.5659, 19.9166],
              [93.4136, 19.9775],
              [93.3748, 20.0885],
              [93.1481, 19.9943],
              [92.7721, 20.2015],
              [92.9012, 20.3081],
              [92.8316, 20.4789],
              [92.6532, 20.4183],
              [92.4536, 20.6685],
              [92.3811, 20.7001],
              [92.2651, 21.0611],
              [92.175, 21.1756],
              [92.1837, 21.3172],
              [92.3227, 21.4619],
              [92.4231, 21.3637],
              [92.6429, 21.2984],
              [92.5902, 21.4955],
              [92.5759, 21.9776],
              [92.7081, 22.148],
              [92.8539, 22.0241],
              [92.9672, 22.0627],
              [93.169, 22.2469],
              [93.0992, 22.512],
              [93.0795, 22.7727],
              [93.3749, 23.1297],
              [93.4324, 23.6473],
              [93.303, 24.0411],
              [93.4567, 23.96],
              [93.6124, 24.0091],
              [93.9979, 23.917],
              [94.0997, 23.8426],
              [94.1903, 23.9953],
              [94.29, 24.3168],
              [94.5163, 24.705],
              [94.5895, 24.7473],
              [94.7086, 25.0259],
              [94.6891, 25.1385],
              [94.5501, 25.2453],
              [94.608, 25.3946],
              [94.8508, 25.5628],
              [95.0088, 25.7371],
              [95.0006, 25.922],
              [95.1395, 26.0299],
              [95.0463, 26.248],
              [95.0406, 26.4756],
              [95.1191, 26.6042],
              [95.3927, 26.6918],
              [95.778, 26.9951],
              [95.8889, 27.027],
              [96.0132, 27.1908],
              [96.1426, 27.2575],
              [96.7589, 27.3417],
              [96.8653, 27.1716],
              [97.0268, 27.0915],
              [97.1348, 27.1273],
              [96.9051, 27.4084],
              [96.8702, 27.6191],
              [97.2229, 27.8997],
              [97.3335, 27.8942],
              [97.3235, 28.2175],
              [97.4367, 28.2864],
              [97.5277, 28.5295],
              [97.6996, 28.488],
              [97.74, 28.3826],
              [97.8975, 28.3553],
              [98.1187, 28.1408],
              [98.1071, 27.9731],
              [98.2947, 27.5366],
              [98.4, 27.6757],
              [98.6793, 27.5773],
              [98.7042, 27.3042],
              [98.6726, 27.1756],
              [98.7492, 26.7714],
              [98.7055, 26.1749],
              [98.5455, 26.1056],
              [98.6924, 25.879],
              [98.4613, 25.7947],
              [98.3326, 25.567],
              [98.1502, 25.6131],
              [98.1084, 25.3889],
              [97.7375, 25.0905],
              [97.6639, 24.8298],
              [97.5361, 24.745],
              [97.5196, 24.4308],
              [97.6399, 24.4393],
              [97.7184, 24.2777],
              [97.7077, 24.1253],
              [97.5165, 23.9428],
              [97.6371, 23.8636],
              [97.89, 24.0226],
              [98.1815, 24.1187],
              [98.5033, 24.1213],
              [98.6633, 23.9695],
              [98.6637, 23.7969],
              [98.7779, 23.7693],
              [98.8574, 23.6043],
              [98.9107, 23.2976],
              [99.0264, 23.1604],
              [99.2166, 23.0574],
              [99.351, 23.1193],
              [99.5346, 22.9493],
              [99.3079, 22.7196],
              [99.3578, 22.4955],
              [99.1664, 22.2046],
              [99.1664, 22.1321],
              [99.4386, 22.1242],
              [99.9424, 22.0455],
              [99.9503, 21.7212],
              [100.0823, 21.6845],
              [100.09, 21.5289],
              [100.1871, 21.4279],
              [100.3265, 21.5243],
              [100.4567, 21.455],
              [100.6217, 21.4691],
              [100.797, 21.6261],
              [101.0687, 21.762],
              [101.159, 21.5527],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Myanmar",
        NAME_LONG: "Myanmar",
        ADM0_A3: "MMR",
        ISO_A2: "MM",
        ISO_A3: "MMR",
        WB_A2: "MM",
        WB_A3: "MMR",
      },
      id: 151,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.1953, 43.5328],
            [19.6187, 43.1682],
            [19.9292, 43.1139],
            [20.3454, 42.8274],
            [20.0763, 42.7734],
            [20.065, 42.5468],
            [19.8196, 42.4665],
            [19.605, 42.5848],
            [19.272, 42.1807],
            [19.3749, 42.0947],
            [19.3651, 41.8524],
            [19.1703, 41.938],
            [19.1392, 42.0424],
            [18.7786, 42.2715],
            [18.6838, 42.4588],
            [18.4964, 42.4163],
            [18.4374, 42.5592],
            [18.5384, 42.6183],
            [18.4528, 42.9934],
            [18.639, 43.0202],
            [18.6795, 43.2495],
            [18.8991, 43.3519],
            [18.911, 43.5073],
            [19.1953, 43.5328],
          ],
        ],
      },
      properties: {
        NAME: "Montenegro",
        NAME_LONG: "Montenegro",
        ADM0_A3: "MNE",
        ISO_A2: "ME",
        ISO_A3: "MNE",
        WB_A2: "ME",
        WB_A3: "MNE",
      },
      id: 152,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [116.6843, 49.8233],
            [116.0378, 48.8701],
            [116.0282, 48.7675],
            [115.8004, 48.5304],
            [115.8093, 48.274],
            [115.5145, 48.1221],
            [115.5619, 47.9332],
            [115.8527, 47.7056],
            [115.9729, 47.7091],
            [116.2439, 47.8629],
            [116.4997, 47.8364],
            [116.8533, 47.8721],
            [117.0697, 47.8102],
            [117.3171, 47.6541],
            [117.7417, 47.9776],
            [118.1825, 48.0282],
            [118.5423, 47.9662],
            [118.7674, 47.7562],
            [119.0835, 47.6616],
            [119.1576, 47.5177],
            [119.7, 47.1595],
            [119.9029, 46.8424],
            [119.9042, 46.7089],
            [119.6801, 46.5916],
            [119.3169, 46.6117],
            [118.8162, 46.7582],
            [118.7201, 46.6767],
            [118.2383, 46.7154],
            [117.6962, 46.5123],
            [117.3938, 46.5714],
            [117.3017, 46.3501],
            [116.8046, 46.3829],
            [116.6036, 46.3093],
            [116.3572, 46.1056],
            [116.2132, 45.9082],
            [116.1699, 45.7091],
            [116.012, 45.6786],
            [115.6378, 45.4444],
            [114.9389, 45.3738],
            [114.7373, 45.425],
            [114.5337, 45.3855],
            [114.4189, 45.2015],
            [114.0553, 44.9406],
            [113.8893, 44.9083],
            [113.6351, 44.7463],
            [113.1049, 44.7944],
            [112.6142, 44.9088],
            [112.4413, 45.0524],
            [112.0115, 45.0875],
            [111.7385, 44.9663],
            [111.4064, 44.4165],
            [111.5194, 44.1886],
            [111.8388, 43.9388],
            [111.9334, 43.6966],
            [111.7538, 43.6632],
            [111.5972, 43.5234],
            [110.9337, 43.2878],
            [110.4067, 42.7686],
            [110.0749, 42.6435],
            [109.6673, 42.549],
            [109.4851, 42.4493],
            [108.9945, 42.4495],
            [108.7969, 42.3984],
            [108.1774, 42.4543],
            [106.7678, 42.2866],
            [105.8686, 41.9935],
            [105.2745, 41.7555],
            [105.0148, 41.5961],
            [104.8923, 41.6448],
            [104.498, 41.6658],
            [104.5008, 41.8706],
            [103.721, 41.7556],
            [103.0735, 42.0045],
            [102.0342, 42.1846],
            [101.6376, 42.5154],
            [101.2187, 42.5298],
            [100.017, 42.6765],
            [99.4745, 42.5642],
            [98.6622, 42.6436],
            [97.1933, 42.7873],
            [96.3506, 42.7409],
            [96.2943, 42.9333],
            [95.9085, 43.2145],
            [95.8328, 43.4089],
            [95.5103, 43.9791],
            [95.3275, 44.0067],
            [95.3794, 44.2871],
            [95.0368, 44.255],
            [94.6982, 44.3435],
            [94.3385, 44.5121],
            [94.195, 44.6543],
            [93.9751, 44.66],
            [93.714, 44.8746],
            [93.5253, 44.9513],
            [92.7527, 45.038],
            [92.4751, 44.9975],
            [92.0094, 45.0762],
            [91.663, 45.0595],
            [91.135, 45.1979],
            [90.9055, 45.186],
            [90.6511, 45.4931],
            [90.7108, 45.7522],
            [91.0024, 46.0218],
            [90.8962, 46.302],
            [91.0475, 46.5664],
            [91.0048, 46.7404],
            [90.8282, 46.9823],
            [90.7121, 47.0147],
            [90.4687, 47.3089],
            [90.4412, 47.493],
            [90.3274, 47.623],
            [90.0829, 47.7561],
            [90.0446, 47.8798],
            [89.7513, 47.8243],
            [89.5418, 48.031],
            [89.0456, 47.993],
            [88.5808, 48.212],
            [88.5651, 48.343],
            [87.9428, 48.5995],
            [88.0338, 48.7291],
            [87.8059, 48.8006],
            [87.7569, 48.9204],
            [87.8163, 49.1658],
            [88.144, 49.2707],
            [88.2, 49.4543],
            [88.3788, 49.4758],
            [88.8706, 49.436],
            [89.1607, 49.5027],
            [89.2522, 49.6294],
            [89.6823, 49.7083],
            [89.6239, 49.9027],
            [89.9694, 49.9671],
            [90.0512, 50.0842],
            [90.4752, 50.2146],
            [90.6839, 50.2162],
            [91.012, 50.4253],
            [91.4157, 50.4614],
            [91.7498, 50.6841],
            [92.1588, 50.6891],
            [92.3017, 50.8434],
            [92.6498, 50.6741],
            [92.9617, 50.7779],
            [93.0114, 50.6233],
            [93.5363, 50.5847],
            [94.2376, 50.5652],
            [94.3443, 50.2492],
            [94.6245, 50.0152],
            [94.9216, 50.0459],
            [95.0764, 49.9455],
            [95.4806, 49.8922],
            [95.867, 50.015],
            [96.3729, 49.8861],
            [96.9692, 49.8867],
            [97.3017, 49.7255],
            [97.5427, 49.8227],
            [97.5666, 49.9168],
            [97.8474, 49.9301],
            [98.1056, 50.0639],
            [98.2625, 50.2838],
            [98.2935, 50.5186],
            [98.0233, 50.642],
            [97.9902, 50.8356],
            [97.8064, 51.0011],
            [98.0509, 51.4664],
            [98.2204, 51.4752],
            [98.3322, 51.7183],
            [98.7111, 51.8451],
            [98.8556, 52.1067],
            [99.3239, 51.9342],
            [99.6793, 51.8887],
            [100.006, 51.7317],
            [100.5109, 51.7269],
            [101.3878, 51.4506],
            [101.4821, 51.4729],
            [102.0512, 51.3836],
            [102.1839, 51.3239],
            [102.1465, 51.0976],
            [102.2168, 50.7989],
            [102.3294, 50.7188],
            [102.3276, 50.5455],
            [102.5866, 50.4153],
            [103.2531, 50.215],
            [103.6014, 50.1335],
            [103.7853, 50.1861],
            [104.1217, 50.1483],
            [104.4058, 50.3005],
            [105.3286, 50.4765],
            [105.9849, 50.3998],
            [106.2449, 50.2903],
            [106.6565, 50.327],
            [106.9737, 50.1964],
            [107.2249, 49.9971],
            [107.9465, 49.9335],
            [108.0327, 49.5893],
            [108.2505, 49.5224],
            [108.5381, 49.3274],
            [109.2867, 49.3385],
            [109.5162, 49.2559],
            [109.7285, 49.2665],
            [110.1814, 49.162],
            [110.3712, 49.2417],
            [110.7314, 49.1377],
            [111.3385, 49.3647],
            [112.0302, 49.4119],
            [112.4322, 49.5292],
            [112.7337, 49.4928],
            [113.0437, 49.5886],
            [113.2124, 49.822],
            [113.5797, 50.0199],
            [113.8498, 50.0807],
            [114.2863, 50.2769],
            [114.7537, 50.2362],
            [114.9974, 50.1443],
            [115.3687, 49.8954],
            [115.7509, 49.885],
            [116.2176, 50.0138],
            [116.5754, 49.9218],
            [116.6843, 49.8233],
          ],
        ],
      },
      properties: {
        NAME: "Mongolia",
        NAME_LONG: "Mongolia",
        ADM0_A3: "MNG",
        ISO_A2: "MN",
        ISO_A3: "MNG",
        WB_A2: "MN",
        WB_A3: "MNG",
      },
      id: 153,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.8931, -26.8461],
            [32.3522, -26.8603],
            [32.1139, -26.84],
            [32.1174, -26.5823],
            [32.0399, -26.2837],
            [32.0575, -26.0412],
            [31.9492, -25.9581],
            [31.9119, -25.7853],
            [31.9916, -25.6502],
            [31.9866, -24.4231],
            [31.8695, -24.1638],
            [31.8558, -23.9465],
            [31.7412, -23.8368],
            [31.6423, -23.5879],
            [31.5215, -23.4156],
            [31.5363, -23.157],
            [31.2889, -22.3973],
            [31.4338, -22.302],
            [32.4085, -21.2903],
            [32.3398, -21.1341],
            [32.4972, -20.8981],
            [32.4691, -20.6868],
            [32.5131, -20.5646],
            [32.6465, -20.558],
            [32.8452, -20.2867],
            [32.8853, -20.103],
            [33.002, -19.927],
            [33.0328, -19.7842],
            [32.8163, -19.6521],
            [32.7688, -19.3636],
            [32.8623, -19.1181],
            [32.6911, -19.0143],
            [32.6914, -18.8342],
            [32.8852, -18.7878],
            [32.8707, -18.5358],
            [32.9964, -18.4671],
            [33.035, -18.3329],
            [32.9523, -18.233],
            [32.9371, -18.0471],
            [33.0225, -17.3615],
            [32.8288, -16.9351],
            [32.9334, -16.8158],
            [32.8934, -16.7124],
            [32.6987, -16.6868],
            [32.6718, -16.5998],
            [32.2905, -16.4518],
            [31.9103, -16.4289],
            [31.6861, -16.2072],
            [31.4046, -16.1623],
            [31.26, -16.0235],
            [31.1141, -15.9969],
            [30.9731, -16.062],
            [30.8574, -15.9981],
            [30.4026, -16.0012],
            [30.3963, -15.636],
            [30.3692, -15.3747],
            [30.2879, -15.2789],
            [30.2145, -14.9815],
            [30.8158, -14.7682],
            [31.4109, -14.6336],
            [31.6584, -14.5196],
            [32.2274, -14.339],
            [33.2027, -14.0139],
            [33.4392, -14.3807],
            [33.6042, -14.524],
            [33.8889, -14.4882],
            [34.3441, -14.3874],
            [34.5018, -14.5757],
            [34.5842, -14.9845],
            [34.5691, -15.2712],
            [34.4101, -15.4813],
            [34.404, -15.6468],
            [34.2471, -15.7941],
            [34.2332, -15.8898],
            [34.3877, -16.037],
            [34.3852, -16.1865],
            [34.5247, -16.3008],
            [35.0045, -16.8146],
            [35.0958, -16.8173],
            [35.0813, -17.1241],
            [35.305, -17.1042],
            [35.2782, -16.7007],
            [35.1319, -16.5376],
            [35.2144, -16.4842],
            [35.2588, -16.2715],
            [35.3746, -16.141],
            [35.6976, -16.1075],
            [35.7957, -16.005],
            [35.8405, -15.4172],
            [35.7845, -15.1952],
            [35.9043, -14.8869],
            [35.853, -14.6675],
            [35.5228, -14.2627],
            [35.4557, -14.137],
            [35.0841, -13.7042],
            [34.8944, -13.5347],
            [34.6406, -13.4924],
            [34.5453, -13.3257],
            [34.5493, -13.1591],
            [34.4945, -12.6874],
            [34.354, -12.1995],
            [34.5638, -11.8309],
            [34.6512, -11.57],
            [34.9646, -11.5736],
            [35.6548, -11.5783],
            [35.8264, -11.4135],
            [35.9397, -11.4341],
            [36.1681, -11.5787],
            [36.1997, -11.7015],
            [36.4949, -11.6809],
            [36.563, -11.7313],
            [36.747, -11.6665],
            [36.8201, -11.5736],
            [37.0444, -11.5957],
            [37.2266, -11.6938],
            [37.4278, -11.7226],
            [37.7837, -11.5589],
            [37.8752, -11.3191],
            [38.0708, -11.2558],
            [38.2566, -11.2771],
            [38.4923, -11.4135],
            [38.6542, -11.2758],
            [38.8963, -11.1677],
            [39.2866, -11.1542],
            [39.4827, -10.9992],
            [39.7605, -10.9431],
            [40.0081, -10.8111],
            [40.4369, -10.4748],
            [40.5321, -10.5095],
            [40.6021, -10.8205],
            [40.4961, -10.9557],
            [40.5065, -11.1874],
            [40.3488, -11.3171],
            [40.4714, -11.4168],
            [40.4295, -11.6415],
            [40.5369, -11.9968],
            [40.4803, -12.4843],
            [40.6468, -12.7553],
            [40.5601, -12.8139],
            [40.5679, -13.3666],
            [40.5427, -13.6589],
            [40.6506, -14.0319],
            [40.6096, -14.1386],
            [40.7461, -14.2709],
            [40.8425, -14.4645],
            [40.8355, -14.8023],
            [40.6709, -14.9767],
            [40.6817, -15.233],
            [40.5733, -15.505],
            [40.4258, -15.615],
            [40.1646, -15.9156],
            [39.9924, -16.2224],
            [39.8133, -16.277],
            [39.6983, -16.5322],
            [39.2044, -16.817],
            [39.0905, -16.9838],
            [38.5955, -17.0944],
            [38.0399, -17.3278],
            [37.9089, -17.3552],
            [37.3609, -17.65],
            [37.0864, -17.8691],
            [36.8874, -18.1891],
            [36.552, -18.486],
            [36.2443, -18.8832],
            [36.1086, -18.8059],
            [35.6623, -19.133],
            [35.3399, -19.5207],
            [35.1268, -19.705],
            [34.8801, -19.8634],
            [34.7576, -19.8691],
            [34.7769, -20.135],
            [34.6799, -20.3469],
            [34.7424, -20.5502],
            [34.9933, -20.7302],
            [35.1201, -20.9664],
            [35.058, -21.1376],
            [35.1516, -21.4539],
            [35.271, -21.6441],
            [35.3358, -22.0891],
            [35.4968, -22.1415],
            [35.5422, -22.4099],
            [35.4927, -22.5649],
            [35.5208, -22.9076],
            [35.6009, -22.9436],
            [35.4785, -23.1769],
            [35.3948, -23.8419],
            [35.5432, -23.8632],
            [35.4935, -24.1105],
            [35.1065, -24.5979],
            [34.6489, -24.8128],
            [34.4827, -24.8513],
            [33.7292, -25.1051],
            [33.1531, -25.3658],
            [32.8727, -25.5439],
            [32.687, -25.8859],
            [32.5915, -25.969],
            [32.6808, -26.1882],
            [32.9295, -26.266],
            [32.8931, -26.8461],
          ],
        ],
      },
      properties: {
        NAME: "Mozambique",
        NAME_LONG: "Mozambique",
        ADM0_A3: "MOZ",
        ISO_A2: "MZ",
        ISO_A3: "MOZ",
        WB_A2: "MZ",
        WB_A3: "MOZ",
      },
      id: 155,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.2641, 14.7749],
            [-12.3825, 14.846],
            [-12.4715, 14.998],
            [-12.6903, 15.0924],
            [-12.8568, 15.222],
            [-12.9643, 15.5092],
            [-13.0896, 15.497],
            [-13.2378, 15.6234],
            [-13.3137, 15.9221],
            [-13.4849, 16.155],
            [-13.8113, 16.1221],
            [-13.9641, 16.2301],
            [-14.0156, 16.3559],
            [-14.3433, 16.6367],
            [-14.9309, 16.6434],
            [-15.1251, 16.5827],
            [-15.4909, 16.5645],
            [-15.6722, 16.4814],
            [-16.0538, 16.4954],
            [-16.1323, 16.5548],
            [-16.3274, 16.4746],
            [-16.3811, 16.2467],
            [-16.466, 16.1741],
            [-16.5423, 15.8088],
            [-16.5392, 16.2672],
            [-16.4592, 16.6439],
            [-16.3431, 16.9423],
            [-16.1726, 17.2624],
            [-16.049, 17.6672],
            [-16.0242, 17.9602],
            [-16.0694, 18.484],
            [-16.132, 18.6286],
            [-16.2046, 18.9827],
            [-16.2828, 19.1304],
            [-16.4746, 19.2708],
            [-16.3592, 19.4158],
            [-16.3846, 19.5739],
            [-16.2338, 19.7936],
            [-16.298, 19.9225],
            [-16.214, 20.0333],
            [-16.1997, 20.2152],
            [-16.2951, 20.3306],
            [-16.3912, 20.5695],
            [-16.5525, 20.5764],
            [-16.665, 20.6706],
            [-16.7454, 20.8686],
            [-16.9312, 21.1515],
            [-17.0159, 21.0312],
            [-17.0569, 20.7669],
            [-17.0812, 20.8747],
            [-16.9588, 21.3329],
            [-16.2677, 21.3329],
            [-15.0023, 21.3332],
            [-14.0155, 21.3333],
            [-13.0152, 21.3334],
            [-13.0933, 22.4955],
            [-13.1655, 22.7527],
            [-13.0152, 23.018],
            [-12.6194, 23.2708],
            [-12.3539, 23.3225],
            [-12.0153, 23.4952],
            [-12.0153, 25.1633],
            [-12.0153, 25.9949],
            [-10.5044, 25.995],
            [-8.7072, 25.995],
            [-8.6808, 26.0131],
            [-8.6824, 27.2854],
            [-7.297, 26.4707],
            [-6.1399, 25.7902],
            [-4.8216, 24.9951],
            [-6.5931, 24.9941],
            [-6.4984, 24.2084],
            [-6.3747, 23.1503],
            [-6.2194, 21.8229],
            [-6.1137, 20.9196],
            [-5.9635, 19.6206],
            [-5.8575, 18.6608],
            [-5.7565, 17.741],
            [-5.6233, 16.5278],
            [-5.3533, 16.312],
            [-5.511, 15.4959],
            [-6.6739, 15.4947],
            [-8.327, 15.4953],
            [-9.3492, 15.4956],
            [-9.4239, 15.4405],
            [-9.6721, 15.4307],
            [-9.8356, 15.3711],
            [-10.1316, 15.3726],
            [-10.3087, 15.4352],
            [-10.7364, 15.4226],
            [-10.9152, 15.1029],
            [-11.0454, 15.2702],
            [-11.2982, 15.451],
            [-11.4096, 15.6022],
            [-11.7271, 15.5412],
            [-11.8457, 15.1791],
            [-11.8371, 14.8931],
            [-11.974, 14.7798],
            [-12.2641, 14.7749],
          ],
        ],
      },
      properties: {
        NAME: "Mauritania",
        NAME_LONG: "Mauritania",
        ADM0_A3: "MRT",
        ISO_A2: "MR",
        ISO_A3: "MRT",
        WB_A2: "MR",
        WB_A3: "MRT",
      },
      id: 156,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [57.7139, -20.0961],
            [57.7957, -20.2228],
            [57.6722, -20.4797],
            [57.5036, -20.5173],
            [57.3578, -20.4274],
            [57.3706, -20.2605],
            [57.5325, -20.0248],
            [57.7139, -20.0961],
          ],
        ],
      },
      properties: {
        NAME: "Mauritius",
        NAME_LONG: "Mauritius",
        ADM0_A3: "MUS",
        ISO_A2: "MU",
        ISO_A3: "MUS",
        WB_A2: "MU",
        WB_A3: "MUS",
      },
      id: 158,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.9646, -11.5736],
            [34.6512, -11.57],
            [34.5638, -11.8309],
            [34.354, -12.1995],
            [34.4945, -12.6874],
            [34.5493, -13.1591],
            [34.5453, -13.3257],
            [34.6406, -13.4924],
            [34.8944, -13.5347],
            [35.0841, -13.7042],
            [35.4557, -14.137],
            [35.5228, -14.2627],
            [35.853, -14.6675],
            [35.9043, -14.8869],
            [35.7845, -15.1952],
            [35.8405, -15.4172],
            [35.7957, -16.005],
            [35.6976, -16.1075],
            [35.3746, -16.141],
            [35.2588, -16.2715],
            [35.2144, -16.4842],
            [35.1319, -16.5376],
            [35.2782, -16.7007],
            [35.305, -17.1042],
            [35.0813, -17.1241],
            [35.0958, -16.8173],
            [35.0045, -16.8146],
            [34.5247, -16.3008],
            [34.3852, -16.1865],
            [34.3877, -16.037],
            [34.2332, -15.8898],
            [34.2471, -15.7941],
            [34.404, -15.6468],
            [34.4101, -15.4813],
            [34.5691, -15.2712],
            [34.5842, -14.9845],
            [34.5018, -14.5757],
            [34.3441, -14.3874],
            [33.8889, -14.4882],
            [33.6042, -14.524],
            [33.4392, -14.3807],
            [33.2027, -14.0139],
            [33.0494, -13.9966],
            [32.8594, -13.8081],
            [32.7228, -13.5734],
            [32.8743, -13.4432],
            [32.9951, -13.1909],
            [33.005, -12.893],
            [32.9411, -12.7699],
            [33.0248, -12.6127],
            [33.3732, -12.5185],
            [33.5181, -12.3718],
            [33.3301, -12.2983],
            [33.2549, -12.0726],
            [33.3127, -11.8987],
            [33.3026, -11.6097],
            [33.2303, -11.4166],
            [33.3909, -11.1648],
            [33.3037, -11.0346],
            [33.3191, -10.8181],
            [33.501, -10.7692],
            [33.6742, -10.577],
            [33.5293, -10.3186],
            [33.5333, -10.2312],
            [33.31, -10.0127],
            [33.3656, -9.8988],
            [33.2167, -9.7271],
            [32.9747, -9.6013],
            [32.9209, -9.4079],
            [33.1729, -9.511],
            [33.3003, -9.4922],
            [33.4042, -9.6049],
            [33.7346, -9.5841],
            [33.8653, -9.6696],
            [34.0127, -9.4775],
            [34.2881, -9.7085],
            [34.4833, -9.9462],
            [34.5853, -10.3007],
            [34.5811, -10.5397],
            [34.6724, -10.7395],
            [34.593, -11.0162],
            [34.7591, -11.3375],
            [34.8866, -11.3754],
            [34.9646, -11.5736],
          ],
        ],
      },
      properties: {
        NAME: "Malawi",
        NAME_LONG: "Malawi",
        ADM0_A3: "MWI",
        ISO_A2: "MW",
        ISO_A3: "MWI",
        WB_A2: "MW",
        WB_A3: "MWI",
      },
      id: 159,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [111.4124, 2.3874],
              [111.3081, 2.4521],
              [111.299, 2.7027],
              [111.381, 2.6979],
              [111.4124, 2.3874],
            ],
          ],
          [
            [
              [117.907, 4.1567],
              [117.7036, 4.1634],
              [117.7854, 4.2466],
              [117.907, 4.1567],
            ],
          ],
          [
            [
              [102.0731, 6.2575],
              [102.3157, 6.1904],
              [102.4998, 5.8974],
              [103.1203, 5.3813],
              [103.4486, 4.7951],
              [103.4942, 4.3366],
              [103.3952, 4.1163],
              [103.438, 3.975],
              [103.3387, 3.7582],
              [103.4822, 3.5118],
              [103.4281, 3.3672],
              [103.4346, 2.9618],
              [103.4736, 2.851],
              [103.6384, 2.6721],
              [103.7671, 2.6238],
              [103.949, 2.3399],
              [103.9764, 2.1924],
              [104.1209, 1.954],
              [104.2552, 1.6378],
              [104.2947, 1.4465],
              [104.2192, 1.3462],
              [103.8397, 1.4765],
              [103.6814, 1.4418],
              [103.5041, 1.2899],
              [103.3674, 1.5384],
              [102.8045, 1.8601],
              [102.7219, 1.8471],
              [102.489, 2.0929],
              [102.188, 2.2164],
              [101.9888, 2.3945],
              [101.8582, 2.4194],
              [101.7831, 2.5759],
              [101.5423, 2.6613],
              [101.2831, 2.9112],
              [101.3728, 3.0023],
              [101.2976, 3.2746],
              [101.1084, 3.4739],
              [101.0405, 3.622],
              [100.7207, 3.8681],
              [100.7761, 4.0865],
              [100.6326, 4.1555],
              [100.5654, 4.3192],
              [100.6326, 4.5329],
              [100.5774, 4.8543],
              [100.4539, 4.9089],
              [100.3645, 5.0843],
              [100.43, 5.3029],
              [100.344, 5.6693],
              [100.3491, 6.0127],
              [100.1273, 6.4423],
              [100.1668, 6.6951],
              [100.2808, 6.6889],
              [100.3874, 6.5221],
              [100.8056, 6.4148],
              [100.8221, 6.2599],
              [101.0817, 6.2465],
              [101.0711, 5.9198],
              [100.9672, 5.781],
              [101.1054, 5.6376],
              [101.249, 5.787],
              [101.5347, 5.906],
              [101.6504, 5.7826],
              [101.8005, 5.7399],
              [101.9129, 5.8593],
              [101.9594, 6.0116],
              [102.06, 6.0948],
              [102.0731, 6.2575],
            ],
          ],
          [
            [
              [117.5671, 4.1597],
              [117.4129, 4.2001],
              [117.1647, 4.3331],
              [116.4941, 4.3249],
              [116.3107, 4.3834],
              [116.1258, 4.3789],
              [116.0206, 4.2709],
              [115.8788, 4.3521],
              [115.6469, 4.1691],
              [115.5485, 3.6053],
              [115.6006, 3.4412],
              [115.5176, 3.3572],
              [115.4641, 3.0308],
              [115.2421, 3.0416],
              [115.0669, 2.8282],
              [115.0565, 2.6086],
              [115.1265, 2.4792],
              [114.9196, 2.2957],
              [114.7788, 2.2472],
              [114.753, 2.1348],
              [114.8453, 2.025],
              [114.832, 1.9025],
              [114.6714, 1.821],
              [114.669, 1.694],
              [114.5006, 1.436],
              [114.3557, 1.502],
              [114.1604, 1.4248],
              [113.9097, 1.4425],
              [113.6495, 1.2333],
              [113.5034, 1.3145],
              [113.089, 1.4313],
              [113.0234, 1.537],
              [112.4705, 1.5677],
              [112.1801, 1.449],
              [112.1001, 1.1549],
              [111.9101, 1.114],
              [111.8231, 1.0085],
              [111.3518, 1.0139],
              [111.1976, 1.0752],
              [110.8527, 0.9972],
              [110.784, 0.9082],
              [110.5539, 0.8514],
              [110.2566, 1.0391],
              [110.1704, 1.1745],
              [109.9763, 1.2877],
              [109.6773, 1.5729],
              [109.6472, 1.7619],
              [109.5284, 1.89],
              [109.6453, 2.0832],
              [109.6546, 1.937],
              [109.9277, 1.6923],
              [110.3665, 1.7159],
              [110.7267, 1.5474],
              [110.9679, 1.5032],
              [111.2113, 2.0739],
              [111.2085, 2.3889],
              [111.3655, 2.3408],
              [111.4483, 2.3724],
              [111.4046, 2.484],
              [111.4403, 2.6801],
              [111.6696, 2.8431],
              [112.0135, 2.8859],
              [112.9744, 3.1442],
              [113.2776, 3.4842],
              [113.4343, 3.7532],
              [113.7368, 4.0013],
              [113.9468, 4.2738],
              [113.9988, 4.6011],
              [114.2456, 4.4814],
              [114.2967, 4.3167],
              [114.4199, 4.2601],
              [114.5866, 4.0214],
              [114.7669, 4.1318],
              [114.8364, 4.3572],
              [114.7518, 4.7385],
              [114.9817, 4.8891],
              [115.0298, 4.8206],
              [115.0355, 4.6212],
              [115.113, 4.3824],
              [115.3244, 4.3146],
              [115.2727, 4.6368],
              [115.1462, 4.9085],
              [115.3584, 4.9028],
              [115.557, 5.0672],
              [115.5608, 5.2078],
              [115.3596, 5.3196],
              [115.5501, 5.5402],
              [115.8486, 5.56],
              [115.9199, 5.7468],
              [116.0508, 5.8423],
              [116.2013, 6.218],
              [116.5032, 6.4952],
              [116.645, 6.7169],
              [116.7464, 7.0104],
              [116.9738, 6.7081],
              [117.0293, 6.91],
              [117.1438, 7.0037],
              [117.3089, 6.6288],
              [117.5098, 6.6022],
              [117.7438, 6.3899],
              [117.723, 6.2546],
              [117.6056, 6.1818],
              [117.6546, 5.9587],
              [117.884, 5.9547],
              [118.0169, 6.061],
              [118.1252, 5.8639],
              [117.938, 5.8145],
              [117.9649, 5.6862],
              [118.1267, 5.6935],
              [118.1763, 5.8052],
              [118.4107, 5.7961],
              [118.7366, 5.5478],
              [118.9709, 5.4283],
              [119.2048, 5.4433],
              [119.2743, 5.3612],
              [119.2671, 5.2011],
              [119.1559, 5.1062],
              [118.6815, 4.9443],
              [118.509, 4.9311],
              [118.3572, 5.0388],
              [118.1365, 4.8823],
              [118.3335, 4.6516],
              [118.5996, 4.5192],
              [118.5428, 4.36],
              [117.8923, 4.257],
              [117.6712, 4.426],
              [117.5671, 4.1597],
            ],
          ],
          [
            [
              [117.2624, 7.202],
              [117.0641, 7.1267],
              [117.0641, 7.2707],
              [117.1577, 7.3407],
              [117.2624, 7.202],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Malaysia",
        NAME_LONG: "Malaysia",
        ADM0_A3: "MYS",
        ISO_A2: "MY",
        ISO_A3: "MYS",
        WB_A2: "MY",
        WB_A3: "MYS",
      },
      id: 160,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.3817, -17.6411],
            [24.2205, -17.4795],
            [24.6844, -17.4924],
            [24.958, -17.5517],
            [25.2598, -17.7941],
            [24.9581, -17.8009],
            [24.7253, -17.8959],
            [24.5778, -18.0444],
            [24.4216, -17.9565],
            [24.1831, -18.0294],
            [23.6454, -18.466],
            [23.5792, -18.4679],
            [23.5015, -18.2375],
            [23.3115, -18.0098],
            [22.9814, -18.02],
            [21.4757, -18.2995],
            [20.9751, -18.3193],
            [20.9776, -19.7508],
            [20.9807, -20.7507],
            [20.9848, -21.964],
            [20.972, -22.0007],
            [19.9783, -22.0007],
            [19.9798, -23.2045],
            [19.9814, -24.7525],
            [19.9823, -25.9424],
            [19.9827, -26.8955],
            [19.9817, -28.4223],
            [19.5688, -28.5312],
            [19.4829, -28.6782],
            [19.2486, -28.7716],
            [19.2889, -28.871],
            [19.0817, -28.9594],
            [18.9545, -28.8667],
            [18.7457, -28.8399],
            [18.3976, -28.8989],
            [18.1665, -28.9019],
            [17.9133, -28.7813],
            [17.4036, -28.7043],
            [17.3282, -28.4561],
            [17.4021, -28.3678],
            [17.3457, -28.2276],
            [17.192, -28.2088],
            [17.0978, -28.0312],
            [16.893, -28.0826],
            [16.7644, -28.2832],
            [16.7693, -28.4426],
            [16.4871, -28.5729],
            [16.4194, -28.607],
            [15.9111, -28.1713],
            [15.6843, -27.9499],
            [15.5207, -27.6337],
            [15.2953, -27.3224],
            [15.2342, -26.9654],
            [15.083, -26.6952],
            [15.1681, -26.6028],
            [15.1288, -26.4576],
            [14.9663, -26.3352],
            [14.979, -26.0588],
            [14.8428, -25.7636],
            [14.877, -25.5781],
            [14.8013, -25.2628],
            [14.8533, -25.0642],
            [14.7808, -24.8034],
            [14.6019, -24.5624],
            [14.6123, -24.4457],
            [14.4736, -24.1591],
            [14.4953, -23.5785],
            [14.4343, -23.4152],
            [14.492, -23.3092],
            [14.4102, -22.9708],
            [14.522, -22.9234],
            [14.5086, -22.548],
            [14.3865, -22.2765],
            [14.1372, -21.9464],
            [13.9523, -21.7839],
            [13.8317, -21.4565],
            [13.6027, -21.1581],
            [13.3872, -20.8161],
            [13.1546, -20.1542],
            [13.0709, -20.1142],
            [13.0054, -19.9355],
            [12.7046, -19.4108],
            [12.5674, -19.1023],
            [12.2869, -18.6977],
            [12.0319, -18.5054],
            [11.9373, -18.2321],
            [11.8491, -18.1432],
            [11.7176, -17.5463],
            [11.7661, -17.2527],
            [12.0953, -17.1397],
            [12.2426, -17.2248],
            [12.5546, -17.2356],
            [12.8872, -17.0298],
            [13.1663, -16.9511],
            [13.3637, -16.9642],
            [13.5213, -17.1219],
            [13.8844, -17.3385],
            [13.9427, -17.4082],
            [14.2074, -17.388],
            [15.0572, -17.3883],
            [16.3398, -17.3887],
            [17.6226, -17.389],
            [18.4536, -17.3899],
            [18.762, -17.7477],
            [19.0206, -17.8223],
            [19.1721, -17.8012],
            [19.4213, -17.8594],
            [19.6726, -17.8427],
            [20.0343, -17.8959],
            [20.3362, -17.8549],
            [20.5655, -17.9852],
            [20.8062, -18.0314],
            [21.2166, -17.9306],
            [21.3812, -18.0126],
            [22.2305, -17.8576],
            [23.3817, -17.6411],
          ],
        ],
      },
      properties: {
        NAME: "Namibia",
        NAME_LONG: "Namibia",
        ADM0_A3: "NAM",
        ISO_A2: "NA",
        ISO_A3: "NAM",
        WB_A2: "NA",
        WB_A3: "NAM",
      },
      id: 161,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [167.9719, -21.3753],
              [168.1375, -21.4318],
              [168.1291, -21.6013],
              [167.9582, -21.6384],
              [167.8568, -21.5007],
              [167.9719, -21.3753],
            ],
          ],
          [
            [
              [167.3013, -20.7131],
              [167.2847, -20.9018],
              [167.3855, -20.9417],
              [167.4234, -21.1476],
              [167.1463, -21.073],
              [167.0674, -20.9964],
              [167.1853, -20.7882],
              [167.3013, -20.7131],
            ],
          ],
          [
            [
              [164.0135, -20.0831],
              [164.2443, -20.2853],
              [164.3158, -20.2348],
              [164.4956, -20.3002],
              [164.8278, -20.5827],
              [165.0615, -20.7341],
              [165.2175, -20.7644],
              [165.4131, -20.9492],
              [165.4067, -21.0554],
              [165.7544, -21.3023],
              [166.0667, -21.4699],
              [166.4113, -21.7055],
              [166.5734, -21.8749],
              [166.9388, -22.0798],
              [167.0288, -22.2268],
              [166.9116, -22.385],
              [166.7777, -22.392],
              [166.6328, -22.277],
              [166.4784, -22.2872],
              [166.1445, -22.0466],
              [165.6777, -21.7614],
              [165.2908, -21.5768],
              [165.1165, -21.3977],
              [164.9204, -21.2851],
              [164.8008, -21.0822],
              [164.6566, -20.9459],
              [164.3656, -20.7581],
              [164.2866, -20.5759],
              [164.1926, -20.5258],
              [164.1325, -20.301],
              [164.0402, -20.301],
              [164.0135, -20.0831],
            ],
          ],
        ],
      },
      properties: {
        NAME: "New Caledonia",
        NAME_LONG: "New Caledonia",
        ADM0_A3: "NCL",
        ISO_A2: "NC",
        ISO_A3: "NCL",
        WB_A2: "NC",
        WB_A3: "NCL",
      },
      id: 162,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.9799, 22.9957],
            [15.172, 21.9934],
            [15.1804, 21.5073],
            [15.301, 21.4013],
            [15.6093, 20.9507],
            [15.5442, 20.799],
            [15.954, 20.3746],
            [15.963, 20.3192],
            [15.736, 19.9035],
            [15.5911, 18.2651],
            [15.472, 16.9167],
            [15.4524, 16.8762],
            [14.369, 15.7496],
            [13.8339, 15.0196],
            [13.7605, 14.8664],
            [13.7644, 14.7191],
            [13.6483, 14.6494],
            [13.6243, 14.5213],
            [13.4823, 14.4836],
            [13.4492, 14.3801],
            [13.6073, 13.7041],
            [13.36, 13.7144],
            [13.2281, 13.548],
            [12.8838, 13.496],
            [12.6831, 13.2889],
            [12.5611, 13.2428],
            [12.4727, 13.0642],
            [12.0576, 13.1168],
            [11.8528, 13.2454],
            [11.44, 13.3644],
            [10.675, 13.3751],
            [10.1615, 13.2672],
            [9.9301, 13.146],
            [9.6407, 12.8225],
            [9.2694, 12.8098],
            [8.9428, 12.8472],
            [8.6793, 12.9236],
            [8.5335, 13.0646],
            [8.3978, 13.0754],
            [8.0775, 13.31],
            [7.7556, 13.3271],
            [7.392, 13.1125],
            [7.1982, 13.1174],
            [7.0901, 13.0064],
            [6.9066, 13.0069],
            [6.7787, 13.1303],
            [6.6725, 13.3212],
            [6.3689, 13.6263],
            [6.1204, 13.6541],
            [5.5543, 13.8734],
            [5.3741, 13.8553],
            [5.2735, 13.7525],
            [4.9251, 13.7331],
            [4.8569, 13.7741],
            [4.5067, 13.6947],
            [4.2485, 13.4941],
            [4.1258, 13.473],
            [4.0888, 12.9962],
            [3.9291, 12.7504],
            [3.6457, 12.5285],
            [3.6515, 12.269],
            [3.6039, 11.9056],
            [3.667, 11.7597],
            [3.5964, 11.6958],
            [3.5035, 11.83],
            [3.2927, 11.9187],
            [3.2551, 12.0188],
            [2.8443, 12.3992],
            [2.3612, 12.2189],
            [2.3902, 11.8965],
            [2.0709, 12.3069],
            [2.245, 12.4242],
            [2.109, 12.7056],
            [1.9717, 12.7242],
            [1.8261, 12.6042],
            [1.5359, 12.6475],
            [1.114, 12.9962],
            [0.9718, 13.0673],
            [0.9835, 13.3684],
            [1.0482, 13.4419],
            [0.951, 13.5832],
            [0.6207, 13.68],
            [0.3392, 14.1258],
            [0.3888, 14.2516],
            [0.1886, 14.4477],
            [0.1529, 14.5467],
            [0.2197, 14.7312],
            [0.2185, 14.911],
            [0.5148, 14.9936],
            [0.6701, 14.9397],
            [0.9737, 14.9913],
            [1.3315, 15.2836],
            [3.0002, 15.3391],
            [3.073, 15.4272],
            [3.5071, 15.354],
            [3.5265, 15.496],
            [3.8712, 15.7148],
            [3.9035, 15.8863],
            [4.0606, 16.2983],
            [4.184, 16.4161],
            [4.182, 16.8096],
            [4.2356, 16.9959],
            [4.2319, 18.1531],
            [4.2286, 19.1422],
            [5.7493, 19.4337],
            [5.8376, 19.4786],
            [6.61, 20.1429],
            [7.4827, 20.8726],
            [8.6345, 21.5515],
            [9.7233, 22.1934],
            [11.0844, 22.9958],
            [11.9689, 23.5174],
            [13.4823, 23.1797],
            [13.5996, 23.119],
            [14.2016, 22.6232],
            [14.2317, 22.6179],
            [14.9799, 22.9957],
          ],
        ],
      },
      properties: {
        NAME: "Niger",
        NAME_LONG: "Niger",
        ADM0_A3: "NER",
        ISO_A2: "NE",
        ISO_A3: "NER",
        WB_A2: "NE",
        WB_A3: "NER",
      },
      id: 163,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.6073, 13.7041],
            [14.0649, 13.078],
            [14.1774, 12.5456],
            [14.1798, 12.3856],
            [14.5144, 12.3235],
            [14.6699, 12.1674],
            [14.5465, 11.7186],
            [14.6214, 11.6298],
            [14.5938, 11.4964],
            [14.1655, 11.2383],
            [13.9823, 11.2763],
            [13.7548, 11.0167],
            [13.7444, 10.9305],
            [13.5663, 10.679],
            [13.4349, 10.1534],
            [13.248, 10.0794],
            [13.2127, 9.8701],
            [13.2629, 9.8017],
            [13.1955, 9.5422],
            [12.9824, 9.4746],
            [12.862, 9.3829],
            [12.8884, 9.2267],
            [12.8221, 9.0968],
            [12.8057, 8.8315],
            [12.6604, 8.6593],
            [12.377, 8.5951],
            [12.4039, 8.4965],
            [12.25, 8.4188],
            [12.1978, 7.9752],
            [12.055, 7.7839],
            [12.025, 7.5966],
            [11.8447, 7.3964],
            [11.7802, 7.2411],
            [11.8813, 7.102],
            [11.6232, 6.9323],
            [11.5098, 6.6123],
            [11.3195, 6.4373],
            [11.0971, 6.4491],
            [11.0559, 6.7004],
            [10.8775, 6.8161],
            [10.8319, 6.9452],
            [10.6025, 7.0581],
            [10.496, 6.8747],
            [10.2384, 6.871],
            [10.1195, 6.9944],
            [9.8746, 6.7877],
            [9.7698, 6.7737],
            [9.693, 6.5313],
            [9.5881, 6.5027],
            [9.3274, 6.2985],
            [9.2733, 6.2028],
            [8.8559, 5.8475],
            [8.8193, 5.7035],
            [8.8937, 5.6218],
            [8.7989, 5.1596],
            [8.5942, 4.8153],
            [8.2984, 4.5533],
            [8.032, 4.5588],
            [7.5584, 4.5253],
            [7.1697, 4.6059],
            [7.1782, 4.5015],
            [6.9695, 4.3746],
            [6.0967, 4.2781],
            [5.9463, 4.3352],
            [5.5949, 4.6365],
            [5.4863, 4.835],
            [5.3636, 5.1716],
            [5.3402, 5.3439],
            [5.198, 5.5015],
            [5.0073, 5.8492],
            [4.7171, 6.1636],
            [4.4055, 6.3589],
            [4.1258, 6.4103],
            [3.5414, 6.4548],
            [3.3845, 6.3977],
            [2.7038, 6.3684],
            [2.7786, 6.6988],
            [2.7151, 6.9798],
            [2.7739, 7.0508],
            [2.7206, 7.5866],
            [2.7272, 7.7933],
            [2.6722, 7.8896],
            [2.735, 8.2119],
            [2.6971, 8.3207],
            [2.7448, 8.4983],
            [2.7229, 8.7723],
            [2.7692, 9.057],
            [2.9831, 9.0606],
            [3.0913, 9.1264],
            [3.1514, 9.2886],
            [3.1286, 9.4296],
            [3.3386, 9.6782],
            [3.3171, 9.7817],
            [3.5131, 9.8466],
            [3.5996, 9.9687],
            [3.6556, 10.1748],
            [3.5724, 10.2857],
            [3.6521, 10.4358],
            [3.7569, 10.4053],
            [3.8374, 10.5999],
            [3.7313, 10.825],
            [3.7221, 11.1123],
            [3.4833, 11.3923],
            [3.5049, 11.5566],
            [3.5964, 11.6958],
            [3.667, 11.7597],
            [3.6039, 11.9056],
            [3.6515, 12.269],
            [3.6457, 12.5285],
            [3.9291, 12.7504],
            [4.0888, 12.9962],
            [4.1258, 13.473],
            [4.2485, 13.4941],
            [4.5067, 13.6947],
            [4.8569, 13.7741],
            [4.9251, 13.7331],
            [5.2735, 13.7525],
            [5.3741, 13.8553],
            [5.5543, 13.8734],
            [6.1204, 13.6541],
            [6.3689, 13.6263],
            [6.6725, 13.3212],
            [6.7787, 13.1303],
            [6.9066, 13.0069],
            [7.0901, 13.0064],
            [7.1982, 13.1174],
            [7.392, 13.1125],
            [7.7556, 13.3271],
            [8.0775, 13.31],
            [8.3978, 13.0754],
            [8.5335, 13.0646],
            [8.6793, 12.9236],
            [8.9428, 12.8472],
            [9.2694, 12.8098],
            [9.6407, 12.8225],
            [9.9301, 13.146],
            [10.1615, 13.2672],
            [10.675, 13.3751],
            [11.44, 13.3644],
            [11.8528, 13.2454],
            [12.0576, 13.1168],
            [12.4727, 13.0642],
            [12.5611, 13.2428],
            [12.6831, 13.2889],
            [12.8838, 13.496],
            [13.2281, 13.548],
            [13.36, 13.7144],
            [13.6073, 13.7041],
          ],
        ],
      },
      properties: {
        NAME: "Nigeria",
        NAME_LONG: "Nigeria",
        ADM0_A3: "NGA",
        ISO_A2: "NG",
        ISO_A3: "NGA",
        WB_A2: "NG",
        WB_A3: "NGA",
      },
      id: 165,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.1304, 14.997],
            [-83.4141, 14.8277],
            [-83.2992, 14.7712],
            [-83.1918, 14.3869],
            [-83.2186, 14.2503],
            [-83.4313, 13.9567],
            [-83.4938, 13.7377],
            [-83.5701, 13.2871],
            [-83.5077, 12.9028],
            [-83.5891, 12.6321],
            [-83.7672, 12.5467],
            [-83.6637, 12.2561],
            [-83.7132, 12.0493],
            [-83.8361, 11.8712],
            [-83.6999, 11.8499],
            [-83.653, 11.6115],
            [-83.7611, 11.5571],
            [-83.868, 11.3749],
            [-83.8373, 11.1425],
            [-83.7521, 10.9822],
            [-83.6444, 10.9259],
            [-83.663, 10.807],
            [-83.9333, 10.7181],
            [-84.0227, 10.7872],
            [-84.6765, 11.0704],
            [-84.9314, 10.9419],
            [-85.6312, 11.1962],
            [-85.7017, 11.0809],
            [-85.7858, 11.1123],
            [-85.928, 11.3107],
            [-86.3365, 11.6114],
            [-86.4964, 11.7594],
            [-86.7975, 12.2185],
            [-87.0714, 12.3887],
            [-87.4549, 12.7438],
            [-87.6465, 12.8724],
            [-87.5839, 13.0573],
            [-87.435, 12.9224],
            [-87.314, 12.9816],
            [-87.0847, 12.9959],
            [-86.9484, 13.0697],
            [-86.9108, 13.2396],
            [-86.7019, 13.3142],
            [-86.7662, 13.7453],
            [-86.5417, 13.7821],
            [-86.4101, 13.749],
            [-86.0967, 14.0441],
            [-85.8242, 13.8477],
            [-85.4317, 14.1328],
            [-85.0296, 14.589],
            [-85.002, 14.7407],
            [-84.7702, 14.8051],
            [-84.6993, 14.6747],
            [-84.4827, 14.6195],
            [-84.0236, 14.7583],
            [-83.8695, 14.7771],
            [-83.3903, 15.031],
            [-83.1304, 14.997],
          ],
        ],
      },
      properties: {
        NAME: "Nicaragua",
        NAME_LONG: "Nicaragua",
        ADM0_A3: "NIC",
        ISO_A2: "NI",
        ISO_A3: "NIC",
        WB_A2: "NI",
        WB_A3: "NIC",
      },
      id: 166,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [4.2215, 51.368],
              [3.927, 51.2063],
              [3.6351, 51.2878],
              [3.3917, 51.2468],
              [3.3494, 51.3752],
              [3.5414, 51.4167],
              [3.7555, 51.3501],
              [4.2215, 51.368],
            ],
          ],
          [
            [
              [7.1946, 53.245],
              [7.1836, 52.9661],
              [7.0367, 52.6474],
              [6.7018, 52.6191],
              [6.6951, 52.4756],
              [7.0482, 52.3651],
              [7.0263, 52.2306],
              [6.7659, 52.1084],
              [6.7167, 51.8993],
              [6.3825, 51.8276],
              [6.2252, 51.8625],
              [5.928, 51.8067],
              [6.1933, 51.5093],
              [6.1836, 51.3357],
              [5.9829, 51.0747],
              [6.0637, 50.9075],
              [5.9949, 50.7499],
              [5.6407, 50.815],
              [5.8405, 51.1389],
              [5.4716, 51.2881],
              [5.2154, 51.2587],
              [5.0681, 51.3745],
              [4.7312, 51.4856],
              [4.5252, 51.4759],
              [4.2611, 51.3694],
              [3.9431, 51.4504],
              [3.8272, 51.3911],
              [3.5354, 51.4592],
              [3.5604, 51.5946],
              [4.118, 51.6307],
              [4.01, 51.9801],
              [4.1419, 52.0056],
              [4.5081, 52.3364],
              [4.7455, 52.9676],
              [4.9131, 52.9056],
              [5.3792, 53.0974],
              [5.5916, 53.3028],
              [5.9808, 53.4061],
              [6.7302, 53.4606],
              [7.1946, 53.245],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Netherlands",
        NAME_LONG: "Netherlands",
        ADM0_A3: "NLD",
        ISO_A2: "NL",
        ISO_A3: "NLD",
        WB_A2: "NL",
        WB_A3: "NLD",
      },
      id: 168,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [5.676, 60.4729],
              [5.324, 60.5468],
              [5.6877, 60.6766],
              [5.676, 60.4729],
            ],
          ],
          [
            [
              [8.9652, 63.6562],
              [9.1068, 63.5333],
              [8.5036, 63.4342],
              [8.5453, 63.6025],
              [8.9652, 63.6562],
            ],
          ],
          [
            [
              [13.9675, 68.2696],
              [13.7188, 68.0776],
              [13.4857, 68.1068],
              [13.6367, 68.2943],
              [13.9675, 68.2696],
            ],
          ],
          [
            [
              [15.1546, 68.4387],
              [14.7632, 68.259],
              [14.6535, 68.4285],
              [15.1546, 68.4387],
            ],
          ],
          [
            [
              [15.9079, 68.9573],
              [15.9331, 68.6841],
              [16.5055, 68.8032],
              [16.4815, 68.5475],
              [16.0935, 68.5335],
              [15.904, 68.3699],
              [15.2166, 68.3354],
              [15.2388, 68.5643],
              [15.5138, 68.6362],
              [15.4592, 68.7961],
              [15.6766, 68.9627],
              [15.9079, 68.9573],
            ],
          ],
          [
            [
              [15.1929, 68.9342],
              [15.3942, 68.7311],
              [15.0627, 68.5785],
              [14.4172, 68.6195],
              [15.1929, 68.9342],
            ],
          ],
          [
            [
              [17.7357, 69.5517],
              [18.0786, 69.425],
              [17.9231, 69.3323],
              [17.9583, 69.1805],
              [16.8233, 69.0544],
              [16.9153, 69.3176],
              [17.2634, 69.4784],
              [17.7357, 69.5517],
            ],
          ],
          [
            [
              [18.8416, 69.878],
              [19.0608, 69.7871],
              [18.8229, 69.5907],
              [18.262, 69.5306],
              [18.3568, 69.7929],
              [18.8416, 69.878],
            ],
          ],
          [
            [
              [19.2234, 70.0737],
              [19.5402, 69.9516],
              [19.1216, 69.7878],
              [18.7693, 69.9553],
              [19.2234, 70.0737],
            ],
          ],
          [
            [
              [23.5015, 70.6166],
              [23.6623, 70.4698],
              [23.2163, 70.2828],
              [22.8972, 70.451],
              [23.5015, 70.6166],
            ],
          ],
          [
            [
              [23.3965, 70.8426],
              [23.4605, 70.7908],
              [22.9138, 70.5438],
              [22.7737, 70.6502],
              [23.3965, 70.8426],
            ],
          ],
          [
            [
              [30.841, 69.8058],
              [30.853, 69.5274],
              [30.5117, 69.5363],
              [29.9007, 69.3582],
              [29.3345, 69.2774],
              [29.2405, 69.1151],
              [28.9541, 69.0273],
              [28.8648, 69.2166],
              [29.3445, 69.4644],
              [29.1312, 69.6742],
              [28.4046, 69.8023],
              [27.8669, 70.0753],
              [27.3118, 69.9295],
              [26.5007, 69.9183],
              [26.032, 69.6967],
              [25.7341, 69.1913],
              [25.8011, 69.0132],
              [25.6516, 68.8835],
              [25.2013, 68.8126],
              [25.1176, 68.6377],
              [24.6634, 68.6661],
              [23.929, 68.8162],
              [23.7348, 68.7154],
              [23.1976, 68.618],
              [22.5965, 68.7246],
              [22.0231, 69.012],
              [21.6629, 69.2632],
              [21.3136, 69.2914],
              [20.6232, 69.0364],
              [20.3411, 68.9101],
              [20.2459, 68.4774],
              [19.931, 68.3502],
              [19.0381, 68.5056],
              [18.1718, 68.5359],
              [18.1667, 68.1585],
              [17.8954, 67.9697],
              [17.3215, 68.1053],
              [16.808, 67.9106],
              [16.483, 67.5578],
              [16.1271, 67.4228],
              [16.4379, 67.2003],
              [16.4156, 67.0527],
              [16.0388, 66.8875],
              [15.6695, 66.5994],
              [15.4263, 66.4912],
              [15.4819, 66.2749],
              [15.0534, 66.1533],
              [14.5405, 66.1254],
              [14.6444, 65.8429],
              [14.5607, 65.7011],
              [14.5143, 65.3181],
              [14.3107, 65.0841],
              [13.6428, 64.584],
              [14.1156, 64.4412],
              [14.1614, 64.2054],
              [13.9394, 64.0095],
              [13.2341, 64.0909],
              [12.6819, 63.9564],
              [12.1703, 63.5981],
              [12.2049, 63.4546],
              [11.9924, 63.2889],
              [12.2227, 63.0015],
              [12.1044, 62.9233],
              [12.0912, 62.5863],
              [12.3175, 62.234],
              [12.1617, 61.7248],
              [12.6138, 61.547],
              [12.8771, 61.371],
              [12.6825, 61.0403],
              [12.2561, 60.9813],
              [12.377, 60.7541],
              [12.5944, 60.5149],
              [12.4873, 60.2959],
              [12.5109, 60.118],
              [11.9035, 59.77],
              [11.6774, 59.5591],
              [11.8117, 59.2497],
              [11.6644, 58.9201],
              [11.4375, 58.9917],
              [11.3705, 59.1007],
              [10.8503, 59.1804],
              [10.4837, 59.5145],
              [10.282, 59.0997],
              [9.8462, 58.9556],
              [9.4281, 58.8931],
              [9.3405, 58.7512],
              [8.5134, 58.2934],
              [8.1229, 58.102],
              [7.3906, 58.0175],
              [6.9994, 58.0307],
              [6.6262, 58.2447],
              [6.1403, 58.3397],
              [5.9285, 58.4836],
              [5.7042, 58.5208],
              [5.478, 58.7547],
              [5.6262, 58.9256],
              [5.8825, 58.9593],
              [5.8727, 59.087],
              [6.1123, 59.2746],
              [5.8596, 59.3508],
              [5.4754, 59.2788],
              [5.1823, 59.5112],
              [5.4372, 59.7082],
              [5.7461, 59.6755],
              [5.7722, 59.9307],
              [6.098, 60.0919],
              [5.304, 60.1906],
              [5.1707, 60.2787],
              [5.2383, 60.4892],
              [5.6177, 60.4172],
              [5.7083, 60.4711],
              [5.7386, 60.7051],
              [5.3001, 60.5608],
              [5.033, 60.7417],
              [5.0039, 61.0161],
              [5.1252, 61.1451],
              [4.9641, 61.2657],
              [5.0571, 61.4443],
              [5.2903, 61.4796],
              [5.2293, 61.6287],
              [4.9745, 61.7386],
              [5.2445, 61.8173],
              [5.2979, 62.0682],
              [5.9287, 62.1989],
              [6.3047, 62.4592],
              [6.3196, 62.6085],
              [6.6222, 62.5888],
              [6.9704, 62.7256],
              [6.9102, 62.9294],
              [7.2712, 63.0141],
              [7.5011, 62.9108],
              [7.7483, 62.9421],
              [8.2362, 63.1507],
              [8.6245, 63.1281],
              [8.5147, 63.3059],
              [8.7918, 63.4312],
              [9.1677, 63.402],
              [9.6991, 63.6445],
              [9.9606, 63.4277],
              [10.7777, 63.4875],
              [10.7557, 63.6251],
              [11.4639, 63.8017],
              [11.0964, 63.8896],
              [10.91, 63.731],
              [10.4158, 63.5794],
              [9.9182, 63.5147],
              [9.7991, 63.6918],
              [9.5459, 63.7744],
              [9.9939, 63.9294],
              [10.0137, 64.1188],
              [10.4626, 64.2941],
              [10.6152, 64.4501],
              [11.0762, 64.5236],
              [11.3621, 64.4113],
              [11.5213, 64.5182],
              [11.402, 64.6632],
              [11.7764, 64.9767],
              [11.9785, 65.0688],
              [12.4715, 65.0893],
              [12.3421, 65.3026],
              [12.6022, 65.4323],
              [12.3489, 65.6384],
              [12.9175, 65.953],
              [12.869, 66.1343],
              [13.2254, 66.1858],
              [13.0818, 66.498],
              [13.2839, 66.6607],
              [13.6883, 66.835],
              [14.2228, 66.9815],
              [14.4069, 67.2781],
              [14.8, 67.4218],
              [14.7268, 67.4887],
              [15.0407, 67.6776],
              [15.0632, 67.8388],
              [15.7887, 67.9514],
              [15.7101, 68.0846],
              [16.104, 68.1602],
              [16.1724, 68.3179],
              [16.5348, 68.4383],
              [16.6292, 68.6417],
              [17.2344, 68.756],
              [17.6794, 69.0919],
              [18.0813, 69.1691],
              [18.0031, 69.2857],
              [18.301, 69.4868],
              [18.8527, 69.5523],
              [19.0983, 69.7362],
              [19.7842, 69.8044],
              [20.007, 69.7685],
              [20.315, 69.9638],
              [20.512, 69.7695],
              [21.1704, 69.8756],
              [21.2531, 70.003],
              [21.9046, 69.8254],
              [21.9401, 69.9857],
              [21.2092, 70.2085],
              [21.9868, 70.3234],
              [23.3578, 70.1153],
              [23.5132, 70.3655],
              [24.2643, 70.6063],
              [24.8602, 70.9284],
              [25.3418, 70.9527],
              [25.8252, 70.8275],
              [25.2459, 70.5279],
              [25.4411, 70.3422],
              [26.0122, 70.6262],
              [26.0708, 70.7125],
              [26.6136, 70.9491],
              [26.7443, 70.8235],
              [26.513, 70.4316],
              [26.9761, 70.5274],
              [27.3913, 70.8764],
              [27.2532, 71.0263],
              [27.5687, 71.0972],
              [28.5461, 70.9799],
              [28.1305, 70.736],
              [28.2332, 70.6152],
              [28.7947, 70.8732],
              [29.3805, 70.7694],
              [30.2068, 70.6815],
              [31.0633, 70.3668],
              [30.5488, 70.2487],
              [30.1331, 70.0732],
              [29.6859, 69.9695],
              [29.7754, 69.8386],
              [30.1652, 69.8795],
              [30.841, 69.8058],
            ],
          ],
          [
            [
              [25.677, 71.1606],
              [25.9686, 70.9777],
              [25.5335, 70.9318],
              [25.2933, 71.0452],
              [25.677, 71.1606],
            ],
          ],
          [
            [
              [23.3278, 78.2001],
              [23.1205, 77.9859],
              [23.6283, 77.8703],
              [24.4248, 77.8259],
              [23.8928, 77.5084],
              [23.2345, 77.2637],
              [22.4297, 77.2637],
              [22.1917, 77.5041],
              [20.9217, 77.4424],
              [21.2608, 77.7331],
              [21.6618, 77.918],
              [20.8801, 78.0854],
              [21.6968, 78.2111],
              [23.3278, 78.2001],
            ],
          ],
          [
            [
              [21.4761, 78.5866],
              [22.2354, 78.4879],
              [22.2713, 78.2675],
              [21.0612, 78.2003],
              [20.1831, 78.4953],
              [21.4761, 78.5866],
            ],
          ],
          [
            [
              [16.8201, 79.8758],
              [17.5625, 79.8979],
              [18.8818, 79.4411],
              [18.9451, 79.157],
              [19.7863, 79.1651],
              [19.9064, 79.0142],
              [21.5221, 78.8537],
              [21.0374, 78.6442],
              [20.2196, 78.6462],
              [18.993, 78.467],
              [18.9206, 78.1785],
              [18.3304, 77.905],
              [18.4452, 77.7563],
              [18.1941, 77.4902],
              [17.8133, 77.4935],
              [17.4832, 77.3061],
              [17.3237, 76.9789],
              [16.9424, 76.7803],
              [17.1921, 76.6968],
              [16.8096, 76.5668],
              [16.3368, 76.6164],
              [15.0402, 77.1348],
              [14.3737, 77.1987],
              [13.9107, 77.5345],
              [14.7742, 77.5488],
              [15.1154, 77.7032],
              [16.87, 77.8113],
              [16.6829, 77.866],
              [14.4578, 77.758],
              [13.7188, 77.7679],
              [13.5891, 78.0559],
              [15.0347, 78.1166],
              [15.7951, 78.3429],
              [15.2093, 78.6562],
              [14.3836, 78.5009],
              [14.2819, 78.3051],
              [13.856, 78.2129],
              [12.9113, 78.247],
              [12.8581, 78.3597],
              [11.6432, 78.7473],
              [11.8566, 78.8556],
              [11.2793, 79.0996],
              [10.708, 79.5614],
              [11.8223, 79.845],
              [12.7676, 79.7733],
              [13.797, 79.8819],
              [14.5486, 79.8115],
              [15.2624, 79.6188],
              [15.4973, 79.33],
              [15.9595, 79.3096],
              [15.6516, 79.8503],
              [16.0217, 80.0154],
              [16.8201, 79.8758],
            ],
          ],
          [
            [
              [23.1424, 80.3812],
              [23.055, 80.2484],
              [24.376, 80.3323],
              [25.9397, 80.1855],
              [27.2391, 80.0973],
              [26.9196, 79.8425],
              [25.826, 79.626],
              [25.6457, 79.3977],
              [24.2881, 79.3099],
              [24.0531, 79.1889],
              [22.9256, 79.2228],
              [22.4167, 79.4097],
              [20.8169, 79.3781],
              [19.6333, 79.6113],
              [20.4756, 79.6864],
              [21.8504, 79.7069],
              [21.8877, 79.8236],
              [18.7839, 79.7231],
              [18.1467, 79.9164],
              [18.3755, 80.039],
              [18.0078, 80.1904],
              [19.1052, 80.258],
              [19.8023, 80.493],
              [20.8826, 80.2112],
              [22.3737, 80.111],
              [22.5642, 80.3109],
              [23.1424, 80.3812],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Norway",
        NAME_LONG: "Norway",
        ADM0_A3: "NOR",
        ISO_A2: "NO",
        ISO_A3: "NOR",
        WB_A2: "NO",
        WB_A3: "NOR",
      },
      id: 169,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [88.1182, 27.8609],
            [88.1597, 27.7741],
            [88.0233, 27.4949],
            [88.0351, 27.3226],
            [87.9707, 27.1027],
            [88.0769, 26.9918],
            [88.1691, 26.744],
            [88.0742, 26.4539],
            [87.8707, 26.4607],
            [87.587, 26.378],
            [87.3842, 26.4186],
            [87.3262, 26.3533],
            [87.0833, 26.4321],
            [87.0413, 26.5802],
            [86.7136, 26.4146],
            [86.2844, 26.612],
            [86.0114, 26.6544],
            [85.8216, 26.5717],
            [85.7129, 26.6532],
            [85.5986, 26.8544],
            [85.2873, 26.737],
            [85.162, 26.851],
            [85.044, 26.8437],
            [84.8019, 27.0138],
            [84.6402, 27.0284],
            [84.6577, 27.2034],
            [84.577, 27.329],
            [84.2894, 27.3761],
            [84.0796, 27.5095],
            [83.8341, 27.434],
            [83.361, 27.4622],
            [83.3049, 27.3319],
            [83.1328, 27.4442],
            [82.7521, 27.495],
            [82.6521, 27.7041],
            [82.4019, 27.6772],
            [82.0516, 27.9052],
            [81.8557, 27.8509],
            [81.6889, 27.9632],
            [81.3071, 28.1238],
            [81.1463, 28.3722],
            [81.0004, 28.397],
            [80.6483, 28.6198],
            [80.4975, 28.6701],
            [80.3498, 28.6202],
            [80.0364, 28.837],
            [80.1326, 29.1102],
            [80.2787, 29.2681],
            [80.2137, 29.4169],
            [80.3852, 29.6048],
            [80.369, 29.7579],
            [80.5713, 29.9469],
            [80.996, 30.197],
            [81.0978, 30.0169],
            [81.1945, 30.0045],
            [81.5916, 30.4143],
            [81.779, 30.358],
            [82.0888, 30.3301],
            [82.1445, 30.0694],
            [82.5418, 29.9232],
            [82.8366, 29.6598],
            [83.1621, 29.6252],
            [83.328, 29.485],
            [83.5171, 29.1917],
            [83.9456, 29.2918],
            [84.0991, 29.247],
            [84.2314, 29.026],
            [84.2351, 28.9],
            [84.6778, 28.6044],
            [84.9187, 28.5357],
            [85.0549, 28.6387],
            [85.168, 28.5832],
            [85.0802, 28.3188],
            [85.2596, 28.2669],
            [85.6751, 28.3064],
            [85.8483, 28.1589],
            [85.9803, 27.8852],
            [86.203, 28.0026],
            [86.4388, 27.9109],
            [86.5442, 28.093],
            [86.662, 28.1068],
            [86.8931, 27.9542],
            [87.0305, 27.9381],
            [87.1558, 27.8258],
            [87.3868, 27.8044],
            [87.7564, 27.8204],
            [87.8259, 27.9066],
            [88.1182, 27.8609],
          ],
        ],
      },
      properties: {
        NAME: "Nepal",
        NAME_LONG: "Nepal",
        ADM0_A3: "NPL",
        ISO_A2: "NP",
        ISO_A3: "NPL",
        WB_A2: "NP",
        WB_A3: "NPL",
      },
      id: 170,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [167.9675, -46.6998],
              [168.1792, -46.8644],
              [168.1921, -47.0989],
              [167.9019, -47.1884],
              [167.6458, -47.2052],
              [167.6482, -47.048],
              [167.8166, -46.8974],
              [167.7874, -46.6972],
              [167.9675, -46.6998],
            ],
          ],
          [
            [
              [172.9045, -40.5085],
              [172.7578, -40.5177],
              [172.6891, -40.7341],
              [172.8565, -40.8437],
              [173.065, -40.8867],
              [173.0309, -41.1491],
              [173.2127, -41.3019],
              [173.5711, -41.0681],
              [174.2114, -40.9996],
              [174.2604, -41.1183],
              [174.0555, -41.4246],
              [174.2259, -41.7234],
              [174.1979, -41.8761],
              [174.0041, -42.0312],
              [173.8909, -42.2408],
              [173.5586, -42.4911],
              [173.2873, -42.9539],
              [172.8112, -43.169],
              [172.7297, -43.4148],
              [172.804, -43.611],
              [173.0529, -43.6533],
              [173.1055, -43.8294],
              [172.8767, -43.902],
              [172.4971, -43.7219],
              [172.3656, -43.8684],
              [172.0612, -43.9566],
              [171.5506, -44.1673],
              [171.346, -44.2786],
              [171.196, -44.5668],
              [171.2031, -44.9055],
              [170.9803, -45.155],
              [170.9063, -45.4028],
              [170.6984, -45.6793],
              [170.7873, -45.8631],
              [170.3338, -45.9815],
              [170.2349, -46.154],
              [169.8648, -46.3784],
              [169.849, -46.4675],
              [169.6351, -46.5784],
              [169.036, -46.6819],
              [168.8449, -46.5602],
              [168.5915, -46.6144],
              [168.3657, -46.5402],
              [168.2161, -46.3544],
              [167.8501, -46.3966],
              [167.6979, -46.1887],
              [167.4609, -46.1482],
              [167.4058, -46.2512],
              [166.762, -46.22],
              [166.4694, -45.9835],
              [166.4898, -45.8075],
              [166.7975, -45.7244],
              [166.7025, -45.5424],
              [166.8814, -45.2796],
              [167.2035, -44.95],
              [167.4187, -44.8409],
              [168.3401, -44.1183],
              [168.4103, -44.0279],
              [168.6458, -43.9645],
              [168.7468, -44.0103],
              [169.1921, -43.7587],
              [169.7337, -43.5657],
              [169.8968, -43.3945],
              [170.2388, -43.2379],
              [170.5144, -43.0066],
              [170.7007, -42.9547],
              [171.1228, -42.5924],
              [171.3049, -42.2946],
              [171.3316, -42.1472],
              [171.4888, -41.8303],
              [171.9038, -41.6069],
              [172.0708, -41.4142],
              [172.1144, -41.2509],
              [172.1068, -40.8869],
              [172.6414, -40.5097],
              [172.9045, -40.5085],
            ],
          ],
          [
            [
              [173.0793, -34.4117],
              [172.9075, -34.5414],
              [173.0508, -34.6013],
              [173.1231, -34.8097],
              [173.4783, -34.9904],
              [173.8882, -35.006],
              [174.1375, -35.3191],
              [174.3325, -35.288],
              [174.5695, -35.5943],
              [174.5174, -35.7208],
              [174.5793, -35.8495],
              [174.4969, -35.9876],
              [174.757, -36.2449],
              [174.7229, -36.5937],
              [174.8191, -36.8237],
              [175.241, -36.9423],
              [175.3311, -37.0213],
              [175.3672, -37.2072],
              [175.5957, -37.2131],
              [175.5278, -36.9625],
              [175.5025, -36.6539],
              [175.7857, -36.7828],
              [175.8818, -36.926],
              [175.9972, -37.6318],
              [176.5186, -37.7621],
              [176.7183, -37.8777],
              [177.1455, -38.0235],
              [177.5568, -37.9138],
              [177.7388, -37.6762],
              [178.0315, -37.5496],
              [178.2777, -37.5593],
              [178.5643, -37.7177],
              [178.3516, -38.0017],
              [178.2946, -38.5398],
              [177.964, -38.7614],
              [177.8828, -39.0855],
              [177.9989, -39.1298],
              [177.8685, -39.2902],
              [177.7639, -39.081],
              [177.3874, -39.0774],
              [177.065, -39.1994],
              [176.9063, -39.4475],
              [177.0364, -39.7536],
              [176.8373, -40.1779],
              [176.7036, -40.2902],
              [176.348, -40.6965],
              [176.2204, -40.9333],
              [175.9668, -41.247],
              [175.3303, -41.6098],
              [175.1818, -41.4179],
              [174.8656, -41.4195],
              [174.6094, -41.2919],
              [174.9427, -40.9706],
              [175.1413, -40.6744],
              [175.2349, -40.336],
              [175.155, -40.0854],
              [174.9673, -39.9164],
              [174.5676, -39.8268],
              [174.319, -39.6139],
              [173.9866, -39.5423],
              [173.7844, -39.3932],
              [173.8527, -39.1345],
              [174.2116, -38.9733],
              [174.3667, -38.9757],
              [174.5789, -38.8395],
              [174.7051, -38.1197],
              [174.8504, -37.7649],
              [174.6917, -37.3294],
              [174.7043, -37.176],
              [174.8805, -37.0763],
              [174.8328, -36.9428],
              [174.4889, -37.0146],
              [174.3599, -36.6313],
              [174.4697, -36.5834],
              [174.4414, -36.4065],
              [174.2923, -36.32],
              [174.0798, -36.3995],
              [173.8582, -36.0656],
              [173.1003, -35.22],
              [173.202, -35.0578],
              [173.1436, -34.9284],
              [172.7993, -34.551],
              [172.7417, -34.4312],
              [173.0793, -34.4117],
            ],
          ],
        ],
      },
      properties: {
        NAME: "New Zealand",
        NAME_LONG: "New Zealand",
        ADM0_A3: "NZL",
        ISO_A2: "NZ",
        ISO_A3: "NZL",
        WB_A2: "NZ",
        WB_A3: "NZL",
      },
      id: 172,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [58.6524, 20.1705],
              [58.6429, 20.3561],
              [58.7512, 20.4401],
              [58.8826, 20.6825],
              [58.9539, 20.5199],
              [58.8441, 20.4373],
              [58.7894, 20.2865],
              [58.6524, 20.1705],
            ],
          ],
          [
            [
              [53.0903, 16.6424],
              [52.8016, 17.2674],
              [52.7301, 17.2999],
              [52.4845, 17.8542],
              [51.9786, 18.9956],
              [52.7744, 19.2608],
              [53.63, 19.546],
              [54.9784, 19.9954],
              [55.3029, 20.9715],
              [55.6376, 21.979],
              [55.6267, 22.0238],
              [55.1868, 22.7036],
              [55.1913, 22.9954],
              [55.3753, 23.4693],
              [55.5383, 23.77],
              [55.4998, 23.9706],
              [55.8071, 24.0075],
              [55.9979, 24.0813],
              [55.8074, 24.3408],
              [55.7598, 24.5585],
              [55.8182, 24.6434],
              [55.7887, 24.8552],
              [56.0056, 24.9562],
              [56.0315, 24.7541],
              [56.1183, 24.7354],
              [56.3833, 24.9782],
              [56.6112, 24.4963],
              [56.9395, 24.1159],
              [57.1515, 23.9536],
              [57.9128, 23.7178],
              [58.0431, 23.7246],
              [58.412, 23.6162],
              [58.4909, 23.6566],
              [58.7861, 23.5121],
              [59.0837, 23.0554],
              [59.2075, 22.9704],
              [59.2975, 22.7927],
              [59.5355, 22.5831],
              [59.7764, 22.5414],
              [59.8446, 22.4453],
              [59.7918, 22.1981],
              [59.6453, 21.9258],
              [59.4979, 21.7563],
              [59.3362, 21.4377],
              [59.1663, 21.3628],
              [58.9192, 21.1437],
              [58.793, 20.9274],
              [58.6803, 20.8284],
              [58.5186, 20.4168],
              [58.2243, 20.3964],
              [58.2642, 20.5876],
              [58.1035, 20.5751],
              [57.8561, 20.2606],
              [57.848, 20.1118],
              [57.6989, 19.7544],
              [57.7044, 19.5487],
              [57.7739, 19.4097],
              [57.7507, 19.2681],
              [57.8401, 19.0153],
              [57.7097, 18.944],
              [57.5015, 18.9537],
              [57.2075, 18.91],
              [56.915, 18.8103],
              [56.6597, 18.5954],
              [56.5613, 18.1492],
              [56.3449, 17.9378],
              [56.1885, 17.9571],
              [55.6535, 17.8953],
              [55.4452, 17.841],
              [55.2254, 17.5154],
              [55.3008, 17.4168],
              [55.2547, 17.2287],
              [55.0269, 17.0111],
              [54.8084, 16.9515],
              [54.5921, 17.0385],
              [54.39, 17.0395],
              [54.0234, 16.9853],
              [53.9811, 16.9088],
              [53.7532, 16.8677],
              [53.5827, 16.7499],
              [53.3386, 16.7348],
              [53.0903, 16.6424],
            ],
          ],
          [
            [
              [56.2791, 25.6274],
              [56.1451, 25.6708],
              [56.1835, 26.0145],
              [56.0774, 26.061],
              [56.1818, 26.246],
              [56.3019, 26.2053],
              [56.4004, 25.9905],
              [56.2791, 25.6274],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Oman",
        NAME_LONG: "Oman",
        ADM0_A3: "OMN",
        ISO_A2: "OM",
        ISO_A3: "OMN",
        WB_A2: "OM",
        WB_A3: "OMN",
      },
      id: 173,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [76.7774, 35.6461],
            [77.049, 35.1104],
            [76.9211, 34.9207],
            [76.5532, 34.7258],
            [76.0234, 34.6772],
            [75.7771, 34.5038],
            [75.3481, 34.5572],
            [75.2129, 34.645],
            [75.0178, 34.6297],
            [74.2858, 34.7689],
            [73.9622, 34.6682],
            [73.7749, 34.371],
            [73.9983, 34.1968],
            [73.9458, 34.0098],
            [74.2284, 34.0129],
            [74.2395, 33.901],
            [74.0346, 33.8286],
            [73.9767, 33.6484],
            [74.1416, 33.5494],
            [74.1055, 33.3452],
            [74.0023, 33.1777],
            [74.1539, 33.0402],
            [74.322, 32.972],
            [74.419, 32.7846],
            [74.6497, 32.7231],
            [74.6296, 32.5881],
            [74.725, 32.4608],
            [75.0237, 32.4663],
            [75.359, 32.2617],
            [75.2947, 32.1489],
            [74.9557, 32.0322],
            [74.8286, 32.0254],
            [74.5656, 31.8405],
            [74.4894, 31.7112],
            [74.6119, 31.4402],
            [74.5064, 31.1754],
            [74.601, 31.0566],
            [74.5193, 30.9625],
            [74.3298, 30.8996],
            [74.1637, 30.6649],
            [73.8518, 30.3727],
            [73.9444, 30.1883],
            [73.7397, 30.0485],
            [73.3703, 29.9273],
            [73.2329, 29.5366],
            [72.9015, 29.0226],
            [72.3822, 28.784],
            [72.2565, 28.6456],
            [72.1499, 28.3538],
            [71.8916, 28.0971],
            [71.8609, 27.9502],
            [71.5607, 27.8685],
            [71.3114, 27.8617],
            [70.8316, 27.7015],
            [70.7104, 27.7412],
            [70.6332, 27.9316],
            [70.4562, 28.0398],
            [70.3419, 28.0115],
            [70.0907, 27.7936],
            [70.0169, 27.6006],
            [69.848, 27.4104],
            [69.5756, 27.1884],
            [69.4651, 26.8078],
            [69.5042, 26.7352],
            [69.8156, 26.5803],
            [70.0561, 26.5891],
            [70.1581, 26.5301],
            [70.132, 26.1805],
            [70.0646, 25.9803],
            [70.2646, 25.6973],
            [70.6575, 25.6336],
            [70.6466, 25.4314],
            [70.8599, 25.1394],
            [70.8933, 25.0019],
            [71.0639, 24.6826],
            [70.9548, 24.5845],
            [70.9177, 24.3617],
            [70.7555, 24.2314],
            [70.5602, 24.2873],
            [70.5629, 24.4241],
            [70.1097, 24.3049],
            [69.972, 24.1652],
            [69.6706, 24.1887],
            [69.5631, 24.2768],
            [68.9808, 24.2554],
            [68.8903, 24.3195],
            [68.7259, 24.2892],
            [68.7245, 23.9652],
            [68.4316, 23.9671],
            [68.183, 23.8421],
            [68.1022, 23.7338],
            [67.8645, 23.8658],
            [67.6316, 23.8035],
            [67.4407, 24.0703],
            [67.3003, 24.1863],
            [67.2468, 24.468],
            [67.1496, 24.6351],
            [67.176, 24.7577],
            [66.9651, 24.8436],
            [66.6797, 24.8306],
            [66.7175, 25.2312],
            [66.437, 25.5977],
            [66.2518, 25.4729],
            [65.8584, 25.4175],
            [65.6716, 25.3474],
            [65.3954, 25.3923],
            [65.1553, 25.2961],
            [64.8073, 25.3285],
            [64.6628, 25.2291],
            [64.0667, 25.3463],
            [63.5725, 25.365],
            [63.5005, 25.1937],
            [63.1785, 25.258],
            [63.0278, 25.2204],
            [62.7705, 25.2619],
            [62.4772, 25.2591],
            [62.3659, 25.1794],
            [62.08, 25.1972],
            [61.8811, 25.103],
            [61.5882, 25.2021],
            [61.62, 25.2855],
            [61.6603, 25.7692],
            [61.7601, 25.8376],
            [61.8099, 26.1674],
            [61.8571, 26.2424],
            [62.2434, 26.3506],
            [62.292, 26.5012],
            [62.7536, 26.6442],
            [63.1413, 26.6254],
            [63.1823, 26.8302],
            [63.2649, 26.89],
            [63.2515, 27.219],
            [63.167, 27.2587],
            [62.8006, 27.2252],
            [62.756, 27.3481],
            [62.8147, 27.495],
            [62.7357, 27.995],
            [62.7664, 28.2115],
            [62.5756, 28.229],
            [62.3628, 28.4189],
            [61.8928, 28.5426],
            [61.653, 28.7563],
            [61.4878, 29.0807],
            [61.2792, 29.4066],
            [60.8444, 29.8582],
            [61.6134, 29.6385],
            [62.4775, 29.4078],
            [63.5686, 29.4975],
            [64.0861, 29.3866],
            [64.1726, 29.4843],
            [64.4777, 29.5704],
            [65.0364, 29.5402],
            [66.1956, 29.8353],
            [66.3405, 29.9566],
            [66.2252, 30.0444],
            [66.3009, 30.2256],
            [66.3218, 30.4374],
            [66.268, 30.6014],
            [66.3663, 30.9229],
            [66.55, 30.977],
            [66.697, 31.1958],
            [66.9433, 31.3147],
            [67.0229, 31.2394],
            [67.3462, 31.2078],
            [67.7494, 31.328],
            [67.597, 31.4257],
            [67.8707, 31.6352],
            [68.0466, 31.6884],
            [68.1257, 31.8115],
            [68.3561, 31.7625],
            [68.5275, 31.823],
            [68.6758, 31.7747],
            [68.7773, 31.6186],
            [69.004, 31.6511],
            [69.2988, 31.9377],
            [69.228, 32.4213],
            [69.4291, 32.6674],
            [69.5475, 33.075],
            [69.8809, 33.0892],
            [70.2944, 33.3189],
            [70.155, 33.5066],
            [70.1082, 33.7273],
            [69.9575, 33.7527],
            [69.8411, 33.9418],
            [70.0028, 34.0438],
            [70.522, 33.9387],
            [70.8622, 33.9648],
            [71.0467, 34.0419],
            [71.122, 34.3568],
            [70.9708, 34.4689],
            [71.0806, 34.6729],
            [71.2895, 34.875],
            [71.46, 34.9427],
            [71.6337, 35.2031],
            [71.5298, 35.3009],
            [71.6224, 35.4296],
            [71.5935, 35.5494],
            [71.3416, 35.9473],
            [71.1709, 36.027],
            [71.6291, 36.4595],
            [71.7363, 36.3959],
            [72.2136, 36.7264],
            [72.5652, 36.8206],
            [73.6401, 36.8966],
            [74.0943, 36.8312],
            [74.394, 36.994],
            [74.5424, 37.0217],
            [74.6624, 37.0545],
            [74.9105, 36.9626],
            [75.1001, 36.9941],
            [75.3513, 36.9158],
            [75.4105, 36.7473],
            [75.5705, 36.7667],
            [75.7905, 36.6878],
            [75.9766, 36.4626],
            [76.0144, 36.2304],
            [75.8943, 36.0974],
            [76.0614, 35.9916],
            [76.166, 35.8062],
            [76.5653, 35.8655],
            [76.5708, 35.7513],
            [76.7774, 35.6461],
          ],
        ],
      },
      properties: {
        NAME: "Pakistan",
        NAME_LONG: "Pakistan",
        ADM0_A3: "PAK",
        ISO_A2: "PK",
        ISO_A3: "PAK",
        WB_A2: "PK",
        WB_A3: "PAK",
      },
      id: 174,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.3735, 8.6647],
            [-77.4804, 8.5262],
            [-77.2012, 7.982],
            [-77.3216, 7.881],
            [-77.3397, 7.7072],
            [-77.58, 7.5284],
            [-77.8202, 7.4765],
            [-77.8958, 7.2351],
            [-78.1442, 7.4979],
            [-78.4308, 8.0771],
            [-78.2864, 8.0807],
            [-78.3058, 8.2539],
            [-78.1556, 8.3941],
            [-78.3033, 8.4543],
            [-78.4498, 8.391],
            [-78.5044, 8.634],
            [-78.6125, 8.6697],
            [-78.7576, 8.8293],
            [-79.176, 9.0107],
            [-79.482, 8.9984],
            [-79.7563, 8.7963],
            [-79.7744, 8.5796],
            [-80.225, 8.3011],
            [-80.3807, 8.3104],
            [-80.4702, 8.2206],
            [-80.4561, 8.0362],
            [-80.1438, 7.7528],
            [-79.9947, 7.5034],
            [-80.1355, 7.4246],
            [-80.3576, 7.382],
            [-80.4745, 7.2533],
            [-80.8731, 7.2126],
            [-80.9463, 7.5557],
            [-81.0854, 7.7943],
            [-81.1952, 7.6389],
            [-81.5258, 7.7115],
            [-81.7115, 8.1303],
            [-82.1094, 8.2333],
            [-82.2801, 8.3432],
            [-82.5117, 8.2764],
            [-82.6589, 8.3228],
            [-82.8772, 8.2191],
            [-82.8976, 8.0347],
            [-82.9437, 8.2486],
            [-83.0532, 8.3151],
            [-82.8704, 8.4381],
            [-82.8498, 8.6299],
            [-82.9242, 8.7412],
            [-82.7491, 8.9741],
            [-82.9394, 9.0596],
            [-82.9443, 9.4372],
            [-82.829, 9.6027],
            [-82.6189, 9.4867],
            [-82.5736, 9.5762],
            [-82.3496, 9.402],
            [-82.3876, 9.2861],
            [-82.2555, 9.1826],
            [-82.246, 9.0141],
            [-82.1054, 8.9352],
            [-81.9037, 8.9711],
            [-81.762, 9.0638],
            [-81.5391, 8.8129],
            [-81.3045, 8.7819],
            [-81.0877, 8.8087],
            [-80.8224, 8.8907],
            [-80.6371, 9.0517],
            [-80.1008, 9.2223],
            [-79.909, 9.3084],
            [-79.551, 9.6293],
            [-79.1938, 9.5396],
            [-79.0581, 9.4579],
            [-78.4377, 9.4044],
            [-78.0246, 9.2265],
            [-77.5741, 8.7928],
            [-77.477, 8.6699],
            [-77.3735, 8.6647],
          ],
        ],
      },
      properties: {
        NAME: "Panama",
        NAME_LONG: "Panama",
        ADM0_A3: "PAN",
        ISO_A2: "PA",
        ISO_A3: "PAN",
        WB_A2: "PA",
        WB_A3: "PAN",
      },
      id: 175,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-69.9649, -4.2365],
            [-70.2313, -4.3037],
            [-70.3111, -4.1709],
            [-70.4299, -4.1342],
            [-70.5853, -4.1945],
            [-70.8322, -4.1794],
            [-70.9687, -4.3853],
            [-71.7743, -4.4815],
            [-72.0711, -4.6051],
            [-72.2511, -4.7588],
            [-72.6273, -5.0224],
            [-72.9179, -5.1321],
            [-72.8967, -5.2716],
            [-72.9735, -5.4661],
            [-73.0014, -5.7088],
            [-73.14, -5.8785],
            [-73.2348, -6.0776],
            [-73.1318, -6.4353],
            [-73.199, -6.5456],
            [-73.3707, -6.5839],
            [-73.6851, -6.8119],
            [-73.7655, -6.9042],
            [-73.8123, -7.1029],
            [-73.7345, -7.2251],
            [-73.7608, -7.3374],
            [-73.9794, -7.3745],
            [-73.9694, -7.5847],
            [-73.7122, -7.7882],
            [-73.7788, -7.9541],
            [-73.668, -8.0138],
            [-73.5265, -8.3724],
            [-73.3564, -8.4798],
            [-73.3506, -8.5983],
            [-73.1746, -8.7207],
            [-72.9761, -8.9854],
            [-72.9594, -9.0856],
            [-73.215, -9.409],
            [-72.8133, -9.4109],
            [-72.4302, -9.4822],
            [-72.2617, -9.6674],
            [-72.1604, -9.8877],
            [-72.1957, -10.0056],
            [-71.3914, -10.0068],
            [-71.1965, -9.9399],
            [-71.0154, -9.8175],
            [-70.8755, -9.6605],
            [-70.6808, -9.5277],
            [-70.5434, -9.727],
            [-70.6384, -9.8518],
            [-70.6413, -11.0108],
            [-70.5341, -10.9381],
            [-70.4373, -11.0367],
            [-70.2894, -11.0647],
            [-69.9561, -10.9193],
            [-69.5776, -10.9523],
            [-69.1316, -11.726],
            [-68.6843, -12.5025],
            [-68.981, -12.8679],
            [-68.9628, -13.2836],
            [-69.016, -13.7531],
            [-68.983, -13.9722],
            [-68.8837, -14.2115],
            [-69.0101, -14.2459],
            [-68.9903, -14.379],
            [-69.2475, -14.5937],
            [-69.2676, -14.7507],
            [-69.3614, -14.788],
            [-69.3907, -14.9644],
            [-69.1481, -15.2333],
            [-69.2912, -15.3508],
            [-69.4243, -15.6563],
            [-69.2196, -16.1528],
            [-69.1447, -16.2235],
            [-68.982, -16.21],
            [-68.8436, -16.302],
            [-69.0017, -16.4228],
            [-69.0371, -16.6702],
            [-69.1917, -16.743],
            [-69.4274, -17.0869],
            [-69.6228, -17.1856],
            [-69.6665, -17.2883],
            [-69.5226, -17.3691],
            [-69.5101, -17.5066],
            [-69.6839, -17.656],
            [-69.8576, -17.7342],
            [-69.7831, -17.9816],
            [-69.9703, -18.2506],
            [-70.1342, -18.3189],
            [-70.3947, -18.3377],
            [-70.6833, -18.1617],
            [-70.9146, -17.9465],
            [-71.0866, -17.8691],
            [-71.3596, -17.6331],
            [-71.4003, -17.4003],
            [-71.5145, -17.2846],
            [-71.8076, -17.1913],
            [-71.9486, -17.0735],
            [-72.2934, -16.8806],
            [-72.3622, -16.7613],
            [-72.6924, -16.6607],
            [-73.2801, -16.3884],
            [-73.4203, -16.2931],
            [-73.7003, -16.2207],
            [-74.0328, -16.0123],
            [-74.0466, -15.9601],
            [-74.4417, -15.798],
            [-74.4631, -15.7267],
            [-74.8476, -15.5663],
            [-75.1608, -15.3933],
            [-75.2411, -15.2053],
            [-75.3618, -15.1448],
            [-75.5506, -14.8849],
            [-75.9307, -14.6493],
            [-75.9806, -14.4611],
            [-76.0922, -14.3731],
            [-76.1492, -14.2239],
            [-76.2821, -14.1409],
            [-76.2774, -13.9451],
            [-76.1813, -13.5395],
            [-76.2211, -13.3576],
            [-76.4833, -13.0363],
            [-76.5162, -12.8699],
            [-76.8118, -12.4827],
            [-76.8536, -12.3026],
            [-77.0383, -12.2044],
            [-77.1749, -12.0719],
            [-77.1459, -11.9321],
            [-77.2255, -11.6272],
            [-77.3205, -11.4937],
            [-77.6473, -11.2923],
            [-77.5947, -11.1651],
            [-77.6705, -10.9275],
            [-78.0558, -10.3475],
            [-78.2346, -9.9525],
            [-78.2294, -9.7938],
            [-78.3699, -9.6154],
            [-78.484, -9.3303],
            [-78.4974, -9.1895],
            [-78.7643, -8.7751],
            [-78.7593, -8.6083],
            [-78.9403, -8.4355],
            [-78.9857, -8.2174],
            [-79.2997, -7.9387],
            [-79.4659, -7.7159],
            [-79.5825, -7.4636],
            [-79.6128, -7.2664],
            [-79.7082, -7.1068],
            [-79.9315, -6.8835],
            [-79.9647, -6.7874],
            [-80.2981, -6.5444],
            [-80.7905, -6.2997],
            [-81.1015, -6.0721],
            [-81.1502, -5.8831],
            [-80.9399, -5.8508],
            [-80.8562, -5.6505],
            [-80.9318, -5.4638],
            [-81.1985, -5.2081],
            [-81.0634, -5.022],
            [-81.3376, -4.6783],
            [-81.3289, -4.4767],
            [-81.2528, -4.2387],
            [-81.0733, -4.0968],
            [-80.8678, -3.8727],
            [-80.7717, -3.7101],
            [-80.5362, -3.5053],
            [-80.3407, -3.3935],
            [-80.2164, -3.6316],
            [-80.1653, -3.89],
            [-80.3046, -4.0052],
            [-80.4811, -3.9988],
            [-80.4885, -4.396],
            [-80.4085, -4.4775],
            [-80.0797, -4.309],
            [-79.7957, -4.4847],
            [-79.658, -4.4387],
            [-79.5072, -4.5316],
            [-79.4506, -4.8102],
            [-79.3032, -4.9612],
            [-79.0093, -4.9601],
            [-78.9002, -4.8437],
            [-78.8531, -4.6523],
            [-78.6727, -4.551],
            [-78.6824, -4.3269],
            [-78.5018, -3.9537],
            [-78.3629, -3.4887],
            [-78.1964, -3.3637],
            [-77.849, -2.9806],
            [-76.6846, -2.5736],
            [-76.0912, -2.1289],
            [-75.56, -1.5026],
            [-75.4126, -0.9239],
            [-75.2273, -0.9698],
            [-75.297, -0.747],
            [-75.272, -0.5257],
            [-75.4342, -0.403],
            [-75.4553, -0.1612],
            [-75.2835, -0.107],
            [-75.1417, -0.0435],
            [-74.9335, -0.2094],
            [-74.8247, -0.1705],
            [-74.4127, -0.5731],
            [-74.2891, -0.943],
            [-73.9199, -1.1138],
            [-73.8808, -1.191],
            [-73.6366, -1.2552],
            [-73.4972, -1.478],
            [-73.531, -1.6737],
            [-73.3403, -1.7922],
            [-73.1863, -1.7996],
            [-73.1111, -2.0734],
            [-73.1953, -2.1872],
            [-72.9433, -2.419],
            [-72.6443, -2.3341],
            [-72.3966, -2.4465],
            [-71.9663, -2.3285],
            [-71.7679, -2.1422],
            [-71.4565, -2.2551],
            [-71.387, -2.3689],
            [-71.2199, -2.3415],
            [-70.9927, -2.1974],
            [-70.8742, -2.2296],
            [-70.457, -2.4927],
            [-70.3575, -2.4869],
            [-70.2361, -2.6251],
            [-70.0506, -2.7151],
            [-70.7341, -3.782],
            [-70.5443, -3.8652],
            [-70.3111, -3.8293],
            [-70.1884, -4.029],
            [-69.9649, -4.2365],
          ],
        ],
      },
      properties: {
        NAME: "Peru",
        NAME_LONG: "Peru",
        ADM0_A3: "PER",
        ISO_A2: "PE",
        ISO_A3: "PER",
        WB_A2: "PE",
        WB_A3: "PER",
      },
      id: 177,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [121.0422, 6.0971],
              [121.4212, 5.9871],
              [120.9341, 5.8857],
              [120.9055, 6.015],
              [121.0422, 6.0971],
            ],
          ],
          [
            [
              [122.0576, 6.4139],
              [121.8801, 6.5032],
              [121.8392, 6.6689],
              [122.0667, 6.7407],
              [122.2354, 6.5712],
              [122.1886, 6.4511],
              [122.0576, 6.4139],
            ],
          ],
          [
            [
              [125.6436, 9.6113],
              [125.9341, 9.4923],
              [126.179, 9.093],
              [126.3128, 8.9562],
              [126.3001, 8.7683],
              [126.1384, 8.654],
              [126.2205, 8.5555],
              [126.3569, 8.5344],
              [126.3884, 8.2929],
              [126.4631, 8.2299],
              [126.4061, 7.8332],
              [126.5829, 7.7194],
              [126.6123, 7.2579],
              [126.4764, 7.0662],
              [126.3495, 7.0001],
              [126.2546, 6.7667],
              [126.2269, 6.4689],
              [126.0951, 6.5141],
              [126.0791, 6.8425],
              [125.9768, 6.9396],
              [125.8069, 7.3511],
              [125.6569, 7.237],
              [125.6581, 7.1291],
              [125.5099, 7.0156],
              [125.3889, 6.7683],
              [125.3975, 6.5985],
              [125.5736, 6.5169],
              [125.7066, 6.1497],
              [125.6673, 5.9501],
              [125.3875, 5.5738],
              [125.1812, 5.7983],
              [125.264, 5.9499],
              [125.169, 6.112],
              [125.0691, 5.8617],
              [124.9387, 5.864],
              [124.5962, 5.9989],
              [124.2458, 6.1865],
              [124.0549, 6.4377],
              [124.0523, 6.6694],
              [123.9655, 6.8213],
              [124.0295, 7.1151],
              [124.1838, 7.2264],
              [124.2468, 7.4041],
              [124.0652, 7.6027],
              [123.7942, 7.7105],
              [123.5869, 7.8393],
              [123.4447, 7.8013],
              [123.4857, 7.6636],
              [123.2258, 7.5156],
              [123.1073, 7.7319],
              [122.9956, 7.4673],
              [122.8307, 7.5498],
              [122.8147, 7.7434],
              [122.5967, 7.7614],
              [122.4439, 7.6086],
              [122.3791, 7.3916],
              [122.2659, 7.2491],
              [122.1545, 6.9281],
              [122.0379, 6.9174],
              [121.8989, 7.0572],
              [121.9334, 7.2179],
              [122.0469, 7.3011],
              [122.1289, 7.815],
              [122.2922, 8.0099],
              [122.6497, 8.116],
              [122.9246, 8.1501],
              [123.0554, 8.505],
              [123.3311, 8.5466],
              [123.4778, 8.6953],
              [123.7588, 8.6075],
              [123.8661, 8.3643],
              [123.8737, 8.1052],
              [124.0363, 8.1898],
              [124.2323, 8.2129],
              [124.3218, 8.5146],
              [124.4058, 8.6079],
              [124.641, 8.476],
              [124.7532, 8.5002],
              [124.7808, 8.9491],
              [124.9153, 8.9941],
              [125.1157, 8.8306],
              [125.1792, 8.998],
              [125.4622, 8.9852],
              [125.542, 9.1892],
              [125.3987, 9.6811],
              [125.569, 9.7567],
              [125.6436, 9.6113],
            ],
          ],
          [
            [
              [124.3496, 10.151],
              [124.5886, 10.0239],
              [124.5498, 9.8616],
              [124.4105, 9.6584],
              [124.2942, 9.6068],
              [123.9375, 9.6175],
              [123.8079, 9.74],
              [123.8861, 9.9149],
              [124.0508, 9.9793],
              [124.1846, 10.1445],
              [124.3496, 10.151],
            ],
          ],
          [
            [
              [125.6848, 10.3999],
              [125.6581, 10.2617],
              [125.6815, 9.9581],
              [125.4971, 10.1035],
              [125.5562, 10.3708],
              [125.6848, 10.3999],
            ],
          ],
          [
            [
              [122.7215, 10.5852],
              [122.6345, 10.4442],
              [122.5286, 10.5912],
              [122.6702, 10.762],
              [122.7215, 10.5852],
            ],
          ],
          [
            [
              [123.2698, 10.9774],
              [123.5218, 10.9199],
              [123.5755, 10.8256],
              [123.4857, 10.5491],
              [123.3897, 10.4372],
              [123.2904, 10.0936],
              [123.1567, 9.8676],
              [123.1976, 9.4871],
              [123.3211, 9.3432],
              [123.2302, 9.1076],
              [123.0332, 9.0353],
              [122.9336, 9.1215],
              [122.8694, 9.3227],
              [122.5995, 9.4327],
              [122.4966, 9.5617],
              [122.3952, 9.8366],
              [122.4746, 9.9744],
              [122.7078, 9.9864],
              [122.8789, 10.1181],
              [122.8239, 10.5329],
              [122.9717, 10.7211],
              [122.9663, 10.8764],
              [123.2698, 10.9774],
            ],
          ],
          [
            [
              [123.9995, 11.0773],
              [124.0669, 10.8891],
              [124.0208, 10.7477],
              [124.04, 10.4844],
              [123.9729, 10.3266],
              [123.8081, 10.2366],
              [123.6433, 10.039],
              [123.6433, 9.9601],
              [123.4883, 9.5665],
              [123.3143, 9.5076],
              [123.3833, 9.9427],
              [123.7068, 10.462],
              [123.8493, 10.7526],
              [123.9177, 11.029],
              [123.9995, 11.0773],
            ],
          ],
          [
            [
              [119.523, 11.3399],
              [119.569, 11.2617],
              [119.5046, 10.9709],
              [119.5934, 10.8371],
              [119.5908, 10.6724],
              [119.71, 10.4945],
              [119.5632, 10.3735],
              [119.356, 10.3288],
              [119.1963, 10.0563],
              [118.8748, 9.9719],
              [118.7505, 9.8875],
              [118.7254, 9.6313],
              [118.4979, 9.3137],
              [118.3358, 9.1793],
              [118.1321, 9.1267],
              [118.0137, 8.8876],
              [117.7737, 8.7031],
              [117.5656, 8.6611],
              [117.5129, 8.5068],
              [117.3186, 8.4518],
              [117.2915, 8.6408],
              [117.4504, 8.7912],
              [117.6555, 9.0579],
              [117.7681, 9.0864],
              [117.9045, 9.2612],
              [118.0032, 9.2333],
              [118.127, 9.3378],
              [118.3455, 9.6362],
              [118.5305, 9.7742],
              [118.8223, 10.1945],
              [118.9497, 10.2202],
              [119.072, 10.3915],
              [119.2781, 10.5082],
              [119.345, 10.7236],
              [119.2234, 10.8508],
              [119.4061, 11.0495],
              [119.4206, 11.2976],
              [119.523, 11.3399],
            ],
          ],
          [
            [
              [124.4431, 11.4469],
              [124.6299, 11.3044],
              [124.8457, 11.433],
              [124.9841, 11.3813],
              [125.0531, 10.9682],
              [125.0061, 10.7973],
              [125.1941, 10.603],
              [125.2666, 10.3684],
              [124.8828, 10.135],
              [124.7804, 10.1519],
              [124.7389, 10.3955],
              [124.7981, 10.6591],
              [124.7788, 10.8214],
              [124.6223, 11.002],
              [124.5073, 10.8744],
              [124.3968, 10.9538],
              [124.4043, 11.3027],
              [124.4431, 11.4469],
            ],
          ],
          [
            [
              [124.4309, 11.6951],
              [124.556, 11.6602],
              [124.6091, 11.4866],
              [124.4588, 11.4945],
              [124.4309, 11.6951],
            ],
          ],
          [
            [
              [122.3001, 11.7619],
              [122.388, 11.7274],
              [122.6223, 11.5087],
              [122.855, 11.5913],
              [122.9007, 11.4379],
              [123.1178, 11.5482],
              [123.1748, 11.4675],
              [123.0149, 11.0916],
              [122.78, 10.9569],
              [122.7615, 10.8107],
              [122.6306, 10.7744],
              [122.5899, 10.6987],
              [122.2266, 10.6422],
              [122.0396, 10.4666],
              [121.937, 10.7757],
              [122.0451, 11.0174],
              [122.1072, 11.6579],
              [122.0845, 11.7247],
              [121.8648, 11.7626],
              [121.9637, 11.9339],
              [122.1179, 11.8236],
              [122.3001, 11.7619],
            ],
          ],
          [
            [
              [119.9639, 11.9401],
              [120.0526, 11.8712],
              [120.0142, 11.6821],
              [119.868, 11.9066],
              [119.9639, 11.9401],
            ],
          ],
          [
            [
              [119.9346, 12.2851],
              [120.1961, 12.112],
              [120.1346, 11.9748],
              [119.9841, 12.0257],
              [119.8672, 12.2318],
              [119.9346, 12.2851],
            ],
          ],
          [
            [
              [122.6253, 12.281],
              [122.4319, 12.4097],
              [122.4811, 12.4934],
              [122.6492, 12.4732],
              [122.6253, 12.281],
            ],
          ],
          [
            [
              [124.919, 12.5622],
              [125.1663, 12.5781],
              [125.3496, 12.3994],
              [125.3607, 12.2851],
              [125.5005, 12.2486],
              [125.452, 11.9641],
              [125.5005, 11.7011],
              [125.4709, 11.6026],
              [125.5586, 11.4248],
              [125.5662, 11.2681],
              [125.7074, 11.1774],
              [125.4402, 11.0976],
              [125.2141, 11.1315],
              [125.0029, 11.4197],
              [124.8283, 11.5087],
              [125.0002, 11.6248],
              [124.6799, 12.0297],
              [124.4932, 12.0978],
              [124.3908, 12.2219],
              [124.2762, 12.5333],
              [124.6792, 12.5159],
              [124.919, 12.5622],
            ],
          ],
          [
            [
              [123.3553, 12.5477],
              [123.5471, 12.4609],
              [123.7673, 12.2141],
              [123.8823, 12.2128],
              [124.0403, 11.9881],
              [123.9241, 11.8596],
              [123.7117, 11.9544],
              [123.5397, 12.194],
              [123.4242, 12.2008],
              [123.1814, 11.9309],
              [123.2178, 12.2654],
              [123.3553, 12.5477],
            ],
          ],
          [
            [
              [122.1563, 12.6321],
              [122.0808, 12.3155],
              [121.9932, 12.4418],
              [122.0191, 12.6117],
              [122.1563, 12.6321],
            ],
          ],
          [
            [
              [123.2014, 12.9064],
              [122.9507, 13.0437],
              [123.0398, 13.1281],
              [123.2014, 12.9064],
            ],
          ],
          [
            [
              [120.9787, 13.5265],
              [121.0427, 13.413],
              [121.2178, 13.4041],
              [121.4305, 13.2243],
              [121.4895, 13.0368],
              [121.4856, 12.7685],
              [121.5464, 12.6441],
              [121.4378, 12.5216],
              [121.4253, 12.3842],
              [121.273, 12.269],
              [121.1317, 12.2418],
              [120.9164, 12.5124],
              [120.9124, 12.6347],
              [120.7979, 12.7294],
              [120.7583, 13.0024],
              [120.6423, 13.1833],
              [120.48, 13.3015],
              [120.44, 13.4272],
              [120.5654, 13.5131],
              [120.7546, 13.4667],
              [120.9787, 13.5265],
            ],
          ],
          [
            [
              [121.8875, 13.5548],
              [122.1152, 13.465],
              [122.1203, 13.3566],
              [121.9932, 13.2016],
              [121.8172, 13.3419],
              [121.8875, 13.5548],
            ],
          ],
          [
            [
              [124.2359, 14.0681],
              [124.2774, 13.9555],
              [124.418, 13.873],
              [124.39, 13.7116],
              [124.1702, 13.5298],
              [124.0261, 13.6696],
              [124.1218, 13.7798],
              [124.1245, 14.0494],
              [124.2359, 14.0681],
            ],
          ],
          [
            [
              [120.9092, 18.5672],
              [121.1068, 18.6427],
              [121.6133, 18.3593],
              [121.9746, 18.286],
              [122.2202, 18.5224],
              [122.3171, 18.3774],
              [122.307, 18.2513],
              [122.18, 18.1208],
              [122.1401, 17.7983],
              [122.1566, 17.6193],
              [122.2511, 17.3602],
              [122.4354, 17.2924],
              [122.4104, 17.1755],
              [122.5291, 17.0905],
              [122.3455, 16.6425],
              [122.2053, 16.4239],
              [122.2039, 16.2638],
              [121.7523, 16.0769],
              [121.5778, 15.9321],
              [121.6366, 15.7225],
              [121.3792, 15.3082],
              [121.4876, 15.1633],
              [121.5945, 14.8592],
              [121.6107, 14.6126],
              [121.7316, 14.1882],
              [121.9363, 13.9952],
              [122.2319, 13.9064],
              [122.2908, 14.0231],
              [122.1702, 14.1396],
              [122.471, 14.3459],
              [122.7527, 14.3322],
              [123.0122, 14.1057],
              [123.1082, 13.7221],
              [123.315, 13.8205],
              [123.2181, 13.9927],
              [123.3296, 14.0703],
              [123.4778, 13.9505],
              [123.9378, 13.784],
              [123.7974, 13.688],
              [123.5881, 13.7289],
              [123.5285, 13.6268],
              [123.7946, 13.3084],
              [123.7495, 13.1547],
              [123.9792, 13.0983],
              [124.0816, 13.0095],
              [124.1431, 12.7973],
              [124.0952, 12.5571],
              [123.8786, 12.6452],
              [123.8191, 12.8319],
              [123.5984, 12.8966],
              [123.4413, 13.0369],
              [123.3101, 13.0124],
              [123.3008, 13.2355],
              [123.1688, 13.4492],
              [122.972, 13.5275],
              [122.8147, 13.6538],
              [122.739, 13.8183],
              [122.5159, 13.9344],
              [122.4976, 13.652],
              [122.6218, 13.5527],
              [122.6907, 13.2287],
              [122.5051, 13.2392],
              [122.5011, 13.3681],
              [122.319, 13.5922],
              [122.2075, 13.6143],
              [122.1184, 13.7521],
              [121.7249, 13.9691],
              [121.4846, 13.8518],
              [121.4641, 13.6948],
              [121.2788, 13.595],
              [120.9092, 13.8046],
              [120.895, 13.9064],
              [120.7459, 13.9338],
              [120.6201, 13.8466],
              [120.5873, 14.2003],
              [120.8779, 14.4589],
              [120.9704, 14.5019],
              [120.9365, 14.6674],
              [120.8301, 14.7636],
              [120.6421, 14.787],
              [120.5393, 14.7028],
              [120.5979, 14.5056],
              [120.4021, 14.4558],
              [120.3674, 14.6208],
              [120.1873, 14.775],
              [120.0901, 14.7943],
              [120.0051, 15.2694],
              [119.8882, 15.429],
              [119.9639, 15.5143],
              [119.8789, 15.9147],
              [119.7559, 15.9446],
              [119.7557, 16.2034],
              [119.9019, 16.2508],
              [120.0912, 16.1535],
              [120.1853, 16.0334],
              [120.3338, 16.059],
              [120.4021, 16.1655],
              [120.3363, 16.2922],
              [120.2761, 16.6119],
              [120.3122, 16.7778],
              [120.4273, 16.9619],
              [120.4031, 17.2216],
              [120.4417, 17.368],
              [120.3338, 17.5564],
              [120.4701, 18.091],
              [120.5862, 18.3645],
              [120.6111, 18.5386],
              [120.9092, 18.5672],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Philippines",
        NAME_LONG: "Philippines",
        ADM0_A3: "PHL",
        ISO_A2: "PH",
        ISO_A3: "PHL",
        WB_A2: "PH",
        WB_A3: "PHL",
      },
      id: 179,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [153.2578, -11.3534],
              [153.6956, -11.5213],
              [153.4356, -11.5784],
              [153.2717, -11.4533],
              [153.2578, -11.3534],
            ],
          ],
          [
            [
              [151.0481, -9.9352],
              [151.3006, -9.9677],
              [151.2119, -10.1867],
              [150.958, -10.1049],
              [150.8823, -9.903],
              [151.0481, -9.9352],
            ],
          ],
          [
            [
              [150.6561, -9.4311],
              [150.8884, -9.5214],
              [150.8418, -9.6974],
              [150.5493, -9.6232],
              [150.4269, -9.4187],
              [150.5055, -9.3377],
              [150.6561, -9.4311],
            ],
          ],
          [
            [
              [150.3656, -9.364],
              [150.3352, -9.4713],
              [150.1082, -9.361],
              [150.2145, -9.2058],
              [150.3656, -9.364],
            ],
          ],
          [
            [
              [152.4716, -8.9495],
              [152.5179, -8.9964],
              [152.7942, -9.0035],
              [152.9512, -9.0945],
              [152.8116, -9.2547],
              [152.5981, -9.0686],
              [152.5063, -9.0388],
              [152.4716, -8.9495],
            ],
          ],
          [
            [
              [143.6423, -8.7262],
              [143.4123, -8.5956],
              [143.4749, -8.5253],
              [143.6423, -8.7262],
            ],
          ],
          [
            [
              [143.3654, -8.3676],
              [143.5737, -8.3737],
              [143.5301, -8.4855],
              [143.3654, -8.3676],
            ],
          ],
          [
            [
              [147.8286, -5.4881],
              [148.0492, -5.6061],
              [148.0246, -5.842],
              [147.8958, -5.7707],
              [147.7691, -5.6117],
              [147.8286, -5.4881],
            ],
          ],
          [
            [
              [154.8108, -5.4836],
              [155.0693, -5.5522],
              [155.1921, -5.7659],
              [155.4038, -6.006],
              [155.4522, -6.1371],
              [155.7139, -6.2792],
              [155.9168, -6.5175],
              [155.9675, -6.7271],
              [155.7178, -6.8728],
              [155.5236, -6.8391],
              [155.3609, -6.7459],
              [155.1873, -6.5545],
              [155.2346, -6.3634],
              [154.9787, -6.193],
              [154.7559, -5.9478],
              [154.7002, -5.7669],
              [154.8108, -5.4836],
            ],
          ],
          [
            [
              [147.2233, -5.4242],
              [147.1272, -5.4433],
              [147.0104, -5.3349],
              [147.134, -5.1909],
              [147.209, -5.2558],
              [147.2233, -5.4242],
            ],
          ],
          [
            [
              [152.2334, -4.1904],
              [152.1779, -4.2968],
              [152.4146, -4.3604],
              [152.3652, -4.4721],
              [152.4116, -4.6838],
              [152.2918, -4.9355],
              [152.1367, -4.9991],
              [151.9927, -4.9718],
              [151.988, -5.164],
              [152.1231, -5.2908],
              [152.0989, -5.4557],
              [151.786, -5.5849],
              [151.7394, -5.5341],
              [151.479, -5.5299],
              [151.4651, -5.685],
              [151.2085, -5.9428],
              [150.5881, -6.1784],
              [150.4651, -6.274],
              [150.273, -6.295],
              [150.054, -6.2577],
              [149.6294, -6.2992],
              [149.464, -6.1026],
              [149.3042, -6.0587],
              [149.1419, -6.1442],
              [149.0121, -6.0276],
              [148.8539, -5.9798],
              [148.772, -5.8733],
              [148.3862, -5.7751],
              [148.3247, -5.6464],
              [148.4299, -5.4448],
              [148.5564, -5.54],
              [148.6519, -5.4809],
              [148.8328, -5.5339],
              [148.978, -5.4653],
              [149.2, -5.5652],
              [149.3905, -5.5873],
              [149.6149, -5.5136],
              [149.7184, -5.5689],
              [149.8728, -5.5276],
              [150.0581, -5.3429],
              [150.1423, -5.5341],
              [150.3069, -5.5615],
              [150.4234, -5.4566],
              [150.719, -5.5341],
              [151.0222, -5.3903],
              [151.0829, -5.1638],
              [151.388, -4.9145],
              [151.6125, -4.9656],
              [151.6956, -4.8188],
              [151.6091, -4.4518],
              [151.5073, -4.2292],
              [151.5403, -4.1767],
              [151.8171, -4.214],
              [151.9647, -4.3312],
              [152.2334, -4.1904],
            ],
          ],
          [
            [
              [140.977, -9.1061],
              [140.9769, -7.9621],
              [140.9772, -6.8966],
              [140.8492, -6.7028],
              [140.9769, -6.3351],
              [140.9769, -5.6212],
              [140.9758, -4.5959],
              [140.9735, -3.8],
              [140.9745, -2.6005],
              [141.2759, -2.6423],
              [141.9055, -2.9672],
              [142, -2.956],
              [142.1819, -3.0802],
              [143.0293, -3.3556],
              [143.4779, -3.4206],
              [143.6187, -3.5449],
              [143.818, -3.6217],
              [143.9959, -3.7931],
              [144.2036, -3.8378],
              [144.5134, -3.8176],
              [144.5513, -3.9684],
              [144.8678, -4.0992],
              [145.051, -4.3419],
              [145.3457, -4.3902],
              [145.6902, -4.7682],
              [145.8119, -4.861],
              [145.8108, -5.0665],
              [145.7274, -5.4225],
              [146.2849, -5.5826],
              [146.4609, -5.5993],
              [146.8128, -5.8386],
              [147.0422, -5.9387],
              [147.4491, -5.9616],
              [147.8267, -6.3354],
              [147.8675, -6.4763],
              [147.854, -6.691],
              [147.7756, -6.7179],
              [146.9773, -6.7404],
              [146.9562, -6.9547],
              [147.1414, -7.2131],
              [147.1619, -7.3906],
              [147.3545, -7.5153],
              [147.6834, -7.8107],
              [147.7268, -7.9321],
              [147.8765, -7.9372],
              [147.9985, -8.0554],
              [148.114, -8.0554],
              [148.2108, -8.3061],
              [148.2376, -8.5693],
              [148.4368, -8.6662],
              [148.5593, -9.0325],
              [148.7583, -9.1037],
              [148.8916, -9.051],
              [149.2437, -8.9972],
              [149.3067, -9.1174],
              [149.1829, -9.3681],
              [149.2266, -9.4867],
              [149.4572, -9.5942],
              [149.731, -9.5978],
              [150.0122, -9.6422],
              [150.0559, -9.715],
              [149.731, -9.8093],
              [149.9395, -10.0624],
              [150.1968, -10.0951],
              [150.3999, -10.3002],
              [150.3469, -10.3653],
              [150.6473, -10.477],
              [150.6929, -10.5567],
              [150.4157, -10.6819],
              [150.2113, -10.7013],
              [150.0674, -10.6399],
              [149.9403, -10.4406],
              [149.7484, -10.3383],
              [149.4606, -10.3523],
              [149.0674, -10.2346],
              [148.978, -10.2761],
              [148.5379, -10.1723],
              [148.402, -10.2005],
              [148.2313, -10.1056],
              [148.0183, -10.1248],
              [147.7617, -10.0597],
              [147.5176, -9.879],
              [147.491, -9.7677],
              [147.3101, -9.5388],
              [147.0527, -9.4602],
              [146.9149, -9.1242],
              [146.6268, -9.023],
              [146.5759, -8.822],
              [146.2772, -8.4352],
              [146.2219, -8.2604],
              [146.1028, -8.1003],
              [145.6133, -7.9325],
              [145.4656, -7.9455],
              [145.3216, -7.862],
              [144.8796, -7.7817],
              [144.6995, -7.6168],
              [144.4313, -7.6122],
              [144.2149, -7.796],
              [143.9243, -7.6943],
              [143.8562, -7.8083],
              [143.9568, -7.9723],
              [143.7673, -8.0402],
              [143.548, -8.0317],
              [143.5921, -8.242],
              [143.3379, -8.2477],
              [142.9805, -8.3356],
              [143.2616, -8.5924],
              [143.3999, -8.7822],
              [143.4055, -8.9622],
              [143.1734, -9.0429],
              [142.6526, -9.3303],
              [142.2234, -9.1612],
              [141.8684, -9.2204],
              [141.51, -9.215],
              [141.4217, -9.1503],
              [141.115, -9.2184],
              [140.977, -9.1061],
            ],
          ],
          [
            [
              [151.0436, -2.7106],
              [151.2163, -2.8692],
              [151.4373, -2.9089],
              [151.8281, -3.1904],
              [152.0525, -3.2467],
              [152.2175, -3.457],
              [152.4929, -3.6428],
              [152.5752, -3.8181],
              [152.9563, -3.9986],
              [153.1372, -4.2695],
              [153.054, -4.4523],
              [153.0752, -4.6018],
              [152.9802, -4.7595],
              [152.8738, -4.7888],
              [152.7326, -4.6496],
              [152.6712, -4.4518],
              [152.6878, -4.1638],
              [152.5189, -3.8547],
              [152.2886, -3.5698],
              [152.1711, -3.4902],
              [151.9841, -3.4589],
              [151.8091, -3.3611],
              [151.6125, -3.1662],
              [151.0906, -2.8327],
              [150.7949, -2.7291],
              [150.9369, -2.6492],
              [151.0436, -2.7106],
            ],
          ],
          [
            [
              [150.2302, -2.3835],
              [150.4473, -2.4845],
              [150.4513, -2.6584],
              [150.1868, -2.6795],
              [149.9946, -2.4665],
              [150.2302, -2.3835],
            ],
          ],
          [
            [
              [146.8923, -1.9521],
              [147.11, -1.9633],
              [147.3332, -2.0323],
              [147.2165, -2.183],
              [146.648, -2.1694],
              [146.6521, -1.9645],
              [146.8923, -1.9521],
            ],
          ],
          [
            [
              [149.5869, -1.3522],
              [149.7248, -1.4289],
              [149.667, -1.5759],
              [149.541, -1.4645],
              [149.5869, -1.3522],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Papua New Guinea",
        NAME_LONG: "Papua New Guinea",
        ADM0_A3: "PNG",
        ISO_A2: "PG",
        ISO_A3: "PNG",
        WB_A2: "PG",
        WB_A3: "PNG",
      },
      id: 181,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.6095, 54.4567],
            [19.6803, 54.4367],
            [20.9296, 54.3978],
            [22.6984, 54.3429],
            [22.7672, 54.3563],
            [22.9733, 54.3754],
            [23.4325, 54.1693],
            [23.4856, 53.9393],
            [23.5909, 53.6113],
            [23.8937, 53.152],
            [23.9225, 52.7426],
            [23.869, 52.67],
            [23.3923, 52.5096],
            [23.1656, 52.2894],
            [23.6375, 52.0845],
            [23.6764, 51.9941],
            [23.5603, 51.7545],
            [23.6062, 51.5174],
            [23.634, 51.3393],
            [23.8747, 51.1361],
            [24.1065, 50.5386],
            [23.9813, 50.4048],
            [23.7475, 50.3894],
            [23.1015, 49.9571],
            [22.6409, 49.5288],
            [22.7242, 49.3671],
            [22.7445, 49.0455],
            [22.5396, 49.0722],
            [22.0124, 49.2111],
            [21.8196, 49.3772],
            [21.2744, 49.4473],
            [21.0535, 49.4145],
            [20.9424, 49.2959],
            [20.6895, 49.4005],
            [20.3295, 49.3918],
            [20.1302, 49.3039],
            [20.07, 49.1831],
            [19.7518, 49.2191],
            [19.4434, 49.6018],
            [19.1725, 49.4023],
            [18.9623, 49.3892],
            [18.8332, 49.5103],
            [18.7735, 49.6758],
            [18.6373, 49.7003],
            [18.5592, 49.9072],
            [18.3069, 49.9097],
            [18.0916, 50.0172],
            [17.8394, 49.9736],
            [17.5842, 50.1459],
            [17.6064, 50.2582],
            [17.3348, 50.27],
            [17.1854, 50.3757],
            [16.9158, 50.4311],
            [16.9603, 50.232],
            [16.7012, 50.0949],
            [16.3342, 50.3719],
            [16.3316, 50.644],
            [15.8482, 50.6752],
            [15.7674, 50.7442],
            [15.3497, 50.7837],
            [15.2271, 50.9774],
            [14.9965, 51.0078],
            [14.8104, 50.8584],
            [14.9553, 51.064],
            [15.0195, 51.2717],
            [14.9451, 51.4492],
            [14.7322, 51.5158],
            [14.7195, 51.6756],
            [14.5858, 51.8039],
            [14.7614, 52.0767],
            [14.5398, 52.4219],
            [14.6448, 52.5769],
            [14.216, 52.818],
            [14.1445, 52.9599],
            [14.3433, 53.0486],
            [14.4094, 53.2724],
            [14.2639, 53.7],
            [14.5449, 53.704],
            [14.5759, 53.8548],
            [14.2008, 53.8782],
            [14.2101, 53.9385],
            [14.367, 53.917],
            [14.7819, 54.0346],
            [15.8597, 54.25],
            [16.2201, 54.2766],
            [16.5696, 54.5572],
            [16.94, 54.606],
            [17.3374, 54.749],
            [17.8854, 54.8241],
            [18.3169, 54.8383],
            [18.4292, 54.7817],
            [18.5881, 54.4337],
            [19.0021, 54.3449],
            [19.3772, 54.3776],
            [19.6095, 54.4567],
          ],
        ],
      },
      properties: {
        NAME: "Poland",
        NAME_LONG: "Poland",
        ADM0_A3: "POL",
        ISO_A2: "PL",
        ISO_A3: "POL",
        WB_A2: "PL",
        WB_A3: "POL",
      },
      id: 182,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-67.0124, 18.5058],
            [-65.9889, 18.4631],
            [-65.6632, 18.3664],
            [-65.5918, 18.2309],
            [-65.7375, 18.1827],
            [-65.8303, 18.0269],
            [-66.1632, 17.9291],
            [-66.7659, 17.9844],
            [-66.9242, 17.9496],
            [-67.2148, 17.9663],
            [-67.1519, 18.2034],
            [-67.2708, 18.3618],
            [-67.0124, 18.5058],
          ],
        ],
      },
      properties: {
        NAME: "Puerto Rico",
        NAME_LONG: "Puerto Rico",
        ADM0_A3: "PRI",
        ISO_A2: "PR",
        ISO_A3: "PRI",
        WB_A2: "PR",
        WB_A3: "PRI",
      },
      id: 183,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [130.5308, 42.5305],
            [130.7, 42.2951],
            [130.4807, 42.3398],
            [130.073, 42.0785],
            [129.9647, 41.8987],
            [129.6908, 41.6497],
            [129.6594, 41.4646],
            [129.7999, 41.3686],
            [129.7119, 41.1226],
            [129.753, 40.9099],
            [129.2064, 40.6755],
            [129.0776, 40.4726],
            [128.6553, 40.3202],
            [128.6646, 40.2012],
            [128.3021, 40.0334],
            [128.0068, 40.0452],
            [127.8746, 39.8928],
            [127.6237, 39.8309],
            [127.5208, 39.747],
            [127.5746, 39.595],
            [127.3784, 39.3715],
            [127.4522, 39.166],
            [127.7696, 39.1092],
            [128.0147, 38.8581],
            [128.3649, 38.6243],
            [128.2681, 38.4535],
            [128.0399, 38.3043],
            [127.7537, 38.3254],
            [127.1575, 38.3072],
            [127.0267, 38.2545],
            [126.7619, 37.979],
            [126.6675, 37.8278],
            [126.5762, 37.7775],
            [126.3977, 37.8918],
            [126.0628, 37.8022],
            [125.8955, 37.8648],
            [125.804, 37.989],
            [125.6092, 37.8063],
            [125.3486, 37.738],
            [125.3006, 37.8952],
            [125.0132, 37.906],
            [125.0135, 38.0624],
            [124.9049, 38.1248],
            [124.8839, 38.3553],
            [125.0582, 38.5685],
            [125.2596, 38.6697],
            [125.1372, 38.8035],
            [125.2874, 39.1523],
            [125.4319, 39.3007],
            [125.2252, 39.5912],
            [125.0477, 39.6042],
            [124.8663, 39.7139],
            [124.6749, 39.6221],
            [124.5549, 39.8171],
            [124.4344, 39.8308],
            [124.37, 40.0983],
            [124.8955, 40.4836],
            [125.3636, 40.6467],
            [125.7609, 40.8857],
            [126.0078, 40.8993],
            [126.1474, 41.0923],
            [126.2607, 41.1431],
            [126.5013, 41.4344],
            [126.6179, 41.6659],
            [126.8871, 41.7849],
            [127.2691, 41.505],
            [127.6383, 41.4299],
            [127.9442, 41.4709],
            [128.1857, 41.4045],
            [128.303, 41.5834],
            [128.04, 41.8796],
            [128.0346, 41.9937],
            [128.262, 42.0329],
            [128.5024, 41.9961],
            [128.9638, 42.0885],
            [129.2112, 42.22],
            [129.2431, 42.3787],
            [129.7033, 42.4424],
            [129.7613, 42.746],
            [129.8798, 42.996],
            [130.1215, 42.9818],
            [130.239, 42.9022],
            [130.243, 42.7434],
            [130.5308, 42.5305],
          ],
        ],
      },
      properties: {
        NAME: "North Korea",
        NAME_LONG: "Dem. Rep. Korea",
        ADM0_A3: "PRK",
        ISO_A2: "KP",
        ISO_A3: "PRK",
        WB_A2: "KP",
        WB_A3: "PRK",
      },
      id: 184,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-17.068, 32.8142],
              [-16.9197, 32.8412],
              [-16.75, 32.7306],
              [-16.9768, 32.6472],
              [-17.068, 32.8142],
            ],
          ],
          [
            [
              [-25.6807, 37.8433],
              [-25.1592, 37.852],
              [-25.1947, 37.7407],
              [-25.5057, 37.7067],
              [-25.6807, 37.8433],
            ],
          ],
          [
            [
              [-28.0471, 38.4326],
              [-28.2419, 38.3776],
              [-28.4595, 38.4057],
              [-28.3745, 38.5505],
              [-28.0471, 38.4326],
            ],
          ],
          [
            [
              [-27.0517, 38.6543],
              [-27.3276, 38.6663],
              [-27.2982, 38.8035],
              [-27.0426, 38.7445],
              [-27.0517, 38.6543],
            ],
          ],
          [
            [
              [-7.4144, 37.1928],
              [-7.7866, 37.0227],
              [-7.9814, 37.0017],
              [-8.1792, 37.0881],
              [-8.6268, 37.1212],
              [-8.8789, 37.0472],
              [-8.8814, 37.2325],
              [-8.7979, 37.4896],
              [-8.7972, 37.8449],
              [-8.8675, 37.9736],
              [-8.7744, 38.1869],
              [-8.7951, 38.3592],
              [-8.9058, 38.5124],
              [-9.2004, 38.4125],
              [-9.2527, 38.6655],
              [-9.4917, 38.7076],
              [-9.4366, 39.0295],
              [-9.3509, 39.1973],
              [-9.3529, 39.3679],
              [-9.0904, 39.5793],
              [-8.8682, 40.1248],
              [-8.8654, 40.2755],
              [-8.6551, 40.9938],
              [-8.7748, 41.4683],
              [-8.8803, 41.7369],
              [-8.7508, 41.969],
              [-8.6269, 42.051],
              [-8.2224, 42.1536],
              [-8.108, 42.0109],
              [-8.2317, 41.886],
              [-8.0486, 41.8164],
              [-7.722, 41.8993],
              [-7.5884, 41.8242],
              [-7.2515, 41.8637],
              [-7.1454, 41.9875],
              [-6.6568, 41.9331],
              [-6.5242, 41.8674],
              [-6.5553, 41.6749],
              [-6.3659, 41.6636],
              [-6.2059, 41.5703],
              [-6.4959, 41.2949],
              [-6.6466, 41.2676],
              [-6.818, 41.0541],
              [-6.9425, 41.016],
              [-6.8367, 40.8402],
              [-6.8571, 40.4421],
              [-6.7944, 40.3564],
              [-7.0431, 40.1814],
              [-6.8797, 40.0092],
              [-7.0358, 39.6893],
              [-7.5573, 39.6798],
              [-7.3135, 39.4574],
              [-7.3267, 39.3413],
              [-7.1675, 39.1135],
              [-6.9733, 39.014],
              [-7.0561, 38.8551],
              [-7.2704, 38.7375],
              [-7.3592, 38.4464],
              [-7.1269, 38.1909],
              [-6.9475, 38.1966],
              [-7.024, 38.0226],
              [-7.2686, 37.9492],
              [-7.5297, 37.5672],
              [-7.4144, 37.1928],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Portugal",
        NAME_LONG: "Portugal",
        ADM0_A3: "PRT",
        ISO_A2: "PT",
        ISO_A3: "PRT",
        WB_A2: "PT",
        WB_A3: "PRT",
      },
      id: 185,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-58.1588, -20.1651],
            [-58.083, -20.3762],
            [-57.9894, -20.4331],
            [-57.991, -20.6206],
            [-57.8602, -20.7303],
            [-57.9087, -20.8816],
            [-57.8254, -20.9986],
            [-57.8556, -21.3307],
            [-57.9299, -21.4531],
            [-57.8956, -21.6884],
            [-57.9868, -22.0353],
            [-57.8439, -22.1437],
            [-57.385, -22.2133],
            [-57.2138, -22.1882],
            [-56.8429, -22.289],
            [-56.6547, -22.2373],
            [-56.4218, -22.0745],
            [-56.1968, -22.2793],
            [-55.8929, -22.3068],
            [-55.7516, -22.4163],
            [-55.6312, -22.6268],
            [-55.6378, -23.0007],
            [-55.5352, -23.2291],
            [-55.5305, -23.6035],
            [-55.4453, -23.7354],
            [-55.398, -23.9768],
            [-55.2009, -24.0195],
            [-54.9436, -23.9692],
            [-54.6126, -23.8112],
            [-54.2453, -24.0506],
            [-54.3346, -24.1489],
            [-54.2611, -24.3295],
            [-54.3213, -24.6284],
            [-54.4623, -25.0372],
            [-54.4297, -25.1306],
            [-54.6098, -25.4328],
            [-54.6002, -25.5749],
            [-54.5879, -25.8108],
            [-54.6623, -25.9799],
            [-54.6383, -26.197],
            [-54.7065, -26.4418],
            [-54.9753, -26.7878],
            [-55.414, -26.9799],
            [-55.599, -27.1826],
            [-55.5913, -27.3284],
            [-55.7547, -27.4437],
            [-55.9131, -27.3278],
            [-56.1246, -27.2989],
            [-56.2798, -27.3896],
            [-56.3675, -27.5806],
            [-56.5464, -27.4551],
            [-56.7712, -27.5067],
            [-56.9044, -27.4187],
            [-57.1801, -27.4873],
            [-57.3352, -27.4095],
            [-57.5357, -27.4072],
            [-57.6978, -27.3335],
            [-58.022, -27.2598],
            [-58.5108, -27.2784],
            [-58.6533, -27.1563],
            [-58.5115, -27.0602],
            [-58.4598, -26.9282],
            [-58.2479, -26.7582],
            [-58.2062, -26.4027],
            [-58.0865, -26.1272],
            [-57.8727, -26.0103],
            [-57.8754, -25.8761],
            [-57.7537, -25.6727],
            [-57.6231, -25.6154],
            [-57.5569, -25.4598],
            [-57.7541, -25.1809],
            [-58.2241, -24.9412],
            [-58.312, -24.9936],
            [-58.4734, -24.8513],
            [-58.8092, -24.7768],
            [-59.0009, -24.6442],
            [-59.341, -24.4876],
            [-59.4597, -24.3585],
            [-60.0337, -24.007],
            [-60.328, -24.018],
            [-60.5936, -23.9122],
            [-61.0063, -23.8055],
            [-61.1099, -23.607],
            [-61.297, -23.4814],
            [-61.4918, -23.4132],
            [-61.7443, -23.2348],
            [-61.9564, -23.0344],
            [-62.1708, -22.7173],
            [-62.2872, -22.4839],
            [-62.6327, -22.296],
            [-62.6504, -22.2345],
            [-62.2757, -21.0666],
            [-62.2773, -20.5798],
            [-61.9443, -20.1041],
            [-61.7612, -19.6578],
            [-60.8339, -19.4679],
            [-60.0064, -19.2981],
            [-59.0895, -19.2867],
            [-58.1753, -19.8214],
            [-58.1588, -20.1651],
          ],
        ],
      },
      properties: {
        NAME: "Paraguay",
        NAME_LONG: "Paraguay",
        ADM0_A3: "PRY",
        ISO_A2: "PY",
        ISO_A3: "PRY",
        WB_A2: "PY",
        WB_A3: "PRY",
      },
      id: 186,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [34.2484, 31.2114],
              [34.2003, 31.3143],
              [34.4812, 31.5831],
              [34.2484, 31.2114],
            ],
          ],
          [
            [
              [35.561, 32.3847],
              [35.5228, 32.0578],
              [35.5594, 31.7653],
              [35.4581, 31.4919],
              [35.2233, 31.381],
              [34.9274, 31.3449],
              [34.8672, 31.3964],
              [34.9736, 31.6304],
              [35.1814, 31.8041],
              [34.9761, 31.9482],
              [34.9461, 32.1773],
              [35.0644, 32.4631],
              [35.2086, 32.5426],
              [35.561, 32.3847],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Palestine",
        NAME_LONG: "Palestine",
        ADM0_A3: "PSX",
        ISO_A2: "PS",
        ISO_A3: "PSE",
        WB_A2: "GZ",
        WB_A3: "WBG",
      },
      id: 187,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-149.3039, -17.7121],
            [-149.5875, -17.7312],
            [-149.6043, -17.5258],
            [-149.3541, -17.5401],
            [-149.3039, -17.7121],
          ],
        ],
      },
      properties: {
        NAME: "Fr. Polynesia",
        NAME_LONG: "French Polynesia",
        ADM0_A3: "PYF",
        ISO_A2: "PF",
        ISO_A3: "PYF",
        WB_A2: "PF",
        WB_A3: "PYF",
      },
      id: 188,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [51.2153, 24.6259],
            [50.9789, 24.5677],
            [50.8079, 24.7466],
            [50.8511, 24.8908],
            [50.7757, 25.0919],
            [50.751, 25.4196],
            [50.8761, 25.6318],
            [50.9017, 25.7895],
            [51.0474, 26.053],
            [51.2512, 26.1601],
            [51.3884, 25.967],
            [51.5775, 25.8808],
            [51.5529, 25.653],
            [51.4719, 25.5214],
            [51.5132, 25.3083],
            [51.6165, 25.1372],
            [51.5869, 24.9534],
            [51.4436, 24.6798],
            [51.2153, 24.6259],
          ],
        ],
      },
      properties: {
        NAME: "Qatar",
        NAME_LONG: "Qatar",
        ADM0_A3: "QAT",
        ISO_A2: "QA",
        ISO_A3: "QAT",
        WB_A2: "QA",
        WB_A3: "QAT",
      },
      id: 189,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.1995, 45.4618],
            [28.3302, 45.3229],
            [28.7101, 45.227],
            [29.3221, 45.4438],
            [29.6503, 45.3461],
            [29.659, 45.2159],
            [29.6112, 44.8493],
            [29.0982, 44.7754],
            [29.0452, 45.0048],
            [28.8801, 44.9548],
            [28.9238, 44.7578],
            [28.7888, 44.7062],
            [28.844, 44.4937],
            [28.6409, 44.3286],
            [28.6712, 44.0033],
            [28.5784, 43.7413],
            [28.2213, 43.762],
            [27.981, 43.8493],
            [27.9358, 43.9644],
            [27.6563, 44.0239],
            [27.3838, 44.0151],
            [27.2511, 44.1224],
            [27.0275, 44.177],
            [26.1507, 44.0124],
            [25.7811, 43.732],
            [25.3596, 43.6543],
            [24.9637, 43.7496],
            [24.7056, 43.7438],
            [24.5001, 43.7995],
            [24.1594, 43.7529],
            [23.3252, 43.8866],
            [22.9196, 43.8342],
            [22.8859, 43.9945],
            [23.023, 44.0316],
            [22.6916, 44.2284],
            [22.4841, 44.4998],
            [22.6212, 44.6374],
            [22.4837, 44.724],
            [22.3197, 44.6853],
            [22.1851, 44.5151],
            [22.0043, 44.6515],
            [21.6563, 44.6877],
            [21.3685, 44.8649],
            [21.4933, 45.1451],
            [20.9815, 45.3328],
            [20.7607, 45.4933],
            [20.7134, 45.7333],
            [20.5721, 45.8877],
            [20.3386, 45.9928],
            [20.2428, 46.1081],
            [20.664, 46.1378],
            [20.8196, 46.2717],
            [21.0515, 46.2361],
            [21.1645, 46.3183],
            [21.3162, 46.6166],
            [21.4836, 46.6849],
            [21.6328, 47.0227],
            [22.0016, 47.3938],
            [21.9889, 47.4929],
            [22.2617, 47.7158],
            [22.7458, 47.825],
            [22.8776, 47.9467],
            [23.2314, 48.0797],
            [23.3601, 47.9931],
            [23.9773, 47.9623],
            [24.231, 47.897],
            [24.5616, 47.9405],
            [24.8966, 47.7101],
            [25.1219, 47.7703],
            [25.2617, 47.8986],
            [26.1731, 47.9931],
            [26.3035, 48.212],
            [26.6179, 48.259],
            [26.8049, 48.2583],
            [27.048, 48.1222],
            [27.272, 47.715],
            [27.5632, 47.4683],
            [27.8066, 47.1446],
            [28.037, 47.0165],
            [28.2341, 46.6624],
            [28.2461, 46.4279],
            [28.0861, 46.0006],
            [28.1995, 45.4618],
          ],
        ],
      },
      properties: {
        NAME: "Romania",
        NAME_LONG: "Romania",
        ADM0_A3: "ROU",
        ISO_A2: "RO",
        ISO_A3: "ROU",
        WB_A2: "RO",
        WB_A3: "ROM",
      },
      id: 190,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [146.3338, 44.4285],
              [146.3259, 44.2922],
              [145.9749, 44.2923],
              [146.1193, 44.506],
              [146.3338, 44.4285],
            ],
          ],
          [
            [
              [148.8167, 45.3335],
              [148.5562, 45.307],
              [148.2158, 45.1957],
              [147.9903, 45.0058],
              [147.7683, 44.9433],
              [147.3884, 44.7367],
              [147.2644, 44.8676],
              [147.8862, 45.2187],
              [148.3119, 45.2753],
              [148.6211, 45.4982],
              [148.7992, 45.5181],
              [148.8167, 45.3335],
            ],
          ],
          [
            [
              [150.5693, 46.2318],
              [150.2454, 46.037],
              [150.1423, 45.898],
              [149.9476, 45.8224],
              [149.6378, 45.6109],
              [149.4823, 45.6694],
              [149.6673, 45.8532],
              [149.811, 45.8798],
              [150.2111, 46.1792],
              [150.5693, 46.2318],
            ],
          ],
          [
            [
              [156.1195, 50.498],
              [155.8849, 50.2537],
              [155.5615, 50.1666],
              [155.3845, 50.0375],
              [155.2209, 50.1099],
              [155.4102, 50.3602],
              [155.7178, 50.4231],
              [155.8895, 50.6909],
              [156.0733, 50.7816],
              [156.1195, 50.498],
            ],
          ],
          [
            [
              [156.4058, 50.6542],
              [156.1685, 50.7366],
              [156.3955, 50.8685],
              [156.511, 50.7658],
              [156.4058, 50.6542],
            ],
          ],
          [
            [
              [142.8428, 54.2807],
              [143.0039, 54.1355],
              [142.9059, 53.9119],
              [142.9397, 53.7497],
              [143.29, 53.1519],
              [143.3379, 52.7038],
              [143.1187, 52.3378],
              [143.0977, 52.1042],
              [143.1631, 51.874],
              [143.3104, 51.7543],
              [143.2212, 51.5316],
              [143.4618, 51.4695],
              [143.7903, 50.3092],
              [144.0027, 50.0128],
              [144.2222, 49.5326],
              [144.2827, 49.2502],
              [143.499, 49.3492],
              [143.1742, 49.246],
              [142.9746, 49.0459],
              [142.9885, 48.9167],
              [142.7927, 48.5626],
              [142.5496, 48.0201],
              [142.5453, 47.7667],
              [142.7595, 47.4312],
              [143.0164, 47.2536],
              [143.0325, 47.0707],
              [143.1729, 46.7078],
              [143.4187, 46.7997],
              [143.6257, 46.3747],
              [143.4214, 46.2231],
              [143.3428, 46.5628],
              [142.7722, 46.6098],
              [142.7246, 46.7408],
              [142.4572, 46.6308],
              [142.2819, 46.3275],
              [142.2017, 46.0307],
              [142.0398, 45.9243],
              [141.9326, 46.0358],
              [141.8223, 46.6023],
              [142.0567, 47.0537],
              [141.9751, 47.2976],
              [141.9658, 47.6125],
              [142.1911, 47.9667],
              [142.1387, 48.3181],
              [141.9196, 48.6091],
              [141.8574, 48.7742],
              [141.9673, 48.8624],
              [142.0772, 49.1982],
              [142.0628, 49.3149],
              [142.1597, 49.678],
              [142.125, 49.8977],
              [142.1738, 50.0864],
              [142.1501, 50.3457],
              [142.0452, 50.5283],
              [142.1284, 50.8945],
              [142.2669, 51.0743],
              [142.0789, 51.4714],
              [141.8171, 51.6723],
              [141.6755, 51.9124],
              [141.661, 52.3825],
              [141.8438, 52.5908],
              [141.9284, 53.0207],
              [141.7688, 53.3716],
              [142.2297, 53.5208],
              [142.3074, 53.3664],
              [142.5767, 53.5001],
              [142.7083, 53.9482],
              [142.4095, 54.2316],
              [142.6255, 54.3273],
              [142.8428, 54.2807],
            ],
          ],
          [
            [
              [137.7864, 55.1376],
              [138.0249, 55.0557],
              [137.9862, 54.7974],
              [137.7061, 54.625],
              [137.4854, 54.8793],
              [137.4614, 55.0268],
              [137.6061, 55.2001],
              [137.7864, 55.1376],
            ],
          ],
          [
            [
              [22.7672, 54.3563],
              [22.6984, 54.3429],
              [20.9296, 54.3978],
              [19.6803, 54.4367],
              [19.6095, 54.4567],
              [19.8319, 54.5981],
              [20.0337, 54.9482],
              [20.3656, 54.9462],
              [20.634, 55.0488],
              [20.9246, 55.2827],
              [20.9894, 55.2731],
              [20.5309, 54.9647],
              [21.0789, 54.8998],
              [21.2302, 54.9389],
              [21.1813, 55.1933],
              [21.2676, 55.2487],
              [22.0999, 55.0299],
              [22.5807, 55.0576],
              [22.8089, 54.8938],
              [22.6804, 54.4532],
              [22.7672, 54.3563],
            ],
          ],
          [
            [
              [165.9556, 55.3642],
              [166.2878, 55.3039],
              [166.2503, 55.1523],
              [166.6498, 54.8445],
              [166.3565, 54.8395],
              [166.0829, 55.0606],
              [165.9556, 55.3642],
            ],
          ],
          [
            [
              [164.5808, 59.1832],
              [164.7341, 59.0233],
              [164.6319, 58.8654],
              [164.1212, 58.7807],
              [163.5855, 58.5579],
              [163.9667, 59.0286],
              [164.5808, 59.1832],
            ],
          ],
          [
            [
              [69.2798, 66.7945],
              [70.0857, 66.6901],
              [70.0436, 66.5013],
              [69.5454, 66.591],
              [69.2798, 66.7945],
            ],
          ],
          [
            [
              [50.2759, 69.192],
              [49.9549, 69.0747],
              [49.7197, 68.89],
              [49.2485, 68.78],
              [48.5921, 68.7309],
              [48.2188, 68.9092],
              [48.3042, 69.2768],
              [48.8063, 69.4932],
              [49.2717, 69.508],
              [50.1644, 69.2609],
              [50.2759, 69.192],
            ],
          ],
          [
            [
              [169.4123, 69.7702],
              [169.1895, 69.5752],
              [168.8745, 69.5679],
              [168.1725, 69.6952],
              [167.7909, 69.8083],
              [168.2647, 70.0241],
              [169.4238, 69.873],
              [169.4123, 69.7702],
            ],
          ],
          [
            [
              [59.3518, 70.3531],
              [59.7829, 70.1315],
              [60.4759, 69.9205],
              [60.4759, 69.7225],
              [60.201, 69.6851],
              [59.5635, 69.715],
              [59.4315, 69.8895],
              [59.053, 69.9031],
              [58.6956, 70.0028],
              [58.453, 70.1955],
              [58.5223, 70.3311],
              [59.0236, 70.4798],
              [59.3518, 70.3531],
            ],
          ],
          [
            [
              [52.7411, 71.4058],
              [53.061, 71.2382],
              [52.7998, 71.093],
              [52.2578, 71.3489],
              [52.7411, 71.4058],
            ],
          ],
          [
            [
              [178.8547, 71.2126],
              [179.5635, 71.4515],
              [180, 71.5364],
              [180, 71.0526],
              [180, 70.9924],
              [179.667, 70.8748],
              [179.131, 70.8787],
              [178.832, 70.8085],
              [178.6227, 71.0377],
              [178.8547, 71.2126],
            ],
          ],
          [
            [
              [137.8701, 71.5795],
              [137.7674, 71.4307],
              [137.3441, 71.4276],
              [137.2183, 71.5762],
              [137.8701, 71.5795],
            ],
          ],
          [
            [
              [77.7708, 72.305],
              [76.918, 72.293],
              [77.6102, 72.6347],
              [78.3945, 72.4914],
              [77.7708, 72.305],
            ],
          ],
          [
            [
              [129.3341, 72.7737],
              [128.3171, 72.8123],
              [128.419, 72.8693],
              [128.9941, 72.9325],
              [129.3341, 72.7737],
            ],
          ],
          [
            [
              [79.5414, 72.9255],
              [79.572, 72.7405],
              [78.9099, 72.7413],
              [78.5923, 72.8621],
              [79.1404, 73.0954],
              [79.5414, 72.9255],
            ],
          ],
          [
            [
              [74.8452, 73.0937],
              [74.465, 72.8996],
              [74.0933, 73.0278],
              [74.2117, 73.1146],
              [74.8452, 73.0937],
            ],
          ],
          [
            [
              [55.3042, 73.3393],
              [56.4352, 73.2301],
              [56.5484, 73.1216],
              [56.2254, 73.009],
              [56.2199, 72.8266],
              [55.7385, 72.7842],
              [55.6848, 72.5733],
              [55.377, 72.3027],
              [55.575, 72.1997],
              [55.3428, 72.0656],
              [55.6721, 71.611],
              [56.1057, 71.259],
              [57.0632, 70.8625],
              [57.6387, 70.7299],
              [57.1719, 70.6132],
              [56.3618, 70.6472],
              [55.8176, 70.6133],
              [55.4446, 70.7436],
              [55.2327, 70.6666],
              [54.7606, 70.6806],
              [54.4924, 70.7845],
              [54.0065, 70.7578],
              [53.4919, 71.0725],
              [53.577, 71.1993],
              [53.2517, 71.456],
              [52.8684, 71.4022],
              [52.5739, 71.5759],
              [51.862, 71.4562],
              [51.5324, 71.5659],
              [51.4014, 71.8705],
              [51.61, 72.0917],
              [52.3323, 72.0706],
              [52.6688, 72.3064],
              [52.6974, 72.4976],
              [52.9142, 72.597],
              [52.3976, 72.7179],
              [52.6167, 72.8632],
              [53.1419, 72.9625],
              [53.1556, 73.1506],
              [53.7532, 73.2989],
              [54.1908, 73.2722],
              [54.9348, 73.4254],
              [55.3042, 73.3393],
            ],
          ],
          [
            [
              [70.9487, 73.5175],
              [71.6691, 73.2275],
              [71.4947, 73.1501],
              [70.2566, 73.0383],
              [69.9343, 73.1317],
              [69.9827, 73.4081],
              [70.9487, 73.5175],
            ],
          ],
          [
            [
              [142.2738, 73.8657],
              [142.5828, 73.8253],
              [143.4072, 73.5428],
              [143.5651, 73.2312],
              [143.1749, 73.1766],
              [141.5124, 73.2994],
              [140.7886, 73.41],
              [139.9651, 73.3266],
              [139.9065, 73.471],
              [140.4933, 73.485],
              [140.8664, 73.7798],
              [141.1272, 73.8817],
              [142.0452, 73.9166],
              [142.2738, 73.8657],
            ],
          ],
          [
            [
              [140.5451, 73.9158],
              [140.1647, 74.0706],
              [140.2219, 74.2201],
              [140.8914, 74.2765],
              [141.1126, 74.1661],
              [141.0378, 74.0039],
              [140.5451, 73.9158],
            ],
          ],
          [
            [
              [113.3664, 74.3512],
              [112.7749, 74.0881],
              [112.1629, 74.1386],
              [111.5813, 74.2602],
              [111.9868, 74.3957],
              [112.1101, 74.5512],
              [113.3145, 74.4798],
              [113.3664, 74.3512],
            ],
          ],
          [
            [
              [146.7295, 75.4882],
              [148.4077, 75.4211],
              [148.6104, 75.2155],
              [149.1268, 75.2686],
              [150.1294, 75.2255],
              [150.6328, 75.1165],
              [150.6092, 74.8935],
              [149.209, 74.758],
              [148.1721, 74.8035],
              [147.6638, 74.9534],
              [147.0879, 75.0052],
              [146.0718, 75.2403],
              [146.3892, 75.5852],
              [146.7295, 75.4882],
            ],
          ],
          [
            [
              [135.8445, 75.3826],
              [135.45, 75.3757],
              [135.6027, 75.7643],
              [136.1602, 75.6296],
              [135.8445, 75.3826],
            ],
          ],
          [
            [
              [139.1096, 76.0969],
              [139.9116, 75.9374],
              [140.1504, 75.8005],
              [140.8644, 75.7388],
              [140.9089, 75.9841],
              [141.3914, 76.1435],
              [141.7022, 76.121],
              [142.5469, 75.863],
              [143.0588, 75.8068],
              [143.9471, 75.8348],
              [145.3662, 75.5402],
              [144.7293, 75.4316],
              [144.6882, 75.16],
              [144.3312, 75.0468],
              [143.4148, 75.0671],
              [142.8722, 75.1566],
              [142.4943, 75.3757],
              [143.0135, 75.6024],
              [143.0011, 75.708],
              [142.324, 75.7119],
              [142.0832, 75.5259],
              [142.1999, 75.3488],
              [142.6282, 75.097],
              [143.448, 75.0058],
              [143.4645, 74.9001],
              [142.3556, 74.8245],
              [141.8438, 74.999],
              [140.0687, 74.8279],
              [139.8496, 74.9648],
              [139.3434, 74.6874],
              [138.1657, 74.7762],
              [137.7938, 74.9754],
              [137.1491, 75.1342],
              [136.8622, 75.362],
              [137.4307, 75.3893],
              [137.0676, 75.7258],
              [137.5884, 75.7736],
              [137.433, 75.9532],
              [138.138, 76.1188],
              [138.9608, 76.2069],
              [139.1096, 76.0969],
            ],
          ],
          [
            [
              [96.283, 76.3093],
              [96.4006, 76.1552],
              [95.3106, 76.2012],
              [95.314, 76.2766],
              [96.283, 76.3093],
            ],
          ],
          [
            [
              [68.1585, 76.9676],
              [68.4629, 76.9667],
              [68.9307, 76.7194],
              [68.7473, 76.5105],
              [68.2879, 76.2813],
              [66.1786, 75.962],
              [63.3044, 75.5886],
              [62.0482, 75.4503],
              [60.8325, 75.1529],
              [60.5606, 74.8663],
              [59.8748, 74.6285],
              [59.2214, 74.6154],
              [59.0912, 74.4949],
              [58.5705, 74.3889],
              [58.5083, 74.2175],
              [57.8784, 73.9343],
              [57.946, 73.8066],
              [57.3296, 73.5739],
              [57.2539, 73.4572],
              [56.5788, 73.2608],
              [55.7723, 73.348],
              [55.4349, 73.3321],
              [55.0207, 73.4435],
              [54.4739, 73.3908],
              [53.8687, 73.7787],
              [54.2528, 73.9158],
              [54.7258, 73.9779],
              [55.0189, 74.173],
              [55.6227, 74.1902],
              [55.5274, 74.3461],
              [55.896, 74.6606],
              [55.8084, 75.1566],
              [56.8809, 75.2233],
              [57.0462, 75.3981],
              [57.7411, 75.3279],
              [57.5636, 75.5197],
              [58.1867, 75.5888],
              [58.2591, 75.6929],
              [59.3987, 75.9374],
              [59.7488, 75.9306],
              [60.2681, 76.1101],
              [60.8384, 76.123],
              [61.0769, 76.28],
              [61.637, 76.312],
              [62.9568, 76.2069],
              [63.7192, 76.3497],
              [64.0345, 76.3153],
              [64.8259, 76.4842],
              [65.169, 76.4753],
              [65.7534, 76.6828],
              [66.9435, 76.949],
              [67.5881, 77.0134],
              [68.1585, 76.9676],
            ],
          ],
          [
            [
              [130.7, 42.2951],
              [130.5308, 42.5305],
              [130.4072, 42.7354],
              [130.7695, 42.8658],
              [130.9924, 42.8487],
              [131.2809, 43.3802],
              [131.1703, 43.7811],
              [131.2645, 44.0542],
              [131.0658, 44.682],
              [130.9334, 44.8417],
              [131.4195, 44.959],
              [131.6091, 45.0897],
              [131.818, 45.3328],
              [131.9696, 45.2338],
              [132.9534, 45.0244],
              [133.1154, 45.123],
              [133.0975, 45.2435],
              [133.2021, 45.5113],
              [133.4135, 45.5733],
              [133.5144, 45.8793],
              [133.6581, 45.9345],
              [133.6874, 46.1418],
              [133.9025, 46.259],
              [133.8493, 46.4803],
              [133.988, 46.6252],
              [134.1541, 47.2579],
              [134.2928, 47.4097],
              [134.4846, 47.4302],
              [134.7726, 47.7107],
              [134.5672, 48.0404],
              [134.7009, 48.2615],
              [134.3863, 48.3813],
              [133.7213, 48.2448],
              [133.4637, 48.0895],
              [133.092, 48.1068],
              [132.6588, 47.8698],
              [132.5247, 47.7075],
              [131.5774, 47.6686],
              [131.4184, 47.7426],
              [131.0234, 47.6823],
              [130.907, 47.8843],
              [130.6634, 48.1164],
              [130.8194, 48.2819],
              [130.7159, 48.4991],
              [130.5333, 48.6358],
              [130.6743, 48.8708],
              [130.2493, 48.8645],
              [129.9134, 49.0395],
              [129.7112, 49.2742],
              [129.0506, 49.3767],
              [128.6662, 49.6095],
              [128.0925, 49.5416],
              [127.8309, 49.5939],
              [127.5081, 49.8223],
              [127.4867, 50.0256],
              [127.5784, 50.2206],
              [127.3307, 50.3282],
              [127.3648, 50.5547],
              [127.2874, 50.751],
              [126.9318, 51.0638],
              [126.9546, 51.3296],
              [126.4461, 51.9825],
              [126.5312, 52.1527],
              [126.2123, 52.533],
              [125.9732, 52.6316],
              [126.0351, 52.7271],
              [125.6214, 53.0621],
              [125.5213, 53.0424],
              [125.1274, 53.2065],
              [124.7202, 53.1488],
              [124.4082, 53.2357],
              [124.2327, 53.3817],
              [123.6396, 53.5513],
              [123.2526, 53.5678],
              [122.8597, 53.4739],
              [122.3378, 53.4913],
              [121.889, 53.4306],
              [121.2035, 53.2767],
              [120.8743, 53.2802],
              [120.2802, 52.8659],
              [120.033, 52.7607],
              [120.0564, 52.6005],
              [120.4299, 52.6361],
              [120.724, 52.5422],
              [120.6262, 52.3468],
              [120.7792, 52.1176],
              [120.6476, 51.911],
              [120.4931, 51.8757],
              [120.1082, 51.6652],
              [119.7483, 51.0834],
              [119.518, 50.9048],
              [119.4967, 50.7371],
              [119.2936, 50.5992],
              [119.2437, 50.4455],
              [119.3529, 50.3455],
              [119.3162, 50.0927],
              [119.0917, 49.9866],
              [118.6444, 49.9546],
              [118.1686, 49.6715],
              [117.7588, 49.5127],
              [117.4729, 49.6143],
              [117.0576, 49.6729],
              [116.6843, 49.8233],
              [116.5754, 49.9218],
              [116.2176, 50.0138],
              [115.7509, 49.885],
              [115.3687, 49.8954],
              [114.9974, 50.1443],
              [114.7537, 50.2362],
              [114.2863, 50.2769],
              [113.8498, 50.0807],
              [113.5797, 50.0199],
              [113.2124, 49.822],
              [113.0437, 49.5886],
              [112.7337, 49.4928],
              [112.4322, 49.5292],
              [112.0302, 49.4119],
              [111.3385, 49.3647],
              [110.7314, 49.1377],
              [110.3712, 49.2417],
              [110.1814, 49.162],
              [109.7285, 49.2665],
              [109.5162, 49.2559],
              [109.2867, 49.3385],
              [108.5381, 49.3274],
              [108.2505, 49.5224],
              [108.0327, 49.5893],
              [107.9465, 49.9335],
              [107.2249, 49.9971],
              [106.9737, 50.1964],
              [106.6565, 50.327],
              [106.2449, 50.2903],
              [105.9849, 50.3998],
              [105.3286, 50.4765],
              [104.4058, 50.3005],
              [104.1217, 50.1483],
              [103.7853, 50.1861],
              [103.6014, 50.1335],
              [103.2531, 50.215],
              [102.5866, 50.4153],
              [102.3276, 50.5455],
              [102.3294, 50.7188],
              [102.2168, 50.7989],
              [102.1465, 51.0976],
              [102.1839, 51.3239],
              [102.0512, 51.3836],
              [101.4821, 51.4729],
              [101.3878, 51.4506],
              [100.5109, 51.7269],
              [100.006, 51.7317],
              [99.6793, 51.8887],
              [99.3239, 51.9342],
              [98.8556, 52.1067],
              [98.7111, 51.8451],
              [98.3322, 51.7183],
              [98.2204, 51.4752],
              [98.0509, 51.4664],
              [97.8064, 51.0011],
              [97.9902, 50.8356],
              [98.0233, 50.642],
              [98.2935, 50.5186],
              [98.2625, 50.2838],
              [98.1056, 50.0639],
              [97.8474, 49.9301],
              [97.5666, 49.9168],
              [97.5427, 49.8227],
              [97.3017, 49.7255],
              [96.9692, 49.8867],
              [96.3729, 49.8861],
              [95.867, 50.015],
              [95.4806, 49.8922],
              [95.0764, 49.9455],
              [94.9216, 50.0459],
              [94.6245, 50.0152],
              [94.3443, 50.2492],
              [94.2376, 50.5652],
              [93.5363, 50.5847],
              [93.0114, 50.6233],
              [92.9617, 50.7779],
              [92.6498, 50.6741],
              [92.3017, 50.8434],
              [92.1588, 50.6891],
              [91.7498, 50.6841],
              [91.4157, 50.4614],
              [91.012, 50.4253],
              [90.6839, 50.2162],
              [90.4752, 50.2146],
              [90.0512, 50.0842],
              [89.9694, 49.9671],
              [89.6239, 49.9027],
              [89.6823, 49.7083],
              [89.2522, 49.6294],
              [89.1607, 49.5027],
              [88.8706, 49.436],
              [88.3788, 49.4758],
              [88.2, 49.4543],
              [88.144, 49.2707],
              [87.8163, 49.1658],
              [87.3238, 49.0853],
              [87.218, 49.2298],
              [86.9051, 49.3519],
              [86.8243, 49.5092],
              [86.5918, 49.5872],
              [86.7759, 49.695],
              [86.6102, 49.7955],
              [86.3918, 49.625],
              [86.1115, 49.5202],
              [85.384, 49.6157],
              [85.2631, 49.5815],
              [85.0326, 49.885],
              [84.9758, 50.0752],
              [84.642, 50.1849],
              [84.25, 50.2599],
              [84.1017, 50.6286],
              [83.8205, 50.868],
              [83.434, 50.9927],
              [83.1076, 50.9927],
              [82.9759, 50.8819],
              [82.698, 50.811],
              [82.0731, 50.7157],
              [81.7635, 50.7843],
              [81.4602, 50.7303],
              [81.4008, 50.9591],
              [81.0583, 50.9471],
              [81.1472, 51.1359],
              [80.6828, 51.302],
              [80.4762, 51.2096],
              [80.439, 50.9581],
              [80.0493, 50.8523],
              [79.9901, 50.7881],
              [79.4698, 51.4926],
              [78.4765, 52.6379],
              [77.8668, 53.2723],
              [77.6128, 53.4428],
              [76.5257, 53.9613],
              [76.3744, 54.1478],
              [76.6646, 54.1341],
              [76.8377, 54.2969],
              [76.7661, 54.3646],
              [76.4331, 54.3166],
              [75.6404, 54.0989],
              [75.0279, 53.8079],
              [74.772, 53.8309],
              [74.6165, 53.6807],
              [74.2139, 53.597],
              [73.8727, 53.6321],
              [73.3912, 53.5202],
              [73.2297, 53.6624],
              [73.4853, 53.8749],
              [73.6971, 53.9008],
              [73.7406, 54.0579],
              [73.3603, 53.9384],
              [73.0628, 53.9791],
              [72.9568, 54.1175],
              [72.6562, 54.0425],
              [72.4556, 53.9053],
              [72.3569, 54.0818],
              [72.4829, 54.1356],
              [72.1518, 54.3683],
              [72.0667, 54.2224],
              [71.7625, 54.2476],
              [71.7087, 54.1322],
              [71.1823, 54.1016],
              [71.1803, 54.7274],
              [71.006, 54.7742],
              [70.9771, 55.078],
              [70.6657, 55.3092],
              [70.4601, 55.2874],
              [70.197, 55.1335],
              [69.6739, 55.334],
              [69.3346, 55.3851],
              [69.1861, 55.3258],
              [68.7225, 55.3528],
              [68.6053, 55.1956],
              [68.2624, 55.194],
              [68.3082, 55.0728],
              [68.1661, 54.9561],
              [67.8173, 54.9522],
              [67.6227, 54.8539],
              [66.4465, 54.7109],
              [65.9775, 54.6117],
              [65.4774, 54.6327],
              [65.2143, 54.5279],
              [65.2129, 54.343],
              [64.9106, 54.4027],
              [64.5785, 54.3856],
              [63.7086, 54.2504],
              [63.0847, 54.0856],
              [62.8121, 54.095],
              [62.5506, 54.0268],
              [61.4786, 54.0029],
              [61.1935, 54.0183],
              [61.202, 53.8196],
              [61.0076, 53.6357],
              [61.2807, 53.5424],
              [61.1372, 53.3731],
              [61.2029, 53.2764],
              [62.0507, 53.1248],
              [62.0942, 52.9925],
              [61.5743, 52.9558],
              [61.1903, 53.0131],
              [60.8291, 52.7716],
              [60.6962, 52.7349],
              [60.8314, 52.5132],
              [61.0399, 52.335],
              [60.6722, 52.1443],
              [60.5065, 52.1516],
              [60.2028, 51.9927],
              [60.1381, 51.8889],
              [60.4521, 51.8055],
              [60.5151, 51.6179],
              [60.9374, 51.6112],
              [61.0233, 51.4855],
              [61.4738, 51.4258],
              [61.5932, 51.2778],
              [61.3721, 50.7825],
              [60.7185, 50.6527],
              [60.3004, 50.6847],
              [60.0213, 50.8541],
              [59.7747, 50.5337],
              [59.5228, 50.4746],
              [59.4376, 50.6311],
              [58.9259, 50.6781],
              [58.6644, 50.7899],
              [58.595, 51.0234],
              [57.7688, 51.1197],
              [57.7373, 50.9382],
              [57.3527, 50.886],
              [57.1682, 51.0938],
              [56.8651, 51.0518],
              [56.508, 51.0663],
              [56.3357, 50.8959],
              [56.1749, 50.9087],
              [56.0789, 50.7248],
              [55.66, 50.53],
              [55.4911, 50.6459],
              [55.3054, 50.665],
              [54.6382, 51.0277],
              [54.6763, 50.6025],
              [54.3798, 50.6194],
              [54.4786, 50.8535],
              [54.1766, 50.9679],
              [53.9359, 51.1856],
              [53.6739, 51.2199],
              [53.6103, 51.3884],
              [53.4073, 51.4842],
              [53.1533, 51.5127],
              [52.5058, 51.4789],
              [52.3349, 51.7637],
              [52.0918, 51.6587],
              [51.8692, 51.6731],
              [51.6205, 51.5187],
              [51.3795, 51.4671],
              [51.3719, 51.6296],
              [51.2339, 51.6855],
              [50.9254, 51.6797],
              [50.7907, 51.5973],
              [50.5817, 51.6353],
              [50.5183, 51.4598],
              [50.3254, 51.3035],
              [49.9869, 51.2298],
              [49.7791, 51.1022],
              [49.4231, 51.102],
              [49.3694, 50.8268],
              [49.1441, 50.7817],
              [48.8304, 50.596],
              [48.6734, 50.5795],
              [48.7013, 50.3346],
              [48.8857, 50.0199],
              [48.7846, 49.9334],
              [48.4176, 49.8117],
              [48.2393, 49.8648],
              [47.9932, 50.1818],
              [47.6247, 50.4408],
              [47.2948, 50.2754],
              [47.3087, 50.0448],
              [46.8999, 49.8203],
              [46.7751, 49.3325],
              [47.0286, 49.2015],
              [47.0209, 49.0968],
              [46.7538, 48.9141],
              [46.4793, 48.4102],
              [47.0783, 48.2753],
              [47.0512, 47.9746],
              [47.1745, 47.7705],
              [47.3597, 47.6833],
              [47.4353, 47.8344],
              [47.6497, 47.7656],
              [48.0445, 47.7697],
              [48.1744, 47.7129],
              [48.3606, 47.4931],
              [48.5251, 47.4102],
              [48.6973, 47.101],
              [49.0063, 46.7691],
              [48.764, 46.6828],
              [48.5567, 46.7617],
              [48.4551, 46.6608],
              [49.2271, 46.3279],
              [48.8871, 46.1223],
              [48.7141, 46.1296],
              [48.7411, 45.923],
              [48.4656, 45.9402],
              [48.2698, 45.7795],
              [47.6087, 45.6388],
              [47.2971, 45.1874],
              [47.0715, 44.7851],
              [46.722, 44.6553],
              [46.7009, 44.4462],
              [47.0442, 44.3463],
              [47.2459, 44.212],
              [47.5028, 43.781],
              [47.3799, 43.5597],
              [47.4909, 43.4628],
              [47.4619, 43.0208],
              [47.6981, 42.8671],
              [47.7156, 42.6967],
              [47.8677, 42.5847],
              [48.304, 42.1027],
              [48.4164, 41.915],
              [48.5789, 41.8453],
              [48.3702, 41.5802],
              [48.0535, 41.4613],
              [47.8711, 41.2082],
              [47.7437, 41.1927],
              [47.2684, 41.3028],
              [47.2315, 41.4275],
              [47.027, 41.5569],
              [46.745, 41.8401],
              [46.5499, 41.7978],
              [46.4309, 41.8904],
              [46.2243, 41.9935],
              [45.9476, 42.0229],
              [45.6214, 42.2245],
              [45.6987, 42.5076],
              [45.356, 42.52],
              [45.1842, 42.6748],
              [44.8594, 42.7595],
              [44.272, 42.7024],
              [43.9397, 42.5522],
              [43.7511, 42.5987],
              [43.8006, 42.7465],
              [43.5174, 42.8605],
              [43.1943, 42.9268],
              [42.7515, 43.177],
              [42.5959, 43.14],
              [42.4229, 43.229],
              [42.0753, 43.1853],
              [41.5506, 43.2263],
              [41.3807, 43.3348],
              [41.0118, 43.3754],
              [40.6519, 43.539],
              [40.4798, 43.5087],
              [40.2331, 43.5758],
              [40.0592, 43.5352],
              [39.986, 43.389],
              [39.7183, 43.5678],
              [39.3294, 43.8962],
              [38.9783, 44.1483],
              [38.5951, 44.3319],
              [38.1777, 44.406],
              [37.9226, 44.6138],
              [37.4783, 44.6772],
              [37.1919, 44.991],
              [36.8401, 45.1094],
              [36.7816, 45.3876],
              [36.8333, 45.443],
              [37.1589, 45.3261],
              [37.6351, 45.3338],
              [37.7376, 45.3747],
              [37.5951, 45.6353],
              [37.8294, 45.7532],
              [37.9401, 46.0203],
              [38.2488, 46.1415],
              [38.4458, 46.0342],
              [38.5568, 46.1152],
              [38.3001, 46.2187],
              [38.1229, 46.3775],
              [37.8273, 46.4765],
              [37.8522, 46.6225],
              [38.0368, 46.6295],
              [38.403, 46.8209],
              [38.8037, 46.8935],
              [39.0752, 47.0327],
              [39.2619, 47.0086],
              [39.264, 47.2608],
              [39.0881, 47.2864],
              [38.9378, 47.1898],
              [38.2166, 47.1033],
              [38.201, 47.2906],
              [38.3529, 47.6071],
              [38.7345, 47.6771],
              [38.8772, 47.8612],
              [39.3916, 47.8226],
              [39.7591, 47.8329],
              [39.8431, 48.1198],
              [39.9932, 48.2732],
              [39.8092, 48.5838],
              [39.6317, 48.5869],
              [39.7587, 48.8954],
              [39.6811, 49.0203],
              [39.9187, 49.0478],
              [40.1417, 49.2458],
              [40.0409, 49.4555],
              [39.8015, 49.5422],
              [39.5704, 49.7133],
              [39.238, 49.7651],
              [39.1828, 49.8585],
              [38.8522, 49.8658],
              [38.3442, 49.9921],
              [37.9953, 49.9213],
              [37.7277, 50.0787],
              [37.5992, 50.2909],
              [37.4353, 50.4249],
              [36.9141, 50.3391],
              [36.6826, 50.2607],
              [36.269, 50.2814],
              [36.1058, 50.4211],
              [35.8374, 50.4232],
              [35.6969, 50.3451],
              [35.4253, 50.5005],
              [35.4454, 50.6875],
              [35.3417, 50.9159],
              [34.9618, 51.2153],
              [34.7481, 51.1647],
              [34.409, 51.2531],
              [34.2803, 51.3613],
              [34.128, 51.6807],
              [34.3763, 51.7086],
              [34.3857, 51.8177],
              [34.0682, 52.031],
              [34.0557, 52.1703],
              [33.8041, 52.3546],
              [33.1965, 52.3689],
              [32.9269, 52.2469],
              [32.5284, 52.3162],
              [32.3395, 52.2872],
              [32.3067, 52.1412],
              [32.0956, 52.0407],
              [31.7643, 52.1006],
              [31.6081, 52.3725],
              [31.5702, 52.7253],
              [31.5051, 52.8489],
              [31.2471, 53.0144],
              [31.3785, 53.182],
              [31.7386, 53.1925],
              [31.9072, 53.0919],
              [32.2059, 53.1004],
              [32.4055, 53.1927],
              [32.7195, 53.4395],
              [32.4529, 53.5464],
              [32.4806, 53.6624],
              [32.0836, 53.8096],
              [31.7538, 53.8196],
              [31.8465, 53.9923],
              [31.7481, 54.0863],
              [31.3247, 54.2292],
              [31.1679, 54.6216],
              [30.7706, 54.786],
              [30.8148, 54.928],
              [31.0064, 55.0424],
              [30.7943, 55.2855],
              [30.9186, 55.4076],
              [30.9128, 55.5716],
              [30.5964, 55.6653],
              [30.4688, 55.7935],
              [30.2178, 55.8551],
              [29.8695, 55.8305],
              [29.461, 55.6873],
              [29.3436, 55.787],
              [29.3774, 55.954],
              [29.0308, 56.0242],
              [28.7064, 55.9598],
              [28.6207, 56.083],
              [28.2898, 56.0466],
              [28.1489, 56.1424],
              [28.2148, 56.2814],
              [28.1261, 56.5478],
              [27.9974, 56.6038],
              [27.9189, 56.8059],
              [27.6487, 56.8793],
              [27.8403, 57.2906],
              [27.3529, 57.5276],
              [27.4077, 57.689],
              [27.6731, 57.9128],
              [27.4954, 58.2215],
              [27.5549, 58.3952],
              [27.4106, 58.7549],
              [27.6944, 58.9759],
              [27.9039, 59.2904],
              [28.1865, 59.3749],
              [28.0191, 59.4818],
              [27.9934, 59.7033],
              [28.3753, 59.6663],
              [28.4275, 59.8134],
              [28.846, 59.7902],
              [29.1614, 60.0026],
              [29.8042, 59.9401],
              [29.9483, 60.0297],
              [29.701, 60.2005],
              [29.0923, 60.178],
              [28.6067, 60.3895],
              [28.7219, 60.7212],
              [28.4006, 60.6653],
              [28.2158, 60.5476],
              [27.8079, 60.553],
              [28.7976, 61.0968],
              [29.2032, 61.2459],
              [29.7791, 61.5931],
              [30.635, 62.184],
              [31.2222, 62.4917],
              [31.5695, 62.9059],
              [31.2257, 63.196],
              [30.8209, 63.3757],
              [30.4887, 63.4621],
              [29.9808, 63.7415],
              [30.2015, 63.7926],
              [30.5093, 63.9914],
              [30.5585, 64.2197],
              [30.0931, 64.3612],
              [29.9658, 64.5786],
              [30.1705, 64.6817],
              [29.7803, 64.7956],
              [29.5881, 64.9914],
              [29.8205, 65.0937],
              [29.7201, 65.3291],
              [29.7029, 65.6294],
              [30.1117, 65.712],
              [29.9002, 66.1081],
              [29.5932, 66.3459],
              [29.5245, 66.4912],
              [29.0892, 66.8375],
              [29.1001, 66.9912],
              [29.5198, 67.2982],
              [29.9162, 67.5081],
              [30.0094, 67.6858],
              [29.6512, 67.8236],
              [29.2987, 68.0818],
              [28.6638, 68.204],
              [28.4474, 68.5149],
              [28.8015, 68.8355],
              [28.4562, 68.9001],
              [28.9541, 69.0273],
              [29.2405, 69.1151],
              [29.3345, 69.2774],
              [29.9007, 69.3582],
              [30.5117, 69.5363],
              [30.853, 69.5274],
              [30.841, 69.8058],
              [31.409, 69.7158],
              [32.0545, 69.9638],
              [32.5232, 69.8032],
              [32.9196, 69.78],
              [33.119, 69.6611],
              [32.687, 69.5028],
              [33.5237, 69.4283],
              [33.8521, 69.3389],
              [34.6618, 69.2495],
              [35.3303, 69.2761],
              [36.2495, 69.111],
              [37.4639, 68.7424],
              [37.6805, 68.7134],
              [38.4346, 68.3559],
              [38.6629, 68.3793],
              [39.5208, 68.076],
              [39.8594, 68.0576],
              [40.8006, 67.7282],
              [41.0166, 67.6979],
              [40.9993, 67.4675],
              [41.1089, 67.2538],
              [41.3384, 67.1711],
              [41.36, 67.0136],
              [41.1781, 66.8096],
              [40.4443, 66.4055],
              [40.085, 66.2888],
              [39.3436, 66.1347],
              [38.5544, 66.055],
              [37.9271, 66.0888],
              [36.8874, 66.2852],
              [36.4063, 66.2947],
              [35.3806, 66.4179],
              [34.8877, 66.5998],
              [34.4343, 66.5473],
              [34.284, 66.6724],
              [33.4832, 66.7304],
              [32.8433, 66.9763],
              [32.8539, 67.0745],
              [32.3826, 67.1597],
              [32.4783, 66.91],
              [32.891, 66.7816],
              [33.0348, 66.5916],
              [33.3548, 66.4802],
              [33.7046, 66.4438],
              [33.6199, 66.3093],
              [34.4968, 66.1162],
              [34.8662, 65.8779],
              [34.7766, 65.5966],
              [34.369, 65.3918],
              [34.8161, 65.0434],
              [34.9421, 64.8576],
              [34.798, 64.7563],
              [34.9776, 64.6119],
              [34.9556, 64.4522],
              [35.4105, 64.2868],
              [35.8108, 64.342],
              [36.2922, 64.0097],
              [36.7035, 63.9246],
              [37.1694, 63.8951],
              [37.291, 63.8182],
              [38.0018, 63.9555],
              [38.0858, 64.059],
              [37.9142, 64.2204],
              [38.0029, 64.317],
              [37.6871, 64.4309],
              [37.3394, 64.3404],
              [37.1215, 64.3947],
              [36.7441, 64.681],
              [36.5598, 64.7174],
              [36.5686, 64.9025],
              [36.8074, 64.9591],
              [37.0077, 65.173],
              [37.706, 65.0174],
              [38.0464, 64.8537],
              [39.3031, 64.6462],
              [39.5665, 64.5453],
              [40.0628, 64.6219],
              [40.5285, 64.5779],
              [40.3781, 64.9225],
              [40.114, 65.0747],
              [39.7121, 65.4018],
              [39.8269, 65.607],
              [40.2798, 65.73],
              [40.7959, 65.9917],
              [41.3425, 66.0629],
              [41.8576, 66.2963],
              [42.2009, 66.5322],
              [42.7492, 66.3951],
              [43.2522, 66.4368],
              [44.0457, 66.081],
              [44.1239, 66.3151],
              [44.4878, 66.6762],
              [44.364, 66.7823],
              [44.4993, 66.9215],
              [44.2063, 67.1247],
              [43.7614, 67.2211],
              [44.003, 67.576],
              [44.2444, 68.2557],
              [44.1602, 68.3362],
              [43.3257, 68.6137],
              [43.4773, 68.6691],
              [43.9845, 68.5513],
              [45.3064, 68.5453],
              [45.9439, 68.4462],
              [46.0293, 68.324],
              [46.5349, 68.1326],
              [46.722, 67.8449],
              [45.3504, 67.7304],
              [45.3146, 67.5921],
              [45.0282, 67.5319],
              [44.9517, 67.3207],
              [45.5939, 67.1582],
              [45.8532, 66.887],
              [46.0415, 66.8309],
              [46.489, 66.8785],
              [46.9269, 66.845],
              [47.7256, 67.0009],
              [47.6951, 67.2047],
              [47.9479, 67.4443],
              [47.8247, 67.5603],
              [48.2557, 67.6827],
              [48.925, 67.6877],
              [49.1333, 67.8626],
              [49.6546, 67.981],
              [50.4347, 68.2205],
              [51.5606, 68.4831],
              [52.0601, 68.5438],
              [52.402, 68.3377],
              [52.7313, 68.4742],
              [52.4988, 68.6195],
              [52.8065, 68.768],
              [53.437, 68.9182],
              [54.1038, 69.0007],
              [53.7205, 68.6569],
              [53.9319, 68.391],
              [53.3191, 68.348],
              [53.2389, 68.2535],
              [54.2398, 68.2113],
              [54.4651, 68.3042],
              [54.864, 68.1851],
              [54.9367, 68.3984],
              [55.3929, 68.5643],
              [56.6296, 68.642],
              [56.8484, 68.5716],
              [57.3298, 68.5648],
              [57.604, 68.7424],
              [57.9779, 68.853],
              [58.8788, 69.0031],
              [59.4155, 68.7667],
              [59.0564, 68.6195],
              [59.1095, 68.4047],
              [59.6748, 68.3362],
              [59.9639, 68.466],
              [59.7736, 68.6682],
              [60.4666, 68.7223],
              [60.9383, 68.9633],
              [60.8438, 69.1575],
              [60.5823, 69.1496],
              [60.5192, 69.3321],
              [60.1388, 69.5779],
              [60.6536, 69.6728],
              [60.7703, 69.8491],
              [62.1611, 69.7462],
              [63.4105, 69.6713],
              [64.548, 69.4517],
              [64.8558, 69.2954],
              [65.8172, 69.1426],
              [67.0555, 68.7814],
              [67.9046, 68.4704],
              [68.1349, 68.4234],
              [68.3828, 68.2285],
              [68.9235, 68.6373],
              [69.1091, 68.879],
              [68.4872, 68.9702],
              [68.1253, 69.2224],
              [67.9691, 69.4913],
              [67.6291, 69.5908],
              [66.771, 69.7407],
              [66.8742, 69.9959],
              [67.3276, 70.106],
              [67.0942, 70.2209],
              [67.2765, 70.4812],
              [67.3179, 70.7841],
              [66.6082, 70.8807],
              [66.6455, 71.0798],
              [66.927, 71.2984],
              [67.3093, 71.3512],
              [67.9473, 71.5276],
              [68.4789, 71.8362],
              [68.6437, 72.0332],
              [68.9891, 72.7059],
              [69.2766, 72.8408],
              [70.1721, 72.9001],
              [71.5632, 72.916],
              [72.4961, 72.7877],
              [72.7307, 72.6174],
              [72.8649, 72.2717],
              [72.3508, 71.8642],
              [72.2093, 71.6147],
              [71.8381, 71.5324],
              [72.1057, 71.2904],
              [72.6707, 71.109],
              [72.8274, 70.8876],
              [72.6882, 70.6344],
              [72.7713, 70.4252],
              [72.4595, 70.3044],
              [72.6216, 70.1617],
              [72.492, 70.0412],
              [72.6917, 69.8369],
              [72.5017, 69.6467],
              [72.6477, 69.2922],
              [72.4893, 69.1992],
              [72.5774, 68.9404],
              [72.8518, 68.7978],
              [73.5391, 68.581],
              [73.6444, 68.5019],
              [73.0908, 68.2143],
              [73.2046, 67.8659],
              [72.7909, 67.6257],
              [72.4059, 67.48],
              [72.2313, 67.1692],
              [71.722, 66.9153],
              [71.4956, 66.9268],
              [71.5769, 66.6588],
              [71.0835, 66.6321],
              [70.9803, 66.5359],
              [70.4133, 66.5632],
              [69.9362, 66.863],
              [69.598, 66.789],
              [69.1307, 66.8307],
              [69.1126, 66.6188],
              [69.3865, 66.5134],
              [70.3626, 66.3344],
              [71.1455, 66.3656],
              [71.6787, 66.3236],
              [71.9901, 66.2308],
              [72.3473, 66.3064],
              [72.4937, 66.6188],
              [72.9424, 66.6567],
              [73.0031, 66.7394],
              [73.4924, 66.8234],
              [73.8713, 67.0063],
              [73.8996, 67.2962],
              [74.0983, 67.4319],
              [74.7303, 67.6863],
              [74.825, 67.9405],
              [74.7014, 68.1807],
              [74.3316, 68.3856],
              [74.4544, 68.694],
              [75.1907, 68.8748],
              [76.3757, 68.994],
              [76.6091, 68.966],
              [76.6626, 68.7626],
              [77.3159, 68.5306],
              [77.167, 68.2818],
              [77.3566, 68.2329],
              [77.1203, 67.7685],
              [78.047, 67.5435],
              [78.1135, 67.657],
              [77.4595, 67.7863],
              [77.537, 68.1286],
              [78.1033, 68.2484],
              [77.9563, 68.4954],
              [77.7151, 68.621],
              [77.6826, 68.8858],
              [76.929, 69.0093],
              [76.7549, 69.1247],
              [76.042, 69.2391],
              [75.5086, 69.2614],
              [74.7151, 69.085],
              [73.7518, 69.1835],
              [73.8996, 69.414],
              [73.516, 69.7464],
              [73.6599, 69.8597],
              [73.7345, 70.1809],
              [74.3162, 70.5203],
              [74.3179, 70.6678],
              [73.9522, 70.8368],
              [73.5562, 71.2551],
              [73.0374, 71.441],
              [73.4818, 71.6527],
              [73.5259, 71.8222],
              [74.3316, 71.9557],
              [74.9284, 72.0981],
              [75.1111, 72.3464],
              [74.9995, 72.6739],
              [75.3189, 72.8192],
              [75.702, 72.547],
              [75.572, 72.4667],
              [75.7212, 72.2496],
              [75.2868, 71.9599],
              [75.2632, 71.7435],
              [75.5329, 71.5423],
              [75.3426, 71.3349],
              [76.0173, 71.237],
              [77.7827, 71.1425],
              [78.0017, 70.9636],
              [78.6138, 70.9626],
              [78.279, 71.2589],
              [77.6709, 71.2861],
              [76.3255, 71.5595],
              [76.0267, 71.9238],
              [76.9461, 72.0443],
              [77.5846, 71.8311],
              [78.1095, 71.8804],
              [78.2036, 72.001],
              [77.7414, 72.2162],
              [78.0876, 72.3588],
              [78.4976, 72.4082],
              [79.3914, 72.3856],
              [81.7367, 71.6999],
              [82.8674, 71.7864],
              [83.3205, 71.6833],
              [82.8201, 71.3913],
              [82.2966, 71.2873],
              [82.2031, 71.0163],
              [83.1338, 70.8824],
              [83.0105, 70.4266],
              [82.6641, 70.2602],
              [82.8831, 70.089],
              [83.183, 70.1389],
              [82.9517, 70.3251],
              [83.4839, 70.335],
              [83.7747, 70.4766],
              [83.3818, 71.0327],
              [83.1476, 71.2448],
              [83.6282, 71.6109],
              [83.3605, 71.8406],
              [82.787, 71.9055],
              [82.1851, 72.0935],
              [82.1981, 72.2816],
              [81.2082, 72.3549],
              [80.7041, 72.5454],
              [80.6321, 72.7338],
              [80.842, 72.9221],
              [80.5062, 73.0784],
              [80.5283, 73.2815],
              [80.2727, 73.3098],
              [80.8322, 73.5718],
              [81.9569, 73.6627],
              [83.4837, 73.6555],
              [84.7932, 73.7647],
              [85.427, 73.7119],
              [85.6043, 73.8219],
              [86.7832, 73.9022],
              [87.0108, 73.7787],
              [86.2036, 73.5578],
              [87.1648, 73.6114],
              [87.2401, 73.8608],
              [86.803, 74.184],
              [86.02, 74.2625],
              [86.4753, 74.4582],
              [86.6392, 74.6973],
              [87.0817, 74.773],
              [87.3543, 75.0439],
              [88.3145, 75.1809],
              [88.6117, 75.3442],
              [89.1323, 75.4528],
              [89.7227, 75.4515],
              [89.9541, 75.5776],
              [90.8635, 75.6683],
              [91.2913, 75.629],
              [92.0078, 75.7258],
              [93.5498, 75.853],
              [93.6858, 76.1323],
              [94.7385, 76.1156],
              [95.3898, 76.164],
              [96.1105, 76.1107],
              [96.8306, 75.9154],
              [98.5762, 76.2316],
              [99.2615, 76.1292],
              [99.5558, 76.279],
              [99.0662, 76.3531],
              [99.5086, 76.4687],
              [101.036, 76.5202],
              [101.2346, 76.7673],
              [100.8641, 76.838],
              [100.9193, 76.9759],
              [102.1577, 77.4023],
              [104.0233, 77.7331],
              [104.6782, 77.7095],
              [105.9836, 77.4383],
              [105.4558, 77.2405],
              [105.797, 77.1126],
              [106.5, 77.0216],
              [107.2505, 77.007],
              [107.0803, 76.8083],
              [106.6123, 76.5596],
              [107.5916, 76.505],
              [107.9846, 76.6227],
              [109.3325, 76.7585],
              [110.0614, 76.6916],
              [110.3586, 76.7557],
              [111.5554, 76.6841],
              [112.6709, 76.3631],
              [113.2593, 76.2664],
              [113.5212, 75.9306],
              [113.8901, 75.854],
              [113.3368, 75.5351],
              [113.7082, 75.4634],
              [113.6052, 75.285],
              [112.8796, 74.9929],
              [112.1468, 74.8784],
              [111.7438, 74.6632],
              [111.4658, 74.6973],
              [110.9602, 74.5485],
              [109.9995, 74.3737],
              [109.8901, 74.197],
              [108.9961, 74.0156],
              [108.4917, 73.7725],
              [107.7769, 73.6166],
              [107.1336, 73.5302],
              [106.648, 73.3067],
              [106.2324, 73.3228],
              [105.4826, 72.7681],
              [106.2546, 72.9672],
              [106.3599, 73.1943],
              [107.7223, 73.1685],
              [108.3933, 73.3173],
              [109.7139, 73.4526],
              [110.8986, 73.6859],
              [109.5311, 73.7753],
              [110.0215, 74.0145],
              [110.5808, 73.9914],
              [111.8037, 73.7442],
              [112.3718, 73.7053],
              [112.8215, 73.7468],
              [113.3955, 73.6858],
              [113.1314, 73.4497],
              [114.6747, 73.5926],
              [115.4131, 73.7104],
              [116.6029, 73.6748],
              [117.4439, 73.5888],
              [118.4502, 73.5888],
              [118.3533, 73.2824],
              [118.9177, 73.1195],
              [119.7834, 73.018],
              [121.0547, 72.9318],
              [121.9637, 72.9637],
              [122.6138, 73.031],
              [123.138, 72.918],
              [123.5535, 73.2176],
              [123.2461, 73.5459],
              [124.3362, 73.8048],
              [125.2124, 73.6489],
              [125.6519, 73.5255],
              [126.2266, 73.5546],
              [126.3888, 73.4118],
              [126.8967, 73.5393],
              [127.9876, 73.4709],
              [128.4393, 73.2363],
              [128.8926, 73.217],
              [129.4435, 73.0289],
              [128.2908, 72.9086],
              [128.228, 72.8195],
              [128.3556, 72.7939],
              [127.9606, 72.6621],
              [128.6113, 72.4827],
              [129.1174, 72.4815],
              [129.5217, 72.2494],
              [129.1458, 72.1003],
              [128.1921, 72.2283],
              [128.9266, 71.7388],
              [128.8753, 71.5967],
              [129.2487, 71.5499],
              [129.3921, 71.3468],
              [129.7627, 71.1476],
              [130.3006, 70.9401],
              [130.8794, 70.7739],
              [131.2263, 70.7502],
              [131.5462, 70.8871],
              [131.9492, 71.293],
              [132.2642, 71.682],
              [132.5393, 71.8836],
              [132.8131, 71.8315],
              [133.1516, 71.5792],
              [133.6501, 71.4365],
              [134.1677, 71.3708],
              [134.7785, 71.4288],
              [135.033, 71.5391],
              [135.9851, 71.6348],
              [136.7746, 71.5234],
              [137.3556, 71.3742],
              [137.7664, 71.168],
              [138.0095, 71.3393],
              [138.0386, 71.5587],
              [138.756, 71.643],
              [139.7178, 71.4573],
              [140.001, 71.525],
              [139.7345, 71.6722],
              [139.5739, 71.9329],
              [139.8742, 72.1782],
              [139.1358, 72.3041],
              [139.5418, 72.4976],
              [140.0728, 72.4792],
              [140.8778, 72.5377],
              [141.013, 72.6908],
              [140.7258, 72.8986],
              [141.5506, 72.7743],
              [145.4095, 72.5559],
              [146.1247, 72.4699],
              [146.792, 72.3307],
              [145.332, 72.3542],
              [145.0687, 72.2643],
              [146.9292, 72.3126],
              [146.4954, 72.066],
              [145.8494, 72.1622],
              [145.7476, 71.9099],
              [145.216, 71.8433],
              [144.9063, 71.6989],
              [145.3438, 71.6633],
              [146.1565, 71.8207],
              [147.1956, 72.3299],
              [148.402, 72.3109],
              [149.6113, 72.1484],
              [150.0669, 71.9219],
              [149.7095, 71.7729],
              [149.408, 71.8967],
              [149.0394, 71.7646],
              [149.1829, 71.6888],
              [149.7725, 71.6479],
              [149.968, 71.5347],
              [150.6702, 71.4664],
              [150.8587, 71.3742],
              [151.4924, 71.3332],
              [152.1035, 71.0501],
              [151.9691, 70.9448],
              [152.613, 70.8346],
              [153.9304, 70.8941],
              [154.2388, 70.965],
              [155.9364, 71.0994],
              [157.5858, 71.0749],
              [158.5236, 70.9688],
              [159.2378, 70.8293],
              [159.6895, 70.671],
              [160.0249, 70.4194],
              [160.1063, 70.2637],
              [159.689, 69.8908],
              [159.8011, 69.807],
              [160.384, 69.6749],
              [160.9255, 69.6421],
              [161.0249, 69.2837],
              [160.9629, 69.1055],
              [161.5657, 69.0149],
              [161.3993, 69.2213],
              [161.4358, 69.3799],
              [162.0256, 69.5512],
              [162.2097, 69.6559],
              [162.7688, 69.6542],
              [163.165, 69.7209],
              [163.773, 69.6944],
              [164.027, 69.7738],
              [164.4391, 69.6208],
              [164.8343, 69.5674],
              [165.6747, 69.596],
              [166.0752, 69.5233],
              [166.9387, 69.5021],
              [167.6741, 69.7719],
              [168.241, 69.5583],
              [168.2883, 69.2427],
              [168.8506, 69.1881],
              [169.4198, 69.0236],
              [169.6255, 68.7772],
              [170.3511, 68.8281],
              [171.0276, 69.0548],
              [170.9131, 69.3041],
              [170.5842, 69.5996],
              [170.5227, 69.9385],
              [170.6109, 70.1163],
              [171.9945, 69.9931],
              [172.7908, 69.9627],
              [173.3684, 69.79],
              [173.4608, 69.9486],
              [174.0708, 69.8869],
              [175.0884, 69.8457],
              [175.5001, 69.8735],
              [175.9262, 69.7757],
              [176.5039, 69.7541],
              [176.7345, 69.6748],
              [177.3579, 69.6172],
              [178.2122, 69.4434],
              [178.7726, 69.4152],
              [180, 68.9811],
              [180, 68.5596],
              [180, 68.0609],
              [180, 67.5623],
              [180, 67.0637],
              [180, 66.5651],
              [180, 66.0665],
              [180, 65.5679],
              [180, 65.0693],
              [180, 65.0662],
              [179.7796, 65.0182],
              [179.4787, 64.8227],
              [179.25, 64.8112],
              [178.758, 64.6409],
              [177.7442, 64.7064],
              [177.2977, 64.8384],
              [176.2101, 64.874],
              [176.7274, 64.5792],
              [176.9859, 64.7111],
              [177.4919, 64.7675],
              [177.3643, 64.5632],
              [177.4536, 64.4206],
              [178, 64.2104],
              [178.3999, 64.2434],
              [178.4683, 63.9926],
              [178.6939, 63.9105],
              [178.7629, 63.6428],
              [178.5955, 63.4113],
              [179.3348, 63.1965],
              [179.2495, 62.9847],
              [179.5407, 62.8489],
              [179.6091, 62.7056],
              [179.3836, 62.5208],
              [179.1488, 62.496],
              [179.1504, 62.3419],
              [178.4894, 62.4368],
              [178.061, 62.5411],
              [177.3068, 62.5821],
              [177.3342, 62.6882],
              [176.931, 62.6944],
              [177.0149, 62.5505],
              [176.5942, 62.4858],
              [176.2793, 62.3171],
              [175.6335, 62.1913],
              [175.1745, 62.0219],
              [174.8151, 61.947],
              [174.4898, 61.8146],
              [174.138, 61.8446],
              [173.991, 61.7145],
              [173.5446, 61.7491],
              [173.4971, 61.5713],
              [172.9313, 61.3515],
              [172.4761, 61.0557],
              [172.2427, 61.0198],
              [172.0068, 60.8552],
              [171.4065, 60.7505],
              [171.1795, 60.5589],
              [170.5807, 60.4339],
              [170.4006, 59.9656],
              [170.2651, 59.9204],
              [169.9558, 60.0592],
              [169.7078, 60.3995],
              [169.1665, 60.5673],
              [168.0964, 60.5715],
              [167.0481, 60.3284],
              [166.2878, 59.8445],
              [166.1095, 59.8275],
              [166.2497, 60.3835],
              [166.0523, 60.4538],
              [165.7214, 60.2697],
              [165.1296, 60.0842],
              [165.1986, 60.0052],
              [165.0135, 59.848],
              [164.4885, 60.111],
              [164.3391, 59.9915],
              [163.6515, 60.0517],
              [163.698, 59.904],
              [163.3782, 59.8144],
              [163.3901, 59.6315],
              [163.1687, 59.5106],
              [163.3125, 59.2875],
              [163.0806, 59.2713],
              [162.8866, 59.1135],
              [163.0685, 58.9681],
              [162.8162, 58.8872],
              [162.4053, 58.6544],
              [162.1699, 58.4059],
              [161.9932, 58.0874],
              [162.0256, 57.9288],
              [162.4555, 57.7844],
              [162.5298, 57.9365],
              [162.7116, 57.9667],
              [163.3295, 57.7089],
              [163.0748, 57.461],
              [162.8331, 57.3833],
              [162.7859, 57.1455],
              [162.8599, 57.0514],
              [162.7835, 56.763],
              [163.2806, 56.688],
              [163.241, 56.5932],
              [163.3661, 56.1807],
              [162.8216, 56.0562],
              [162.638, 56.2208],
              [162.7402, 56.4798],
              [162.4012, 56.3698],
              [162.5925, 56.2685],
              [162.1421, 56.1291],
              [161.7771, 55.6083],
              [161.7477, 55.376],
              [161.813, 55.1792],
              [162.1532, 54.8499],
              [162.1301, 54.7724],
              [161.7532, 54.5198],
              [161.3119, 54.4901],
              [161.0664, 54.5968],
              [160.7412, 54.5352],
              [160.053, 54.1757],
              [159.8441, 53.7969],
              [159.9692, 53.5703],
              [159.9224, 53.2836],
              [159.6325, 53.2571],
              [159.0033, 53.0768],
              [158.5156, 52.7463],
              [158.5477, 52.296],
              [158.2869, 51.9675],
              [157.7592, 51.544],
              [157.5369, 51.4861],
              [157.4465, 51.3479],
              [157.0459, 51.1236],
              [156.7197, 50.8865],
              [156.7219, 51.1955],
              [156.5355, 51.2893],
              [156.4802, 51.5165],
              [156.5012, 51.7751],
              [156.4392, 52.216],
              [156.3728, 52.4046],
              [156.4319, 52.5246],
              [156.2657, 52.5982],
              [156.1112, 52.9354],
              [155.9358, 53.8652],
              [155.728, 54.4268],
              [155.61, 54.8949],
              [155.5512, 55.2842],
              [155.6862, 56.0391],
              [155.756, 56.1067],
              [155.9243, 56.5845],
              [156.1253, 56.832],
              [156.3856, 56.9118],
              [156.8316, 57.2135],
              [157.0013, 57.4255],
              [156.9307, 57.6584],
              [156.801, 57.7734],
              [156.9815, 57.8571],
              [157.2419, 57.7705],
              [157.4699, 57.8039],
              [157.7232, 58.0246],
              [158.168, 58.0207],
              [158.6548, 58.2694],
              [159.0238, 58.4148],
              [159.7507, 58.9256],
              [159.8644, 59.1406],
              [160.3103, 59.3461],
              [160.4973, 59.5525],
              [160.8049, 59.6054],
              [161.2839, 59.8705],
              [161.5328, 60.0676],
              [161.8972, 60.203],
              [161.9386, 60.4168],
              [162.2062, 60.538],
              [162.7537, 60.6459],
              [162.889, 60.7525],
              [163.3457, 60.7993],
              [163.7743, 60.9185],
              [163.4873, 61.0017],
              [164.0288, 61.3466],
              [163.7595, 61.479],
              [163.8661, 61.6465],
              [164.0448, 61.6834],
              [164.1272, 62.2624],
              [164.5383, 62.4511],
              [164.9884, 62.403],
              [164.5979, 62.6731],
              [164.0551, 62.6561],
              [163.2671, 62.522],
              [163.2315, 62.3114],
              [163.0915, 62.2266],
              [163.1478, 62.0376],
              [162.9784, 61.8095],
              [163.3265, 61.6579],
              [162.4172, 61.6758],
              [161.9864, 61.3726],
              [161.5956, 61.2552],
              [161.1836, 60.9926],
              [160.9151, 60.8996],
              [160.8285, 60.7547],
              [160.3818, 60.7016],
              [160.1701, 60.8249],
              [160.4249, 60.9837],
              [160.0001, 61.1061],
              [159.9652, 61.378],
              [160.414, 61.7648],
              [160.2952, 61.9399],
              [159.9667, 61.7588],
              [159.5772, 61.6528],
              [159.3599, 61.893],
              [158.9207, 61.903],
              [158.853, 61.8263],
              [158.2602, 61.8259],
              [158.0802, 61.734],
              [157.4645, 61.7821],
              [156.6795, 61.524],
              [156.5469, 61.2092],
              [156.0076, 60.9496],
              [155.9207, 60.7548],
              [154.9524, 60.3674],
              [154.5637, 60.0937],
              [154.4878, 59.8963],
              [154.2208, 59.8696],
              [154.2762, 59.6501],
              [154.4288, 59.547],
              [154.9629, 59.4961],
              [155.2097, 59.3439],
              [155.1941, 59.1758],
              [154.5016, 59.2179],
              [154.3577, 59.1106],
              [154.0487, 59.0528],
              [153.802, 59.1723],
              [153.3692, 59.2413],
              [153.3373, 59.0899],
              [153.0901, 59.0831],
              [152.916, 58.9166],
              [152.5567, 58.95],
              [152.4048, 59.0234],
              [152.0203, 58.875],
              [151.3591, 58.856],
              [151.2165, 59.0917],
              [151.4377, 59.1703],
              [152.1094, 59.1558],
              [152.1367, 59.2962],
              [151.7603, 59.2993],
              [151.6376, 59.4825],
              [151.3221, 59.5559],
              [150.9371, 59.5559],
              [150.8431, 59.4484],
              [150.4568, 59.6126],
              [149.5977, 59.7582],
              [149.1329, 59.6407],
              [148.9925, 59.3561],
              [148.7879, 59.2365],
              [148.4375, 59.2623],
              [148.3937, 59.3793],
              [147.9138, 59.3905],
              [147.5911, 59.3041],
              [146.7196, 59.3819],
              [146.4396, 59.4604],
              [146.3122, 59.2788],
              [146.0503, 59.1389],
              [145.6753, 59.4235],
              [145.0008, 59.3696],
              [143.8405, 59.4025],
              [143.4936, 59.3185],
              [143.1597, 59.3539],
              [142.2624, 59.1246],
              [141.9468, 58.943],
              [141.5837, 58.6177],
              [140.9043, 58.3828],
              [140.6668, 58.1911],
              [140.4957, 57.8448],
              [139.9815, 57.6877],
              [139.7897, 57.4827],
              [139.5631, 57.4301],
              [139.4185, 57.2972],
              [139.1965, 57.2727],
              [138.6616, 56.9779],
              [138.1148, 56.5281],
              [138.2235, 56.4242],
              [137.9141, 56.3595],
              [137.8344, 56.2255],
              [137.572, 56.1165],
              [136.7733, 55.6605],
              [136.5655, 55.6232],
              [136.319, 55.4866],
              [136.2198, 55.3546],
              [135.8689, 55.1739],
              [135.5343, 55.0866],
              [135.2196, 54.8941],
              [135.2651, 54.7217],
              [135.6541, 54.666],
              [135.7502, 54.5709],
              [136.2493, 54.6204],
              [136.8833, 54.5914],
              [136.7386, 54.4138],
              [136.8206, 54.3161],
              [136.6847, 53.8085],
              [137.1857, 53.8511],
              [137.2909, 54.0357],
              [137.0614, 54.1457],
              [137.4514, 54.3176],
              [137.6846, 54.2766],
              [137.4854, 54.1121],
              [137.5969, 53.8242],
              [137.3351, 53.5294],
              [137.9182, 53.5866],
              [138.2982, 53.7562],
              [138.2468, 53.5328],
              [138.5021, 53.5435],
              [138.6558, 53.7318],
              [138.7606, 53.9752],
              [138.7385, 54.316],
              [139.1619, 54.2047],
              [139.3718, 54.1959],
              [139.797, 54.2974],
              [139.8159, 54.2152],
              [140.2406, 54.057],
              [140.252, 53.8906],
              [140.5398, 53.6717],
              [140.9497, 53.489],
              [141.433, 53.1489],
              [141.1888, 52.9875],
              [141.2806, 52.5788],
              [141.1672, 52.3649],
              [141.5148, 52.1719],
              [141.3089, 52.0371],
              [141.4295, 51.942],
              [141.2002, 51.8237],
              [141.1092, 51.6788],
              [140.9056, 51.6154],
              [140.896, 51.4288],
              [140.7057, 51.3348],
              [140.6567, 50.9378],
              [140.5422, 50.8771],
              [140.4429, 50.5444],
              [140.5454, 50.3441],
              [140.523, 50.1511],
              [140.6607, 50.058],
              [140.4158, 49.8627],
              [140.5422, 49.7568],
              [140.5608, 49.5754],
              [140.3438, 49.2184],
              [140.3768, 49.1435],
              [140.1858, 48.6501],
              [140.165, 48.4494],
              [139.3905, 47.9042],
              [138.959, 47.3134],
              [138.8907, 47.3052],
              [138.5348, 46.9866],
              [138.2024, 46.4543],
              [138.1004, 46.228],
              [137.7821, 45.946],
              [137.6715, 45.7946],
              [137.3967, 45.6239],
              [137.1445, 45.3747],
              [136.8255, 45.2041],
              [136.5535, 44.895],
              [136.3648, 44.7925],
              [136.1226, 44.4755],
              [135.8887, 44.4015],
              [135.6559, 44.1687],
              [135.4879, 43.8327],
              [135.2268, 43.6822],
              [135.1399, 43.5084],
              [134.9747, 43.4595],
              [134.5872, 43.229],
              [134.1937, 43.0798],
              [133.9268, 42.8827],
              [133.605, 42.8463],
              [133.1577, 42.6884],
              [132.9963, 42.8044],
              [132.7737, 42.8751],
              [132.5779, 42.8404],
              [132.4044, 42.9423],
              [132.3562, 43.286],
              [131.9522, 43.072],
              [131.7505, 43.2253],
              [131.5177, 43.0093],
              [131.4717, 42.8732],
              [131.1274, 42.6468],
              [130.6567, 42.6641],
              [130.8585, 42.5246],
              [130.7, 42.2951],
            ],
          ],
          [
            [
              [102.9299, 79.2757],
              [103.3491, 79.1164],
              [103.9891, 79.1308],
              [104.4992, 78.8638],
              [105.1751, 78.8093],
              [105.4079, 78.5662],
              [104.7874, 78.3364],
              [102.8657, 78.2191],
              [101.2098, 78.1935],
              [100.0373, 77.9616],
              [99.2683, 78.0416],
              [100.0497, 78.3432],
              [100.2546, 78.6654],
              [101.0271, 79.0314],
              [101.3099, 79.2388],
              [101.9646, 79.2923],
              [102.9299, 79.2757],
            ],
          ],
          [
            [
              [90.9021, 80.0736],
              [92.4243, 80.0307],
              [93.7239, 79.9273],
              [93.6699, 79.8317],
              [92.6248, 79.6728],
              [92.0995, 79.8099],
              [91.2624, 79.903],
              [90.9021, 80.0736],
            ],
          ],
          [
            [
              [97.7743, 80.1314],
              [98.5779, 79.9826],
              [99.199, 80.05],
              [100.0225, 79.8236],
              [99.5706, 79.2722],
              [99.9466, 78.9701],
              [99.4056, 78.8225],
              [97.7608, 78.8088],
              [96.7985, 78.9892],
              [95.0182, 79.0385],
              [94.3645, 79.2369],
              [94.3123, 79.4792],
              [93.7489, 79.5439],
              [94.2883, 79.8986],
              [94.9356, 80.0713],
              [97.7743, 80.1314],
            ],
          ],
          [
            [
              [57.0977, 80.3452],
              [56.9568, 80.0663],
              [55.9486, 80.0784],
              [55.9893, 80.3367],
              [57.0977, 80.3452],
            ],
          ],
          [
            [
              [53.3411, 80.399],
              [53.4899, 80.1718],
              [52.645, 80.1723],
              [52.8595, 80.4055],
              [53.3411, 80.399],
            ],
          ],
          [
            [
              [59.2643, 80.3432],
              [57.2197, 80.2413],
              [57.2744, 80.3915],
              [58.4524, 80.4681],
              [59.2643, 80.3432],
            ],
          ],
          [
            [
              [47.8655, 80.8121],
              [46.9924, 80.5527],
              [46.084, 80.4399],
              [45.0947, 80.6492],
              [47.8655, 80.8121],
            ],
          ],
          [
            [
              [62.2556, 80.7923],
              [62.0374, 80.6658],
              [61.0446, 80.4052],
              [60.3074, 80.4956],
              [59.6364, 80.4312],
              [59.2293, 80.6441],
              [59.6939, 80.8371],
              [60.564, 80.8228],
              [62.0677, 80.8799],
              [62.2556, 80.7923],
            ],
          ],
          [
            [
              [49.9973, 80.8651],
              [50.7243, 80.8955],
              [51.7009, 80.6769],
              [50.9496, 80.5368],
              [49.7278, 80.4943],
              [49.4904, 80.3635],
              [48.6545, 80.317],
              [49.1072, 80.1895],
              [48.4439, 80.1008],
              [47.6331, 80.1178],
              [46.6595, 80.2742],
              [47.428, 80.3227],
              [48.8274, 80.4977],
              [49.631, 80.6396],
              [49.0037, 80.7757],
              [49.9973, 80.8651],
            ],
          ],
          [
            [
              [80.1219, 80.9572],
              [80.3264, 80.8605],
              [79.1276, 80.8067],
              [79.2378, 80.9531],
              [80.1219, 80.9572],
            ],
          ],
          [
            [
              [56.1712, 81.1213],
              [56.7029, 81.1131],
              [58.2824, 80.9265],
              [57.2316, 80.907],
              [56.1712, 81.1213],
            ],
          ],
          [
            [
              [55.5203, 81.0084],
              [56.0192, 81.0436],
              [57.6813, 80.8168],
              [55.7988, 80.8544],
              [55.5203, 81.0084],
            ],
          ],
          [
            [
              [64.7048, 81.2053],
              [65.2298, 81.1367],
              [65.4458, 80.9196],
              [64.4256, 80.7409],
              [63.1307, 80.6831],
              [62.5298, 80.8438],
              [63.1423, 80.9714],
              [64.0365, 80.9869],
              [64.7048, 81.2053],
            ],
          ],
          [
            [
              [91.3328, 81.0808],
              [89.9963, 81.1053],
              [90.0715, 81.2145],
              [91.4548, 81.1926],
              [91.3328, 81.0808],
            ],
          ],
          [
            [
              [95.9675, 81.205],
              [96.973, 80.9061],
              [97.9729, 80.7211],
              [97.1916, 80.6453],
              [97.0208, 80.5259],
              [97.188, 80.2336],
              [95.0339, 80.1383],
              [93.6941, 79.9981],
              [92.5049, 80.1577],
              [91.9185, 80.4684],
              [92.7405, 80.513],
              [92.7188, 80.888],
              [93.0527, 80.9954],
              [95.9675, 81.205],
            ],
          ],
          [
            [
              [56.781, 81.3852],
              [57.6887, 81.3311],
              [56.4312, 81.1745],
              [55.8347, 81.3248],
              [56.781, 81.3852],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Russia",
        NAME_LONG: "Russian Federation",
        ADM0_A3: "RUS",
        ISO_A2: "RU",
        ISO_A3: "RUS",
        WB_A2: "RU",
        WB_A3: "RUS",
      },
      id: 191,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.4718, -1.0668],
            [30.5553, -1.3184],
            [30.7375, -1.4067],
            [30.831, -1.5942],
            [30.8299, -1.9606],
            [30.8878, -2.0825],
            [30.8344, -2.3453],
            [30.5546, -2.4006],
            [30.3786, -2.3031],
            [30.2075, -2.3392],
            [30.1391, -2.4308],
            [29.9317, -2.3169],
            [29.9033, -2.6491],
            [29.6975, -2.8083],
            [29.3397, -2.8264],
            [29.3066, -2.6601],
            [29.1297, -2.597],
            [29.0154, -2.7207],
            [28.8913, -2.6527],
            [28.8588, -2.4182],
            [29.1506, -2.1504],
            [29.1493, -1.7993],
            [29.3585, -1.5099],
            [29.5779, -1.3884],
            [29.8361, -1.3295],
            [29.8979, -1.4696],
            [30.1472, -1.3451],
            [30.3528, -1.0608],
            [30.4718, -1.0668],
          ],
        ],
      },
      properties: {
        NAME: "Rwanda",
        NAME_LONG: "Rwanda",
        ADM0_A3: "RWA",
        ISO_A2: "RW",
        ISO_A3: "RWA",
        WB_A2: "RW",
        WB_A3: "RWA",
      },
      id: 192,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-8.6824, 27.6614],
            [-8.6824, 27.2854],
            [-8.6808, 26.0131],
            [-8.7072, 25.995],
            [-10.5044, 25.995],
            [-12.0153, 25.9949],
            [-12.0153, 25.1633],
            [-12.0153, 23.4952],
            [-12.3539, 23.3225],
            [-12.6194, 23.2708],
            [-13.0152, 23.018],
            [-13.1655, 22.7527],
            [-13.0933, 22.4955],
            [-13.0152, 21.3334],
            [-14.0155, 21.3333],
            [-15.0023, 21.3332],
            [-16.2677, 21.3329],
            [-16.9588, 21.3329],
            [-17.0812, 20.8747],
            [-17.0569, 20.7669],
            [-17.1002, 20.8376],
            [-17.0137, 21.42],
            [-16.73, 21.4698],
            [-15.75, 21.4903],
            [-15.4599, 21.4503],
            [-14.8398, 21.4503],
            [-14.6699, 21.5997],
            [-14.6299, 21.8604],
            [-14.2202, 22.3096],
            [-14.1699, 22.7599],
            [-14.02, 23.4103],
            [-13.8901, 23.6905],
            [-13.77, 23.7901],
            [-13.31, 23.9806],
            [-13.06, 24.4005],
            [-12.4301, 24.8302],
            [-12.31, 25.1104],
            [-12.1699, 25.6397],
            [-12.0298, 26.0304],
            [-11.7172, 26.1036],
            [-11.6362, 26.295],
            [-11.3369, 26.6329],
            [-11.3916, 26.8829],
            [-10.9218, 27.0098],
            [-10.5503, 26.9903],
            [-10.2505, 26.8604],
            [-10.0317, 26.9102],
            [-9.7344, 26.8604],
            [-9.4121, 27.088],
            [-9.0009, 27.0899],
            [-8.7529, 27.1905],
            [-8.817, 27.6615],
            [-8.6824, 27.6614],
          ],
        ],
      },
      properties: {
        NAME: "W. Sahara",
        NAME_LONG: "Western Sahara",
        ADM0_A3: "SAH",
        ISO_A2: "EH",
        ISO_A3: "ESH",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 193,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.5324, 29.0957],
            [47.4341, 28.9946],
            [47.5968, 28.6088],
            [47.6681, 28.5335],
            [48.4328, 28.5405],
            [48.4946, 28.5001],
            [48.6065, 28.1265],
            [48.8779, 27.8368],
            [48.7852, 27.7447],
            [48.8733, 27.6125],
            [49.0889, 27.5511],
            [49.3049, 27.3255],
            [49.4071, 27.1327],
            [49.5164, 27.1884],
            [49.6995, 26.9582],
            [49.9421, 26.8583],
            [50.0306, 26.4946],
            [50.1916, 26.4036],
            [50.216, 26.2151],
            [50.1013, 25.9895],
            [50.2341, 25.6152],
            [50.4629, 25.4469],
            [50.5622, 25.0548],
            [50.8079, 24.7466],
            [50.9789, 24.5677],
            [51.2153, 24.6259],
            [51.3953, 24.4879],
            [51.3064, 24.406],
            [51.5693, 24.2562],
            [51.5935, 24.0783],
            [52.5385, 22.9547],
            [52.5831, 22.9311],
            [54.1343, 22.7403],
            [55.1053, 22.6209],
            [55.1868, 22.7036],
            [55.6267, 22.0238],
            [55.6376, 21.979],
            [55.3029, 20.9715],
            [54.9784, 19.9954],
            [53.63, 19.546],
            [52.7744, 19.2608],
            [51.9786, 18.9956],
            [51.0198, 18.8666],
            [49.7384, 18.6942],
            [49.1288, 18.6121],
            [49.0358, 18.5797],
            [48.1619, 18.1489],
            [47.589, 17.4633],
            [47.4276, 17.0918],
            [47.1909, 16.9589],
            [46.9703, 16.9568],
            [46.7101, 17.2754],
            [46.3226, 17.2241],
            [45.4304, 17.312],
            [45.1654, 17.4284],
            [44.4041, 17.4187],
            [44.1045, 17.352],
            [43.7506, 17.3457],
            [43.5034, 17.5135],
            [43.391, 17.512],
            [43.165, 17.3259],
            [43.1087, 16.927],
            [43.2086, 16.7734],
            [43.0849, 16.6726],
            [43.0363, 16.5363],
            [42.7895, 16.371],
            [42.7373, 16.6711],
            [42.5418, 16.878],
            [42.5442, 17.0043],
            [42.3391, 17.1736],
            [42.3015, 17.4536],
            [42.0935, 17.6521],
            [41.7192, 17.9092],
            [41.4509, 18.356],
            [41.4382, 18.4642],
            [41.2102, 18.6979],
            [41.249, 18.8342],
            [41.1372, 18.9577],
            [40.9559, 19.3433],
            [40.9447, 19.5057],
            [40.7847, 19.5963],
            [40.7909, 19.7141],
            [40.6287, 19.7921],
            [40.5226, 19.9725],
            [40.0676, 20.2865],
            [39.9232, 20.2636],
            [39.6465, 20.4616],
            [39.506, 20.7464],
            [39.2969, 20.928],
            [39.0807, 21.315],
            [39.1711, 21.4041],
            [39.0794, 21.7064],
            [38.9786, 21.8621],
            [39.0404, 22.2358],
            [39.1428, 22.3906],
            [38.9577, 22.775],
            [38.9647, 22.8704],
            [38.8142, 22.9827],
            [38.6002, 23.5682],
            [38.4526, 23.784],
            [38.0791, 24.0704],
            [37.8656, 24.1499],
            [37.6761, 24.2912],
            [37.5227, 24.2677],
            [37.4567, 24.4412],
            [37.1486, 24.851],
            [37.28, 24.9641],
            [37.2305, 25.1937],
            [37.1053, 25.3222],
            [36.9363, 25.6461],
            [36.6634, 25.8557],
            [36.6961, 26.0223],
            [36.5366, 26.0824],
            [36.2298, 26.6256],
            [35.8317, 27.0927],
            [35.8128, 27.1911],
            [35.5596, 27.4556],
            [35.492, 27.6324],
            [35.3228, 27.8763],
            [35.0519, 28.1196],
            [34.9009, 28.0823],
            [34.6267, 28.1605],
            [34.8038, 28.5271],
            [34.7785, 28.6645],
            [34.8525, 29.0451],
            [34.9494, 29.3517],
            [36.0164, 29.19],
            [36.4771, 29.4946],
            [36.7562, 29.8655],
            [37.4702, 29.9946],
            [37.6345, 30.3128],
            [37.9811, 30.4995],
            [37.48, 31.0073],
            [36.9595, 31.491],
            [38.9633, 31.9944],
            [39.1464, 32.1181],
            [40.0293, 31.9944],
            [40.4241, 31.9205],
            [42.0754, 31.0799],
            [42.8587, 30.4947],
            [43.8901, 29.7674],
            [44.6918, 29.2018],
            [45.4349, 29.1362],
            [46.3575, 29.0631],
            [46.5324, 29.0957],
          ],
        ],
      },
      properties: {
        NAME: "Saudi Arabia",
        NAME_LONG: "Saudi Arabia",
        ADM0_A3: "SAU",
        ISO_A2: "SA",
        ISO_A3: "SAU",
        WB_A2: "SA",
        WB_A3: "SAU",
      },
      id: 194,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.8836, 21.9957],
            [36.8968, 21.6779],
            [37.1486, 21.171],
            [37.0867, 21.0571],
            [37.1473, 20.9409],
            [37.228, 20.565],
            [37.1751, 20.3492],
            [37.2249, 20.1195],
            [37.1961, 19.9792],
            [37.2595, 19.8207],
            [37.2383, 19.6553],
            [37.3335, 19.1597],
            [37.4343, 18.8617],
            [37.551, 18.7099],
            [37.6747, 18.741],
            [37.9255, 18.585],
            [38.1517, 18.2493],
            [38.5428, 18.1184],
            [38.6016, 18.0048],
            [38.4593, 17.8907],
            [38.3437, 17.6559],
            [38.1606, 17.5573],
            [38.0154, 17.54],
            [37.872, 17.4373],
            [37.5068, 17.3185],
            [37.4034, 17.0299],
            [37.134, 17.0164],
            [37.0005, 17.0726],
            [36.985, 16.7657],
            [36.8773, 16.5273],
            [36.944, 16.2529],
            [36.8859, 16.1849],
            [36.7595, 15.8375],
            [36.671, 15.7231],
            [36.5249, 15.2479],
            [36.4236, 15.1115],
            [36.5264, 14.2635],
            [36.4366, 13.9912],
            [36.4582, 13.8201],
            [36.3821, 13.571],
            [36.2336, 13.3616],
            [36.1144, 12.9339],
            [36.1236, 12.7214],
            [35.7284, 12.6744],
            [35.6169, 12.5752],
            [35.2156, 11.8966],
            [35.0482, 11.7714],
            [35.0731, 11.549],
            [34.9471, 11.2749],
            [34.9803, 11.1553],
            [34.9143, 10.964],
            [34.9338, 10.8453],
            [34.8385, 10.7291],
            [34.5871, 10.8793],
            [34.4373, 10.7957],
            [34.2795, 10.5655],
            [34.3249, 10.2687],
            [34.0982, 9.6797],
            [34.0707, 9.4546],
            [33.9009, 9.4553],
            [33.9049, 9.7107],
            [33.9691, 9.8383],
            [33.9668, 10.0473],
            [33.9021, 10.192],
            [33.469, 10.5439],
            [33.3706, 10.6509],
            [33.1507, 10.731],
            [33.1821, 10.8432],
            [33.0829, 11.5846],
            [33.1324, 11.6862],
            [33.145, 11.9347],
            [33.2092, 12.2104],
            [32.7318, 12.2162],
            [32.7467, 12.0027],
            [32.0822, 11.9998],
            [32.3452, 11.7091],
            [32.3642, 11.24],
            [32.4141, 11.0509],
            [31.9429, 10.6556],
            [31.8642, 10.4721],
            [31.6648, 10.2142],
            [31.2348, 9.7923],
            [30.7493, 9.7358],
            [30.4846, 9.972],
            [30.013, 10.2705],
            [29.6154, 10.0581],
            [29.6139, 9.9145],
            [29.4831, 9.7617],
            [29.2524, 9.7112],
            [29.0095, 9.6032],
            [28.8287, 9.4275],
            [28.8438, 9.3245],
            [28.0451, 9.3314],
            [27.8951, 9.5954],
            [27.0804, 9.6068],
            [26.6943, 9.4769],
            [26.5569, 9.5205],
            [26.1795, 9.959],
            [25.9009, 10.1911],
            [25.8431, 10.4178],
            [25.4795, 10.3438],
            [25.0841, 10.2932],
            [24.9763, 9.9674],
            [24.7927, 9.7984],
            [24.7944, 9.5322],
            [24.6802, 9.3734],
            [24.661, 9.1823],
            [24.5654, 9.0149],
            [24.5584, 8.8867],
            [24.3801, 8.8442],
            [24.1703, 8.6893],
            [23.7213, 8.702],
            [23.4823, 8.7834],
            [23.4577, 9.1462],
            [23.6322, 9.2776],
            [23.6061, 9.5372],
            [23.674, 9.6903],
            [23.624, 9.9078],
            [23.2912, 10.4397],
            [23.0056, 10.6868],
            [22.8611, 10.9192],
            [22.9565, 11.2088],
            [22.9148, 11.3961],
            [22.7818, 11.3989],
            [22.6281, 11.5098],
            [22.5371, 11.6809],
            [22.5929, 11.9889],
            [22.4814, 12.0443],
            [22.4814, 12.1767],
            [22.3728, 12.4631],
            [22.4457, 12.6111],
            [22.2049, 12.7434],
            [22.0587, 12.6367],
            [21.936, 12.6395],
            [21.8094, 12.7937],
            [21.9351, 13.0592],
            [22.14, 13.1935],
            [22.2676, 13.3346],
            [22.1959, 13.5805],
            [22.0737, 13.7714],
            [22.2435, 13.9754],
            [22.5315, 14.1226],
            [22.441, 14.2499],
            [22.4249, 14.4704],
            [22.4654, 14.6293],
            [22.6663, 14.6817],
            [22.6508, 14.8162],
            [22.7389, 14.9798],
            [22.9657, 15.2239],
            [22.9257, 15.5639],
            [23.0946, 15.7043],
            [23.3206, 15.6813],
            [23.5927, 15.749],
            [23.9844, 15.7212],
            [23.9831, 17.4057],
            [23.9821, 18.567],
            [23.9813, 19.4961],
            [23.9813, 19.9954],
            [24.968, 19.995],
            [24.9808, 20.5004],
            [24.9812, 21.9954],
            [26.3438, 21.9951],
            [27.317, 21.9949],
            [28.2903, 21.9948],
            [29.4582, 21.9946],
            [30.4315, 21.9945],
            [31.2484, 21.9944],
            [31.3595, 22.1881],
            [31.4906, 22.1482],
            [31.4355, 21.9954],
            [33.1811, 21.9954],
            [34.3231, 21.9955],
            [35.4864, 21.9956],
            [36.8836, 21.9957],
          ],
        ],
      },
      properties: {
        NAME: "Sudan",
        NAME_LONG: "Sudan",
        ADM0_A3: "SDN",
        ISO_A2: "SD",
        ISO_A3: "SDN",
        WB_A2: "SD",
        WB_A3: "SDN",
      },
      id: 196,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.0707, 9.4546],
            [34.1119, 8.6266],
            [33.9708, 8.4454],
            [33.6955, 8.3729],
            [33.6001, 8.464],
            [33.1741, 8.4045],
            [33.2069, 8.2407],
            [32.9898, 7.9172],
            [33.0513, 7.8011],
            [33.4623, 7.7495],
            [33.7161, 7.6572],
            [34.0065, 7.4099],
            [34.0311, 7.2554],
            [34.1958, 7.129],
            [34.2976, 6.9686],
            [34.5037, 6.89],
            [34.5247, 6.7529],
            [34.7335, 6.6376],
            [34.8447, 6.2487],
            [34.9515, 6.0812],
            [34.9844, 5.841],
            [35.0987, 5.6225],
            [35.262, 5.5119],
            [35.3208, 5.3487],
            [35.4302, 5.4272],
            [35.8042, 5.318],
            [35.7559, 5.0634],
            [35.7845, 4.7642],
            [35.9208, 4.6193],
            [35.6106, 4.62],
            [35.5222, 4.7805],
            [35.5708, 4.9043],
            [35.2457, 4.9817],
            [34.6663, 4.765],
            [34.3812, 4.6202],
            [33.9771, 4.2197],
            [33.5326, 3.7743],
            [33.1954, 3.7571],
            [33.0172, 3.8772],
            [32.7564, 3.769],
            [32.3718, 3.7311],
            [32.1878, 3.6192],
            [32.1746, 3.5209],
            [31.9437, 3.5913],
            [31.781, 3.8158],
            [31.6685, 3.705],
            [31.5054, 3.6599],
            [31.295, 3.7742],
            [31.1415, 3.7851],
            [30.9444, 3.6793],
            [30.8395, 3.4902],
            [30.6934, 3.6319],
            [30.5726, 3.601],
            [30.5389, 3.8415],
            [30.2059, 3.9504],
            [30.1227, 4.1025],
            [29.7762, 4.4056],
            [29.7777, 4.5239],
            [29.4941, 4.6683],
            [29.3039, 4.3871],
            [29.1735, 4.348],
            [28.9862, 4.4959],
            [28.8153, 4.4783],
            [28.7554, 4.554],
            [28.4041, 4.2778],
            [28.1776, 4.3472],
            [28.0089, 4.4991],
            [27.7724, 4.5958],
            [27.7669, 4.7354],
            [27.641, 4.8909],
            [27.5527, 4.9005],
            [27.4413, 5.0707],
            [27.3013, 5.2052],
            [27.2201, 5.4409],
            [27.2607, 5.5503],
            [27.1704, 5.7203],
            [26.9811, 5.8592],
            [26.8189, 5.8946],
            [26.705, 6.0098],
            [26.528, 6.0432],
            [26.452, 6.2803],
            [26.2896, 6.3872],
            [26.277, 6.4774],
            [26.378, 6.6533],
            [26.081, 6.8423],
            [26.0261, 6.9967],
            [25.36, 7.3356],
            [25.1904, 7.5012],
            [25.2794, 7.6595],
            [25.2169, 7.8641],
            [25.0896, 7.8849],
            [24.8321, 8.1657],
            [24.4311, 8.2714],
            [24.3321, 8.2457],
            [24.1314, 8.3431],
            [24.2437, 8.5702],
            [24.1703, 8.6893],
            [24.3801, 8.8442],
            [24.5584, 8.8867],
            [24.5654, 9.0149],
            [24.661, 9.1823],
            [24.6802, 9.3734],
            [24.7944, 9.5322],
            [24.7927, 9.7984],
            [24.9763, 9.9674],
            [25.0841, 10.2932],
            [25.4795, 10.3438],
            [25.8431, 10.4178],
            [25.9009, 10.1911],
            [26.1795, 9.959],
            [26.5569, 9.5205],
            [26.6943, 9.4769],
            [27.0804, 9.6068],
            [27.8951, 9.5954],
            [28.0451, 9.3314],
            [28.8438, 9.3245],
            [28.8287, 9.4275],
            [29.0095, 9.6032],
            [29.2524, 9.7112],
            [29.4831, 9.7617],
            [29.6139, 9.9145],
            [29.6154, 10.0581],
            [30.013, 10.2705],
            [30.4846, 9.972],
            [30.7493, 9.7358],
            [31.2348, 9.7923],
            [31.6648, 10.2142],
            [31.8642, 10.4721],
            [31.9429, 10.6556],
            [32.4141, 11.0509],
            [32.3642, 11.24],
            [32.3452, 11.7091],
            [32.0822, 11.9998],
            [32.7467, 12.0027],
            [32.7318, 12.2162],
            [33.2092, 12.2104],
            [33.145, 11.9347],
            [33.1324, 11.6862],
            [33.0829, 11.5846],
            [33.1821, 10.8432],
            [33.1507, 10.731],
            [33.3706, 10.6509],
            [33.469, 10.5439],
            [33.9021, 10.192],
            [33.9668, 10.0473],
            [33.9691, 9.8383],
            [33.9049, 9.7107],
            [33.9009, 9.4553],
            [34.0707, 9.4546],
          ],
        ],
      },
      properties: {
        NAME: "S. Sudan",
        NAME_LONG: "South Sudan",
        ADM0_A3: "SDS",
        ISO_A2: "SS",
        ISO_A3: "SSD",
        WB_A2: "SS",
        WB_A3: "SSD",
      },
      id: 197,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.2641, 14.7749],
            [-12.1659, 14.6252],
            [-12.2217, 14.3912],
            [-12.1156, 14.3551],
            [-11.9976, 14.1662],
            [-12.0252, 14.0267],
            [-11.9629, 13.9092],
            [-12.0976, 13.7044],
            [-11.9968, 13.5442],
            [-11.7565, 13.3998],
            [-11.6131, 13.3608],
            [-11.5331, 13.1118],
            [-11.4503, 13.0751],
            [-11.4022, 12.7317],
            [-11.461, 12.6584],
            [-11.3884, 12.4039],
            [-11.5154, 12.4316],
            [-11.7578, 12.3833],
            [-12.0792, 12.4081],
            [-12.3609, 12.3056],
            [-12.5051, 12.3903],
            [-12.7733, 12.4351],
            [-12.9131, 12.5363],
            [-13.0827, 12.5157],
            [-13.0765, 12.6359],
            [-13.7283, 12.6734],
            [-15.1951, 12.6794],
            [-15.677, 12.4393],
            [-16.2329, 12.4529],
            [-16.382, 12.3738],
            [-16.7284, 12.3325],
            [-16.7987, 12.4309],
            [-16.7612, 12.7132],
            [-16.7537, 13.065],
            [-16.6735, 13.1643],
            [-15.8333, 13.1568],
            [-15.8227, 13.3194],
            [-15.5195, 13.3866],
            [-15.3792, 13.3625],
            [-15.2278, 13.4252],
            [-15.1377, 13.59],
            [-15.0151, 13.4957],
            [-14.5922, 13.3531],
            [-14.395, 13.243],
            [-14.2017, 13.2296],
            [-13.851, 13.3358],
            [-13.8187, 13.4294],
            [-13.9675, 13.5756],
            [-14.3299, 13.4564],
            [-14.4703, 13.5224],
            [-14.5855, 13.6605],
            [-14.7286, 13.6193],
            [-14.9158, 13.7924],
            [-15.0977, 13.82],
            [-15.2673, 13.7418],
            [-15.3941, 13.7715],
            [-15.5182, 13.5831],
            [-16.5614, 13.5869],
            [-16.7102, 13.7736],
            [-16.8026, 14.1079],
            [-16.9396, 14.3559],
            [-17.0595, 14.4539],
            [-17.1782, 14.6531],
            [-17.371, 14.8003],
            [-17.1387, 14.9277],
            [-16.8782, 15.2331],
            [-16.5389, 15.7744],
            [-16.5423, 15.8088],
            [-16.466, 16.1741],
            [-16.3811, 16.2467],
            [-16.3274, 16.4746],
            [-16.1323, 16.5548],
            [-16.0538, 16.4954],
            [-15.6722, 16.4814],
            [-15.4909, 16.5645],
            [-15.1251, 16.5827],
            [-14.9309, 16.6434],
            [-14.3433, 16.6367],
            [-14.0156, 16.3559],
            [-13.9641, 16.2301],
            [-13.8113, 16.1221],
            [-13.4849, 16.155],
            [-13.3137, 15.9221],
            [-13.2378, 15.6234],
            [-13.0896, 15.497],
            [-12.9643, 15.5092],
            [-12.8568, 15.222],
            [-12.6903, 15.0924],
            [-12.4715, 14.998],
            [-12.3825, 14.846],
            [-12.2641, 14.7749],
          ],
        ],
      },
      properties: {
        NAME: "Senegal",
        NAME_LONG: "Senegal",
        ADM0_A3: "SEN",
        ISO_A2: "SN",
        ISO_A3: "SEN",
        WB_A2: "SN",
        WB_A3: "SEN",
      },
      id: 198,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [103.9608, 1.3911],
            [103.8441, 1.2685],
            [103.6476, 1.3084],
            [103.8049, 1.4486],
            [103.9608, 1.3911],
          ],
        ],
      },
      properties: {
        NAME: "Singapore",
        NAME_LONG: "Singapore",
        ADM0_A3: "SGP",
        ISO_A2: "SG",
        ISO_A3: "SGP",
        WB_A2: "SG",
        WB_A3: "SGP",
      },
      id: 200,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.6872, -53.9937],
            [-36.6216, -54.1198],
            [-36.042, -54.464],
            [-35.8873, -54.7844],
            [-36.1018, -54.8875],
            [-36.4633, -54.5375],
            [-36.8134, -54.445],
            [-37.3881, -54.1477],
            [-37.7245, -54.1335],
            [-37.6872, -53.9937],
          ],
        ],
      },
      properties: {
        NAME: "S. Geo. and S. Sandw. Is.",
        NAME_LONG: "South Georgia and South Sandwich Islands",
        ADM0_A3: "SGS",
        ISO_A2: "GS",
        ISO_A3: "SGS",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 201,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [160.0524, -11.4952],
              [160.3939, -11.6578],
              [160.5645, -11.773],
              [160.4832, -11.845],
              [160.2305, -11.6262],
              [160.0106, -11.5895],
              [160.0524, -11.4952],
            ],
          ],
          [
            [
              [166.0612, -10.654],
              [165.862, -10.8685],
              [165.7565, -10.8122],
              [165.8781, -10.6568],
              [166.0612, -10.654],
            ],
          ],
          [
            [
              [161.3357, -10.2087],
              [161.5022, -10.2507],
              [161.8357, -10.447],
              [162.1136, -10.4565],
              [162.2775, -10.6796],
              [162.2717, -10.8144],
              [162.1011, -10.8215],
              [161.7922, -10.735],
              [161.5247, -10.5642],
              [161.511, -10.3649],
              [161.2808, -10.3089],
              [161.3357, -10.2087],
            ],
          ],
          [
            [
              [161.4263, -9.4117],
              [161.5452, -9.6047],
              [161.3948, -9.649],
              [161.4263, -9.4117],
            ],
          ],
          [
            [
              [159.9397, -9.4329],
              [160.3664, -9.4128],
              [160.7854, -9.7275],
              [160.8247, -9.8673],
              [160.6448, -9.9371],
              [160.3203, -9.8151],
              [159.8356, -9.7939],
              [159.7234, -9.707],
              [159.5991, -9.5124],
              [159.616, -9.2953],
              [159.709, -9.2477],
              [159.9397, -9.4329],
            ],
          ],
          [
            [
              [158.0134, -8.5455],
              [158.1057, -8.6911],
              [158.0134, -8.7652],
              [157.8792, -8.6825],
              [158.0134, -8.5455],
            ],
          ],
          [
            [
              [160.8005, -8.3588],
              [160.9968, -8.6038],
              [160.9466, -8.8137],
              [161.1511, -8.9496],
              [161.3281, -9.4386],
              [161.2372, -9.4475],
              [160.8679, -9.1521],
              [160.7643, -8.9467],
              [160.6545, -8.6041],
              [160.72, -8.5534],
              [160.5935, -8.3849],
              [160.8005, -8.3588],
            ],
          ],
          [
            [
              [157.636, -8.1894],
              [157.8384, -8.3372],
              [157.8861, -8.5829],
              [157.6748, -8.5092],
              [157.5671, -8.2763],
              [157.2282, -8.3167],
              [157.3828, -8.0315],
              [157.5789, -8.0055],
              [157.636, -8.1894],
            ],
          ],
          [
            [
              [157.1843, -8.1067],
              [157.0542, -8.1291],
              [156.947, -7.9797],
              [157.0911, -7.8505],
              [157.1913, -7.9529],
              [157.1843, -8.1067],
            ],
          ],
          [
            [
              [156.6112, -7.5694],
              [156.7995, -7.7665],
              [156.6597, -7.9392],
              [156.5042, -7.6301],
              [156.6112, -7.5694],
            ],
          ],
          [
            [
              [158.596, -7.5859],
              [158.654, -7.5613],
              [158.9898, -7.8022],
              [159.0857, -7.9036],
              [159.4026, -7.9824],
              [159.4317, -8.0315],
              [159.8421, -8.3256],
              [159.7966, -8.3918],
              [159.3454, -8.2614],
              [158.8457, -7.9734],
              [158.5801, -7.7408],
              [158.596, -7.5859],
            ],
          ],
          [
            [
              [156.5816, -6.6497],
              [157.0427, -6.8971],
              [157.1525, -7.1243],
              [157.4417, -7.4142],
              [156.9277, -7.2088],
              [156.687, -6.8971],
              [156.4611, -6.7333],
              [156.5816, -6.6497],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Solomon Is.",
        NAME_LONG: "Solomon Islands",
        ADM0_A3: "SLB",
        ISO_A2: "SB",
        ISO_A3: "SLB",
        WB_A2: "SB",
        WB_A3: "SLB",
      },
      id: 203,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.2822, 8.4846],
            [-10.3649, 8.1482],
            [-10.5292, 8.1243],
            [-10.6177, 8.0108],
            [-10.6152, 7.769],
            [-10.7306, 7.7055],
            [-10.8746, 7.5309],
            [-11.1263, 7.368],
            [-11.3732, 7.1388],
            [-11.4762, 6.9194],
            [-11.8396, 7.149],
            [-12.5066, 7.3898],
            [-12.4283, 7.5336],
            [-12.5314, 7.6871],
            [-12.7114, 7.712],
            [-12.8759, 7.8287],
            [-12.8952, 8.0796],
            [-12.9787, 8.242],
            [-13.1617, 8.1872],
            [-13.1444, 8.4312],
            [-13.2412, 8.6722],
            [-13.3011, 9.0415],
            [-13.0289, 9.096],
            [-12.9579, 9.2724],
            [-12.8358, 9.282],
            [-12.7016, 9.4202],
            [-12.5375, 9.7157],
            [-12.5083, 9.8604],
            [-12.2536, 9.9284],
            [-12.1413, 9.8749],
            [-11.9218, 9.9222],
            [-11.9101, 9.9925],
            [-11.2727, 9.996],
            [-11.0101, 9.7427],
            [-10.8549, 9.4553],
            [-10.6751, 9.3065],
            [-10.7463, 9.0838],
            [-10.606, 9.0638],
            [-10.6002, 8.819],
            [-10.4819, 8.6725],
            [-10.6389, 8.5199],
            [-10.6691, 8.3991],
            [-10.5193, 8.3348],
            [-10.3898, 8.4891],
            [-10.2822, 8.4846],
          ],
        ],
      },
      properties: {
        NAME: "Sierra Leone",
        NAME_LONG: "Sierra Leone",
        ADM0_A3: "SLE",
        ISO_A2: "SL",
        ISO_A3: "SLE",
        WB_A2: "SL",
        WB_A3: "SLE",
      },
      id: 204,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.3616, 14.4155],
            [-89.0266, 14.3113],
            [-88.9572, 14.1852],
            [-88.7466, 14.0525],
            [-88.4954, 13.9751],
            [-88.488, 13.8653],
            [-88.0875, 13.9805],
            [-88.0236, 13.8912],
            [-87.8329, 13.9152],
            [-87.7126, 13.8005],
            [-87.8172, 13.4066],
            [-87.7981, 13.2657],
            [-87.9282, 13.1586],
            [-88.2083, 13.1604],
            [-88.4692, 13.2231],
            [-88.7857, 13.2453],
            [-89.2515, 13.4723],
            [-89.808, 13.5269],
            [-89.8371, 13.5968],
            [-90.093, 13.7289],
            [-90.099, 13.8585],
            [-89.8801, 14.0427],
            [-89.5309, 14.2256],
            [-89.5699, 14.412],
            [-89.3616, 14.4155],
          ],
        ],
      },
      properties: {
        NAME: "El Salvador",
        NAME_LONG: "El Salvador",
        ADM0_A3: "SLV",
        ISO_A2: "SV",
        ISO_A3: "SLV",
        WB_A2: "SV",
        WB_A3: "SLV",
      },
      id: 205,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [48.9391, 11.2491],
            [48.9391, 10.1254],
            [48.9391, 9.4512],
            [48.3391, 8.5421],
            [47.9792, 7.9966],
            [46.9792, 7.9966],
            [46.9205, 8.0256],
            [45.5144, 8.4965],
            [44.0239, 8.9855],
            [43.6215, 9.3369],
            [43.419, 9.413],
            [43.2353, 9.6918],
            [43.1872, 9.8833],
            [43.0677, 9.9225],
            [42.9582, 10.1156],
            [42.8363, 10.2081],
            [42.6996, 10.6585],
            [42.9237, 10.9988],
            [43.2407, 11.4879],
            [43.4597, 11.3511],
            [43.4881, 11.2389],
            [43.6589, 10.988],
            [43.8296, 10.803],
            [44.2743, 10.4567],
            [44.5861, 10.3836],
            [44.7507, 10.4279],
            [44.962, 10.4158],
            [45.3373, 10.6708],
            [45.4871, 10.6847],
            [45.8027, 10.8753],
            [46.1022, 10.7707],
            [46.2074, 10.7962],
            [46.4478, 10.6931],
            [46.6597, 10.7543],
            [47.0081, 10.939],
            [47.3669, 11.1728],
            [47.5227, 11.187],
            [47.6777, 11.108],
            [48.1608, 11.1457],
            [48.3428, 11.2755],
            [48.6548, 11.3306],
            [48.9391, 11.2491],
          ],
        ],
      },
      properties: {
        NAME: "Somaliland",
        NAME_LONG: "Somaliland",
        ADM0_A3: "SOL",
        ISO_A2: "-99",
        ISO_A3: "-99",
        WB_A2: "-99",
        WB_A3: "-99",
      },
      id: 207,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [41.5351, -1.6963],
            [41.5229, -1.5728],
            [40.9798, -0.8708],
            [40.9685, -0.0027],
            [40.9685, 0.8722],
            [40.9685, 2.1036],
            [40.9654, 2.8141],
            [41.3427, 3.2011],
            [41.885, 3.9772],
            [41.9235, 4.0706],
            [42.1353, 4.2001],
            [42.2973, 4.2017],
            [42.7898, 4.2856],
            [42.8689, 4.3268],
            [42.9604, 4.5174],
            [43.1193, 4.6477],
            [43.5285, 4.8416],
            [43.969, 4.954],
            [44.9126, 4.8994],
            [44.9415, 4.9115],
            [45.936, 5.9967],
            [46.4239, 6.4967],
            [47.3959, 7.4342],
            [47.9792, 7.9966],
            [48.3391, 8.5421],
            [48.9391, 9.4512],
            [48.9391, 10.1254],
            [48.9391, 11.2491],
            [49.2396, 11.3003],
            [49.5217, 11.3938],
            [49.5768, 11.46],
            [49.8512, 11.4649],
            [50.2683, 11.5893],
            [50.5127, 11.7712],
            [50.5759, 11.921],
            [50.7979, 11.9891],
            [51.0293, 11.8851],
            [51.2927, 11.8331],
            [51.2478, 11.6524],
            [51.1209, 11.5053],
            [51.0859, 11.188],
            [51.1382, 10.6767],
            [51.241, 10.4256],
            [50.9274, 10.3278],
            [50.9004, 10.0241],
            [50.8062, 9.6245],
            [50.8403, 9.4561],
            [50.6768, 9.2592],
            [50.6484, 9.1104],
            [50.422, 8.8571],
            [50.2902, 8.4868],
            [50.0583, 8.1303],
            [49.8421, 7.9627],
            [49.8193, 7.7399],
            [49.5866, 7.3148],
            [49.367, 7.0257],
            [49.0747, 6.4153],
            [49.0361, 6.1442],
            [48.6467, 5.48],
            [48.2024, 4.9089],
            [47.9485, 4.4571],
            [47.578, 4.0599],
            [47.4893, 3.9363],
            [46.6257, 3.0239],
            [46.2365, 2.6676],
            [46.027, 2.4381],
            [45.2322, 1.9677],
            [45.0015, 1.8664],
            [44.5501, 1.5591],
            [44.1455, 1.2296],
            [43.9326, 1.0098],
            [43.7819, 0.9216],
            [43.4676, 0.6206],
            [42.7703, -0.1293],
            [42.6478, -0.2289],
            [42.0809, -0.8629],
            [41.842, -1.2249],
            [41.6521, -1.5651],
            [41.5351, -1.6963],
          ],
        ],
      },
      properties: {
        NAME: "Somalia",
        NAME_LONG: "Somalia",
        ADM0_A3: "SOM",
        ISO_A2: "SO",
        ISO_A3: "SOM",
        WB_A2: "SO",
        WB_A3: "SOM",
      },
      id: 208,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.2428, 46.1081],
            [20.3386, 45.9928],
            [20.5721, 45.8877],
            [20.7134, 45.7333],
            [20.7607, 45.4933],
            [20.9815, 45.3328],
            [21.4933, 45.1451],
            [21.3685, 44.8649],
            [21.6563, 44.6877],
            [22.0043, 44.6515],
            [22.1851, 44.5151],
            [22.3197, 44.6853],
            [22.4837, 44.724],
            [22.6212, 44.6374],
            [22.4841, 44.4998],
            [22.6916, 44.2284],
            [22.6047, 44.0794],
            [22.3996, 43.9933],
            [22.3495, 43.8079],
            [22.5189, 43.4742],
            [22.8045, 43.329],
            [22.9353, 43.0856],
            [22.727, 42.8869],
            [22.506, 42.8701],
            [22.4297, 42.5714],
            [22.5088, 42.4049],
            [22.345, 42.3134],
            [21.9415, 42.3331],
            [21.5641, 42.2463],
            [21.7387, 42.6822],
            [21.3787, 42.7441],
            [21.0926, 43.0907],
            [20.8386, 43.1705],
            [20.5975, 43.185],
            [20.6438, 43.0523],
            [20.3454, 42.8274],
            [19.9292, 43.1139],
            [19.6187, 43.1682],
            [19.1953, 43.5328],
            [19.3634, 43.6016],
            [19.4617, 43.7621],
            [19.2754, 43.9333],
            [19.6189, 44.0357],
            [19.1772, 44.287],
            [19.1299, 44.5183],
            [19.3284, 44.734],
            [19.3687, 44.8871],
            [19.1743, 44.9254],
            [19.0158, 44.8656],
            [19.1317, 44.9532],
            [19.0823, 45.0849],
            [19.1621, 45.285],
            [18.9124, 45.6192],
            [18.845, 45.8157],
            [18.9013, 45.9312],
            [19.2635, 45.9814],
            [19.5499, 46.1642],
            [19.9292, 46.1635],
            [20.2428, 46.1081],
          ],
        ],
      },
      properties: {
        NAME: "Serbia",
        NAME_LONG: "Serbia",
        ADM0_A3: "SRB",
        ISO_A2: "RS",
        ISO_A3: "SRB",
        WB_A2: "YF",
        WB_A3: "SRB",
      },
      id: 210,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.6162, 0.0781],
            [6.5164, 0.0336],
            [6.4617, 0.2222],
            [6.6419, 0.4108],
            [6.7609, 0.2842],
            [6.6162, 0.0781],
          ],
        ],
      },
      properties: {
        NAME: "São Tomé and Principe",
        NAME_LONG: "São Tomé and Principe",
        ADM0_A3: "STP",
        ISO_A2: "ST",
        ISO_A3: "STP",
        WB_A2: "ST",
        WB_A3: "STP",
      },
      id: 211,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.171, 5.3484],
            [-54.4302, 5.0534],
            [-54.4821, 4.9128],
            [-54.4355, 4.7094],
            [-54.4508, 4.4842],
            [-54.3552, 4.0665],
            [-54.1874, 3.8147],
            [-53.9888, 3.611],
            [-54.003, 3.4554],
            [-54.1905, 3.1781],
            [-54.1634, 2.9697],
            [-54.2125, 2.7764],
            [-54.3594, 2.508],
            [-54.5203, 2.3489],
            [-54.6153, 2.3263],
            [-54.7032, 2.4458],
            [-54.8803, 2.4473],
            [-55.0177, 2.5906],
            [-55.1125, 2.5278],
            [-55.3545, 2.4949],
            [-55.3996, 2.4302],
            [-55.7243, 2.3969],
            [-55.9892, 2.5208],
            [-56.1168, 2.3331],
            [-55.9258, 2.0617],
            [-55.9168, 1.9222],
            [-56.0197, 1.8335],
            [-56.4818, 1.9416],
            [-56.7052, 2.0296],
            [-56.9941, 2.5049],
            [-57.0272, 2.6482],
            [-57.1746, 2.819],
            [-57.2749, 3.1343],
            [-57.2836, 3.3438],
            [-57.5651, 3.3498],
            [-57.644, 3.5167],
            [-57.8298, 3.6624],
            [-57.8539, 3.7667],
            [-58.0321, 3.9877],
            [-58.0677, 4.1511],
            [-57.965, 4.282],
            [-57.9527, 4.4097],
            [-57.8374, 4.6509],
            [-57.9185, 4.8298],
            [-57.7205, 4.9898],
            [-57.3152, 5.0261],
            [-57.3241, 5.3386],
            [-57.2477, 5.4849],
            [-57.1637, 5.5571],
            [-57.1258, 5.8181],
            [-56.9565, 6.0116],
            [-56.6595, 5.9797],
            [-55.9992, 5.8107],
            [-55.8444, 5.976],
            [-55.336, 5.9627],
            [-54.7736, 5.9853],
            [-54.4718, 5.941],
            [-54.0367, 5.8424],
            [-53.9864, 5.7457],
            [-54.0736, 5.4898],
            [-54.171, 5.3484],
          ],
        ],
      },
      properties: {
        NAME: "Suriname",
        NAME_LONG: "Suriname",
        ADM0_A3: "SUR",
        ISO_A2: "SR",
        ISO_A3: "SUR",
        WB_A2: "SR",
        WB_A3: "SUR",
      },
      id: 212,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.5396, 49.0722],
            [22.3225, 48.7003],
            [22.1537, 48.5859],
            [22.1328, 48.4048],
            [21.7277, 48.3409],
            [21.4246, 48.5613],
            [21.1093, 48.4891],
            [20.7841, 48.5691],
            [20.4817, 48.5261],
            [20.2724, 48.2525],
            [19.8843, 48.1296],
            [19.6232, 48.227],
            [19.4282, 48.0859],
            [18.8385, 48.04],
            [18.7677, 47.8223],
            [18.2359, 47.7539],
            [17.8257, 47.75],
            [17.472, 47.8888],
            [17.3379, 47.9987],
            [17.1483, 48.0054],
            [16.8445, 48.3656],
            [16.945, 48.6042],
            [17.1675, 48.8598],
            [17.5351, 48.813],
            [17.8603, 48.9217],
            [18.1029, 49.0923],
            [18.19, 49.2769],
            [18.5356, 49.4817],
            [18.8332, 49.5103],
            [18.9623, 49.3892],
            [19.1725, 49.4023],
            [19.4434, 49.6018],
            [19.7518, 49.2191],
            [20.07, 49.1831],
            [20.1302, 49.3039],
            [20.3295, 49.3918],
            [20.6895, 49.4005],
            [20.9424, 49.2959],
            [21.0535, 49.4145],
            [21.2744, 49.4473],
            [21.8196, 49.3772],
            [22.0124, 49.2111],
            [22.5396, 49.0722],
          ],
        ],
      },
      properties: {
        NAME: "Slovakia",
        NAME_LONG: "Slovakia",
        ADM0_A3: "SVK",
        ISO_A2: "SK",
        ISO_A3: "SVK",
        WB_A2: "SK",
        WB_A3: "SVK",
      },
      id: 213,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.5153, 46.5017],
            [16.3441, 46.547],
            [16.1918, 46.3698],
            [15.6613, 46.2153],
            [15.6661, 45.8317],
            [15.4513, 45.8151],
            [15.2489, 45.7118],
            [15.314, 45.4505],
            [15.1393, 45.43],
            [14.9226, 45.5149],
            [14.6685, 45.534],
            [14.5561, 45.6567],
            [14.3728, 45.4778],
            [14.2187, 45.4972],
            [13.889, 45.4236],
            [13.5895, 45.4888],
            [13.7118, 45.5932],
            [13.7787, 45.7434],
            [13.5813, 45.8092],
            [13.6228, 45.9664],
            [13.3653, 46.2903],
            [13.701, 46.5197],
            [14.1499, 46.4401],
            [14.5022, 46.4184],
            [14.7886, 46.5066],
            [15.062, 46.6496],
            [15.4627, 46.6146],
            [15.6521, 46.7108],
            [15.9705, 46.743],
            [16.094, 46.8628],
            [16.272, 46.864],
            [16.3764, 46.6291],
            [16.5153, 46.5017],
          ],
        ],
      },
      properties: {
        NAME: "Slovenia",
        NAME_LONG: "Slovenia",
        ADM0_A3: "SVN",
        ISO_A2: "SI",
        ISO_A3: "SVN",
        WB_A2: "SI",
        WB_A3: "SVN",
      },
      id: 214,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [17.0735, 57.3432],
              [17.0663, 57.2267],
              [16.6432, 56.5684],
              [16.3989, 56.5447],
              [16.6209, 56.8723],
              [16.7168, 56.8918],
              [16.9665, 57.3046],
              [17.0735, 57.3432],
            ],
          ],
          [
            [
              [18.7844, 57.8396],
              [18.7702, 57.3645],
              [18.7141, 57.2429],
              [18.4312, 57.1543],
              [18.0994, 57.259],
              [18.112, 57.5111],
              [18.4631, 57.8032],
              [18.7844, 57.8396],
            ],
          ],
          [
            [
              [24.1631, 65.8227],
              [23.957, 65.7891],
              [23.3743, 65.8227],
              [23.0868, 65.7277],
              [22.8372, 65.8077],
              [22.3606, 65.7612],
              [22.1701, 65.553],
              [21.7017, 65.2859],
              [21.6188, 65.148],
              [21.1813, 64.8358],
              [21.4898, 64.487],
              [20.9666, 64.1494],
              [20.6875, 63.7987],
              [20.5034, 63.822],
              [19.7532, 63.5243],
              [19.2761, 63.4427],
              [19.0325, 63.182],
              [18.6113, 63.1789],
              [18.5125, 62.9041],
              [17.9264, 62.8223],
              [18.0425, 62.5921],
              [17.8617, 62.5008],
              [17.347, 62.4033],
              [17.5695, 62.2047],
              [17.3442, 61.9241],
              [17.3992, 61.7224],
              [17.0972, 61.6361],
              [17.104, 61.3994],
              [17.2127, 61.2353],
              [17.1582, 60.9484],
              [17.238, 60.6977],
              [17.575, 60.6479],
              [17.6382, 60.5154],
              [17.9675, 60.5189],
              [18.2775, 60.3494],
              [18.4199, 60.1935],
              [18.8245, 60.0683],
              [18.8616, 59.803],
              [18.6865, 59.6058],
              [18.2769, 59.4226],
              [18.0425, 59.3974],
              [18.3926, 59.1758],
              [17.093, 58.6608],
              [16.6359, 58.6268],
              [16.8213, 58.2071],
              [16.679, 58.0051],
              [16.4603, 57.8947],
              [16.6755, 57.7645],
              [16.689, 57.4717],
              [16.5348, 57.3603],
              [16.5525, 57.1101],
              [16.3566, 56.7584],
              [16.0952, 56.4194],
              [16.0383, 56.2551],
              [15.8517, 56.0864],
              [15.4929, 56.1763],
              [14.6858, 56.1474],
              [14.3464, 55.9533],
              [14.1958, 55.7257],
              [14.363, 55.5236],
              [14.1812, 55.3908],
              [13.8823, 55.4331],
              [13.3814, 55.346],
              [13, 55.4001],
              [12.9226, 55.7495],
              [12.6095, 56.1165],
              [12.8335, 56.2657],
              [12.9344, 56.5471],
              [12.7318, 56.647],
              [12.5983, 56.821],
              [12.375, 56.9121],
              [12.2044, 57.1479],
              [12.0593, 57.4588],
              [11.9033, 57.5241],
              [11.6936, 57.8812],
              [11.8757, 58.1992],
              [11.5671, 58.2492],
              [11.2249, 58.3707],
              [11.2317, 58.7996],
              [11.1082, 58.9536],
              [11.4375, 58.9917],
              [11.6644, 58.9201],
              [11.8117, 59.2497],
              [11.6774, 59.5591],
              [11.9035, 59.77],
              [12.5109, 60.118],
              [12.4873, 60.2959],
              [12.5944, 60.5149],
              [12.377, 60.7541],
              [12.2561, 60.9813],
              [12.6825, 61.0403],
              [12.8771, 61.371],
              [12.6138, 61.547],
              [12.1617, 61.7248],
              [12.3175, 62.234],
              [12.0912, 62.5863],
              [12.1044, 62.9233],
              [12.2227, 63.0015],
              [11.9924, 63.2889],
              [12.2049, 63.4546],
              [12.1703, 63.5981],
              [12.6819, 63.9564],
              [13.2341, 64.0909],
              [13.9394, 64.0095],
              [14.1614, 64.2054],
              [14.1156, 64.4412],
              [13.6428, 64.584],
              [14.3107, 65.0841],
              [14.5143, 65.3181],
              [14.5607, 65.7011],
              [14.6444, 65.8429],
              [14.5405, 66.1254],
              [15.0534, 66.1533],
              [15.4819, 66.2749],
              [15.4263, 66.4912],
              [15.6695, 66.5994],
              [16.0388, 66.8875],
              [16.4156, 67.0527],
              [16.4379, 67.2003],
              [16.1271, 67.4228],
              [16.483, 67.5578],
              [16.808, 67.9106],
              [17.3215, 68.1053],
              [17.8954, 67.9697],
              [18.1667, 68.1585],
              [18.1718, 68.5359],
              [19.0381, 68.5056],
              [19.931, 68.3502],
              [20.2459, 68.4774],
              [20.3411, 68.9101],
              [20.6232, 69.0364],
              [20.9346, 68.967],
              [21.4636, 68.6869],
              [22.0722, 68.477],
              [22.6076, 68.4249],
              [23.0522, 68.2982],
              [23.1517, 68.1303],
              [23.6612, 67.9329],
              [23.4989, 67.8822],
              [23.5535, 67.587],
              [23.7652, 67.4201],
              [23.5813, 67.1531],
              [24.0013, 66.8124],
              [23.8819, 66.5635],
              [23.6423, 66.4363],
              [23.7242, 66.2066],
              [24.0196, 66.0489],
              [24.1631, 65.8227],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Sweden",
        NAME_LONG: "Sweden",
        ADM0_A3: "SWE",
        ISO_A2: "SE",
        ISO_A3: "SWE",
        WB_A2: "SE",
        WB_A3: "SWE",
      },
      id: 215,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.9492, -25.9581],
            [32.0575, -26.0412],
            [32.0399, -26.2837],
            [32.1174, -26.5823],
            [32.1139, -26.84],
            [31.9922, -26.8383],
            [31.9421, -27.1007],
            [31.9683, -27.3163],
            [31.5265, -27.3109],
            [31.157, -27.2056],
            [30.9761, -27.035],
            [30.8802, -26.7722],
            [30.7857, -26.7169],
            [30.8046, -26.3975],
            [30.9699, -26.2091],
            [31.1198, -25.91],
            [31.3093, -25.7574],
            [31.4269, -25.7436],
            [31.8346, -25.9821],
            [31.9492, -25.9581],
          ],
        ],
      },
      properties: {
        NAME: "Swaziland",
        NAME_LONG: "Swaziland",
        ADM0_A3: "SWZ",
        ISO_A2: "SZ",
        ISO_A3: "SWZ",
        WB_A2: "SZ",
        WB_A3: "SWZ",
      },
      id: 216,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [42.3572, 37.11],
            [42.2816, 36.994],
            [41.8438, 36.6179],
            [41.4149, 36.5274],
            [41.277, 36.3548],
            [41.2406, 36.043],
            [41.3437, 35.8577],
            [41.3635, 35.6552],
            [41.2613, 35.4943],
            [41.1915, 35.1821],
            [41.1957, 34.7685],
            [40.988, 34.4285],
            [40.6905, 34.3315],
            [39.8804, 33.9256],
            [38.7745, 33.3717],
            [38.0567, 32.9943],
            [36.8194, 32.3168],
            [36.3731, 32.3864],
            [36.1774, 32.5273],
            [36.0699, 32.5166],
            [35.8957, 32.7133],
            [35.7576, 32.7443],
            [35.8881, 32.9449],
            [35.8036, 33.2485],
            [35.8211, 33.4067],
            [36.0129, 33.608],
            [36.0504, 33.8164],
            [36.2672, 33.9104],
            [36.6041, 34.1991],
            [36.5306, 34.4142],
            [36.2616, 34.6272],
            [35.9699, 34.6498],
            [35.8669, 34.9242],
            [35.9624, 35.1979],
            [35.9089, 35.4257],
            [35.7781, 35.5412],
            [35.8119, 35.8354],
            [35.9113, 35.9178],
            [36.1385, 35.8198],
            [36.1969, 35.9517],
            [36.3555, 36.0224],
            [36.3605, 36.1817],
            [36.5878, 36.3248],
            [36.5355, 36.494],
            [36.6586, 36.8275],
            [36.9647, 36.7538],
            [37.0145, 36.6423],
            [37.4461, 36.6342],
            [38.1901, 36.9055],
            [38.5294, 36.8337],
            [38.6641, 36.7195],
            [39.2396, 36.6613],
            [39.7653, 36.7422],
            [40.1901, 36.8844],
            [40.709, 37.1005],
            [40.8964, 37.1227],
            [41.2013, 37.065],
            [41.4798, 37.0756],
            [42.0092, 37.1756],
            [42.1785, 37.3063],
            [42.3532, 37.227],
            [42.3572, 37.11],
          ],
        ],
      },
      properties: {
        NAME: "Syria",
        NAME_LONG: "Syria",
        ADM0_A3: "SYR",
        ISO_A2: "SY",
        ISO_A3: "SYR",
        WB_A2: "SY",
        WB_A3: "SYR",
      },
      id: 219,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.9813, 19.4961],
            [23.9821, 18.567],
            [23.9831, 17.4057],
            [23.9844, 15.7212],
            [23.5927, 15.749],
            [23.3206, 15.6813],
            [23.0946, 15.7043],
            [22.9257, 15.5639],
            [22.9657, 15.2239],
            [22.7389, 14.9798],
            [22.6508, 14.8162],
            [22.6663, 14.6817],
            [22.4654, 14.6293],
            [22.4249, 14.4704],
            [22.441, 14.2499],
            [22.5315, 14.1226],
            [22.2435, 13.9754],
            [22.0737, 13.7714],
            [22.1959, 13.5805],
            [22.2676, 13.3346],
            [22.14, 13.1935],
            [21.9351, 13.0592],
            [21.8094, 12.7937],
            [21.936, 12.6395],
            [22.0587, 12.6367],
            [22.2049, 12.7434],
            [22.4457, 12.6111],
            [22.3728, 12.4631],
            [22.4814, 12.1767],
            [22.4814, 12.0443],
            [22.5929, 11.9889],
            [22.5371, 11.6809],
            [22.6281, 11.5098],
            [22.7818, 11.3989],
            [22.9148, 11.3961],
            [22.9565, 11.2088],
            [22.8611, 10.9192],
            [22.4605, 11.0008],
            [22.253, 10.9153],
            [22.1764, 10.8162],
            [21.7226, 10.6367],
            [21.7511, 10.4118],
            [21.6563, 10.2337],
            [21.4854, 10.1643],
            [21.3743, 9.9731],
            [21.2569, 9.9757],
            [20.8261, 9.4181],
            [20.4961, 9.2709],
            [20.3594, 9.1164],
            [20.0687, 9.1381],
            [19.8895, 9.0464],
            [19.3694, 9.0004],
            [19.1006, 9.0153],
            [18.8927, 8.8979],
            [18.9291, 8.7965],
            [19.1241, 8.6751],
            [18.813, 8.2764],
            [18.6726, 8.2075],
            [18.5893, 8.0479],
            [18.0705, 8.0192],
            [17.8979, 7.9675],
            [17.6798, 7.9852],
            [17.2509, 7.8228],
            [17.1019, 7.6777],
            [16.8807, 7.6329],
            [16.7686, 7.5502],
            [16.6245, 7.6687],
            [16.5487, 7.87],
            [16.4074, 7.7961],
            [16.3708, 7.6725],
            [16.0652, 7.5912],
            [15.7939, 7.458],
            [15.481, 7.5233],
            [15.5629, 7.7924],
            [15.4407, 7.8394],
            [15.3453, 8.136],
            [15.1837, 8.4791],
            [15.0313, 8.6587],
            [14.7939, 8.8136],
            [14.3495, 9.1684],
            [14.3213, 9.2432],
            [13.9476, 9.6378],
            [14.1817, 9.9782],
            [14.4405, 9.9953],
            [14.7325, 9.9238],
            [15.2149, 9.9841],
            [15.383, 9.9302],
            [15.5361, 10.0805],
            [15.3014, 10.3117],
            [15.0659, 10.7931],
            [15.0212, 11.1825],
            [15.0606, 11.4161],
            [15.1356, 11.5308],
            [15.0683, 11.7001],
            [15.0406, 12.0556],
            [14.9031, 12.1459],
            [14.9083, 12.3269],
            [14.8308, 12.6182],
            [14.604, 12.7608],
            [14.4906, 12.8863],
            [14.4182, 13.0811],
            [14.0649, 13.078],
            [13.6073, 13.7041],
            [13.4492, 14.3801],
            [13.4823, 14.4836],
            [13.6243, 14.5213],
            [13.6483, 14.6494],
            [13.7644, 14.7191],
            [13.7605, 14.8664],
            [13.8339, 15.0196],
            [14.369, 15.7496],
            [15.4524, 16.8762],
            [15.472, 16.9167],
            [15.5911, 18.2651],
            [15.736, 19.9035],
            [15.963, 20.3192],
            [15.954, 20.3746],
            [15.5442, 20.799],
            [15.6093, 20.9507],
            [15.301, 21.4013],
            [15.1804, 21.5073],
            [15.172, 21.9934],
            [14.9799, 22.9957],
            [15.9851, 23.4447],
            [17.4348, 22.7288],
            [18.176, 22.3628],
            [19.1641, 21.8749],
            [20.5228, 21.2039],
            [21.6345, 20.655],
            [22.2521, 20.3501],
            [23.2402, 19.8621],
            [23.9813, 19.4961],
          ],
        ],
      },
      properties: {
        NAME: "Chad",
        NAME_LONG: "Chad",
        ADM0_A3: "TCD",
        ISO_A2: "TD",
        ISO_A3: "TCD",
        WB_A2: "TD",
        WB_A3: "TCD",
      },
      id: 221,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.9015, 10.9927],
            [0.8629, 10.7961],
            [0.7813, 10.693],
            [0.7688, 10.3671],
            [1.331, 9.9965],
            [1.3513, 9.5951],
            [1.4253, 9.2845],
            [1.6012, 9.0495],
            [1.6213, 7.9966],
            [1.6257, 6.9968],
            [1.566, 6.6781],
            [1.7441, 6.4257],
            [1.7824, 6.2773],
            [1.6196, 6.2139],
            [1.1854, 6.1005],
            [0.7668, 6.4194],
            [0.7146, 6.5839],
            [0.5193, 6.8322],
            [0.4946, 6.9731],
            [0.598, 7.0312],
            [0.6457, 7.3236],
            [0.5006, 7.455],
            [0.4994, 7.5624],
            [0.6123, 7.6994],
            [0.5738, 8.2039],
            [0.7052, 8.2828],
            [0.6163, 8.4888],
            [0.4435, 8.6069],
            [0.3659, 8.7743],
            [0.5056, 8.8663],
            [0.4313, 9.0276],
            [0.5376, 9.3776],
            [0.4304, 9.4892],
            [0.2314, 9.419],
            [0.2302, 9.5757],
            [0.3546, 9.5711],
            [0.3224, 9.7607],
            [0.3702, 9.947],
            [0.3666, 10.3045],
            [0.177, 10.3977],
            [0.0203, 10.588],
            [-0.0881, 10.6335],
            [0.0162, 11.0626],
            [-0.1661, 11.135],
            [0.4866, 11.0032],
            [0.9015, 10.9927],
          ],
        ],
      },
      properties: {
        NAME: "Togo",
        NAME_LONG: "Togo",
        ADM0_A3: "TGO",
        ISO_A2: "TG",
        ISO_A3: "TGO",
        WB_A2: "TG",
        WB_A3: "TGO",
      },
      id: 222,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [98.2891, 8.1897],
              [98.4385, 8.0657],
              [98.4398, 7.8866],
              [98.2866, 7.7917],
              [98.2891, 8.1897],
            ],
          ],
          [
            [
              [100.0993, 20.3178],
              [100.3644, 20.369],
              [100.5434, 20.0666],
              [100.4743, 19.8654],
              [100.3835, 19.7639],
              [100.462, 19.5371],
              [100.7455, 19.485],
              [100.8746, 19.6129],
              [101.1247, 19.5651],
              [101.1929, 19.4528],
              [101.2291, 19.1436],
              [101.3179, 19.0501],
              [101.2329, 18.8927],
              [101.2401, 18.6736],
              [101.0303, 18.4278],
              [101.1455, 18.3362],
              [101.1652, 18.0534],
              [101.0083, 17.8952],
              [100.9019, 17.5619],
              [101.1323, 17.4617],
              [101.247, 17.5922],
              [101.5776, 17.8681],
              [101.7291, 17.9122],
              [101.7904, 18.0736],
              [101.9043, 18.0371],
              [102.0785, 18.2138],
              [102.3067, 18.0515],
              [102.5675, 17.9708],
              [102.5956, 17.8501],
              [102.9386, 18.0089],
              [103.0379, 17.9907],
              [103.2608, 18.4002],
              [103.3968, 18.4414],
              [103.8321, 18.3207],
              [104, 18.3184],
              [104.1109, 18.1146],
              [104.4812, 17.6404],
              [104.7145, 17.5153],
              [104.8164, 17.3728],
              [104.8099, 17.1717],
              [104.7453, 17.0248],
              [104.7783, 16.7158],
              [104.7545, 16.5289],
              [105.0155, 16.2767],
              [105.0425, 16.1414],
              [105.4049, 16.0188],
              [105.3726, 15.8821],
              [105.448, 15.7649],
              [105.6129, 15.7215],
              [105.651, 15.6346],
              [105.564, 15.2725],
              [105.4438, 15.1338],
              [105.5836, 14.9776],
              [105.4891, 14.7863],
              [105.5101, 14.5936],
              [105.416, 14.4282],
              [105.1843, 14.3457],
              [105.1014, 14.2305],
              [104.9738, 14.3806],
              [104.7717, 14.4399],
              [104.462, 14.3571],
              [104.2475, 14.4011],
              [103.8703, 14.3433],
              [103.5502, 14.4219],
              [103.085, 14.2958],
              [102.9186, 14.1855],
              [102.8698, 14.0206],
              [102.6986, 13.7618],
              [102.5744, 13.6939],
              [102.5132, 13.5672],
              [102.3322, 13.5646],
              [102.3282, 13.2752],
              [102.4732, 13.0061],
              [102.5586, 12.6312],
              [102.7651, 12.4163],
              [102.7, 12.1387],
              [102.9138, 11.7655],
              [102.9136, 11.6459],
              [102.9067, 11.7414],
              [102.777, 11.9025],
              [102.7497, 12.0403],
              [102.2959, 12.19],
              [102.072, 12.4885],
              [101.8303, 12.6728],
              [101.4429, 12.6248],
              [101.0877, 12.6806],
              [100.9261, 12.6191],
              [100.8374, 12.7079],
              [100.8783, 13.0983],
              [100.9813, 13.359],
              [100.9507, 13.4682],
              [100.6497, 13.5202],
              [100.2899, 13.505],
              [100.0388, 13.4027],
              [99.9671, 13.259],
              [100.1043, 13.0573],
              [99.9876, 12.7905],
              [99.9609, 12.6254],
              [100.02, 12.1927],
              [99.8553, 11.9684],
              [99.8171, 11.7414],
              [99.7518, 11.7099],
              [99.5693, 11.334],
              [99.5862, 11.1951],
              [99.4942, 11.1146],
              [99.5116, 10.9024],
              [99.2446, 10.5351],
              [99.1516, 10.3574],
              [99.1814, 9.6433],
              [99.3236, 9.3915],
              [99.2551, 9.2325],
              [99.4748, 9.201],
              [99.6895, 9.3159],
              [99.8535, 9.2947],
              [99.9163, 9.102],
              [99.9592, 8.6376],
              [100.1043, 8.4177],
              [100.2205, 8.4487],
              [100.4402, 7.477],
              [100.3235, 7.6164],
              [100.307, 7.7579],
              [100.1429, 7.7],
              [100.1854, 7.5142],
              [100.3955, 7.2113],
              [100.486, 7.1399],
              [100.6111, 7.1926],
              [100.7651, 6.9831],
              [100.9976, 6.8567],
              [101.3852, 6.9052],
              [101.5663, 6.8322],
              [101.7717, 6.5007],
              [102.0731, 6.2575],
              [102.06, 6.0948],
              [101.9594, 6.0116],
              [101.9129, 5.8593],
              [101.8005, 5.7399],
              [101.6504, 5.7826],
              [101.5347, 5.906],
              [101.249, 5.787],
              [101.1054, 5.6376],
              [100.9672, 5.781],
              [101.0711, 5.9198],
              [101.0817, 6.2465],
              [100.8221, 6.2599],
              [100.8056, 6.4148],
              [100.3874, 6.5221],
              [100.2808, 6.6889],
              [100.1668, 6.6951],
              [100.1273, 6.4423],
              [99.7972, 6.8217],
              [99.6858, 6.8773],
              [99.6929, 7.1161],
              [99.584, 7.1564],
              [99.5247, 7.3113],
              [99.3659, 7.3364],
              [99.2927, 7.6207],
              [99.1375, 7.7443],
              [98.918, 8.0513],
              [98.7474, 8.0683],
              [98.7442, 8.2149],
              [98.5989, 8.3749],
              [98.4456, 8.3251],
              [98.425, 8.1484],
              [98.2776, 8.2407],
              [98.1995, 8.5344],
              [98.2241, 8.7353],
              [98.382, 9.0719],
              [98.3269, 9.2037],
              [98.4717, 9.6212],
              [98.5127, 9.8369],
              [98.5999, 9.9481],
              [98.7474, 10.3505],
              [98.7913, 10.5204],
              [98.7667, 10.6888],
              [99.0015, 10.9576],
              [99.213, 11.1083],
              [99.2433, 11.1977],
              [99.63, 11.8158],
              [99.4663, 12.126],
              [99.387, 12.4659],
              [99.3938, 12.5898],
              [99.2141, 12.7347],
              [99.1021, 13.1713],
              [99.1902, 13.2295],
              [99.1525, 13.7149],
              [98.9453, 14.0691],
              [98.5477, 14.3777],
              [98.4182, 14.6076],
              [98.2433, 14.8051],
              [98.165, 15.1258],
              [98.283, 15.29],
              [98.4034, 15.3601],
              [98.5599, 15.3553],
              [98.5431, 15.7354],
              [98.5838, 15.977],
              [98.6587, 16.1163],
              [98.8072, 16.1105],
              [98.9033, 16.3634],
              [98.657, 16.3016],
              [98.6316, 16.4631],
              [98.4567, 16.7232],
              [98.5246, 16.8504],
              [98.4362, 17.0116],
              [98.3133, 17.052],
              [98.1006, 17.3026],
              [97.9828, 17.5054],
              [97.7689, 17.6792],
              [97.6855, 17.8807],
              [97.7541, 17.969],
              [97.5606, 18.3283],
              [97.4381, 18.4881],
              [97.7518, 18.5825],
              [97.7191, 18.8646],
              [97.6578, 18.9257],
              [97.8108, 19.1122],
              [97.7671, 19.3975],
              [97.8398, 19.5553],
              [98.0083, 19.6391],
              [98.0179, 19.7894],
              [98.2179, 19.708],
              [98.5326, 19.6759],
              [98.8078, 19.8065],
              [99.0088, 19.8459],
              [99.0168, 20.041],
              [99.1175, 20.1178],
              [99.2957, 20.0625],
              [99.5258, 20.1962],
              [99.5029, 20.3452],
              [99.7696, 20.3284],
              [99.942, 20.444],
              [100.0993, 20.3178],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Thailand",
        NAME_LONG: "Thailand",
        ADM0_A3: "THA",
        ISO_A2: "TH",
        ISO_A3: "THA",
        WB_A2: "TH",
        WB_A3: "THA",
      },
      id: 223,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.959, 40.2384],
            [70.6394, 40.0849],
            [70.6274, 39.9939],
            [69.9696, 40.2116],
            [69.5755, 40.1036],
            [69.314, 39.9869],
            [69.2263, 39.751],
            [69.2862, 39.5397],
            [69.6694, 39.5778],
            [70.0197, 39.5686],
            [70.206, 39.5241],
            [70.46, 39.5995],
            [70.6221, 39.5635],
            [70.7397, 39.3862],
            [71.0093, 39.4076],
            [71.4595, 39.6121],
            [71.4921, 39.4937],
            [71.7403, 39.4483],
            [71.7329, 39.2851],
            [72.0498, 39.3689],
            [72.2033, 39.2408],
            [72.3163, 39.3288],
            [72.6505, 39.3938],
            [73.1679, 39.3554],
            [73.3676, 39.4438],
            [73.6326, 39.4483],
            [73.6023, 39.2358],
            [73.816, 38.9938],
            [73.6837, 38.8549],
            [73.7974, 38.6028],
            [73.9329, 38.5297],
            [74.1404, 38.6692],
            [74.3342, 38.667],
            [74.7763, 38.5107],
            [74.7907, 38.0814],
            [74.8928, 37.994],
            [74.9652, 37.7675],
            [74.8919, 37.5992],
            [75.1641, 37.4006],
            [74.8923, 37.2311],
            [74.6604, 37.394],
            [74.2239, 37.4034],
            [74.1633, 37.3301],
            [73.7982, 37.2285],
            [73.7535, 37.4284],
            [73.4853, 37.481],
            [73.2761, 37.4595],
            [73.0675, 37.3151],
            [72.761, 37.1875],
            [72.6577, 37.0288],
            [72.2598, 36.9673],
            [71.8366, 36.6992],
            [71.6111, 36.7048],
            [71.4311, 37.067],
            [71.487, 37.2671],
            [71.4972, 37.5665],
            [71.5977, 37.8984],
            [71.3412, 37.8933],
            [71.2558, 37.9499],
            [71.3645, 38.2068],
            [71.3344, 38.2807],
            [70.974, 38.4737],
            [70.7611, 38.4435],
            [70.5958, 38.3382],
            [70.4826, 38.1374],
            [70.1652, 37.8899],
            [70.2831, 37.7048],
            [70.1579, 37.5414],
            [69.5287, 37.586],
            [69.3784, 37.4374],
            [69.4452, 37.2364],
            [69.3239, 37.121],
            [69.1236, 37.1692],
            [69.0055, 37.3055],
            [68.3073, 37.1142],
            [68.0111, 36.9309],
            [67.7805, 37.1889],
            [67.8292, 37.5279],
            [68.2487, 37.949],
            [68.3607, 38.1741],
            [68.1837, 38.3355],
            [68.0552, 38.5363],
            [68.0376, 38.6916],
            [68.1726, 38.8453],
            [68.0856, 38.9938],
            [67.6905, 38.9896],
            [67.6198, 39.1513],
            [67.3782, 39.1995],
            [67.4365, 39.4857],
            [67.7645, 39.6226],
            [68.3376, 39.5279],
            [68.5175, 39.5484],
            [68.6158, 39.8281],
            [68.8774, 39.9006],
            [68.602, 40.1754],
            [69.0192, 40.2321],
            [69.2853, 40.2186],
            [69.3063, 40.365],
            [69.198, 40.5458],
            [69.4358, 40.8027],
            [69.6601, 40.6387],
            [70.2804, 40.8777],
            [70.4457, 41.0259],
            [70.6969, 40.8162],
            [70.7562, 40.6951],
            [70.3538, 40.4561],
            [70.5818, 40.2217],
            [70.959, 40.2384],
          ],
        ],
      },
      properties: {
        NAME: "Tajikistan",
        NAME_LONG: "Tajikistan",
        ADM0_A3: "TJK",
        ISO_A2: "TJ",
        ISO_A3: "TJK",
        WB_A2: "TJ",
        WB_A3: "TJK",
      },
      id: 224,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.5196, 37.3642],
            [66.2944, 37.3312],
            [66.0797, 37.4409],
            [65.6688, 37.5206],
            [65.6241, 37.3451],
            [65.5014, 37.2423],
            [65.0631, 37.2332],
            [64.7602, 37.0926],
            [64.7788, 36.9385],
            [64.592, 36.6211],
            [64.6059, 36.4611],
            [64.4446, 36.25],
            [64.2661, 36.1525],
            [63.7659, 35.9773],
            [63.6023, 35.9627],
            [63.3429, 35.8563],
            [63.1253, 35.8602],
            [63.1839, 35.7123],
            [63.0766, 35.6247],
            [63.0802, 35.4371],
            [62.6211, 35.2227],
            [62.219, 35.3063],
            [62.0338, 35.424],
            [61.5387, 35.4523],
            [61.2697, 35.6185],
            [61.2474, 35.8762],
            [61.174, 36.0065],
            [61.2228, 36.1175],
            [61.1525, 36.4063],
            [61.186, 36.5777],
            [61.0755, 36.6478],
            [60.3423, 36.6371],
            [60.0192, 37.0258],
            [59.5095, 37.1898],
            [59.3704, 37.3194],
            [59.3517, 37.4938],
            [58.861, 37.6681],
            [58.5143, 37.6915],
            [58.3718, 37.6304],
            [58.1812, 37.7749],
            [57.6912, 37.9203],
            [57.3524, 37.9675],
            [57.2183, 38.2651],
            [57.0384, 38.1873],
            [56.739, 38.2696],
            [56.4365, 38.2551],
            [56.2997, 38.0802],
            [55.9784, 38.0659],
            [55.7087, 38.1187],
            [55.4231, 38.0759],
            [55.1303, 37.9523],
            [54.8566, 37.7611],
            [54.7837, 37.5175],
            [54.6169, 37.433],
            [54.1866, 37.3237],
            [53.9137, 37.3428],
            [53.8249, 37.7449],
            [53.8071, 38.032],
            [53.8589, 38.2065],
            [53.827, 38.5255],
            [53.9775, 38.9018],
            [53.7478, 39.0544],
            [53.5813, 39.3305],
            [53.3943, 39.317],
            [53.0887, 39.3832],
            [53.1782, 39.5157],
            [53.3635, 39.5381],
            [53.5772, 39.4678],
            [53.4299, 39.7625],
            [53.5671, 39.9495],
            [53.4373, 40.0027],
            [52.8841, 39.9449],
            [52.7385, 40.0536],
            [52.699, 40.2739],
            [52.7366, 40.4846],
            [52.9582, 41.0163],
            [53.1074, 40.7739],
            [53.5417, 40.7616],
            [53.721, 40.6257],
            [54.0108, 40.6579],
            [54.6399, 40.8388],
            [54.76, 40.965],
            [54.6004, 41.1923],
            [54.3118, 41.3383],
            [54.0414, 41.5346],
            [54.0197, 41.7315],
            [53.8967, 42.0788],
            [53.6818, 42.1439],
            [53.2967, 42.0788],
            [53.1219, 42.09],
            [52.9603, 41.9844],
            [52.8215, 41.7649],
            [52.9038, 41.6898],
            [52.8157, 41.3887],
            [52.5622, 41.5195],
            [52.5952, 41.6337],
            [52.4377, 41.7489],
            [52.9787, 42.1266],
            [53.4123, 42.2582],
            [54.0472, 42.3454],
            [54.1953, 42.3181],
            [54.7383, 42.0482],
            [54.9071, 41.9321],
            [54.9507, 41.7956],
            [55.2284, 41.4728],
            [55.4295, 41.2908],
            [55.6355, 41.2644],
            [55.9784, 41.3217],
            [56.7935, 41.2883],
            [57.0102, 41.2541],
            [57.1538, 41.3724],
            [57.0196, 41.4659],
            [56.9501, 41.8661],
            [57.3823, 42.1636],
            [57.8505, 42.1914],
            [57.9977, 42.4934],
            [58.2827, 42.6889],
            [58.4932, 42.6455],
            [58.6123, 42.7809],
            [58.8944, 42.5478],
            [59.122, 42.538],
            [59.2452, 42.3653],
            [59.4782, 42.2856],
            [59.8664, 42.3042],
            [60.0282, 42.1995],
            [59.9837, 41.949],
            [60.2411, 41.8081],
            [60.0492, 41.7535],
            [60.1556, 41.6033],
            [60.0942, 41.3895],
            [60.4145, 41.2353],
            [60.8603, 41.253],
            [61.2814, 41.1573],
            [61.4701, 41.2972],
            [61.8779, 41.125],
            [61.9708, 41.0154],
            [62.0448, 40.715],
            [62.1918, 40.499],
            [62.3533, 40.4304],
            [62.4528, 40.0092],
            [62.5932, 39.8902],
            [63.4776, 39.4029],
            [63.7093, 39.1909],
            [64.1206, 38.9617],
            [64.3373, 38.971],
            [64.6349, 38.7441],
            [64.9777, 38.6123],
            [65.2053, 38.4496],
            [65.6041, 38.2374],
            [65.8767, 38.273],
            [66.5542, 38.0269],
            [66.6458, 37.9459],
            [66.5188, 37.7827],
            [66.5196, 37.3642],
          ],
        ],
      },
      properties: {
        NAME: "Turkmenistan",
        NAME_LONG: "Turkmenistan",
        ADM0_A3: "TKM",
        ISO_A2: "TM",
        ISO_A3: "TKM",
        WB_A2: "TM",
        WB_A3: "TKM",
      },
      id: 225,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [124.03, -9.342],
              [124.1908, -9.2551],
              [124.4505, -9.1802],
              [124.3101, -9.4887],
              [124.1975, -9.3732],
              [124.03, -9.342],
            ],
          ],
          [
            [
              [125.0616, -9.4858],
              [124.9745, -9.1919],
              [125.1425, -9.1891],
              [125.1624, -9.0606],
              [124.9479, -9.0785],
              [124.9195, -8.962],
              [125.1004, -8.7783],
              [125.1443, -8.6314],
              [125.8977, -8.4811],
              [126.0691, -8.5216],
              [126.4116, -8.4191],
              [126.5488, -8.4867],
              [126.8421, -8.391],
              [126.9671, -8.309],
              [127.1863, -8.3325],
              [127.3032, -8.4323],
              [127.0093, -8.6767],
              [126.8462, -8.7525],
              [126.5833, -8.8058],
              [126.4302, -8.9547],
              [126.1659, -8.9884],
              [125.9471, -9.1257],
              [125.6094, -9.1862],
              [125.3788, -9.281],
              [125.0616, -9.4858],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Timor-Leste",
        NAME_LONG: "Timor-Leste",
        ADM0_A3: "TLS",
        ISO_A2: "TL",
        ISO_A3: "TLS",
        WB_A2: "TP",
        WB_A3: "TMP",
      },
      id: 226,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.9073, 10.8371],
            [-61.0327, 10.6835],
            [-60.996, 10.1383],
            [-61.1916, 10.0738],
            [-61.637, 10.0578],
            [-61.7121, 10.1636],
            [-61.4584, 10.3022],
            [-61.4723, 10.5949],
            [-61.6021, 10.7477],
            [-61.3666, 10.803],
            [-60.9073, 10.8371],
          ],
        ],
      },
      properties: {
        NAME: "Trinidad and Tobago",
        NAME_LONG: "Trinidad and Tobago",
        ADM0_A3: "TTO",
        ISO_A2: "TT",
        ISO_A3: "TTO",
        WB_A2: "TT",
        WB_A3: "TTO",
      },
      id: 228,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [10.9299, 33.8963],
              [11.0603, 33.8007],
              [10.8937, 33.6582],
              [10.7402, 33.7155],
              [10.736, 33.8848],
              [10.9299, 33.8963],
            ],
          ],
          [
            [
              [11.5051, 33.1812],
              [11.4499, 32.638],
              [11.5641, 32.4655],
              [11.444, 32.3685],
              [10.8732, 32.1367],
              [10.8056, 32.0324],
              [10.6059, 31.9536],
              [10.5135, 31.757],
              [10.264, 31.6805],
              [10.1081, 31.4118],
              [10.2448, 31.0782],
              [10.2702, 30.9156],
              [10.1922, 30.7313],
              [9.8713, 30.3552],
              [9.5197, 30.2289],
              [9.2644, 31.2193],
              [9.045, 32.0718],
              [8.4827, 32.4348],
              [8.3196, 32.5606],
              [8.2829, 32.8364],
              [8.0868, 33.0943],
              [7.7507, 33.2077],
              [7.6932, 33.4541],
              [7.553, 33.6587],
              [7.4798, 33.8939],
              [7.5175, 34.095],
              [7.7743, 34.261],
              [7.8315, 34.4144],
              [8.0941, 34.5301],
              [8.2365, 34.6477],
              [8.2489, 34.902],
              [8.3137, 35.1031],
              [8.3236, 35.6522],
              [8.2414, 35.8277],
              [8.3171, 36.1459],
              [8.3492, 36.4488],
              [8.1699, 36.5258],
              [8.4079, 36.6426],
              [8.444, 36.7962],
              [8.6238, 36.8261],
              [8.6025, 36.9395],
              [8.802, 36.9731],
              [9.2107, 37.2338],
              [9.6663, 37.3354],
              [9.8589, 37.3288],
              [10.1463, 37.2365],
              [10.2201, 36.9766],
              [10.3472, 36.8803],
              [10.3201, 36.7488],
              [10.5382, 36.772],
              [11.0127, 37.0848],
              [11.1168, 36.8384],
              [11.0221, 36.7883],
              [10.7999, 36.4519],
              [10.5191, 36.3375],
              [10.4795, 36.0793],
              [10.6086, 35.8559],
              [10.8412, 35.6988],
              [11.012, 35.6579],
              [11.0422, 35.3344],
              [11.1116, 35.2056],
              [10.9177, 34.9574],
              [10.9163, 34.8736],
              [10.597, 34.5447],
              [10.1267, 34.3256],
              [10.0052, 34.1706],
              [10.0488, 33.9835],
              [10.3303, 33.7028],
              [10.4893, 33.6471],
              [10.7166, 33.7069],
              [10.7171, 33.4835],
              [11.1082, 33.5496],
              [11.0899, 33.4541],
              [11.1795, 33.2111],
              [11.5051, 33.1812],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Tunisia",
        NAME_LONG: "Tunisia",
        ADM0_A3: "TUN",
        ISO_A2: "TN",
        ISO_A3: "TUN",
        WB_A2: "TN",
        WB_A3: "TUN",
      },
      id: 229,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [28.0168, 41.9726],
              [27.972, 41.8223],
              [28.1133, 41.6116],
              [28.3843, 41.4475],
              [28.9085, 41.2665],
              [29.1092, 41.2353],
              [28.9819, 41.0017],
              [28.6253, 40.9684],
              [28.2367, 41.0852],
              [27.9413, 40.9711],
              [27.5242, 40.9892],
              [27.4248, 40.8313],
              [27.1777, 40.6326],
              [26.6834, 40.4555],
              [26.3758, 40.1535],
              [26.2191, 40.3314],
              [26.8289, 40.602],
              [26.7904, 40.6606],
              [26.3026, 40.6011],
              [26.0769, 40.6118],
              [26.044, 40.7385],
              [26.3591, 40.9647],
              [26.3317, 41.2541],
              [26.6366, 41.3785],
              [26.5963, 41.5975],
              [26.3334, 41.713],
              [26.5261, 41.8242],
              [26.6236, 41.969],
              [26.9389, 41.9962],
              [27.0226, 42.0739],
              [27.2734, 42.0917],
              [27.5332, 41.9081],
              [27.808, 41.9935],
              [28.0168, 41.9726],
            ],
          ],
          [
            [
              [41.5208, 41.5142],
              [41.8008, 41.4257],
              [41.948, 41.5056],
              [42.4379, 41.4309],
              [42.5652, 41.5671],
              [42.9577, 41.437],
              [43.2306, 41.1726],
              [43.4404, 41.1066],
              [43.6049, 40.9633],
              [43.7294, 40.6771],
              [43.5942, 40.3454],
              [43.7214, 40.1694],
              [44.0324, 40.0086],
              [44.2553, 40.0434],
              [44.4643, 39.9723],
              [44.7746, 39.7028],
              [44.807, 39.6399],
              [44.5657, 39.7659],
              [44.4595, 39.6985],
              [44.4048, 39.4344],
              [44.0614, 39.4003],
              [44.0777, 39.2001],
              [44.1866, 39.1451],
              [44.1392, 38.994],
              [44.275, 38.8436],
              [44.2895, 38.3823],
              [44.4247, 38.377],
              [44.2196, 37.8753],
              [44.3779, 37.8541],
              [44.5961, 37.7164],
              [44.5659, 37.4472],
              [44.8016, 37.2927],
              [44.7661, 37.1419],
              [44.5782, 37.1664],
              [44.2343, 36.9837],
              [44.2232, 37.2541],
              [43.9904, 37.3125],
              [43.8936, 37.2249],
              [43.4796, 37.2434],
              [43.0837, 37.3688],
              [42.937, 37.3202],
              [42.7716, 37.3749],
              [42.5613, 37.1466],
              [42.3572, 37.11],
              [42.3532, 37.227],
              [42.1785, 37.3063],
              [42.0092, 37.1756],
              [41.4798, 37.0756],
              [41.2013, 37.065],
              [40.8964, 37.1227],
              [40.709, 37.1005],
              [40.1901, 36.8844],
              [39.7653, 36.7422],
              [39.2396, 36.6613],
              [38.6641, 36.7195],
              [38.5294, 36.8337],
              [38.1901, 36.9055],
              [37.4461, 36.6342],
              [37.0145, 36.6423],
              [36.9647, 36.7538],
              [36.6586, 36.8275],
              [36.5355, 36.494],
              [36.5878, 36.3248],
              [36.3605, 36.1817],
              [36.3555, 36.0224],
              [36.1969, 35.9517],
              [36.1385, 35.8198],
              [35.9113, 35.9178],
              [35.9754, 36.0185],
              [35.7887, 36.3203],
              [36.1891, 36.604],
              [36.189, 36.8017],
              [36.0203, 36.9263],
              [35.8054, 36.7751],
              [35.6067, 36.7404],
              [35.5134, 36.613],
              [35.348, 36.5444],
              [34.7708, 36.8161],
              [34.5547, 36.7676],
              [34.0773, 36.4139],
              [34.0779, 36.3288],
              [33.5442, 36.1445],
              [32.9409, 36.1035],
              [32.6707, 36.0467],
              [32.3655, 36.178],
              [32.0261, 36.5434],
              [31.4076, 36.7634],
              [31.3408, 36.8086],
              [30.6865, 36.8912],
              [30.5698, 36.7813],
              [30.5698, 36.528],
              [30.4408, 36.2393],
              [30.2546, 36.3083],
              [29.9116, 36.217],
              [29.7953, 36.1452],
              [29.3223, 36.2469],
              [29.1243, 36.4182],
              [29.0964, 36.6652],
              [28.6035, 36.7335],
              [28.4248, 36.8291],
              [28.1115, 36.6042],
              [28.0418, 36.7199],
              [27.9866, 37.0351],
              [27.7804, 36.9936],
              [27.2632, 36.9634],
              [27.303, 37.1196],
              [27.5545, 37.1301],
              [27.5532, 37.2729],
              [27.1908, 37.3573],
              [27.2495, 37.7443],
              [27.2391, 37.9879],
              [26.9492, 38.0674],
              [26.7603, 38.2167],
              [26.6071, 38.1083],
              [26.2654, 38.2867],
              [26.4759, 38.3669],
              [26.3486, 38.6213],
              [26.5056, 38.6527],
              [26.7669, 38.3641],
              [26.9386, 38.4342],
              [26.7191, 38.7209],
              [27.0134, 38.8712],
              [26.8065, 38.9501],
              [26.8831, 39.0716],
              [26.6703, 39.306],
              [26.926, 39.4858],
              [26.8934, 39.5844],
              [26.1528, 39.4592],
              [26.1526, 39.9426],
              [26.311, 40.0195],
              [26.7356, 40.4036],
              [27.0134, 40.3925],
              [27.3034, 40.4584],
              [27.4523, 40.322],
              [27.7898, 40.3205],
              [27.9699, 40.503],
              [28.112, 40.3961],
              [28.6377, 40.3652],
              [28.772, 40.4025],
              [28.7927, 40.5533],
              [28.9863, 40.6448],
              [29.2816, 40.6659],
              [29.2639, 40.8698],
              [29.0281, 40.9821],
              [29.1301, 41.2119],
              [29.2318, 41.2407],
              [29.8846, 41.149],
              [30.1369, 41.1438],
              [30.2713, 41.2112],
              [30.8333, 41.0799],
              [31.2489, 41.1057],
              [31.4011, 41.2021],
              [31.4036, 41.3165],
              [32.1341, 41.5994],
              [32.2634, 41.7202],
              [32.6126, 41.8395],
              [32.9261, 41.8818],
              [33.3245, 42.019],
              [34.3313, 41.9479],
              [34.6942, 41.9459],
              [35.1045, 42.0219],
              [35.2248, 41.7619],
              [35.5301, 41.6343],
              [35.8938, 41.7075],
              [36.1205, 41.6278],
              [36.1345, 41.4619],
              [36.451, 41.2404],
              [36.6199, 41.3751],
              [36.9849, 41.2945],
              [37.0397, 41.1815],
              [37.2634, 41.1479],
              [37.5047, 41.0429],
              [37.655, 41.1176],
              [37.8958, 40.9821],
              [38.3309, 40.9179],
              [38.7176, 40.9548],
              [38.9275, 41.0426],
              [39.2732, 41.0504],
              [39.4302, 41.1125],
              [39.5921, 41.0251],
              [40.1428, 40.9228],
              [40.4649, 41.0504],
              [40.6983, 41.0981],
              [40.8275, 41.1979],
              [41.0203, 41.2237],
              [41.3904, 41.3793],
              [41.5208, 41.5142],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Turkey",
        NAME_LONG: "Turkey",
        ADM0_A3: "TUR",
        ISO_A2: "TR",
        ISO_A3: "TUR",
        WB_A2: "TR",
        WB_A3: "TUR",
      },
      id: 230,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [121.636, 25.2228],
            [121.8928, 25.1137],
            [121.9058, 24.9501],
            [121.8235, 24.8549],
            [121.856, 24.5505],
            [121.7727, 24.3092],
            [121.6629, 24.1931],
            [121.4001, 23.1455],
            [121.1414, 22.7418],
            [120.9483, 22.5268],
            [120.875, 22.2893],
            [120.8416, 21.9255],
            [120.7044, 21.9814],
            [120.6213, 22.2951],
            [120.512, 22.4248],
            [120.3312, 22.5197],
            [120.1755, 22.8841],
            [120.1703, 23.0134],
            [120.0594, 23.151],
            [120.1301, 23.361],
            [120.1243, 23.52],
            [120.1892, 23.7748],
            [120.4932, 24.2307],
            [120.7022, 24.607],
            [120.8475, 24.7108],
            [121.0593, 25.0502],
            [121.3788, 25.1579],
            [121.5397, 25.2874],
            [121.636, 25.2228],
          ],
        ],
      },
      properties: {
        NAME: "Taiwan",
        NAME_LONG: "Taiwan",
        ADM0_A3: "TWN",
        ISO_A2: "TW",
        ISO_A3: "TWN",
        WB_A2: "TW",
        WB_A3: "TWN",
      },
      id: 232,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [39.3574, -5.912],
              [39.5695, -6.4208],
              [39.5085, -6.4628],
              [39.2642, -6.3024],
              [39.1904, -5.9142],
              [39.3574, -5.912],
            ],
          ],
          [
            [
              [39.7111, -4.927],
              [39.8059, -4.9609],
              [39.8556, -5.2294],
              [39.7961, -5.4068],
              [39.6758, -5.0017],
              [39.7111, -4.927],
            ],
          ],
          [
            [
              [33.9042, -1.0026],
              [34.0525, -1.0404],
              [35.4156, -1.8013],
              [36.7035, -2.5204],
              [37.6449, -3.046],
              [37.7001, -3.3144],
              [37.5903, -3.4041],
              [37.771, -3.6554],
              [38.5083, -4.1862],
              [39.1906, -4.6775],
              [39.207, -4.8723],
              [39.1304, -4.9181],
              [39.1387, -5.1032],
              [39.0742, -5.1863],
              [38.9988, -5.4653],
              [38.8325, -5.8156],
              [38.778, -6.0307],
              [38.8568, -6.1637],
              [38.8522, -6.3487],
              [39.0409, -6.4701],
              [39.2127, -6.6418],
              [39.2937, -6.8152],
              [39.4645, -6.8577],
              [39.5505, -6.9938],
              [39.5475, -7.1183],
              [39.4268, -7.1923],
              [39.304, -7.4485],
              [39.2962, -7.6884],
              [39.4435, -7.8326],
              [39.4544, -8.0104],
              [39.28, -8.3093],
              [39.4089, -8.8702],
              [39.4933, -9.0795],
              [39.6438, -9.1891],
              [39.6507, -9.4448],
              [39.7795, -9.9301],
              [39.9866, -10.1397],
              [40.2321, -10.2005],
              [40.4369, -10.4748],
              [40.0081, -10.8111],
              [39.7605, -10.9431],
              [39.4827, -10.9992],
              [39.2866, -11.1542],
              [38.8963, -11.1677],
              [38.6542, -11.2758],
              [38.4923, -11.4135],
              [38.2566, -11.2771],
              [38.0708, -11.2558],
              [37.8752, -11.3191],
              [37.7837, -11.5589],
              [37.4278, -11.7226],
              [37.2266, -11.6938],
              [37.0444, -11.5957],
              [36.8201, -11.5736],
              [36.747, -11.6665],
              [36.563, -11.7313],
              [36.4949, -11.6809],
              [36.1997, -11.7015],
              [36.1681, -11.5787],
              [35.9397, -11.4341],
              [35.8264, -11.4135],
              [35.6548, -11.5783],
              [34.9646, -11.5736],
              [34.8866, -11.3754],
              [34.7591, -11.3375],
              [34.593, -11.0162],
              [34.6724, -10.7395],
              [34.5811, -10.5397],
              [34.5853, -10.3007],
              [34.4833, -9.9462],
              [34.2881, -9.7085],
              [34.0127, -9.4775],
              [33.8653, -9.6696],
              [33.7346, -9.5841],
              [33.4042, -9.6049],
              [33.3003, -9.4922],
              [33.1729, -9.511],
              [32.9209, -9.4079],
              [32.7132, -9.2858],
              [32.518, -9.2582],
              [32.383, -9.1339],
              [31.9177, -9.0225],
              [31.9366, -8.9326],
              [31.6727, -8.913],
              [31.539, -8.7036],
              [31.3533, -8.5872],
              [31.1198, -8.6166],
              [30.9595, -8.5505],
              [30.8283, -8.3881],
              [30.7521, -8.1941],
              [30.655, -7.9274],
              [30.4416, -7.5963],
              [30.3696, -7.3103],
              [30.2347, -7.0571],
              [30.0909, -6.89],
              [29.7386, -6.6524],
              [29.5255, -6.2731],
              [29.4761, -6.0021],
              [29.603, -5.7817],
              [29.5935, -5.6203],
              [29.4556, -5.23],
              [29.3778, -5.0875],
              [29.321, -4.8363],
              [29.4042, -4.4498],
              [29.7283, -4.4616],
              [29.8003, -4.3639],
              [30.003, -4.2719],
              [30.1498, -4.0868],
              [30.2088, -3.9305],
              [30.4689, -3.513],
              [30.6129, -3.4449],
              [30.6405, -3.3329],
              [30.7998, -3.2746],
              [30.8322, -3.1728],
              [30.7189, -2.9895],
              [30.4158, -2.8517],
              [30.416, -2.6456],
              [30.5546, -2.4006],
              [30.8344, -2.3453],
              [30.8878, -2.0825],
              [30.8299, -1.9606],
              [30.831, -1.5942],
              [30.7375, -1.4067],
              [30.5553, -1.3184],
              [30.4718, -1.0668],
              [30.6386, -1.0734],
              [30.8284, -1.0026],
              [32.3576, -1.0026],
              [33.9042, -1.0026],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Tanzania",
        NAME_LONG: "Tanzania",
        ADM0_A3: "TZA",
        ISO_A2: "TZ",
        ISO_A3: "TZA",
        WB_A2: "TZ",
        WB_A3: "TZA",
      },
      id: 233,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.9042, -1.0026],
            [32.3576, -1.0026],
            [30.8284, -1.0026],
            [30.6386, -1.0734],
            [30.4718, -1.0668],
            [30.3528, -1.0608],
            [30.1472, -1.3451],
            [29.8979, -1.4696],
            [29.8361, -1.3295],
            [29.5779, -1.3884],
            [29.556, -0.9195],
            [29.6108, -0.864],
            [29.6294, -0.4424],
            [29.7118, 0.0996],
            [29.8006, 0.1724],
            [29.8326, 0.337],
            [29.9405, 0.4983],
            [29.9283, 0.785],
            [30.1451, 0.9032],
            [30.2389, 1.136],
            [30.4783, 1.2386],
            [30.6817, 1.5003],
            [31.0258, 1.7782],
            [31.2428, 2.0512],
            [31.2675, 2.1733],
            [31.1776, 2.3029],
            [30.8718, 2.3321],
            [30.7249, 2.4408],
            [30.7394, 2.6033],
            [30.8548, 2.8932],
            [30.7451, 3.0363],
            [30.8042, 3.246],
            [30.91, 3.3934],
            [30.8395, 3.4902],
            [30.9444, 3.6793],
            [31.1415, 3.7851],
            [31.295, 3.7742],
            [31.5054, 3.6599],
            [31.6685, 3.705],
            [31.781, 3.8158],
            [31.9437, 3.5913],
            [32.1746, 3.5209],
            [32.1878, 3.6192],
            [32.3718, 3.7311],
            [32.7564, 3.769],
            [33.0172, 3.8772],
            [33.1954, 3.7571],
            [33.5326, 3.7743],
            [33.9771, 4.2197],
            [34.0611, 4.0078],
            [34.2789, 3.7097],
            [34.4397, 3.6677],
            [34.3821, 3.466],
            [34.434, 3.182],
            [34.5456, 3.0974],
            [34.5747, 2.9461],
            [34.7301, 2.8529],
            [34.8187, 2.598],
            [34.9236, 2.4773],
            [34.8656, 2.3475],
            [34.9675, 2.1019],
            [34.9787, 1.6759],
            [34.779, 1.3885],
            [34.7979, 1.2319],
            [34.5075, 1.1025],
            [34.388, 0.8158],
            [34.1324, 0.5728],
            [34.088, 0.3674],
            [33.8936, 0.1098],
            [33.9535, -0.1544],
            [33.8948, -0.6627],
            [33.9042, -1.0026],
          ],
        ],
      },
      properties: {
        NAME: "Uganda",
        NAME_LONG: "Uganda",
        ADM0_A3: "UGA",
        ISO_A2: "UG",
        ISO_A3: "UGA",
        WB_A2: "UG",
        WB_A3: "UGA",
      },
      id: 234,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.2166, 47.1033],
            [37.5807, 47.0904],
            [37.3352, 46.8825],
            [37.2082, 46.9336],
            [36.8862, 46.804],
            [36.1895, 46.669],
            [35.9016, 46.6527],
            [35.5105, 46.4572],
            [35.2914, 46.2934],
            [35.075, 46.2711],
            [34.8113, 46.1662],
            [34.8744, 45.9261],
            [35.0444, 45.6701],
            [35.3322, 45.3712],
            [35.4807, 45.2978],
            [35.7918, 45.4123],
            [36.0223, 45.3681],
            [36.3, 45.4733],
            [36.6135, 45.4089],
            [36.418, 45.2299],
            [36.4401, 45.0675],
            [36.2246, 45.0075],
            [36.0479, 45.0488],
            [35.848, 44.9924],
            [35.5474, 45.1197],
            [35.3894, 45.0019],
            [35.0057, 44.8352],
            [34.757, 44.8205],
            [34.469, 44.7298],
            [34.3412, 44.5585],
            [33.9579, 44.383],
            [33.721, 44.3996],
            [33.4774, 44.6066],
            [33.6196, 44.9315],
            [33.5625, 45.0939],
            [33.4134, 45.1845],
            [33.2596, 45.1561],
            [32.8993, 45.3542],
            [32.5129, 45.3406],
            [32.5252, 45.4582],
            [32.9368, 45.6625],
            [33.7087, 45.9097],
            [33.6135, 46.1452],
            [33.4238, 46.0533],
            [33.2935, 46.1357],
            [32.7898, 46.1343],
            [32.5034, 46.0761],
            [32.2625, 46.1282],
            [31.9683, 46.3511],
            [32.1117, 46.5124],
            [31.8584, 46.6291],
            [31.481, 46.6464],
            [31.3377, 46.6013],
            [30.9914, 46.6013],
            [30.7549, 46.5159],
            [30.7725, 46.3964],
            [30.4942, 46.0805],
            [30.1384, 45.8198],
            [29.9685, 45.8389],
            [29.865, 45.6723],
            [29.5897, 45.5591],
            [29.7474, 45.4634],
            [29.659, 45.2159],
            [29.6503, 45.3461],
            [29.3221, 45.4438],
            [28.7101, 45.227],
            [28.3302, 45.3229],
            [28.1995, 45.4618],
            [28.4809, 45.502],
            [28.474, 45.6579],
            [28.7383, 45.8376],
            [28.7576, 45.9612],
            [28.932, 45.9932],
            [29.0154, 46.1826],
            [28.9335, 46.259],
            [28.9458, 46.4548],
            [29.1627, 46.5381],
            [29.2007, 46.3571],
            [29.7269, 46.4558],
            [29.8478, 46.3415],
            [30.0811, 46.3742],
            [29.9161, 46.5188],
            [29.9526, 46.7247],
            [29.8436, 46.8541],
            [29.5587, 46.9457],
            [29.5566, 47.324],
            [29.3589, 47.3527],
            [29.1174, 47.5333],
            [29.2386, 47.756],
            [29.124, 47.976],
            [28.9504, 47.9348],
            [28.7993, 48.1118],
            [28.1999, 48.2117],
            [27.7518, 48.452],
            [27.5038, 48.4724],
            [27.1758, 48.3618],
            [26.8428, 48.3937],
            [26.6179, 48.259],
            [26.3035, 48.212],
            [26.1731, 47.9931],
            [25.2617, 47.8986],
            [25.1219, 47.7703],
            [24.8966, 47.7101],
            [24.5616, 47.9405],
            [24.231, 47.897],
            [23.9773, 47.9623],
            [23.3601, 47.9931],
            [23.2314, 48.0797],
            [22.8776, 47.9467],
            [22.831, 48.0724],
            [22.6001, 48.1011],
            [22.3571, 48.2431],
            [22.2718, 48.4035],
            [22.1328, 48.4048],
            [22.1537, 48.5859],
            [22.3225, 48.7003],
            [22.5396, 49.0722],
            [22.7445, 49.0455],
            [22.7242, 49.3671],
            [22.6409, 49.5288],
            [23.1015, 49.9571],
            [23.7475, 50.3894],
            [23.9813, 50.4048],
            [24.1065, 50.5386],
            [23.8747, 51.1361],
            [23.634, 51.3393],
            [23.6062, 51.5174],
            [23.7497, 51.6445],
            [23.9813, 51.586],
            [24.2441, 51.7182],
            [24.3908, 51.88],
            [24.7218, 51.8823],
            [25.1833, 51.9498],
            [25.7679, 51.9285],
            [26.4078, 51.8506],
            [26.8548, 51.7493],
            [27.1779, 51.7471],
            [27.254, 51.5954],
            [27.4773, 51.6237],
            [28.3469, 51.5252],
            [28.7999, 51.5326],
            [29.1604, 51.6033],
            [29.297, 51.3737],
            [29.618, 51.4856],
            [29.8286, 51.43],
            [30.1486, 51.4844],
            [30.3551, 51.3053],
            [30.6383, 51.3359],
            [30.5341, 51.5533],
            [30.662, 51.8194],
            [30.9405, 52.0201],
            [31.4749, 52.1178],
            [31.7643, 52.1006],
            [32.0956, 52.0407],
            [32.3067, 52.1412],
            [32.3395, 52.2872],
            [32.5284, 52.3162],
            [32.9269, 52.2469],
            [33.1965, 52.3689],
            [33.8041, 52.3546],
            [34.0557, 52.1703],
            [34.0682, 52.031],
            [34.3857, 51.8177],
            [34.3763, 51.7086],
            [34.128, 51.6807],
            [34.2803, 51.3613],
            [34.409, 51.2531],
            [34.7481, 51.1647],
            [34.9618, 51.2153],
            [35.3417, 50.9159],
            [35.4454, 50.6875],
            [35.4253, 50.5005],
            [35.6969, 50.3451],
            [35.8374, 50.4232],
            [36.1058, 50.4211],
            [36.269, 50.2814],
            [36.6826, 50.2607],
            [36.9141, 50.3391],
            [37.4353, 50.4249],
            [37.5992, 50.2909],
            [37.7277, 50.0787],
            [37.9953, 49.9213],
            [38.3442, 49.9921],
            [38.8522, 49.8658],
            [39.1828, 49.8585],
            [39.238, 49.7651],
            [39.5704, 49.7133],
            [39.8015, 49.5422],
            [40.0409, 49.4555],
            [40.1417, 49.2458],
            [39.9187, 49.0478],
            [39.6811, 49.0203],
            [39.7587, 48.8954],
            [39.6317, 48.5869],
            [39.8092, 48.5838],
            [39.9932, 48.2732],
            [39.8431, 48.1198],
            [39.7591, 47.8329],
            [39.3916, 47.8226],
            [38.8772, 47.8612],
            [38.7345, 47.6771],
            [38.3529, 47.6071],
            [38.201, 47.2906],
            [38.2166, 47.1033],
          ],
        ],
      },
      properties: {
        NAME: "Ukraine",
        NAME_LONG: "Ukraine",
        ADM0_A3: "UKR",
        ISO_A2: "UA",
        ISO_A3: "UKR",
        WB_A2: "UA",
        WB_A3: "UKR",
      },
      id: 235,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.3791, -33.7407],
            [-53.4731, -33.8346],
            [-53.5395, -34.0624],
            [-53.711, -34.1988],
            [-53.7649, -34.3904],
            [-53.9722, -34.4872],
            [-54.1459, -34.6676],
            [-54.3065, -34.7077],
            [-54.9393, -34.9699],
            [-55.0821, -34.884],
            [-55.2602, -34.8976],
            [-55.3769, -34.8014],
            [-55.7921, -34.7741],
            [-56.0696, -34.901],
            [-56.3113, -34.9062],
            [-56.4484, -34.7583],
            [-56.7953, -34.6985],
            [-57.12, -34.463],
            [-57.8225, -34.4751],
            [-58.077, -34.192],
            [-58.1962, -34.1594],
            [-58.3888, -33.9421],
            [-58.4346, -33.7849],
            [-58.4126, -33.3717],
            [-58.3669, -33.1537],
            [-58.1744, -33.1104],
            [-58.0485, -32.9117],
            [-58.1205, -32.8193],
            [-58.2001, -32.4471],
            [-58.1016, -32.3114],
            [-58.1865, -32.1529],
            [-58.1686, -31.846],
            [-58.0593, -31.8115],
            [-57.9868, -31.5541],
            [-58.0752, -31.4752],
            [-57.9051, -31.241],
            [-57.8072, -30.9076],
            [-57.8496, -30.4853],
            [-57.6527, -30.3291],
            [-57.6117, -30.183],
            [-57.536, -30.2744],
            [-57.2126, -30.2873],
            [-57.1023, -30.1212],
            [-56.8313, -30.102],
            [-56.3726, -30.4859],
            [-56.2351, -30.5654],
            [-56.0114, -30.7982],
            [-56.0114, -31.0821],
            [-55.8643, -31.0768],
            [-55.5919, -30.8483],
            [-55.3539, -31.0563],
            [-55.26, -31.2287],
            [-54.8198, -31.4353],
            [-54.5915, -31.471],
            [-54.4637, -31.6719],
            [-54.1351, -31.909],
            [-54.0688, -31.8799],
            [-53.7718, -32.0471],
            [-53.6487, -32.3386],
            [-53.4156, -32.5642],
            [-53.1861, -32.6467],
            [-53.1269, -32.7548],
            [-53.3273, -32.9736],
            [-53.5115, -33.0992],
            [-53.5396, -33.6493],
            [-53.3791, -33.7407],
          ],
        ],
      },
      properties: {
        NAME: "Uruguay",
        NAME_LONG: "Uruguay",
        ADM0_A3: "URY",
        ISO_A2: "UY",
        ISO_A3: "URY",
        WB_A2: "UY",
        WB_A3: "URY",
      },
      id: 237,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-155.6065, 20.138],
              [-155.3323, 20.0467],
              [-155.0871, 19.8604],
              [-155.0854, 19.7274],
              [-154.8172, 19.4797],
              [-154.9823, 19.3392],
              [-155.2911, 19.2595],
              [-155.5028, 19.1377],
              [-155.6829, 18.9329],
              [-155.8781, 19.0291],
              [-155.9199, 19.1386],
              [-155.8855, 19.3342],
              [-156.0602, 19.7312],
              [-155.8241, 20.0201],
              [-155.8672, 20.2631],
              [-155.6065, 20.138],
            ],
          ],
          [
            [
              [-156.5896, 21.0277],
              [-156.4817, 20.9044],
              [-156.2983, 20.9527],
              [-156.0017, 20.8004],
              [-156.0031, 20.6859],
              [-156.3026, 20.5812],
              [-156.4077, 20.5872],
              [-156.4643, 20.7814],
              [-156.6202, 20.8071],
              [-156.696, 20.9158],
              [-156.5896, 21.0277],
            ],
          ],
          [
            [
              [-157.8938, 21.5984],
              [-157.8421, 21.461],
              [-157.6472, 21.3064],
              [-157.795, 21.2547],
              [-158.0943, 21.2936],
              [-158.2276, 21.483],
              [-158.0144, 21.7015],
              [-157.8938, 21.5984],
            ],
          ],
          [
            [
              [-159.3882, 22.2288],
              [-159.2928, 22.1464],
              [-159.3341, 21.9581],
              [-159.4373, 21.8688],
              [-159.7598, 21.9785],
              [-159.7165, 22.1575],
              [-159.5484, 22.231],
              [-159.3882, 22.2288],
            ],
          ],
          [
            [
              [-72.2469, 41.1354],
              [-72.109, 41.0014],
              [-72.4067, 40.8621],
              [-72.9468, 40.7419],
              [-73.2333, 40.7145],
              [-73.7073, 40.5828],
              [-73.8296, 40.6488],
              [-73.9086, 40.7887],
              [-73.4857, 40.9456],
              [-73.2246, 40.9083],
              [-73.1131, 40.9735],
              [-72.6061, 40.9899],
              [-72.3434, 41.1407],
              [-72.2469, 41.1354],
            ],
          ],
          [
            [
              [-74.713, 44.9993],
              [-73.3992, 45.0044],
              [-71.5041, 45.0137],
              [-71.3949, 45.2415],
              [-71.1417, 45.2523],
              [-70.8338, 45.3931],
              [-70.6868, 45.5727],
              [-70.4074, 45.7315],
              [-70.2625, 45.923],
              [-70.3114, 46.0719],
              [-70.1884, 46.3584],
              [-70.0743, 46.4195],
              [-70.0078, 46.7041],
              [-69.2676, 47.4398],
              [-69.0697, 47.4319],
              [-69.0402, 47.2491],
              [-68.9062, 47.1902],
              [-68.5397, 47.2999],
              [-68.197, 47.3414],
              [-67.8052, 47.0356],
              [-67.7728, 45.8281],
              [-67.8001, 45.6998],
              [-67.45, 45.6108],
              [-67.475, 45.2824],
              [-67.3563, 45.1659],
              [-67.176, 45.1787],
              [-66.9773, 44.8155],
              [-67.2244, 44.6442],
              [-67.575, 44.5535],
              [-67.8066, 44.5754],
              [-67.8996, 44.4221],
              [-68.0975, 44.3857],
              [-68.2698, 44.4861],
              [-68.7562, 44.3345],
              [-68.8276, 44.4791],
              [-68.9866, 44.4212],
              [-69.1188, 44.0409],
              [-69.2634, 43.9299],
              [-69.6494, 43.8976],
              [-69.7873, 43.7375],
              [-69.9858, 43.8628],
              [-70.2391, 43.7001],
              [-70.2049, 43.5611],
              [-70.3564, 43.527],
              [-70.3824, 43.3937],
              [-70.5195, 43.3395],
              [-70.8153, 42.8703],
              [-70.8614, 42.5469],
              [-71.0384, 42.3079],
              [-70.7838, 42.2597],
              [-70.631, 42.0925],
              [-70.5331, 41.8162],
              [-70.2747, 41.7152],
              [-70.0702, 41.7735],
              [-69.9389, 41.6742],
              [-70.4284, 41.6319],
              [-70.6587, 41.5213],
              [-70.6583, 41.722],
              [-71.1859, 41.4687],
              [-71.2069, 41.6462],
              [-71.3556, 41.7463],
              [-71.4977, 41.3663],
              [-71.8981, 41.3465],
              [-72.288, 41.2829],
              [-72.7794, 41.2702],
              [-73.1701, 41.1696],
              [-73.6346, 41.0071],
              [-73.8199, 40.8391],
              [-73.9268, 40.8048],
              [-74.096, 40.6548],
              [-74.2096, 40.6342],
              [-74.2827, 40.4973],
              [-74.0323, 40.4216],
              [-73.9724, 40.3277],
              [-74.1676, 39.6994],
              [-74.3588, 39.5502],
              [-74.7457, 39.0545],
              [-75.0267, 39.2107],
              [-75.1593, 39.1986],
              [-75.3594, 39.3379],
              [-75.3938, 39.0556],
              [-75.1663, 38.7944],
              [-75.0577, 38.5527],
              [-75.1543, 38.2409],
              [-75.4343, 37.9618],
              [-75.4923, 37.8311],
              [-75.8873, 37.357],
              [-75.9217, 37.6105],
              [-75.7081, 37.8332],
              [-75.8144, 38.1352],
              [-76.2211, 38.3439],
              [-76.319, 38.4739],
              [-76.1454, 38.665],
              [-76.3403, 38.7284],
              [-76.1544, 38.8953],
              [-76.1305, 39.1178],
              [-76.2727, 39.1516],
              [-76.175, 39.3162],
              [-76.4245, 39.3237],
              [-76.3985, 39.0144],
              [-76.5517, 38.7756],
              [-76.512, 38.5277],
              [-76.3205, 38.1388],
              [-76.6146, 38.1504],
              [-76.5306, 38.0251],
              [-76.2479, 37.9035],
              [-76.3565, 37.5218],
              [-76.2445, 37.4495],
              [-76.3785, 37.2723],
              [-76.2689, 36.9614],
              [-75.9889, 36.912],
              [-75.9279, 36.7062],
              [-75.9164, 36.4027],
              [-75.8597, 36.1616],
              [-76.0204, 36.1915],
              [-76.1954, 36.1035],
              [-76.4117, 36.083],
              [-76.3873, 35.9384],
              [-76.1954, 35.9936],
              [-75.8529, 35.98],
              [-75.7218, 35.8292],
              [-75.7538, 35.6118],
              [-75.8972, 35.579],
              [-76.1504, 35.3472],
              [-76.4425, 35.404],
              [-76.5605, 35.1244],
              [-76.5482, 34.9993],
              [-76.3357, 34.8995],
              [-76.5039, 34.7665],
              [-76.6582, 34.7219],
              [-76.8961, 34.7392],
              [-77.2015, 34.6507],
              [-77.5359, 34.4686],
              [-77.8327, 34.207],
              [-77.9111, 33.9582],
              [-78.0323, 33.8923],
              [-78.2525, 33.9251],
              [-78.559, 33.869],
              [-78.938, 33.6421],
              [-79.1331, 33.4174],
              [-79.203, 33.1929],
              [-79.387, 33.0173],
              [-79.5848, 33.0138],
              [-79.6074, 32.9162],
              [-80.0051, 32.6116],
              [-80.4093, 32.4811],
              [-80.4411, 32.3661],
              [-80.8876, 32.0694],
              [-80.8357, 32.0029],
              [-81.0609, 31.7882],
              [-81.1963, 31.7176],
              [-81.3639, 31.4158],
              [-81.2699, 31.2748],
              [-81.4993, 30.9682],
              [-81.4993, 30.7043],
              [-81.2558, 29.7895],
              [-81.1783, 29.5855],
              [-80.9221, 29.0683],
              [-80.6094, 28.6102],
              [-80.7659, 28.6123],
              [-80.7383, 28.3735],
              [-80.381, 27.6661],
              [-80.3454, 27.5146],
              [-80.0756, 26.9732],
              [-80.0382, 26.8112],
              [-80.1293, 25.8879],
              [-80.3069, 25.6182],
              [-80.3143, 25.3712],
              [-80.4269, 25.2217],
              [-80.5806, 25.2414],
              [-80.7109, 25.1452],
              [-80.855, 25.1862],
              [-81.0865, 25.1214],
              [-81.1771, 25.2278],
              [-81.1492, 25.3954],
              [-81.3217, 25.7827],
              [-81.6644, 25.9814],
              [-81.8009, 26.0986],
              [-81.8559, 26.4489],
              [-82.0391, 26.5279],
              [-82.0821, 26.6802],
              [-82.3138, 26.8494],
              [-82.5618, 27.2763],
              [-82.6608, 27.5203],
              [-82.3982, 27.796],
              [-82.596, 27.9831],
              [-82.6378, 27.7011],
              [-82.8424, 27.8285],
              [-82.7673, 28.1059],
              [-82.7873, 28.1938],
              [-82.6642, 28.4479],
              [-82.6444, 28.9004],
              [-82.8492, 29.1822],
              [-83.0239, 29.1748],
              [-83.2157, 29.4106],
              [-83.4048, 29.5317],
              [-83.4089, 29.6673],
              [-83.5277, 29.716],
              [-83.6806, 29.9213],
              [-84.024, 30.1066],
              [-84.2696, 30.0927],
              [-84.5084, 29.9221],
              [-84.8866, 29.73],
              [-85.3079, 29.6934],
              [-85.3565, 29.8887],
              [-85.6709, 30.123],
              [-85.7086, 30.247],
              [-85.8645, 30.2163],
              [-86.3648, 30.3808],
              [-86.4846, 30.48],
              [-86.6801, 30.4223],
              [-87.1568, 30.4745],
              [-87.3288, 30.3197],
              [-87.7865, 30.2368],
              [-87.9166, 30.4233],
              [-87.9425, 30.6597],
              [-88.0526, 30.6544],
              [-88.1201, 30.3383],
              [-88.3553, 30.4038],
              [-88.4706, 30.3239],
              [-88.7421, 30.3541],
              [-88.9276, 30.4422],
              [-89.0134, 30.3774],
              [-89.3302, 30.3029],
              [-89.4425, 30.1898],
              [-89.7084, 30.1753],
              [-89.9691, 30.2647],
              [-90.0585, 30.3603],
              [-90.2224, 30.3876],
              [-90.405, 30.2202],
              [-90.3391, 30.0661],
              [-90.037, 30.0403],
              [-89.8804, 30.1549],
              [-89.8389, 29.9953],
              [-89.6865, 29.8826],
              [-89.4273, 30.0587],
              [-89.4568, 29.8293],
              [-89.6684, 29.5218],
              [-89.3423, 29.3461],
              [-89.202, 29.3529],
              [-89.0232, 29.1474],
              [-89.1881, 29.0305],
              [-89.3837, 29.0878],
              [-89.4825, 29.2431],
              [-89.7577, 29.3432],
              [-89.9077, 29.477],
              [-90.0363, 29.3659],
              [-90.0802, 29.1747],
              [-90.2511, 29.2474],
              [-90.562, 29.3007],
              [-90.7059, 29.1195],
              [-91.2286, 29.2386],
              [-91.2198, 29.4044],
              [-91.3209, 29.4966],
              [-91.5343, 29.534],
              [-91.6847, 29.7531],
              [-91.8779, 29.7593],
              [-91.9844, 29.832],
              [-92.1341, 29.7568],
              [-92.1074, 29.6204],
              [-92.2765, 29.5338],
              [-92.6831, 29.6041],
              [-93.1996, 29.7728],
              [-93.7394, 29.7372],
              [-93.8541, 29.9866],
              [-93.9327, 29.7762],
              [-94.0601, 29.6818],
              [-94.6543, 29.4519],
              [-94.8761, 29.6658],
              [-95.0239, 29.5422],
              [-94.8888, 29.376],
              [-95.1592, 29.1524],
              [-95.1589, 29.0457],
              [-95.6019, 28.7639],
              [-95.8174, 28.7325],
              [-96.2049, 28.5867],
              [-96.5595, 28.6543],
              [-96.5819, 28.5789],
              [-96.4038, 28.4349],
              [-96.6537, 28.3195],
              [-97.025, 28.0414],
              [-97.1841, 27.8366],
              [-97.4224, 27.3278],
              [-97.5211, 27.2322],
              [-97.446, 27.1327],
              [-97.5502, 27.0092],
              [-97.3519, 26.3538],
              [-97.3105, 26.1233],
              [-97.1393, 25.9658],
              [-97.4254, 25.8549],
              [-97.6796, 26.0346],
              [-98.2227, 26.0754],
              [-98.3693, 26.194],
              [-98.6406, 26.2418],
              [-98.7959, 26.3683],
              [-99.0855, 26.4076],
              [-99.1649, 26.5404],
              [-99.2483, 26.8271],
              [-99.4617, 27.057],
              [-99.432, 27.2076],
              [-99.4976, 27.3388],
              [-99.5072, 27.5738],
              [-99.7004, 27.6592],
              [-99.8634, 27.8047],
              [-99.8788, 27.9018],
              [-100.0749, 28.1544],
              [-100.2843, 28.2965],
              [-100.3971, 28.5927],
              [-100.6243, 28.9247],
              [-100.669, 29.1162],
              [-100.8161, 29.2708],
              [-101.1636, 29.5013],
              [-101.4093, 29.7658],
              [-101.9722, 29.8182],
              [-102.1093, 29.8021],
              [-102.3022, 29.8894],
              [-102.4061, 29.7773],
              [-102.6835, 29.7437],
              [-102.8831, 29.3438],
              [-103.148, 28.9851],
              [-103.3015, 29.0024],
              [-103.6731, 29.1736],
              [-103.7942, 29.2776],
              [-104.0573, 29.339],
              [-104.2672, 29.5266],
              [-104.5308, 29.6679],
              [-104.6805, 29.9423],
              [-104.6816, 30.1929],
              [-105.0085, 30.677],
              [-105.3638, 30.8504],
              [-105.5909, 31.0714],
              [-105.7682, 31.18],
              [-106.0047, 31.3969],
              [-106.1643, 31.4478],
              [-106.3585, 31.7175],
              [-106.5172, 31.7738],
              [-108.2151, 31.7778],
              [-108.2148, 31.3274],
              [-109.9663, 31.3272],
              [-111.0671, 31.3336],
              [-112.3247, 31.728],
              [-113.9617, 32.2341],
              [-114.8221, 32.5002],
              [-114.7243, 32.7128],
              [-115.7941, 32.632],
              [-117.1251, 32.5317],
              [-117.2575, 32.7017],
              [-117.2536, 32.8851],
              [-117.3222, 33.1085],
              [-117.5088, 33.3352],
              [-118.0905, 33.731],
              [-118.3914, 33.8359],
              [-118.5441, 34.0389],
              [-118.9368, 34.0432],
              [-119.2171, 34.1455],
              [-119.2693, 34.255],
              [-119.6172, 34.4205],
              [-119.8786, 34.4066],
              [-120.1334, 34.4727],
              [-120.4727, 34.4503],
              [-120.6478, 34.5857],
              [-120.6031, 34.7048],
              [-120.6728, 34.9075],
              [-120.6449, 35.1394],
              [-120.8977, 35.2498],
              [-120.8711, 35.4053],
              [-121.0054, 35.4607],
              [-121.2691, 35.6642],
              [-121.49, 35.9822],
              [-121.8956, 36.3139],
              [-121.9463, 36.4919],
              [-121.7898, 36.8062],
              [-121.9372, 36.9783],
              [-122.1536, 36.9771],
              [-122.4045, 37.1945],
              [-122.4003, 37.3597],
              [-122.5205, 37.5314],
              [-122.3585, 37.5928],
              [-122.1463, 37.579],
              [-122.4874, 38.1048],
              [-122.5284, 37.8164],
              [-122.9211, 38.0349],
              [-122.9745, 38.2662],
              [-123.6483, 38.8495],
              [-123.8249, 39.3473],
              [-123.7698, 39.5446],
              [-123.854, 39.8335],
              [-124.1158, 40.1071],
              [-124.3635, 40.2615],
              [-124.4092, 40.4438],
              [-124.2138, 40.7014],
              [-124.1104, 41.0153],
              [-124.1243, 41.1818],
              [-124.0622, 41.4359],
              [-124.2188, 41.85],
              [-124.217, 42.0062],
              [-124.3596, 42.113],
              [-124.4328, 42.3293],
              [-124.4145, 42.6624],
              [-124.5477, 42.8454],
              [-124.4449, 43.03],
              [-124.3765, 43.3402],
              [-124.277, 43.4215],
              [-124.151, 43.8817],
              [-124.0632, 44.6095],
              [-124.0652, 44.8233],
              [-123.9711, 45.1537],
              [-123.9711, 45.452],
              [-123.9207, 45.6627],
              [-123.9811, 45.7603],
              [-123.9368, 46.0818],
              [-123.8669, 46.1599],
              [-123.6141, 46.1865],
              [-123.693, 46.3052],
              [-123.9252, 46.2515],
              [-124.0638, 46.3563],
              [-123.8981, 46.4416],
              [-123.913, 46.7278],
              [-124.0934, 46.7432],
              [-124.0129, 46.9837],
              [-124.1719, 47.0419],
              [-124.3178, 47.3574],
              [-124.4228, 47.7419],
              [-124.6381, 47.9079],
              [-124.7346, 48.1704],
              [-124.5749, 48.3704],
              [-124.0502, 48.1773],
              [-123.7003, 48.1649],
              [-122.792, 48.0829],
              [-122.4684, 47.7565],
              [-122.6284, 47.6929],
              [-122.5079, 47.5203],
              [-122.5859, 47.3364],
              [-122.7987, 47.205],
              [-122.7225, 47.0893],
              [-122.3236, 47.3725],
              [-122.4135, 47.6417],
              [-122.3204, 47.9421],
              [-122.3918, 48.3098],
              [-122.5338, 48.3747],
              [-122.4403, 48.5807],
              [-122.5299, 48.7686],
              [-122.7725, 48.8865],
              [-122.753, 48.9925],
              [-121.1145, 48.9925],
              [-119.356, 48.9925],
              [-117.8174, 48.9925],
              [-116.2788, 48.9925],
              [-115.1797, 48.9925],
              [-113.641, 48.9925],
              [-111.6627, 48.9926],
              [-110.5637, 48.9926],
              [-108.3656, 48.9926],
              [-106.6072, 48.9926],
              [-104.409, 48.9926],
              [-102.4307, 48.9926],
              [-100.4525, 48.9927],
              [-99.3535, 48.9927],
              [-97.8148, 48.9927],
              [-96.7158, 48.9927],
              [-95.1771, 48.9927],
              [-95.1606, 49.3695],
              [-94.8273, 49.2929],
              [-94.7112, 48.9146],
              [-94.5929, 48.7264],
              [-94.2322, 48.652],
              [-93.8749, 48.6362],
              [-93.7568, 48.5165],
              [-93.4918, 48.5423],
              [-93.2577, 48.6303],
              [-92.9797, 48.6177],
              [-92.6486, 48.5363],
              [-92.3031, 48.3109],
              [-92.0434, 48.3346],
              [-91.9573, 48.2283],
              [-91.6925, 48.0979],
              [-91.4279, 48.0364],
              [-90.9232, 48.2271],
              [-90.7865, 48.1005],
              [-90.1786, 48.1164],
              [-89.9803, 48.0101],
              [-89.3404, 47.9842],
              [-88.6427, 48.2566],
              [-88.3473, 48.2987],
              [-86.6371, 47.6241],
              [-84.8787, 46.8979],
              [-84.7733, 46.6334],
              [-84.5894, 46.4751],
              [-84.1104, 46.5265],
              [-84.1588, 46.4333],
              [-84.0868, 46.2012],
              [-83.9473, 46.0606],
              [-83.6139, 46.1156],
              [-83.4659, 45.9931],
              [-83.5953, 45.8164],
              [-82.5888, 45.3718],
              [-82.5321, 45.2935],
              [-82.2918, 44.2422],
              [-82.1536, 43.5496],
              [-82.4111, 43.0513],
              [-82.5113, 42.6467],
              [-82.8001, 42.418],
              [-83.1285, 42.24],
              [-83.157, 42.0004],
              [-83.0684, 41.8477],
              [-82.7111, 41.6802],
              [-82.4249, 41.6768],
              [-81.2767, 42.2087],
              [-80.2466, 42.3655],
              [-79.0194, 42.8027],
              [-78.9153, 42.9476],
              [-79.032, 43.0134],
              [-79.0538, 43.2604],
              [-79.1743, 43.4645],
              [-78.6881, 43.6318],
              [-76.8415, 43.6255],
              [-76.4546, 44.0842],
              [-76.0673, 44.348],
              [-75.8703, 44.4086],
              [-75.6997, 44.5817],
              [-75.2702, 44.8638],
              [-74.8685, 45.0101],
              [-74.713, 44.9993],
            ],
          ],
          [
            [
              [172.9041, 53.0012],
              [173.3013, 52.9256],
              [173.1886, 52.7957],
              [172.9443, 52.7498],
              [172.6404, 52.8688],
              [172.6604, 53.0086],
              [172.9041, 53.0012],
            ],
          ],
          [
            [
              [-168.0083, 53.5703],
              [-167.7948, 53.502],
              [-167.85, 53.3854],
              [-168.2876, 53.243],
              [-168.4899, 53.0421],
              [-168.7513, 52.9602],
              [-168.7595, 53.1867],
              [-168.1998, 53.5402],
              [-168.0083, 53.5703],
            ],
          ],
          [
            [
              [-166.7464, 53.975],
              [-166.4558, 53.8961],
              [-166.2735, 53.687],
              [-166.5472, 53.6259],
              [-166.6564, 53.5024],
              [-166.8768, 53.4815],
              [-167.6651, 53.2419],
              [-167.85, 53.3096],
              [-167.4746, 53.434],
              [-167.1586, 53.4771],
              [-167.1554, 53.6226],
              [-166.9465, 53.7158],
              [-167.1634, 53.8574],
              [-167.0216, 53.9612],
              [-166.7464, 53.975],
            ],
          ],
          [
            [
              [-163.7565, 55.0604],
              [-163.5716, 55.057],
              [-163.3695, 54.7646],
              [-163.6225, 54.6118],
              [-164.1327, 54.6232],
              [-164.4056, 54.436],
              [-164.8453, 54.4235],
              [-164.9582, 54.5852],
              [-164.7007, 54.6783],
              [-164.5474, 54.8828],
              [-164.314, 54.893],
              [-163.7565, 55.0604],
            ],
          ],
          [
            [
              [-131.2089, 55.9449],
              [-130.9687, 55.7011],
              [-130.9672, 55.4238],
              [-131.192, 55.1857],
              [-131.6615, 55.3432],
              [-131.8103, 55.4766],
              [-131.5788, 55.9072],
              [-131.2089, 55.9449],
            ],
          ],
          [
            [
              [-132.4838, 56.1946],
              [-132.0922, 56.1064],
              [-132.1226, 55.9455],
              [-132.3034, 55.9154],
              [-132.4838, 56.1946],
            ],
          ],
          [
            [
              [-133.5868, 56.3529],
              [-133.1821, 56.3323],
              [-133.0539, 56.2344],
              [-133.0628, 56.0523],
              [-132.7364, 55.9966],
              [-132.3467, 55.6431],
              [-132.4013, 55.5168],
              [-132.0907, 55.2768],
              [-131.9943, 55.2681],
              [-131.9488, 54.79],
              [-132.004, 54.6938],
              [-132.2976, 54.7445],
              [-132.2648, 54.8445],
              [-132.5794, 54.9544],
              [-132.6086, 55.1192],
              [-132.9596, 55.2766],
              [-133.213, 55.2792],
              [-133.0631, 55.6235],
              [-133.2513, 55.5848],
              [-133.3852, 55.7093],
              [-133.1479, 55.8184],
              [-133.2367, 55.9003],
              [-133.2855, 56.1674],
              [-133.6122, 56.2123],
              [-133.5868, 56.3529],
            ],
          ],
          [
            [
              [-132.6409, 56.4422],
              [-132.6472, 56.2824],
              [-132.8689, 56.2435],
              [-133.0489, 56.3628],
              [-132.9242, 56.452],
              [-132.6409, 56.4422],
            ],
          ],
          [
            [
              [-132.1515, 56.352],
              [-131.9283, 56.1946],
              [-132.0723, 56.1195],
              [-132.3529, 56.2778],
              [-132.1515, 56.352],
            ],
          ],
          [
            [
              [-132.8822, 56.8012],
              [-132.5334, 56.6003],
              [-132.9375, 56.5176],
              [-132.8822, 56.8012],
            ],
          ],
          [
            [
              [-133.9867, 56.8656],
              [-133.8012, 56.777],
              [-133.8452, 56.4344],
              [-134.2001, 56.1505],
              [-134.282, 56.3628],
              [-134.0674, 56.4776],
              [-134.3576, 56.6766],
              [-134.4086, 56.8429],
              [-134.1759, 56.922],
              [-133.9867, 56.8656],
            ],
          ],
          [
            [
              [-133.7646, 57.0768],
              [-132.9804, 56.935],
              [-132.9339, 56.6416],
              [-133.1479, 56.4621],
              [-133.6594, 56.4642],
              [-133.6966, 56.832],
              [-134.0182, 57.0176],
              [-133.7646, 57.0768],
            ],
          ],
          [
            [
              [-135.4098, 57.449],
              [-135.1422, 57.4694],
              [-134.9095, 57.3398],
              [-134.6161, 56.7285],
              [-134.6488, 56.3392],
              [-134.75, 56.2174],
              [-135.0528, 56.5378],
              [-135.1547, 56.7459],
              [-135.3675, 56.8289],
              [-135.3103, 57.0473],
              [-135.6732, 57.3497],
              [-135.4098, 57.449],
            ],
          ],
          [
            [
              [-153.0128, 57.9291],
              [-152.4863, 57.9107],
              [-152.4678, 57.6572],
              [-152.1609, 57.6271],
              [-152.3303, 57.4459],
              [-152.591, 57.4509],
              [-152.6922, 57.2833],
              [-153.2533, 57.2367],
              [-153.5598, 56.9865],
              [-153.9625, 56.7482],
              [-154.5286, 56.9916],
              [-154.535, 57.1746],
              [-154.81, 57.3466],
              [-154.6147, 57.5266],
              [-154.2393, 57.6733],
              [-153.754, 57.562],
              [-153.9246, 57.8105],
              [-153.5744, 57.8395],
              [-153.1919, 57.8052],
              [-153.0128, 57.9291],
            ],
          ],
          [
            [
              [-135.715, 58.2297],
              [-134.9248, 58.0275],
              [-134.9425, 57.8066],
              [-134.8139, 57.4975],
              [-135.0569, 57.4666],
              [-135.628, 57.706],
              [-135.5463, 57.4661],
              [-135.7074, 57.3665],
              [-135.9491, 57.4619],
              [-136.3948, 57.8857],
              [-136.2969, 58.0106],
              [-136.4053, 58.1339],
              [-135.715, 58.2297],
            ],
          ],
          [
            [
              [-134.9171, 58.3735],
              [-134.6932, 58.1684],
              [-134.1864, 58.1684],
              [-134.1693, 58.0778],
              [-134.3122, 57.9794],
              [-133.9379, 57.6186],
              [-133.865, 57.3551],
              [-134.1274, 57.2646],
              [-134.5018, 57.029],
              [-134.6215, 57.2299],
              [-134.4639, 57.3944],
              [-134.6536, 57.5982],
              [-134.7978, 58.1128],
              [-134.9171, 58.3735],
            ],
          ],
          [
            [
              [-152.6068, 58.4685],
              [-152.4769, 58.3615],
              [-152.0951, 58.3676],
              [-151.9722, 58.2373],
              [-152.3311, 58.165],
              [-152.8049, 57.9915],
              [-153.2296, 58.1689],
              [-153.0417, 58.3056],
              [-152.7933, 58.2868],
              [-152.6068, 58.4685],
            ],
          ],
          [
            [
              [-147.1142, 60.3567],
              [-146.9223, 60.3135],
              [-147.4903, 59.9392],
              [-147.7031, 59.9975],
              [-147.2012, 60.2422],
              [-147.1142, 60.3567],
            ],
          ],
          [
            [
              [-166.1027, 60.392],
              [-165.6844, 60.2998],
              [-165.7329, 60.1763],
              [-165.5549, 59.9346],
              [-166.244, 59.8078],
              [-166.6134, 59.8564],
              [-167.1898, 60.0278],
              [-167.2881, 60.2387],
              [-166.874, 60.2106],
              [-166.4592, 60.3872],
              [-166.1027, 60.392],
            ],
          ],
          [
            [
              [-146.5416, 60.4686],
              [-146.3336, 60.3522],
              [-146.6002, 60.2452],
              [-146.7226, 60.3862],
              [-146.5416, 60.4686],
            ],
          ],
          [
            [
              [-169, 63.3294],
              [-168.7152, 63.312],
              [-168.8118, 63.1701],
              [-169, 63.1793],
              [-169, 63.3294],
            ],
          ],
          [
            [
              [-141.0056, 69.6509],
              [-141.005, 68.4847],
              [-141.0042, 66.8811],
              [-141.0036, 65.5692],
              [-141.0031, 64.5487],
              [-141.0026, 63.3825],
              [-141.0023, 62.6536],
              [-141.0018, 61.6331],
              [-141.0012, 60.3211],
              [-140.5337, 60.2185],
              [-140.4241, 60.2932],
              [-140.0158, 60.1874],
              [-139.6283, 60.3341],
              [-139.1016, 60.3466],
              [-139.1821, 60.0734],
              [-138.6921, 59.8869],
              [-138.5992, 59.7538],
              [-137.6114, 59.2393],
              [-137.4231, 58.9077],
              [-136.8408, 59.1482],
              [-136.6135, 59.1542],
              [-136.4665, 59.2878],
              [-136.4676, 59.4616],
              [-136.2585, 59.6216],
              [-135.9238, 59.6667],
              [-135.4828, 59.7925],
              [-135.0162, 59.5434],
              [-135.0958, 59.4189],
              [-134.9324, 59.2706],
              [-134.7055, 59.2401],
              [-134.398, 59.052],
              [-134.2222, 58.8427],
              [-133.8712, 58.7359],
              [-133.4631, 58.4622],
              [-133.3746, 58.291],
              [-133.1656, 58.1473],
              [-132.5524, 57.4991],
              [-132.231, 57.1968],
              [-132.3412, 57.0804],
              [-132.0319, 57.0284],
              [-132.1074, 56.8588],
              [-131.8716, 56.7935],
              [-131.7991, 56.5877],
              [-131.5859, 56.595],
              [-131.1005, 56.4077],
              [-130.8391, 56.3725],
              [-130.4955, 56.2324],
              [-130.404, 56.1219],
              [-130.0715, 56.0841],
              [-130.0196, 55.908],
              [-130.1737, 55.7495],
              [-130.147, 55.5472],
              [-129.9892, 55.284],
              [-130.3654, 54.9073],
              [-130.5803, 54.8064],
              [-130.9342, 54.8244],
              [-130.9497, 55.0738],
              [-131.0576, 55.1987],
              [-130.8732, 55.342],
              [-130.8899, 55.7153],
              [-131.1652, 55.9406],
              [-131.4144, 56.0102],
              [-131.7359, 55.8867],
              [-131.9614, 55.501],
              [-132.1716, 55.5885],
              [-132.2615, 55.7397],
              [-131.9435, 56.0069],
              [-131.963, 56.1574],
              [-131.8138, 56.196],
              [-131.9713, 56.3564],
              [-132.4769, 56.6045],
              [-132.4795, 56.7428],
              [-132.8604, 56.9189],
              [-133.093, 57.0865],
              [-133.552, 57.1815],
              [-133.3915, 57.3352],
              [-133.5729, 57.7161],
              [-133.8612, 57.9567],
              [-134.0597, 58.0722],
              [-134.0677, 58.2789],
              [-134.2416, 58.2143],
              [-134.498, 58.3539],
              [-134.7673, 58.4003],
              [-135.1436, 58.8339],
              [-135.2262, 58.6123],
              [-135.0528, 58.3093],
              [-135.3096, 58.2521],
              [-135.4159, 58.395],
              [-135.8411, 58.5284],
              [-136.0603, 58.8259],
              [-136.2691, 58.7641],
              [-136.0267, 58.3881],
              [-136.5984, 58.223],
              [-136.9794, 58.4069],
              [-137.1314, 58.4013],
              [-137.5185, 58.5648],
              [-137.931, 58.803],
              [-138.1849, 59.0256],
              [-139.2059, 59.3198],
              [-139.857, 59.5463],
              [-139.6576, 59.5755],
              [-139.4734, 59.7176],
              [-139.6211, 59.8903],
              [-140.3227, 59.7012],
              [-140.8631, 59.7456],
              [-141.2742, 59.8492],
              [-141.3859, 60.0348],
              [-141.7327, 59.9631],
              [-141.9691, 60.0281],
              [-142.7985, 60.1124],
              [-143.6096, 60.0495],
              [-143.9186, 59.997],
              [-144.3476, 60.2011],
              [-144.5711, 60.1906],
              [-144.9145, 60.3812],
              [-145.2359, 60.3681],
              [-145.5726, 60.4511],
              [-145.9388, 60.4561],
              [-145.6918, 60.6567],
              [-146.5115, 60.8186],
              [-146.8155, 61.0318],
              [-147.1007, 61.0153],
              [-147.3646, 60.8913],
              [-148.3065, 60.8957],
              [-148.2646, 60.7608],
              [-147.9419, 60.4431],
              [-148.402, 59.9914],
              [-148.5618, 59.9571],
              [-149.3411, 60.0224],
              [-149.7386, 59.8909],
              [-149.9165, 59.7176],
              [-150.3262, 59.476],
              [-150.6531, 59.4239],
              [-151.0925, 59.2851],
              [-151.7415, 59.1671],
              [-151.9772, 59.2794],
              [-151.894, 59.4128],
              [-151.2733, 59.56],
              [-151.7469, 59.6828],
              [-151.8829, 59.7865],
              [-151.706, 60.0417],
              [-151.4654, 60.1877],
              [-151.2828, 60.5371],
              [-151.4173, 60.7182],
              [-151.0715, 60.7853],
              [-150.4525, 61.0342],
              [-150.0438, 60.9168],
              [-149.618, 60.9946],
              [-150.0673, 61.1557],
              [-150.6336, 61.2987],
              [-151.0609, 61.1633],
              [-151.1548, 61.0644],
              [-151.7469, 60.9168],
              [-151.8407, 60.7511],
              [-152.3401, 60.4761],
              [-152.416, 60.2978],
              [-152.6984, 60.1701],
              [-152.5903, 60.0922],
              [-152.7379, 59.9084],
              [-153.0212, 59.8889],
              [-153.0553, 59.7102],
              [-153.5732, 59.6422],
              [-153.7247, 59.4661],
              [-154.0764, 59.3812],
              [-154.1984, 59.077],
              [-153.7115, 59.0707],
              [-153.3316, 58.9338],
              [-153.4388, 58.7336],
              [-153.6907, 58.6216],
              [-153.8994, 58.6167],
              [-154.0002, 58.3856],
              [-154.3001, 58.3204],
              [-154.1657, 58.2168],
              [-154.4432, 58.1445],
              [-154.5831, 58.025],
              [-155.0231, 58.0256],
              [-155.0687, 57.8974],
              [-155.3347, 57.8265],
              [-155.3944, 57.7257],
              [-156.3389, 57.4154],
              [-156.346, 57.1778],
              [-156.5742, 56.9832],
              [-156.7675, 57.0118],
              [-157.1894, 56.7763],
              [-157.4042, 56.7737],
              [-157.9134, 56.6539],
              [-158.068, 56.5119],
              [-158.3551, 56.4711],
              [-158.3183, 56.1747],
              [-158.4836, 56.0324],
              [-158.8594, 56.0129],
              [-159.6252, 55.8127],
              [-159.8304, 55.8527],
              [-160.4775, 55.4946],
              [-161.025, 55.4274],
              [-161.2792, 55.3549],
              [-161.5184, 55.3834],
              [-161.3887, 55.5722],
              [-161.6208, 55.613],
              [-161.7164, 55.3933],
              [-161.9566, 55.1257],
              [-162.2748, 55.0196],
              [-162.4165, 55.0979],
              [-162.8776, 54.939],
              [-163.0411, 55.0994],
              [-162.6533, 55.3615],
              [-162.227, 55.6979],
              [-161.8263, 55.8799],
              [-161.4577, 55.9539],
              [-161.1242, 55.9612],
              [-160.8204, 55.7392],
              [-160.5207, 55.9352],
              [-160.5681, 56.0076],
              [-160.3687, 56.2768],
              [-159.7457, 56.5793],
              [-159.4564, 56.6293],
              [-158.945, 56.8429],
              [-158.6815, 56.7866],
              [-158.6821, 57.022],
              [-158.3124, 57.3149],
              [-157.7097, 57.5687],
              [-157.7088, 57.7299],
              [-157.5027, 58.4637],
              [-157.0619, 58.7262],
              [-157.0845, 58.8867],
              [-158.1537, 58.614],
              [-158.4083, 58.7701],
              [-158.7939, 58.8164],
              [-158.8894, 58.7063],
              [-158.77, 58.5185],
              [-159.0513, 58.4247],
              [-159.385, 58.7558],
              [-159.7252, 58.9104],
              [-159.9115, 58.7758],
              [-160.2572, 58.9119],
              [-160.3522, 59.0707],
              [-161.3575, 58.7262],
              [-161.5624, 58.602],
              [-161.7174, 58.7548],
              [-161.7995, 58.984],
              [-161.9741, 59.1314],
              [-161.9466, 59.3878],
              [-161.7139, 59.5014],
              [-162.3749, 60.1763],
              [-162.5258, 59.9975],
              [-163.0876, 59.8591],
              [-163.6149, 59.8008],
              [-164.0983, 59.8337],
              [-164.2006, 60.0343],
              [-164.4853, 60.1837],
              [-164.989, 60.3414],
              [-165.3143, 60.5809],
              [-164.6691, 60.8258],
              [-163.9556, 60.7799],
              [-163.7944, 60.5842],
              [-163.4643, 60.6766],
              [-163.4243, 60.7647],
              [-163.7659, 60.8895],
              [-164.5644, 60.8547],
              [-164.628, 60.9323],
              [-165.129, 60.9235],
              [-165.0587, 61.0629],
              [-165.6217, 61.1435],
              [-165.6237, 61.2805],
              [-165.9213, 61.3994],
              [-165.777, 61.5158],
              [-166.1999, 61.5945],
              [-165.9112, 61.7042],
              [-166.1028, 61.8211],
              [-165.6091, 61.8513],
              [-165.7391, 61.9651],
              [-165.7056, 62.1166],
              [-165.133, 62.5157],
              [-164.8132, 62.6202],
              [-164.8832, 62.8415],
              [-164.5791, 63.1406],
              [-164.1454, 63.2622],
              [-163.7673, 63.2248],
              [-163.3505, 63.0308],
              [-163.094, 63.0574],
              [-162.5965, 63.2767],
              [-162.2373, 63.5463],
              [-162.087, 63.4523],
              [-161.5734, 63.4554],
              [-161.1863, 63.5056],
              [-160.7916, 63.7465],
              [-160.9735, 64.2505],
              [-161.457, 64.5211],
              [-161.035, 64.5078],
              [-160.8032, 64.6434],
              [-160.8847, 64.8101],
              [-161.1826, 64.9395],
              [-161.4222, 64.7744],
              [-161.7164, 64.7949],
              [-162.0809, 64.7211],
              [-162.6072, 64.5068],
              [-164.3419, 64.59],
              [-165.0355, 64.4483],
              [-166.1897, 64.5852],
              [-166.4835, 64.7296],
              [-166.3902, 64.8985],
              [-166.7495, 65.062],
              [-166.2516, 65.2581],
              [-166.3101, 65.3122],
              [-167.3986, 65.4011],
              [-168.081, 65.5915],
              [-167.3459, 65.8986],
              [-167.0599, 65.8776],
              [-166.725, 66.0613],
              [-165.89, 66.241],
              [-165.3057, 66.437],
              [-164.9506, 66.4602],
              [-164.7181, 66.5563],
              [-163.9864, 66.6123],
              [-163.7591, 66.5048],
              [-163.8956, 66.3993],
              [-163.8905, 66.1676],
              [-163.6805, 66.0786],
              [-162.6547, 66.0385],
              [-161.9156, 66.0424],
              [-161.5712, 66.258],
              [-161.1143, 66.2069],
              [-161.1357, 66.3432],
              [-161.7446, 66.4055],
              [-162.6387, 66.8996],
              [-162.3271, 66.9604],
              [-162.015, 66.7724],
              [-162.0808, 66.6662],
              [-161.6072, 66.4539],
              [-161.061, 66.4862],
              [-160.7848, 66.3745],
              [-160.2491, 66.4023],
              [-160.2192, 66.532],
              [-160.881, 66.6724],
              [-161.3137, 66.5353],
              [-161.8683, 66.7063],
              [-161.7307, 66.9536],
              [-161.8352, 67.055],
              [-162.4355, 66.991],
              [-163.6883, 67.1032],
              [-163.81, 67.3352],
              [-164.1515, 67.6196],
              [-165.4372, 68.0602],
              [-165.9281, 68.1369],
              [-166.5482, 68.3588],
              [-166.24, 68.5643],
              [-166.2368, 68.8748],
              [-165.6647, 68.8616],
              [-164.3464, 68.9294],
              [-163.6225, 69.1192],
              [-163.1558, 69.3594],
              [-162.9498, 69.7905],
              [-162.5378, 69.9567],
              [-162.015, 70.2773],
              [-161.3422, 70.2588],
              [-160.8607, 70.3473],
              [-159.9479, 70.6364],
              [-159.6682, 70.805],
              [-159.3568, 70.7602],
              [-158.043, 70.8369],
              [-157.2312, 71.0714],
              [-156.8172, 71.3063],
              [-155.9993, 71.1945],
              [-155.5937, 71.0697],
              [-156.0872, 70.927],
              [-155.5424, 70.8664],
              [-155.1093, 71.1504],
              [-154.5971, 71.0005],
              [-154.5388, 70.8262],
              [-154.131, 70.7913],
              [-153.8935, 70.8984],
              [-153.3024, 70.9287],
              [-152.5458, 70.8875],
              [-152.2192, 70.818],
              [-152.5141, 70.6889],
              [-152.4562, 70.5559],
              [-151.9797, 70.4487],
              [-151.1777, 70.4487],
              [-150.5131, 70.5039],
              [-150.1191, 70.4429],
              [-149.8897, 70.5128],
              [-149.3445, 70.5101],
              [-148.3814, 70.3125],
              [-147.1237, 70.1675],
              [-145.8802, 70.175],
              [-145.5997, 70.0808],
              [-144.9416, 69.978],
              [-144.3861, 70.044],
              [-143.2357, 70.1182],
              [-142.6008, 70.0131],
              [-142.2709, 69.856],
              [-141.6533, 69.7622],
              [-141.4129, 69.6504],
              [-141.0056, 69.6509],
            ],
          ],
        ],
      },
      properties: {
        NAME: "United States",
        NAME_LONG: "United States",
        ADM0_A3: "USA",
        ISO_A2: "US",
        ISO_A3: "USA",
        WB_A2: "US",
        WB_A3: "USA",
      },
      id: 238,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [71.0086, 40.1578],
              [71.2211, 39.9317],
              [71.1137, 39.8745],
              [70.953, 40.0792],
              [71.0086, 40.1578],
            ],
          ],
          [
            [
              [70.9478, 42.2481],
              [71.2179, 42.2064],
              [70.8251, 41.9363],
              [70.5501, 41.8241],
              [70.4537, 41.7121],
              [70.1693, 41.5783],
              [70.4772, 41.4047],
              [70.6338, 41.4675],
              [70.7563, 41.2696],
              [70.9142, 41.193],
              [71.2761, 41.1132],
              [71.4215, 41.1621],
              [71.4126, 41.3347],
              [71.5855, 41.3235],
              [71.6841, 41.5341],
              [71.863, 41.3122],
              [71.8716, 41.1945],
              [72.1699, 41.1687],
              [72.1856, 41.0411],
              [72.4767, 41.0118],
              [72.6195, 40.8801],
              [73.0034, 40.8702],
              [73.118, 40.7829],
              [72.7839, 40.6697],
              [72.5858, 40.5087],
              [72.4479, 40.5605],
              [72.3705, 40.3856],
              [72.0993, 40.4264],
              [71.6731, 40.1479],
              [71.3651, 40.2941],
              [70.959, 40.2384],
              [70.5818, 40.2217],
              [70.3538, 40.4561],
              [70.7562, 40.6951],
              [70.6969, 40.8162],
              [70.4457, 41.0259],
              [70.2804, 40.8777],
              [69.6601, 40.6387],
              [69.4358, 40.8027],
              [69.198, 40.5458],
              [69.3063, 40.365],
              [69.2853, 40.2186],
              [69.0192, 40.2321],
              [68.602, 40.1754],
              [68.8774, 39.9006],
              [68.6158, 39.8281],
              [68.5175, 39.5484],
              [68.3376, 39.5279],
              [67.7645, 39.6226],
              [67.4365, 39.4857],
              [67.3782, 39.1995],
              [67.6198, 39.1513],
              [67.6905, 38.9896],
              [68.0856, 38.9938],
              [68.1726, 38.8453],
              [68.0376, 38.6916],
              [68.0552, 38.5363],
              [68.1837, 38.3355],
              [68.3607, 38.1741],
              [68.2487, 37.949],
              [67.8292, 37.5279],
              [67.7805, 37.1889],
              [67.4824, 37.2774],
              [67.2368, 37.1924],
              [67.0059, 37.3844],
              [66.5196, 37.3642],
              [66.5188, 37.7827],
              [66.6458, 37.9459],
              [66.5542, 38.0269],
              [65.8767, 38.273],
              [65.6041, 38.2374],
              [65.2053, 38.4496],
              [64.9777, 38.6123],
              [64.6349, 38.7441],
              [64.3373, 38.971],
              [64.1206, 38.9617],
              [63.7093, 39.1909],
              [63.4776, 39.4029],
              [62.5932, 39.8902],
              [62.4528, 40.0092],
              [62.3533, 40.4304],
              [62.1918, 40.499],
              [62.0448, 40.715],
              [61.9708, 41.0154],
              [61.8779, 41.125],
              [61.4701, 41.2972],
              [61.2814, 41.1573],
              [60.8603, 41.253],
              [60.4145, 41.2353],
              [60.0942, 41.3895],
              [60.1556, 41.6033],
              [60.0492, 41.7535],
              [60.2411, 41.8081],
              [59.9837, 41.949],
              [60.0282, 42.1995],
              [59.8664, 42.3042],
              [59.4782, 42.2856],
              [59.2452, 42.3653],
              [59.122, 42.538],
              [58.8944, 42.5478],
              [58.6123, 42.7809],
              [58.4932, 42.6455],
              [58.2827, 42.6889],
              [57.9977, 42.4934],
              [57.8505, 42.1914],
              [57.3823, 42.1636],
              [56.9501, 41.8661],
              [57.0196, 41.4659],
              [57.1538, 41.3724],
              [57.0102, 41.2541],
              [56.7935, 41.2883],
              [55.9784, 41.3217],
              [55.9774, 42.6985],
              [55.9768, 43.6164],
              [55.9784, 44.9962],
              [57.4783, 45.3369],
              [58.5314, 45.5587],
              [58.5822, 45.5487],
              [59.6758, 45.0291],
              [61.0363, 44.3828],
              [61.1617, 44.1681],
              [61.9857, 43.4933],
              [62.0261, 43.4806],
              [63.208, 43.6275],
              [64.4267, 43.547],
              [64.855, 43.7129],
              [64.9565, 43.6974],
              [65.2714, 43.417],
              [65.5169, 43.2972],
              [65.7952, 42.8772],
              [66.1013, 42.9903],
              [66.0532, 42.4409],
              [66.0137, 42.403],
              [66.0172, 41.9976],
              [66.5041, 41.9935],
              [66.5389, 41.7408],
              [66.6882, 41.1992],
              [66.8153, 41.1419],
              [67.9374, 41.2004],
              [68.1313, 41.0296],
              [68.0675, 40.788],
              [68.4617, 40.5847],
              [68.6274, 40.6671],
              [68.5497, 40.835],
              [68.7463, 41.0638],
              [69.0392, 41.2203],
              [69.0444, 41.3792],
              [69.6246, 41.6683],
              [69.8709, 41.6996],
              [70.1364, 41.8481],
              [70.3151, 42.0346],
              [70.4554, 42.0937],
              [70.6102, 42.0274],
              [70.7343, 42.1973],
              [70.9478, 42.2481],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Uzbekistan",
        NAME_LONG: "Uzbekistan",
        ADM0_A3: "UZB",
        ISO_A2: "UZ",
        ISO_A3: "UZB",
        WB_A2: "UZ",
        WB_A3: "UZB",
      },
      id: 240,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-60.7281, 8.8785],
              [-60.686, 8.789],
              [-60.8466, 8.6377],
              [-61.0939, 8.6337],
              [-61.1264, 8.7565],
              [-60.8617, 8.8702],
              [-60.7281, 8.8785],
            ],
          ],
          [
            [
              [-63.7934, 11.0011],
              [-63.8981, 10.885],
              [-64.0426, 10.8685],
              [-63.9858, 11.1093],
              [-63.8529, 11.1448],
              [-63.7934, 11.0011],
            ],
          ],
          [
            [
              [-60.021, 8.558],
              [-59.8156, 8.2878],
              [-59.9933, 8.1685],
              [-60.0195, 8.0603],
              [-60.2311, 7.9641],
              [-60.3748, 7.8236],
              [-60.5033, 7.8209],
              [-60.6013, 7.6339],
              [-60.7306, 7.5254],
              [-60.6018, 7.3301],
              [-60.6431, 7.2209],
              [-60.2923, 7.1053],
              [-60.421, 6.9422],
              [-60.9338, 6.7317],
              [-61.1298, 6.7158],
              [-61.2048, 6.5958],
              [-61.139, 6.4248],
              [-61.1216, 6.1867],
              [-61.2694, 6.1072],
              [-61.3796, 5.9053],
              [-60.7399, 5.2021],
              [-60.6643, 5.1703],
              [-60.6126, 4.9006],
              [-60.7637, 4.7551],
              [-60.9034, 4.6996],
              [-61.012, 4.5183],
              [-61.2973, 4.5237],
              [-61.4582, 4.4191],
              [-61.5421, 4.263],
              [-61.7189, 4.2547],
              [-61.9315, 4.1464],
              [-62.1927, 4.0947],
              [-62.3842, 4.1735],
              [-62.561, 4.0378],
              [-62.7662, 4.0207],
              [-62.7496, 3.6604],
              [-62.8894, 3.5608],
              [-62.9971, 3.5991],
              [-63.286, 3.9208],
              [-63.4254, 3.9684],
              [-63.5107, 3.8545],
              [-63.6228, 3.9352],
              [-63.8751, 3.9498],
              [-64.0638, 3.9116],
              [-64.1251, 4.0885],
              [-64.365, 4.1519],
              [-64.5897, 4.1189],
              [-64.6629, 3.997],
              [-64.5314, 3.8535],
              [-64.3242, 3.7241],
              [-64.203, 3.5947],
              [-64.2456, 3.4189],
              [-64.2229, 3.124],
              [-63.9979, 2.706],
              [-64.048, 2.4713],
              [-63.8313, 2.4286],
              [-63.3848, 2.4206],
              [-63.4118, 2.1494],
              [-63.6175, 2.1012],
              [-63.7626, 1.9857],
              [-63.995, 1.958],
              [-64.0536, 1.8937],
              [-64.0809, 1.6474],
              [-64.3015, 1.4467],
              [-64.409, 1.5075],
              [-64.7309, 1.2476],
              [-64.8393, 1.2708],
              [-65.0225, 1.14],
              [-65.1368, 1.1269],
              [-65.203, 0.9238],
              [-65.3272, 0.9103],
              [-65.4512, 0.6901],
              [-65.5873, 0.7391],
              [-65.517, 0.8628],
              [-65.5829, 0.9777],
              [-65.7454, 0.9742],
              [-65.9741, 0.807],
              [-66.1563, 0.733],
              [-66.3462, 0.7594],
              [-66.8751, 1.2225],
              [-66.8825, 1.326],
              [-67.0668, 1.8941],
              [-67.2177, 2.2845],
              [-67.1976, 2.402],
              [-67.3254, 2.4746],
              [-67.6266, 2.8134],
              [-67.8386, 2.8861],
              [-67.4522, 3.2437],
              [-67.3046, 3.4257],
              [-67.4039, 3.5045],
              [-67.4714, 3.6801],
              [-67.6318, 3.7619],
              [-67.8049, 4.2685],
              [-67.8537, 4.5758],
              [-67.7931, 5.0633],
              [-67.8437, 5.2972],
              [-67.647, 5.4834],
              [-67.6012, 5.8206],
              [-67.4286, 6.0385],
              [-67.574, 6.2662],
              [-67.8391, 6.3075],
              [-67.9241, 6.2346],
              [-68.3042, 6.177],
              [-68.449, 6.195],
              [-68.6353, 6.1359],
              [-69.0611, 6.2178],
              [-69.2461, 6.0807],
              [-69.4436, 6.1222],
              [-70.0966, 6.9444],
              [-70.3191, 6.9383],
              [-70.7033, 7.0999],
              [-71.184, 6.9626],
              [-71.2835, 7.0188],
              [-71.6543, 7.0534],
              [-71.8326, 6.9864],
              [-72.081, 7.0666],
              [-72.2062, 7.3819],
              [-72.4513, 7.4402],
              [-72.4878, 7.9492],
              [-72.3358, 8.1039],
              [-72.3868, 8.3386],
              [-72.6754, 8.6515],
              [-72.7831, 9.0599],
              [-72.9364, 9.0992],
              [-73.0097, 9.2954],
              [-73.1762, 9.1909],
              [-73.3778, 9.1647],
              [-72.9776, 9.8381],
              [-72.9877, 9.9994],
              [-72.9076, 10.4525],
              [-72.7061, 10.8113],
              [-72.4815, 11.1325],
              [-72.2566, 11.1678],
              [-71.9711, 11.6619],
              [-71.4495, 11.7955],
              [-71.3275, 11.85],
              [-71.3782, 11.7584],
              [-71.9605, 11.5913],
              [-71.9393, 11.3808],
              [-71.7698, 11.1064],
              [-71.6936, 10.8371],
              [-71.5763, 10.7211],
              [-71.6295, 10.454],
              [-71.779, 10.3417],
              [-71.8977, 10.1392],
              [-71.9825, 10.0883],
              [-72.1244, 9.8261],
              [-71.9989, 9.6547],
              [-71.9675, 9.4982],
              [-71.7304, 9.3554],
              [-71.7589, 9.1201],
              [-71.5148, 9.049],
              [-71.2735, 9.1351],
              [-71.0561, 9.3636],
              [-71.0902, 9.5554],
              [-71.0419, 9.7609],
              [-71.2069, 9.9806],
              [-71.2678, 10.1519],
              [-71.3639, 10.22],
              [-71.5265, 10.5447],
              [-71.5314, 10.7527],
              [-71.4459, 10.7962],
              [-71.4583, 10.9749],
              [-71.2939, 10.9866],
              [-70.8697, 11.1963],
              [-70.5139, 11.2532],
              [-70.18, 11.3764],
              [-70.0505, 11.5211],
              [-69.8141, 11.4256],
              [-69.7471, 11.4808],
              [-69.8495, 11.6937],
              [-70.1865, 11.6027],
              [-70.2968, 11.8719],
              [-70.2058, 12.112],
              [-70.0177, 12.1989],
              [-69.8353, 12.0152],
              [-69.8086, 11.8036],
              [-69.7003, 11.5402],
              [-69.6236, 11.4678],
              [-69.2704, 11.536],
              [-69.0619, 11.4535],
              [-68.8632, 11.4529],
              [-68.4176, 11.1791],
              [-68.2627, 10.9031],
              [-68.3315, 10.8068],
              [-68.2423, 10.5784],
              [-68.1295, 10.4877],
              [-67.9412, 10.4665],
              [-67.5602, 10.5283],
              [-67.17, 10.556],
              [-67.0124, 10.6112],
              [-66.5416, 10.6327],
              [-66.1246, 10.602],
              [-66.1183, 10.498],
              [-65.8685, 10.3096],
              [-65.5804, 10.1855],
              [-65.1695, 10.0973],
              [-64.7481, 10.1069],
              [-64.6314, 10.2481],
              [-64.4905, 10.2407],
              [-64.3392, 10.3907],
              [-64.1435, 10.4801],
              [-63.8204, 10.4476],
              [-63.8468, 10.556],
              [-63.7569, 10.6656],
              [-63.5316, 10.631],
              [-63.1713, 10.7316],
              [-62.9043, 10.7022],
              [-62.7196, 10.76],
              [-62.1542, 10.6863],
              [-62.335, 10.5302],
              [-62.6623, 10.567],
              [-62.857, 10.5316],
              [-62.8311, 10.4068],
              [-62.5176, 10.1841],
              [-62.4089, 9.924],
              [-62.1965, 10.0197],
              [-62.0254, 9.9574],
              [-61.8768, 9.8237],
              [-61.5925, 9.782],
              [-61.4392, 9.8184],
              [-61.1301, 9.5725],
              [-60.8623, 9.4561],
              [-60.7838, 9.3386],
              [-60.984, 9.1674],
              [-61.0997, 8.9604],
              [-61.2095, 8.609],
              [-61.5231, 8.5966],
              [-61.284, 8.3972],
              [-61.181, 8.4934],
              [-60.9848, 8.5705],
              [-60.6925, 8.6047],
              [-60.4616, 8.5344],
              [-60.3719, 8.6354],
              [-60.021, 8.558],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Venezuela",
        NAME_LONG: "Venezuela",
        ADM0_A3: "VEN",
        ISO_A2: "VE",
        ISO_A3: "VEN",
        WB_A2: "VE",
        WB_A3: "VEN",
      },
      id: 243,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [104.0047, 10.451],
              [104.0855, 10.2481],
              [103.8574, 10.3209],
              [104.0047, 10.451],
            ],
          ],
          [
            [
              [107.9912, 21.4857],
              [107.8819, 21.5341],
              [107.5992, 21.2988],
              [107.3916, 21.2804],
              [107.3338, 21.0058],
              [107.1609, 20.9324],
              [106.7551, 20.9385],
              [106.7041, 20.6911],
              [106.5677, 20.5249],
              [106.5696, 20.232],
              [106.395, 20.2046],
              [106.2593, 20.0613],
              [105.9556, 19.9255],
              [105.9234, 19.7668],
              [105.8106, 19.5996],
              [105.7371, 19.1085],
              [105.6438, 19.0654],
              [105.6483, 18.8927],
              [105.7835, 18.7473],
              [105.8259, 18.5939],
              [106.0982, 18.29],
              [106.4035, 18.1062],
              [106.5248, 17.9496],
              [106.4701, 17.7482],
              [106.6346, 17.4704],
              [106.7639, 17.3355],
              [107.1118, 17.0883],
              [107.2165, 16.9079],
              [107.7588, 16.4631],
              [107.8123, 16.3132],
              [107.9452, 16.3568],
              [108.1512, 16.217],
              [108.3059, 15.9552],
              [108.7039, 15.4451],
              [108.8828, 15.3362],
              [108.9426, 14.9796],
              [109.0808, 14.7295],
              [109.0745, 14.5786],
              [109.1814, 14.3048],
              [109.304, 13.7593],
              [109.2322, 13.3931],
              [109.3088, 13.3415],
              [109.3074, 13.133],
              [109.465, 12.9133],
              [109.2049, 12.6464],
              [109.1987, 11.9481],
              [109.1258, 11.8636],
              [109.2429, 11.7357],
              [109.136, 11.5702],
              [109.0278, 11.5175],
              [109.0181, 11.3559],
              [108.8213, 11.3237],
              [108.7293, 11.1815],
              [108.5774, 11.1791],
              [108.345, 10.9538],
              [108.1065, 10.9182],
              [107.9983, 10.6994],
              [107.8823, 10.717],
              [107.326, 10.4425],
              [106.9636, 10.5014],
              [106.8816, 10.6522],
              [106.7334, 10.5224],
              [106.7881, 10.3931],
              [106.6687, 10.0324],
              [106.5755, 9.6486],
              [106.3982, 9.5421],
              [106.197, 9.5412],
              [106.1934, 9.3693],
              [105.5387, 9.1293],
              [105.334, 8.8397],
              [105.1015, 8.6343],
              [104.8562, 8.5656],
              [104.8528, 8.7292],
              [104.7746, 8.8179],
              [104.8356, 9.5562],
              [104.8928, 9.8511],
              [105.0877, 9.9967],
              [104.9011, 10.0973],
              [104.8049, 10.2071],
              [104.5889, 10.2609],
              [104.4513, 10.4197],
              [104.554, 10.5183],
              [104.8544, 10.5313],
              [105.0662, 10.7464],
              [105.0291, 10.9141],
              [105.3275, 10.8483],
              [105.4739, 10.9409],
              [105.7529, 11.0156],
              [105.861, 10.8393],
              [106.155, 10.7848],
              [106.0631, 11.0933],
              [105.8434, 11.3041],
              [105.8532, 11.5469],
              [106.0154, 11.7705],
              [106.2873, 11.6746],
              [106.3989, 11.7463],
              [106.394, 11.9698],
              [106.7016, 11.9708],
              [106.7492, 12.0523],
              [106.9777, 12.0972],
              [107.1525, 12.277],
              [107.5143, 12.3438],
              [107.5651, 12.6064],
              [107.4604, 13.0216],
              [107.607, 13.3698],
              [107.5969, 13.5351],
              [107.4432, 13.7835],
              [107.4307, 13.9845],
              [107.3199, 14.1198],
              [107.3869, 14.4219],
              [107.5053, 14.541],
              [107.5204, 14.7046],
              [107.578, 15.0472],
              [107.6576, 15.2824],
              [107.5732, 15.4112],
              [107.3693, 15.4961],
              [107.1603, 15.7587],
              [107.1752, 15.8485],
              [107.3906, 15.9358],
              [107.1169, 16.2546],
              [106.9673, 16.2999],
              [106.8599, 16.4118],
              [106.6552, 16.4708],
              [106.5351, 16.6835],
              [106.5334, 16.9727],
              [106.4203, 16.9887],
              [106.26, 17.2892],
              [106.1854, 17.2574],
              [105.846, 17.598],
              [105.7353, 17.664],
              [105.476, 18.1273],
              [105.3683, 18.15],
              [105.1567, 18.3198],
              [105.08, 18.4544],
              [105.1695, 18.6306],
              [105.1127, 18.6983],
              [104.722, 18.792],
              [104.4809, 18.9777],
              [104.1477, 19.1829],
              [103.8567, 19.3172],
              [104.09, 19.4837],
              [104.0887, 19.6519],
              [104.229, 19.706],
              [104.5058, 19.6028],
              [104.805, 19.7909],
              [104.7807, 19.9081],
              [104.9532, 20.0093],
              [104.908, 20.1753],
              [104.6571, 20.2127],
              [104.6809, 20.341],
              [104.5426, 20.5357],
              [104.6007, 20.6605],
              [104.3891, 20.771],
              [104.2953, 20.8798],
              [104.0567, 20.9588],
              [103.7756, 20.8349],
              [103.6654, 20.6583],
              [103.4266, 20.8207],
              [103.3638, 20.7876],
              [103.1153, 20.8684],
              [102.8879, 21.2269],
              [102.895, 21.4953],
              [102.9741, 21.5753],
              [102.9478, 21.7371],
              [102.7867, 21.7399],
              [102.6507, 21.6578],
              [102.591, 21.9014],
              [102.2262, 22.2284],
              [102.1187, 22.3975],
              [102.218, 22.4107],
              [102.4427, 22.7652],
              [102.8452, 22.5852],
              [102.9891, 22.4376],
              [103.142, 22.5378],
              [103.3096, 22.7879],
              [103.4731, 22.5916],
              [103.6469, 22.799],
              [103.8668, 22.5754],
              [103.9854, 22.5272],
              [104.0238, 22.7192],
              [104.2133, 22.825],
              [104.3402, 22.6864],
              [104.5535, 22.836],
              [104.7282, 22.8391],
              [104.827, 22.9543],
              [104.7979, 23.0856],
              [105.0611, 23.2325],
              [105.3323, 23.318],
              [105.8539, 22.9046],
              [105.9992, 22.9754],
              [106.2024, 22.947],
              [106.3157, 22.8538],
              [106.4878, 22.9254],
              [106.6675, 22.8675],
              [106.7355, 22.6954],
              [106.5688, 22.5746],
              [106.5269, 22.4278],
              [106.6629, 22.2233],
              [106.7224, 22.007],
              [107.0211, 21.8923],
              [107.1724, 21.7151],
              [107.3482, 21.5994],
              [107.6132, 21.6056],
              [107.7448, 21.6575],
              [107.9912, 21.4857],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Vietnam",
        NAME_LONG: "Vietnam",
        ADM0_A3: "VNM",
        ISO_A2: "VN",
        ISO_A3: "VNM",
        WB_A2: "VN",
        WB_A3: "VNM",
      },
      id: 246,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [169.1868, -18.735],
              [169.3324, -18.9049],
              [169.2549, -18.9848],
              [168.9873, -18.8766],
              [168.9888, -18.6906],
              [169.1868, -18.735],
            ],
          ],
          [
            [
              [168.3317, -17.5492],
              [168.4534, -17.5463],
              [168.5855, -17.7013],
              [168.396, -17.8235],
              [168.179, -17.6562],
              [168.3317, -17.5492],
            ],
          ],
          [
            [
              [168.1851, -16.5754],
              [168.3333, -16.7867],
              [168.1794, -16.8063],
              [168.1204, -16.7092],
              [168.1851, -16.5754],
            ],
          ],
          [
            [
              [168.3223, -16.3183],
              [168.14, -16.3619],
              [167.9209, -16.2589],
              [168.1716, -16.0803],
              [168.3223, -16.3183],
            ],
          ],
          [
            [
              [167.4245, -16.1116],
              [167.7861, -16.3586],
              [167.7996, -16.487],
              [167.6219, -16.4942],
              [167.4588, -16.568],
              [167.3845, -16.3683],
              [167.3771, -16.2006],
              [167.1475, -16.0829],
              [167.1853, -15.8907],
              [167.3361, -15.9089],
              [167.4245, -16.1116],
            ],
          ],
          [
            [
              [166.8132, -15.1604],
              [166.9198, -15.1559],
              [167.0568, -15.002],
              [167.1917, -15.3056],
              [167.2334, -15.5236],
              [166.9463, -15.5768],
              [166.7931, -15.6563],
              [166.6161, -15.385],
              [166.6501, -15.2647],
              [166.5226, -14.8321],
              [166.6099, -14.6347],
              [166.7373, -14.8295],
              [166.8132, -15.1604],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Vanuatu",
        NAME_LONG: "Vanuatu",
        ADM0_A3: "VUT",
        ISO_A2: "VU",
        ISO_A3: "VUT",
        WB_A2: "VU",
        WB_A3: "VUT",
      },
      id: 247,
    },
    {
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [53.5738, 12.7038],
              [53.7629, 12.6197],
              [54.1194, 12.7011],
              [54.4707, 12.5436],
              [54.1405, 12.3513],
              [53.7764, 12.305],
              [53.5721, 12.3346],
              [53.3342, 12.5155],
              [53.396, 12.6565],
              [53.5738, 12.7038],
            ],
          ],
          [
            [
              [53.0903, 16.6424],
              [52.5977, 16.4767],
              [52.2927, 16.2638],
              [52.1555, 15.9839],
              [52.2244, 15.8467],
              [52.2284, 15.6161],
              [52.0471, 15.57],
              [51.6799, 15.4035],
              [51.3678, 15.2297],
              [51.0698, 15.1496],
              [50.554, 15.0486],
              [50.1795, 14.8433],
              [49.9421, 14.8388],
              [49.5781, 14.7373],
              [49.078, 14.5061],
              [49.0037, 14.3248],
              [48.6815, 14.0436],
              [48.3382, 14.0163],
              [48.1887, 13.9691],
              [48.0169, 14.0573],
              [47.6672, 13.8872],
              [47.3982, 13.6549],
              [46.9119, 13.5333],
              [46.7007, 13.4303],
              [46.2345, 13.4363],
              [45.6658, 13.342],
              [45.403, 13.0701],
              [45.1935, 13.0127],
              [44.8683, 12.7321],
              [44.6936, 12.8035],
              [44.5428, 12.7973],
              [44.433, 12.6936],
              [44.1912, 12.6191],
              [43.9094, 12.6453],
              [43.5877, 12.7514],
              [43.4732, 12.8466],
              [43.2489, 13.2127],
              [43.2809, 13.7221],
              [43.2332, 13.8764],
              [43.0955, 14.084],
              [42.9935, 14.4483],
              [43.0287, 14.538],
              [42.9422, 14.7889],
              [42.9373, 14.9485],
              [42.8031, 15.2452],
              [42.7743, 15.491],
              [42.6992, 15.7174],
              [42.82, 15.8749],
              [42.8433, 16.0522],
              [42.7895, 16.371],
              [43.0363, 16.5363],
              [43.0849, 16.6726],
              [43.2086, 16.7734],
              [43.1087, 16.927],
              [43.165, 17.3259],
              [43.391, 17.512],
              [43.5034, 17.5135],
              [43.7506, 17.3457],
              [44.1045, 17.352],
              [44.4041, 17.4187],
              [45.1654, 17.4284],
              [45.4304, 17.312],
              [46.3226, 17.2241],
              [46.7101, 17.2754],
              [46.9703, 16.9568],
              [47.1909, 16.9589],
              [47.4276, 17.0918],
              [47.589, 17.4633],
              [48.1619, 18.1489],
              [49.0358, 18.5797],
              [49.1288, 18.6121],
              [49.7384, 18.6942],
              [51.0198, 18.8666],
              [51.9786, 18.9956],
              [52.4845, 17.8542],
              [52.7301, 17.2999],
              [52.8016, 17.2674],
              [53.0903, 16.6424],
            ],
          ],
        ],
      },
      properties: {
        NAME: "Yemen",
        NAME_LONG: "Yemen",
        ADM0_A3: "YEM",
        ISO_A2: "YE",
        ISO_A3: "YEM",
        WB_A2: "RY",
        WB_A3: "YEM",
      },
      id: 251,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.2889, -22.3973],
            [31.5363, -23.157],
            [31.5215, -23.4156],
            [31.6423, -23.5879],
            [31.7412, -23.8368],
            [31.8558, -23.9465],
            [31.8695, -24.1638],
            [31.9866, -24.4231],
            [31.9916, -25.6502],
            [31.9119, -25.7853],
            [31.9492, -25.9581],
            [31.8346, -25.9821],
            [31.4269, -25.7436],
            [31.3093, -25.7574],
            [31.1198, -25.91],
            [30.9699, -26.2091],
            [30.8046, -26.3975],
            [30.7857, -26.7169],
            [30.8802, -26.7722],
            [30.9761, -27.035],
            [31.157, -27.2056],
            [31.5265, -27.3109],
            [31.9683, -27.3163],
            [31.9421, -27.1007],
            [31.9922, -26.8383],
            [32.1139, -26.84],
            [32.3522, -26.8603],
            [32.8931, -26.8461],
            [32.8394, -27.1236],
            [32.6765, -27.5207],
            [32.544, -28.1901],
            [32.422, -28.3819],
            [32.3794, -28.5528],
            [32.0121, -28.8665],
            [31.7521, -28.9662],
            [31.4885, -29.2096],
            [31.1869, -29.5603],
            [31.0158, -29.8681],
            [30.3923, -30.8471],
            [30.0099, -31.2923],
            [29.7845, -31.4347],
            [29.3919, -31.7355],
            [29.1476, -31.9698],
            [28.8704, -32.287],
            [28.5486, -32.5624],
            [28.2108, -32.7747],
            [27.8971, -33.04],
            [27.7327, -33.1088],
            [27.3802, -33.3574],
            [26.5151, -33.7553],
            [26.2866, -33.7664],
            [25.9553, -33.7109],
            [25.679, -33.7952],
            [25.5982, -34.0485],
            [25.1011, -33.9605],
            [24.9529, -33.9851],
            [24.8411, -34.1325],
            [24.584, -34.1783],
            [24.1631, -34.0548],
            [23.6355, -33.9797],
            [23.4276, -34.0124],
            [23.4084, -34.11],
            [22.936, -34.0827],
            [22.6299, -33.9958],
            [22.2752, -34.0577],
            [22.1141, -34.1939],
            [21.9217, -34.2467],
            [21.8945, -34.336],
            [21.7026, -34.3906],
            [21.5159, -34.3522],
            [21.2625, -34.4216],
            [20.9613, -34.3561],
            [20.5244, -34.4543],
            [20.2117, -34.6721],
            [19.9535, -34.8087],
            [19.8584, -34.7497],
            [19.6424, -34.7721],
            [19.3987, -34.6018],
            [19.3044, -34.4179],
            [19.0884, -34.3427],
            [18.8083, -34.3647],
            [18.8582, -34.1503],
            [18.555, -34.0738],
            [18.4417, -34.1442],
            [18.3148, -34.0291],
            [18.4754, -33.8998],
            [18.4318, -33.6967],
            [18.3074, -33.4793],
            [18.1462, -33.3559],
            [18.0752, -33.2022],
            [17.9128, -33.0475],
            [17.8524, -32.8297],
            [17.9675, -32.7035],
            [18.0806, -32.7813],
            [18.2731, -32.6476],
            [18.3494, -32.2603],
            [18.2769, -31.8927],
            [18.1729, -31.6633],
            [17.8937, -31.3448],
            [17.5617, -30.8381],
            [17.282, -30.3478],
            [17.1037, -29.8849],
            [17.0563, -29.6764],
            [16.8088, -29.0783],
            [16.6004, -28.8575],
            [16.4871, -28.5729],
            [16.7693, -28.4426],
            [16.7644, -28.2832],
            [16.893, -28.0826],
            [17.0978, -28.0312],
            [17.192, -28.2088],
            [17.3457, -28.2276],
            [17.4021, -28.3678],
            [17.3282, -28.4561],
            [17.4036, -28.7043],
            [17.9133, -28.7813],
            [18.1665, -28.9019],
            [18.3976, -28.8989],
            [18.7457, -28.8399],
            [18.9545, -28.8667],
            [19.0817, -28.9594],
            [19.2889, -28.871],
            [19.2486, -28.7716],
            [19.4829, -28.6782],
            [19.5688, -28.5312],
            [19.9817, -28.4223],
            [19.9827, -26.8955],
            [19.9823, -25.9424],
            [19.9814, -24.7525],
            [20.1189, -24.8874],
            [20.3649, -25.0332],
            [20.7941, -25.8939],
            [20.8414, -26.1313],
            [20.6225, -26.4275],
            [20.6089, -26.6861],
            [20.6935, -26.889],
            [20.8519, -26.8065],
            [21.1225, -26.8653],
            [21.3808, -26.824],
            [21.6872, -26.8552],
            [21.7675, -26.7043],
            [22.0577, -26.6176],
            [22.2573, -26.3409],
            [22.5451, -26.2074],
            [22.7194, -25.9843],
            [22.7464, -25.7553],
            [22.8446, -25.4811],
            [23.007, -25.3108],
            [23.2657, -25.264],
            [23.4593, -25.2822],
            [23.9248, -25.6292],
            [24.2277, -25.649],
            [24.2967, -25.7235],
            [24.7986, -25.8292],
            [25.0517, -25.7379],
            [25.3865, -25.7435],
            [25.5873, -25.6195],
            [25.8767, -24.886],
            [25.8684, -24.7482],
            [26.2788, -24.6285],
            [26.4044, -24.6328],
            [26.5312, -24.4587],
            [26.8497, -24.2481],
            [27.0042, -23.6458],
            [27.35, -23.3915],
            [27.5489, -23.3608],
            [27.608, -23.2167],
            [27.8955, -23.0795],
            [27.9375, -22.9638],
            [28.3386, -22.5846],
            [28.6239, -22.5628],
            [28.9125, -22.4537],
            [29.0478, -22.2203],
            [29.3501, -22.1867],
            [29.7589, -22.1309],
            [30.3008, -22.337],
            [30.6324, -22.3307],
            [30.8379, -22.2823],
            [31.2889, -22.3973],
          ],
          [
            [28.9808, -28.909],
            [28.6995, -28.6885],
            [28.6665, -28.5972],
            [28.4034, -28.6189],
            [28.155, -28.7023],
            [28.0187, -28.8568],
            [27.7474, -28.9086],
            [27.665, -29.0657],
            [27.4533, -29.2916],
            [27.4176, -29.3966],
            [27.2418, -29.549],
            [27.0149, -29.6256],
            [27.1988, -29.9785],
            [27.2659, -30.0333],
            [27.3662, -30.311],
            [27.4594, -30.3166],
            [27.7436, -30.6003],
            [28.0547, -30.6497],
            [28.2182, -30.3874],
            [28.216, -30.2674],
            [28.3641, -30.1593],
            [28.86, -30.0664],
            [29.1442, -29.9197],
            [29.1613, -29.6669],
            [29.2786, -29.6136],
            [29.3038, -29.4668],
            [29.4359, -29.3424],
            [29.3118, -29.0898],
            [28.9808, -28.909],
          ],
        ],
      },
      properties: {
        NAME: "South Africa",
        NAME_LONG: "South Africa",
        ADM0_A3: "ZAF",
        ISO_A2: "ZA",
        ISO_A3: "ZAF",
        WB_A2: "ZA",
        WB_A3: "ZAF",
      },
      id: 252,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.9209, -9.4079],
            [32.9747, -9.6013],
            [33.2167, -9.7271],
            [33.3656, -9.8988],
            [33.31, -10.0127],
            [33.5333, -10.2312],
            [33.5293, -10.3186],
            [33.6742, -10.577],
            [33.501, -10.7692],
            [33.3191, -10.8181],
            [33.3037, -11.0346],
            [33.3909, -11.1648],
            [33.2303, -11.4166],
            [33.3026, -11.6097],
            [33.3127, -11.8987],
            [33.2549, -12.0726],
            [33.3301, -12.2983],
            [33.5181, -12.3718],
            [33.3732, -12.5185],
            [33.0248, -12.6127],
            [32.9411, -12.7699],
            [33.005, -12.893],
            [32.9951, -13.1909],
            [32.8743, -13.4432],
            [32.7228, -13.5734],
            [32.8594, -13.8081],
            [33.0494, -13.9966],
            [33.2027, -14.0139],
            [32.2274, -14.339],
            [31.6584, -14.5196],
            [31.4109, -14.6336],
            [30.8158, -14.7682],
            [30.2145, -14.9815],
            [30.2879, -15.2789],
            [30.3692, -15.3747],
            [30.3963, -15.636],
            [30.0107, -15.6462],
            [29.8373, -15.6148],
            [29.407, -15.7142],
            [29.1863, -15.8128],
            [28.871, -16.0872],
            [28.8225, -16.4708],
            [28.6433, -16.5688],
            [28.2801, -16.7065],
            [28.1139, -16.8276],
            [27.7773, -17.0012],
            [27.5773, -17.3631],
            [27.1571, -17.7693],
            [27.0485, -17.9443],
            [26.7119, -18.0658],
            [26.203, -17.8872],
            [25.9785, -17.999],
            [25.6814, -17.8115],
            [25.4956, -17.8549],
            [25.2598, -17.7941],
            [24.958, -17.5517],
            [24.6844, -17.4924],
            [24.2205, -17.4795],
            [23.3817, -17.6411],
            [23.2908, -17.5354],
            [23.073, -17.4051],
            [22.9986, -17.2938],
            [22.7559, -17.1549],
            [22.7105, -17.0554],
            [22.5229, -16.9147],
            [22.3337, -16.6737],
            [22.1517, -16.5977],
            [21.9838, -16.1659],
            [21.9813, -15.1112],
            [21.9808, -14.3576],
            [21.9799, -13.0015],
            [23.2227, -13.0015],
            [24.0006, -13.0015],
            [23.8657, -12.7897],
            [23.9287, -12.5616],
            [24.0308, -12.3851],
            [23.9596, -12.1967],
            [23.9599, -11.6172],
            [24.0611, -11.395],
            [24.0112, -11.2729],
            [23.9675, -10.8723],
            [24.1142, -10.9192],
            [24.1242, -11.0433],
            [24.3818, -11.1206],
            [24.3922, -11.2791],
            [24.3101, -11.4066],
            [24.5647, -11.4447],
            [24.6741, -11.3416],
            [25.2788, -11.1999],
            [25.279, -11.3963],
            [25.352, -11.6461],
            [25.8507, -11.7938],
            [25.9941, -11.9048],
            [26.298, -11.9412],
            [26.4146, -11.9118],
            [26.6435, -11.9855],
            [26.8742, -11.9643],
            [26.9673, -11.8704],
            [27.0103, -11.6098],
            [27.1801, -11.5696],
            [27.2342, -11.8091],
            [27.4207, -11.922],
            [27.5205, -12.1796],
            [27.6382, -12.2936],
            [27.9339, -12.3033],
            [28.1313, -12.4292],
            [28.3397, -12.4505],
            [28.4227, -12.5213],
            [28.584, -12.8887],
            [28.6679, -12.8493],
            [28.9034, -13.1623],
            [28.994, -13.3959],
            [29.1689, -13.4339],
            [29.5744, -13.2254],
            [29.6017, -13.3744],
            [29.7976, -13.4242],
            [29.7993, -12.1541],
            [29.4743, -12.2427],
            [29.4466, -12.3351],
            [29.0304, -12.3762],
            [28.835, -12.0922],
            [28.4972, -11.8574],
            [28.357, -11.4469],
            [28.4396, -11.3482],
            [28.4768, -11.078],
            [28.5635, -10.8119],
            [28.6996, -10.6432],
            [28.6349, -10.5195],
            [28.6239, -9.951],
            [28.6685, -9.8216],
            [28.516, -9.3783],
            [28.3723, -9.2351],
            [28.5991, -9.0964],
            [28.7654, -8.934],
            [28.9305, -8.6799],
            [28.9153, -8.4729],
            [29.8581, -8.3298],
            [30.7521, -8.1941],
            [30.8283, -8.3881],
            [30.9595, -8.5505],
            [31.1198, -8.6166],
            [31.3533, -8.5872],
            [31.539, -8.7036],
            [31.6727, -8.913],
            [31.9366, -8.9326],
            [31.9177, -9.0225],
            [32.383, -9.1339],
            [32.518, -9.2582],
            [32.7132, -9.2858],
            [32.9209, -9.4079],
          ],
        ],
      },
      properties: {
        NAME: "Zambia",
        NAME_LONG: "Zambia",
        ADM0_A3: "ZMB",
        ISO_A2: "ZM",
        ISO_A3: "ZMB",
        WB_A2: "ZM",
        WB_A3: "ZMB",
      },
      id: 253,
    },
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.3963, -15.636],
            [30.4026, -16.0012],
            [30.8574, -15.9981],
            [30.9731, -16.062],
            [31.1141, -15.9969],
            [31.26, -16.0235],
            [31.4046, -16.1623],
            [31.6861, -16.2072],
            [31.9103, -16.4289],
            [32.2905, -16.4518],
            [32.6718, -16.5998],
            [32.6987, -16.6868],
            [32.8934, -16.7124],
            [32.9334, -16.8158],
            [32.8288, -16.9351],
            [33.0225, -17.3615],
            [32.9371, -18.0471],
            [32.9523, -18.233],
            [33.035, -18.3329],
            [32.9964, -18.4671],
            [32.8707, -18.5358],
            [32.8852, -18.7878],
            [32.6914, -18.8342],
            [32.6911, -19.0143],
            [32.8623, -19.1181],
            [32.7688, -19.3636],
            [32.8163, -19.6521],
            [33.0328, -19.7842],
            [33.002, -19.927],
            [32.8853, -20.103],
            [32.8452, -20.2867],
            [32.6465, -20.558],
            [32.5131, -20.5646],
            [32.4691, -20.6868],
            [32.4972, -20.8981],
            [32.3398, -21.1341],
            [32.4085, -21.2903],
            [31.4338, -22.302],
            [31.2889, -22.3973],
            [30.8379, -22.2823],
            [30.6324, -22.3307],
            [30.3008, -22.337],
            [29.7589, -22.1309],
            [29.3501, -22.1867],
            [29.2393, -22.0726],
            [29.0216, -21.9828],
            [29.0387, -21.7979],
            [28.6297, -21.6513],
            [28.4646, -21.6603],
            [27.9904, -21.5519],
            [27.885, -21.3102],
            [27.6748, -21.0901],
            [27.6981, -20.5091],
            [27.3407, -20.473],
            [27.2149, -20.1105],
            [27.0266, -20.0101],
            [26.731, -19.9359],
            [26.3122, -19.6514],
            [26.1303, -19.5011],
            [25.9565, -19.1221],
            [25.9407, -18.9213],
            [25.8153, -18.814],
            [25.7619, -18.6303],
            [25.5085, -18.3991],
            [25.3875, -18.139],
            [25.2261, -17.9319],
            [25.2598, -17.7941],
            [25.4956, -17.8549],
            [25.6814, -17.8115],
            [25.9785, -17.999],
            [26.203, -17.8872],
            [26.7119, -18.0658],
            [27.0485, -17.9443],
            [27.1571, -17.7693],
            [27.5773, -17.3631],
            [27.7773, -17.0012],
            [28.1139, -16.8276],
            [28.2801, -16.7065],
            [28.6433, -16.5688],
            [28.8225, -16.4708],
            [28.871, -16.0872],
            [29.1863, -15.8128],
            [29.407, -15.7142],
            [29.8373, -15.6148],
            [30.0107, -15.6462],
            [30.3963, -15.636],
          ],
        ],
      },
      properties: {
        NAME: "Zimbabwe",
        NAME_LONG: "Zimbabwe",
        ADM0_A3: "ZWE",
        ISO_A2: "ZW",
        ISO_A3: "ZWE",
        WB_A2: "ZW",
        WB_A3: "ZWE",
      },
      id: 254,
    },

    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.458324, 41.902596],
            [12.458346, 41.901542],
            [12.457509, 41.901015],
            [12.454569, 41.900329],
            [12.450857, 41.900616],
            [12.448754, 41.900888],
            [12.447767, 41.900856],
            [12.445815, 41.901942],
            [12.447853, 41.903315],
            [12.448926, 41.904289],
            [12.450042, 41.905647],
            [12.451437, 41.906733],
            [12.455385, 41.907324],
            [12.455728, 41.906349],
            [12.457638, 41.90579],
            [12.457595, 41.903363],
            [12.458324, 41.902596],
          ],
        ],
      },
      properties: {
        NAME: "Vatican",
        NAME_LONG: "Vatican City",
        ADM0_A3: "VAT",
        ISO_A2: "VA",
        ISO_A3: "VAT",
        WB_A2: "VA",
        WB_A3: "VAT",
      },
      id: 255,
    },

    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.441459246416315, 43.87457461462827],
            [12.475052993212586, 43.89908633104065],
            [12.47685963366527, 43.92496523482265],
            [12.463285416209828, 43.94439882671929],
            [12.45068776116122, 43.952406638455614],
            [12.414554952107125, 43.95621523184241],
            [12.391410423064343, 43.949379294994344],
            [12.354594020325436, 43.91598086067944],
            [12.357084254462961, 43.88751406651926],
            [12.386381126668994, 43.86891055266844],
            [12.441459246416315, 43.87457461462827],
          ],
        ],
      },
      properties: {
        NAME: "San Marino",
        NAME_LONG: "San Marino",
        ADM0_A3: "SMR",
        ISO_A2: "SM",
        ISO_A3: "SMR",
        WB_A2: "SM",
        WB_A3: "SMR",
      },
      id: 256,
    },

    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-61.866806318989, 17.347571482946],
            [-61.865496607765, 17.369607114865],
            [-61.849361252433, 17.697029378831],
            [-61.842139282017, 17.701344097408],
            [-61.828236357157, 17.700984113961],
            [-61.746854211034, 17.653268673944],
            [-61.673498312724, 17.206974489796],
            [-61.675501951042, 17.077983119329],
            [-61.682869662211, 17.060763246497],
            [-61.767611236958, 16.985530941235],
            [-61.785760721855, 16.980491929126],
            [-61.791508856288, 16.98091884417],
            [-61.854595451471, 17.003582384136],
            [-61.885477027241, 17.079331148265],
            [-61.866806318989, 17.347571482946],
          ],
        ],
      },
      properties: {
        NAME: "Antigua and Barbuda",
        NAME_LONG: "Antigua and Barbuda",
        ADM0_A3: "ATG",
        ISO_A2: "AG",
        ISO_A3: "ATG",
        WB_A2: "AG",
        WB_A3: "ATG",
      },
      id: 257,
    },
  ],
};
