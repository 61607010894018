import React, { useCallback, useEffect, useState } from "react";
import L, { Map as TheMap, LatLngExpression } from "leaflet";
import {MapContainer, TileLayer, LayerGroup, MapConsumer, GeoJSON, Circle, CircleMarker} from "react-leaflet";
import ReactLeafletKml from "react-leaflet-kml";
import { useSelector } from "react-redux";
// import marker_icon from '../../assets/images/map_marker.png';
import { getCoordinatesFromKMLText } from "../../utils/helper-functions";
import { RootState } from "../../store";
import { getKmlDataByUrl, KmlValueSchema, setKmlData } from "../../services/databases";
import info_marker_icon from '../../assets/images/info-circle-fill.svg';
import { CustomMarkerPopup } from './index';
import parse from 'html-react-parser';
import axios from "axios";
import LocationMarker from "./UserLocationMarker";
import { worldGeoJSON } from '../../data/worldGeoJSON';
import 'leaflet.offline'
import { calculateMaxOfflineZoom, basicZoomLevelsForWalk, basicZoomLevelsForDrive } from "../../utils/map-functions";

export const infoMarkerIcon = L.icon({
  iconUrl: info_marker_icon,
  iconSize: [24, 24],
  className: "marker-icon-class"
});

interface Props {
  followUser: boolean,
  zoomLevel: number,
  trailType: string,
  detailedMapDownloaded: boolean
}

function Map({ followUser, zoomLevel, trailType, detailedMapDownloaded }: Props) {

  const [map, setMap] = useState<TheMap | undefined>();
  const { KMLData, PinPoints } = useSelector((state: RootState) => {
    return { KMLData: state.trails.currentTrailMapData, PinPoints: state.trails.currentPinPoints }
  });

  const [markerPosition, setMarkerPosition] = useState<LatLngExpression>([-34.38, 117.96]);
  const [isClicked, setIsClicked] = useState(false);
  const [kml, setKml] = useState<Document | null>(null);
  const [kmlText, setKmlText] = useState<string | null>(null);
  const [customZoom, setCustomeZoom] = useState(zoomLevel || 10)

  let [online, isOnline] = useState(navigator.onLine);

  const setOnline = () => {
    console.log('We are online')
    isOnline(true)
  };
  const setOffline = () => {
    console.log('We are offline')
    isOnline(false)
  };

   // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline)
    window.addEventListener('online', setOnline)

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline)
      window.removeEventListener('online', setOnline)
    }
  }, [])

  const openPopup = useCallback(() => {
    const elmts = document.getElementsByClassName('leaflet-marker-icon');
    if (elmts.length > 1) {
      setIsClicked(true);
      if (!isClicked) {
        for (let i = 0; i < elmts.length; i++) {
          const imgRef = elmts.item(i) as HTMLImageElement;
          if (!imgRef.title) {
            imgRef.click();
            return;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCoordinates = (kml_data: string | null) => {
    if (kml_data) {
      const { startCoordinates, endCoordinates } = getCoordinatesFromKMLText(kml_data);
      if (startCoordinates.length > 0 && endCoordinates.length > 0) {
        const lng = (parseFloat(startCoordinates[0]) + parseFloat(endCoordinates[0])) / 2;
        const lat = (parseFloat(startCoordinates[1]) + parseFloat(endCoordinates[1])) / 2;
        setMarkerPosition([lat, lng]);

        if (startCoordinates.length && endCoordinates.length) {
          const s1 = parseFloat(startCoordinates[0]);
          const s2 = parseFloat(endCoordinates[0]);
          if (Math.hypot(s1 - s2) < 0.1) {
            setCustomeZoom(14)
          }
        }
      }
    }
  };

  const setKmlDataForMap = useCallback((kmlText: string) => {
    getCoordinates(kmlText);
    const parser = new DOMParser();
    const kml = parser.parseFromString(kmlText.replace(/http:/g, 'https:'), "text/xml");
    setKml(kml);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @function Load KML data from store.
   */
  const loadKmlData = useCallback(async (trails_link: string) => {
    try {
      if (navigator.onLine) {
        const res = await axios.get(trails_link);
        if (res.status === 200 && res.data) {
          setKmlDataForMap(res.data);
          setKmlText(res.data);
          const data = {
            data: res.data,
            kmlurl: trails_link,
          } as KmlValueSchema;
          await setKmlData(data);
        }
      } else {
        const res = await getKmlDataByUrl(trails_link);
        if (res) {
          setKmlDataForMap(res.data);
          setKmlText(res.data);
        }
      }
      openPopup();
    } catch (err) {
      const res = await getKmlDataByUrl(trails_link);
      if (res) {
        setKmlDataForMap(res.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setKmlDataForMap]);

  useEffect(() => {
    if (KMLData !== null) {
      loadKmlData(KMLData);
    }
  }, [KMLData, loadKmlData]);

  useEffect( () => {
    if (map && kmlText) {
      if (online) {
        let minZoomVar =  5
        let maxZoomVar = 18
        console.log('Network Online')
        console.log('minZoomVar: ', minZoomVar)
        console.log('maxZoomVar: ', maxZoomVar)
        map.options.minZoom = minZoomVar
        map.options.maxZoom = maxZoomVar
      } else {
        // we offline
        if(detailedMapDownloaded) { // if we DO have detailed map use this
          calculateMaxOfflineZoom(kmlText, map).then((maxZoomLevel) => {
            console.log('Promise returned, yes we got max zoom level:', maxZoomLevel)
            let minZoomVar = 9
            let maxZoomVar = maxZoomLevel
            console.log('Network Offline - WE HAVE DETAILED MAP')
            console.log('minZoomVar: ', minZoomVar)
            console.log('maxZoomVar: ', maxZoomVar)
            map.options.minZoom = minZoomVar
            map.options.maxZoom = maxZoomVar
          })
        } else {
          // dont have detailed map downloaded, use basic fetch all map zoom levels:
          // if we are DRIVE lock off at 9 min 10 max
          // if we are WALK lock off at 9 min 14 max
          console.log('Network Offline - BASIC MAP ONLY - No detailed map')
          console.log('Trail Type: ', trailType)
          let minZoomVar = trailType === 'drive' ? basicZoomLevelsForDrive[0] : basicZoomLevelsForWalk[0]
          let maxZoomVar = trailType === 'drive' ? basicZoomLevelsForDrive[basicZoomLevelsForDrive.length - 1] : basicZoomLevelsForWalk[basicZoomLevelsForWalk.length - 1]
          console.log('minZoomVar: ', minZoomVar)
          console.log('maxZoomVar: ', maxZoomVar)
          map.options.minZoom = minZoomVar
          map.options.maxZoom = maxZoomVar
        }
      }
    }
  }, [map, kmlText, online, detailedMapDownloaded, zoomLevel, customZoom])

  useEffect(() => {
    if(map){
      // @ts-ignore
      const tileLayerOffline = L.tileLayer.offline(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      );
      tileLayerOffline.addTo(map);
    }
  }, [map]);

  return (
    <MapContainer
      style={{ height: "100%", width: "100%" }}
      zoom={customZoom}  // Sets the initial zoom level
      markerZoomAnimation={true}  // Animates the zoom marker with the map (otherwise hides until animation complete)
      zoomAnimation={true}
      zoomSnap={1}
      zoomControl={false}
      center={[-34.38, 117.96]}
      className="w-100"
      whenCreated={setMap}
    >
      <LayerGroup >
        {PinPoints && PinPoints.map((el, i: number) => (
          <CustomMarkerPopup
            key={'marker-info-' + i}
            markerIcon={infoMarkerIcon}
            title={el.title}
            description={parse(el.description || "")}
            image={el.image || el.image_link}
            latitude={el.latitude}
            longitude={el.longitude}
          />
        ))}
      </LayerGroup>
      <MapConsumer>
        {(mapRef) => {
          return <LocationMarker map={mapRef} followUser={followUser}/>;
        }}
      </MapConsumer>
      {!navigator.onLine && <GeoJSON key="geojson-data" data={worldGeoJSON} style={() => ({
        color: '#4a83ec',
        weight: 0.5,
        fillColor: "#f4f4f4",
        fillOpacity: 0.3,
      })} />}
      {kml && <ReactLeafletKml kml={kml} />}
    </MapContainer>
  );
}

export default Map;
