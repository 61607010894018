import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store';
import './scss/App.scss';
import Routes from './routes/index';
import "leaflet/dist/leaflet.css";
import { AppContextProvider } from "./contexts/AppContext";
import InstallPWA from './components/InstallPromptModal';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppContextProvider>
          <React.Fragment>
            <Routes />
            <InstallPWA />
          </React.Fragment>
        </AppContextProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
