import * as actions from "../actionTypes/trailsActionTypes";
import { PoinPoints, trailData } from "../../types/trails";
import { aboutUsImage } from "../../types/aboutUsType";
import { accommodationService } from "../../types/accommodationType";
import { foodFuelFoodData, foodFuelFuelData } from "../../types/foodFuelType";
import { HomePageBestMoments } from "../../types/HomeType";
import { wildFlowerData } from "../../types/wildFlowers";

export interface TrailsState {
  loading: boolean;
  Trails: string | [];
  MountainWalkData: {
    [key: number]: {
      [key: number]: trailData[];
    };
  };
  DriveData: {
    [key: number]: {
      [key: number]: trailData[];
    };
  };
  MountainBikeData: {
    [key: number]: {
      [key: number]: trailData[];
    };
  };
  WildFlowerData: {
    [key: number]: wildFlowerData[];
  };
  trails_map_data: {};
  currentTrailMapData: string | null;
  currentPinPoints: PoinPoints[] | null;
  bestMoments: HomePageBestMoments[];
  aboutUsPageImages: string | aboutUsImage[];
  accommodationsServices: {
    [key: number]: {
      [key: number]: accommodationService[];
    };
  };
  foodFuelFoodDatas: {
    [key: number]: {
      [key: number]: foodFuelFoodData[];
    };
  };
  foodFuelFuelDatas: {
    [key: number]: {
      [key: number]: foodFuelFuelData[];
    };
  };
  singleTrail: trailData | null;
  trailsCheckList: {
    MountainWalkData: [];
    DriveData: [];
    MountainBikeData: [];
  };
  accommodationLoaded: boolean;
  aboutImagesLoaded: boolean;
  foodDataLoaded: boolean;
  fuelDataLoaded: boolean;
  wildFlowerLoaded: boolean;
  walkDataLoaded: boolean;
  driveDataLoaded: boolean;
  mapsLoaded: boolean;
  mapsProgress: number;
  detailedMapsArr: number[];
}

const initialState: TrailsState = {
  loading: false,
  Trails: "",
  MountainWalkData: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
  },
  DriveData: {
    0: {},
    1: {},
    6: {},
  },
  MountainBikeData: {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
  },
  WildFlowerData: {},
  trails_map_data: {},
  currentTrailMapData: null,
  currentPinPoints: null,
  bestMoments: [],
  aboutUsPageImages: "",
  accommodationsServices: {
    1: {},
    2: {},
    3: {},
    4: {},
  },
  foodFuelFoodDatas: {
    1: {},
    2: {},
    3: {},
    4: {},
  },
  foodFuelFuelDatas: {
    1: {},
    2: {},
    3: {},
    4: {},
  },
  singleTrail: null,
  trailsCheckList: {
    MountainWalkData: [],
    DriveData: [],
    MountainBikeData: [],
  },
  accommodationLoaded: false,
  aboutImagesLoaded: false,
  foodDataLoaded: false,
  fuelDataLoaded: false,
  wildFlowerLoaded: false,
  walkDataLoaded: false,
  driveDataLoaded: false,
  mapsLoaded: false,
  mapsProgress: 0,
  detailedMapsArr: []
};

export default function TrailsReducer(
  state: TrailsState = initialState,
  action: actions.TrailsAction
): TrailsState {
  switch (action.type) {
    case actions.SET_TRAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actions.SET_SINGLE_TRAIL_DATA:
      return {
        ...state,
        singleTrail: action.data,
      };

    case actions.SET_TRAILS:
    case actions.GET_TRAILS_SUCCESS:
      if (action.trail_type === 1) {
        return {
          ...state,
          loading: false,
          DriveData: {
            ...state.DriveData,
            [action.filter]: {
              [action.page]: action.data,
            },
          },
          driveDataLoaded: Object.keys(state.DriveData[0]).length > 0,
        };
      } else if (action.trail_type === 2)
        return {
          ...state,
          loading: false,
          MountainWalkData: {
            ...state.MountainWalkData,
            [action.filter]: {
              [action.page]: action.data,
            },
          },
          walkDataLoaded: Object.keys(state.MountainWalkData[0]).length > 0,
        };
      else if (action.trail_type === 3)
        return {
          ...state,
          loading: false,
          MountainBikeData: {
            ...state.MountainBikeData,
            [action.filter]: {
              [action.page]: action.data,
            },
          },
        };

      return {
        ...state,
        loading: false,
      };

    case actions.GET_WILD_FLOWER_SUCCESS:
      state.WildFlowerData[action.page] = action.data;
      return {
        ...state,
        loading: false,
        wildFlowerLoaded: true,
      };
    case actions.GET_ABOUT_PAGE_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutUsPageImages: action.data,
        aboutImagesLoaded: true,
      };
    case actions.GET_ACCOMMODATION_SERVICES_SUCCESS:
      state.accommodationsServices[action.accommodation_title_id][action.page] =
        action.data;
      return {
        ...state,
        loading: false,
      };
    case actions.SET_SINGLE_ACCOMMODATION_SERVICE:
      if (
        state.accommodationsServices[action.accommodation_title_id][action.page]
      )
        state.accommodationsServices[action.accommodation_title_id][
          action.page
        ].push(action.data);
      else
        state.accommodationsServices[action.accommodation_title_id][
          action.page
        ] = [action.data];
      return {
        ...state,
        loading: false,
        accommodationLoaded:
          Object.keys(state.accommodationsServices[4]).length > 0,
      };
    case actions.GET_FUEL_DATA_SUCCESS:
      state.foodFuelFuelDatas[action.fuel_and_food_title_id][action.page] =
        action.data.data.fuel;
      return {
        ...state,
        loading: false,
      };
    case actions.SET_SINGLE_FUEL_DATA:
      if (state.foodFuelFuelDatas[action.fuel_and_food_title_id][action.page])
        state.foodFuelFuelDatas[action.fuel_and_food_title_id][
          action.page
        ].push(action.data);
      else
        state.foodFuelFuelDatas[action.fuel_and_food_title_id][action.page] = [
          action.data,
        ];
      return {
        ...state,
        loading: false,
        fuelDataLoaded: Object.keys(state.foodFuelFuelDatas[4]).length > 0,
      };
    case actions.GET_FOOD_DATA_SUCCESS:
      state.foodFuelFoodDatas[action.fuel_and_food_title_id][action.page] =
        action.data.data.food;
      return {
        ...state,
        loading: false,
      };
    case actions.SET_SINGLE_FOOD_DATA:
      if (state.foodFuelFoodDatas[action.fuel_and_food_title_id][action.page])
        state.foodFuelFoodDatas[action.fuel_and_food_title_id][
          action.page
        ].push(action.data);
      else
        state.foodFuelFoodDatas[action.fuel_and_food_title_id][action.page] = [
          action.data,
        ];
      return {
        ...state,
        loading: false,
        foodDataLoaded: Object.keys(state.foodFuelFoodDatas[4]).length > 0,
      };

    case actions.GET_HOME_PAGE_BEST_MOMENTS_SUCCESS:
      state.bestMoments.splice((action.page - 1) * 4, 4, ...action.data.data);
      return {
        ...state,
        loading: false,
        // bestMoments: action.data.data,
      };
    case actions.GET_TRAILS_FAILURE:
    case actions.GET_WILD_FLOWER_FAILURE:
    case actions.GET_ABOUT_PAGE_IMAGES_FAILURE:
    case actions.GET_ACCOMMODATION_SERVICES_FAILURE:
    case actions.GET_FOOD_DATA_FAILURE:
    case actions.GET_FUEL_DATA_FAILURE:
    case actions.GET_HOME_PAGE_BEST_MOMENTS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case actions.SET_CURRENT_TRAILS_MAP_DATA:
      return {
        ...state,
        currentTrailMapData: action.data,
      };
    case actions.SET_CURRENT_MAP_PIN_POINTS:
      return {
        ...state,
        currentPinPoints: action.pinpoints,
      };
    case actions.SET_MAPS_PROGRESS:
      return {
        ...state,
        mapsProgress: state.mapsProgress + 10,
      };
    case actions.SET_MAPS_LOADED:
      return {
        ...state,
        mapsLoaded: true,
    };
    case actions.SET_MAP_AS_DETAILED:
      let idAlreadyExists = state.detailedMapsArr.indexOf(action.data) > -1;
      let detailedMapsArr = state.detailedMapsArr.slice();
      if (!idAlreadyExists) {
          detailedMapsArr.push(action.data);            
      }      
      return {
        ...state,
        detailedMapsArr
    };

    default:
      return state;
  }
}
