import { BulkPageContent } from "../../types/bulkPageContent";
import * as actions from "../actionTypes/pageActionTypes";

export function setPageDataLoading(
  loading: boolean
): actions.SetPageDataLoadingAction {
  return {
    type: actions.SET_PAGE_DATA_LOADING,
    loading,
  };
}

export function setDownloadDataLoading(
  loading: boolean
): actions.SetDownloadDataLoadingAction {
  return {
    type: actions.SET_DOWNLOAD_DATA_LOADING,
    loading,
  };
}

export function setPageData(
  trails: string | [],
  pageName: string
): actions.SetPageDataAction {
  return {
    type: actions.SET_PAGE_DATA,
    data: trails,
    pageName,
  };
}

export function getPageData(pageName: string): actions.GetPageDataAction {
  return {
    type: actions.GET_PAGE_DATA,
    pageName,
  };
}

export function getBulkPageData(): actions.GetBulkPageDataAction {
  return {
    type: actions.GET_BULK_PAGE_DATA,
  };
}

export function setBulkPageData(
  data: BulkPageContent
): actions.SetBulkPageDataAction {
  return {
    type: actions.SET_BULK_PAGE_DATA,
    data: data,
  };
}

export function setDownloadData(
  trails: string | [],
  device: string
): actions.SetDownloadDataAction {
  return {
    type: actions.SET_DOWNLOAD_DATA,
    data: trails,
    device,
  };
}

export function getDownloadData(device: string): actions.GetDownloadDataAction {
  return {
    type: actions.GET_DOWNLOAD_DATA,
    device,
  };
}

export function getPageDataSuccess(
  data: string | [],
  pageName: string
): actions.GetPageDataSuccessAction {
  return {
    type: actions.GET_PAGE_DATA_SUCCESS,
    data,
    pageName,
  };
}

export function getPageDataFailure(
  error: Error | string
): actions.GetPageDataFailureAction {
  return {
    type: actions.GET_PAGE_DATA_FAILURE,
    error,
  };
}

export function getDownloadDataSuccess(
  data: string | [],
  device: string
): actions.GetDownloadDataSuccessAction {
  return {
    type: actions.GET_DOWNLOAD_DATA_SUCCESS,
    data,
    device,
  };
}

export function getDownloadDataFailure(
  error: Error | string
): actions.GetDownloadDataFailureAction {
  return {
    type: actions.GET_DOWNLOAD_DATA_FAILURE,
    error,
  };
}
