import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { getAccommodationServices } from '../../store/actionCreators/trailsActionCreators';
import { accommodationService } from '../../types/accommodationType';
import Colors from '../../utils/colors';
import ServiceCard from '../Cards/ServiceCard';

export interface AccommodationListProps {
    data: { [key: number]: accommodationService[] } | undefined;
    type: number;
    title: string;
    subtitle?: string;
    loading: boolean;
}

const AccommodationList = ({ data, type, title, subtitle, loading }: AccommodationListProps) => {

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [dataLength, setDataLength] = useState(0);
    const [hasMore, setHasmore] = useState(true);

    useEffect(() => {
        if (navigator.onLine) dispatch(getAccommodationServices(page, type));
    }, [page, dispatch, type]);

    // eslint-disable-next-line
    useEffect(() => {
        let total = 0;
        if (data) {
            for (let key of Object.keys(data)) {
                if (data[parseInt(key)] && parseInt(key) <= page) total += data[parseInt(key)].length;
                else if (parseInt(key) > page) setPage(page + 1)
            }
        }
        if (dataLength >= page * 3) setHasmore(true)
        else setHasmore(false);
        setDataLength(total);

    });
    //, [data, loading, dataLength, page, hasMore]

    if (!data || Object.keys(data).length <= 0) {
        return null;
    }

    return (
        <div>
            <div>
                <div className="accommodation-list-title">
                    <h3>{title}</h3>
                </div>
                <div className="accommodation-list-subtitle">{subtitle}</div>
            </div>
            <InfiniteScroll
                dataLength={dataLength}
                next={() => setPage(page + 1)}
                hasMore={hasMore}
                loader={
                    <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" style={{ color: Colors.primary }} />
                    </div>
                }
                className="accommodation-place-list-box"
                scrollThreshold={0.6}
            >

                {data &&
                    Object.keys(data).map(
                        (key: string) =>
                            data[parseInt(key)] &&
                            data[parseInt(key)].map((el: accommodationService, i: number) => (
                                <ServiceCard key={el.id + "-service--" + type + "-" + i} {...el} type="ACCOMMODATION" />
                            ))
                    )}

            </InfiniteScroll>
            {loading && !(dataLength >= page * 3) && (
                <div className="d-flex justify-content-center w-100">
                    <Spinner animation="border" style={{ color: Colors.primary }} />
                </div>
            )}
            {!loading && Object.keys(data).length <= 0 && (
                <div className="no-result-box">
                    <span>No results</span>
                </div>
            )}
        </div>
    )
}


export default AccommodationList;