import React, { useEffect, useState } from "react";
import { drive_banner_image, van_image } from "../utils/images";
import { RootState } from "../store";
import { connect, useDispatch } from "react-redux";
import { getTrails } from "../store/actionCreators/trailsActionCreators";
import { MountainWalkCard } from "../components/Cards";
import { trailData } from "../types/trails";
import Colors from "../utils/colors";
import { Spinner } from "react-bootstrap";
import { getPageData } from "../store/actionCreators/pageActionCreators";
import { PAGE_TYPES } from "../store/actionTypes/pageActionTypes";
import { FullPageLoader } from "../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { TRAILS_TYPES } from "../store/actionTypes/trailsActionTypes";
import parse from "html-react-parser";
import ImageView from "../components/Image";
import { sealedUnsealedInfo } from '../data/sealedUnsealedInfo';
import { Helmet } from 'react-helmet';

const Drive = (props: any) => {
  const { trailsList, loading, pageData, pageLoading, detailedMapsArr } = props;
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(6);
  const [currentData, setCurrentData] = useState<any>([]);

  /**
   * @function dispatch event to get mountain-walk data from server if user is online
   */
  useEffect(() => {
    if (navigator.onLine)
      dispatch(getTrails(page, TRAILS_TYPES.DRIVES_PAGE, filter));
  }, [page, dispatch, filter]);

  const changeFilter = (grade: number) => {
    if (page !== 1) setPage(1);
    setFilter(grade);
  }


  useEffect(() => {
    console.log(detailedMapsArr)
  }, [detailedMapsArr]);

  
  useEffect(() => {
    if (page === 1) {
      setCurrentData([]);
      if (
        trailsList[filter] !== undefined &&
        trailsList[filter][page] !== undefined
      )
        setCurrentData(trailsList[filter][page]);
    } else {
      if (
        trailsList[filter] !== undefined &&
        trailsList[filter][page] !== undefined
      )
        setCurrentData((prev: any) => [...prev, ...trailsList[filter][page]]);
    }
  }, [trailsList, filter, page]);

  // show loader when page data is being loaded
  if (pageLoading && !pageData) {
    return <FullPageLoader />
  }

  return (
    <div className="main-wrapper">
      <Helmet>
        <link rel="canonical" href="https://www.stirlingrangetrails.com.au/drives" />
        <meta name="description" content={pageData?.meta_description || ""} />
        <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
      </Helmet>
      <section className="inner-hero-section">
        <ImageView
          url_src={pageData?.banner_image || drive_banner_image}
          placeholder={drive_banner_image}
          alt={pageData?.banner_image_alt_content}
          description={pageData?.banner_image_description}
        />
      </section>
      <section className="inner-second-section">
        <div className="container">
          <div className="title-section-head-box">
            <div className="title-section-head">
              <div className="title-section-head-icon">
                <img src={van_image} alt="van" />
              </div>
              <h1>{parse(pageData?.h1_tag || 'Drives')}</h1>
            </div>
            <div className="title-section-head-content">
              <div className="title-section-head-title">
                {parse(pageData?.short_description || "")}
              </div>
              <div className="title-section-head-text">
                {parse(pageData?.full_description || "")}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mountain-walk-item-section">
        <div className="container">
          <div className="item-list-fliter-box">
            <div className="item-list-fliter-title">Filter</div>
            <div className="item-list-fliter-field">
              <div className="item-list-fliter-field-item ">
                <div
                  className={`item-list-fliter-field-label cursor-pointer ${filter === 6 ? "active-text" : ""
                    }`}
                  onClick={() => changeFilter(6)}
                >
                  SHOW ALL
                </div>
                <ul className="item-fliter-item-full">
                  <li onClick={() => changeFilter(1)}>
                    <input
                      type="radio"
                      name="grade"
                      id="grade1"
                      checked={filter === 1}
                      onChange={() => changeFilter(1)}
                    />{" "}
                    <label> Sealed Road</label>{" "}
                  </li>
                  <li onClick={() => changeFilter(0)}>
                    <input
                      type="radio"
                      name="grade"
                      id="grade2"
                      checked={filter === 0}
                      onChange={() => changeFilter(0)}
                    />{" "}
                    <label> Unsealed Road</label>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="item-list-fliter-item-text">
              <h2>
                {filter === 1
                  ? "Sealed Road"
                  : filter === 0
                    ? "Unsealed Road"
                    : "ALL"}
              </h2>
              {filter === 1 && sealedUnsealedInfo.sealed}
              {filter === 0 && sealedUnsealedInfo.unsealed}
            </div>
            <div className="offline-instruction-text mt-2">To use offline, click on each trail</div>
          </div>

          <InfiniteScroll
            dataLength={currentData.length}
            next={() => {
              setPage(page + 1);
            }}
            hasMore={currentData.length >= page * 2}
            loader={
              loading ? (
                <div className="d-flex justify-content-center w-100">
                  <Spinner
                    animation="border"
                    style={{ color: Colors.primary }}
                  />
                </div>
              ) : (
                <></>
              )
            }
            className="item-info-list-box"
          >
            {currentData.length > 0 &&
              currentData.map((item: trailData, i: number) => (
                <MountainWalkCard
                  id={item?.id}
                  title={item?.title}
                  key={item?.id + "-drive-" + i}
                  description={item?.short_description}
                  distance={item?.length}
                  elevation={item?.elevation}
                  sealed_unsealed={item?.sealed_unsealed}
                  image={item?.trail_image}
                  time={item?.time}
                  link="/drives"
                  type="DRIVE"
                  trip_type={item.trip_type}
                  road_condition={item.road_condition}
                  item={item}
                  alt={item.banner_image_alt_content}
                  image_description={item.banner_image_description}
                  detailedMapDownloaded={detailedMapsArr.includes(item.id)}
                />
              ))}
          </InfiniteScroll>
          {loading && !(currentData.length >= page * 2) && (
            <div className="d-flex justify-content-center w-100">
              <Spinner animation="border" style={{ color: Colors.primary }} />
            </div>
          )}
          {!loading && currentData.length <= 0 && (
            <div className="no-result-box">
              <span>No results</span>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  const { trails, pages } = state;
  return {
    trailsList: trails.DriveData || {},
    loading: trails.loading,
    pageData: pages.mountainDrivePageData,
    pageLoading: pages.pageLoading,
    detailedMapsArr: trails.detailedMapsArr
  };
}

function mapDispatchToProps(dispatch: any) {
  if (navigator.onLine) dispatch(getPageData(PAGE_TYPES.DRIVES_PAGE));
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Drive);
