import { useEffect, useState } from 'react';
import useShouldShowPrompt from './useShouldShowPrompt';
import { getDevicetype } from '../utils/helper-functions';

const androidInstallPromptedAt = 'androidInstallPromptedAt';

export const isAndroid = (): boolean => {
    const ua = window.navigator.userAgent;
    return !!ua.match(/android/i) || getDevicetype() === 'tablet';
};

const useAndroidInstallPrompt = (): [any, () => void, () => void] => {
    const [installPromptEvent, setInstallPromptEvent] = useState<Event | null>();
    const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(androidInstallPromptedAt);

    useEffect(() => {
        const beforeInstallPromptHandler = (event: Event) => {
            event.preventDefault();
            // check if user has already been asked
            if (userShouldBePromptedToInstall) {
                // store the event for later use
                setInstallPromptEvent(event);
            }
        };
        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    }, [userShouldBePromptedToInstall]);

    const handleInstallDeclined = () => {
        handleUserSeeingInstallPrompt();
        setInstallPromptEvent(null);
    };

    const handleInstallAccepted = () => {
        if (installPromptEvent) {
            // show native prompt
            // @ts-expect-error
            installPromptEvent.prompt();
            // decide what to do after the user chooses
            // @ts-expect-error
            installPromptEvent.userChoice.then(choice => {
                // if the user declined, we don't want to show the prompt again
                if (choice.outcome !== 'accepted') {
                    handleUserSeeingInstallPrompt();
                }
                setInstallPromptEvent(null);
            });
        }
    };
    return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];
};

export default useAndroidInstallPrompt;