import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { FullPageLoader } from "../components/Loader";
import { RootState } from "../store";
import { getPageData } from "../store/actionCreators/pageActionCreators";
import { PAGE_TYPES } from "../store/actionTypes/pageActionTypes";
import {
    food_fuel_banner_image,
    food_icon,
    food_image,
    fuel_image,
} from "../utils/images";
import parse from "html-react-parser";
import ImageView from "../components/Image";
import { getDevicetype } from "../utils/helper-functions";
import { FoodFuelTitleList } from "../components/List";
import { Helmet } from 'react-helmet';

const FuelFood = (props: any) => {
    const { pageData, pageLoading } = props;

    const { FoodFuelFuelData, FoodFuelFoodData, loading } = useSelector((state: RootState) => {
        return {
            FoodFuelFuelData: state.trails.foodFuelFuelDatas,
            FoodFuelFoodData: state.trails.foodFuelFoodDatas,
            loading: state.trails.loading
        }
    });

    const [split, setSplit] = useState<boolean>(getDevicetype() === 'mobile');
    const [selectedCategory, setSelectedCategory] = useState<number>(1);


    const resetDeviceType = () => {
        setSplit(getDevicetype() === 'mobile');
    };

    useEffect(() => {
        window.addEventListener("resize", resetDeviceType);
        return () => {
            window.addEventListener("resize", resetDeviceType);
        };
    }, [split]);

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    const renderFuel = () => {
        return (
            <section className="content-page-second-section content-page-second-reverse fuel-food-first-section">
                <div className="content-page-second-img-big">
                    <ImageView
                        url_src={pageData?.fuel_nearby_image}
                        placeholder={fuel_image}
                        alt={pageData?.fuel_nearby_image_alt_content}
                        description={pageData?.fuel_nearby_image_description}
                    />
                </div>
                <div className="container">
                    <div className="content-page-second-box">
                        <div className="content-page-second-text">
                            <div className="section-title">
                                <h2>
                                    Fuel <br /> Nearby
                                </h2>
                            </div>
                            <div className="content-page-second-img content-page-second-img-mobile">
                                <ImageView
                                    url_src={pageData?.fuel_nearby_image}
                                    placeholder={fuel_image}
                                    alt={pageData?.fuel_nearby_image_alt_content}
                                    description={pageData?.fuel_nearby_image_description}
                                />
                            </div>
                            <div className="section-content-box">
                                {parse(
                                    pageData?.fuel_nearby_content || "No description available"
                                )}
                            </div>
                        </div>
                        <div className="content-page-second-img content-page-second-img-desktop">
                            <ImageView
                                url_src={pageData?.fuel_nearby_image}
                                placeholder={fuel_image}
                                alt={pageData?.fuel_nearby_image_alt_content}
                                description={pageData?.fuel_nearby_image_description}
                            />
                        </div>
                    </div>
                    <FoodFuelTitleList apidata={FoodFuelFuelData} loading={loading} type={1} />
                </div>
            </section>
        )
    }

    const renderFood = () => {
        return (
            <section className="content-page-second-section  fuel-food-second-section">
                <div className="content-page-second-img-big">
                    <ImageView
                        url_src={pageData?.food_and_meals_image}
                        placeholder={food_image}
                        alt={pageData?.food_and_meals_image_alt_content}
                        description={pageData?.food_and_meals_image_description}
                    />
                </div>
                <div className="container">
                    <div className="content-page-second-box">
                        <div className="content-page-second-text">
                            <div className="section-title">
                                <h2>
                                    Food
                                </h2>
                            </div>
                            <div className="content-page-second-img content-page-second-img-mobile">
                                <ImageView
                                    url_src={pageData?.food_and_meals_image}
                                    placeholder={food_image}
                                    alt={pageData?.food_and_meals_image_alt_content}
                                    description={pageData?.food_and_meals_image_description}
                                />
                            </div>
                            <div className="section-content-box">
                                {parse(
                                    pageData?.food_and_meals_content || "No description available"
                                )}
                            </div>
                        </div>
                        <div className="content-page-second-img content-page-second-img-desktop">
                            <ImageView
                                url_src={pageData?.food_and_meals_image}
                                placeholder={food_image}
                                alt={pageData?.food_and_meals_image_alt_content}
                                description={pageData?.food_and_meals_image_description}
                            />
                        </div>
                    </div>
                    <FoodFuelTitleList apidata={FoodFuelFoodData} loading={loading} type={2} />
                </div>
            </section>
        )
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/fuel-food" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image}
                    placeholder={food_fuel_banner_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={food_icon} alt="food icon" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Fuel & Food')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(
                                    pageData?.banner_short_description ||
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed est viverra, pretium ligula vitae, cursus quam."
                                )}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.banner_full_description || "")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {split &&
                <section className="content-page-second-section fuel-food-filter-section">
                    <div className="container">
                        <div className="item-list-fliter-box">
                            <div className="item-list-fliter-field">
                                <div className="item-list-fliter-field-item ">
                                    <ul className="item-fliter-item-full">
                                        <li onClick={() => setSelectedCategory(1)}>
                                            <input type="radio" name="grade" id="grade1" onChange={() => setSelectedCategory(1)} checked={selectedCategory === 1} />{" "}
                                            <label> Fuel</label>{" "}
                                        </li>
                                        <li onClick={() => setSelectedCategory(2)}>
                                            <input type="radio" name="grade" id="grade2" onChange={() => setSelectedCategory(1)} checked={selectedCategory === 2} />{" "}
                                            <label> Food</label>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {split ?
                selectedCategory === 1 ? renderFuel() : renderFood()
                : (
                    <>
                        {renderFuel()}

                        {renderFood()}
                    </>
                )}
        </div >
    );
};

function mapStateToProps(state: RootState) {
    const { pages } = state;
    return {
        pageData: pages.foodFuelPageData,
        pageLoading: pages.pageLoading,
    };
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine) dispatch(getPageData(PAGE_TYPES.FOOD_FUEL_PAGE));
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelFood);
