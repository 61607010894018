import useShouldShowPrompt from './useShouldShowPrompt';

const iosInstallPromptedAt = 'iosInstallPromptedAt';

export const isIOS = (): boolean => {
  // @ts-ignore
  // if (navigator.standalone) {
  //   //user has already installed the app
  //   return false;
  // }
  const ua = window.navigator.userAgent;
  const isIPad = !!ua.match(/iPad/i);
  const isIPad13 = !!ua.match(/Macintosh/i);
  const isIPhone = !!ua.match(/iPhone/i);
  return isIPad || isIPhone || isIPad13;
};

const useIosInstallPrompt = (): [boolean, () => void] => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(iosInstallPromptedAt, 1);

  return [isIOS() && userShouldBePromptedToInstall, handleUserSeeingInstallPrompt];
};

export default useIosInstallPrompt;
