import { lazy } from 'react';

/**
 * @Routes
 */
const routes = [
    {
        path: "/",
        exact: true,
        component: lazy(() => import('../pages/Home')),
    },
    {
        path: "/about",
        exact: false,
        component: lazy(() => import('../pages/About')),
    },
    {
        path: "/privacy-policy",
        exact: false,
        component: lazy(() => import('../pages/PrivacyPolicy')),
    },
    {
        path: "/terms-and-conditions",
        exact: false,
        component: lazy(() => import('../pages/TermsAndCondition')),
    },
    {
        path: "/accommodation",
        exact: false,
        component: lazy(() => import('../pages/Accommodation')),
    },
    {
        path: "/drives",
        exact: true,
        component: lazy(() => import('../pages/Drive')),
    },
    {
        path: "/drives/:id",
        exact: false,
        component: lazy(() => import('../pages/DriveDetail')),
    },
    {
        path: "/fuel-food",
        exact: false,
        component: lazy(() => import('../pages/FuelFood')),
    },
    {
        path: "/get-directions",
        exact: false,
        component: lazy(() => import('../pages/GetDirections')),
    },
    {
        path: "/mountain-bike",
        exact: true,
        component: lazy(() => import('../pages/MountainBike')),
    },
    {
        path: "/mountain-bike/:id",
        exact: false,
        component: lazy(() => import('../pages/MountainBikeDetail')),
    },
    {
        path: "/mountain-walk",
        exact: true,
        component: lazy(() => import('../pages/MountainWalk')),
    },
    {
        path: "/mountain-walk/:id",
        exact: false,
        component: lazy(() => import('../pages/MountainWalkDetail')),
    },
    {
        path: "/wild-flower",
        exact: false,
        component: lazy(() => import('../pages/WildFlower')),
    },
    {
        path: "/live-trail/:type/:id",
        exact: false,
        component: lazy(() => import('../pages/LiveTrail')),
    },
    {
        path: "/download-app",
        exact: false,
        component: lazy(() => import('../pages/DownloadApp')),
    },
    {
        path: "*",
        exact: false,
        component: lazy(() => import('../pages/Error')),
    },
]

export default routes;