const constants = {
  APP_NAME: "Stirling Range Trails",
  FACEBOOK_URL: "https://www.facebook.com/StirlingRangeTrails",
  INSTAGRAM_URL: "https://www.instagram.com/stirlingrangetrails/",
  MOUNTAIN_WALK_FILTER1_ERROR_MSG: `The minimum grade for walks in this area is grade three. Please click on grade three for specific walk information. 
 <br/><br/>
  At the base of Mt Trio, Mt Magog, Mt Talyuberlup, Mt Toolbrunup and Mt Hassell you can still take in the
  Amazing WA bush – the smell of eucalypt trees, glorious sounds of the local birdlife and wildlife sightings.
   <br/><br/>
  Remember  you don’t always need to climb to the top of the mountains to have wonderful experience. There
  are bush picnic areas at the base of Mt Magog and at Redgum Pass and lookouts on Stirling Range Drive.
  <br/>
  <br/>
  Bluff Knoll carpark lookout gives an opportunity for visitors to take in the excellent vista across the Stirling Range and surrounding farmland. The Stirling Range Interpretive Shelter at Bluff Knoll lookout is wheelchair accessible via a ramp.`,
  MOUNTAIN_WALK_FILTER2_ERROR_MSG: `The minimum grade for walks in this area is grade three. Please click on grade three for specific walk information. 
 <br/><br/>
  At the base of Mt Trio, Mt Magog, Mt Talyuberlup, Mt Toolbrunup and Mt Hassell you can still take in the
  Amazing WA bush – the smell of eucalypt trees, glorious sounds of the local birdlife and wildlife sightings.
   <br/><br/>
  Remember  you don’t always need to climb to the top of the mountains to have wonderful experience. There
  are bush picnic areas at the base of Mt Magog and at Redgum Pass and lookouts on Stirling Range Drive.
  <br/>
  <br/>
  There are a number of fire break trails within the Stirling Range National Park suitable for walking. These walks are all gravel/sand/rocky and undulating. Check with your local accommodation host to see what firebreak walks are near where you are staying. `,
};

export default constants;
