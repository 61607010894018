import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import { getBulkPageContent } from "../../services/apis/common";
import {
  fetchDownloadAppDeviceInfo,
  fetchPageData,
} from "../../services/apis/pagesServices";
import * as actionCreators from "../actionCreators/pageActionCreators";
import * as trailsActionCreators from "../actionCreators/trailsActionCreators";
import * as actionTypes from "../actionTypes/pageActionTypes";

function* onLoadPageData({ pageName }: actionTypes.GetPageDataAction) {
  try {
    yield put(actionCreators.setPageDataLoading(true));
    const { data } = yield call(fetchPageData, pageName);
    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(actionCreators.getPageDataSuccess(data.data, pageName));
        return;
      }
    }
    yield put(actionCreators.getPageDataFailure(""));
  } catch (error: any) {
    yield put(actionCreators.setPageDataLoading(false));
    if (error && error.response && error.response.data)
      yield put(actionCreators.getPageDataFailure(error.response.data.error));
    else yield put(actionCreators.getPageDataFailure(""));
  }
}

function* onLoadDownloadAppData({ device }: actionTypes.GetDownloadDataAction) {
  try {
    yield put(actionCreators.setDownloadDataLoading(true));
    const { data } = yield call(fetchDownloadAppDeviceInfo, device);
    yield put(actionCreators.setDownloadDataLoading(false));
    if (data) {
      if (data.status === 200 && data.success === true) {
        yield put(actionCreators.getDownloadDataSuccess(data.data, device));
        return;
      }
    }
  } catch (error) {
    yield put(actionCreators.setDownloadDataLoading(false));
  }
}

function* onLoadBulkPageData() {
  try {
    yield put(actionCreators.setPageDataLoading(true));
    const { data } = yield call(getBulkPageContent);
    if (data && data.success) {
      // const kmlFilesLinks = data.data.trailsKMLFiles.map((el: string) => ({
      //   url: el,
      //   revision: "1",
      // }));
      // yield put(trailsActionCreators.setKmlLinkData(kmlFilesLinks));

      yield put(
        trailsActionCreators.getWildFlowerSuccess(data.data.wildflowers, 1)
      );
      yield put(
        trailsActionCreators.getAboutPageImagesSuccess(
          data.data.aboutPageImages
        )
      );
      const TAItems = [1, 1, 1, 1];
      for (let i = 0; i < data.data.accommodation.length; i++) {
        const id = data.data.accommodation[i].accommodation_title_id;
        yield put(
          trailsActionCreators.SetSingleAccommodationService(
            data.data.accommodation[i],
            Math.ceil(TAItems[id - 1] / 3),
            id
          )
        );
        TAItems[id - 1] = TAItems[id - 1] + 1;
      }

      const FuelCounts = [1, 1, 1, 1];
      const FoodCounts = [1, 1, 1, 1];
      for (let i = 0; i < data.data.fuelAndFoods.length; i++) {
        const id = data.data.fuelAndFoods[i].fuel_and_food_title_id;
        if (data.data.fuelAndFoods[i].fuel_food === 0) {
          yield put(
            trailsActionCreators.setSingleFuelData(
              data.data.fuelAndFoods[i],
              Math.ceil(FuelCounts[id - 1] / 3),
              id
            )
          );
          FuelCounts[id - 1] = FuelCounts[id - 1] + 1;
        } else {
          yield put(
            trailsActionCreators.setSingleFoodData(
              data.data.fuelAndFoods[i],
              Math.ceil(FoodCounts[id - 1] / 3),
              id
            )
          );
          FoodCounts[id - 1] = FoodCounts[id - 1] + 1;
        }
      }
    }
    yield put(actionCreators.setPageDataLoading(false));
  } catch (error) {
    yield put(actionCreators.setPageDataLoading(false));
    console.log(error);
  }
}

function* watchOnLoadPageData() {
  yield takeEvery(actionTypes.GET_PAGE_DATA, onLoadPageData);
}

function* watchOnLoadDownloadAppData() {
  yield takeEvery(actionTypes.GET_DOWNLOAD_DATA, onLoadDownloadAppData);
}

function* watchOnLoadBulkPageData() {
  yield takeEvery(actionTypes.GET_BULK_PAGE_DATA, onLoadBulkPageData);
}

export default function* pageDataSaga() {
  yield all([
    fork(watchOnLoadPageData),
    fork(watchOnLoadDownloadAppData),
    fork(watchOnLoadBulkPageData),
  ]);
}
