import L from 'leaflet'
//@ts-ignore
import { getStorageInfo, getTileUrls, downloadTile, saveTile } from '../../node_modules/leaflet.offline/src/TileManager'
import 'leaflet.offline'
import { store, RootState } from "../store";
import { SET_MAPS_LOADED, SET_MAPS_PROGRESS, SET_MAP_AS_DETAILED } from '../store/actionTypes/trailsActionTypes';

// some config constants
const offlineZoomRange = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18] // offline zoom range
const padFactorArray = [5, 4, 3, 2, 1, 1, 1, 0.4, 0.2, 0.1, 0.05] // padding factor for each zoom level (bigger padding at lower zoom levels where tiles are cheap)
const maxTilesPerZoomLevel = 500 // no zoom level with tile count over this limit will be stored

export const basicZoomLevelsForWalk = [9, 10, 11, 12, 13, 14] //default zoom varies for differernt trail sizes/device platform combos
export const basicZoomLevelsForDrive = [9, 10] // drives are more limited

/**
 * Return array of coords from KML text in ALL <coordinates> tags
 *
 * @param string kmlText
 * @return array allCoords
 */
export const parseKkmlForCoords = (kmlText: string) => {
  try {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(kmlText, "text/xml");
    if (xmlDoc.documentElement.nodeName === "kml") {
      const allCoords = []
      for (const item of xmlDoc.getElementsByTagName("coordinates") as any) {
        const sets = item.textContent.trim().split(" ")
        for (const set of sets) {
          const coords = set.split(",")
            allCoords.push([coords[0], coords[1]])
        }
      }
      return allCoords
    } else {
      return null
    }
  } catch (err) {
    return null
  }
};


/**
 * Return the bounds of all coordinates
 *
 * @param string kmlText
 * @return L.bounds bounds
 */
const getBoundsKML = async (kmlText: string) => {
  // get bounds of all coords in kml
  const coordsList = await parseKkmlForCoords(kmlText)
  if (coordsList) {
    const bounds = L.latLngBounds(coordsList.map((i: any) => { 
      return [i[1], i[0]]; 
    }));
    return bounds
  }
}


/**
 * Return the bounds of all coordinates from KML by URL
 *
 * @param string kmlUrl
 * @return L.bounds bounds
 */
 const getBoundsKmlFromUrl = async (url: string) => {
  console.log('fetching KML')
  const response = await fetch(url)
  const plaintext = await response.text()
  // console.log(plaintext)
  const bounds = getBoundsKML(plaintext)
  // console.log(bounds)
  return bounds
}


/**
 * Fetch basic version of all trail's map
 *
 * @param string kmlUrl
 */
 export const fetchAllMaps = async (map: any) => {
  const { trails } = store.getState();

  console.log('whole store: ', store.getState())

    // GET ALL THE WALKS - at zoom levels [9, 10, 11, 12, 13, 14]
    let walksProcessed = 0
    let drivesProcessed = 0 
    let totalWalks = trails.MountainWalkData[0][1].length
    let totalDrives = trails.DriveData[6][1].length

    trails.MountainWalkData[0][1].forEach(async (item: any, index: number, array: any[]) => {
      
        let startTime = performance.now()

        const bounds = await getBoundsKmlFromUrl(item.trail_data_link)
        if (bounds) {
          
          // @ts-ignore
          const tileLayerOffline = L.tileLayer.offline(
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
          ).addTo(map);
          let totalTiles = 0
          for (const zoom of basicZoomLevelsForWalk) {
            const paddedBounds = await addPadding(bounds, zoom)
            const p1 = map.options.crs.latLngToPoint(paddedBounds.getNorthEast(), zoom).floor(); // prepare the boundary points
            const p2 = map.options.crs.latLngToPoint(paddedBounds.getSouthWest(), zoom).floor();
            const tiles = getTileUrls(tileLayerOffline, L.bounds(p1,p2), zoom) // get urls for tiles we need
            let storageInfo = await getStorageInfo('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png') // get keys of tiles we have in db already 
            let skipped = 0
              for (let i = 0; i < tiles.length; i++) {
                const tileInfo: any = tiles[i]
                if (storageInfo.some((e: any) => e.key === tileInfo.key)) { // check if we have this tile in db
                  skipped++
                } else {
                  //@ts-ignore
                  downloadTile(tiles[i].url).then((blob: any) => saveTile(tileInfo, blob)) // download tile
                }
              }
              console.log(`Zoom level: ${zoom} - Already had ${skipped} of ${tiles.length} tiles`)
              totalTiles = totalTiles + tiles.length
          }
          console.log(`GOT basic zoom levels: ${basicZoomLevelsForWalk}.\n TOTAL MAP has: ${totalTiles} tiles! fetchMapForOffline took ${performance.now() - startTime} milliseconds`)
        }
        store.dispatch({ type: SET_MAPS_PROGRESS })
        walksProcessed++
        if (walksProcessed === totalWalks && drivesProcessed === totalDrives) {
          console.log('ALL MAPS DONE')
          store.dispatch({ type: SET_MAPS_LOADED })
        }
    })
    
    // GET ALL THE DRIVES - at zoom level [9, 10]
    trails.DriveData[6][1].forEach(async (item: any, index: number, array: any[]) => {
      let startTime = performance.now()
      const bounds = await getBoundsKmlFromUrl(item.trail_data_link)
      if (bounds) {
        // @ts-ignore
        const tileLayerOffline = L.tileLayer.offline(
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
        ).addTo(map);
        let totalTiles = 0
        for (const zoom of basicZoomLevelsForDrive) {
          const paddedBounds = await addPadding(bounds, zoom)
          const p1 = map.options.crs.latLngToPoint(paddedBounds.getNorthEast(), zoom).floor(); // prepare the boundary points
          const p2 = map.options.crs.latLngToPoint(paddedBounds.getSouthWest(), zoom).floor();
          const tiles = getTileUrls(tileLayerOffline, L.bounds(p1,p2), zoom) // get urls for tiles we need
          let storageInfo = await getStorageInfo('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png') // get keys of tiles we have in db already 
          let skipped = 0
            for (let i = 0; i < tiles.length; i++) {
              const tileInfo: any = tiles[i]
              if (storageInfo.some((e: any) => e.key === tileInfo.key)) { // check if we have this tile in db
                skipped++
              } else {
                //@ts-ignore
                downloadTile(tiles[i].url).then((blob: any) => saveTile(tileInfo, blob)) // download tile
              }
            }
            console.log(`Zoom level: ${zoom} - Already had ${skipped} of ${tiles.length} tiles`)
            totalTiles = totalTiles + tiles.length
        }
        console.log(`GOT basic zoom levels: ${basicZoomLevelsForDrive}.\n TOTAL MAP has: ${totalTiles} tiles! fetchMapForOffline took ${performance.now() - startTime} milliseconds`)
      }
      store.dispatch({ type: SET_MAPS_PROGRESS })
      drivesProcessed++
      if (walksProcessed === totalWalks && drivesProcessed === totalDrives) {
        console.log('ALL MAPS DONE')
        store.dispatch({ type: SET_MAPS_LOADED })
      }
  })
 }


/**
 * Return the bounds of all coordinates plus padding factor for current zoom level
 *
 * @param L.bounds bounds
 * @param number zoom
 * @return L.bounds bounds
 */
const addPadding = async (bounds: L.LatLngBounds, zoom: number) => {
  const padFactorIndex = offlineZoomRange.indexOf(zoom)
  const padFactor = padFactorArray[padFactorIndex]
  console.log('Index position of zoomLevel: ' + zoom + ' is index: ' + padFactorIndex + ' with padFactor of: ' + padFactor)
  return bounds.pad(padFactor)
}

/**
 * Return the maximum zoom level of trail based on max tiles constants
 *
 * @param string kmlText
 * @param object map
 * @return number zoom
 */
export const calculateMaxOfflineZoom = async (kmlText: string, map: any) => {
  console.log('calculateMaxOfflineZoom function')
  let startTime = performance.now()
    const bounds = await getBoundsKML(kmlText)
    if (bounds && map) {
      // @ts-ignore
      const tileLayerOffline = L.tileLayer.offline(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
      ).addTo(map)
        let copy = [...offlineZoomRange]
        for (const zoom of copy.reverse()) {
          const paddedBounds = await addPadding(bounds, zoom)
          const p1 = map.options.crs.latLngToPoint(paddedBounds.getNorthEast(), zoom).floor() // prepare the boundary points
          const p2 = map.options.crs.latLngToPoint(paddedBounds.getSouthWest(), zoom).floor()
          const tiles = getTileUrls(tileLayerOffline, L.bounds(p1,p2), zoom) // tally the tiles
          if (tiles.length < maxTilesPerZoomLevel) {
            console.log(`Max zoom level found: ${zoom} - in ${performance.now() - startTime} milliseconds`)
            return zoom
          }
        }
    }
}


/**
 * Fetch any tiles that are missing from the offline db
 * 
 * @param {string} kmlText
 * @param {object} map
 * @returns {void}
 */

export const fetchMapForOffline = async (kmlText: string, map: any, trailId: number) => {
  console.log('fetchMapForOffline function')
  let startTime = performance.now()
    const bounds = await getBoundsKML(kmlText)
    if (bounds) {
      // @ts-ignore
      const tileLayerOffline = L.tileLayer.offline(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}
      ).addTo(map);
      let totalTiles = 0
      for (const zoom of offlineZoomRange) {
        const paddedBounds = await addPadding(bounds, zoom)
        const p1 = map.options.crs.latLngToPoint(paddedBounds.getNorthEast(), zoom).floor(); // prepare the boundary points
        const p2 = map.options.crs.latLngToPoint(paddedBounds.getSouthWest(), zoom).floor();
        const tiles = getTileUrls(tileLayerOffline, L.bounds(p1,p2), zoom) // get urls for tiles we need
        let storageInfo = await getStorageInfo('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png') // get keys of tiles we have in db already 
        let skipped = 0
        if (tiles.length < maxTilesPerZoomLevel) {
          for (let i = 0; i < tiles.length; i++) {
            const tileInfo: any = tiles[i]
            if (storageInfo.some((e: any) => e.key === tileInfo.key)) { // check if we have this tile in db
              skipped++
            } else {
              //@ts-ignore
              downloadTile(tiles[i].url).then((blob: any) => saveTile(tileInfo, blob)) // download tile
            }
          }
          console.log(`Zoom level: ${zoom} - Already had ${skipped} of ${tiles.length} tiles`)
          totalTiles = totalTiles + tiles.length
        } else {
          console.log(`SKIPPING zoom levels at: ${zoom} has ${tiles.length} tiles\n TOTAL MAP has: ${totalTiles} tiles in ${performance.now() - startTime}`)
          store.dispatch({ type: SET_MAP_AS_DETAILED, data: trailId })
          return
        }
      }
      store.dispatch({ type: SET_MAP_AS_DETAILED, data: trailId })
      console.log(`GOT ALL zoom levels up to 18.\n TOTAL MAP has: ${totalTiles} tiles! fetchMapForOffline took ${performance.now() - startTime} milliseconds`)
    }
}

