import React from 'react';
import { Spinner, ProgressBar } from 'react-bootstrap';
import Logo from '../Logo';

function FullPageLoader(props: { now?: number }) {

    const { now } = props;
    const progressInstance = <ProgressBar variant="success" now={now} label={`${now}%`}
        style={{ minWidth: '100px', height: '12px', marginTop: 12, color: 'black' }} />;

    return (
        <div className="loader-wrapper">
            <div className="loader-body align-items-center d-flex flex-column">
                <Logo />
                <br />
                <Spinner animation={'border'} className="text-success" style={{ marginTop: 10 }} />
                {now && progressInstance}
            </div>
        </div>
    )
}

export default FullPageLoader;