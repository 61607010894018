import React from 'react';
import { connect } from 'react-redux';
import { FullPageLoader } from '../components/Loader';
import { RootState } from '../store';
import { getPageData } from '../store/actionCreators/pageActionCreators';
import { PAGE_TYPES } from '../store/actionTypes/pageActionTypes';
import { terms_page_icon, privacy_banner_image } from '../utils/images';
import parse from 'html-react-parser';
import ImageView from '../components/Image';
import { Helmet } from 'react-helmet';

const TermsAndConditions = (props: any) => {
    const { pageData, pageLoading } = props;

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/terms-and-conditions" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image || privacy_banner_image}
                    placeholder={privacy_banner_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={terms_page_icon} alt="info" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Terms and Conditions')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(pageData?.short_description || "")}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.full_description || "")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {pageData?.terms_and_conditions_content && (
                <section className="privacy-content-section">
                    <div className="container">
                        <div className="privacy-content-box">
                            <div className="privacy-content-title">
                                {parse(pageData?.page_title || "")}
                            </div>
                            <div className="privacy-content-text">
                                {parse(pageData?.terms_and_conditions_content || "")}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}

function mapStateToProps(state: RootState) {
    const { pages } = state;
    return { pageData: pages.termsAndConditionsData, pageLoading: pages.pageLoading }
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine)
        dispatch(getPageData(PAGE_TYPES.TERMS_AND_CONDITIONS))
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);