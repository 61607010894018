import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../store";
import {
    about_banner_image,
    apple_icon,
    android_icon,
    install_android_app_image_desktop,
    install_ios_app_image_desktop,
    cog_wheel,
    install_ios_app_image_mobile,
    install_android_app_image_mobile,
} from "../utils/images";
import { getPageData } from "../store/actionCreators/pageActionCreators";
import { PAGE_TYPES } from "../store/actionTypes/pageActionTypes";
import { FullPageLoader } from "../components/Loader";
import parse from "html-react-parser";
import ImageView from "../components/Image";
import { isIOS } from "../hooks/useIosInstallPrompt";
import { isAndroid } from '../hooks/useAndroidInstallPrompt';
import { Helmet } from 'react-helmet';
import DeviceInstruction from "../components/List/DeviceInstruction";

const DownloadApp = (props: any) => {
    const { pageData, pageLoading, iosInfo, androidInfo, } = props;

    const [activeInstallButton, setActiveInstallButton] = useState("ios");
    const [enableShow, setEnableShow] = React.useState(false);

    useEffect(() => {
        if (isIOS()) {
            setActiveInstallButton("ios");
        }
        if (isAndroid()) {
            setActiveInstallButton("android");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const setActiveButtonType = (type: string) => {
        setActiveInstallButton(type);
        setEnableShow(true);

    }

    useEffect(() => {
        if (enableShow) {
            if ((activeInstallButton === 'android' && androidInfo) || (activeInstallButton === 'ios' && iosInfo)) {
                const a = document.createElement('a');
                a.href = "#location-steps";
                a.click()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeInstallButton, enableShow, iosInfo, androidInfo])

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />;
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/download-app" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image || about_banner_image}
                    placeholder={about_banner_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>

            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={cog_wheel} alt="" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Download App')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(pageData?.banner_short_description || "")}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.banner_full_description || "")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-app-install-section bg-white" id="install">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="home-app-install-img">
                                <img
                                    src={
                                        activeInstallButton === "ios"
                                            ? install_ios_app_image_desktop
                                            : install_android_app_image_desktop
                                    }
                                    alt="install app"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>How to install this app onto your phone</h2>
                            </div>
                            <div className="section-content-box">
                                {parse(pageData?.android_installation_content || "")}
                            </div>
                            <div className="home-app-install-img-mob">
                                <img
                                    src={
                                        activeInstallButton === "ios"
                                            ? install_ios_app_image_mobile
                                            : install_android_app_image_mobile
                                    }
                                    alt="install app"
                                />
                            </div>
                            <div className="home-app-install-btn">
                                <button
                                    className={`${activeInstallButton === "ios" ? "active" : ""} mr-lg-2`}
                                    onClick={() => setActiveButtonType("ios")}
                                >
                                    <div className="install-btn-container">
                                        <span>
                                            <img src={apple_icon} alt="apple icon" width="28" height="28" />
                                        </span>
                                        <label>Apple</label>
                                    </div>
                                </button>

                                <button
                                    className={`${activeInstallButton === "android" ? "active" : ""
                                        }`}
                                    onClick={() => setActiveButtonType("android")}
                                >
                                    <div className="install-btn-container">
                                        <span>
                                            <img src={android_icon} alt="android icon" width="28" height="28" />
                                        </span>
                                        <label>Android</label>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <DeviceInstruction activeInstallButton={activeInstallButton} />

        </div>
    );
};

function mapStateToProps(state: RootState) {
    const { pages } = state;
    return {
        pageData: pages.downloadAppPageData,
        pageLoading: pages.pageLoading,
        iosInfo: pages.downloadAppDeviceInfo.ios,
        androidInfo: pages.downloadAppDeviceInfo.android,
    };
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine) dispatch(getPageData(PAGE_TYPES.DOWNLOAD_APP_PAGE));
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadApp);
