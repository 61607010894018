import React from 'react';
import { Spinner } from 'react-bootstrap';

function ImageLoader() {
    return (
        <div className="image-loader-wrapper">
            <Spinner animation="grow" style={{ backgroundColor: '#26262b', color: '#5dfc0a', padding: '20px' }} />
        </div>
    )
}

export default ImageLoader;