import { AxiosResponse } from "axios";
import API, { getPublicKey } from "./index";
import { trailsResponse } from "../../types/trails";
import { wildFlowerResponse } from "../../types/wildFlowers";
import { foodFuelFoodData } from "../../types/foodFuelType";
import { accommodationPageContentResponse } from "../../types/accommodationType";
import { aboutUsImagesResponse } from "../../types/aboutUsType";
import { HomePageBestMomentsResponse } from "../../types/HomeType";

const paths = {
  GET_TRAILS: "/trails/get_trails",
  GET_SINGLE_TRAIL: "/trails/get_trail_details",
  GET_WILD_FLOWER: "/wildflowers/get_wildflower",
  GET_ABOUTUS_IMAGES: "/aboutus/images",
  GET_ACCOMMODATION_SERVICES: "/accommodation/accommodation",
  GET_FOOD_FUEL_FOOD_DATA: "/fuel_and_food/food",
  GET_FOOD_FUEL_FULES_DATA: "/fuel_and_food/fuel",
  GET_HOME_PAGE_BEST_MOMENTS: "/home/best_moments",
};

export async function fetchTrails(
  page: number,
  trail_type: number,
  filter: number
): Promise<trailsResponse | AxiosResponse> {
  let data = {
    page,
    trail_type,
    filter,
    private_key: getPublicKey(),
  };

  if (filter === 0 && trail_type !== 1) {
    try {
      // @ts-expect-error delete if filter value is 0
      delete data?.filter;
    } catch (err) {}
  }

  if (filter === 6 && trail_type === 1) {
    try {
      // @ts-expect-error delete if filter value is 0
      delete data?.filter;
    } catch (err) {}
  }

  return await API.post(paths.GET_TRAILS, data);
}

export async function fetchSingleTrail(
  trail_id: number
): Promise<AxiosResponse> {
  const data = {
    trail_id,
    private_key: getPublicKey(),
  };

  return await API.post(paths.GET_SINGLE_TRAIL, data);
}

export async function fetchWildFlowers(
  page: number
): Promise<wildFlowerResponse | AxiosResponse> {
  const data = {
    page,
    private_key: getPublicKey(),
  };

  return await API.post(paths.GET_WILD_FLOWER, data);
}

export async function fetchAboutPageImages(
  page: number
): Promise<aboutUsImagesResponse | AxiosResponse> {
  const data = {
    page,
    private_key: getPublicKey(),
  };
  return await API.post(paths.GET_ABOUTUS_IMAGES, data);
}

export async function fetchAccommodationServices(
  page: number,
  accommodation_title_id: number
): Promise<accommodationPageContentResponse | AxiosResponse> {
  const data = {
    page,
    accommodation_title_id,
    private_key: getPublicKey(),
  };
  return await API.post(paths.GET_ACCOMMODATION_SERVICES, data);
}

export async function fetchFuelData(
  page: number,
  fuel_and_food_title_id: number
): Promise<foodFuelFoodData | AxiosResponse> {
  const data = {
    page,
    fuel_and_food_title_id,
    private_key: getPublicKey(),
  };
  return await API.post(paths.GET_FOOD_FUEL_FULES_DATA, data);
}

export async function fetchFoodData(
  page: number,
  fuel_and_food_title_id: number
): Promise<foodFuelFoodData | AxiosResponse> {
  const data = {
    page,
    fuel_and_food_title_id,
    private_key: getPublicKey(),
  };
  return await API.post(paths.GET_FOOD_FUEL_FOOD_DATA, data);
}

export async function fetchBestMoments(
  page: number
): Promise<HomePageBestMomentsResponse | AxiosResponse> {
  const data = {
    page,
    private_key: getPublicKey(),
  };
  return await API.post(paths.GET_HOME_PAGE_BEST_MOMENTS, data);
}
