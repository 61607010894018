export const getTripType = (trip_type: number, trial_type: string) => {
  switch (trip_type) {
    case 1:
      return "(one way)";
    case 2:
      return trial_type === "DRIVE" ? "(return drive)" : "(return)";
    case 4:
      return "(round trip)";
    case 3:
      return "(loop)";
    default:
      return "";
  }
};

export interface trail_images {
  id: number;
  trail_id: number;
  image_link: string;
  created_at: string;
  updated_at: string;
  image_alt_content: string | null;
  image_description: string | null;
}

export interface PoinPoints {
  created_at: string;
  description: string;
  id: number;
  image: string;
  image_link: string;
  latitude: string;
  longitude: string;
  title: string;
  trail_id: number;
  updated_at: string;
}

export interface trailData {
  id: number;
  title: string;
  short_description: string;
  full_description: string;
  trail_type: string;
  filter: string;
  length: string;
  time: string;
  trail_data_link: string;
  trail_image: string;
  elevation: string;
  status: number;
  road_condition: string;
  sealed_unsealed: number;
  trip_type: number;
  pin_location: PoinPoints[];
  trail_images: trail_images[];
  h1_tag: string | null;
  meta_description: string | null;
  meta_title: string | null;
  banner_image_alt_content: string | null;
  banner_image_description: string | null;
}

export interface trailDataSeasons {
  content: string;
  season_id: number;
  season: string;
}
export interface trailsResponse {
  success: boolean;
  data: trailData[];
  message: string;
  status: number;
}

export interface trailPageContent {
  banner_image: string;
  banner_image_alt_content: string | null;
  banner_image_description: string | null;
  trail_type: number;
  short_description: string;
  full_description: string;
  safety_information: string | null;
  before_travel: string;
  before_walk: string;
  seasons: trailDataSeasons[];
  h1_tag: string | null;
  meta_description: string | null;
  meta_title: string | null;
}

export interface trailPageContentResponse {
  success: boolean;
  data: trailPageContent;
  message: string;
  status: number;
}
