import React, { useState, useEffect } from 'react';

import {
    about_menu_icon, accommodation_menu_icon, close_menu_icon, direction_menu_icon, drives_menu_icon, food_fuel_menu_icon, install_menu_icon, logo,
    mountain_bike_menu_icon,
    mountain_walk_menu_icon, privacy_menu_icon, wild_flower_menu_icon
} from '../../utils/images';
import { slide as Menu } from 'react-burger-menu';
import { useLocation, Link } from "react-router-dom";


function Header() {

    const location = useLocation();
    const splitLocation = React.useMemo(() => {
        const { pathname } = location;
        return pathname.split("/");
    }, [location]);

    const [menuOpen, setMenuOpen] = useState(false);
    const [installPromptEvent, setInstallPromptEvent] = useState<Event | null>();

    useEffect(() => {
        const beforeInstallPromptHandler = (event: Event) => {
            event.preventDefault();
            setInstallPromptEvent(event);
        };
        window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
        return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    }, []);

    const closeAllMenusOnEsc = (e: any) => {
        e = e || window.event;
        if (e.key === 'Escape' || e.keyCode === 27) {
            setMenuOpen(false);
        }
    };

    const installApp = () => {
        setMenuOpen(false);
        if (installPromptEvent) {
            // show native prompt
            // @ts-expect-error
            installPromptEvent.prompt();
            // @ts-expect-error
            installPromptEvent.userChoice.then(choice => {
                setInstallPromptEvent(null);
            });
        }
    }

    return (
        <header className="header-main">
            <div className="container">
                <div className="header-main-inner">
                    <div className="brand-header">
                        <Link to="/">
                            <img src={logo} className="brand-desktop" alt="logo" />
                            <img src={logo} alt="logo" className="brand-mobile" />
                        </Link>
                    </div>
                    <div className="nav-wrapper">
                        <nav className="custom-nav">
                            <div className="header-top">
                                <ul>
                                    <li className={splitLocation[1] === "about" ? "active" : ""}>
                                        <Link to="/about">About</Link>
                                    </li>
                                    <li className={splitLocation[1] === "fuel-food" ? "active" : ""}>
                                        <Link to="/fuel-food">Fuel & Food</Link>
                                    </li>
                                    <li className={splitLocation[1] === "accommodation" ? "active" : ""}>
                                        <Link to="/accommodation">Accommodation</Link>
                                    </li>
                                    {/* <li className={splitLocation[1] === "download-app" ? "active" : ""}>
                                        <Link to="/download-app">Download App</Link>
                                    </li> */}
                                </ul>
                            </div>
                            <ul>
                                <li className={splitLocation[1] === "get-directions" ? "active" : ""}>
                                    <Link to="/get-directions">Get Directions</Link>
                                </li>
                                <li className={splitLocation[1] === "mountain-walk" ? "active" : ""}>
                                    <Link to="/mountain-walk">Mountain Walks</Link>
                                </li>
                                <li className={splitLocation[1] === "drives" ? "active" : ""}>
                                    <Link to="/drives">Drives</Link>
                                </li>
                                <li className={splitLocation[1] === "mountain-bike" ? "active" : ""}>
                                    <Link to="/mountain-bike">Mountain Bike</Link>
                                </li>
                                <li className={splitLocation[1] === "wild-flower" ? "active" : ""}>
                                    <Link to="/wild-flower">Wildflowers</Link>
                                </li>
                            </ul>

                        </nav>
                        <Link className="download-btn-wrapper" to="/download-app" onClick={installApp}>
                            <button className="downlload-btn">Download App</button>
                        </Link>
                    </div>
                    <div className="resposive-menu">
                        <Menu right isOpen={menuOpen} onOpen={() => setMenuOpen(true)}
                            onClose={() => setMenuOpen(false)}
                            burgerButtonClassName={`${menuOpen ? 'd-none' : ""}`}
                            customOnKeyDown={closeAllMenusOnEsc} pageWrapId={'page-wrap'}
                            customBurgerIcon={<div className="three-menu" onClick={() => setMenuOpen(true)}></div>}
                            customCrossIcon={
                                <img src={close_menu_icon} alt="close" width="20" height="20" />
                            }>

                            <ul className="top-mobile-menu-item-ul">
                                <li className={splitLocation[1] === "get-directions" ? "active" : ""}>
                                    <img src={direction_menu_icon} alt="menu-icon" />
                                    <Link to="/get-directions" onClick={() => setMenuOpen(false)}>Get Directions</Link>
                                </li>
                                <li className={splitLocation[1] === "mountain-walk" ? "active" : ""}>
                                    <img src={mountain_walk_menu_icon} alt="menu-icon" />
                                    <Link to="/mountain-walk" onClick={() => setMenuOpen(false)}>Mountain Walks</Link>
                                </li>
                                <li className={splitLocation[1] === "drives" ? "active" : ""}>
                                    <img src={drives_menu_icon} alt="menu-icon" />
                                    <Link to="/drives" onClick={() => setMenuOpen(false)}>Drives</Link>
                                </li>
                                <li className={splitLocation[1] === "mountain-bike" ? "active" : ""}>
                                    <img src={mountain_bike_menu_icon} alt="menu-icon" />
                                    <Link to="/mountain-bike" onClick={() => setMenuOpen(false)}>Mountain Bike</Link>
                                </li>
                                <li className={splitLocation[1] === "wild-flower" ? "active" : ""}>
                                    <img src={wild_flower_menu_icon} alt="menu-icon" />
                                    <Link to="/wild-flower" onClick={() => setMenuOpen(false)}>Wildflowers</Link>
                                </li>
                            </ul>
                            <hr className="mobile-menu-hr" />
                            <div className="header-top mobile-menu-item-wrap">
                                <ul className="bottom-mobile-menu-item-ul">
                                    <li className={splitLocation[1] === "about" ? "active" : ""}>
                                        <img src={about_menu_icon} alt="menu-icon" />
                                        <Link to="/about" onClick={() => setMenuOpen(false)}>About Stirling Range</Link>
                                    </li>
                                    <li className={splitLocation[1] === "fuel-food" ? "active" : ""}>
                                        <img src={food_fuel_menu_icon} alt="menu-icon" />
                                        <Link to="/fuel-food" onClick={() => setMenuOpen(false)}>Food & Fuel</Link>
                                    </li>
                                    <li className={splitLocation[1] === "accommodation" ? "active" : ""}>
                                        <img src={accommodation_menu_icon} alt="menu-icon" />
                                        <Link to="/accommodation" onClick={() => setMenuOpen(false)}>Accommodation</Link>
                                    </li>
                                    <li className={splitLocation[1] === "privacy-policy" ? "active" : ""}>
                                        <img src={privacy_menu_icon} alt="menu-icon" />
                                        <Link to="/privacy-policy" onClick={() => setMenuOpen(false)}>Privacy Policy & Disclaimers</Link>
                                    </li>
                                    <li>
                                        <img src={install_menu_icon} alt="menu-icon" />
                                        <Link to="/download-app" onClick={installApp}>Install the app</Link>
                                    </li>
                                </ul>
                            </div>
                        </Menu>
                    </div>
                </div>
            </div >
        </header >
    )
}

export default Header;