import React, { Suspense, useEffect } from 'react';
import RouteLinks from './routes';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { FullPageLoader } from '../components/Loader';
import Header from '../components/Header';
import Footer from '../components/Footer';

const RouteConsumer = ({ children }: any) => {
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (children);
}

const Routes = () => {

  return (
    <Router >
      <Suspense fallback={<FullPageLoader />}>

        <Header /> {/* common header*/}

        <Switch>
          {/* iterate over routes [start]*/}
          {RouteLinks.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
            >
              <RouteConsumer>
                <route.component />
              </RouteConsumer>
            </Route>
          ))}
          {/* iterate over routes [end]*/}
        </Switch>

        <Footer /> {/* common Footer */}

      </Suspense>
    </Router>
  );
}

export default Routes;