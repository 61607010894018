import React from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { grade_image, image_placehoder } from "../../utils/images";
import parse from "html-react-parser";
import ImageView from "../Image";
import { getTripType, trailData } from '../../types/trails';
import { getDevicetype } from "../../utils/helper-functions";
import { SetSingleTrailData } from '../../store/actionCreators/trailsActionCreators';

export interface MountainWalkCardProps {
  id: number | null;
  title: string;
  description: string;
  image: string;
  distance: string;
  time: string;
  elevation: string;
  grade?: string;
  link: string;
  sealed_unsealed?: number;
  type: "WALK" | "BIKE" | "DRIVE";
  trip_type: number;
  road_condition?: string;
  item: trailData;
  alt: string | null;
  image_description: string | null;
  detailedMapDownloaded: boolean;
}

function MountainWalkCard(props: MountainWalkCardProps) {

  const history = useHistory();
  const dispatch = useDispatch();

  const getTitle = () => {
    const splits = props.title.split("(");
    const title = splits[0].replace(/drive/i, '');
    return title.trim().toLowerCase().replaceAll(" ", "-")
  }

  const showDetails = () => {
    dispatch(SetSingleTrailData(props.item));
    setTimeout(() => {
      history.push(`${props.link}/${getTitle()}`)
    }, 120);
  }

  return (
    <div
      className="item-info-list-col cursor-pointer"
      onClick={showDetails}
    >
      <div className="item-info-list-col-full">
        <div className="item-info-list-col-img">
          <ImageView
            url_src={props.image}
            placeholder={image_placehoder}
            className="explore-image"
            height="225"
            styles={{ width: "100%", objectFit: "cover" }}
            alt={props.alt}
            description={props.image_description}
          />
        </div>
        <div className="item-info-list-col-title">{props.title}</div>
        <div className="d-flex flex-column trail-desc-grade">
          <div className="item-info-list-col-text">
            {parse(props.description || "")}
          </div>
          <div className={`item-info-list-col-data ${props.type === "DRIVE" ? "drive-item-info-list-col-data" : ""}`}>
            <ul>
              <li className={getDevicetype() === 'mobile' ? "less-line-height" : ""}>
                <span>{props.distance}km</span> Length
                {getDevicetype() === 'mobile' ? <br /> : <>&nbsp;</>}
                {getTripType(props.trip_type, props.type).trim()}
              </li>
              <li>
                <span>{props.time}</span> Time
              </li>
              {props.type !== "DRIVE" &&
                <li>
                  <span>{props.elevation}m</span> Elevation
                </li>
              }
              {props.type === "DRIVE" ?
                <li>
                  <span>
                    {props?.sealed_unsealed === 0 ? "Unsealed" : props?.sealed_unsealed === 1 ? "Sealed" : "Mixed"}
                  </span>{" "}
                  Road
                </li>
                :
                <li>
                  <span>
                    <img src={grade_image} alt="grade" />
                  </span>{" "}
                  Grade {props.grade}
                </li>}
            </ul>
            {props.type === "DRIVE" && props.road_condition &&
              <div className="road-condition-container text-center">
                {props.road_condition}
              </div>
            }
          </div>
        </div>
        {window.matchMedia('(display-mode: standalone)').matches &&
          (props.detailedMapDownloaded ?
            <div className="d-flex justify-content-center mt-3">
                <span className="align-self-center mt-1 available-offline-text">Available Offline</span>
                <div className="led-green align-self-center"></div>
            </div>
          :
          <div className="mt-3">
            <div className="d-flex justify-content-center">
                <span className="align-self-center mt-1 available-offline-text">Not Available Offline</span>
                <div className="led-red align-self-center"></div>
            </div>
            <div className="d-flex justify-content-center">
                <span className="text-center available-offline-text-muted">Click to make available offline</span>
            </div>
          </div>
          )
        }
      </div>
    </div>
  );
}

export default MountainWalkCard;
