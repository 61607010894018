import React, { useCallback, useEffect, useState } from "react";
import { SimpleCard } from "../components/Cards";
import { Link } from "react-router-dom";
import {
    android_icon,
    apple_icon,
    bed_image,
    bluffknoll_map_image,
    direction_icon,
    flower_icon,
    food_icon,
    home_banner_image,
    info_image,
    install_android_app_image_desktop,
    install_ios_app_image_desktop,
    install_android_app_image_mobile,
    install_ios_app_image_mobile,
    shoes_icon,
    trail_map,
    van_image,
    arrow_down,
    food_fuel_banner_image,
    room_image,
    bicycle_icon,
    instagramIcon,
    facebookIcon,
} from "../utils/images";
import { RootState } from "../store";
import { PAGE_TYPES, pageTypesArray } from "../store/actionTypes/pageActionTypes";
import { getBulkPageData, getDownloadData, getPageData } from "../store/actionCreators/pageActionCreators";
import { connect, useSelector } from "react-redux";
import { FullPageLoader } from "../components/Loader";
import { FullPageMapLoader } from "../components/MapLoader";
import { getHomePageBestMoments, getTrails } from "../store/actionCreators/trailsActionCreators";
import { HomePageBestMoments } from "../types/HomeType";
import { Spinner } from "react-bootstrap";
import parse from "html-react-parser";
import { Helmet } from 'react-helmet';
import Colors from "../utils/colors";
import { getDevicetype } from "../utils/helper-functions";
import VideoCover from "react-video-cover";
import BannerVideo from "../assets/videos/home-banner.mp4";
import ImageView from "../components/Image";
import constants from "../utils/globalConstants";
import { isAndroid } from "../hooks/useAndroidInstallPrompt";
import { isIOS } from "../hooks/useIosInstallPrompt";
import { TRAILS_TYPES } from "../store/actionTypes/trailsActionTypes";

const Home = (props: any) => {
    const { bestMoments, loading, pageLoading, pageData, dispatch, needBulkLoad, loadWalkData, loadDriveData, trailsLoaded, mapsLoaded, mapsProgress } = props;
    const { pageContentCheckList, pageDataCheckList, deviceInfoLoaded } = useSelector((state: RootState) => {
        return {
            pageContentCheckList: state.pages.pageContentCheckList,
            deviceInfoLoaded: state.pages.deviceInfoLoaded,
            pageDataCheckList: [state.trails.accommodationLoaded,
            state.trails.aboutImagesLoaded,
            state.trails.foodDataLoaded,
            state.trails.fuelDataLoaded,
            state.trails.walkDataLoaded,
            state.trails.driveDataLoaded,
            state.trails.wildFlowerLoaded,]
        }
    });
    const [activeInstallButton, setActiveInstallButton] = useState("ios");
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
    const [deviceType, setDeviceType] = useState<string>(getDevicetype());
    const [page, setPage] = useState(1);

    let videoRef: HTMLVideoElement | null = null;

    useEffect(() => {
        if (navigator.onLine) {
            dispatch(getHomePageBestMoments(page));
        }
    }, [dispatch, page]);

    useEffect(() => {
        // if (window.matchMedia('(display-mode: standalone)').matches)

        console.log('navigator.onLine', navigator.onLine)
        console.log('is standalone', window.matchMedia('(display-mode: standalone)').matches)

        if (navigator.onLine && window.matchMedia('(display-mode: standalone)').matches) {
            try {
                if (!deviceInfoLoaded) {
                    dispatch(getDownloadData('ios'));
                    dispatch(getDownloadData('android'));
                }
                const pagesToDownload = pageTypesArray.filter(page => !Object.keys(pageContentCheckList).includes(page));
                pagesToDownload.forEach((page) => {
                    dispatch(getPageData(page));
                });
                if (needBulkLoad) {
                    dispatch(getBulkPageData())
                }

                if (loadDriveData) {
                    dispatch(getTrails(page, TRAILS_TYPES.DRIVES_PAGE, 6));
                }
                if (loadWalkData) {
                    dispatch(getTrails(page, TRAILS_TYPES.MOUNTAIN_WALK_PAGE, 0));
                }
            } catch (err) {
                console.log("Failed to fetch initial data")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetDeviceType = () => {
        setDeviceType(getDevicetype());
    };
    useEffect(() => {
        window.addEventListener("resize", resetDeviceType);
        if (isIOS()) {
            setActiveInstallButton("ios");
        }
        if (isAndroid()) {
            setActiveInstallButton("android");
        }
        return () => {
            window.addEventListener("resize", resetDeviceType);
        };
    }, [deviceType]);



    const pageContentPer = Object.keys(pageContentCheckList).filter(el => pageContentCheckList[el] === 1).length * 5 / 2;
    const pageDataPer = pageDataCheckList.filter(el => el).length * 6
    const now = pageContentPer + pageDataPer + (deviceInfoLoaded ? 28 : 0);



    const renderBestMoments = useCallback(() => {
        return bestMoments.map((el: HomePageBestMoments, i: number) => {
            return (
                <div className="home-captured-moments-item" key={el.id + "moments" + i}>
                    <ImageView url_src={el.image} placeholder={""} alt="moment" description="" />
                </div>
            );
        });
    }, [bestMoments]);

    const videoLoadedSuccessfully = () => {
        setIsVideoLoaded(true);
    };

    useEffect(() => {
        if (videoRef) {
            videoRef.addEventListener("loadeddata", videoLoadedSuccessfully);
        }
        return () => {
            if (videoRef) {
                videoRef.removeEventListener("loadeddata", videoLoadedSuccessfully);
            }
        };
    }, [videoRef]);

    const videoOptions = {
        src: BannerVideo,
        autoPlay: true,
        muted: true,
        loop: true,
        playsInline: true,
        ref: (ref: HTMLVideoElement) => (videoRef = ref),
    };

    // show loader when page data is being loaded
    if (window.matchMedia('(display-mode: standalone)').matches && now < 100) {
        return <FullPageLoader now={now} />;
    }
    else if (pageLoading || !pageData) {
        return <FullPageLoader />;
    } 
    else if (window.matchMedia('(display-mode: standalone)').matches && !mapsLoaded) {
      return <FullPageMapLoader trailsLoaded={trailsLoaded} title='Downloading maps' now={parseInt(mapsProgress)} />;
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au" />
                <meta name="description" content={pageData?.meta_description} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="home-hero">
                {deviceType === "desktop" ? (
                    <>
                        <VideoCover
                            videoOptions={videoOptions}
                            remeasureOnWindowResize
                            className={`${!isVideoLoaded ? "d-none" : ""}`}
                        />
                        <img
                            src={home_banner_image}
                            loading="lazy"
                            className={`${isVideoLoaded ? "d-none" : ""}`}
                            alt={pageData?.banner_image_alt_content || ""}
                        />
                    </>
                ) : (
                    <>
                        <ImageView
                            url_src={pageData?.banner_image}
                            placeholder={home_banner_image}
                            alt={pageData?.banner_image_alt_content}
                            description={pageData?.banner_image_description}
                        />
                        <label className="banner-text">
                            {pageData?.banner_content || "Explore"}
                        </label>
                        <a className="arrow-hero" href="#home-second-section">
                            <img src={arrow_down} alt="arrow_down" />
                        </a>
                    </>
                )}
            </section>
            <section className="home-second-section" id="home-second-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="home-second-content ">
                                {parse(pageData?.banner_short_description || "")}
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div
                                className={`home-second-content home-banner-full-description ${!showFullDescription
                                    ? "home-second-content-large"
                                    : "home-full-description"
                                    }`}
                            >
                                {parse(pageData?.banner_full_description || "")}
                            </div>
                            <button
                                onClick={() => setShowFullDescription(!showFullDescription)}
                                className="readmore-text white"
                            >
                                {showFullDescription ? "Show less" : "Read more"}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home-third-section">
                <div className="container">
                    <div className="home-third-box">
                        <div className="home-third-img-mob">
                            <ImageView
                                url_src={pageData?.get_direction_image}
                                placeholder={bluffknoll_map_image}
                                alt={pageData?.get_direction_image_alt_content}
                                description={pageData?.get_direction_image_description}
                            />
                        </div>
                        <div className="home-third-content">
                            <div className="section-title">
                                <h2>Get Directions</h2>
                            </div>
                            <div className="section-content-box">
                                {parse(pageData?.get_direction_content || "")}

                                <br />
                                {/* <button className="readmore-text black">Read more</button> */}
                            </div>
                            <Link to="/get-directions">
                                <button className="round-icon-btn">
                                    <span>
                                        <img src={direction_icon} alt="direction icon" />
                                    </span>
                                    Getting Here
                                </button>
                            </Link>
                        </div>
                        <div className="home-third-img">
                            <ImageView
                                url_src={pageData?.get_direction_image}
                                placeholder={trail_map}
                                alt={pageData?.get_direction_image_alt_content}
                                description={pageData?.get_direction_image_description}
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-explore-range-section">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            Explore Stirling
                            <br /> Range Trails
                        </h2>
                    </div>
                    {/* <div className="section-content-box">
                        By car, the Stirling Ranges are 4.5 hours south east of Perth and just one hour's drive north from Albany in Western Australia.
                    </div> */}
                    <div className="home-explore-range-box">
                        <SimpleCard
                            icon={shoes_icon}
                            name="Mountain Walks"
                            link="/mountain-walk"
                            image={pageData?.mountain_walk_image || ""}
                            additionalClass="mobile-single-column"
                            alt={pageData?.mountain_walk_image_alt_content}
                            description={pageData?.mountain_walk_image_description}
                        />
                        <SimpleCard
                            icon={van_image}
                            name="Drives"
                            link="/drives"
                            image={pageData?.drives_image || ""}
                            additionalClass="mobile-single-column"
                            alt={pageData?.drives_image_alt_content}
                            description={pageData?.drives_image_description}
                        />
                        <SimpleCard
                            icon={bicycle_icon}
                            name="Mountain Bike"
                            link="/mountain-bike"
                            image={pageData?.mountain_bike_trails_image || ""}
                            additionalClass="mobile-single-column"
                            alt={pageData?.mountain_bike_trails_image_alt_content}
                            description={pageData?.mountain_bike_trails_image_description}
                        />
                        <SimpleCard
                            icon={flower_icon}
                            name="Wildflowers"
                            link="/wild-flower"
                            image={pageData?.wildflower_image || ""}
                            additionalClass="mobile-single-column"
                            alt={pageData?.wildflower_image_alt_content}
                            description={pageData?.wildflower_image_description}
                        />
                    </div>
                </div>
            </section>

            <section className="home-app-install-section" id="install">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="home-app-install-img">
                                <img
                                    src={
                                        activeInstallButton === "ios"
                                            ? install_ios_app_image_desktop
                                            : install_android_app_image_desktop
                                    }
                                    alt="install app"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>How to install this app onto your phone</h2>
                            </div>
                            <div className="section-content-box">
                                {parse(pageData?.installation_content || "")}
                            </div>
                            <div className="home-app-install-img-mob">
                                <img
                                    src={
                                        activeInstallButton === "ios"
                                            ? install_ios_app_image_mobile
                                            : install_android_app_image_mobile
                                    }
                                    alt="install app"
                                />
                            </div>
                            <div className="home-app-install-btn">
                                <button
                                    className={`${activeInstallButton === "ios" ? "active" : ""} mr-lg-2`}
                                    onClick={() => setActiveInstallButton("ios")}
                                >
                                    <div className="install-btn-container">
                                        <span>
                                            <img src={apple_icon} alt="apple icon" width="28" height="28" />
                                        </span><label>Apple</label>
                                    </div>
                                </button>
                                {/* <span className="gap">&nbsp;</span> */}
                                <button
                                    className={`${activeInstallButton === "android" ? "active" : ""
                                        }`}
                                    onClick={() => setActiveInstallButton("android")}
                                >
                                    <div className="install-btn-container">
                                        <span>
                                            <img src={android_icon} alt="android icon" width="28" height="28" />
                                        </span> <label>Android</label>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="home-plan-visit-section">
                <div className="container">
                    <div className="section-title mb-3">
                        <h2>Plan your visit</h2>
                    </div>
                    <div className="home-plan-visit-box">
                        <SimpleCard
                            icon={info_image}
                            name="About Stirling Ranges"
                            link="/about"
                            image={pageData?.about_image?.banner_image || ""}
                            additionalClass="reszied-card"
                            alt=""
                            description=""
                        />
                        <SimpleCard
                            icon={food_icon}
                            name="Food & Fuel"
                            link="/fuel-food"
                            image={
                                pageData?.fuel_food_image?.banner_image ||
                                food_fuel_banner_image
                            }
                            additionalClass="reszied-card"
                            alt=""
                            description=""
                        />
                        <SimpleCard
                            icon={bed_image}
                            name="Accommodation"
                            link="/accommodation"
                            image={
                                pageData?.accommodationPage_image?.banner_image || room_image
                            }
                            additionalClass="reszied-card"
                            alt=""
                            description=""
                        />
                        {/* <SimpleCard
                            icon={privacy_icon}
                            name="Privacy Policy & Disclaimers"
                            link="/privacy-policy"
                            image={pageData?.privacy_disclaimer_image?.banner_image || ""}
                        /> */}
                    </div>

                    {/* <div className="home-plan-visit-content">
                        {parse(pageData?.plan_your_visit_content || "")}
                    </div> */}
                </div>
            </section>

            <section className="home-get-social-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>Get Social</h2>
                            </div>
                            <div className="section-content-box">
                                {parse(pageData?.get_social_content || "")}
                                <br />
                                {/* <button className="readmore-text black">Read more</button> */}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="home-get-social-img">
                                <ImageView
                                    url_src={pageData?.get_social_image}
                                    alt={pageData?.get_social_image_alt_content}
                                    description={pageData?.get_social_image_description}
                                />
                                <div className="home-get-social-box">
                                    <div className="home-get-social-btn">
                                        <h3>Tag Us</h3>
                                        <a
                                            href={constants.FACEBOOK_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={facebookIcon}
                                                alt=""
                                                width="42"
                                                height="42"
                                                className="social-icon"
                                            />
                                        </a>
                                        <a
                                            href={constants.INSTAGRAM_URL}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={instagramIcon}
                                                alt=""
                                                width="42"
                                                height="42"
                                                className="social-icon"
                                            />
                                        </a>
                                    </div>
                                    <div className="home-get-social-tag">
                                        @StirlingRangeTrails
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-captured-moments-section">
                <div className="container">
                    <div className="section-title">
                        <h2>
                            Best moments <br />
                            captured on
                            <br />
                            camera
                        </h2>
                    </div>
                    <div className="home-captured-moments-box">
                        {bestMoments && renderBestMoments()}
                    </div>
                    {loading && bestMoments?.length <= (page - 1) * 4 && (
                        <div className="d-flex justify-content-center w-100">
                            <Spinner animation="border" style={{ color: Colors.primary }} />
                        </div>
                    )}

                    {!loading && !bestMoments && (
                        <div className="no-result-box">
                            <span>No results</span>
                        </div>
                    )}
                    {bestMoments?.length >= page * 4 && (
                        <div className="d-flex justify-content-center load-more-text-wrapper">
                            <span onClick={() => setPage(page + 1)}>Load more</span>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};

function mapStateToProps(state: RootState) {
    const { pages, trails, } = state;
    return {
        bestMoments: trails.bestMoments,
        loading: trails.loading,
        pageData: pages.homePageData,
        pageLoading: pages.pageLoading,
        pageContentCheckList: pages.pageContentCheckList,
        needBulkLoad: !trails.accommodationLoaded ||
            !trails.aboutImagesLoaded ||
            !trails.foodDataLoaded ||
            !trails.fuelDataLoaded ||
            !trails.wildFlowerLoaded,
        loadWalkData: !trails.walkDataLoaded,
        loadDriveData: !trails.driveDataLoaded,
        trailsLoaded: trails.walkDataLoaded && trails.driveDataLoaded,
        mapsLoaded: trails.mapsLoaded,
        mapsProgress: trails.mapsProgress,
    };
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine) {
        dispatch(getPageData(PAGE_TYPES.HOME_PAGE));
        // 
        // dispatch(getBulkPageData())
    }
    return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
