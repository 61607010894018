import { BulkPageContent } from "../../types/bulkPageContent";
export const pageTypesArray = [
  "HOME_PAGE",
  "FOOD_FUEL_PAGE",
  "ABOUTUS_PAGE",
  "DIRECTION_PAGE",
  "PRIVACY_PAGE",
  "TERMS_AND_CONDITIONS",
  "ACCOMMODATION_PAGE",
  "WILD_FLOWER_PAGE",
  "MOUNTAIN_WALK_PAGE",
  "MOUNTAIN_BIKE_PAGE",
  "DRIVES_PAGE",
  "DOWNLOAD_APP_PAGE",
];
export const PAGE_TYPES = {
  HOME_PAGE: "HOME_PAGE",
  FOOD_FUEL_PAGE: "FOOD_FUEL_PAGE",
  ABOUTUS_PAGE: "ABOUTUS_PAGE",
  DIRECTION_PAGE: "DIRECTION_PAGE",
  PRIVACY_PAGE: "PRIVACY_PAGE",
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  ACCOMMODATION_PAGE: "ACCOMMODATION_PAGE",
  WILD_FLOWER_PAGE: "WILD_FLOWER_PAGE",
  MOUNTAIN_WALK_PAGE: "MOUNTAIN_WALK_PAGE",
  MOUNTAIN_BIKE_PAGE: "MOUNTAIN_BIKE_PAGE",
  DRIVES_PAGE: "DRIVES_PAGE",
  DOWNLOAD_APP_PAGE: "DOWNLOAD_APP_PAGE",
};

export const SET_PAGE_DATA_LOADING =
  "pageDataActionTypes/SET_PAGE_DATA_LOADING";
export interface SetPageDataLoadingAction {
  type: typeof SET_PAGE_DATA_LOADING;
  loading: boolean;
}

export const SET_DOWNLOAD_DATA_LOADING =
  "pageDataActionTypes/SET_DOWNLOAD_DATA_LOADING";
export interface SetDownloadDataLoadingAction {
  type: typeof SET_DOWNLOAD_DATA_LOADING;
  loading: boolean;
}

export const SET_PAGE_DATA = "pageDataActionTypes/SET_PAGE_DATA";
export interface SetPageDataAction {
  type: typeof SET_PAGE_DATA;
  data: string | [];
  pageName: string;
}

export const GET_PAGE_DATA = "pageDataActionTypes/GET_PAGE_DATA";
export interface GetPageDataAction {
  type: typeof GET_PAGE_DATA;
  pageName: string;
}

export const SET_DOWNLOAD_DATA = "pageDataActionTypes/SET_DOWNLOAD_DATA";
export interface SetDownloadDataAction {
  type: typeof SET_DOWNLOAD_DATA;
  data: string | [];
  device: string;
}

export const GET_DOWNLOAD_DATA = "pageDataActionTypes/GET_DOWNLOAD_DATA";
export interface GetDownloadDataAction {
  type: typeof GET_DOWNLOAD_DATA;
  device: string;
}

export const GET_PAGE_DATA_SUCCESS =
  "pageDataActionTypes/GET_PAGE_DATA_SUCCESS";
export interface GetPageDataSuccessAction {
  type: typeof GET_PAGE_DATA_SUCCESS;
  data: string | [];
  pageName: string;
}

export const GET_PAGE_DATA_FAILURE =
  "pageDataActionTypes/GET_PAGE_DATA_FAILURE";
export interface GetPageDataFailureAction {
  type: typeof GET_PAGE_DATA_FAILURE;
  error: Error | string;
}

export const GET_DOWNLOAD_DATA_SUCCESS =
  "pageDataActionTypes/GET_DOWNLOAD_DATA_SUCCESS";
export interface GetDownloadDataSuccessAction {
  type: typeof GET_DOWNLOAD_DATA_SUCCESS;
  data: string | [];
  device: string;
}

export const GET_DOWNLOAD_DATA_FAILURE =
  "pageDataActionTypes/GET_DOWNLOAD_DATA_FAILURE";
export interface GetDownloadDataFailureAction {
  type: typeof GET_DOWNLOAD_DATA_FAILURE;
  error: Error | string;
}

export const SET_BULK_PAGE_DATA = "pageDataActionTypes/SET_BULK_PAGE_DATA";
export interface SetBulkPageDataAction {
  type: typeof SET_BULK_PAGE_DATA;
  data: BulkPageContent;
}

export const GET_BULK_PAGE_DATA = "pageDataActionTypes/GET_BULK_PAGE_DATA";
export interface GetBulkPageDataAction {
  type: typeof GET_BULK_PAGE_DATA;
}

export type PageDataAction =
  | SetPageDataAction
  | GetPageDataAction
  | SetBulkPageDataAction
  | GetBulkPageDataAction
  | SetDownloadDataAction
  | GetDownloadDataAction
  | GetPageDataSuccessAction
  | GetPageDataFailureAction
  | SetPageDataLoadingAction
  | SetDownloadDataLoadingAction
  | GetDownloadDataSuccessAction
  | GetDownloadDataFailureAction;
