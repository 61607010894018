import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers";
import rootSaga from "./sagas/rootSaga";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "pwa-v1.5",
  storage,
};

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({});

const middleware: any = [sagaMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === "development"
    ? composeEnhancers(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

sagaMiddleware.run(rootSaga);

export { store };

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
