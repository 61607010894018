import { aboutUsImage } from "../../types/aboutUsType";
import { accommodationService } from "../../types/accommodationType";
import {
  foodFuelFoodData,
  foodFuelFuelData,
  foodFuelServicesResponse,
} from "../../types/foodFuelType";
import { HomePageBestMomentsResponse } from "../../types/HomeType";
import { PoinPoints, trailData } from "../../types/trails";
import { wildFlowerData } from "../../types/wildFlowers";
import * as actions from "../actionTypes/trailsActionTypes";

export function SetTrailsLoading(
  loading: boolean
): actions.SetTrailsLoadingAction {
  return {
    type: actions.SET_TRAILS_LOADING,
    loading,
  };
}

export function SetSingleTrailData(
  data: trailData
): actions.SetSingleTrailDataAction {
  return {
    type: actions.SET_SINGLE_TRAIL_DATA,
    data,
  };
}

export function setTrails(
  trails: trailData[],
  trail_type: number,
  page: number,
  filter: number
): actions.SetTrailsAction {
  return {
    type: actions.SET_TRAILS,
    data: trails,
    trail_type,
    page,
    filter,
  };
}

export function getTrails(
  page: number,
  trail_type: number,
  filter: number
): actions.GetTrailsAction {
  return {
    type: actions.GET_TRAILS,
    page,
    trail_type,
    filter,
  };
}

export function getTrailsRequest(): actions.GetTrailsRequestAction {
  return {
    type: actions.GET_TRAILS_REQUEST,
  };
}

export function getTrailsSuccess(
  trails: trailData[],
  trail_type: number,
  page: number,
  filter: number
): actions.GetTrailsSuccessAction {
  return {
    type: actions.GET_TRAILS_SUCCESS,
    data: trails,
    trail_type,
    page,
    filter,
  };
}

export function getTrailsFailure(
  error: Error | string
): actions.GetTrailsFailureAction {
  return {
    type: actions.GET_TRAILS_FAILURE,
    error,
  };
}

export function getWildFlowers(page: number): actions.GetWildFlowerAction {
  return {
    type: actions.GET_WILD_FLOWERS,
    page,
  };
}

export function getWildFlowerSuccess(
  data: wildFlowerData[],
  page: number
): actions.GetWildFlowerSuccessAction {
  return {
    type: actions.GET_WILD_FLOWER_SUCCESS,
    data: data,
    page: page,
  };
}

export function getWildFlowerFailure(
  error: Error | string
): actions.GetWildFlowerFailureAction {
  return {
    type: actions.GET_WILD_FLOWER_FAILURE,
    error,
  };
}
//-----------------------------------------------------------------

export function getAboutPageImages(
  page: number
): actions.GetAboutPageImagesAction {
  return {
    type: actions.GET_ABOUT_PAGE_IMAGES,
    page,
  };
}

export function getAboutPageImagesSuccess(
  data: aboutUsImage[] | string
): actions.GetAboutPageImagesSuccessAction {
  return {
    type: actions.GET_ABOUT_PAGE_IMAGES_SUCCESS,
    data: data,
  };
}

export function getAboutPageImagesFailure(
  error: Error | string
): actions.GetAboutPageImagesFailureAction {
  return {
    type: actions.GET_ABOUT_PAGE_IMAGES_FAILURE,
    error,
  };
}

//----------------------------------------------------------------------
export function getAccommodationServices(
  page: number,
  accommodation_title_id: number
): actions.GetAccommodationServicesAction {
  return {
    type: actions.GET_ACCOMMODATION_SERVICES,
    page,
    accommodation_title_id,
  };
}

export function SetSingleAccommodationService(
  data: accommodationService,
  page: number,
  accommodation_title_id: number
): actions.SetSingleAccommodationServiceAction {
  return {
    type: actions.SET_SINGLE_ACCOMMODATION_SERVICE,
    data: data,
    page: page,
    accommodation_title_id,
  };
}

export function getAccommodationServicesSuccess(
  data: accommodationService[],
  page: number,
  accommodation_title_id: number
): actions.GetAccommodationServicesSuccessAction {
  return {
    type: actions.GET_ACCOMMODATION_SERVICES_SUCCESS,
    data: data,
    page: page,
    accommodation_title_id,
  };
}

export function getAccommodationServicesFailure(
  error: Error | string
): actions.GetAccommodationServicesFailureAction {
  return {
    type: actions.GET_ACCOMMODATION_SERVICES_FAILURE,
    error,
  };
}
//----------------------------------------------------------------------
export function getFuelData(
  page: number,
  fuel_and_food_title_id: number
): actions.GetFuelDataAction {
  return {
    type: actions.GET_FUEL_DATA,
    page,
    fuel_and_food_title_id,
  };
}

export function setSingleFuelData(
  data: foodFuelFuelData,
  page: number,
  fuel_and_food_title_id: number
): actions.SetSingleFuelDataAction {
  return {
    type: actions.SET_SINGLE_FUEL_DATA,
    data: data,
    page: page,
    fuel_and_food_title_id,
  };
}

export function getFoodData(
  page: number,
  fuel_and_food_title_id: number
): actions.GetFoodDataAction {
  return {
    type: actions.GET_FOOD_DATA,
    page,
    fuel_and_food_title_id,
  };
}

export function setSingleFoodData(
  data: foodFuelFoodData,
  page: number,
  fuel_and_food_title_id: number
): actions.SetSingleFoodDataAction {
  return {
    type: actions.SET_SINGLE_FOOD_DATA,
    data: data,
    page: page,
    fuel_and_food_title_id,
  };
}

export function getFoodDataSuccess(
  data: foodFuelServicesResponse,
  page: number,
  fuel_and_food_title_id: number
): actions.GetFoodDataSuccessAction {
  return {
    type: actions.GET_FOOD_DATA_SUCCESS,
    data: data,
    page: page,
    fuel_and_food_title_id,
  };
}

export function getFuelDataSuccess(
  data: foodFuelServicesResponse,
  page: number,
  fuel_and_food_title_id: number
): actions.GetFuelDataSuccessAction {
  return {
    type: actions.GET_FUEL_DATA_SUCCESS,
    data: data,
    page: page,
    fuel_and_food_title_id,
  };
}

export function getFoodDataFailure(
  error: Error | string
): actions.GetFoodDataFailureAction {
  return {
    type: actions.GET_FOOD_DATA_FAILURE,
    error,
  };
}
export function getFuelDataFailure(
  error: Error | string
): actions.GetFuelDataFailureAction {
  return {
    type: actions.GET_FUEL_DATA_FAILURE,
    error,
  };
}
//----------------------------------------------------------------------
export function getHomePageBestMoments(
  page: number
): actions.GetHomePageBestMomentsAction {
  return {
    type: actions.GET_HOME_PAGE_BEST_MOMENTS,
    page,
  };
}

export function getHomePageBestMomentsSuccess(
  data: HomePageBestMomentsResponse,
  page: number
): actions.GetHomePageBestMomentsSuccessAction {
  return {
    type: actions.GET_HOME_PAGE_BEST_MOMENTS_SUCCESS,
    data: data,
    page: page,
  };
}

export function getHomePageBestMomentsFailure(
  error: Error | string
): actions.GetHomePageBestMomentsFailureAction {
  return {
    type: actions.GET_HOME_PAGE_BEST_MOMENTS_FAILURE,
    error,
  };
}
//---------------------[Trails map data]-------------------
export function setCurrentTrailsMapData(
  trails_map_data: string | null
): actions.SetCurrentTrailsMapDataAction {
  return {
    type: actions.SET_CURRENT_TRAILS_MAP_DATA,
    data: trails_map_data,
  };
}

//----------------------[Map pinpoints]----------------------------
export function setCurrentMapPinpPointsData(
  pintpoints: PoinPoints[] | null
): actions.SetCurrentMapPinPointsAction {
  return {
    type: actions.SET_CURRENT_MAP_PIN_POINTS,
    pinpoints: pintpoints,
  };
}

// export function setKmlLinkData(
//   data: Array<{ url: string; revision: string }>
// ): actions.SetKMLLinks {
//   return {
//     type: actions.SET_KML_LINKS,
//     data,
//   };
// }
