import React, { useState, useEffect, useCallback } from "react";
import { MapConsumer, MapContainer, TileLayer } from "react-leaflet";
import ReactLeafletKml from "react-leaflet-kml";
import { getCoordinatesFromKMLText, getDevicetype } from "../../utils/helper-functions";
import { useDispatch } from 'react-redux';
import { setCurrentTrailsMapData } from "../../store/actionCreators/trailsActionCreators";
import L, { Map, LatLngExpression } from "leaflet";
import { getKmlDataByUrl, KmlValueSchema, setKmlData } from "../../services/databases";
import { CustomMarkerPopup } from ".";
import { infoMarkerIcon } from "./Map";
import { PoinPoints } from "../../types/trails";
import parse from 'html-react-parser';
import axios from "axios";
import 'leaflet.offline'
import { fetchMapForOffline, calculateMaxOfflineZoom, basicZoomLevelsForWalk, basicZoomLevelsForDrive } from "../../utils/map-functions";

export interface KMLMapProps {
    trailId: number | undefined,
    trails_link: string | undefined,
    pinpoints: PoinPoints[] | null,
    onStartLatitude: ((latitude: any) => any),
    onStartLongitude: ((longitude: any) => any),
    zoomLevel?: number,
    detailedMapDownloaded: boolean,
    trailType: string
}

function KMLMap({ trailId, trails_link, pinpoints, onStartLatitude, onStartLongitude, zoomLevel, detailedMapDownloaded, trailType }: KMLMapProps) {

    const [map, setMap] = useState<Map | undefined>();

    const dispath = useDispatch();

    const [kml, setKml] = useState<Document | null>(null);
    const [kmlText, setKmlText] = useState<string | null>(null);
    const [mapCenter, setMapCenter] = useState<LatLngExpression>([-34.38, 117.96]);
    const [isClicked, setIsClicked] = useState(false);
    const [customZoom, setCustomeZoom] = useState(zoomLevel || 10)

    let [online, isOnline] = useState(navigator.onLine);

    const setOnline = () => {
      console.log('We are online')
      isOnline(true)
    };
    const setOffline = () => {
      console.log('We are offline')
      isOnline(false)
    };

     // Register the event listeners
    useEffect(() => {
      window.addEventListener('offline', setOffline)
      window.addEventListener('online', setOnline)

      // cleanup if we unmount
      return () => {
        window.removeEventListener('offline', setOffline)
        window.removeEventListener('online', setOnline)
      }
    }, [])

    const openPopup = useCallback(() => {
        const elmts = document.getElementsByClassName('leaflet-marker-icon');
        if (elmts.length > 0) {
            setIsClicked(true);
            if (!isClicked) {
                for (let i = 0; i < elmts.length; i++) {
                    const imgRef = elmts.item(i) as HTMLImageElement;
                    if (!imgRef.title) {
                        imgRef.click();
                        return;
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    /**
     * 
     * @param kml_data 
     */
    const getCoordinates = useCallback((kml_data: string | null) => {
        if (kml_data) {
            const { startCoordinates, endCoordinates } = getCoordinatesFromKMLText(kml_data);
            if (startCoordinates.length > 0 && endCoordinates.length > 0) {
                onStartLatitude(parseFloat(startCoordinates[1]));
                onStartLongitude(parseFloat(startCoordinates[0]));
                const lng = (parseFloat(startCoordinates[0]) + parseFloat(endCoordinates[0])) / 2;
                const lat = (parseFloat(startCoordinates[1]) + parseFloat(endCoordinates[1])) / 2;

                setMapCenter([lat, lng]);
                openPopup();
                if (startCoordinates.length && endCoordinates.length) {
                    const s1 = parseFloat(startCoordinates[0]);
                    const s2 = parseFloat(endCoordinates[0]);
                    if (Math.hypot(s1 - s2) < 0.1) {
                        setCustomeZoom(14)
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 
     */
    const setKmlDataForMap = useCallback((kmlText: string, kml_url: string) => {
        dispath(setCurrentTrailsMapData(kml_url));
        getCoordinates(kmlText);
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText.replace(/http:/g, 'https:'), "text/xml");
        setKml(kml);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * 
     */
    const setDefaultTrailData = useCallback(async (trails_link: string) => {
        try {
            const res = await getKmlDataByUrl(trails_link);
            if (res) {
                setKmlDataForMap(res.data, trails_link);
                setKmlText(res.data);
            }
        } catch (err) {
            console.log("Error");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * @Set KMl data with trail_kml url to index DB
     * @param data : string (kml parsed data)
     * @param trail_link : string (kml data url)
     */
    const setKmlDataToIndexDB = useCallback(async (data: string, trails_link: string) => {
        const info = {
            data: data,
            kmlurl: trails_link,
        } as KmlValueSchema;
        await setKmlData(info);
        setDefaultTrailData(trails_link || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * @Get trail kml data from server by url
     * @param trail_link : string (kml data url)
     */
    const getTrailDataFromServer = useCallback(async (trails_link: string) => {
        try {
            const res = await axios.get(trails_link);
            if (res.status === 200 && res.data) {
                setKmlDataForMap(res.data, trails_link);
                setKmlDataToIndexDB(res.data, trails_link);
                return;
            }
            setKmlDataForMap("", trails_link);
            setKmlDataToIndexDB("", trails_link);
        } catch (err) {
            setKmlDataForMap("", trails_link);
            setKmlDataToIndexDB("", trails_link);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /*
    * Watch for trail link and fetch the trail data from server.
    * Then parse it using DOMParser and set the KML parsed document.
    */
    useEffect(() => {
        if (trails_link) {
            const s = trails_link.split('/');
            if (s[s.length - 1].includes('kml')) {
                // if (navigator.onLine) {
                getTrailDataFromServer(trails_link);
                // } else {
                //     setDefaultTrailData(trails_link);
                // }
            } else {
                dispath(setCurrentTrailsMapData(trails_link));
                setKmlDataToIndexDB("", trails_link || "not-available");
            }
        } else {
            dispath(setCurrentTrailsMapData(""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trails_link]);

    useEffect( () => {
      if (map && kmlText) {
        if (online) {
          let minZoomVar =  5
          let maxZoomVar = 18
          console.log('Network Online')
          console.log('minZoomVar: ', minZoomVar)
          console.log('maxZoomVar: ', maxZoomVar)
          map.options.minZoom = minZoomVar
          map.options.maxZoom = maxZoomVar
        } else {
          // we offline
          if(detailedMapDownloaded) { // if we DO have detailed map use this
            calculateMaxOfflineZoom(kmlText, map).then((maxZoomLevel) => {
              console.log('Promise returned, yes we got max zoom level:', maxZoomLevel)
              let minZoomVar = 9
              let maxZoomVar = maxZoomLevel
              console.log('Network Offline - WE HAVE DETAILED MAP')
              console.log('minZoomVar: ', minZoomVar)
              console.log('maxZoomVar: ', maxZoomVar)
              map.options.minZoom = minZoomVar
              map.options.maxZoom = maxZoomVar
            })
          } else {
            // dont have detailed map downloaded, use basic fetch all map zoom levels:
            // if we are DRIVE lock off at 9 min 10 max
            // if we are WALK lock off at 9 min 14 max
            console.log('Network Offline - BASIC MAP ONLY - No detailed map')
            console.log('Trail Type: ', trailType)
            let minZoomVar = trailType === 'drive' ? basicZoomLevelsForDrive[0] : basicZoomLevelsForWalk[0]
            let maxZoomVar = trailType === 'drive' ? basicZoomLevelsForDrive[basicZoomLevelsForDrive.length - 1] : basicZoomLevelsForWalk[basicZoomLevelsForWalk.length - 1]
            console.log('minZoomVar: ', minZoomVar)
            console.log('maxZoomVar: ', maxZoomVar)
            map.options.minZoom = minZoomVar
            map.options.maxZoom = maxZoomVar
          }
        }
      }
    }, [map, kmlText, online, detailedMapDownloaded, zoomLevel, customZoom])

    useEffect(() => {
      if(map && kmlText && trailId){
        console.log('THIS TRAIL ID IS', trailId)
        if (online && !detailedMapDownloaded) {
          fetchMapForOffline(kmlText, map, trailId)
        }
        // @ts-ignore
        const tileLayerOffline = L.tileLayer.offline(
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          }
        );
        tileLayerOffline.addTo(map);
      }
    }, [map, kmlText, trailId, online, detailedMapDownloaded]);


    return (
        <MapContainer
            style={{ height: "100%", width: "100%" }}
            zoom={zoomLevel ? customZoom : getDevicetype() === 'mobile' ? 9 : 10}
            markerZoomAnimation
            zoomControl={false}
            center={[-34.38, 118.1]}
            className="w-100"
            tap={false}
            whenCreated={setMap}
        >
            {pinpoints && pinpoints.map((el, i: number) => (
              
                <CustomMarkerPopup
                    key={'marker-info-' + i}
                    markerIcon={infoMarkerIcon}
                    title={el.title}
                    description={parse(el.description || "")}
                    image={el.image || el.image_link}
                    latitude={el.latitude}
                    longitude={el.longitude}
                />
            ))}
            {kml && <ReactLeafletKml kml={kml} />}
            <MapConsumer>
                {(map) => {
                    map.panTo(mapCenter);
                    map.flyTo(mapCenter, customZoom);
                    return null
                }}
            </MapConsumer>
        </MapContainer>
    );
}

export default KMLMap;