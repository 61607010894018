import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router-dom';
import { bicycle_icon, grade_image, map_direction_image, map_trail_image, mountain_walk_detail_banner_image } from '../utils/images';
import AliceCarousel from 'react-alice-carousel';
import { RootState } from '../store';
import { connect, useDispatch } from 'react-redux';
import { trailData, trail_images } from '../types/trails';
import { fetchSingleTrail } from '../services/apis/trailsServices';
import { FullPageLoader } from '../components/Loader';
import { KMLMap } from '../components/CustomMap';
import parse from 'html-react-parser';
import ImageView from '../components/Image';
import { setCurrentMapPinpPointsData } from '../store/actionCreators/trailsActionCreators';
import { Helmet } from 'react-helmet';
import { getTitle } from '../utils/helper-functions';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

interface RouteParams { id: string, page: string }

const MountainWalkDetail = (props: any) => {

    const { trailsList, detailedMapsArr } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams<RouteParams>();

    const [data, setData] = useState<trailData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [startLatitude, SetStartLatitude] = useState(-34.45);
    const [startLongitude, SetStartLongitude] = useState(118.05);

    /*
    **    function to redirect to previous page or home page
    */
    const redirectPage = () => {
        if (history.length > 0)
            history.goBack();
        else history.replace("/");
    }

    const getTrailData = async () => {
        try {
            const res = await fetchSingleTrail(parseInt(params.id));
            if (res.status === 200 && res.data) {
                if (res.data) {
                    setLoading(false);
                    setData(res.data?.data);
                    dispatch(setCurrentMapPinpPointsData(res.data.data.pin_location))
                    return;
                }
            }
            setLoading(false);
            redirectPage();
        } catch (err) {
            redirectPage();
        }
    }


    useEffect(() => {
        if (params.id) {
            try {
                if (navigator.onLine) {
                    getTrailData();
                    return;
                }
                if (Object.keys(trailsList).length > 0) {
                    const id = parseInt(params.id);
                    for (let key of Object.keys(trailsList)) {
                        for (let page of Object.keys(trailsList[key])) {
                            for (let item of trailsList[key][page]) {
                                if (item.id === id) {
                                    setLoading(false);
                                    setData(item);
                                    dispatch(setCurrentMapPinpPointsData(item.pin_location))
                                    return;
                                }
                            }
                        }
                    }
                }
                setLoading(false);
                redirectPage();
            } catch (err) {
                redirectPage();
            }
        }
        // eslint-disable-next-line 
    }, [params, trailsList]);

    // show loader when page data is being loaded
    if (loading) return (<FullPageLoader />);

    else
        return (
            <div className="main-wrapper">
                <Helmet>
                    <link rel="canonical" href={`https://www.stirlingrangetrails.com.au/mountain-bike/${getTitle(data?.title)}`} />
                    <meta name="description" content={data?.meta_description || ""} />
                    <title>{data?.meta_title || "Stirling Range Trails"}</title>
                </Helmet>
                <section className="inner-hero-section">
                    <ImageView
                        url_src={data?.trail_image || mountain_walk_detail_banner_image}
                        placeholder={mountain_walk_detail_banner_image}
                        alt="banner"
                        description=""
                    />
                </section>
                {data && (
                    <>
                        <section className="inner-second-section">
                            <div className="container">
                                <div className="title-section-head-box">
                                    <div className="title-section-head">
                                        <div className="title-section-head-icon">
                                            <img src={bicycle_icon} alt="shoes" />
                                        </div>
                                        <h1>{data?.title}</h1>
                                        <span>Mountain Bikes</span>
                                    </div>
                                    <div className="item-info-detail-col">
                                        <div className="item-info-detail-col-data">
                                            <ul>
                                                <li>
                                                    <span>{data?.length || "N/A"}km</span> Length
                                                </li>
                                                <li>
                                                    <span>{data?.time || "N/A"}</span> Time
                                                </li>
                                                <li>
                                                    <span>{data?.elevation || "N/A"}m</span> Elevation
                                                </li>
                                                <li>
                                                    <span><img src={grade_image} alt="grade" /></span> {data?.filter || "Grade N/A"}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="title-section-head-content">
                                        <div className="title-section-head-title">
                                            {parse(data?.short_description || "No description available")}
                                        </div>
                                        <div className="title-section-head-text">
                                            {parse(data?.full_description || "No description available")}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>
                        <section className="item-detain-map-section">
                            <div className="container">
                                <div className="section-title text-center">
                                    <h2 className=" text-center">Walk Trail Map</h2>
                                </div>
                                <div className="item-detain-map-view">
                                    <div className="detail-page-map-container">
                                        <KMLMap trails_link={data?.trail_data_link} pinpoints={data?.pin_location || null}
                                            onStartLatitude={(latitude: number) => SetStartLatitude(latitude)}
                                            onStartLongitude={(longitude: number) => SetStartLongitude(longitude)}
                                            trailId={data?.id}
                                            trailType={'bike'}
                                            detailedMapDownloaded={detailedMapsArr.includes(data?.id)}
                                        />
                                    </div>
                                    <div className="map-button-box">
                                        <a href={`https://maps.google.com/maps/dir/?api=1&dir_action=navigate&destination=${startLatitude},${startLongitude}`} target="_blank" rel="noreferrer">
                                            <button className="map-button">
                                                <span>
                                                    <img
                                                        src={map_direction_image}
                                                        alt="map"
                                                    />
                                                </span>
                                                Get Directions
                                            </button>
                                        </a>
                                        <Link to={`https://www.stirlingrangetrails.com.au/live-trail/`}>
                                            <button className="map-button">
                                                <span>
                                                    <img src={map_trail_image} alt="map" />
                                                </span>
                                                Start Trail
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="item-detail-img-section">
                            <div className="container">
                                <div className="section-title text-center">
                                    <h2 className=" text-center">{data?.title} <span>TRAIL PHOTOS</span></h2>
                                </div>
                                <div className="item-detail-img-box">
                                    {data?.trail_images && data?.trail_images.length > 0 && (
                                        <AliceCarousel
                                            mouseTracking
                                            responsive={responsive}
                                            controlsStrategy="responsive"
                                            disableButtonsControls={false}
                                            renderNextButton={(e) => e.isDisabled = false}
                                            disableDotsControls={false}
                                            autoPlay={data?.trail_images.length > 3}
                                            animationDuration={3000}
                                            infinite={data?.trail_images.length > 3}
                                            autoPlayStrategy="action"
                                        >
                                            {data?.trail_images.map((el: trail_images) => (
                                                <div className="mountain-walk-detail-slide-item" key={el.id}>
                                                    <ImageView
                                                        url_src={el.image_link}
                                                        alt="moment"
                                                        description=""
                                                    />
                                                </div>
                                            ))}
                                        </AliceCarousel>
                                    )}
                                </div>
                            </div>
                        </section>
                    </>
                )}
            </div>
        )
}

function mapStateToProps(state: RootState) {
    const { trails } = state;
    return { 
      trailsList: trails.MountainBikeData || {},
      detailedMapsArr: trails.detailedMapsArr
    }
}

export default connect(mapStateToProps)(MountainWalkDetail);