import { AxiosResponse } from "axios";
import { BulkPageContentResponse } from "../../types/bulkPageContent";
import { InstallAppResposne } from "../../types/downloadAppType";
import API, { getPublicKey } from "./index";

const paths = {
  SEND_APP_INSTALL_STATUS: "/user_identifier",
  GET_BULK_PAGE_CONTENT: "/get_pages_content",
};

export async function sendAppInstallStatus(
  id: string
): Promise<InstallAppResposne | AxiosResponse> {
  const data = {
    user_identifier: id,
    private_key: getPublicKey(),
  };
  return await API.post(paths.SEND_APP_INSTALL_STATUS, data);
}

export async function getBulkPageContent(): Promise<
  AxiosResponse<BulkPageContentResponse>
> {
  // const data = {
  //   private_key: getPublicKey(),
  // };
  return await API.get(
    `${paths.GET_BULK_PAGE_CONTENT}?private_key=${getPublicKey()}`
  );
}
