import L, {LatLngExpression, PathOptions, CircleMarkerOptions, CircleMarker } from "leaflet";
import { EventedProps, createLayerComponent } from "@react-leaflet/core";


if (typeof window.exports != "object") {
    //cdn usage on browsers without "exports" variable
    window.exports = {};
}

// needed leaflet type
type LeafletType = {
    Marker: CircleMarker;
    Util: any;
};

declare global {
    interface Window {
        SlideCircle: any;
        exports: Object;
        L: LeafletType;
    }
}

type slideOptions = {
    duration: number;
    keepAtCenter?: boolean;
};

class SlideCircle extends L.CircleMarker {
    private _slideToUntil = 0;
    private _slideToDuration = 1000;
    private _slideToLatLng: LatLngExpression = [0, 0];
    private _slideFromLatLng: LatLngExpression = [0, 0];
    private _slideKeepAtCenter = false;
    private _slideDraggingWasAllowed = false;
    private _slideFrame = 0;

    addInitHook = () => {
        this.on("move", this.slideCancel, this);
    };

    // method slideTo(latlng: LatLng, options: Slide Options): this
    // Moves this marker until `latlng`, like `setLatLng()`, but with a smooth
    // sliding animation. Fires `movestart` and `moveend` events.
    slideTo = (latlng: LatLngExpression, options: slideOptions) => {
        if (!this._map) return;

        this._slideToDuration = options.duration;
        this._slideToUntil = performance.now() + options.duration;
        this._slideFromLatLng = this.getLatLng();
        this._slideToLatLng = latlng;
        this._slideKeepAtCenter = !!options.keepAtCenter;
        this._slideDraggingWasAllowed =
            this._slideDraggingWasAllowed !== undefined
                ? this._slideDraggingWasAllowed
                : this._map.dragging.enabled();

        if (this._slideKeepAtCenter) {
            // this._map.dragging.disable();
            // this._map.doubleClickZoom.disable();
            this._map.options.touchZoom = "center";
            this._map.options.scrollWheelZoom = "center";
        }

        this.fire("movestart");
        this._slideTo();

        return this;
    };

    // method slideCancel(): this
    // Cancels the sliding animation from `slideTo`, if applicable.
    slideCancel() {
        L.Util.cancelAnimFrame(this._slideFrame);
    }

    private _slideTo = () => {
        if (!this._map) return;

        let remaining = this._slideToUntil - performance.now();

        if (remaining < 0) {
            this.setLatLng(this._slideToLatLng);
            this.fire("moveend");
            if (this._slideDraggingWasAllowed) {
                this._map.dragging.enable();
                this._map.doubleClickZoom.enable();
                this._map.options.touchZoom = true;
                this._map.options.scrollWheelZoom = true;
            }
            this._slideDraggingWasAllowed = false;
            return this;
        }

        let startPoint = this._map.latLngToContainerPoint(this._slideFromLatLng);
        let endPoint = this._map.latLngToContainerPoint(this._slideToLatLng);
        let percentDone =
            (this._slideToDuration - remaining) / this._slideToDuration;

        let currPoint = endPoint
            .multiplyBy(percentDone)
            .add(startPoint.multiplyBy(1 - percentDone));
        let currLatLng = this._map.containerPointToLatLng(currPoint);
        this.setLatLng(currLatLng);

        if (this._slideKeepAtCenter) {
            this._map.panTo(currLatLng, { animate: false });
        }

        this._slideFrame = L.Util.requestAnimFrame(
            this._slideTo,
            this
        );
    };
}

export interface ReactLeafletSlideCircleProps
    extends CircleMarkerOptions, PathOptions, EventedProps {
        center: LatLngExpression;
        duration: number;
        keepAtCenter?: boolean;
    }

export default createLayerComponent<
    SlideCircle,
    ReactLeafletSlideCircleProps
    >(
    function createCircleMarker({ center, ...options }, ctx) {
        const instance = new SlideCircle(center, options);
        return { instance, context: { ...ctx, overlayContainer: instance } };
    },
    function updateMarker(marker, props, prevProps) {
        if (
            prevProps.center !== props.center
        ) {
            marker.slideTo(props.center, {
                duration: props.duration,
                keepAtCenter: props.keepAtCenter,
            });
        }
    }
);

window.SlideCircle = SlideCircle;
