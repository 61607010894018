import React from 'react';
import { bed_image_black, calendar_icon, email_icon, fuelIcon, spoon_image, telphone_image, website_icon } from '../../utils/images';
import { accommodationService } from '../../types/accommodationType';
import { foodFuelFoodData } from '../../types/foodFuelType';
import moment from 'moment';

export const card_categories = {
    ACCOMMODATION: "ACCOMMODATION",
    FOOD: "FOOD",
    FUEL: "FUEL",
}

export interface ServiceCardProps extends accommodationService, foodFuelFoodData {
    type: "ACCOMMODATION" | "FOOD" | "FUEL";
}

function ServiceCard(props: ServiceCardProps) {

    const getIcon = (type: string) => {
        switch (type) {
            case card_categories.ACCOMMODATION: return bed_image_black;
            case card_categories.FOOD: return spoon_image;
            case card_categories.FUEL: return fuelIcon;
            default: return "";
        }
    }

    const getLinkByUrl = (url: string) => {
        if (!url) return "";
        if (url && (url.includes("https://") || url.includes("http://"))) {
            return url;
        } else if (url && url.toLowerCase() === 'no website') return "";
        else return "http://" + url;
    }

    return (
        <div className="accommodation-place-list-item">
            <div className="accommodation-place-list-item-full">
                <ul>
                    <li >
                        <div className="place-list-icon">
                            <img src={getIcon(props.type)} alt="spoon" />
                        </div>
                        <div className="place-list-text">
                            <h4>{props.service_name}</h4>
                            {props.fuel_food !== undefined && props.day_from && props.day_to && (
                                <div className="place-list-time">{props.day_from} – {props.day_to}: {moment(props.time_from, 'hh:mm').format('LT').toLocaleLowerCase().replace(" ", "")} to {moment(props.time_to, 'hh:mm').format('LT').toLocaleLowerCase().replace(" ", "")}</div>
                            )}
                            {props.fuel_food !== undefined && props.extra_day_from && props.extra_day_to && props.day_to && (
                                <div className="place-list-time">{props.extra_day_from} – {props.extra_day_to}: {props.extra_time_from && moment(props.extra_time_from, 'hh:mm').format('LT').toLocaleLowerCase().replace(" ", "")} to {props.extra_time_to && moment(props.extra_time_to, 'hh:mm').format('LT').toLocaleLowerCase().replace(" ", "")}</div>
                            )}
                            <div className="place-list-address">{props.address}</div>
                        </div>
                    </li>
                    {props.phone &&
                        <li>
                            <div className="place-list-icon"><img src={telphone_image} alt="tpropsephone" /></div>
                            <div className="place-list-text phone-number-link">
                                <a href={`tel:${props.phone}`}>
                                    {props.phone}
                                </a>
                            </div>
                        </li>
                    }
                    {props.email &&
                        <li>
                            <div className="place-list-icon">
                                <img src={email_icon} alt="email icon" />
                            </div>
                            <div className="place-list-text website-link">
                                <a href={`mailto:${props.email}`}>
                                    {props.email}
                                </a>
                            </div>
                        </li>
                    }
                    {props.website &&
                        <li>
                            <div className="place-list-icon">
                                <img src={website_icon} alt="website icon" />
                            </div>
                            {props.website.toLowerCase() === 'no website' ? (
                                <div className="place-list-text">
                                    {props.website}
                                </div>
                            ) : (
                                <div className="place-list-text website-link">
                                    <a href={getLinkByUrl(props.website)} target="_blank" rel="noreferrer">
                                        {props.website}
                                    </a>
                                </div>
                            )}

                        </li>
                    }
                    {props.bookings && props.bookings.length > 0 &&
                        <li>
                            <div className="place-list-icon">
                                <img src={calendar_icon} alt="bookings icon" width="15" height="15" />
                            </div>
                            <div className="place-list-text">
                                {props.bookings.map((el, i) => (
                                    <div className="website-link" key={props.id + "bookings-" + el.booking + i}>
                                        <a href={getLinkByUrl(el.booking)} target="_blank" rel="noreferrer">
                                            {el.booking}
                                        </a>
                                    </div>
                                ))}
                            </div>

                        </li>
                    }
                </ul>

                {props.requirements && (
                    <span className="accommodation-bottom-text">
                        {props.requirements}
                    </span>
                )}

            </div>

        </div>
    );
}


export default ServiceCard;