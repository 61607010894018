import React from 'react';
import { Link } from 'react-router-dom';
import { logo_white } from '../../utils/images';

function Footer() {
    return (
        <footer className="footer-main">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 mobile-show">
                        <div className="footer-logo">
                            <img src={logo_white} alt="logo white" />
                        </div>
                        <div className="footer-sponsors-logo">
                            SPONSORS LOGOS
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h2 className="footer-title">Acknowledgement</h2>
                        <div className="footer-about-text">
                            <p>
                                We would like to thank and acknowledge the Commonwealth funding, via the Drought Communities Programme. Without this support this project would not be possible.
                            </p>
                            <p>
                                We’d like to thank the various photographers that have shared their photography with us. The photos on this website belong with these photographers and copyright remains with them. Please request permission to replicate these images directly with the photographer prior to any use.</p>
                            <p>
                                The Stirling Range National Park has some of Western Australia’s highest peaks including Bluff Knoll, and most spectacular bushwalks. Well-known for their brooding beauty, the mountains have so much to offer the avid bushwalker and casual observer. It’s an experience not to be missed!
                            </p>
                        </div>

                    </div>
                    <div className="col-md-3">
                        <h4 className="footer-title">&nbsp;</h4>
                        <div className="footer-links">

                            <ul>
                                <li><Link to="/get-directions">Get Directions</Link></li>
                                <li><Link to="/mountain-walk">Mountain Walks</Link></li>
                                <li><Link to="/drives">Drives</Link></li>
                                <li><Link to="/mountain-bike">Mountain Bike</Link></li>
                                <li><Link to="/wild-flower">Wildflowers</Link></li>
                                <li><Link to="/about">About Stirling Range</Link></li>
                                <li><Link to="/fuel-food">Food & Fuel</Link></li>
                                <li><Link to="/accommodation">Accommodation</Link></li>
                                <li><Link to="/privacy-policy">Privacy Policy & Disclaimers</Link></li>
                                <li><Link to="/download-app">Install the app</Link></li>
                                <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3 desktop-show">
                        <div className="footer-logo">
                            <img src={logo_white} alt="logo white" />
                        </div>
                        {/* <div className="footer-sponsors-logo">
                            SPONSORS LOGOS
                    </div> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-bottom-text">
                            &copy; Stirling Range Trails {new Date().getFullYear()}
                            {/* <div className="footer-bottom-text-mob">
                                We’d like to thank the various photographers that have shared their photography with us. The photos on this website belong with these photographers and copyright remains with them. Please request permission to replicate these images directly with the photographer prior to any use.
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
