import React, { useRef } from 'react';
import { direction_icon, get_direction_banner_image, map_direction_image, map_image } from '../utils/images';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { getPageData } from '../store/actionCreators/pageActionCreators';
import { PAGE_TYPES } from '../store/actionTypes/pageActionTypes';
import { RootState } from '../store';
import { FullPageLoader } from '../components/Loader';
import ImageView from '../components/Image';
import { Helmet } from 'react-helmet';

const GetDirection = (props: any) => {
    const { pageData, pageLoading } = props;
    let divRef = useRef<HTMLDivElement | null>(null);

    const goToDirection = () => {
        const link = document.createElement("a");
        link.href = "https://maps.google.com/maps/dir/?api=1&destination=Stirling+Range+National+Park, Western+Australia+6338";
        link.target = "_blank";
        if (divRef.current) {
            divRef.current.appendChild(link);
            link.click();
            divRef.current.removeChild(link);
        }
    }

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/get-directions" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image}
                    placeholder={get_direction_banner_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={direction_icon} alt="direction icon" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Get Directions')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(pageData?.banner_short_description || "No description available")}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.banner_full_description || `By car, the Stirling Ranges are 4.5 hours south east of Perth and just one hour's drive north from Albany in Western Australia.<br />
The nearest towns to the north are Gnowangerup (50km) and Borden (45km) and Cranbrook is on the western end of the Range.<br />
All road leading to the Stirling Range are sealed although some travel on good unsealed roads may be required to reach some mountains.<br />
To work out the best directions to the Stirling Range please enter in the following box where you are travelling from, the let us to the rest.`)}
                            </div>
                        </div>
                    </div>
                    <div className="direction-map-box">
                        <ImageView
                            url_src={pageData?.map_image}
                            placeholder={map_image}
                            alt={pageData?.map_image_alt_content}
                            description={pageData?.map_image_description}
                        />
                    </div>
                </div>
            </section>
            <section className="direction-content-section">
                <div className="container">
                    <div className="direction-content-box">
                        <div className="direction-content-left">{parse(pageData?.section_one_title || "")}</div>
                        <div className="direction-content-right">
                            {parse(pageData?.section_one_short_description || "For the most direct route to the Stirling Range, click the 'Get Directions' button and a travelling map will be created for you.")}
                        </div>
                        <div className="get-direction-control">
                            {/* <div className="pin-input-wrapper">
                                <input className="pin-input" type="text" placeholder="POST CODE" pattern="[0-9]*" value={userPin} onChange={onPinChange} maxLength={6} />
                            </div> */}
                            <div>
                                <button className="map-button" onClick={goToDirection}>
                                    <span>
                                        <img src={map_direction_image} alt="map" />
                                    </span>
                                    Get Directions
                                </button>
                                <div className="d-none" ref={divRef}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    const { pages } = state;
    return { pageData: pages.directionPageData, pageLoading: pages.pageLoading }
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine)
        dispatch(getPageData(PAGE_TYPES.DIRECTION_PAGE))
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(GetDirection);