import React, { SyntheticEvent } from "react";
// import { ImageValueSchema } from "../../services/databases";
// import { setImage, getImageByUrl } from "../../services/databases";
import { image_placehoder } from "../../utils/images";
import ImageLoader from './ImageLoader';

export interface ImageProps {
    url_src: string;
    placeholder?: string;
    alt: string | null;
    className?: string;
    styles?: {};
    height?: string;
    width?: string;
    description: string | null;
}

function ImageView({
    url_src,
    placeholder,
    alt,
    className,
    styles,
    height,
    width,
    description
}: ImageProps) {

    // const [error, setError] = useState(false);

    /**
     * 
     * @param blob 
     * @returns image_url 
     */

    // const blobToUrl = (blob: Blob): string => {
    //     var urlCreator = window.URL || window.webkitURL;
    //     return urlCreator.createObjectURL(blob);
    // };

    /**
     * Load saved image from indexDB if already saved.
     * @param e Event
     */

    const onImageError = async (e: SyntheticEvent<HTMLImageElement, Event>) => {
        let imageTag = e.currentTarget;
        if (placeholder) {
            imageTag.src = placeholder;
        } else {
            imageTag.src = image_placehoder;
        }
    };

    /**
     * Called when image get loaded successfully 
     * Used to save image Blob to indexDB for offline usage
     * @param e  Event
     */
    const onImageLoaded = async (e: SyntheticEvent<HTMLImageElement, Event>) => {

    };

    return (
        <React.Fragment>
            {url_src ? (
                <img
                    src={url_src}
                    onError={onImageError}
                    onLoad={onImageLoaded}
                    alt={alt || ""}
                    className={className ? className : ""}
                    style={styles ? styles : {}}
                    height={height ? height : "auto"}
                    width={width ? width : "auto"}
                    loading="lazy"
                    aria-describedby={description || ""}
                />
            ) : <ImageLoader />}
        </React.Fragment>
    );
}

export default ImageView;
