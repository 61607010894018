const nth = function (d: number) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function getFormattedDate(dateString: string): string {
  try {
    if (dateString.split(" ").length > 1) return dateString;
    const splits = dateString.split(/[/-]/);
    const convertedDate = new Date(`${splits[1]} ${splits[0]} ${splits[2]}`);
    const date = convertedDate.getDate();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][convertedDate.getMonth()];
    return `${date}${nth(date)} ${month}`;
  } catch (err) {
    return dateString;
  }
}

export const truncateText = (input: string) =>
  input.length > 100 ? `${input.substring(0, 120)}...` : input;

export const KMLParser = async (plainText: string) => {
  try {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let Polygons = [];
    let Markers = [];

    if (xmlDoc.documentElement.nodeName === "kml") {
      for (const item of xmlDoc.getElementsByTagName("Placemark") as any) {
        // let placeMarkName = item.getElementsByTagName('name')[0].childNodes[0].nodeValue.trim()
        let polygons = item.getElementsByTagName("Polygon");
        let markers = item.getElementsByTagName("Point");

        /** POLYGONS PARSE **/
        for (const polygon of polygons) {
          let coords = polygon
            .getElementsByTagName("coordinates")[0]
            .childNodes[0].nodeValue.trim();
          let points = coords.split(" ");

          let PolygonsPaths = [];
          for (const point of points) {
            let coord = point.split(",");
            PolygonsPaths.push({ lat: +coord[1], lng: +coord[0] });
          }
          Polygons.push(PolygonsPaths);
        }

        /** MARKER PARSE **/
        for (const marker of markers) {
          var coords = marker
            .getElementsByTagName("coordinates")[0]
            .childNodes[0].nodeValue.trim();
          let coord = coords.split(",");
          Markers.push({ lat: +coord[1], lng: +coord[0] });
        }
      }
    } else {
      return { markers: [], polygons: [] };
    }
    return { markers: Markers, polygons: Polygons };
  } catch (err) {
    return { markers: [], polygons: [] };
  }
};

export const getDevicetype = (): string => {
  if (navigator) {
    const ua = navigator.userAgent;
    if (/(tablet|ipdad|playbook|silk) | (android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
  }
  if (window.orientation !== undefined) return "ipad";
  return "desktop";
};

export const getCoordinatesFromKMLText = (plainText: string) => {
  try {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let StartCoordinates: any[] = [];
    let EndCoordinates: any[] = [];

    if (xmlDoc.documentElement.nodeName === "kml") {
      for (const item of xmlDoc.getElementsByTagName("Placemark") as any) {
        let placeMarkName = item
          .getElementsByTagName("name")[0]
          .textContent.trim();
        if (placeMarkName.match(/start point/i)) {
          StartCoordinates = item
            .getElementsByTagName("Point")[0]
            .getElementsByTagName("coordinates")[0]
            .textContent.trim()
            .split(",");
        } else if (placeMarkName.match(/finish point/i)) {
          EndCoordinates = item
            .getElementsByTagName("Point")[0]
            .getElementsByTagName("coordinates")[0]
            .textContent.trim()
            .split(",");
        }
      }
    } else {
      return { startCoordinates: [], endCoordinates: [] };
    }
    return {
      startCoordinates: StartCoordinates,
      endCoordinates: EndCoordinates,
    };
  } catch (err) {
    return { startCoordinates: [], endCoordinates: [] };
  }
};

export const getObjectLength = (data: { [key: number]: any[] }) => {
  if (data && Object.keys(data).length > 0) {
    return parseInt(
      Object.keys(data).reduce((prev, key) => {
        if (parseInt(key) === 2)
          return (parseInt(prev) + data[parseInt(key)].length + 2).toString();
        return (parseInt(prev) + data[parseInt(key)].length).toString();
      })
    );
  }
  return 0;
};

export const checkAppInstalled = () => {
  let isInstalled = localStorage.getItem("pwaInstalled") === "1" || false;
  let isVerified = localStorage.getItem("pwaVerified") !== "0" || false;

  if (window.matchMedia("(display-mode: standalone)").matches) {
    // User is currently navigating on the PWA so yes it's installed
    localStorage.setItem("pwaInstalled", "1");
    localStorage.setItem("pwaVerified", "1");
    isInstalled = true;
  } else {
    //User is navigating in browser
    window.addEventListener("beforeinstallprompt", () => {
      localStorage.setItem("pwaInstalled", "0");
      localStorage.setItem("pwaVerified", "0");
      isInstalled = false;
      //User can get an installation prompt meaning the app is not installed
    });
    window.addEventListener("onappinstalled", () => {
      localStorage.setItem("pwaInstalled", "1");
      localStorage.setItem("pwaVerified", "1");
      isInstalled = true;
    });
  }

  return isInstalled && isVerified;
};

export const getTitle = (title: string | undefined) => {
  if (!title) return "";
  const splits = title.split("(");
  const _title = splits[0].replace(/drive/i, "");
  return _title.trim().toLowerCase().replaceAll(" ", "-");
};
