import React, { useEffect, useState } from "react";
import { mountain_walk_banner_image, shoes_icon } from "../utils/images";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../store";
import { getTrails } from "../store/actionCreators/trailsActionCreators";
import { MountainWalkCard } from "../components/Cards";
import { trailData, trailDataSeasons } from "../types/trails";
import { Spinner } from "react-bootstrap";
import Colors from "../utils/colors";
import GradeInfo from "../data/gradesInfo";
import { getPageData } from "../store/actionCreators/pageActionCreators";
import { PAGE_TYPES } from "../store/actionTypes/pageActionTypes";
import { FullPageLoader } from "../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { TRAILS_TYPES } from "../store/actionTypes/trailsActionTypes";
import parse from "html-react-parser";
import ImageView from "../components/Image";
import constants from "../utils/globalConstants";
import { Helmet } from 'react-helmet';

const MountainWalk = (props: any) => {
  const { trailsList, loading, pageData, pageLoading, detailedMapsArr } = props;
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<number>(0);
  const [currentData, setCurrentData] = useState<any>([]);

  /**
   * @function dispatch event to get mountain-walk data from server if user is online
   */
  useEffect(() => {
    if (navigator.onLine)
      dispatch(getTrails(page, TRAILS_TYPES.MOUNTAIN_WALK_PAGE, filter));
  }, [page, dispatch, filter]);

  const changeFilter = (grade: number) => {
    if (page !== 1) setPage(1);
    setFilter(grade);
  }



  /**
   * @hook 
   * Watch changes in traillist, filter and page then
   * set current trail data according to filter(grade) and page; from traillist object array
   */
  useEffect(() => {
    if (page === 1) {
      setCurrentData([]);
      if (
        trailsList[filter] !== undefined &&
        trailsList[filter][page] !== undefined
      )
        setCurrentData(trailsList[filter][page]);
    } else {
      if (
        trailsList[filter] !== undefined &&
        trailsList[filter][page] !== undefined
      )
        setCurrentData((prev: any) => [...prev, ...trailsList[filter][page]]);
    }

  }, [trailsList, filter, page]);

  // show loader when page data is being loaded
  if (pageLoading && !pageData) {
    return <FullPageLoader />
  }
  return (
    <div className="main-wrapper">
      <Helmet>
        <link rel="canonical" href="https://www.stirlingrangetrails.com.au/mountain-walk" />
        <meta name="description" content={pageData?.meta_description || ""} />
        <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
      </Helmet>
      <section className="inner-hero-section">
        <ImageView
          url_src={pageData?.banner_image || mountain_walk_banner_image}
          placeholder={mountain_walk_banner_image}
          alt={pageData?.banner_image_alt_content}
          description={pageData?.banner_image_description}
        />
      </section>
      <section className="inner-second-section">
        <div className="container">
          <div className="title-section-head-box">
            <div className="title-section-head">
              <div className="title-section-head-icon">
                <img src={shoes_icon} alt="shoes" />
              </div>
              <h1>{parse(pageData?.h1_tag || 'Mountain Walks')}</h1>
            </div>
            <div className="title-section-head-content">
              <div className="title-section-head-title">
                {parse(pageData?.short_description || "")}
              </div>
              <div className="title-section-head-text">
                {parse(pageData?.full_description || "")}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mountain-walk-item-section">
        <div className="container">
          <div className="item-list-fliter-box">
            <div className="item-list-fliter-title">Grade Filter</div>
            <div className="item-list-fliter-field">
              <div className="item-list-fliter-field-item">
                <div
                  className={`item-list-fliter-field-label cursor-pointer ${filter === 0 ? "active-text" : ""
                    }`}
                  onClick={() => setFilter(0)}
                >
                  SHOW ALL
                </div>
                <ul>
                  {[1, 2, 3, 4, 5].map((el) => (
                    <li onClick={() => setFilter(el)} key={'input-key-' + el}>
                      <input
                        type="radio"
                        name="grade"
                        id={`grade-${el}`}
                        checked={filter === el}
                        onChange={() => changeFilter(el)}
                      />
                      <label>{el}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="item-list-fliter-item-text">
              <h4>GRADE {filter || "ALL"}</h4>
              {GradeInfo[filter]}
            </div>
            <div className="offline-instruction-text mt-2">To use offline, click on each trail</div>
          </div>
          <InfiniteScroll
            dataLength={currentData.length}
            next={() => {
              setPage(page + 1);
            }}
            hasMore={currentData.length >= page * 2}
            loader={
              loading ? (
                <div className="d-flex justify-content-center w-100">
                  <Spinner
                    animation="border"
                    style={{ color: Colors.primary }}
                  />
                </div>
              ) : null
            }
            className="item-info-list-box"
          >
            {trailsList[filter] !== undefined &&
              Object.keys(trailsList[filter]).map(
                (key) =>
                  trailsList[filter][key] &&
                  trailsList[filter][key].map((item: trailData, i: number) => (
                    <MountainWalkCard
                      id={item?.id}
                      title={item.title}
                      key={'mountain-walk-item' + item?.id + i}
                      description={item.short_description}
                      distance={item.length}
                      elevation={item.elevation}
                      grade={item.filter.replace("Grade", "").trim()}
                      image={item?.trail_image}
                      time={item.time}
                      link="/mountain-walk"
                      type="WALK"
                      trip_type={item.trip_type}
                      item={item}
                      alt={item.banner_image_alt_content}
                      image_description={item.banner_image_description}
                      detailedMapDownloaded={detailedMapsArr.includes(item.id)}
                    />
                  ))
              )}
          </InfiniteScroll>
          {loading && !(currentData.length >= page * 2) && (
            <div className="d-flex justify-content-center w-100">
              <Spinner animation="border" style={{ color: Colors.primary }} />
            </div>
          )}
          {!loading && currentData.length <= 0 && (
            <div className="no-result-box">
              <span>
                {filter === 1 ? parse(constants.MOUNTAIN_WALK_FILTER1_ERROR_MSG || "") :
                  filter === 2 ? parse(constants.MOUNTAIN_WALK_FILTER2_ERROR_MSG || "") : "No results"}
              </span>
            </div>
          )}
        </div>
      </section>
      <section>
        <div className="container">
          <div className="item-info-text-box item-list-text-season-box">
            <div className="item-info-text-title">Safety Information</div>
            <div className="item-info-text-subtitle">About the Grading System</div>
            <div className="item-info-text-description">
              {parse(
                pageData?.safety_information || "No information available"
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="mountain-walk-item-section">
        <div className="container">
          <div className="item-list-fliter-season-box ">
            <div className="item-list-fliter-season-title ">
              There is something different <br />to see in each season.
            </div>
            <div className="item-list-fliter-season-description">
              <div className="container">
                <ul className="list-style-none">
                  {pageData && pageData.seasons.map((el: trailDataSeasons, i: number) => (
                    <li className="season-wrapper-li" key={el.season_id + "-season"}>
                      <div className="season-title">{el.season}</div>
                      <div className="season-desc">{parse(el.content)}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="before-travel-walk-section">
        <div className="container">
          <div className="item-list-fliter-season-title font-weight-bold title-top-margin">Before You Travel</div>
          <div className="item-info-text-description">
            {parse(pageData?.before_travel || `A National Park Pass is required to access this area. A pass can be obtained online here:<a href="https://parks.dpaw.wa.gov.au" target="_blank" rel="noreferrer">https://parks.dpaw.wa.gov.au</a>`)}
          </div>
          <div className="item-list-fliter-season-title title-top-margin font-weight-bold">Before You Walk - Advice to Walkers</div>
          <div className="item-info-text-description">
            <div className="container text-start">
              {parse(pageData?.before_walk || `
              There are walks suitable for all ages and levels of fitness, but we strongly recommend all walkers to take some basic precautions for each walk:<br /><br />
              <ul>
                <li>Take plenty of water (more water than you think you’ll need) and snacks with you!</li>
                <li>Check the weather forecast and be prepared for changes in the weather conditions.</li>
                <li>Wear appropriate clothing, including long pants, hat and supporting footwear. Be aware that weather conditions can change during your walk.</li>
                <li>Take sunscreen.</li>
                <li>Undertake walks suitable to your physical fitness, general state of health and experience.</li>
                <li>Carry your possessions in a suitable backpack that is comfortable, leaving both hands free.</li>
              </ul>`)}
            </div>
          </div>

        </div>
      </section>
    </div>
  );
};

function mapStateToProps(state: RootState) {
  const { trails, pages } = state;
  return {
    trailsList: trails.MountainWalkData || {},
    loading: trails.loading,
    pageData: pages.mountainWalkPageData,
    pageLoading: pages.pageLoading,
    detailedMapsArr: trails.detailedMapsArr
  };
}

function mapDispatchToProps(dispatch: any) {
  if (navigator.onLine) dispatch(getPageData(PAGE_TYPES.MOUNTAIN_WALK_PAGE));
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(MountainWalk);
