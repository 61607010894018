const GradeInfo = [
  "",
  "Suitable for people with a disability or wheelchair with assistance. No hiking experience required as trails have a flat even surface with no steps or steep sections. Hikes generally less than 5km. There are a number of firebreak trails within the Stirling Range National Park.",
  "Suitable for families with young children. No hiking experience required as trails have a harder compacted surface. Expect gentle hills and occasional steps. Hikes generally less than 10km. There are a number of firebreak trails within the Stirling Range National Park.",
  "Suitable for most ages and fitness levels. Hiking experience is recommended as trails may have short steep hill sections, rough surfaces and natural steps. Hikes generally less than 20km.",
  "Suitable for experienced hikers as navigation and technical skills will be required. Trails will be long, rough and very steep with limited signage or navigation aids. Rock scrambling and river crossings may be required. Hikes generally less than 25km.",
  "Suitable for highly experienced self-reliant hikers with specialised skills, including map and compass navigation and emergency first aid. Trails will be unmarked, long, very rough and very steep. Rock scrambling and river crossings may be required. Hikes generally greater than 25km.",
];

export default GradeInfo;
