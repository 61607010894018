import { aboutUsImage } from "../../types/aboutUsType";
import { accommodationService } from "../../types/accommodationType";
import {
  foodFuelFoodData,
  foodFuelFuelData,
  foodFuelServicesResponse,
} from "../../types/foodFuelType";
import { HomePageBestMomentsResponse } from "../../types/HomeType";
import { PoinPoints, trailData } from "../../types/trails";
import { wildFlowerData } from "../../types/wildFlowers";

export const TRAILS_TYPES = {
  MOUNTAIN_WALK_PAGE: 2,
  MOUNTAIN_BIKE_PAGE: 3,
  DRIVES_PAGE: 1,
};

export const SET_TRAILS_LOADING = "trailsActionTypes/SET_TRAILS_LOADING";
export interface SetTrailsLoadingAction {
  type: typeof SET_TRAILS_LOADING;
  loading: boolean;
}

export const SET_SINGLE_TRAIL_DATA = "trailsActionTypes/SET_SINGLE_TRAIL_DATA";
export interface SetSingleTrailDataAction {
  type: typeof SET_SINGLE_TRAIL_DATA;
  data: trailData;
}

export const SET_TRAILS = "trailsActionTypes/SET_TRAILS";
export interface SetTrailsAction {
  type: typeof SET_TRAILS;
  data: trailData[];
  trail_type: number;
  page: number;
  filter: number;
}

export const GET_TRAILS = "trailsActionTypes/GET_TRAILS";
export interface GetTrailsAction {
  type: typeof GET_TRAILS;
  page: number;
  trail_type: number;
  filter: number;
}

export const GET_WILD_FLOWERS = "wildflowerActionTypes/GET_WILD_FLOWERS";
export interface GetWildFlowerAction {
  type: typeof GET_WILD_FLOWERS;
  page: number;
}

export const GET_TRAILS_REQUEST = "trailsActionTypes/GET_TRAILS_REQUEST";
export interface GetTrailsRequestAction {
  type: typeof GET_TRAILS_REQUEST;
}

export const GET_TRAILS_SUCCESS = "trailsActionTypes/GET_TRAILS_SUCCESS";
export interface GetTrailsSuccessAction {
  type: typeof GET_TRAILS_SUCCESS;
  data: trailData[];
  trail_type: number;
  page: number;
  filter: number;
}

export const GET_TRAILS_FAILURE = "trailsActionTypes/GET_TRAILS_FAILURE";
export interface GetTrailsFailureAction {
  type: typeof GET_TRAILS_FAILURE;
  error: Error | string;
}

export const GET_WILD_FLOWER_SUCCESS =
  "wildflowerActionTypes/GET_WILD_FLOWER_SUCCESS";
export interface GetWildFlowerSuccessAction {
  type: typeof GET_WILD_FLOWER_SUCCESS;
  data: wildFlowerData[];
  page: number;
}

export const GET_WILD_FLOWER_FAILURE =
  "wildflowerActionTypes/GET_WILD_FLOWER_FAILURE";
export interface GetWildFlowerFailureAction {
  type: typeof GET_WILD_FLOWER_FAILURE;
  error: Error | string;
}

export const SET_CURRENT_TRAILS_MAP_DATA =
  "trailsActionTypes/SET_CURRENT_TRAILS_MAP_DATA";
export interface SetCurrentTrailsMapDataAction {
  type: typeof SET_CURRENT_TRAILS_MAP_DATA;
  data: string | null;
}

export const SET_CURRENT_MAP_PIN_POINTS =
  "trailsActionTypes/SET_CURRENT_MAP_PIN_POINTS";
export interface SetCurrentMapPinPointsAction {
  type: typeof SET_CURRENT_MAP_PIN_POINTS;
  pinpoints: PoinPoints[] | null;
}

// getting About Image  [start]
export const GET_ABOUT_PAGE_IMAGES =
  "aboutPageImagesActionTypes/GET_ABOUT_PAGE_IMAGES";
export interface GetAboutPageImagesAction {
  type: typeof GET_ABOUT_PAGE_IMAGES;
  page: number;
}

export const GET_ABOUT_PAGE_IMAGES_SUCCESS =
  "aboutPageImagesActionTypes/GET_ABOUT_PAGE_IMAGES_SUCCESS";
export interface GetAboutPageImagesSuccessAction {
  type: typeof GET_ABOUT_PAGE_IMAGES_SUCCESS;
  data: aboutUsImage[] | string;
}

export const GET_ABOUT_PAGE_IMAGES_FAILURE =
  "aboutPageImagesActionTypes/GET_ABOUT_PAGE_IMAGES_FAILURE";
export interface GetAboutPageImagesFailureAction {
  type: typeof GET_ABOUT_PAGE_IMAGES_FAILURE;
  error: Error | string;
}
// getting About Image  [end]

// getting Accommodation services  [start]
export const GET_ACCOMMODATION_SERVICES =
  "accommodationServicesActionTypes/GET_ACCOMMODATION_SERVICES";
export interface GetAccommodationServicesAction {
  type: typeof GET_ACCOMMODATION_SERVICES;
  page: number;
  accommodation_title_id: number;
}

export const GET_ACCOMMODATION_SERVICES_SUCCESS =
  "accommodationServicesActionTypes/GET_ACCOMMODATION_SERVICES_SUCCESS";
export interface GetAccommodationServicesSuccessAction {
  type: typeof GET_ACCOMMODATION_SERVICES_SUCCESS;
  data: accommodationService[];
  page: number;
  accommodation_title_id: number;
}

export const GET_ACCOMMODATION_SERVICES_FAILURE =
  "accommodationServicesActionTypes/GET_ACCOMMODATION_SERVICES_FAILURE";
export interface GetAccommodationServicesFailureAction {
  type: typeof GET_ACCOMMODATION_SERVICES_FAILURE;
  error: Error | string;
}
// getting Accommodation services  [end]

// getting Fuel Data  [start]
export const GET_FUEL_DATA = "foodFuelDataActionTypes/GET_FUEL_DATA";
export interface GetFuelDataAction {
  type: typeof GET_FUEL_DATA;
  page: number;
  fuel_and_food_title_id: number;
}

export const GET_FUEL_DATA_SUCCESS =
  "foodFuelDataActionTypes/GET_FUEL_DATA_SUCCESS";
export interface GetFuelDataSuccessAction {
  type: typeof GET_FUEL_DATA_SUCCESS;
  data: foodFuelServicesResponse;
  page: number;
  fuel_and_food_title_id: number;
}

export const GET_FUEL_DATA_FAILURE =
  "foodFuelDataActionTypes/GET_FUEL_DATA_FAILURE";
export interface GetFuelDataFailureAction {
  type: typeof GET_FUEL_DATA_FAILURE;
  error: Error | string;
}
// getting Fuel Data  [end]

// getting Food Data  [start]
export const GET_FOOD_DATA = "foodFuelFoodDataActionTypes/GET_FOOD_DATA";
export interface GetFoodDataAction {
  type: typeof GET_FOOD_DATA;
  page: number;
  fuel_and_food_title_id: number;
}

export const GET_FOOD_DATA_SUCCESS =
  "foodFuelFoodDataActionTypes/GET_FOOD_DATA_SUCCESS";
export interface GetFoodDataSuccessAction {
  type: typeof GET_FOOD_DATA_SUCCESS;
  data: foodFuelServicesResponse;
  page: number;
  fuel_and_food_title_id: number;
}

export const GET_FOOD_DATA_FAILURE =
  "foodFuelFoodDataActionTypes/GET_FOOD_DATA_FAILURE";
export interface GetFoodDataFailureAction {
  type: typeof GET_FOOD_DATA_FAILURE;
  error: Error | string;
}
// getting Food Data  [end]

// getting HomePage Data  [start]
export const GET_HOME_PAGE_BEST_MOMENTS =
  "bestMomentsActionTypes/GET_HOME_PAGE_BEST_MOMENTS";
export interface GetHomePageBestMomentsAction {
  type: typeof GET_HOME_PAGE_BEST_MOMENTS;
  page: number;
}

export const GET_HOME_PAGE_BEST_MOMENTS_SUCCESS =
  "bestMomentsActionTypes/GET_HOME_PAGE_BEST_MOMENTS_SUCCESS";
export interface GetHomePageBestMomentsSuccessAction {
  type: typeof GET_HOME_PAGE_BEST_MOMENTS_SUCCESS;
  data: HomePageBestMomentsResponse;
  page: number;
}

export const GET_HOME_PAGE_BEST_MOMENTS_FAILURE =
  "bestMomentsActionTypes/GET_HOME_PAGE_BEST_MOMENTS_FAILURE";
export interface GetHomePageBestMomentsFailureAction {
  type: typeof GET_HOME_PAGE_BEST_MOMENTS_FAILURE;
  error: Error | string;
}
// getting HomePage Data  [end]

export const SET_SINGLE_ACCOMMODATION_SERVICE =
  "accommodationServicesActionTypes/SET_SINGLE_ACCOMMODATION_SERVICE";
export interface SetSingleAccommodationServiceAction {
  type: typeof SET_SINGLE_ACCOMMODATION_SERVICE;
  data: accommodationService;
  page: number;
  accommodation_title_id: number;
}

export const SET_SINGLE_FOOD_DATA =
  "foodFuelFoodDataActionTypes/SET_SINGLE_FOOD_DATA";
export interface SetSingleFoodDataAction {
  type: typeof SET_SINGLE_FOOD_DATA;
  data: foodFuelFoodData;
  page: number;
  fuel_and_food_title_id: number;
}

export const SET_SINGLE_FUEL_DATA =
  "foodFuelFoodDataActionTypes/SET_SINGLE_FUEL_DATA";
export interface SetSingleFuelDataAction {
  type: typeof SET_SINGLE_FUEL_DATA;
  data: foodFuelFuelData;
  page: number;
  fuel_and_food_title_id: number;
}

export const SET_MAPS_PROGRESS =
  "pageDataActionTypes/SET_MAPS_PROGRESS";
export interface SetMapsProgressAction {
  type: typeof SET_MAPS_PROGRESS;
}

export const SET_MAPS_LOADED =
  "pageDataActionTypes/SET_MAPS_LOADED";
export interface SetMapsLoadedAction {
  type: typeof SET_MAPS_LOADED;
}

export const SET_MAP_AS_DETAILED =
  "pageDataActionTypes/SET_MAP_AS_DETAILED";
export interface SetMapAsDetailedAction {
  type: typeof SET_MAP_AS_DETAILED;
  data: number;
}


// export const SET_KML_LINKS = "kmllinks/SET_KML_LINKS";
// export interface SetKMLLinks {
//   type: typeof SET_KML_LINKS;
//   data: Array<{url: string, revision:string}>;
// }

export type TrailsAction =
  | SetTrailsAction
  | GetTrailsAction
  | GetWildFlowerAction
  | GetTrailsRequestAction
  | GetTrailsSuccessAction
  | GetTrailsFailureAction
  | SetTrailsLoadingAction
  | GetWildFlowerSuccessAction
  | GetWildFlowerFailureAction
  | SetCurrentTrailsMapDataAction
  | SetCurrentMapPinPointsAction
  | GetAboutPageImagesAction
  | GetAboutPageImagesSuccessAction
  | GetAboutPageImagesFailureAction
  | GetAccommodationServicesAction
  | GetAccommodationServicesSuccessAction
  | GetAccommodationServicesFailureAction
  | GetFoodDataAction
  | GetFoodDataSuccessAction
  | GetFoodDataFailureAction
  | GetFuelDataAction
  | GetFuelDataSuccessAction
  | GetFuelDataFailureAction
  | GetHomePageBestMomentsAction
  | GetHomePageBestMomentsSuccessAction
  | GetHomePageBestMomentsFailureAction
  | SetSingleTrailDataAction
  | SetSingleAccommodationServiceAction
  | SetSingleFoodDataAction
  | SetSingleFuelDataAction
  | SetMapsProgressAction
  | SetMapsLoadedAction
  | SetMapAsDetailedAction;
// | SetKMLLinks;
