import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { WildFlowerCard } from '../components/Cards';
import { RootState } from '../store';
import { getWildFlowers } from '../store/actionCreators/trailsActionCreators';
import { wildFlowerData } from '../types/wildFlowers';
import Colors from '../utils/colors';
import { flower_icon, wild_flower_banner_image, wild_flower_img1, wild_flower_img2 } from '../utils/images';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FullPageLoader } from '../components/Loader';
import parse from 'html-react-parser';
import { PAGE_TYPES } from '../store/actionTypes/pageActionTypes';
import { getPageData } from '../store/actionCreators/pageActionCreators';
import ImageView from '../components/Image';
import { Helmet } from 'react-helmet';

const WildFlower = (props: any) => {
    const { wildFlowerList, loading, pageData, pageLoading } = props;
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    /**
     * @function dispatch event to get wild flower data from server if user is online
     */
    const loadWildFlowersData = () => {
        if (navigator.onLine)
            dispatch(getWildFlowers(page))
    }

    /**
     * @Hook for loading data when page changes
     */
    useEffect(() => {
        loadWildFlowersData();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (wildFlowerList && (Object.keys(wildFlowerList).length > 0 || page !== 1)) {
            let total = 0;
            for (const key of Object.keys(wildFlowerList)) {
                if (wildFlowerList[key] !== undefined)
                    total += wildFlowerList[key].length;
            }
            if (total >= page * 3) setHasMore(true);
            else setHasMore(false);
        }
    }, [page, wildFlowerList]);


    /** 
     * @Hook to detect changes in wildflower list data to calculate latest list length
     */
    const dataLength = useMemo(() => {
        let total = 0;

        if (wildFlowerList) {
            for (let key of Object.keys(wildFlowerList)) {
                if (wildFlowerList[key] !== undefined)
                    total += wildFlowerList[key].length;
            }
        }
        return total;
    }, [wildFlowerList]);

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/wild-flower" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView
                    url_src={pageData?.banner_image || wild_flower_banner_image}
                    placeholder={wild_flower_banner_image}
                    alt={pageData?.banner_image_alt_content}
                    description={pageData?.banner_image_description}
                />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={flower_icon} alt="flower" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'Wildflowers')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(pageData?.banner_short_description || 'The Stirling Range is nature’s gallery for rare and unusual floral species.')}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.banner_full_description || "No description available")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wild-flower-tour-section bg-striped">
                <div className="container">
                    <div className="wild-flower-tour-title">
                        <div className="section-title text-center">
                            <h2>{parse(pageData?.section_two_title || "Wild Flower Tours")}</h2>
                        </div>
                        <div className="section-content-box text-center">
                            {parse(pageData?.section_two_short_description || "Vestibulum non sapien ex. Praesent maximus neque in lectus malesuada maximus. Sed et gravida urna, ut sodales nunc. In vulputate, urna ut porta finibus, libero dolor porta mi, non lacinia justo diam sit amet ligula. Morbi arcu odio, condimentum non ipsum et, commodo tincidunt dui. Nam at ullamcorper diam.")}
                        </div>
                    </div>
                    <InfiniteScroll
                        dataLength={dataLength}
                        next={() => setPage(page + 1)}
                        hasMore={hasMore}
                        loader={
                            <div className="d-flex justify-content-center w-100">
                                <Spinner animation="border" style={{ color: Colors.primary }} />
                            </div>
                        }
                        className="wild-flower-tour-item-box">
                        {wildFlowerList && Object.keys(wildFlowerList).map(key => (
                            wildFlowerList[key] && wildFlowerList[key].map((item: wildFlowerData, i: number) =>
                                <WildFlowerCard title={item.title} key={item.id + "-wild-flower-" + i}
                                    short_desc={item.short_description}
                                    date_from={item.date_from}
                                    date_to={item.date_to}
                                    image={item.image_link}
                                    email={item.email}
                                    external_link={item.external_link}
                                    alt={item.image_alt_content}
                                    description={item.image_description}
                                />
                            )
                        ))}
                    </InfiniteScroll>
                    {!loading && wildFlowerList && Object.keys(wildFlowerList).length <= 0 && (
                        <div className="no-result-box">
                            <span>No results</span>
                        </div>
                    )}
                </div>
            </section>

            <section className="content-page-second-section bg-white wildflower-third-section">
                <div className="content-page-second-img-big">
                    <ImageView
                        url_src={pageData?.section_one_image || wild_flower_img1}
                        placeholder={wild_flower_img1}
                        alt={pageData?.section_one_image_alt_content}
                        description={pageData?.section_one_image_description}
                    />
                </div>
                <div className="container">
                    <div className="content-page-second-box wild-flower-left-content">
                        <div className="content-page-second-text">
                            <div className="section-title">
                                <h2>{parse(pageData?.section_one_title || "<p>Discovering<br />Nature</p>")}</h2>
                            </div>
                            <div className="content-page-second-img content-page-second-img-mobile">
                                <ImageView
                                    url_src={pageData?.section_one_image || wild_flower_img1}
                                    placeholder={wild_flower_img1}
                                    alt={pageData?.section_one_image_alt_content}
                                    description={pageData?.section_one_image_description}
                                />
                            </div>
                            <div className="section-content-box">
                                {parse(pageData?.section_one_short_description || "Kangaroo, Brush-tailed Wallabies, Brushtail Possums and Emus are frequent visitors to the region. Quokkas, Numbats, Chuditch, the Short Nosed Brown Bandicoot and the Honey Possum also live in the Stirling Range National Park.")}
                            </div>
                        </div>
                    </div>
                    <div className="content-page-second-bottom">
                        <div className="content-page-second-bottom-left">
                            <div className="section-content-box">
                                {parse(pageData?.section_one_full_description || "Stirling Range National Park is known for its staggering range of wildflowers - more than 1,500 different species, some of which are found nowhere else. Wildflower species include Banksia, Dryandras, Queen Sheba Orchids and Mountain Bells.")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content-page-second-section content-page-second-reverse mb-0">
                <div className="content-page-second-img-big">
                    <ImageView
                        url_src={pageData?.section_three_image || wild_flower_img2}
                        placeholder={wild_flower_img2}
                        alt={pageData?.section_three_image_alt_content}
                        description={pageData?.section_three_image_description}
                    />
                </div>
                <div className="container">
                    <div className="content-page-second-box wild-flower-left-content">
                        <div className="content-page-second-text">
                            <div className="section-title">
                                <h2>{parse(pageData?.section_three_title || "Annual<br /> Wildflower<br />Shows")}</h2>
                            </div>
                            <div className="content-page-second-img content-page-second-img-mobile">
                                <ImageView
                                    url_src={pageData?.section_three_image || wild_flower_img1}
                                    placeholder={wild_flower_img1}
                                    alt={pageData?.section_three_image_alt_content}
                                    description={pageData?.section_three_image_description}
                                />
                            </div>
                            <div className="section-content-box">
                                {parse(pageData?.section_three_description || '')}
                            </div>
                        </div>
                    </div>
                    <div className="content-page-second-bottom">
                        <div className="content-page-second-bottom-left">
                            <div className="section-content-box">
                                {parse(pageData?.section_three_short_description || "")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

function mapStateToProps(state: RootState) {
    const { trails, pages } = state;
    return { wildFlowerList: trails.WildFlowerData || {}, loading: trails.loading, pageData: pages.wildFlowerPageData, pageLoading: pages.pageLoading }
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine)
        dispatch(getPageData(PAGE_TYPES.WILD_FLOWER_PAGE))
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(WildFlower);