import React, { createContext, useCallback, useEffect } from "react";
import { sendAppInstallStatus } from "../services/apis/common";

export const AppContext = createContext({});

export interface AppContextProviderProps {
  children: JSX.Element
}

export function AppContextProvider({ children }: AppContextProviderProps) {
  const getUserIp = async () => {
    const res = await fetch('https://api.ipify.org?format=json');
    if (res.status === 200) {
      const data = await res.json();
      return data.ip;
    }
  }

  const setAppInstalledStatus = (status: string) => {
    if (status === "1")
      localStorage.setItem('pwaVerified', '1');
    else localStorage.setItem('pwaVerified', '0');
  }

  const isAppInstalledStatusNotSent = () => {
    if (localStorage.getItem("pwaVerified"))
      return localStorage.getItem("pwaVerified") === "0";
    return true;
  }

  const sendInstallStatus = async () => {
    try {
      const res = await sendAppInstallStatus(await getUserIp());
      if (res.status === 200) {
        setAppInstalledStatus("1");
        localStorage.setItem('pwaVerified', "1");
      }
    } catch (err) {
      console.log(err);
    }
  }

  if (window.matchMedia('(display-mode: standalone)').matches) {
    // User is currently navigating on the PWA so yes it's installed
    if (isAppInstalledStatusNotSent()) {
      sendInstallStatus()
    }
  }

  const setAppNotInstalled = useCallback(() => {
    setAppInstalledStatus("0")
  }, []);

  const setAppInstalled = useCallback(() => {
    setAppInstalledStatus("1")
  }, []);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', setAppNotInstalled);
    window.addEventListener('onappinstalled', setAppInstalled);
    return () => {
      window.removeEventListener('beforeinstallprompt', setAppNotInstalled);
      window.removeEventListener('onappinstalled', setAppInstalled);
    }
  }, [setAppInstalled, setAppNotInstalled]);

  return (
    <AppContext.Provider
      value={{}}
    >
      {children}
    </AppContext.Provider>
  );
}
