import React, { useEffect, useState } from 'react';
import { RootState } from '../store';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Map } from '../components/CustomMap';
import { getDevicetype } from '../utils/helper-functions';
import { NavigationButtonIcon } from '../utils/images';
import { Button, Modal, Card } from 'react-bootstrap';

/**
 * @Interface parameter type, id
 */
interface RouteParams {
    type: string,
    id: string
}
interface Props {
  detailedMapsArr: number[]
}

const LiveTrail = ({ detailedMapsArr }: Props) => {

    const params = useParams<RouteParams>();
    const [mapContainerHeight, setMapContainerHeight] = useState(`${window.innerHeight - 112}px`);
    const [followUser, setFollowUser] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    /**
     * @Function to set container height
     */
    const setContainerHeight = () => {
        setMapContainerHeight(`${window.innerHeight - 112}px`)
    }

    /**
     * @Hook watch device screen and change container height accordingly;
     */
    useEffect(() => {
      console.log('ID izzzzz: ' + params.id)
        window.addEventListener("resize", setContainerHeight);
        return () => {
            window.removeEventListener('resize', setContainerHeight);
        }
    }, [mapContainerHeight]);

    const goToUserLocation = () => {
        console.log("Go to user location");
        setFollowUser(true);
    };

    const onDialogResposne = () => {
        setShowDialog(false);
    }

    return (
        <div style={{ height: mapContainerHeight }}>
            <Map
              followUser={followUser}
              // onMoveDone={() => setFollowUser(false)}
              zoomLevel={params.type === "1" ? getDevicetype() === 'desktop' ? 13 : 11 : 10}
              trailType={params.type === '1' ? 'walk' : 'drive'}
              detailedMapDownloaded={detailedMapsArr.includes(parseInt(params.type))}
            />
            {/*<div className="direction-icon-wrapper">*/}
            {/*    /!* get direction button *!/*/}
            {/*    <div className="navigation-button cursor-pointer" onClick={goToUserLocation}>*/}
            {/*        <img src={NavigationButtonIcon} alt="navigation" />*/}
            {/*    </div>*/}
            {/*    /!* get direction button *!/*/}
            {/*</div>*/}
            <div>
                <Modal show={showDialog} centered onHide={() => setShowDialog(false)}
                    contentClassName="mx-2">
                    <Card style={{ padding: "25px 0 15px 0" }}>
                        <Card.Body>
                            <Card.Title className="text-center">
                                <h4 style={{ marginBottom: 35 }}>
                                    Please enable location service.
                                </h4>
                            </Card.Title>
                            <div className="d-flex justify-content-center">
                                <Button variant="success" color="primary" style={{ padding: '5px 20px' }}
                                    onClick={onDialogResposne}>
                                    Ok
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Modal>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState) {
  const { trails } = state;
  return {
      detailedMapsArr: trails.detailedMapsArr
  }
}

export default connect(mapStateToProps)(LiveTrail);