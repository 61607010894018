import React from 'react';
import { foodFuelFoodData } from '../../types/foodFuelType';
import { FoodFuelList } from './index';

export interface FoodFuelTitleListProps {
    apidata: { [key: number]: { [key: number]: foodFuelFoodData[] } };
    type: number;
    loading: boolean;
}


const FoodFuelTitleList = ({ apidata, type, loading }: FoodFuelTitleListProps) => {

    return (
        <section>
            <div className="accommodation-list-item-wrapper">
                <FoodFuelList
                    loading={loading}
                    food_fuel_type={type}
                    type={1}
                    data={apidata[1]}
                    title="AMELUP"
                    subtitle={type === 1 ? "Closest fuel stop to the Stirling Range National Park." : "Closest food and snack stop to the Stirling Range National Park."}
                />
            </div>
            <div className="accommodation-list-item-wrapper">
                <FoodFuelList
                    loading={loading}
                    food_fuel_type={type}
                    type={2}
                    data={apidata[2]}
                    title="BORDEN (39km North)"
                />
            </div>
            <div className="accommodation-list-item-wrapper">
                <FoodFuelList
                    loading={loading}
                    food_fuel_type={type}
                    type={3}
                    data={apidata[3]}
                    title="GNOWANGERUP (50km North)"
                />
            </div>
            <div className="accommodation-list-item-wrapper">
                <FoodFuelList
                    loading={loading}
                    food_fuel_type={type}
                    type={4}
                    data={apidata[4]}
                    title="ONGERUP (68km North)"
                />
            </div>
        </section>
    )
};

export default FoodFuelTitleList;