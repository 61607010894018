import React from "react";
import L, {LocationEvent} from "leaflet";
import { NavigationButtonIcon } from "../../utils/images";
import SlideCircle from "./SlideCircle";
import {Circle} from "react-leaflet";
import {latLng} from "leaflet";

export default class LocationMarker extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        console.log(this.props.map.getCenter());
        this.state = {
            position: undefined,
            locationPermission: false,
            accuracy: 100,
            accuracyWarning: false,
            followUser: props.followUser,
        }
    }

    componentDidMount() {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
            this.setState({permission: true});
            this.setState({
                position: L.latLng([position.coords.latitude, position.coords.longitude])
            });
            this.props.map.locate({
                watch: true,
                maximumAge: 0,
                enableHighAccuracy: true
            });
            this.props.map.on("locationfound", this.onLocationFound.bind(this));
        }, error => {
            console.log(error);
        });
        this.props.map.on("mousedown", this.stopFollowUser.bind(this));
    }

    componentWillUnmount() {
        this.props.map.stopLocate();
        // TODO: Remove other event handlers
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        this.flyMapToUser();
    }

    onLocationFound(location: LocationEvent) {
        console.log("Location found");
        console.log(location.latlng);
        console.log(this.state.position);
        let distanceTravelled = LocationMarker.haversineDistance(
            location.latlng, this.state.position);

        if (distanceTravelled > 37 || location.accuracy > 200) {
            console.warn("Location may not be accurate:");
            console.warn("Speed (m/s): " + distanceTravelled);
            console.warn("Accuracy (m): " + location.accuracy);
            this.setState({accuracyWarning: true});

        } else if (this.state.accuracyWarning) {
            console.log("Resetting warning");
            this.setState({accuracyWarning: false});
        }

        this.setState({position: location.latlng});
        this.setState({accuracy: location.accuracy});
        this.flyMapToUser();
    }

    stopFollowUser() {
        this.setState({followUser: false});
        console.log("Stop follow user");
    }

    setFollowUser() {
        console.log("Start follow user");
        this.setState({followUser: true});
    }

    flyMapToUser() {
        if (this.state.followUser && this.state.position != undefined) {
            this.props.map.flyTo(this.state.position, this.props.map.getZoom());
        }
    }

    static haversineDistance(a: any, b: any) {

        let atan2 = Math.atan2;
        let cos = Math.cos;
        let sin = Math.sin;
        let sqrt = Math.sqrt;
        let PI = Math.PI;

        // (mean) radius of Earth (meters)
        const R = 6378137;

        function squared (x: any) { return x ** 2 }
        function toRad (x: any) { return x * PI / 180.0 }

        let alat = a.lat || a.latitude;
        let blat = b.lat || b.latitude;
        let alng = a.lng || a.longitude;
        let blng = b.lng || b.longitude;

        let dLat = blat - alat;
        let dLon = blng - alng;

        const error = 0.00001;

        if (dLat < error || dLon < error) {
            return 0;
        }

        dLat = toRad(dLat);
        dLon = toRad(dLon);

        let f = squared(sin(dLat / 2.0)) + cos(toRad(alat) * cos(toRad(blat)) * squared(sin(dLon / 2.0)))
        let c = 2 * atan2(sqrt(f), sqrt(1 - f))

        return R * c
    }

    render() {
        return ( this.state.position == undefined ? null :
            <div>
                <div className="direction-icon-wrapper">
                    {/* get direction button */}
                    <div className="navigation-button cursor-pointer" onClick={this.setFollowUser.bind(this)}>
                        <img src={NavigationButtonIcon} alt="navigation" />
                    </div>
                    {/* get direction button */}
                </div>
                <div className="location-accuracy-warning" style={{
                    display: this.state.accuracyWarning ? "flex": "none"
                }}>
                    <p>Your location may not be accurate</p>
                </div>

                <SlideCircle
                    center={this.state.position}
                    fillOpacity={1.0}
                    fillColor={'#3388ff'}
                    radius={10}
                    stroke={true}
                    weight={3}
                    color={'#FFF'}
                    duration={999}
                    keepAtCenter={this.state.followUser}
                />
                {!this.state.accuracyWarning ? null :
                    <Circle center={this.state.position} radius={this.state.accuracy}/>
                }
            </div>
        );
    }
}
