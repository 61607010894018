import React, { useEffect, useMemo } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { connect } from 'react-redux';
import { RootState } from '../store';
import { info_image, about_page_img1, about_banner_image } from '../utils/images';
import { getPageData } from '../store/actionCreators/pageActionCreators';
import { PAGE_TYPES } from '../store/actionTypes/pageActionTypes';
import { FullPageLoader } from '../components/Loader';
import { useDispatch } from 'react-redux';
import { getAboutPageImages } from '../store/actionCreators/trailsActionCreators';
import parse from 'html-react-parser';
import { aboutUsImage } from '../types/aboutUsType';
import ImageView from '../components/Image';
import { Helmet } from 'react-helmet';

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

const About = (props: any) => {
    const { Images, pageData, pageLoading } = props;
    const dispatch = useDispatch();

    /**
     * @Hook to get crousel images by dipatching event on first load
     */
    useEffect(() => {
        if (navigator.onLine)
            dispatch(getAboutPageImages(1))
    }, [dispatch]);

    /**
     * @Function to render crouselItems
     */
    const crouselItems = useMemo(() => {
        if (Images)
            return Images.map((item: aboutUsImage, i: number) => (
                <div className="about-slide-item" key={item.id + "-item-" + i}>
                    <ImageView url_src={item.image} placeholder={about_banner_image} className="about-slide-image" alt={item.image_alt_content}
                        description={item.image_description} />
                </div>));
        else return [];

    }, [Images]);

    // show loader when page data is being loaded
    if (pageLoading && !pageData) {
        return <FullPageLoader />
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <link rel="canonical" href="https://www.stirlingrangetrails.com.au/about" />
                <meta name="description" content={pageData?.meta_description || ""} />
                <title>{pageData?.meta_title || "Stirling Range Trails"}</title>
            </Helmet>
            <section className="inner-hero-section">
                <ImageView url_src={pageData?.banner_image} placeholder={about_banner_image}
                    alt={pageData?.banner_image_alt_content} description={pageData?.banner_image_description} />
            </section>
            <section className="inner-second-section">
                <div className="container">
                    <div className="title-section-head-box">
                        <div className="title-section-head">
                            <div className="title-section-head-icon">
                                <img src={info_image} alt="info" />
                            </div>
                            <h1>{parse(pageData?.h1_tag || 'About<br />Stirling Range')}</h1>
                        </div>
                        <div className="title-section-head-content">
                            <div className="title-section-head-title">
                                {parse(pageData?.banner_short_description || "Beautiful and pristine Stirling Range National Park near Albany is famous for its colourful wildflowers, bushwalking and camping.")}
                            </div>
                            <div className="title-section-head-text">
                                {parse(pageData?.banner_full_description || "Stirling Range National Park is home to one of Western Australia’s highest peaks, Bluff Knoll. Take the challenge and climb Bluff Knoll, which rises more than a kilometre into the air, or go rock-climbing at Ellen Peak. You’ll be rewarded with panoramic views of the park and surrounding plains. Bluff Knoll is one of the few places in Western Australia where it snows - but only very occasionally!")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content-page-second-section content-page-second-reverse" >
                <div className="content-page-second-img-big">
                    <ImageView url_src={pageData?.section_one_image} placeholder={about_page_img1} alt={pageData?.section_one_image_alt_content || ""} description={pageData?.section_one_image_description} />
                </div>
                <div className="container">
                    <div className="content-page-second-box">
                        <div className="content-page-second-text">
                            <div className="section-title">
                                <h2> {parse(pageData?.section_one_title || "Discovering<br />Stirling Range")}</h2>
                            </div>
                            <div className="content-page-second-img content-page-second-img-mobile">
                                <ImageView url_src={pageData?.section_one_image} placeholder={about_page_img1} alt={pageData?.section_one_image_alt_content || ""} description={pageData?.section_one_image_description} />
                            </div>
                            <div className="section-content-box ">
                                {parse(pageData?.section_one_short_description || "Stirling Range National Park is known for its staggering range of wildflowers - more than 1,500 different species, some of which are found nowhere else.")}
                            </div>
                        </div>
                        <div className="content-page-second-img content-page-second-img-desktop">
                            <ImageView url_src={pageData?.section_one_image} placeholder={about_page_img1} alt={pageData?.section_one_image_alt_content || ""} description={pageData?.section_one_image_description} />
                        </div>
                    </div>
                    <div className="content-page-second-bottom">
                        <div className="content-page-second-bottom-left">
                            <div className="section-content-box text-start">
                                {parse(pageData?.section_one_full_description || "Stirling Range National Park is known for its staggering range of wildflowers - more than 1,500 different species, some of which are found nowhere else. Wildflower species include Banksia, Dryandras, Queen Sheba Orchids and Mountain Bells.")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="stirling-discover-section">
                <div className="container">
                    <div className="stirling-discover-top-box">
                        <div className="stirling-discover-top-title">{parse(pageData?.section_two_title || "Discovering<br /> Nature")}</div>
                        <div className="stirling-discover-top-text">
                            <h5>{parse(pageData?.section_two_short_description || "Go bushwalking during spring-time and you’ll be sure to see mountain-bells, banksias and orchids.")}
                            </h5>
                            {parse(pageData?.section_two_full_description || `Birdlife abounds in the park making it an ideal place for birdwatching. You can see large numbers of parrots and emus, while other common animals are wallabies and kangaroos. <br />
                            There is a campground at Moingup Springs and plenty of accommodation nearby. 
                            <br />
                            Stirling Range National Park is just over an hours’ drive northeast of Albany.`)}
                        </div>
                    </div>
                    <div className="stirling-discover-img-box">
                        {Images && <AliceCarousel
                            mouseTracking
                            responsive={responsive}
                            controlsStrategy="responsive"
                            autoPlay={Images.length > 2}
                            animationDuration={3000}
                            infinite={Images.length > 2}
                            autoPlayStrategy="action"
                            items={crouselItems}
                            autoHeight
                            autoWidth
                        />
                        }
                    </div>
                    <div className="stirling-discover-bottom-text">
                        {parse(pageData?.section_three_description || "Vestibulum non sapien ex. Praesent maximus neque in lectus malesuada maximus. Sed et gravida urna, ut sodales nunc. In vulputate, urna ut porta finibus, libero dolor porta mi, non lacinia justo diam sit amet ligula. Morbi arcu odio, condimentum non ipsum et, commodo tincidunt dui. Nam at ullamcorper diam.")}
                    </div>
                </div>
            </section>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    const { pages, trails } = state;
    return { Images: trails.aboutUsPageImages || [], loading: trails.loading, pageData: pages.aboutPageData, pageLoading: pages.pageLoading }
}

function mapDispatchToProps(dispatch: any) {
    if (navigator.onLine)
        dispatch(getPageData(PAGE_TYPES.ABOUTUS_PAGE))
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(About);